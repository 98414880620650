import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import {
  BoxHolder,
  Heading,
  MediumButton,
  PillGroup,
  BoxImagesComponent,
  Loader,
  MapComponent,
  SignatureComponent,
  InputComponent,
  RadioBox,
} from '../../../components/UIComponents'
import { Input, Dropdown, Radio, Switch, Checkbox, DatePicker, Tooltip } from 'sfy-react'
import AddressBox from '../../../components/AddressBox'
import { getTranslationText } from '../../../utils/languageTranslation.js'
import { regexCheck } from '../../../utils/regex'
import {
  getElementPosition,
  getFormattedTime,
  sortAlphabetically,
  getRequestTypeForServiceType,
  getLegalConsents,
} from '../../../utils/functions'
import { fallbackAddressConfig } from '../../../utils/constants'
import moment from 'moment'
import _ from 'lodash'
import './Request.scss'
import { ReactComponent as AddIcon } from '../../../../public/commonAssets/add-plus.svg'
import { ReactComponent as Email } from '../../../../public/commonAssets/email-web.svg'
import { ReactComponent as Phone } from '../../../../public/commonAssets/phone.svg'
import { ReactComponent as DownArrow } from '../../../../public/commonAssets/down-arrow.svg'
import { ReactComponent as ErrorIcon } from '../../../../public/commonAssets/info-alert.svg'
import { ReactComponent as IssueCheckIcon } from '../../../../public/commonAssets/issue-check.svg'
import { ReactComponent as InfoAlert } from '../../../../public/commonAssets/alert-circle.svg'
const invoiceVoice = '/commonAssets/Request/audio@3x.png'
const playIcon = '/commonAssets/play-icon.png'
const voiceRecording = '/commonAssets/voice-message.png'

const invoicePhoto = '/commonAssets/Request/photo@3x.png'
const directionalArrow = '/commonAssets/Request/directionalArrow.png'

import { getLocaleCurrency, getLocaleDate, getLocaleTime } from '../../../utils/locale'
import pushToAnalytics from '../../../utils/Analytics'

export const SelectDeviceComponent = ({ clearDeviceInfo, setIssues, setglobalLoaderStatus, getPaginatedDevicesAsync, ...props }) => {
  const [searchValue, setSearchValue] = useState()
  const [isSearchOn, setSearchIsOn] = useState(false)

  const formatProducts = useCallback((devices, disableNormalRepair) => {
    let arr = []
    if (devices?.length) {
      arr = devices.map((data) => {
        const { ConsumerProductID, TagName, BrandID, ProductSubCategoryID } = data
        const ProductName = data.Product.ProductName
        return { ConsumerProductID, TagName, BrandID, ProductSubCategoryID, ProductName, value: ProductName }
      })
      if (!disableNormalRepair) {
        arr.push({
          ConsumerProductID: -1,
          TagName: '',
          BrandID: -1,
          ProductSubCategoryID: -1,
          ProductName: `+${getTranslationText('common', 'addNewDevice')}`,
          value: `+${getTranslationText('common', 'addNewDevice')}`,
        })
      }
    }
    // setProducts(arr)
    return arr
  }, [])

  useEffect(() => {
    setIssues([])
    getPaginatedProducts(1, true) // Reset data on back, should always be initial
  }, [setIssues, getPaginatedProducts])

  useEffect(() => {
    const consumerID = props.user.userDetails.consumerID
    pushToAnalytics('select_device_page', {
      ConsumerID: consumerID,
    })
  }, [props.user.userDetails.consumerID])

  const getPaginatedProducts = useCallback(
    async (pageNumber, resetData, searchValue) => {
      try {
        setglobalLoaderStatus(true)
        const requestObject = {
          ConsumerID: props.user.userDetails.consumerID,
          pagination: {
            itemsPerPage: 5,
            pageNo: pageNumber,
          },
          protectedDevice: !props.user.appConfigDetails.showNormalRepairOptions,
        }
        if (searchValue?.trim()?.length > 2) {
          requestObject.searchQuery = searchValue
        }
        await getPaginatedDevicesAsync(requestObject, resetData)
      } catch (e) {
        console.log(e)
      } finally {
        setglobalLoaderStatus(false)
      }
    },
    [props.user.userDetails.consumerID, setglobalLoaderStatus, getPaginatedDevicesAsync]
  )

  useEffect(() => {
    const optionsComponent = document.getElementById('servify-dropdown-options')
    const optionsComponentRef = optionsComponent
    const handleOnScroll = () => {
      if (optionsComponent) {
        const { scrollTop, scrollHeight, clientHeight } = optionsComponent
        const isNearBottom = Math.round(scrollTop + clientHeight) >= Math.round(scrollHeight)
        if (isNearBottom && props.devices.deviceDetails?.currentPage < props.devices.deviceDetails?.totalPages) {
          getPaginatedProducts(props.devices.deviceDetails.currentPage + 1, false, searchValue)
        }
      }
    }
    optionsComponent.addEventListener('scroll', handleOnScroll)
    return () => {
      optionsComponentRef.removeEventListener('scroll', handleOnScroll)
    }
  }, [props.devices.deviceDetails, searchValue, getPaginatedProducts])

  const handleOnProductSearch = async (e) => {
    const optionsComponent = document.getElementById('servify-dropdown-options')
    if (optionsComponent) {
      optionsComponent.scrollTop = 0 // This is for scrolling to top when searching
    }
    const searchString = e.target.value
    // Below line is to make sure on every change of character, page number is reset to 1.
    const pageNo = searchValue?.length !== e.target.value?.trim()?.length ? 1 : props.devices.deviceDetails.currentPage
    setSearchValue(searchString)
    if (searchString?.trim()?.length > 2) {
      try {
        setSearchIsOn(true)
        e.target.blur() // So that the user cannot type while fetching the details
        setglobalLoaderStatus(true)
        const getProducts = () =>
          new Promise((resolve) =>
            setTimeout(async () => {
              await getPaginatedDevicesAsync(
                {
                  ConsumerID: props.user.userDetails.consumerID,
                  searchQuery: searchString,
                  pagination: {
                    pageNo,
                    itemsPerPage: 5,
                  },
                  protectedDevice: !props.user.appConfigDetails.showNormalRepairOptions,
                },
                true
              )
              e.target.focus() // So that the user can type again after fetching all the details
              setglobalLoaderStatus(false)
              resolve()
            }, 1000)
          )
        await getProducts()
      } catch (e) {
        console.log(e)
        setglobalLoaderStatus(false)
      }
    } else if (isSearchOn) {
      e.target.blur() // So that the user cannot type while fetching the details
      await getPaginatedProducts(1, true, searchString)
      setSearchIsOn(false) // Setting to false so that it is not called again for less than 3 characters
      e.target.focus() // So that the user can type again after fetching all the details
    }
  }

  const selectedValue = () => {
    var objLength = Object.getOwnPropertyNames(props.deviceInfo).length
    if (objLength) {
      let { ConsumerProductID, TagName, BrandID, ProductSubCategoryID } = props.deviceInfo
      let ProductName = props.deviceInfo.Product.ProductName
      return [
        {
          ConsumerProductID,
          TagName,
          BrandID,
          ProductSubCategoryID,
          ProductName,
          value: ProductName,
        },
      ]
    } else {
      let selectedObj = []
      if (props.listboxdata.categoryenabled) {
        selectedObj = [
          {
            ConsumerProductID: -1,
            TagName: '',
            BrandID: -1,
            ProductName: `+${getTranslationText('common', 'addNewDevice')}`,
            value: `+${getTranslationText('common', 'addNewDevice')}`,
          },
        ]
      }
      return selectedObj
    }
  }

  // render() {
  const appConfigDetails = _.get(props, 'user.appConfigDetails')
  const dateFormat = _.get(props, 'user.selectedCountryData.countryConfig.DateFormat.DEFAULT', 'DD/MM/YYYY')

  let selectedProductInfo = _.get(props, 'deviceInfo')
  const active_plan = selectedProductInfo?.PolicyDetails?.find((planInfo) => planInfo.Status === 1)
  const isLGbrandCheck = ['LGLA-Web'].includes(_.get(props, 'user.appConfigDetails.app'))
  const isNarwalUSbrandCheck = ['NarwalUS-Web'].includes(_.get(props, 'user.appConfigDetails.app'))

  let isFieldEditable = false
  if (props.isNewDevice || (props.uniqueIdrequired && !props.isNewDevice && _.get(props, 'listboxdata.productvalue', []).length)) {
    isFieldEditable = true
  }
  const postFix = props.uniqueIdrequired ? '' : ' ' + getTranslationText('common', 'optional')
  let appDeviceSerialLabel = `${getTranslationText('common', 'serialNumberLabel')} ${postFix}`
  if (!props.isNewDevice && _.get(props, 'deviceInfo.ProductUniqueID')) {
    appDeviceSerialLabel = getTranslationText('common', 'serialNumber')
  }
  // btnStatus true mean disabled
  let continueBtnStatus = !props.btnStatus
  let orderCheckDetails = _.get(props, 'productBrandConfig.WarrantyCheckDetails.AllowedValues', []).find(
    (configVal) => configVal.Type === 'Order No.'
  )
  if (orderCheckDetails) {
    let selectedProductInfo = _.get(props, 'deviceInfo')
    if (props.isNewDevice) {
      selectedProductInfo = _.get(props, 'listboxdata.productvalue[0]')
    }
    orderCheckDetails = !!orderCheckDetails.ProductList.find((id) => id === selectedProductInfo.ProductID)
  }
  let isInvoiceMandatory = _.get(props, 'deviceInfo.ProductConfig.IsInvoiceRequired')
  if (props.isNewDevice) {
    isInvoiceMandatory = _.get(props, 'listboxdata.productvalue[0].ProductConfig.IsInvoiceRequired')
  }
  if (isInvoiceMandatory) {
    let warrantyStatus = !!_.get(props, 'deviceInfo.IsUnderWarranty')
    if (props.isNewDevice) {
      warrantyStatus = !!_.get(props, 'newDeviceData.newDeviceWarrantyDate')
    }
    isInvoiceMandatory = warrantyStatus
  }
  let uploadedInvoice = _.get(props, 'newDeviceDocsList', {})
  if (isInvoiceMandatory) {
    if (props.isNewDevice) {
      continueBtnStatus = continueBtnStatus && uploadedInvoice.imgFilePath
    } else {
      let selectedProductDocs = !!_.get(props, 'devices.productDocs', []).length
      if (!props.isInvoiceUploaded) {
        isInvoiceMandatory = isInvoiceMandatory && !selectedProductDocs
        if (isInvoiceMandatory) {
          continueBtnStatus = continueBtnStatus && uploadedInvoice.imgFilePath
        }
      }
    }
  }

  if (isNarwalUSbrandCheck) {
    continueBtnStatus = active_plan
  }

  let curdate = new Date()
  let selectedDeviceOption = selectedValue()
  const language = localStorage.getItem('languageCode')
  const locale = _.get(props, 'user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en-IN')

  return (
    <div className='request-device-selection'>
      <BoxHolder>
        <div className='device-selection'>
          {props.devices.deviceDetails?.supportedDevices && (
            <div className='device-list-input'>
              <div className='bookFontStyle fontSmall overrideCasing'>{getTranslationText('common', 'myDevice')}</div>
              <Dropdown
                id='ProductName'
                classObject={{
                  container: `device-list-selection mt8 mb8`,
                }}
                value={!_.isEmpty(selectedDeviceOption) ? selectedDeviceOption[0].value : ''}
                options={[
                  ...sortAlphabetically(formatProducts(props.devices.deviceDetails.supportedDevices, props.disableNormalRepair), 'value'),
                ]}
                placeholder={getTranslationText('common', 'selectDevice')}
                handleOnChange={(item) => {
                  props.handleDeviceSelection([item], true)
                }}
                // filterBy='value'
                searchText='Search'
                handleOnSearch={handleOnProductSearch}
                isFilteredDataRequired={false}
                showTooltip
              />
            </div>
          )}
          <div className='device-input'>
            <div className='bookFontStyle fontSmall'>{getTranslationText('common', 'category')}</div>
            <Dropdown
              id={getTranslationText('common', 'category')}
              classObject={{
                container: `mt8 mb8`,
              }}
              value={!_.isEmpty(props.listboxdata.categoryvalue) ? props.listboxdata.categoryvalue[0].value : ''}
              options={sortAlphabetically(props.listofProducts.categoryDataList, 'value')}
              placeholder={getTranslationText('addingDevices', 'selectCatg')}
              handleOnChange={(item) => {
                props.catergoryListHandler([item])
              }}
              filterBy='value'
              searchText='Search'
              isDisabled={!props.listboxdata.categoryenabled}
              showTooltip
            />
          </div>
          <div className='device-input'>
            <div className='bookFontStyle fontSmall'>{getTranslationText('common', 'brand')}</div>
            <Dropdown
              id={getTranslationText('common', 'brand')}
              classObject={{
                container: `mt8 mb8`,
              }}
              value={!_.isEmpty(props.listboxdata.brandvalue) ? props.listboxdata.brandvalue[0].value : ''}
              options={sortAlphabetically(props.listofProducts.brandDataList, 'value')}
              placeholder={getTranslationText('common', 'selectBrand')}
              handleOnChange={(item) => {
                props.brandListHandler([item])
              }}
              filterBy='value'
              searchText='Search'
              isDisabled={!props.listboxdata.brandenabled}
              showTooltip
            />
          </div>
          <div className='device-input'>
            <div className='bookFontStyle fontSmall'>{getTranslationText('common', 'productLabel')}</div>
            <div className='product-list-typeahead'>
              <Dropdown
                id={getTranslationText('common', 'productLabel')}
                classObject={{
                  container: `mt8 mb8`,
                }}
                value={!_.isEmpty(props.listboxdata.productvalue) ? props.listboxdata.productvalue[0].value : ''}
                options={sortAlphabetically(props.listofProducts.productDataList, 'value')}
                placeholder={getTranslationText('device', 'selectproduct')}
                handleOnChange={(item) => {
                  props.productListHandler([item])
                }}
                filterBy='value'
                searchText='Search'
                isDisabled={!props.listboxdata.productenabled}
                showTooltip
              />
              {props.fetchingProductLoader ? <div className='btn-level-loader' /> : ''}
            </div>
          </div>
        </div>
      </BoxHolder>
      <BoxHolder className='DeviceSubDataReq'>
        <Heading wordBreak title={getTranslationText('request', 'devicesInfo')} className={!props.isNewDevice ? 'disabledLabel' : ''} />
        <div className='device-info-wrapper mt20'>
          <Input
            inputType='text'
            value={!props.isNewDevice ? props.deviceInfo.TagName : props.newDeviceData.newDeviceTagName}
            name='device_tag_name'
            handleOnChange={props.handleNewDeviceInputChange}
            className={{
              formControl: 'device-input',
              label: 'bookFontStyle fontSmall overrideCasing ' + (!props.isNewDevice ? 'disabledLabel' : ''),
            }}
            label={getTranslationText('common', 'deviceTagLabel')}
            placeholder={getTranslationText('device', 'placeholderMyDevice')}
            isDisabled={!props.isNewDevice}
            id='device-tag-name'
          />
          {!isNarwalUSbrandCheck ? (
            <Input
              inputType='text'
              value={
                props.isNewDevice || props.uniqueIdrequired
                  ? props.newDeviceData.newDeviceSerialNo
                  : props.isAnAppleDevice
                  ? props.deviceInfo.AlternateUniqueKey
                  : props.deviceInfo.DownloadedDeviceUniqueKey
              }
              handleOnChange={props.handleNewDeviceInputChange}
              className={{
                formControl: 'device-input txt_upperCase',
                label:
                  'bookFontStyle fontSmall overrideCasing ' +
                  (props.isNewDevice || (props.isAnAppleDevice && isFieldEditable) || isFieldEditable ? '' : 'disabledLabel'),
              }}
              label={
                props.isAnAppleDevice
                  ? props.mobileCategory
                    ? appDeviceSerialLabel
                    : `${getTranslationText('common', 'serialNumber')}`
                  : getTranslationText('common', orderCheckDetails ? 'orderNumber' : 'serialNumber') +
                    (props.uniqueIdrequired && !props.mobileCategory ? '' : ` ${getTranslationText('common', 'optional')}`)
              }
              placeholder={getTranslationText('device', 'placeholderSerialNo')}
              isDisabled={!(props.isNewDevice || (props.isAnAppleDevice && isFieldEditable) || isFieldEditable)}
              id='device-serial-no'
            />
          ) : (
            ''
          )}

          {!isNarwalUSbrandCheck && orderCheckDetails ? (
            <div className={'super-text'} onClick={props.toggleOrdersInstr}>
              {getTranslationText('common', 'Help')}?
            </div>
          ) : (
            ''
          )}

          {!isNarwalUSbrandCheck && props.isAnAppleDevice && !props.isNewDevice && _.get(props, 'deviceInfo.ProductUniqueID') ? (
            <Input
              inputType='text'
              value={props.deviceInfo.ProductUniqueID}
              className={{
                formControl: 'device-input txt_upperCase',
                label: 'bookFontStyle fontSmall overrideCasing disabledLabel',
              }}
              label={`${getTranslationText('device', 'imei1')}`}
              placeholder={getTranslationText('device', 'placeholderImei')}
              isDisabled={true}
            />
          ) : (
            ''
          )}
          {isNarwalUSbrandCheck || props.isAnAppleDevice || !props.mobileCategory ? (
            ''
          ) : (
            <div className='device-input'>
              <Input
                inputType='text'
                value={
                  props.isNewDevice || props.uniqueIdrequired ? props.newDeviceData.newDeviceImei1No : props.deviceInfo.ProductUniqueID
                }
                className={{
                  formControl: 'txt_upperCase',
                  label: `bookFontStyle fontSmall overrideCasing ${isFieldEditable ? `` : `disabledLabel`}`,
                }}
                label={`${getTranslationText('device', 'imei1')} ${
                  props.uniqueIdrequired ? '' : ` ${getTranslationText('common', 'optional')}`
                }`}
                placeholder={getTranslationText('device', 'placeholderImei')}
                isDisabled={!isFieldEditable}
                id='device-imei1-no'
                handleOnChange={props.handleNewDeviceInputChange}
              />

              {props.isInvalidIMEI && appConfigDetails && appConfigDetails.showContactUsOption && appConfigDetails.contactUsLink ? (
                <div className='invalid-imei-error mb20 boldFontStyle'>
                  {getTranslationText('common', 'forSupportClick')}
                  <a href={appConfigDetails.contactUsLink} target='_blank' rel='noreferrer'>
                    {` ${getTranslationText('common', 'here')}`}
                  </a>
                </div>
              ) : (
                ''
              )}
            </div>
          )}

          {isNarwalUSbrandCheck ? (
            <div className='device-input'>
              <Input
                inputType='text'
                value={props.deviceInfo?.ProductUniqueID || props.newDeviceData?.newDeviceImei1No || ''}
                className={{
                  formControl: 'txt_upperCase',
                  label: `bookFontStyle fontSmall overrideCasing}`,
                }}
                label={`${getTranslationText('common', 'serialNumber')} ${!isLGbrandCheck ? ' (Robot)' : ''}`}
                placeholder={getTranslationText('device', 'placeholderImei')}
                isDisabled={!!props.deviceInfo?.ProductUniqueID || !active_plan}
                id='device-imei1-no'
                handleOnChange={props.handleNewDeviceInputChange}
              />

              {props.isInvalidIMEI && appConfigDetails && appConfigDetails.showContactUsOption && appConfigDetails.contactUsLink ? (
                <div className='invalid-imei-error mb20 boldFontStyle'>
                  {getTranslationText('common', 'forSupportClick')}
                  <a href={appConfigDetails.contactUsLink} target='_blank' rel='noreferrer'>
                    {` ${getTranslationText('common', 'here')}`}
                  </a>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
          {isNarwalUSbrandCheck ? (
            <Input
              inputType='text'
              value={props.deviceInfo?.AlternateUniqueKey || props.newDeviceData?.newDeviceImei2No || ''}
              className={{
                formControl: 'device-input txt_upperCase',
                label: `bookFontStyle fontSmall overrideCasing`,
              }}
              label={getTranslationText('common', 'serialNumber') + ' (Charging Dock)'}
              placeholder={getTranslationText('device', 'placeholderImei')}
              isDisabled={!!props.deviceInfo?.AlternateUniqueKey || !active_plan}
              id='device-imei2-no'
              handleOnChange={props.handleNewDeviceInputChange}
            />
          ) : (
            ''
          )}

          {isNarwalUSbrandCheck || props.isAnAppleDevice || !props.mobileCategory ? (
            ''
          ) : (
            <Input
              inputType='text'
              value={props.isNewDevice ? props.newDeviceData.newDeviceImei2No : props.deviceInfo.AlternateUniqueKey}
              className={{
                formControl: 'device-input txt_upperCase',
                label: `bookFontStyle fontSmall overrideCasing ${props.isNewDevice ? `` : `disabledLabel`}`,
              }}
              label={`${getTranslationText('device', 'imei2')}`}
              placeholder={getTranslationText('device', 'placeholderImei')}
              isDisabled={!props.isNewDevice}
              id='device-imei2-no'
              handleOnChange={props.handleNewDeviceInputChange}
            />
          )}
        </div>
        <div className='mt20'>
          {isNarwalUSbrandCheck || (props.isAnAppleDevice && props.isNewDevice) ? (
            ''
          ) : (
            <div className={'col-sm-12 ' + (!props.isNewDevice ? 'disabledLabel' : '')}>
              <div className={'bookFontStyle fontSmall mb8 ' + (!props.isNewDevice ? 'disabledLabel' : '')}>
                {getTranslationText('common', 'isDeviceWarranty')}
              </div>
              <Switch
                id='device-warranty'
                disabled={!props.isNewDevice}
                checked={
                  _.get(props, 'listboxdata.categoryvalue', []).length
                    ? _.get(props, 'listboxdata.productenabled')
                      ? _.get(props, 'listboxdata.productvalue', []).length
                        ? props.deviceRadiobtnvalue
                        : null
                      : props.deviceInfo.IsUnderWarranty
                    : null
                }
                otherProps={{ onChange: props.isNewDevice ? props.handleNewDeviceInputChange : () => {} }}
              />
            </div>
          )}
          {!isNarwalUSbrandCheck && props.deviceInfo.WarrantyTill && props.deviceRadiobtnvalue && !props.isAnAppleDevice ? (
            <div className={'col-sm-12 mb8 bookFontStyle fontSmall addDevice-input-field ' + (!props.isNewDevice ? 'disabledLabel' : '')}>
              {getTranslationText('common', 'warrantyTillDate')} &nbsp;
              <span className='bookFontStyle'>{getLocaleDate(dateFormat, props.deviceInfo.WarrantyTill)}</span>
            </div>
          ) : (
            ''
          )}
          {props.isNewDevice && props.deviceRadiobtnvalue && !props.isAnAppleDevice ? (
            <div className='col-sm-12 mb28 mediumFontStyle fontSmall addDevice-input-field'>
              <DatePicker
                placeholder={dateFormat}
                format={dateFormat.toLowerCase()}
                maxDay={new Date(curdate.getFullYear() + 10, curdate.getMonth(), curdate.getDate())}
                minDay={new Date()}
                handleOnChange={(date) => props.handleDatePickerOnChange(date, 'add-warranty-date')}
                date={props.newDeviceData.newDeviceWarrantyDate || ''}
                locale={locale}
                language={language}
              ></DatePicker>
            </div>
          ) : (
            ''
          )}
          {isInvoiceMandatory ? (
            <div className='col-sm-12'>
              <div className='row'>
                <div className='col-sm-12 m5'>{getTranslationText('request', 'uploadInvoice')}</div>
                <div className='col-3 img-min-width'>
                  <BoxImagesComponent
                    loaderState={false}
                    isUploaded={uploadedInvoice.imgFilePath}
                    imgFilePath={uploadedInvoice.imgFilePath || <AddIcon />}
                    imageID={uploadedInvoice.imageID}
                    imgViewerHandler={() =>
                      props.setDocViewerObject({
                        FilePath: uploadedInvoice.imgFilePath,
                        uniqueId: uploadedInvoice.imageID,
                        showRemoveBtn: true,
                        isAdditionalUpload: false,
                      })
                    }
                    inputName='deviceDocs-upload'
                    inputId='upload-img-ip-id'
                    fileUploadHander={props.handleInvoiceUpload}
                  />
                </div>
              </div>
              <div className='col-sm-12 bookFontStyle fontSmall mt8 Note_Text'>{getTranslationText('device', 'deviceDocUploadNote')}</div>
            </div>
          ) : (
            ''
          )}
          <div className='mt20 buttons-container'>
            <MediumButton
              className='button-small button-ghost'
              buttonText={getTranslationText('common', 'backLabel')}
              onClick={() => {
                props.handleBackButton()
                pushToAnalytics('Device_selected_back_button_clicked', {
                  ConsumerID: _.get(props, 'request.selectedDeviceInfo.ConsumerID', ''),
                  ConsumerProductID: _.get(props, 'request.selectedDeviceInfo.ConsumerProductID', ''),
                })
              }}
            />
            <MediumButton
              className='button-small'
              buttonText={getTranslationText('common', 'continue')}
              onClick={() => {
                props.setSelectedDeviceID()
                const productData = _.get(props, 'request.selectedDeviceInfo', {})
                pushToAnalytics('Device_selected_continue_button_clicked', {
                  Device_Name: productData.Product.ProductName || '',
                  Category: productData.ProductSubCategory.ProductSubCategory || '',
                  Brand: productData.Brand.BrandName || '',
                  Product: productData.Product.ProductID || '',
                  ConsumerProductID: productData.ConsumerProductID || '',
                  ConsumerID: productData.ConsumerID || '',
                  Serial_No: productData.Product.ProductName || '',
                  Device_Tag: productData.TagName || '',
                })
              }}
              btnLoader={props.BtnStatusLoader}
              disabled={!continueBtnStatus}
            />
          </div>
        </div>
      </BoxHolder>
    </div>
  )
}

export class OrderListComponent extends React.Component {
  constructor(props) {
    super(props)
    let selectedOrder = _.get(this, 'props.request.selectedOrderDetails', {})
    let orderList = []
    let selectedItemList = []
    _.get(selectedOrder, 'itemList', []).map((item) => {
      if (item.isSelected) {
        selectedItemList.push(item.ConsumerProductID)
      }
    })
    let isRefundType = _.get(this, 'props.location.query.type', '') === 'refund'
    _.get(this, 'props.devices.deviceDetails.ordersList', []).filter((order) => {
      let itemList = []
      order.itemList
        .filter((item) => !item.ConsumerServiceRequestID)
        .filter((item) => {
          if (isRefundType) {
            if (item.showRefundOption) {
              itemList.push({
                ...item,
                isSelected: !!selectedItemList.includes(item.ConsumerProductID),
              })
            }
          } else {
            if (item.showReplacementOption) {
              itemList.push({
                ...item,
                isSelected: !!selectedItemList.includes(item.ConsumerProductID),
              })
            }
          }
        })
      if (itemList && itemList.length) {
        orderList.push({
          OrderNo: order.OrderNo,
          DateOfPurchase: order.DateOfPurchase,
          itemList,
          isSelected: !itemList.find((item) => !item.isSelected),
        })
      }
    })
    let nonOrderDevices = _.compact(
      _.get(this, 'props.devices.deviceDetails.supportedDevices', []).map((device) => {
        if (
          !device.OrderNo &&
          !device.ConsumerServiceRequestID &&
          (isRefundType ? device.showRefundOption : device.showReplacementOption)
        ) {
          let isDeviceSelected = !!selectedItemList.includes(device.ConsumerProductID)
          return {
            OrderNo: device.ConsumerProductID,
            DateOfPurchase: device.DateOfPurchase,
            itemList: [{ ...device, isSelected: isDeviceSelected }],
            isSelected: isDeviceSelected,
            isNotanOrder: true,
          }
        }
      })
    )
    this.state = {
      orderList: [...orderList, ...nonOrderDevices],
    }
  }

  componentDidMount() {
    let cpID = this.state.orderList.find((order) => order.itemList.find((item) => item.isSelected))
    if (cpID) {
      // has a selected order
      let OrderNo = cpID.itemList[0].OrderNo
      let ele = document.getElementById('order-' + OrderNo)
      if (ele) {
        let pos = getElementPosition(ele)
        window.scrollTo(pos.x, pos.y - 200)
      }
    }
  }

  handleItemSelection = (selectedItem) => {
    let selectionConstraint = _.get(this, 'props.user.appConfigDetails.MaxLimitForRequests')
    this.setState({
      orderList: this.state.orderList.map((order) => {
        let updatedItems = order.itemList.map((item) => {
          return {
            ...item,
            isSelected:
              selectedItem.ConsumerProductID === item.ConsumerProductID
                ? !item.isSelected
                : selectedItem.OrderNo === order.OrderNo
                ? item.isSelected
                : false,
          }
        })
        let selectedItems = updatedItems.filter((info) => info.isSelected)
        if (selectionConstraint && selectedItems.length > selectionConstraint) {
          this.props.setToaster(
            getTranslationText('error', 'cannotSelect') + selectionConstraint + ` ${getTranslationText('common', 'products')}`,
            'error'
          )
          return {
            ...order,
            itemList: order.itemList,
            isSelected: !!order.itemList.find((item) => item.isSelected),
          }
        } else {
          return {
            ...order,
            itemList: updatedItems,
            isSelected: !!updatedItems.find((item) => item.isSelected),
          }
        }
      }),
    })
  }

  render() {
    let productImageUrl = this.props.user.appConfigDetails.productImageUrl
    const dateFormat = _.get(this, 'props.selectedCountryData.countryConfig.DateFormat.DEFAULT', 'DD/MM/YYYY')
    return (
      <div className='order-wrapper'>
        {this.state.orderList.length &&
          this.state.orderList.map((orderInfo, index) => {
            let selectedOrderBtnStatus = orderInfo.itemList.find((item) => item.isSelected)
            return (
              <div key={index} className='order-section-container mb28'>
                {!orderInfo.isNotanOrder && (
                  <BoxHolder>
                    <div className='order-info'>
                      <div>
                        {getTranslationText('common', 'orderNumber')}
                        <br />
                        <span className='mediumFontStyle'>{orderInfo.OrderNo}</span>
                      </div>
                      <div>
                        {getTranslationText('common', 'date')}
                        <br />
                        <span className='mediumFontStyle'>{getLocaleDate(dateFormat, orderInfo.DateOfPurchase)}</span>
                      </div>
                    </div>
                    <div className={orderInfo.isSelected ? 'active-order' : ''} />
                  </BoxHolder>
                )}
                <div className='selected-order-info'>
                  {orderInfo.itemList.map((item, index) => (
                    <BoxHolder key={index}>
                      <div className='row' onClick={() => this.handleItemSelection(item)} id={'order-' + item.OrderNo}>
                        <div className='col-sm-1 item-selector-ckbox'>
                          <Checkbox type='checkbox' value={item.isSelected} handleOnChange={() => this.handleItemSelection(item)} />
                        </div>
                        <div className='col-sm-2 item-img-wrapper'>
                          <img src={productImageUrl + item.ProductSubCategoryID + '.png'} />
                        </div>
                        <div className='col-sm item-info-wrapper'>
                          <div className='row'>
                            <div className='col-sm-4 col-6'>
                              <div>
                                {getTranslationText('request', 'itemName')}
                                <br />
                                <span className='mediumFontStyle'>{item.Product.ProductName}</span>
                              </div>
                            </div>
                            <div className='col-sm-4 col-6'>
                              <div>
                                {getTranslationText('request', 'productType')}
                                <br />
                                <span className='mediumFontStyle'>{item.ProductSubCategory.ProductSubCategory}</span>
                              </div>
                            </div>
                            <div className='col-sm-4 col-6'>
                              <div>
                                {getTranslationText('device', 'imei')}
                                <br />
                                <span className='mediumFontStyle'>{item.ProductUniqueID || '-'}</span>
                              </div>
                            </div>
                            {/*
                          <div className='col-sm-4 col-6'>
                            <div>{getTranslationText('request', 'itemPrice')}<br />
                              {currencySign && <span dangerouslySetInnerHTML={{ __html: currencySign }} />}
                              <span className='mediumFontStyle'>40000</span>
                            </div>
                          </div>
                          */}
                          </div>
                        </div>
                      </div>
                    </BoxHolder>
                  ))}
                  <BoxHolder>
                    <div className='textRight'>
                      <MediumButton
                        buttonText={getTranslationText('request', 'returnSelected')}
                        disabled={!selectedOrderBtnStatus}
                        onClick={() => this.props.initOrderQuery(orderInfo)}
                      />
                    </div>
                  </BoxHolder>
                </div>
              </div>
            )
          })}
      </div>
    )
  }
  static propTypes = {
    initOrderQuery: PropTypes.func.isRequired,
    user: PropTypes.func.isRequired,
    setToaster: PropTypes.func,
  }
}

export class ReturnOrderInfo extends React.Component {
  constructor(props) {
    super(props)
    window.scrollTo(0, 0)
    let selectedList = _.get(this, 'props.selectedOrderDetails.itemList', [])
    this.state = {
      selectedItemList:
        selectedList.map((item, index) => {
          return {
            ...item,
            returnDescption: item.returnDescption || '',
            uploadedImageUrl: item.uploadedImageUrl,
            showCompleteInfo: selectedList.length && item.uploadedImageUrl ? selectedList.length - 1 === index : index === 0,
            reasonsList: item.reasonsList
              ? item.reasonsList
              : this.props.request.returnReasons.map((reason, idx) => ({
                  ...reason,
                  id: idx,
                  text: reason.ReturnReason || reason.Reason,
                  isSelected: reason.isSelected,
                })),
          }
        }) || [],
    }
  }

  componentDidMount = () => {
    if (!_.get(this, 'state.selectedItemList', []).length) {
      browserHistory.replace({
        pathname: '/request/select-order',
        query: this.props.location.query,
      })
    }
  }

  handleReasonSelection = (eventID) => {
    let itemID = parseInt(eventID.currentTarget.id.split('-')[1])
    let reasonID = parseInt(eventID.currentTarget.id.split('-')[2])
    this.setState({
      selectedItemList: this.state.selectedItemList.map((item) => {
        if (item.ConsumerProductID === itemID) {
          let resp = { ...item }
          let updatedReasonList = item.reasonsList.map((reason) => ({
            ...reason,
            isSelected: reason.id === reasonID ? !reason.isSelected : reason.isSelected,
          }))
          let selectedReasons = updatedReasonList.filter((reason) => reason.isSelected) || []
          if (selectedReasons.length > 4) {
            this.props.setToaster(getTranslationText('request', 'selectMaxIssues'), 'error')
            return {
              ...resp,
              reasonsList: item.reasonsList,
            }
          } else {
            return {
              ...item,
              returnDescption: selectedReasons.some((item) => item.text.toLowerCase() === 'others') ? item.returnDescption : '',
              // isReasonSelected: reasonID,
              reasonsList: updatedReasonList,
            }
          }
        }
        return item
      }),
    })
  }

  uploadItemImage = (fileObj, selectedItem) => {
    this.props.setglobalLoaderStatus(true)
    this.props.handleInvoiceUpload(fileObj, (uploadedUrl) => {
      this.props.setglobalLoaderStatus(false)
      this.setState({
        selectedItemList: this.state.selectedItemList.map((item) => ({
          ...item,
          uploadedImageUrl: item.ConsumerProductID === selectedItem.ConsumerProductID ? uploadedUrl : item.uploadedImageUrl,
        })),
      })
    })
  }

  returnInCompleteItem = () => {
    let idx = -1
    this.state.selectedItemList.find((item, index) => {
      if (item.isReasonSelected < 0 || !item.uploadedImageUrl) {
        idx = index
      }
    })
    return idx
  }

  handleItemStatus = (itemIdx) => {
    this.props.setSelectedOrder({
      ...this.props.selectedOrderDetails,
      itemList: this.props.selectedOrderDetails.itemList.map((item, index) => {
        if (index === itemIdx) {
          return {
            ...this.state.selectedItemList[itemIdx],
          }
        } else {
          return item
        }
      }),
    })
    this.setState(
      {
        selectedItemList: this.state.selectedItemList.map((item, index) => ({
          ...item,
          showCompleteInfo: this.returnInCompleteItem() === index,
        })),
      },
      () => {
        window.scrollTo(0, 0)
        if (this.returnInCompleteItem() < 0) {
          browserHistory.push({
            pathname: '/request/modes',
            query: this.props.location.query,
          })
        }
      }
    )
  }

  handleItemDropDown = (selectedItem) => {
    this.setState({
      selectedItemList: this.state.selectedItemList.map((item, index) => {
        let selectedItmIdx = item.ConsumerProductID === selectedItem.ConsumerProductID ? index : undefined
        return {
          ...item,
          showCompleteInfo: index === selectedItmIdx,
        }
      }),
    })
  }

  handleDescripOnChange = (e, selectedItem) => {
    let value = e.target.value
    if (!value || regexCheck('onChange', 'plainTxt', value, true)) {
      this.setState({
        selectedItemList: this.state.selectedItemList.map((item) => ({
          ...item,
          returnDescption: selectedItem.ConsumerProductID === item.ConsumerProductID ? value : item.returnDescption,
        })),
      })
    }
  }

  removeUploadReturnImage = (cpid) => {
    this.setState({
      selectedItemList: this.state.selectedItemList.map((item) => ({
        ...item,
        uploadedImageUrl: item.ConsumerProductID === cpid ? undefined : item.uploadedImageUrl,
      })),
    })
  }

  render() {
    let productImageUrl = this.props.user.appConfigDetails.productImageUrl
    return (
      <div className='return-replace-order-info row'>
        {this.state.selectedItemList.length &&
          this.state.selectedItemList.map((item, index) => {
            let isOtherReasonSelected = false
            let selectedReasonList = item.reasonsList.filter((val) => {
              if (val.isSelected) {
                if (val.text.toLowerCase() === 'others') {
                  isOtherReasonSelected = true
                }
                return val
              }
            })
            let btnStatus = selectedReasonList.length && item.uploadedImageUrl
            if (isOtherReasonSelected) {
              btnStatus = btnStatus && regexCheck('getValue', 'characters', item.returnDescption || '').length >= 50
            }
            if (item.showCompleteInfo || (selectedReasonList.length && item.uploadedImageUrl)) {
              return (
                <div className='col-sm-12 mb20' key={index}>
                  <BoxHolder className='item-list-section'>
                    <div className='img-wrapper'>
                      <img src={productImageUrl + item.ProductSubCategoryID + '.png'} />
                    </div>
                    {item.Product.ProductName && (
                      <div className='item-info-wrapper'>
                        {getTranslationText('common', 'productName')}
                        <div className='mediumFontStyle'>{item.Product.ProductName}</div>
                      </div>
                    )}
                    {item.ProductUniqueID && (
                      <div className='item-unique-info-wrapper'>
                        {getTranslationText('device', 'imei')}
                        <div className='mediumFontStyle'>{item.ProductUniqueID || '-'}</div>
                      </div>
                    )}
                    {selectedReasonList.length && item.uploadedImageUrl && !item.showCompleteInfo ? (
                      <div className='item-return-reason-content'>
                        <div>
                          {getTranslationText('request', 'selectedReason')}
                          <br />
                          <span className='mediumFontStyle'>
                            {selectedReasonList.map((reason, idx) =>
                              reason.text.toLowerCase() === 'others'
                                ? (idx !== 0 ? ' | ' : '') + item.returnDescption
                                : (idx !== 0 ? ' | ' : '') + reason.text
                            )}
                          </span>
                        </div>
                        {/* <img src={downArrow} onClick={() => this.handleItemDropDown(item)} /> */}
                        <div onClick={() => this.handleItemDropDown(item)}>
                          <DownArrow />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </BoxHolder>
                  {item.showCompleteInfo && (
                    <BoxHolder>
                      <div className='row return-info-wrapper'>
                        <div className='col-sm-12 mb20'>
                          <div className='reasons-container fontMedium'>
                            <div className='mediumFontStyle mb8'>{getTranslationText('request', 'returnReason')}</div>
                            <div className='reasons-list-wrapper'>
                              <PillGroup
                                handlePillSelect={this.handleReasonSelection}
                                prefix={'reason-' + item.ConsumerProductID}
                                pills={item.reasonsList}
                              />
                            </div>
                          </div>
                        </div>
                        {isOtherReasonSelected ? (
                          <div className='col-sm-12'>
                            <div className='mediumFontStyle mb8'>{getTranslationText('request', 'returnDescription')}</div>
                            <textarea
                              placeholder='minimum 50 characters'
                              value={item.returnDescption}
                              onChange={(e) => this.handleDescripOnChange(e, item)}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                        <div className='col-sm-12 mt28'>
                          <div className='mediumFontStyle mb8'>{getTranslationText('request', 'uploadImages')}</div>
                          <BoxImagesComponent
                            loaderState={false}
                            isUploaded={!!item.uploadedImageUrl}
                            imgFilePath={item.uploadedImageUrl || <AddIcon />}
                            inputName='device-images'
                            imgViewerHandler={() => {
                              this.props.setDocViewerObject({
                                FilePath: item.uploadedImageUrl,
                                itemId: item.ConsumerProductID,
                                showRemoveBtn: true,
                                removeImgFunc: () => {
                                  this.removeUploadReturnImage(item.ConsumerProductID)
                                },
                              })
                            }}
                            imageID={item.ConsumerProductID}
                            fileUploadHander={(fileObj) => this.uploadItemImage(fileObj, item)}
                          />
                        </div>
                        <div className='col-sm-12 mt28 buttons-container'>
                          <MediumButton
                            className='button-small button-ghost'
                            buttonText={getTranslationText('common', 'backLabel')}
                            onClick={this.props.handleBackButton}
                          />
                          <MediumButton
                            className='button-small'
                            buttonText={getTranslationText('common', 'continue')}
                            onClick={() => this.handleItemStatus(index)}
                            disabled={!btnStatus}
                          />
                        </div>
                      </div>
                    </BoxHolder>
                  )}
                </div>
              )
            }
            return null
          })}
      </div>
    )
  }

  static propTypes = {
    user: PropTypes.func.isRequired,
    request: PropTypes.object.isRequired,
    handleBackButton: PropTypes.func.isRequired,
    setDocViewerObject: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    handleInvoiceUpload: PropTypes.func.isRequired,
    setSelectedOrder: PropTypes.func.isRequired,
    selectedOrderDetails: PropTypes.object.isRequired,
    setglobalLoaderStatus: PropTypes.func.isRequired,
    setToaster: PropTypes.func.isRequired,
  }
}

export const RequestModes = (props) => {
  useEffect(() => {
    pushToAnalytics(props.modeSelectStep === 2 ? 'Shipping_location_page' : 'service_options_page', {
      ConsumerID: _.get(props, 'request.selectedDeviceInfo.ConsumerID', ''),
      ConsumerProductID: _.get(props, 'request.selectedDeviceInfo.ConsumerProductID', ''),
    })
  })
  return (
    <div className='request-modes row'>
      <div className='col-sm-12'>
        <BoxHolder className='address-box-container fixed-height-override'>
          <div className='row'>
            <div className={'col-sm-6 offset-sm-3 mb20 ' + (props.location.query.changeMode ? 'disable-block' : '')}>
              <AddressBox
                handleDetectClick={props.handleDetectClick}
                handleTypeaheadSelect={props.handleTypeaheadSelect}
                placesAutocomplete={props.placesAutocomplete}
                customLocations={props.user.locations}
                onChangeTypeahead={props.onChangeTypeahead}
                handleUserAddressClick={props.handleUserAddressClick}
                isLoaderSet={props.isLoaderSet}
                showDetect
                user={props.user}
                additionalInfo={getTranslationText('request', 'serviceOptionInfo')}
              />
            </div>
          </div>
        </BoxHolder>
      </div>
      <div className='col-sm-12'>
        <RequestModeSteps {...props} />
      </div>
    </div>
  )
}

RequestModes.propTypes = {
  locationObtained: PropTypes.any,
  placesAutocomplete: PropTypes.object.isRequired,
  handleTypeaheadSelect: PropTypes.func.isRequired,
  onChangeTypeahead: PropTypes.func.isRequired,
  handleDetectClick: PropTypes.func.isRequired,
  handleUserAddressClick: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
  isLoaderSet: PropTypes.bool.isRequired,
}

export const RequestModeSteps = (props) => {
  if (props.modeSelectStep === 1) {
    let timings = _.get(props, 'request.requestModesData.ContactDetails.WorkingTime', '')
    timings = timings && timings.split('\n')
    let addressConfig = _.get(props, 'user.countryAddressConfig.addressConfig', fallbackAddressConfig)
    const isStateMendatory = addressConfig.some((v) => v['keyName'] === 'State' && v['mandatory'])
    const showAdminFeesWithCurrency = _.get(props, 'user.appConfigDetails.showAdminFeesWithCurrency', false)
    return (
      <BoxHolder className='request-box-override fixed-height-override'>
        <div className='row'>
          <div className='col-sm-12'>
            {Object.keys(_.get(props, 'request.userLocation', {})).length ? (
              <div className='mode-container'>
                <Loader loaderState={props.requestModeListLoader}>
                  <div className='mode-options mb8'>
                    {Object.keys(_.get(props, 'request.requestModesData', {})).length &&
                    props.request.requestModesData.isServiceable === true &&
                    !props.requestModeListLoader ? (
                      <div className='mode-item-container'>
                        {props.request.requestModesData.modes.map((mode) => {
                          if (
                            mode.ServiceTypeID === 65 &&
                            !_.get(props, 'request.advanceExchangeDetails.isAdvanceExchangeApplicable', false)
                          ) {
                            return null
                          }
                          return (
                            <div
                              id={'radio-' + mode.ServiceTypeID}
                              key={mode.ServiceTypeID}
                              className={
                                (props.request.requestModesData.brand.BrandID == 4 &&
                                  mode.ServiceTypeID == 2 &&
                                  _.get(props, 'user.selectedCountryData.CountryCode') === 'IN') ||
                                mode.IsAvailable === false
                                  ? 'disabledDiv'
                                  : ''
                              }
                              onClick={mode.IsAvailable === false ? null : props.handleModeSelect}
                            >
                              <RadioBox
                                isSelected={
                                  !(
                                    props.request.requestModesData.brand.BrandID == 4 &&
                                    mode.ServiceTypeID == 2 &&
                                    _.get(props, 'user.selectedCountryData.CountryCode') === 'IN'
                                  ) && mode.ServiceTypeID === props.selectedMode
                                }
                                key={mode.ServiceTypeID}
                                id={'radio-' + mode.ServiceTypeID}
                              >
                                <>
                                  <div className='option-text'>
                                    <div className='text-heading mb8 boldFontStyle'>{mode.Type}</div>
                                    <div className='text-content mb8 bookFontStyle'>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: `${mode.Description} ${
                                            [17, 23].includes(mode.ServiceTypeID) &&
                                            _.get(props, 'request.emptyBoxServiceable.isEmptyBoxServiceable')
                                              ? getTranslationText('request', 'emptyBox')
                                              : ''
                                          }`,
                                        }}
                                      />
                                    </div>

                                    {showAdminFeesWithCurrency && mode.adminFeesWithCurrency ? (
                                      <div className='text-content mb8 bookFontStyle'>
                                        {`${getTranslationText('request', 'modeAdminFee')} ${mode.adminFeesWithCurrency}`}
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    {[2, 17, 23, 57, 58].includes(mode.ServiceTypeID) &&
                                    props.request.requestModesData.serviceLocations &&
                                    props.request.requestModesData.serviceLocations.length > 0 ? (
                                      <div className='text-content boldFontStyle overrideCasing'>
                                        {mode.ServiceTypeID === 2
                                          ? props.request.requestModesData.serviceLocations.length +
                                            ` ${getTranslationText('request', 'serviceCenters')} 
                                            ${getTranslationText('request', 'foundNearYou')}`
                                          : ''}
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </>
                              </RadioBox>
                            </div>
                          )
                        })}
                      </div>
                    ) : Object.keys(_.get(props, 'request.requestModesData', {})).length && !props.requestModeListLoader ? (
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='text-content mb8 bookFontStyle'>
                            {_.get(
                              props,
                              'request.requestModesData.NoServiceMessage',
                              getTranslationText('request', 'splitStringRequest2')
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </Loader>
              </div>
            ) : (
              <div className='row'>
                {!_.get(props, 'user.appConfigDetails.hideServiceCenterDetails', false) &&
                _.get(props, 'request.requestModesData.ContactDetails', false) ? (
                  <div className={`contact-container ` + window.isDirectionRTL ? 'mr28' : ''}>
                    <div className='mode-label mb8'>
                      <span>{props.request.requestModesData.ContactDetails.Name}</span>
                      {_.compact(_.get(props, 'request.requestModesData.ContactDetails.EmailID', [])).length > 0 &&
                        props.request.requestModesData.ContactDetails.EmailID.map((brandEmail, index) => {
                          return (
                            <div
                              onClick={() => {
                                window.location.href = 'mailto:' + brandEmail
                              }}
                              key={index}
                              className='bookFontStyle fontSmall mt8 special-link-txt'
                            >
                              <Email />
                              <span>{brandEmail}</span>
                            </div>
                          )
                        })}
                      <div className='bookFontStyle fontSmall mt8 special-link-txt-no-cursor'>
                        {_.compact(_.get(props, 'request.requestModesData.ContactDetails.ContactNumber', [])).length > 0 &&
                          props.request.requestModesData.ContactDetails.ContactNumber.map((brandNumber, index) => {
                            return (
                              <div key={index}>
                                <Phone />
                                <span dir='ltr'>{brandNumber}</span>
                              </div>
                            )
                          })}
                      </div>
                      {timings.length > 0 && (
                        <div className={'bookFontStyle fontSmall mt8  ml20 paragraph'}>
                          {timings.map((part, index) => {
                            return <p key={index}>{part}</p>
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            )}
          </div>
          {Object.keys(_.get(props, 'request.requestModesData', {})).length &&
          _.get(props, 'user.appConfigDetails').hasOwnProperty('standardChargesData') ? (
            <div className='col-sm-12'>
              Standard{' '}
              <span className='special-link-txt text-underline' onClick={props.toggleRepairStandardCharges}>
                Charges
              </span>{' '}
              Applied.
            </div>
          ) : (
            ''
          )}
          <div className='col-sm-12'>
            <div className='buttons-container'>
              <MediumButton
                className='button-small button-ghost'
                id='back'
                onClick={() => {
                  props.handleBackButton()
                  pushToAnalytics('service_options_back_button_clicked', {
                    ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
                    ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
                  })
                }}
                buttonText={getTranslationText('common', 'backLabel')}
              />
              <MediumButton
                className='button-small'
                id={getTranslationText('common', 'continue')}
                onClick={() => {
                  props.handleModeSelectionContinue()
                  const selectedMode = _.get(this, 'props.request.selectedMode', {})
                  pushToAnalytics('service_options_continue_button_clicked', {
                    service_type: selectedMode.ServiceTypeCode || '',
                    ServiceTypeID: selectedMode.ServiceTypeID || '',
                    Category: selectedMode.Category || '',
                    zip_code: _.get(this, 'props.request.userLocation.Zipcode', _.get(this, 'props.request.userLocation.PinCode', '')),
                    ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
                    ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
                  })
                }}
                disabled={
                  (isStateMendatory && !_.get(props, 'request.userLocation.StateID', false)) ||
                  !props.locationObtained ||
                  !props.selectedMode ||
                  (_.get(props, 'request.requestModesData.brand.BrandID', '') == 4 &&
                    props.selectedMode == 2 &&
                    _.get(props, 'user.selectedCountryData.CountryCode') === 'IN')
                }
                buttonText={getTranslationText('common', 'continue')}
              />
            </div>
          </div>
        </div>
      </BoxHolder>
    )
  } else if (props.modeSelectStep === 2) {
    return (
      <BoxHolder className='request-box-override fixed-height-override'>
        {_.includes([66], props.selectedMode) && (
          <div className='row background-grey m1rem'>
            <div className='col-md-3 col-sm-12'>
              <div className='fontSmall lightFontStyle mb4'>{getTranslationText('request', 'replacementDevice')}</div>
              <div>{_.get(props, 'request.selectedReplacementOption.ProductName', '')}</div>
            </div>
            {_.includes(
              [12, 13],
              _.get(props, 'track.trackDetails.productSubcategory.ProductSubCategoryID', '') ||
                _.get(props, 'request.selectedDeviceInfo.ProductSubCategory.ProductSubCategoryID')
            ) ? (
              <div className='col-md-3 col-sm-12'>
                <div className='fontSmall lightFontStyle mb4'>{getTranslationText('device', 'deviceDetails')}</div>
                <div>
                  {`${_.get(props, 'request.selectedReplacementOption.ProductSkuAttributes.RAM', '')} 
                      ${_.get(props, 'request.selectedReplacementOption.ProductSkuAttributes.Storage', '')}, 
                      ${_.get(props, 'request.selectedReplacementOption.ProductSkuAttributes.Colour', '')}`}
                </div>
              </div>
            ) : (
              ''
            )}
            {_.get(props, 'request.selectedReplacementOption.ProductSkuAttributes.Carrier', '') ? (
              <div className='col-md-3 col-sm-12'>
                <div className='fontSmall lightFontStyle mb4'>{getTranslationText('common', 'carrier')}</div>
                <div className=''>{_.get(props, 'request.selectedReplacementOption.ProductSkuAttributes.Carrier', '')}</div>
              </div>
            ) : (
              ''
            )}
          </div>
        )}

        <div className={'row ' + (!props.locationObtained || !props.request.requestModesData ? 'disabledDiv' : '')}>
          <div className='col-sm-12'>{props.request.requestModesData ? <LocationSelectorForMode {...props} /> : ''}</div>
        </div>
      </BoxHolder>
    )
  } else {
    return null
  }
}

RequestModeSteps.propTypes = {
  modeSelectStep: PropTypes.number.isRequired,
  request: PropTypes.object.isRequired,
  selectedMode: PropTypes.number,
  locationObtained: PropTypes.bool.isRequired,
  handleModeSelect: PropTypes.func.isRequired,
  handleBackButton: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
  handleReplacement: PropTypes.func.isRequired,
  requestModeListLoader: PropTypes.bool,
  BtnStatusLoader: PropTypes.bool,
  user: PropTypes.object.isRequired,
}

export function AvailDaysComponent(props) {
  let daysList = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
  let workingDays = props.split(',').map((day) => day.toLowerCase())
  let availableDays = []
  daysList.map((day) => {
    let dayOn = workingDays.indexOf(day) > -1 ? '' : 'offDay'
    availableDays.push(
      <span className={dayOn} key={day}>
        {day.charAt(0)}
      </span>
    )
  })
  return availableDays
}

export const LocationSelectorForMode = (props) => {
  let locationArray = []
  const locale = _.get(props, 'user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
  if (_.includes([12, 2], props.selectedMode)) {
    locationArray = props.request.requestModesData.serviceLocations.filter((obj) => {
      if (!!obj.ClientServiceLocationID && !_.size(obj.externalSlots)) {
        return
      } else {
        return obj
      }
    })
  } else if (_.includes([17, 23, 57, 58], props.selectedMode)) {
    locationArray = props.location.query.changeMode
      ? _.get(props, 'track.logisticsVendorDetails.dropOffLocations')
      : props.request.requestModesData.dropOffLocations
  } else if (_.includes([66], props.selectedMode)) {
    locationArray = _.get(props, 'request.selectedReplacementOption.ServiceLocations', false)
  }
  let distance = _.get(props, 'user.appConfigDetails.distances', [{ multiplier: 1, displayName: 'km' }])
  const activePSL =
    props?.selectedPartnerServiceLocation?.ClientServiceLocationID || props?.selectedPartnerServiceLocation?.PartnerServiceLocationID
  const showWalkinPromptDetails =
    _.includes([2, 12], props.selectedMode) && _.get(props, 'user.appConfigDetails.showWalkinPromptDetails', false)

  let divToRender
  let dropoffDiv = (
    <div className='row'>
      <div className='col-sm-12'>
        {showWalkinPromptDetails && (
          <p className='custom-heightlight-text-box'>
            <InfoAlert />
            {getTranslationText('request', 'afterSchedulingAppointmentWalkin')}
          </p>
        )}
      </div>
      <div className='col-sm-6'>
        <div className='dropoff-location-holder'>
          {locationArray.map((location, index) => {
            if (location.WorkingHours) {
              let WorkingDays = ''
              location.WorkingHours.forEach((day, index) => {
                if (day.slots.filter((slot) => slot.Active)[0]) {
                  WorkingDays += (index === 0 ? '' : ',') + day.Day
                }
              })
              location.WorkingDays = WorkingDays
            }
            let availableDays = AvailDaysComponent(location.WorkingDays || '')
            return (
              <div
                id={location.ClientServiceLocationID || location.PartnerServiceLocationID || location.ServiceLocationCode}
                className={`dropoff-location clickable 
                ${
                  activePSL && _.includes([location?.ClientServiceLocationID, location?.PartnerServiceLocationID], activePSL)
                    ? ' active-psl'
                    : ''
                } `}
                key={location.ServiceLocationCode || location.PartnerServiceLocationID}
                onClick={props.selectServiceLocation}
              >
                <div className='dropoff-location-info'>
                  <div className='d-flex mediumFontStyle fontSmall mb8'>
                    <span className='mr30'>
                      #{index + 1}. {location.NameOfFirm || location.City}
                    </span>
                    {location?.Score && location?.ScoreMeta?.Label ? (
                      <div className='d-flex fontSmall mb10'>
                        <span className='ml10 score-label' style={{ background: location?.ScoreMeta?.ColourCode || '#000' }}>
                          <div style={{ color: location?.ScoreMeta?.FontColourCode || '#000' }}>{location?.ScoreMeta?.Label}</div>
                        </span>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='bookFontStyle fontSmall mb8'>
                    <span>{location.ServiceLocationName}</span>
                  </div>
                  {_.includes([17, 23, 57, 58], props.selectedMode) && (
                    <div className='bookFontStyle fontSmall mb8'>
                      <span>{location.City}</span>
                    </div>
                  )}
                  {_.includes([66], props.selectedMode) ? (
                    <div className='bookFontStyle fontSmall mb8'>
                      <span>{`${location.address}, ${location.City || ''} ${location.State || ''}, ${location.Zipcode || ''}`}</span>
                    </div>
                  ) : (
                    <div className='bookFontStyle fontSmall mb8'>
                      <span>{location.address}</span>
                    </div>
                  )}

                  {!_.includes([66], props.selectedMode) && (
                    <>
                      <div className='mediumFontStyle fontSmall mb5 mt5'>
                        {getTranslationText('common', 'contact')}: {location.ContactNo1}
                      </div>
                      <div className='bookFontStyle mb4 working-days-component'>{availableDays}</div>
                      {location.WorkingFrom && location.WorkingTo ? (
                        <div dir='ltr' className='bookFontStyle fontSmall mb20'>
                          {getFormattedTime(location.WorkingFrom, locale) + ' - ' + getFormattedTime(location.WorkingTo, locale)}
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </div>
                <div className='dropoff-icons-container'>
                  {location.distance ? (
                    <div className='bookFontStyle mb8 distance-component'>
                      <img src={directionalArrow} />
                      <span>
                        {(distance[0].multiplier * location.distance).toPrecision(3)} {distance[0].displayName}
                      </span>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {!_.includes([66], props.selectedMode) && <DownArrow className='location-right-icon' />}
              </div>
            )
          })}
        </div>
      </div>
      {props.selectedMode === 2 || _.includes([17, 23, 57, 58], props.selectedMode) ? (
        <div className='col-sm-6'>
          <div className='map-holder'>
            <MapComponent
              mapStyles={{ disableDefaultUI: true, zoomControl: true }}
              markers={[
                ...locationArray,
                {
                  isUserAddress: 1,
                  Lat: _.get(props, 'request.userLocation.Lat') || _.get(props, 'request.userLocation.latitude'),
                  Lng: _.get(props, 'request.userLocation.Lng') || _.get(props, 'request.userLocation.longitude'),
                  ServiceLocationName: 'Your Location',
                  isSelectedPSL: false,
                },
              ].map((loc) => ({
                lat: parseFloat(loc.Lat),
                lng: parseFloat(loc.Lng),
                title: loc.ServiceLocationName,
                isUserAddress: loc.isUserAddress,
                isSelectedPSL: activePSL && _.includes([loc?.ClientServiceLocationID, loc?.PartnerServiceLocationID], activePSL),
              }))}
              mapProps={{
                center: {
                  lat: parseFloat(locationArray[0].Lat),
                  lng: parseFloat(locationArray[0].Lng),
                  // locationArray
                },
                zoom: 12,
              }}
              mapHeight='100%'
              language={_.get(props, 'user.selectedCountryData.countryConfig.LanguageCode.DEFAULT', 'en')}
            />
          </div>
        </div>
      ) : (
        ''
      )}

      <div className='col-sm-12'>
        <div className='buttons-container'>
          <MediumButton
            className='button-small button-ghost'
            buttonText={getTranslationText('common', 'backLabel')}
            onClick={props.handleBackButton}
          />
          {[2, 12].includes(props.selectedMode) && (
            <MediumButton
              className='button-small'
              onClick={props.handleDropOffItemClick}
              disabled={!activePSL}
              buttonText={getTranslationText('common', 'continue')}
            />
          )}
        </div>
      </div>
    </div>
  )
  if ([2, 12, 17, 23, 32, 57, 58, 66].includes(props.selectedMode)) {
    divToRender = dropoffDiv
  } else {
    divToRender = null
  }
  return divToRender
}

export class ClaimFormComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      continueBtnFlg: false,
      timeOptns: [],
    }
    this.claimSignCanvasRef = undefined
  }

  componentDidMount = () => {
    if (!_.isEmpty(this.props.request.claimFormDetails)) {
      this.btnStatusHandler(this.props)
    }
    let minutes = 0
    let locale = _.get(this.props, 'user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
    let timeOptns = []
    while (minutes <= 1410) {
      let timeObj = new Date()
      timeObj.setHours(0, minutes, 0)
      timeOptns.push({ time: timeObj, value: getLocaleTime(timeObj, locale) })
      minutes += 30
    }
    this.setState({ timeOptns })
    pushToAnalytics('Claim_details_page', {
      ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
      ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
    })
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props !== nextProps) {
      this.btnStatusHandler(nextProps)
    }
  }

  getSignCanvasRef = (canvasRef) => {
    if (_.get(this, 'props.request.selectedDamageType.ClaimForm.RequiresSignature', false)) {
      this.claimSignCanvasRef = canvasRef
    }
  }

  btnStatusHandler = (nextProps) => {
    let continueBtnFlg = true
    let selectedPlan = nextProps.request.selectedDamageType
    let checkForms = selectedPlan.ClaimForm.checkForms
    let claimFormFields
    if (checkForms) {
      claimFormFields = selectedPlan.LostTheftClaimForm
    } else {
      claimFormFields = selectedPlan.ClaimForm
    }
    let claimFormValues = nextProps.claimformInfo || {}
    let showVoiceRecorder = _.get(this, 'props.user.appConfigDetails.showVoiceRecorder', false)
    if (Object.keys(claimFormFields || {}).length) {
      if (
        (claimFormFields.RequiresDateOfDamage && !claimFormValues.deviceDamageDate) ||
        (claimFormFields.RequiresTimeOfDamage && !claimFormValues.deviceDamageTime?.value?.length) ||
        (claimFormFields.RequiresPlaceOfDamage &&
          !(claimFormFields.RequiresPlaceOfDamage && Object.keys(claimFormValues.deviceDamagePlace || {}).length > 0)) ||
        (claimFormFields.RequiresPlaceOfDevice && claimFormValues.deviceDamageLocation.replace(/[\s]/g, '').length <= 2) ||
        (claimFormFields.RequiresDeviceSwitchingOnStatus && claimFormValues.deviceOnState === null) ||
        (claimFormFields.alternateMobileNo &&
          claimFormValues.alternateMobileNo.length &&
          claimFormValues.alternateMobileNo.length < Math.min(...nextProps.phnoLengthAr)) ||
        (claimFormFields.alternateMobileNo && claimFormValues.alternateMobileNo.length && nextProps.invalidFormat) ||
        (claimFormFields.alternateMobileNo && claimFormValues.alternateMobileNo === _.get(this.props, 'user.userDetails.mobileNo')) ||
        (claimFormFields.RequiresTypeOfDamage && !claimFormValues.typesOfDamage.filter((dam) => dam.isSelected)[0]) ||
        (claimFormFields.RequiresRepairDate && !claimFormValues.devicerepairDate) ||
        (claimFormFields.RequiresRepairAmount && !parseInt(claimFormValues.deviceRepairAmount)) ||
        (!showVoiceRecorder &&
          !claimFormFields.RequiresDamageDescriptionVoice &&
          claimFormFields.RequiresDamageDescriptionText &&
          !(_.get(claimFormValues, 'deviceDamageDescp', '').replace(/[\s]/g, '').length >= 30)) ||
        (claimFormFields.RequiresSignature &&
          _.get(this, 'claimSignCanvasRef.isSignCompEmpty') &&
          this.claimSignCanvasRef.isSignCompEmpty()) ||
        (claimFormFields.RequiresRepairDate && !claimFormValues.devicerepairDate) ||
        (claimFormValues.EmailID && claimFormValues.EmailID.length && !regexCheck('validation', 'emailId', claimFormValues.EmailID)) ||
        (claimFormFields.RequiresPoliceReportDate && !claimFormValues.policeReportDate) ||
        (claimFormFields.RequiresPoliceReportNumber && !claimFormValues.policeReportNumber) ||
        (claimFormFields.RequiresDateOfTheft && !claimFormValues.dateOfTheft) ||
        (claimFormFields.RequiresDateOfLoss && !claimFormValues.dateOfLoss) ||
        (claimFormFields.RequiresPlaceOfTheft &&
          !(claimFormFields.RequiresPlaceOfTheft && Object.keys(claimFormValues.deviceDamagePlace || {}).length > 0)) ||
        (claimFormFields.RequiresPoliceAgency && !claimFormValues.policeAgency) ||
        (claimFormFields.RequiresTheftDetails && !(_.get(claimFormValues, 'theftDetails', '').replace(/[\s]/g, '').length >= 30)) ||
        (claimFormFields.RequiresPoliceReportUrl && !claimFormValues.policeReportUrl) ||
        (claimFormFields.RequiresIDUrl && !claimFormValues.policeReportUrl) ||
        (claimFormFields.RequiresLossDetails && !(_.get(claimFormValues, 'lossDetails', '').replace(/[\s]/g, '').length >= 30)) ||
        (claimFormFields.RequiresLegalAcknowlegement && !claimFormValues.lossConsentCheck) ||
        (claimFormFields.RequiresAccountHolderFullName && !claimFormValues.userFullName) ||
        (claimFormFields.RequiresAccountHolderEmailID && !claimFormValues.lossEmailId) ||
        (claimFormFields.RequiresAccountHolderEmailID &&
          claimFormValues.lossEmailId &&
          claimFormValues.lossEmailId.length &&
          !regexCheck('validation', 'emailId', claimFormValues.lossEmailId)) ||
        (claimFormFields.RequiresAccountHolderMobileNo && !claimFormValues.lossMobileNumber) ||
        (claimFormFields.RequiresAccountHolderMobileNo && claimFormValues.lossMobileNumber.length && nextProps.invalidFormat) ||
        (claimFormFields.RequiresAccountHolderMobileNo &&
          claimFormValues.lossMobileNumber &&
          claimFormValues.lossMobileNumber.length &&
          claimFormValues.lossMobileNumber.length < Math.min(...nextProps.phnoLengthAr)) ||
        (claimFormFields.RequiresAccountHolderBillingAddress && !claimFormValues.lossBillingAddress)
      ) {
        continueBtnFlg = false
      }
    } else {
      if (
        !_.get(nextProps, 'claimformInfo.deviceDamageDate') ||
        !claimFormValues.deviceDamageTime.length ||
        Object.keys(claimFormValues.deviceDamagePlace || {}).length > 0 ||
        claimFormValues.deviceOnState === null ||
        claimFormValues.deviceDamageLocation.replace(/[\s]/g, '').length <= 2 ||
        (claimFormValues.alternateMobileNo.length && claimFormValues.alternateMobileNo.length < Math.min(...this.props.phnoLengthAr))
      ) {
        continueBtnFlg = false
      }
    }
    if (
      (selectedPlan.RequiresInvoiceForClaim || (claimFormFields && claimFormFields.RequiresInvoiceForClaim)) &&
      !this.props.invoiceDocAlreadyAdded
    ) {
      continueBtnFlg = (this.props.uploadedInvoiceImgFlg || false) && continueBtnFlg
    }

    if (claimFormFields && claimFormFields.RequiresDamageDescriptionVoice) {
      if (!this.props.savedVoiceRecorder) {
        continueBtnFlg =
          claimFormFields.RequiresDamageDescriptionText &&
          _.get(claimFormValues, 'deviceDamageDescp', '').replace(/[\s]/g, '').length >= 30 &&
          continueBtnFlg
      } else if (claimFormFields.RequiresDamageDescriptionText) {
        continueBtnFlg = this.props.savedVoiceRecorder && continueBtnFlg
      }
    }
    this.setState({
      continueBtnFlg,
    })
  }

  signCallBack = (event) => {
    if (event) {
      let imgUrl = this.claimSignCanvasRef.getSignImageUrl()
      this.props.handleClaimInputs({
        currentTarget: {
          id: 'claim-usr-sign',
          value: imgUrl.src,
        },
      })
      return
    }
    this.btnStatusHandler(this.props)
  }

  render() {
    let claimFormFields
    const checkForms = _.get(this, 'props.request.selectedDamageType.ClaimForm.checkForms', false)
    if (checkForms) {
      claimFormFields = _.get(this, 'props.request.selectedDamageType.LostTheftClaimForm', {})
    } else {
      claimFormFields = _.get(this, 'props.request.selectedDamageType.ClaimForm', {})
    }
    let claimFormValues = this.props.claimformInfo || {}
    let invoiceCheckFlg =
      (this.props.request.selectedDamageType.RequiresInvoiceForClaim || claimFormFields.RequiresInvoiceForClaim) &&
      !this.props.invoiceDocAlreadyAdded
    let currencySign = _.get(this, 'props.user.selectedCountryData.currencies[0].CurrencySymbol', '')
    let curdate = new Date()
    const getDamagedFieldLabel = getTranslationText('request', 'getDamaged').replace('@charCount', claimFormValues.charlength)
    const dateFormat = _.get(this, 'props.user.selectedCountryData.countryConfig.DateFormat.DEFAULT', 'DD/MM/YYYY')
    const language = localStorage.getItem('languageCode')
    const locale = _.get(this, 'props.user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en-IN')
    let showVoiceRecorder = _.get(this, 'props.user.appConfigDetails.showVoiceRecorder', false)
    const showCustomPlaceholder = _.get(this, 'props.user.appConfigDetails.showCustomPlaceholder', false)
    return (
      <div className='claim-form-component'>
        <BoxHolder>
          <div className='row m0 mb20'>
            {checkForms && (
              <div className='col-sm-2 device-damage-time fontSmall mb20'>
                <div className='bookFontStyle overrideCasing mb8'>{getTranslationText('request', 'incidentType')}</div>
                <Dropdown
                  id='incident-type'
                  value={claimFormValues.incidentType || ''}
                  options={[
                    { value: 'Theft', IncendentTypeID: '1' },
                    { value: 'Loss', IncendentTypeID: '2' },
                  ]}
                  placeholder='Select Incident Type'
                  handleOnChange={(selectedItem, e) => {
                    this.props.incidentTypeSelection(selectedItem, e)
                    pushToAnalytics('Incident_type_selected', {
                      Incident_type: selectedItem.value || '',
                      ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
                      ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
                    })
                  }}
                />
              </div>
            )}
          </div>

          {claimFormFields.IncidentType == 'Loss' && (
            <>
              <div className='bookFontStyle fontSmall overrideCasing mb8'>{getTranslationText('request', 'lossDeviceDetais')}</div>
              <div className='row m0 mb20'>
                <div className='col-sm-4 claim-form-device-details'>
                  <div className='lightFontStyle fontSmall black-400'>{getTranslationText('request', 'serialOrIMEI')}</div>
                  <div className='bookFontStyle fontMedium'>{_.get(this, 'props.request.selectedDeviceInfo.ProductUniqueID')}</div>
                </div>
                <div className='col-sm-4 claim-form-device-details'>
                  <div className='lightFontStyle fontSmall black-400'>{getTranslationText('request', 'deviceManufacturer')}</div>
                  <div className='bookFontStyle fontMedium'>{_.get(this, 'props.request.selectedDeviceInfo.Brand.BrandName')}</div>
                </div>
                <div className='col-sm-4 claim-form-device-details'>
                  <div className='lightFontStyle fontSmall black-400'> {getTranslationText('request', 'deviceModel')}</div>
                  <div className='bookFontStyle fontMedium'>{_.get(this, 'props.request.selectedDeviceInfo.Product.ProductName')}</div>
                </div>
              </div>
            </>
          )}

          {claimFormFields.RequiresDateOfLoss && (
            <div className='row m0 mb20'>
              <div className='mb8 device-damage-date fontSmall'>
                <div className='bookFontStyle overrideCasing mb8'>{getTranslationText('request', 'dateOfLoss')}</div>
                <DatePicker
                  id={'date-of-loss'}
                  placeholder={getTranslationText('request', 'selectDateOfLoss')}
                  format={dateFormat.toLowerCase()}
                  minDay={new Date(curdate.getFullYear() - 10, curdate.getMonth(), curdate.getDate())}
                  maxDay={new Date()}
                  handleOnChange={(e) => this.props.handleDatePickerOnChange(e, 'date-of-loss')}
                  date={claimFormValues.dateOfLoss || ''}
                  locale={locale}
                  language={language}
                ></DatePicker>
              </div>
            </div>
          )}

          {claimFormFields.RequiresPlaceOfLoss && (
            <div className='row m0 mb20'>
              <div className='col-sm-5 mb20'>
                <AddressBox
                  placesAutocomplete={this.props.placesAutocomplete}
                  handleTypeaheadSelect={this.props.handleTypeaheadSelect}
                  onChangeTypeahead={this.props.onChangeTypeahead}
                  user={this.props.user}
                  isLoaderSet={this.props.isLoaderSet}
                  locationLabel={getTranslationText('request', 'placeLossLastKnown')}
                />
              </div>
            </div>
          )}

          {claimFormFields && claimFormFields.RequiresLossDetails && (
            <div className='row m0 mb20'>
              <div className='col-sm-12'>
                <Input
                  inputComponent='textarea'
                  id='loss-details'
                  placeholder={`${getTranslationText('request', 'enterIncidentDetails')}`}
                  className={{
                    formControl: 'mb8',
                    inputContainer: `damage-descp-textarea ${this.props.showDamageDescError ? 'damage-error-border' : ''}`,
                    label: 'fontSmall',
                    input: 'fontMedium',
                  }}
                  maxLength='500'
                  handleOnChange={this.props.handleClaimInputs}
                  value={claimFormValues.lossDetails || ''}
                  required
                  label={getTranslationText('request', 'incidentDetails')}
                >
                  <p className='device-descp-length bookFontStyle fontSmall mb0'>
                    {`${(claimFormValues.lossDetails || '').length}/500 ${getTranslationText('common', 'characters')}`}
                  </p>
                </Input>
                {this.props.showDamageDescError && (
                  <div className='fontSmall d-flex damage-error'>
                    <ErrorIcon className='mr6' />
                    <span>{getTranslationText('common', 'min30Chars')}</span>
                  </div>
                )}
                <span className='bookFontStyle fontSmall'>{getTranslationText('request', 'lossNoteDescription')}</span>
              </div>
            </div>
          )}

          {(claimFormFields.RequiresDateOfDamage ||
            claimFormFields.RequiresRepairDate ||
            claimFormFields.RequiresTimeOfDamage ||
            claimFormFields.RequiresPlaceOfDamage) && (
            <div className='row m0 mb20'>
              {(claimFormFields.RequiresDateOfDamage || claimFormFields.RequiresRepairDate) && (
                <div className='device-damage-date fontSmall'>
                  <div className='bookFontStyle overrideCasing mb8'>
                    {claimFormFields.RequiresRepairDate
                      ? `${getTranslationText('request', 'repairDate')}`
                      : `${getTranslationText('track', 'dateOfDamage')}`}
                  </div>
                  <DatePicker
                    id={claimFormFields.RequiresRepairDate ? 'repair-date' : 'damage-date'}
                    placeholder={dateFormat}
                    format={dateFormat.toLowerCase()}
                    minDay={new Date(curdate.getFullYear() - 10, curdate.getMonth(), curdate.getDate())}
                    maxDay={new Date()}
                    handleOnChange={(e) =>
                      this.props.handleDatePickerOnChange(e, claimFormFields.RequiresRepairDate ? 'repair-date' : 'damage-date')
                    }
                    date={
                      claimFormFields.RequiresRepairDate
                        ? claimFormValues.claimFormValues.deviceDamageDate
                          ? new Date(claimFormValues.claimFormValues.deviceDamageDate)
                          : ''
                        : claimFormValues.deviceDamageDate
                        ? new Date(claimFormValues.deviceDamageDate)
                        : ''
                    }
                    locale={locale}
                    language={language}
                  ></DatePicker>
                </div>
              )}
              {claimFormFields.RequiresTimeOfDamage && (
                <div className='col-sm-1 device-damage-time fontSmall'>
                  <div className='bookFontStyle overrideCasing mb8'>{getTranslationText('request', 'timeOfDamage')}</div>
                  <Dropdown
                    id='damage-time'
                    value={claimFormValues.deviceDamageTime?.value}
                    options={this.state.timeOptns}
                    placeholder='hh:mm'
                    handleOnChange={(selectedItem, e) => {
                      this.props.handleClaimInputs({ ...e, currentTarget: { ...e.currentTarget, id: 'damage-time', value: selectedItem } })
                    }}
                    filterBy='value'
                    searchText='_ _ : _ _'
                  />
                </div>
              )}
              {claimFormFields.RequiresPlaceOfDamage && (
                <div className='col-sm-4 mb20'>
                  <AddressBox
                    placesAutocomplete={this.props.placesAutocomplete}
                    handleTypeaheadSelect={this.props.handleTypeaheadSelect}
                    onChangeTypeahead={this.props.onChangeTypeahead}
                    user={this.props.user}
                    isLoaderSet={this.props.isLoaderSet}
                    locationLabel={getTranslationText('request', 'placeOfDamage')}
                  />
                </div>
              )}
            </div>
          )}
          {claimFormFields.RequiresDateOfTheft && (
            <div className='row m0 mb20'>
              <div className='mb8 device-damage-date fontSmall'>
                <div className='bookFontStyle overrideCasing mb8'>{getTranslationText('request', 'dateOfTheft')}</div>
                <DatePicker
                  id={'date-of-theft'}
                  placeholder={`Select date of theft`}
                  format={dateFormat.toLowerCase()}
                  minDay={new Date(curdate.getFullYear() - 10, curdate.getMonth(), curdate.getDate())}
                  maxDay={new Date()}
                  handleOnChange={(e) => {
                    this.props.handleDatePickerOnChange(e, 'date-of-theft')
                    // pushToAnalytics('Date_of_theft', {
                    //   Date_of_theft: claimFormValues.dateOfTheft || '',
                    //   ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
                    //   ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
                    // })
                  }}
                  date={claimFormValues.dateOfTheft || ''}
                  locale={locale}
                  language={language}
                ></DatePicker>
              </div>
            </div>
          )}
          {claimFormFields.RequiresPlaceOfTheft && (
            <div className='row m0 mb20'>
              <div className='col-sm-5 mb8 fontSmall'>
                <AddressBox
                  placesAutocomplete={this.props.placesAutocomplete}
                  handleTypeaheadSelect={this.props.handleTypeaheadSelect}
                  onChangeTypeahead={this.props.onChangeTypeahead}
                  user={this.props.user}
                  isLoaderSet={this.props.isLoaderSet}
                  locationLabel={getTranslationText('request', 'placeOfTheft')}
                />
              </div>
            </div>
          )}

          <div className='row m0 mb20'>
            {claimFormFields.RequiresPoliceReportDate && (
              <div className='mb8 device-damage-date fontSmall'>
                <div className='bookFontStyle overrideCasing mb8'>{getTranslationText('request', 'policeReportDate')}</div>
                <DatePicker
                  id={'police-report-date'}
                  placeholder={`Select date of report`}
                  format={dateFormat.toLowerCase()}
                  minDay={new Date(curdate.getFullYear() - 10, curdate.getMonth(), curdate.getDate())}
                  maxDay={new Date()}
                  handleOnChange={(e) => this.props.handleDatePickerOnChange(e, 'police-report-date')}
                  date={claimFormValues.policeReportDate || ''}
                  locale={locale}
                  language={language}
                ></DatePicker>
              </div>
            )}
            {claimFormFields.RequiresPoliceReportNumber && (
              <div className='mb8 device-damage-date fontSmall ml20'>
                <div className='bookFontStyle overrideCasing mb8'>{getTranslationText('request', 'policeReportNumber')}</div>
                <Input
                  className={{ inputContainer: 'claim-form-input-width' }}
                  handleOnChange={this.props.handleClaimInputs}
                  placeholder={`Enter report number`}
                  id='police-report-number'
                  maxLength='30'
                  value={claimFormValues.policeReportNumber || ''}
                />
              </div>
            )}
          </div>

          {(claimFormFields.RequiresTypeOfDamage ||
            claimFormFields.RequiresPlaceOfDevice ||
            claimFormFields.RequiresDeviceSwitchingOnStatus) && (
            <div className='row m0 mb20'>
              {claimFormFields.RequiresTypeOfDamage && (
                <div className='device-damage-type mb4 fontSmall'>
                  <div className='bookFontStyle overrideCasing mb8'>{getTranslationText('common', 'typeOfDamage')}</div>
                  <PillGroup
                    handlePillSelect={this.props.handleTypeOfDamageSelect}
                    prefix='typesOfDamage'
                    pills={claimFormValues.typesOfDamage}
                  />
                </div>
              )}
              {!(claimFormFields.RequiresPlaceOfDevice || claimFormFields.RequiresDeviceSwitchingOnStatus) ? (
                ''
              ) : (
                <React.Fragment>
                  {claimFormFields.RequiresPlaceOfDevice && (
                    <Input
                      inputType='text'
                      id='device-damage-locationip'
                      placeholder={getTranslationText('request', 'exampleThings')}
                      className={{
                        formControl: 'device-damage-location fontSmall',
                        label: 'bookFontStyle overrideCasing fontSmall',
                        input: 'boldFontStyle',
                      }}
                      required
                      label={getTranslationText('request', 'incidentTime')}
                      handleOnChange={this.props.handleClaimInputs}
                      value={claimFormValues.deviceDamageLocation}
                    />
                  )}
                  {claimFormFields.RequiresDeviceSwitchingOnStatus && (
                    <div className='col-sm-4 fontSmall'>
                      <div className='bookFontStyle mb8'>{getTranslationText('request', 'isDeviceOn')}</div>
                      <span
                        className={'yes-radio-btn ' + (window.isDirectionRTL ? ' ml8 ' : ' mr8')}
                        id='screenOff-yes'
                        onClick={this.props.handleClaimInputs}
                      >
                        <Radio classes='radio-styling' value={true} name='switiching-on' selectedValue={claimFormValues.deviceOnState} />
                        <label className={claimFormValues.deviceOnState ? 'boldFontStyle' : 'bookFontStyle'}>
                          {getTranslationText('common', 'yes')}
                        </label>
                      </span>
                      <span className='no-radio-btn' id='screenOff-no' onClick={this.props.handleClaimInputs}>
                        <Radio classes='radio-styling' value={false} name='switiching-on' selectedValue={claimFormValues.deviceOnState} />
                        <label
                          className={
                            claimFormValues.deviceOnState !== null && !claimFormValues.deviceOnState ? 'boldFontStyle' : 'bookFontStyle'
                          }
                        >
                          {getTranslationText('common', 'no')}
                        </label>
                      </span>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          )}
          {claimFormFields &&
          (claimFormFields.RequiresRepairAmount ||
            claimFormFields.RequiresRepairInvoice ||
            claimFormFields.RequiresSignature ||
            claimFormFields.RequiresRepairDate) ? (
            <div className='row m0 mb20'>
              {claimFormFields.RequiresRepairAmount && (
                <div className='col-sm-12 mb8'>
                  <div className='bookFontStyle fontSmall overrideCasing mb8'>{getTranslationText('request', 'repairAmount')}</div>
                  <div className='repair-amount-wraper boldFontStyle'>
                    <span dangerouslySetInnerHTML={{ __html: currencySign }} />
                    <Input
                      inputType='text'
                      placeholder=''
                      id='repair-amount-ip'
                      autoComplete={'off'}
                      handleOnChange={this.props.handleClaimInputs}
                      value={claimFormValues.deviceRepairAmount || ''}
                      otherProps={{
                        required: true,
                      }}
                    />
                  </div>
                </div>
              )}
              {claimFormFields.RequiresRepairInvoice && (
                <div className='col-sm-12 mb8'>
                  <div className='bookFontStyle fontSmall overrideCasing mb8'>{getTranslationText('request', 'repairInvoice')}</div>
                  <BoxImagesComponent
                    loaderState={false}
                    isUploaded={!!claimFormValues.repairInvoiceUrl}
                    imgFilePath={claimFormValues.repairInvoiceUrl || <AddIcon />}
                    inputName='invoice-upload'
                    imgViewerHandler={this.props.viewRepairInvoice}
                    fileUploadHander={this.props.handleRepairInvoiceUpload}
                  />
                </div>
              )}
              {claimFormFields.RequiresSignature && (
                <div className='col-sm-12 mb8'>
                  <div className='bookFontStyle mb4 fontSmall overrideCasing mb4'>{getTranslationText('request', 'sign')}</div>
                  <SignatureComponent
                    styles={{ className: 'claim-sign-wrapper' }}
                    getSignCanvasRef={this.getSignCanvasRef}
                    signImgUrl={claimFormValues.signImgUrl}
                    signCallBack={this.signCallBack}
                  />
                </div>
              )}
            </div>
          ) : (
            ''
          )}
          <div className='row m0'>
            {claimFormFields && claimFormFields.RequiresDamageDescriptionText && (
              <React.Fragment>
                <div className='col-sm-12 p0 mb8 device-damage-info'>
                  <Input
                    inputComponent='textarea'
                    id='device-damage-descpip'
                    placeholder={
                      showCustomPlaceholder
                        ? `${getTranslationText('request', 'customDeviceDamagePlaceholder')}`
                        : `${getTranslationText('request', 'typeHere')}...`
                    }
                    className={{
                      formControl: 'mt8 mb8',
                      inputContainer: `damage-descp-textarea ${this.props.showDamageDescError ? 'damage-error-border' : ''}`,
                      label: 'fontSmall mb8',
                      input: 'fontMedium',
                    }}
                    maxLength='500'
                    handleOnChange={this.props.handleClaimInputs}
                    value={claimFormValues.deviceDamageDescp}
                    required
                    label={getDamagedFieldLabel}
                  >
                    <p className='device-descp-length bookFontStyle fontSmall mb0'>
                      {`${(claimFormValues.deviceDamageDescp || '').length}/500 ${getTranslationText('common', 'characters')}`}
                    </p>
                  </Input>
                  {this.props.showDamageDescError && (
                    <div className='fontSmall d-flex damage-error'>
                      <ErrorIcon className='mr6' />
                      <span>{getTranslationText('common', 'min30Chars')}</span>
                    </div>
                  )}
                </div>
                {showVoiceRecorder && claimFormFields && claimFormFields.RequiresDamageDescriptionVoice ? (
                  ''
                ) : (
                  <div className='col-sm-12 mb8 descrip-info bookFontStyle fontSmall Note_Text mt15'>
                    <p>{getTranslationText('request', 'descSubmissionNote')}</p>
                  </div>
                )}
              </React.Fragment>
            )}
            {showVoiceRecorder && claimFormFields && claimFormFields.RequiresDamageDescriptionVoice && (
              <React.Fragment>
                <div className='col-sm-12 mb8 device-damage-info'>
                  <div className='bookFontStyle fontSmall overrideCasing mb8'>{getTranslationText('request', 'damageDesciptionVoice')}</div>
                  <div className='additional-upload-container remove-padding'>
                    <div
                      className='upload-icon'
                      onClick={this.props.savedVoiceRecorder ? this.props.playUploadedAudio : this.props.handleVoiceRecorder}
                    >
                      <div className='icon-container'>
                        <label htmlFor='voice-input' className='icon-label'>
                          <img
                            src={this.props.savedVoiceRecorder ? playIcon : this.props.startVoiceRecorder ? voiceRecording : invoiceVoice}
                          />
                        </label>
                        <label htmlFor='voice-input' className='icon-txt bookFontStyle fontMicro'>
                          {this.props.savedVoiceRecorder ? getTranslationText('request', 'play') : getTranslationText('request', 'voice')}
                        </label>
                      </div>
                    </div>
                    {this.props.startVoiceRecorder ? (
                      <div className='small-theme-button' onClick={this.props.stopVoiceRecorderHandler}>
                        {getTranslationText('request', 'stopRecording')}
                      </div>
                    ) : (
                      ''
                    )}
                    {this.props.savedVoiceRecorder ? (
                      <div className='small-theme-button' onClick={this.props.deleteVoiceRecorderHandler}>
                        {getTranslationText('request', 'deleteRecording')}
                      </div>
                    ) : (
                      ''
                    )}
                    <audio className='audio-tag' id='recordedAudio' />
                  </div>
                  <div className='col-sm-12 mb8 descrip-info bookFontStyle fontSmall Note_Text mt15'>
                    <p>{getTranslationText('request', 'descSubmissionNote')}</p>
                  </div>
                </div>
              </React.Fragment>
            )}
            {invoiceCheckFlg ? (
              <div className='col-sm-12 mb20 request-flow-invoice-upload'>
                <div className='boldFontStyle fontSmall mb8 overrideCasing'>
                  <span>{getTranslationText('request', 'uploadInvoice')}</span>
                </div>
                <div className='multiple-invoice-container'>
                  <div className='claim-form-invoice'>
                    <BoxImagesComponent
                      loaderState={false}
                      isUploaded={false}
                      imgFilePath={<AddIcon />}
                      inputName='invoice-upload'
                      fileUploadHander={this.props.handleInvoiceUpload}
                    />
                  </div>
                  {_.get(this.props, 'devices.productDocs', []).length > 0 &&
                    this.props.devices.productDocs.map((doc, index) => {
                      return (
                        <div key={index} className='claim-form-invoice'>
                          <BoxImagesComponent
                            loaderState={false}
                            isUploaded
                            imgFilePath={doc.FilePath}
                            imageID={doc.ConsumerProductDocumentID}
                            imgViewerHandler={this.props.ViewUploadInvoice}
                          />
                        </div>
                      )
                    })}
                </div>
              </div>
            ) : (
              ''
            )}
            {claimFormFields && claimFormFields.RequiresIDUrl && (
              <div className='col-sm-12 mb20 request-flow-invoice-upload'>
                <div className='bookFontStyle fontSmall mb8 overrideCasing'>
                  <span>{`${getTranslationText('request', 'uploadLossPhotoID')} `}</span>
                  <Tooltip
                    content={`Acceptable forms of Photo ID: 
                      1. Valid driver's license, or 
                      2. Passport, or
                      3. Federally issued ID card, or 
                      4. Matricula consular ID`}
                    position='right'
                  >
                    <span className='icon'>&#9432;</span>
                  </Tooltip>
                </div>
                <div className='multiple-invoice-container'>
                  <div className='claim-form-invoice'>
                    <BoxImagesComponent
                      loaderState={false}
                      isUploaded={!!claimFormValues.policeReportUrl}
                      imgFilePath={claimFormValues.policeReportRenderableUrl || <AddIcon />}
                      inputName='police-report'
                      fileUploadHander={this.props.handlePoliceReportUpload}
                      imgViewerHandler={this.props.ViewUploadedPoliceReport}
                    />
                  </div>
                  <div className='bookFontStyle fontSmall mb8 overrideCasing'>
                    <span>{getTranslationText('request', 'lossNotePhotoID')}</span>
                  </div>
                </div>
              </div>
            )}
            {claimFormFields && claimFormFields.RequiresPoliceReportUrl && (
              <div className='col-sm-12 mb20 request-flow-invoice-upload'>
                <div className='bookFontStyle fontSmall mb8 overrideCasing'>
                  <span>{getTranslationText('request', 'uploadPoliceReport')}</span>
                </div>
                <div className='multiple-invoice-container'>
                  <div className='claim-form-invoice'>
                    <BoxImagesComponent
                      loaderState={false}
                      isUploaded={!!claimFormValues.policeReportUrl}
                      imgFilePath={claimFormValues.policeReportRenderableUrl || <AddIcon />}
                      inputName='police-report'
                      fileUploadHander={this.props.handlePoliceReportUpload}
                      imgViewerHandler={this.props.ViewUploadedPoliceReport}
                    />
                  </div>
                </div>
              </div>
            )}
            {claimFormFields.RequiresPoliceAgency && (
              <div className='col-sm-5 mb20 fontSmall'>
                <div className='bookFontStyle overrideCasing mb8'>{getTranslationText('request', 'lawEnforcementAgency')}</div>
                <Input
                  className={{ inputContainer: '' }}
                  id='law-enforcement-agency'
                  value={claimFormValues.policeAgency || ''}
                  placeholder={`${getTranslationText('request', 'placeHolderlawEnforcementAgency')}`}
                  handleOnChange={this.props.handleClaimInputs}
                  maxLength='50'
                />
              </div>
            )}
            {claimFormFields && claimFormFields.RequiresEmail && (
              <div className='col-sm-12 mb8'>
                <Input
                  className={{
                    formControl: 'col-sm-4 mb8',
                    inputContainer: 'email-input',
                    input: 'mediumFontStyle fontSmall text-input',
                    label: 'bookFontStyle fontSmall mb4 overrideCasing',
                  }}
                  label={getTranslationText('login', 'email')}
                  inputType='email'
                  autoComplete='off'
                  id='repair-email'
                  placeholder={getTranslationText('login', 'enterEmailID')}
                  handleOnChange={this.props.handleClaimInputs}
                  value={this.props.claimformInfo.EmailID}
                  otherProps={{
                    required: true,
                  }}
                />
              </div>
            )}
            {claimFormFields && claimFormFields.RequiresAlternateNumber && (
              <div className='col-sm-12 mb20 no-padding'>
                <Input
                  className={{
                    formControl: 'col-sm-4 mb20',
                    input: 'mediumFontStyle fontSmall text-input',
                    label: 'bookFontStyle fontSmall mb8 overrideCasing',
                  }}
                  label={`${getTranslationText('request', 'alternateMobileNumber')} ${getTranslationText('common', 'optional')}`}
                  inputType='text'
                  autoComplete='off'
                  id='alternateMobileNo'
                  placeholder={getTranslationText('common', 'mobileNumber')}
                  handleOnChange={this.props.handleClaimInputs}
                  value={this.props.claimformInfo.alternateMobileNo}
                  otherProps={{
                    required: true,
                  }}
                />
              </div>
            )}
            {claimFormFields.IncidentType == 'Loss' && (
              <React.Fragment>
                <div className='col-sm-12 mb8 bookFontStyle fontSmall mb8'>{getTranslationText('request', 'lossAccountHolder')}</div>
                {claimFormFields && claimFormFields.RequiresAccountHolderFullName && (
                  <div className='col-sm-12 col-md-4 mb20'>
                    <Input
                      inputType='text'
                      id='loss-user-full-name'
                      autoComplete='off'
                      placeholder={getTranslationText('request', 'lossEnterUserName')}
                      className={{
                        formControl: 'rm_pl device-damage-place fontSmall',
                        label: 'bookFontStyle overrideCasing fontSmall',
                        input: 'boldFontStyle',
                      }}
                      maxLength='50'
                      required
                      label={getTranslationText('request', 'lossUserFullName')}
                      handleOnChange={this.props.handleClaimInputs}
                      value={claimFormValues.userFullName}
                    />
                  </div>
                )}
                {claimFormFields && claimFormFields.RequiresAccountHolderEmailID && (
                  <div className='col-sm-12 col-md-4 mb20'>
                    <Input
                      inputType='email'
                      id='loss-email-id'
                      autoComplete='off'
                      placeholder={`${getTranslationText('request', 'lossEnterEmail')}`}
                      className={{
                        formControl: 'device-damage-place fontSmall',
                        label: 'bookFontStyle overrideCasing fontSmall',
                        input: 'boldFontStyle',
                      }}
                      required
                      label={`${getTranslationText('request', 'lossEmail')}`}
                      handleOnChange={this.props.handleClaimInputs}
                      value={claimFormValues.lossEmailId}
                    />
                  </div>
                )}
                {claimFormFields && claimFormFields.RequiresAccountHolderMobileNo && (
                  <div className='col-sm-12 col-md-4 mb20'>
                    <Input
                      inputType='text'
                      id='loss-mobile-number'
                      autoComplete='off'
                      placeholder={`${getTranslationText('request', 'lossEnterMobileNumber')}`}
                      className={{
                        formControl: 'device-damage-place fontSmall',
                        label: 'bookFontStyle overrideCasing fontSmall',
                        input: 'boldFontStyle',
                      }}
                      required
                      label={` ${getTranslationText('request', 'lossMobileNumber')}`}
                      handleOnChange={this.props.handleClaimInputs}
                      value={claimFormValues.lossMobileNumber}
                    />
                  </div>
                )}
                {claimFormFields.RequiresAccountHolderBillingAddress && (
                  <div className='col-sm-5 mb20 fontSmall'>
                    <div className='bookFontStyle overrideCasing mb8 mt20'>{getTranslationText('request', 'lossBillingAddress')}</div>
                    <Input
                      inputType='text'
                      className={{ inputContainer: '' }}
                      id='loss-billing-address'
                      autoComplete='off'
                      maxLength='200'
                      value={claimFormValues.lossBillingAddress || ''}
                      placeholder={`${getTranslationText('request', 'lossBillingAddressPlaceholder')}`}
                      handleOnChange={this.props.handleClaimInputs}
                    />
                  </div>
                )}
                {claimFormFields.RequiresLegalConsent && (
                  <div className='col-sm-12 fontMedium inline-ele-Casing mt20'>
                    <Checkbox
                      type='checkbox'
                      value={claimFormValues.lossConsentCheck}
                      handleOnChange={this.props.toggleLossConsentCheck}
                      otherProps={{
                        style: window.isDirectionRTL ? { marginLeft: '5px' } : { marginRight: '5px' },
                      }}
                    >
                      <span className=''>
                        {
                          getLegalConsents(_.get(this, 'props.user.claimLegalConfigs.LegalConfigs', []), ['CLAIM_FORM_ACKNOWLEDGEMENT'])[0]
                            .legalConfig.Text
                        }
                      </span>
                    </Checkbox>
                  </div>
                )}
              </React.Fragment>
            )}
            {claimFormFields.RequiresTheftDetails && (
              <React.Fragment>
                <div className='col-sm-12'>
                  <div className='bookFontStyle fontSmall'>{getTranslationText('request', 'incidentDetails')}</div>
                  <Input
                    inputComponent='textarea'
                    id='theft-details'
                    placeholder={`${getTranslationText('request', 'theftIncidentDetails')}`}
                    className={{
                      formControl: 'mt8 mb8',
                      inputContainer: `damage-descp-textarea ${this.props.showTheftDetailsError ? 'damage-error-border' : ''}`,
                      label: 'fontSmall',
                      input: 'fontMedium',
                    }}
                    maxLength='500'
                    handleOnChange={this.props.handleClaimInputs}
                    value={claimFormValues.theftDetails || ''}
                    required
                  >
                    <p className='device-descp-length bookFontStyle fontSmall'>
                      {`${(claimFormValues.theftDetails || '').length}/500 ${getTranslationText('common', 'characters')}`}
                    </p>
                  </Input>
                  {this.props.showTheftDetailsError && (
                    <div className='fontSmall d-flex damage-error'>
                      <ErrorIcon className='mr6' />
                      <span>{getTranslationText('common', 'min30Chars')}</span>
                    </div>
                  )}
                </div>
                <div className='col-sm-12 mb8 descrip-info bookFontStyle fontSmall Note_Text mt15'>
                  <p>{getTranslationText('request', 'descSubmissionNote')}</p>
                </div>
              </React.Fragment>
            )}
            <div className='col-sm-12 mt20 buttons-container'>
              <MediumButton
                className='button-small button-ghost'
                buttonText={getTranslationText('common', 'backLabel')}
                onClick={() => {
                  this.props.handleBackButton()
                  pushToAnalytics('Claim_details_back_button_clicked', {
                    ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
                    ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
                  })
                }}
              />
              <MediumButton
                className='button-small'
                buttonText={getTranslationText('common', 'continue')}
                onClick={() => {
                  claimFormFields.IncidentType == 'Loss' ? this.props.validateAffidavit() : this.props.validateClaimForm()
                  let SelectedDamageObj = _.get(this, 'props.request.claimFormDetails.typesOfDamage', []).filter((obj) => obj.isSelected)[0]
                  pushToAnalytics('Claim_details_continue_button_clicked', {
                    Device_Damage_Date: _.get(this, 'props.request.claimFormDetails.deviceDamageDate', ''),
                    Damage_Type: SelectedDamageObj?.displayText || '',
                    Description: _.get(this, 'props.request.claimFormDetails.deviceDamageDescp', ''),
                    ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
                    ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
                    claimFormDetails: _.get(this, 'props.request.claimFormDetails', {}),
                  })
                }}
                btnLoader={this.props.ClaimRaiseBtnLoader}
                disabled={!this.state.continueBtnFlg}
              />
            </div>
          </div>
        </BoxHolder>
      </div>
    )
  }
}
ClaimFormComponent.propTypes = {
  handleClaimInputs: PropTypes.func.isRequired,
  claimformInfo: PropTypes.object.isRequired,
  validateClaimForm: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
  handleBackButton: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
  ClaimRaiseBtnLoader: PropTypes.func.isRequired,
  devices: PropTypes.object.isRequired,
  uploadedInvoiceImgFlg: PropTypes.bool,
  ViewUploadInvoice: PropTypes.func,
  handleInvoiceUpload: PropTypes.func,
  handleTypeOfDamageSelect: PropTypes.func.isRequired,
  // typesOfDamage: PropTypes.array.isRequired,
  invoiceDocAlreadyAdded: PropTypes.bool,
  phnoLengthAr: PropTypes.array.isRequired,
  handleDatePickerOnChange: PropTypes.func.isRequired,
  viewRepairInvoice: PropTypes.func,
  handleRepairInvoiceUpload: PropTypes.func,
  // invalidFormat: PropTypes.bool
}

export class SlotsSelectionScreen extends React.Component {
  componentDidMount() {
    if (!this.props.sameDeliveryAddressCheck) {
      if (_.includes([17, 23, 46, 63, 65, 64, 3], this.props.selectedMode)) {
        this.props.toggleSameDeliveryAddressCheck() // Delivery address is same for these service modes
      } else {
        let inputNode = document.getElementById('PlacesAutocomplete__root')
        if (inputNode) {
          inputNode.childNodes[0].value = ''
          inputNode.childNodes[0].focus()
        }
      }
    }
    pushToAnalytics(
      [17, 23].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
        ? 'Return_address_page'
        : 'Delivery_address_page',
      {
        ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
        ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
      }
    )
  }
  render() {
    let courierBtnStatus =
      this.props.newAddress &&
      Object.keys(this.props.newAddress).length >= 1 &&
      !this.props.deliveryAddressType.find((data) => data.isSelected) &&
      !!this.props.addressType.find((data) => data.isSelected) &&
      this.props.isServiceable
    let btnStatus =
      this.props.newAddress &&
      Object.keys(this.props.newAddress).length >= 1 &&
      !!this.props.addressType.find((data) => data.isSelected) &&
      (this.props.sameDeliveryAddressCheck ||
        (this.props.deliveryAddress &&
          Object.keys(this.props.deliveryAddress).length >= 1 &&
          !!this.props.deliveryAddressType.find((data) => data.isSelected))) &&
      this.props.isServiceable

    // && this.props.timesToShow.find(data => (data.isSelected)))
    let availableDays = []
    let addressConfig = _.get(this.props, 'user.countryAddressConfig.addressConfig', fallbackAddressConfig)
    let hideLandmark = _.get(this, 'props.user.appConfigDetails.HideLandmark', false)
    addressConfig = addressConfig.sort((a, b) => {
      return a.displayOrder - b.displayOrder
    })

    let sortedAddressConfig = addressConfig
    let isUserLandmarkPresent = _.findIndex(sortedAddressConfig, (v) => {
      return v['keyName'] === 'UserLandmark'
    })
    let isLandMarkPresent = _.findIndex(sortedAddressConfig, (v) => {
      return v['keyName'] === 'Landmark'
    })

    if (isUserLandmarkPresent > -1) {
      const swap = (arr, index1, index2) =>
        arr.map((val, idx) => {
          if (idx === index1) return arr[index2]
          if (idx === index2) return arr[index1]
          return val
        })
      const arr = addressConfig
      if (isUserLandmarkPresent == 0) {
        sortedAddressConfig = swap(arr, isUserLandmarkPresent, isLandMarkPresent)
      }
    }

    if (isUserLandmarkPresent < 0 && isLandMarkPresent > 0) {
      const swap = (arr, index1, index2) =>
        arr.map((val, idx) => {
          if (idx === index1) return arr[index2]
          if (idx === index2) return arr[index1]
          return val
        })
      const arr = addressConfig
      sortedAddressConfig = swap(arr, isLandMarkPresent, 0)
    }
    sortedAddressConfig = _.compact(sortedAddressConfig)

    if (Object.keys(_.get(this, 'props.partnerServiceLocation', {})).length) {
      availableDays = AvailDaysComponent(this.props.partnerServiceLocation.WorkingDays || '')
    }
    let divToRender = ''

    let isAddressValueRequire = true
    let isCityValueRequire = true
    let isLandmarkValueRequire = true
    let isUserLandMarkValueRequire = true

    let isDeliveryAddressValueRequire = true
    let isDeliveryCityValueRequire = true
    let isDeliveryLandmarkValueRequire = true
    let isDeliveryUserLandMarkValueRequire = true

    let trimmedUserLandmark
    let trimmedDeliveryUserLandmark

    if (this.props.slotSelectStep !== 2) {
      divToRender = (
        <div className='col-sm-12'>
          <BoxHolder className='request-mode-pickup'>
            <React.Fragment>
              <div className='col-xl-6 col-md-8 col-sm-12 col-xs-12'>
                <div className={'row ' + (this.props.isEditAddressFlag ? 'disable-block' : '')}>
                  {sortedAddressConfig.map((element) => {
                    switch (element.keyName) {
                      case 'Address':
                        isAddressValueRequire = element['mandatory'] ? !!this.props.newAddress.Address : true
                        return (
                          <div className='col-sm-6 mt20 mb8 pickup-address'>
                            <div className='bookFontStyle mb8 overrideCasing address-label'>
                              {element.label}
                              {element.mandatory ? '' : ' ' + getTranslationText('common', 'optional')}
                            </div>
                            <span
                              className={
                                ` bookFontStyle fontSmall char-length-checker` + (window.isDirectionRTL ? ' text-left-align10' : '')
                              }
                            >
                              {`(${
                                (!!_.get(this, 'props.newAddress.Address', '') && _.get(this, 'props.newAddress.Address', '').length) || 0
                              } / ${element.inputLength || 50})`}
                            </span>
                            <Input
                              className={{
                                inputContainer: 'email-input',
                                input: 'mediumFontStyle text-input',
                              }}
                              inputType='text'
                              autoComplete='off'
                              name='Address'
                              placeholder={getTranslationText('request', 'addressExample')}
                              handleOnChange={this.props.handleNewInputAddress}
                              value={this.props.newAddress.Address}
                              isDisabled={_.get(this, 'props.newAddress.Uneditable', false)}
                              maxLength={element.inputLength || '50'}
                              otherProps={{
                                required: true,
                              }}
                            />
                          </div>
                        )
                      case 'Zipcode':
                      case 'PinCode': {
                        return (
                          element.display && (
                            <Input
                              className={{
                                formControl: 'col-sm-6 mt20 mb8 pickup-address',
                                inputContainer: 'email-input',
                                input: 'boldFontStyle text-input',
                                label: 'bookFontStyle mb8 overrideCasing',
                              }}
                              label={`${element.label} ${element.mandatory ? '' : `${getTranslationText('common', 'optional')}`}`}
                              inputType='text'
                              autoComplete='off'
                              name='Zipcode'
                              handleOnChange={this.props.handleNewInputAddress}
                              value={this.props.newAddress.pincode || this.props.newAddress.Zipcode || ''}
                              isDisabled
                            />
                          )
                        )
                      }
                      case 'State':
                        return (
                          <div className='col-sm-6 mt20 mb8 pickup-address'>
                            <div className='bookFontStyle mb8 overrideCasing'>
                              <span>{element.label}</span>
                              {element.mandatory ? '' : ' ' + getTranslationText('common', 'optional')}
                            </div>
                            <Dropdown
                              id='state-address-dropdown'
                              value={
                                !_.isEmpty(this.props.selectedState) ? this.props.selectedState.value : this.props.newAddress.state || ''
                              }
                              options={this.props.user.stateAddressListConfig.map((state) => ({
                                ...state,
                                label: state.State,
                                value: state.State,
                              }))}
                              placeholder={getTranslationText('store', 'selectStateLabel')}
                              handleOnChange={(e) => this.props.onChangeStateSelection(e, 'userLocation')}
                              filterBy='value'
                              searchText='Search'
                              isDisabled={
                                !!(_.get(this, 'props.request.userLocation.State') || _.get(this, 'props.request.userLocation.state'))
                              }
                            />
                          </div>
                        )
                      case 'City':
                        isCityValueRequire = element['mandatory'] ? !!this.props.newAddress.city : true
                        return (
                          <Input
                            className={{
                              formControl: 'col-sm-6 mt20 mb8 pickup-address',
                              inputContainer: 'email-input',
                              input: 'boldFontStyle text-input',
                              label: 'bookFontStyle mb8 overrideCasing',
                            }}
                            label={`${element.label} ${element.mandatory ? '' : `${getTranslationText('common', 'optional')}`}`}
                            inputType='text'
                            name='city'
                            handleOnChange={this.props.handleNewInputAddress}
                            value={this.props.newAddress.city}
                            isDisabled={_.get(this, 'props.newAddress.Uneditable', false)}
                          />
                        )
                      case 'Landmark':
                        isLandmarkValueRequire = element['mandatory']
                          ? !!_.get(this, 'props.newAddress.Landmark') || !!_.get(this, 'props.newAddress.landmark')
                          : true

                        return hideLandmark ? null : (
                          <>
                            <Input
                              className={{
                                formControl: 'col-sm-12 mt20 mb8 pickup-address',
                                inputContainer: 'email-input',
                                input: 'boldFontStyle text-input',
                                label: 'bookFontStyle mb8 overrideCasing',
                              }}
                              label={`${element.label} ${element.mandatory ? '' : `${getTranslationText('common', 'optional')}`}`}
                              inputType='text'
                              name='Landmark'
                              handleOnChange={this.props.handleNewInputAddress}
                              value={_.get(this, 'props.newAddress.Landmark') || _.get(this, 'props.newAddress.landmark') || ''}
                              isDisabled
                            />
                          </>
                        )
                      case 'UserLandmark':
                        trimmedUserLandmark = _.get(this, 'props.newAddress.UserLandmark', '')?.replace(/\s/g, '')
                        isUserLandMarkValueRequire = element['mandatory']
                          ? _.get(this, 'props.newAddress.Uneditable', false)
                            ? true
                            : trimmedUserLandmark?.length >= _.get(element, 'minLength', 1)
                          : true
                        return (
                          <div className='col-sm-12 mt20 mb8 pickup-address'>
                            <div className='bookFontStyle mb8 overrideCasing'>
                              {element.label}
                              {element.mandatory ? '' : ' ' + getTranslationText('common', 'optional')}
                            </div>
                            <span
                              className={
                                `fontSmall bookFontStyle char-length-checker` + (window.isDirectionRTL ? ' text-left-align10' : '')
                              }
                            >
                              {`(${
                                (!!_.get(this, 'props.newAddress.UserLandmark', '') &&
                                  _.get(this, 'props.newAddress.UserLandmark', '').length) ||
                                0
                              } / ${element.inputLength || 50})`}
                            </span>
                            <Input
                              className={{
                                formControl: 'mt20 mb8 pickup-address',
                                inputContainer: 'email-input',
                                input: 'boldFontStyle text-input',
                                label: 'bookFontStyle mb8 overrideCasing',
                              }}
                              inputType='text'
                              name='UserLandmark'
                              handleOnChange={this.props.handleNewInputAddress}
                              value={_.get(this, 'props.newAddress.UserLandmark') || ''}
                              maxLength={element.inputLength || '50'}
                              disabled={_.get(this, 'props.newAddress.Uneditable', false)}
                            />
                          </div>
                        )
                      case 'District':
                        return (
                          <Input
                            className={{
                              formControl: 'col-sm-6 mt20 mb8 pickup-address',
                              inputContainer: 'email-input',
                              input: 'boldFontStyle text-input',
                              label: 'bookFontStyle mb8 overrideCasing',
                            }}
                            label={`${element.label} ${element.mandatory ? '' : `${getTranslationText('common', 'optional')}`}`}
                            inputType='text'
                            name='district'
                            handleOnChange={this.props.handleNewInputAddress}
                            value={_.get(this, 'props.newAddress.District', '')}
                            isDisabled
                          />
                        )
                    }
                  })}
                </div>
              </div>

              <div className={'row mt20 mb28' + (this.props.isEditAddressFlag ? 'disable-block' : '')}>
                <div className={'col-xl-6 col-md-8 col-sm-12 ' + (window.isDirectionRTL ? ' pr20 ' : ' pl20')}>
                  <div className='bookFontStyle mb8 overrideCasing'>{getTranslationText('request', 'tagAddress')}</div>
                  <PillGroup
                    disabled={_.get(this, 'props.newAddress.Uneditable', false)}
                    handlePillSelect={this.props.handleAddressType}
                    prefix='addressType'
                    showImg
                    pills={this.props.addressType}
                  />
                </div>
              </div>
            </React.Fragment>

            {_.includes([17, 23, 43, 52, 46, 63, 65, 3, 64], this.props.selectedMode) ? (
              ''
            ) : (
              <React.Fragment>
                <hr />
                <div className='row'>
                  <div className='col-sm-6 mb8 pickup-address'>
                    <div className='bookFontStyle mb8 overrideCasing'>{getTranslationText('common', 'deliveryAddress')}</div>
                    <div className='boldFontStyle fontMedium inline-ele-Casing'>
                      <Checkbox
                        type='checkbox'
                        value={this.props.sameDeliveryAddressCheck}
                        handleOnChange={this.props.toggleSameDeliveryAddressCheck}
                        otherProps={{
                          style: window.isDirectionRTL ? { marginLeft: '5px' } : { marginRight: '5px' },
                        }}
                      >
                        <span className='chkDeliveryAdd'>{getTranslationText('request', 'deliverSameAddress')}</span>
                      </Checkbox>
                    </div>
                  </div>
                  <div className='col-sm-12'>
                    <div className='row'>
                      <div
                        className={
                          'col-xl-6 col-md-8 col-sm-12 col-xs-12 mb8 pickup-address ' + (window.isDirectionRTL ? ' pr20 ' : ' pl20')
                        }
                      >
                        {this.props.sameDeliveryAddressCheck ? (
                          <div>
                            <div className='row mt8'>
                              {sortedAddressConfig.map((element) => {
                                switch (element.keyName) {
                                  case 'Address':
                                    isAddressValueRequire = element['mandatory'] ? !!this.props.newAddress.Address : true
                                    return (
                                      <div className='col-sm-6 mt20 mb8 pickup-address'>
                                        <div className='bookFontStyle  mb8 overrideCasing'>
                                          {element.label}
                                          {element.mandatory ? '' : ' ' + getTranslationText('common', 'optional')}
                                        </div>
                                        <span
                                          className={
                                            `fontSmall bookFontStyle char-length-checker` +
                                            (window.isDirectionRTL ? ' text-left-align10' : '')
                                          }
                                        >
                                          {`(${
                                            (!!_.get(this, 'props.newAddress.Address', '') &&
                                              _.get(this, 'props.newAddress.Address', '').length) ||
                                            0
                                          } / ${element.inputLength || 50})`}
                                        </span>
                                        <Input
                                          className={{
                                            inputContainer: 'email-input',
                                            input: 'boldFontStyle text-input',
                                            label: 'bookFontStyle mb8 overrideCasing',
                                          }}
                                          inputType='text'
                                          name='Address'
                                          handleOnChange={this.props.handleNewInputAddress}
                                          value={this.props.newAddress.Address}
                                          placeholder={getTranslationText('request', 'addressExample')}
                                          maxLength={element.inputLength || '50'}
                                          isDisabled={_.get(this, 'props.newAddress.Uneditable', false)}
                                        />
                                      </div>
                                    )
                                  case 'Zipcode':
                                  case 'PinCode': {
                                    return (
                                      element.display && (
                                        <Input
                                          className={{
                                            formControl: 'col-sm-6 mt20 mb8 pickup-address',
                                            inputContainer: 'email-input',
                                            input: 'boldFontStyle text-input',
                                            label: 'bookFontStyle mb8 overrideCasing',
                                          }}
                                          label={`${element.label} ${
                                            element.mandatory ? '' : `${getTranslationText('common', 'optional')}`
                                          }`}
                                          inputType='text'
                                          name='Zipcode'
                                          handleOnChange={this.props.handleNewInputAddress}
                                          value={this.props.newAddress.pincode || this.props.newAddress.Zipcode || ''}
                                          isDisabled
                                        />
                                      )
                                    )
                                  }
                                  case 'State':
                                    return (
                                      <div className='col-sm-6 mt20 mb8 pickup-address'>
                                        <div className='bookFontStyle mb8 overrideCasing'>
                                          <span>{element.label}</span>
                                          {element.mandatory ? '' : ' ' + getTranslationText('common', 'optional')}
                                        </div>
                                        <Dropdown
                                          id='state-address-dropdown'
                                          value={
                                            !_.isEmpty(this.props.selectedState)
                                              ? this.props.selectedState.value
                                              : this.props.newAddress.state || ''
                                          }
                                          options={this.props.user.stateAddressListConfig.map((state) => ({
                                            ...state,
                                            label: state.State,
                                            value: state.State,
                                          }))}
                                          placeholder={getTranslationText('store', 'selectStateLabel')}
                                          handleOnChange={(e) => this.props.onChangeStateSelection(e, 'userLocation')}
                                          filterBy='value'
                                          searchText='Search'
                                          isDisabled={
                                            !!(
                                              _.get(this, 'props.request.userLocation.State') ||
                                              _.get(this, 'props.request.userLocation.state')
                                            )
                                          }
                                        />
                                      </div>
                                    )
                                  case 'City':
                                    isCityValueRequire = element['mandatory'] ? !!this.props.newAddress.city : true
                                    return (
                                      <Input
                                        className={{
                                          formControl: 'col-sm-6 mt20 mb8 pickup-address',
                                          inputContainer: 'email-input',
                                          input: 'boldFontStyle text-input',
                                          label: 'bookFontStyle mb8 overrideCasing',
                                        }}
                                        label={`${element.label} ${element.mandatory ? '' : `${getTranslationText('common', 'optional')}`}`}
                                        inputType='text'
                                        name='city'
                                        handleOnChange={this.props.handleNewInputAddress}
                                        value={this.props.newAddress.city}
                                        isDisabled={_.get(this, 'props.newAddress.Uneditable', false)}
                                      />
                                    )
                                  case 'Landmark':
                                    isLandmarkValueRequire = element['mandatory']
                                      ? !!_.get(this, 'props.newAddress.Landmark') || !!_.get(this, 'props.newAddress.landmark')
                                      : true
                                    return hideLandmark ? null : (
                                      <Input
                                        className={{
                                          formControl: 'col-sm-6 mt20 mb8 pickup-address',
                                          inputContainer: 'email-input',
                                          input: 'boldFontStyle text-input',
                                          label: 'bookFontStyle mb8 overrideCasing',
                                        }}
                                        label={`${element.label} ${element.mandatory ? '' : `${getTranslationText('common', 'optional')}`}`}
                                        inputType='text'
                                        name='Landmark'
                                        handleOnChange={this.props.handleNewInputAddress}
                                        value={_.get(this, 'props.newAddress.Landmark') || _.get(this, 'props.newAddress.landmark') || ''}
                                        isDisabled
                                      />
                                    )
                                  case 'UserLandmark':
                                    trimmedUserLandmark = _.get(this, 'props.newAddress.UserLandmark', '')?.replace(/\s/g, '')
                                    isUserLandMarkValueRequire = element['mandatory']
                                      ? _.get(this, 'props.newAddress.Uneditable', false)
                                        ? true
                                        : trimmedUserLandmark?.length >= _.get(element, 'minLength', 1)
                                      : true
                                    return (
                                      <div className='col-sm-12 mt20 mb8 pickup-address'>
                                        <div className='bookFontStyle fontSmall mb8 overrideCasing'>
                                          {element.label}
                                          {element.mandatory ? '' : ' ' + getTranslationText('common', 'optional')}
                                        </div>
                                        <span
                                          className={
                                            `fontSmall bookFontStyle char-length-checker` +
                                            (window.isDirectionRTL ? ' text-left-align10' : '')
                                          }
                                        >
                                          {`(${
                                            (!!_.get(this, 'props.newAddress.UserLandmark', '') &&
                                              _.get(this, 'props.newAddress.UserLandmark', '').length) ||
                                            0
                                          } / ${element.inputLength || 50})`}
                                        </span>
                                        <Input
                                          className={{
                                            formControl: 'mt20 mb8 pickup-address',
                                            inputContainer: 'email-input',
                                            input: 'boldFontStyle text-input',
                                            label: 'bookFontStyle mb8 overrideCasing',
                                          }}
                                          inputType='text'
                                          name='UserLandmark'
                                          handleOnChange={this.props.handleNewInputAddress}
                                          value={_.get(this, 'props.newAddress.UserLandmark') || ''}
                                          maxLength={element.inputLength || '50'}
                                          disabled={_.get(this, 'props.newAddress.Uneditable', false)}
                                        />
                                      </div>
                                    )
                                  case 'District':
                                    return (
                                      <Input
                                        className={{
                                          formControl: 'col-sm-6 mt20 mb8 pickup-address',
                                          inputContainer: 'email-input',
                                          input: 'boldFontStyle text-input',
                                          label: 'bookFontStyle mb8 overrideCasing',
                                        }}
                                        label={`${element.label} ${element.mandatory ? '' : `${getTranslationText('common', 'optional')}`}`}
                                        inputType='text'
                                        name='district'
                                        handleOnChange={this.props.handleNewInputAddress}
                                        value={_.get(this, 'props.newAddress.District', '')}
                                        isDisabled
                                      />
                                    )
                                }
                              })}
                            </div>
                          </div>
                        ) : (
                          <AddressBox
                            sameDeliveryAddressCheck={this.props.sameDeliveryAddressCheck}
                            handleDetectClick={this.props.handleDeliveryDetectClick}
                            handleTypeaheadSelect={this.props.handleTypeaheadSelect}
                            placesAutocomplete={this.props.placesAutocomplete}
                            customLocations={this.props.user.locations}
                            onChangeTypeahead={this.props.onChangeDeliveryTypeahead}
                            locationObtained={this.props.locationObtained}
                            containerComponentName='slots'
                            disableAutoOpenDropdown='true'
                            isLoaderSet={this.props.isLoaderSet}
                            handleUserAddressClick={this.props.handleDeliveryAddressClick}
                            showDetect
                            user={this.props.user}
                          />
                        )}
                        {this.props.placesAutocomplete.landmark &&
                        this.props.request.deliveryLocation &&
                        !this.props.isServiceable &&
                        !this.props.isLoaderSet ? (
                          <span className='serviceable-error'>{getTranslationText('request', 'notServicable')}</span>
                        ) : (
                          ''
                        )}

                        <div className={'row ' + (this.props.sameDeliveryAddressCheck ? 'disable-block' : '')}>
                          {Object.keys(this.props.deliveryAddress).length > 1 &&
                            sortedAddressConfig.map((element) => {
                              switch (element.keyName) {
                                case 'Address':
                                  isDeliveryAddressValueRequire = element['mandatory'] ? !!this.props.deliveryAddress.Address : true
                                  return (
                                    <div className='col-sm-6 mt20 mb8 pickup-address'>
                                      <div className='bookFontStyle mb8 overrideCasing address-label'>
                                        {element.label}
                                        {element.mandatory ? '' : ' ' + getTranslationText('common', 'optional')}
                                      </div>
                                      <span
                                        className={
                                          `fontSmall bookFontStyle char-length-checker` +
                                          (window.isDirectionRTL ? ' text-left-align10' : '')
                                        }
                                      >
                                        {`(${
                                          (!!_.get(this, 'props.deliveryAddress.Address', '') &&
                                            _.get(this, 'props.deliveryAddress.Address', '').length) ||
                                          0
                                        } / ${element.inputLength || 50})`}
                                      </span>
                                      <Input
                                        className={{
                                          inputContainer: 'email-input',
                                          input: 'boldFontStyle text-input',
                                          label: 'bookFontStyle mb8 overrideCasing',
                                        }}
                                        inputType='text'
                                        name='Address'
                                        handleOnChange={this.props.handleNewDeliveryInputAddress}
                                        value={this.props.deliveryAddress.Address}
                                        placeholder={getTranslationText('request', 'addressExample')}
                                        maxLength={element.inputLength || '50'}
                                        isDisabled={this.props.isLoaderSet || _.get(this, 'props.deliveryAddress.Uneditable', false)}
                                      />
                                    </div>
                                  )
                                case 'Zipcode':
                                case 'PinCode': {
                                  return (
                                    element.display && (
                                      <Input
                                        className={{
                                          formControl: 'col-sm-6 mt20 mb8 pickup-address',
                                          inputContainer: 'email-input',
                                          input: 'boldFontStyle text-input',
                                          label: 'bookFontStyle mb8 overrideCasing',
                                        }}
                                        label={`${element.label} ${element.mandatory ? '' : `${getTranslationText('common', 'optional')}`}`}
                                        inputType='text'
                                        name='Zipcode'
                                        handleOnChange={this.props.handleNewDeliveryInputAddress}
                                        value={this.props.deliveryAddress.pincode || this.props.deliveryAddress.Zipcode || ''}
                                        isDisabled
                                      />
                                    )
                                  )
                                }
                                case 'State':
                                  return (
                                    <div className='col-sm-6 mt20 mb8 pickup-address'>
                                      <div className='bookFontStyle mb8 overrideCasing'>
                                        <span>{element.label}</span>
                                        {element.mandatory ? '' : ' ' + getTranslationText('common', 'optional')}
                                      </div>
                                      <Dropdown
                                        id='state-address-dropdown'
                                        value={
                                          !_.isEmpty(this.props.selectedDeliveryAddressState)
                                            ? this.props.selectedDeliveryAddressState.value
                                            : this.props.deliveryAddress.state || ''
                                        }
                                        options={this.props.user.stateAddressListConfig.map((state) => ({
                                          ...state,
                                          label: state.State,
                                          value: state.State,
                                        }))}
                                        placeholder={getTranslationText('store', 'selectStateLabel')}
                                        handleOnChange={(e) => this.props.onChangeStateSelection(e, 'deliveryLocation')}
                                        filterBy='value'
                                        searchText='Search'
                                        isDisabled={
                                          !!(
                                            _.get(this, 'props.request.deliveryLocation.State') ||
                                            _.get(this, 'props.request.deliveryLocation.state')
                                          )
                                        }
                                      />
                                    </div>
                                  )
                                case 'City':
                                  isDeliveryCityValueRequire = element['mandatory'] ? !!this.props.deliveryAddress.city : true
                                  return (
                                    <Input
                                      className={{
                                        formControl: 'col-sm-6 mt20 mb8 pickup-address',
                                        inputContainer: 'email-input',
                                        input: 'boldFontStyle text-input',
                                        label: 'bookFontStyle mb8 overrideCasing',
                                      }}
                                      label={`${element.label} ${element.mandatory ? '' : `${getTranslationText('common', 'optional')}`}`}
                                      inputType='text'
                                      name='city'
                                      handleOnChange={this.props.handleNewDeliveryInputAddress}
                                      value={this.props.deliveryAddress.city}
                                      isDisabled={this.props.isLoaderSet || _.get(this, 'props.deliveryAddress.Uneditable', false)}
                                    />
                                  )
                                case 'Landmark':
                                  isDeliveryLandmarkValueRequire = element['mandatory']
                                    ? !!_.get(this, 'props.deliveryAddress.Landmark') || !!_.get(this, 'props.deliveryAddress.landmark')
                                    : true
                                  return hideLandmark ? null : (
                                    <div className='col-sm-6 mt20 mb8 pickup-address'>
                                      <span
                                        className={
                                          `fontSmall bookFontStyle char-length-checker` +
                                          (window.isDirectionRTL ? ' text-left-align10' : '')
                                        }
                                      >
                                        {`(${
                                          (_.get(this, 'props.deliveryAddress.UserLandmark', '') &&
                                            _.get(this, 'props.deliveryAddress.UserLandmark', '').length) ||
                                          0
                                        } / ${element.inputLength || 50})`}
                                      </span>
                                      <Input
                                        className={{
                                          inputContainer: 'email-input',
                                          input: 'boldFontStyle text-input',
                                          label: 'bookFontStyle mb8 overrideCasing',
                                        }}
                                        label={`${element.label} ${element.mandatory ? '' : `${getTranslationText('common', 'optional')}`}`}
                                        inputType='text'
                                        name='Landmark'
                                        handleOnChange={this.props.handleNewDeliveryInputAddress}
                                        value={
                                          _.get(this, 'props.deliveryAddress.Landmark') ||
                                          _.get(this, 'props.deliveryAddress.landmark') ||
                                          ''
                                        }
                                        maxLength={element.inputLength || '50'}
                                        isDisabled={this.props.isLoaderSet || _.get(this, 'props.deliveryAddress.Uneditable', false)}
                                      />
                                    </div>
                                  )
                                case 'UserLandmark':
                                  trimmedDeliveryUserLandmark = _.get(this, 'props.deliveryAddress.UserLandmark', '')?.replace(/\s/g, '')
                                  isDeliveryUserLandMarkValueRequire = element['mandatory']
                                    ? _.get(this, 'props.deliveryAddress.Uneditable', false)
                                      ? true
                                      : trimmedDeliveryUserLandmark?.length >= _.get(element, 'minLength', 1)
                                    : true
                                  return (
                                    <div className='col-sm-12 mt20 mb8 pickup-address'>
                                      <div className='bookFontStyle fontSmall mb8 overrideCasing'>
                                        {element.label}
                                        {element.mandatory ? '' : ' ' + getTranslationText('common', 'optional')}
                                      </div>
                                      <span
                                        className={
                                          `fontSmall bookFontStyle char-length-checker` +
                                          (window.isDirectionRTL ? ' text-left-align10' : '')
                                        }
                                      >
                                        {`(${
                                          (_.get(this, 'props.deliveryAddress.UserLandmark', '') &&
                                            _.get(this, 'props.deliveryAddress.UserLandmark', '').length) ||
                                          0
                                        } / ${element.inputLength || 50})`}
                                      </span>
                                      <Input
                                        className={{
                                          formControl: 'mt20 mb8 pickup-address',
                                          inputContainer: 'email-input',
                                          input: 'boldFontStyle text-input',
                                          label: 'bookFontStyle mb8 overrideCasing',
                                        }}
                                        inputType='text'
                                        name='UserLandmark'
                                        handleOnChange={this.props.handleNewDeliveryInputAddress}
                                        value={_.get(this, 'props.deliveryAddress.UserLandmark') || ''}
                                        maxLength={element.inputLength || '50'}
                                        disabled={this.props.isLoaderSet || _.get(this, 'props.deliveryAddress.Uneditable', false)}
                                      />
                                    </div>
                                  )
                                case 'District':
                                  return (
                                    <Input
                                      className={{
                                        formControl: 'col-sm-6 mt20 mb8 pickup-address',
                                        inputContainer: 'email-input',
                                        input: 'boldFontStyle text-input',
                                        label: 'bookFontStyle mb8 overrideCasing',
                                      }}
                                      label={`${element.label} ${element.mandatory ? '' : `${getTranslationText('common', 'optional')}`}`}
                                      inputType='text'
                                      name='district'
                                      handleOnChange={this.props.handleNewInputAddress}
                                      value={_.get(this, 'props.deliveryAddress.District', '')}
                                      isDisabled
                                    />
                                  )
                              }
                            })}
                        </div>

                        {Object.keys(this.props.deliveryAddress).length > 1 && (
                          <div className={'col-sm-12 ' + (window.isDirectionRTL ? ' pr0 ' : ' pl0')}>
                            <div className='bookFontStyle mb8 mt20'>{getTranslationText('request', 'tagAddress')}</div>
                            <PillGroup
                              handlePillSelect={this.props.handleDeliveryAddressType}
                              prefix='deliveryAddressType'
                              showImg
                              pills={this.props.deliveryAddressType}
                              disabled={this.props.isLoaderSet || _.get(this, 'props.deliveryAddress.Uneditable', false)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
            <div className='buttons-container'>
              {!this.props.isEditAddressFlag ? (
                <MediumButton
                  className='button-small button-ghost'
                  buttonText={getTranslationText('common', 'backLabel')}
                  onClick={() => {
                    this.props.handleBackButton()
                    pushToAnalytics('Delivery_address_details_back_button_clicked', {
                      ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
                      ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
                    })
                  }}
                />
              ) : (
                ''
              )}
              <MediumButton
                disabled={
                  (_.includes([43, 52, 3, 64], this.props.selectedMode) ? !courierBtnStatus : !btnStatus) ||
                  !isAddressValueRequire ||
                  !isCityValueRequire ||
                  !isLandmarkValueRequire ||
                  !isUserLandMarkValueRequire ||
                  !isDeliveryAddressValueRequire ||
                  !isDeliveryCityValueRequire ||
                  !isDeliveryLandmarkValueRequire ||
                  !isDeliveryUserLandMarkValueRequire
                }
                onClick={this.props.handleContinue}
                buttonText={getTranslationText('common', 'continue')}
                className='button-small'
              />
            </div>
          </BoxHolder>
        </div>
      )
    } else {
      const isDropOffLocations = _.get(this.props, 'request.requestModesData.dropOffLocations', []).length
      const baseURL = window.location.origin
      const showWalkinPromptDetails =
        _.includes([2, 12], this.props.selectedMode) && _.get(this, 'props.user.appConfigDetails.showWalkinPromptDetails', false)
      divToRender = (
        <div style={{ width: '100%' }}>
          {_.includes([2, 12, 17, 32, 57, 58], this.props.selectedMode) ||
          (_.includes([23], this.props.selectedMode) && isDropOffLocations) ? (
            <div className='col-sm-12'>
              {showWalkinPromptDetails && (
                <p className='custom-heightlight-text-box'>
                  <InfoAlert />
                  {getTranslationText('request', 'afterSchedulingAppointmentWalkin')}
                </p>
              )}
              <BoxHolder className='remove-mt fixed-height-override hr-margin-bottom'>
                <div className='row'>
                  <div className='col-sm-6'>
                    <div className='dropoff-location-holder'>
                      <div className='dropoff-location'>
                        <div className='mediumFontStyle fontSmall mb8'>
                          <span>{this.props.partnerServiceLocation.NameOfFirm || this.props.partnerServiceLocation.City}</span>
                        </div>
                        <div className='bookFontStyle fontSmall mb8'>
                          <span>{this.props.partnerServiceLocation.ServiceLocationName}</span>
                        </div>
                        {_.includes([17, 23, 57, 58], this.props.selectedMode) && (
                          <div className='bookFontStyle fontSmall mb8'>
                            <span>{location.City}</span>
                          </div>
                        )}
                        <div className='bookFontStyle fontSmall mb8'>
                          <span>{this.props.partnerServiceLocation.address}</span>
                        </div>
                        <div className='mediumFontStyle fontSmall mb5 mt5'>
                          {getTranslationText('common', 'contact')}: {this.props.partnerServiceLocation.ContactNo1}
                        </div>
                        {availableDays && availableDays.length > 0 ? (
                          <div className='bookFontStyle mb4 working-days-component'>{availableDays}</div>
                        ) : (
                          ''
                        )}
                        {this.props.partnerServiceLocation.WorkingFrom && this.props.partnerServiceLocation.WorkingTo ? (
                          <div dir='ltr' className='bookFontStyle fontSmall mb20'>
                            {moment(this.props.partnerServiceLocation.WorkingFrom, 'HH:mm:ss').format('h:mm a') +
                              ' - ' +
                              moment(this.props.partnerServiceLocation.WorkingTo, 'HH:mm:ss').format('h:mm a')}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <div className='map-holder-small'>
                      <MapComponent
                        mapStyles={{ disableDefaultUI: true, zoomControl: false, scrollwheel: false }}
                        markers={(() => {
                          let origin = this.props.request.userLocation
                          origin = {
                            lat: parseFloat(origin.Lat || origin.latitude),
                            lng: parseFloat(origin.Lng || origin.longitude),
                            isUserAddress: true,
                            icon: `${baseURL}/commonAssets/UserAddressMarker.png`,
                            title: 'You',
                            animation: 'google.maps.Animation.DROP',
                          }
                          let dest = this.props.request.partnerServiceLocation
                          dest = {
                            lat: parseFloat(dest.Lat),
                            lng: parseFloat(dest.Lng),
                            text: 'Service center',
                            isSelectedPSL: true,
                            icon: `${baseURL}/commonAssets/ping.gif`,
                            title: this.props.partnerServiceLocation.ServiceLocationName,
                          }
                          return [origin, dest]
                        })()}
                        mapProps={{
                          center: {
                            lat: parseFloat(this.props.request.partnerServiceLocation.Lat),
                            lng: parseFloat(this.props.request.partnerServiceLocation.Lng),
                          },
                          zoom: 12,
                        }}
                        mapHeight='100%'
                        language={_.get(this, 'props.user.selectedCountryData.countryConfig.LanguageCode.DEFAULT', 'en')}
                      />
                    </div>
                    <div className='special-link-txt fontSmall' onClick={this.props.dropoffDirections}>
                      {getTranslationText('request', 'directions')}
                    </div>
                  </div>
                </div>
              </BoxHolder>
            </div>
          ) : (
            ''
          )}
          <div className='col-sm-12'>
            <BoxHolder className='fixed-height-override remove-mt'>
              <div className='row'>
                <div className='col-sm-6'>
                  <div className='bookFontStyle mb8 overrideCasing '>
                    <span>{getTranslationText('request', 'selectPrefferedDay')}</span>
                  </div>
                  <PillGroup handlePillSelect={this.props.handleDateSelect} prefix='datepill' pills={this.props.datesToShow} />
                </div>
                <div className='col-sm-6'>
                  <div className='bookFontStyle mb8 overrideCasing '>
                    <span>{getTranslationText('request', 'timeSlot')}</span>
                  </div>
                  <PillGroup handlePillSelect={this.props.handleTimeSelect} prefix='timepill' pills={this.props.timesToShow} />
                </div>
                {_.includes([1, 11, 17, 23], this.props.selectedMode) ? (
                  <div className='bookFontStyle mb8'>{getTranslationText('request', 'dateTimeNote')}</div>
                ) : (
                  ''
                )}
                <div className='col-sm-12'>
                  <div className='buttons-container'>
                    <MediumButton
                      className='button-small button-ghost'
                      buttonText={getTranslationText('common', 'backLabel')}
                      onClick={() => {
                        this.props.handleBackButton()
                        pushToAnalytics('Date_and_time_back_button_clicked', {
                          ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
                          ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
                        })
                      }}
                    />
                    <MediumButton
                      className='button-small'
                      disabled={this.props.disableContinue}
                      onClick={() => {
                        this.props.handleContinue()
                        pushToAnalytics('Date_and_time_continue_button_clicked', {
                          ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
                          ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
                        })
                      }}
                      btnLoader={this.props.BtnStatusLoader}
                      buttonText={getTranslationText('common', 'continue')}
                    />
                  </div>
                </div>
              </div>
            </BoxHolder>
          </div>
        </div>
      )
    }
    return <div className='request-slots row'>{divToRender}</div>
  }

  static propTypes = {
    request: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    handleBackButton: PropTypes.func.isRequired,
    handleContinue: PropTypes.func.isRequired,
    disableContinue: PropTypes.bool.isRequired,
    partnerServiceLocation: PropTypes.object.isRequired,
    dropoffDirections: PropTypes.func.isRequired,
    selectedMode: PropTypes.number.isRequired,
    slotSelectStep: PropTypes.number.isRequired,
    datesToShow: PropTypes.array.isRequired,
    timesToShow: PropTypes.array.isRequired,
    addressType: PropTypes.array.isRequired,
    deliveryAddressType: PropTypes.array.isRequired,
    handleDateSelect: PropTypes.func.isRequired,
    handleTimeSelect: PropTypes.func.isRequired,
    handleAddressType: PropTypes.func.isRequired,
    handleDeliveryAddressType: PropTypes.func.isRequired,
    newAddress: PropTypes.string.isRequired,
    deliveryAddress: PropTypes.string.isRequired,
    toggleSameDeliveryAddressCheck: PropTypes.func.isRequired,
    sameDeliveryAddressCheck: PropTypes.bool,
    handleNewInputAddress: PropTypes.func.isRequired,
    handleNewDeliveryInputAddress: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    newAddressFlg: PropTypes.bool.isRequired,
    newDeliveryAddressFlg: PropTypes.bool.isRequired,
    BtnStatusLoader: PropTypes.bool,
    landmark: PropTypes.string.isRequired,
    placesAutocomplete: PropTypes.object.isRequired,
    onChangeTypeahead: PropTypes.func.isRequired,
    onChangeDeliveryTypeahead: PropTypes.func.isRequired,
    handleTypeaheadSelect: PropTypes.func.isRequired,
    handleDeliveryDetectClick: PropTypes.func.isRequired,
    locationObtained: PropTypes.func.isRequired,
    handleDeliveryAddressClick: PropTypes.func.isRequired,
    isEditAddress: PropTypes.bool,
    isEditAddressFlag: PropTypes.bool,
    isLoaderSet: PropTypes.bool.isRequired,
    isServiceable: PropTypes.bool,
    userLocation: PropTypes.object,
  }

  static defaultProps = {
    partnerServiceLocation: {},
  }
}

export const PlanHandlingTab = (props) => {
  return (
    <div className='issue-type'>
      {props.needToActivatePlanFlg ? (
        <PlanActivationComponent
          request={props.request}
          handlePlanActivationChange={props.handlePlanActivationChange}
          activationPageDetails={props.activationPageDetails}
          handleDatePickerOnChange={props.handleDatePickerOnChange}
          handleBackButton={props.handleBackButton}
          enableActivation={props.enableActivation}
          handleContinue={props.handleContinue}
          openActivationInvoiceImageViewer={props.openActivationInvoiceImageViewer}
          BtnStatusLoader={props.BtnStatusLoader}
        />
      ) : (
        <SelectPlanType {...props} />
      )}
    </div>
  )
}

PlanHandlingTab.propTypes = {
  handlePlanActivationChange: PropTypes.func.isRequired,
  activationPageDetails: PropTypes.func.isRequired,
  handleDatePickerOnChange: PropTypes.func.isRequired,
  handleBackButton: PropTypes.bool.isRequired,
  handleContinue: PropTypes.bool.isRequired,
  BtnStatusLoader: PropTypes.bool.isRequired,
  enableActivation: PropTypes.bool.isRequired,
  request: PropTypes.object.isRequired,
  needToActivatePlanFlg: PropTypes.bool.isRequired,
  openActivationInvoiceImageViewer: PropTypes.func,
}

export const SelectPlanType = (props) => {
  useEffect(() => {
    pushToAnalytics('Request_type_page', {
      ConsumerID: _.get(props, 'request.selectedDeviceInfo.ConsumerID', ''),
      ConsumerProductID: _.get(props, 'request.selectedDeviceInfo.ConsumerProductID', ''),
    })
  })
  const appName = _.get(props, 'user.appConfigDetails.app', '')
  let isSubsciptionRepairFlow = ['OneplusNA-Web'].includes(appName)
  let isServifyWeb = ['Consumer-Web'].includes(appName)
  let planGroups =
    isSubsciptionRepairFlow && _.get(props, 'request.selectedDeviceInfo.DeviceState.DeviceStateMeta.ShowRaiseClaim')
      ? props.request.eligiblePlansNew && props.request.eligiblePlansNew.filter((group) => _.includes([-7, -2, 0, 1], group.GroupStatus))
      : [
          {
            GroupID: 0,
            GroupStatus: 1,
            PlanObject: props.request.selectedDeviceInfo.PolicyDetails.filter(
              (planInfo) => planInfo.Status === 1 && planInfo.NoOfRepairsAllowed - planInfo.NoOfRepairsUsed > 0
            ),
          },
        ]
  return (
    <BoxHolder className='fixed-height-override'>
      <div className='mode-options'>
        <Loader loaderState={!planGroups}>
          {planGroups ? (
            <div className='d-flex-wrap'>
              {planGroups
                .sort((a, b) => b.GroupStatus - a.GroupStatus)
                .map((group) => {
                  let handler = group.GroupStatus === 1 ? props.handleDamageTypeSelect : props.handlePlanActivationSelect
                  return group.PlanObject.map((plan, idx) => {
                    return !plan.HideDialog ? (
                      <div
                        className={group.GroupStatus === -7 ? 'disabledDiv' : ''}
                        id={
                          group.GroupStatus === 1
                            ? 'radio-' + plan.SoldPlanID
                            : plan.SoldPlanID
                            ? 'radio-' + plan.SoldPlanID
                            : 'unpurchased-' + plan.PlanID
                        }
                        onClick={(e) => {
                          handler(e)
                          pushToAnalytics('Request_type_clicked', {
                            Request_type: plan.PlanName,
                            ConsumerID: _.get(props, 'request.selectedDeviceInfo.ConsumerID', ''),
                            ConsumerProductID: _.get(props, 'request.selectedDeviceInfo.ConsumerProductID', ''),
                          })
                        }}
                      >
                        <RadioBox
                          isSelected={
                            (group.GroupStatus === 1 ? plan.SoldPlanID : plan.SoldPlanID ? plan.SoldPlanID : plan.PlanID) ===
                            props.selectedPlanID
                          }
                          key={`plan-${idx}`}
                        >
                          <div className='plan-type-container'>
                            <img src={plan.PlanImage} className='plan-type-image' />
                            <div className='option-text'>
                              <div className='text-heading boldFontStyle'>
                                {getTranslationText('request', 'repairUnder')} {plan.PlanName}
                              </div>
                              <div className='text-content bookFontStyle'>{plan.PlanDescription}</div>
                            </div>
                          </div>
                        </RadioBox>
                      </div>
                    ) : (
                      ''
                    )
                  })
                })}
              {!props.disableNormalRepair ? (
                <div
                  id={'radio--1'}
                  onClick={(e) => {
                    props.handleDamageTypeSelect(e)
                    pushToAnalytics('Request_type_clicked', {
                      Request_type: 'normalRepair',
                      ConsumerID: _.get(props, 'request.selectedDeviceInfo.ConsumerID', ''),
                      ConsumerProductID: _.get(props, 'request.selectedDeviceInfo.ConsumerProductID', ''),
                    })
                  }}
                >
                  <RadioBox
                    className={{ container: 'plan-type-container' }}
                    isSelected={props.selectedPlanID === -1}
                    key={`normalRepair`}
                    image={isServifyWeb ? `/servify/images/repairImg.png` : `/${appName.split('-')[0].toLowerCase()}/images/repairImg.png`}
                  >
                    <>
                      <div className='option-text'>
                        <div className='text-heading boldFontStyle'>{getTranslationText('common', 'normalRepair')}</div>
                        <div className='text-content bookFontStyle'>{getTranslationText('common', 'normalRepairDesc')}</div>
                      </div>
                    </>
                  </RadioBox>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </Loader>
      </div>
      <div className='buttons-container mt28'>
        <MediumButton
          className='button-small button-ghost'
          buttonText={getTranslationText('common', 'backLabel')}
          onClick={() => {
            props.handleBackButton()
            pushToAnalytics('Request_type_back_button_clicked', {
              ConsumerID: _.get(props, 'request.selectedDeviceInfo.ConsumerID', ''),
              ConsumerProductID: _.get(props, 'request.selectedDeviceInfo.ConsumerProductID', ''),
            })
          }}
        />
        <MediumButton
          className='button-small'
          disabled={!props.selectedPlanID}
          onClick={() => {
            props.handleContinue()
            pushToAnalytics('Request_type_continue_button_clicked', {
              ConsumerID: _.get(props, 'request.selectedDeviceInfo.ConsumerID', ''),
              ConsumerProductID: _.get(props, 'request.selectedDeviceInfo.ConsumerProductID', ''),
            })
          }}
          btnLoader={props.BtnStatusLoader}
          buttonText={getTranslationText('common', 'continue')}
        />
      </div>
    </BoxHolder>
  )
}

SelectPlanType.propTypes = {
  request: PropTypes.object.isRequired,
  handleBackButton: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
  handleDamageTypeSelect: PropTypes.func.isRequired,
  handlePlanActivationSelect: PropTypes.func.isRequired,
  selectedPlanID: PropTypes.number,
  BtnStatusLoader: PropTypes.bool,
  disableNormalRepair: PropTypes.bool,
}

SelectPlanType.defaultProps = {
  issuesForType: [],
}

export const PlanActivationComponent = (props) => {
  let instructions = _.get(props, 'request.activationPlanDetails.PlanConfig.activationCodeInstructions', '')
  let curdate = new Date()
  const dateFormat = _.get(props, 'user.selectedCountryData.countryConfig.DateFormat.DEFAULT', 'DD/MM/YYYY')
  const language = localStorage.getItem('languageCode')
  const locale = _.get(this, 'props.user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en-IN')
  return (
    <BoxHolder className='fixed-height-override plan-activation-component'>
      <div className='row'>
        <div className='col-md-6'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='plan-info-container mb28'>
                <div className='plan-img'>
                  <img src={props.request.activationPlanDetails.PlanImage} />
                </div>
                <div className='plan-descp'>
                  <div className='boldFontStyle fontMedium'>{props.request.activationPlanDetails.PlanName}</div>
                  <div className='bookFontStyle fontMedium'>{props.request.activationPlanDetails.PlanHeader}</div>
                </div>
              </div>
            </div>
            <Input
              className={{
                formControl: 'col-sm-12 mb8',
                inputContainer: 'plan-activation-code-ip mediumFontStyle',
                label: 'bookFontStyle fontSmall',
              }}
              label={getTranslationText('request', 'activationCode')}
              inputType='text'
              name='activationCode'
              maxLength='8'
              placeholder={getTranslationText('request', 'typeHere')}
              handleOnChange={props.handlePlanActivationChange}
              value={props.activationPageDetails.activationCode}
            />
            {props.request.activationPlanDetails.PlanConfig.RequiresDateOfPurchase &&
            props.request.selectedDeviceInfo.VerificationStateMeta.VerificationStateID !== 2 ? (
              <div className='col-sm-12 mb8'>
                <div className='mb4 bookFontStyle fontSmall'>{getTranslationText('request', 'enterDop')}</div>
                <DatePicker
                  name='activationDOP'
                  placeholder={dateFormat}
                  format={dateFormat.toLowerCase()}
                  minDay={new Date(curdate.getFullYear() - 10, curdate.getMonth(), curdate.getDate())}
                  maxDay={new Date()}
                  handleOnChange={(e) => props.handleDatePickerOnChange(e, 'activationDOP')}
                  date={props.activationPageDetails.activationDOP}
                  locale={locale}
                  language={language}
                ></DatePicker>
              </div>
            ) : (
              ''
            )}
            {props.request.activationPlanDetails.PlanConfig.RequiresProductPurchaseCost ? (
              <Input
                className={{
                  formControl: 'col-sm-12 mb8',
                  inputContainer: 'plan-activation-code-ip mediumFontStyle',
                  label: 'bookFontStyle fontSmall',
                }}
                label={getTranslationText('request', 'enterPurchaseCost')}
                inputType='text'
                name='activationPurchaseCost'
                maxLength='8'
                placeholder={getTranslationText('request', 'typeHere')}
                handleOnChange={props.handlePlanActivationChange}
                value={props.activationPageDetails.activationPurchaseCost}
              />
            ) : (
              ''
            )}
            {props.request.activationPlanDetails.PlanConfig.RequiresInvoiceOfDevice &&
            props.request.selectedDeviceInfo.VerificationStateMeta.VerificationStateID !== 2 ? (
              <div className='col-sm-12 mb8'>
                <div className='bookFontStyle fontSmall mb4'>{getTranslationText('request', 'uploadInvoiceOfDevice')}</div>
                <div className='plan-activation-code-ip'>
                  {props.activationPageDetails.activationInvoiceFiles.map((file, index) => {
                    return (
                      <div key={index} className='image-div viewable' onClick={() => props.openActivationInvoiceImageViewer(index)}>
                        <div className='image-holder'>
                          <img src={URL.createObjectURL(file)} />
                        </div>
                      </div>
                    )
                  })}
                  <div className='image-div'>
                    <div className='image-holder'>
                      <span>+</span>
                    </div>
                    <InputComponent
                      type='file'
                      name='activationInvoiceFiles'
                      accept='image/*'
                      onChange={props.handlePlanActivationChange}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className='col-sm-12 mt28'>
              <div className='buttons-container'>
                <MediumButton
                  className='button-small button-ghost'
                  buttonText={getTranslationText('common', 'backLabel')}
                  onClick={props.handleBackButton}
                />
                <MediumButton
                  className='button-small'
                  disabled={!props.enableActivation}
                  onClick={props.handleContinue}
                  btnLoader={props.BtnStatusLoader}
                  buttonText={getTranslationText('common', 'continue')}
                />
              </div>
            </div>
          </div>
        </div>
        {instructions ? (
          <div className={'col-md-6 plan-help-descp'}>
            <Heading title={getTranslationText('request', 'findActivationCode')} />
            <div className='mt20 instructions-text' dangerouslySetInnerHTML={{ __html: instructions }} />
          </div>
        ) : (
          ''
        )}
      </div>
    </BoxHolder>
  )
}

PlanActivationComponent.propTypes = {
  request: PropTypes.object.isRequired,
  activationPageDetails: PropTypes.string.isRequired,
  enableActivation: PropTypes.bool.isRequired,
  handlePlanActivationChange: PropTypes.func.isRequired,
  handleBackButton: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
  BtnStatusLoader: PropTypes.bool,
  openActivationInvoiceImageViewer: PropTypes.func,
  handleDatePickerOnChange: PropTypes.func,
}

const CategorizedIssueList = ({ listItems, handleIssueSelect, heading }) => {
  const categoryIcon = listItems && listItems.length > 0 ? listItems[0].CategoryIcon : null
  return (
    <div className='issue-list'>
      <h6 className='issue-list-heading text-center boldFontStyle'>
        {' '}
        {categoryIcon && <img src={categoryIcon} className='mr8' height='30px' />} {' ' + heading}
      </h6>
      <div className='issue-category-container'>
        {listItems &&
          listItems.length > 0 &&
          listItems.map(
            (item) =>
              item && (
                <Checkbox
                  key={item.id}
                  handleOnChange={handleIssueSelect}
                  value={item.isSelected}
                  className={{
                    container: `issue-list-item ${item.isSelected ? 'issue-list-item--selected' : ''}`,
                    label: 'issue-list-label',
                  }}
                  id={`issuepill-${item.id}`}
                  type='tabs'
                >
                  {item.isSelected && <IssueCheckIcon className='issue-icon' />}
                  {item.text}
                </Checkbox>
              )
          )}
      </div>
    </div>
  )
}

export const ShowIssuesByCategory = (props) => {
  // let issuesList =
  let categories = {}
  props.issuesForType.map((issue) => {
    issue.id = issue.IssueID
    if (!(categories[issue.Category] && categories[issue.Category].length)) categories[issue.Category] = []
    categories[issue.Category].push(issue)
  })
  return (
    <div className='issue-selection-container'>
      {props.issuesForType && props.issuesForType.length
        ? Object.keys(categories).map((issue, index) => {
            return (
              <CategorizedIssueList key={index} heading={issue} listItems={categories[issue]} handleIssueSelect={props.handleIssueSelect} />
            )
          })
        : ''}
    </div>
  )
}

ShowIssuesByCategory.propTypes = {
  handleIssueSelect: PropTypes.func.isRequired,
  issuesForType: PropTypes.array.isRequired,
}

export class ConfirmDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tnc_WIPE_DATA: false,
      tnc_LossTheft_DATA: false,
      advanceExchangeConsentDATA_0: false,
      advanceExchangeConsentDATA_1: false,
      selectedClaimConsent: {},
      repairLegalConfig: this.props.request.selectedMode?.legalConfig,
    }
  }

  componentDidMount = () => {
    const csr_id = _.get(this.props, 'request.raisedRequest.ConsumerServiceRequestID', undefined)
    const isBuyOutServiceTypeID = _.includes([63], parseInt(this.props.request.selectedMode.ServiceTypeID))
    const appName = _.get(this.props, 'user.appConfigDetails.app', '')
    let isAirtelUser = ['Airtel-Web'].includes(appName)
    let SoldPlanCoverageID = _.get(this.props, 'request.selectedDamageType.SoldPlanCoverageID')
    let SoldPlanID = _.get(this.props, 'request.selectedDamageType.SoldPlanID')
    const isReassignServiceLocationFlow = !_.get(this, 'props.track.trackDetails.isReassignServiceLocationFlow', false)
    let isThumbEWCoverage =
      ['ThumbUS-Web'].includes(_.get(this, 'props.user.appConfigDetails.app', '')) &&
      [_.get(this, 'props.request.selectedDamageType.CoverageType', ''), _.get(this, 'props.track.trackDetails.coverageType', '')].includes(
        'EW'
      )
    if (
      !isThumbEWCoverage &&
      isReassignServiceLocationFlow &&
      !isBuyOutServiceTypeID &&
      !isAirtelUser &&
      SoldPlanCoverageID &&
      SoldPlanID
    ) {
      let advancePayReqObj = {
        ConsumerProductID: _.get(this.props, 'request.selectedDeviceInfo.ConsumerProductID'),
        SoldPlanCoverageID,
        SoldPlanID,
        ConsumerServiceRequestID: csr_id,
        RaisingRequestServiceTypeID: _.get(this.props, 'request.selectedMode.ServiceTypeID'),
        CountryID: _.get(this.props, 'user.selectedCountryData.CountryID'),
        damageId:
          _.get(this, 'props.request.claimFormDetails.typesOfDamage', []).find((damageType) => damageType.isSelected)?.id || undefined,
      }
      this.props.setglobalLoaderStatus(true)
      this.props
        .getAdminFeeDetails(advancePayReqObj)
        .then((res) => {
          setPay(res.data.data)
          this.props.setglobalLoaderStatus(false)
        })
        .catch(() => {
          this.props.setglobalLoaderStatus(false)
        })
    }
    pushToAnalytics('Review_details_page', {
      ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
      ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
    })
    const defaultLegalConfigs = this.props.user.claimLegalConfigs?.LegalConfigs?.map((config) => {
      if (config.legalConfig?.LegalConfigCode === 'TNC_AGREEMENT') {
        return {
          ...config,
          Active: false,
        }
      } else {
        return {
          ...config,
          Selected: false,
        }
      }
    })
    this.props.setClaimLegalConfigs({ LegalConfigs: defaultLegalConfigs })
  }

  toggleRepairConsentClaimCheck = async (consentDetails) => {
    let defaultLegalConfigs = this.state.repairLegalConfig?.map((config) => {
      if (config.CSRConsentConfigID === consentDetails?.CSRConsentConfigID) {
        return {
          ...config,
          Selected: !config?.Selected,
        }
      }
      return config
    })

    await this.setState(
      {
        repairLegalConfig: defaultLegalConfigs,
      },
      () => {
        this.props.setClaimLegalConfigs({ LegalConfigs: defaultLegalConfigs })
      }
    )
  }

  toggleConsentClaimCheck = (checkbox, consent) => {
    this.setState(
      {
        [checkbox]: !this.state[checkbox],
        selectedClaimConsent: consent,
      },
      () => {
        if (!this.state.tnc_WIPE_DATA) {
          this.setState({
            tnc_LossTheft_DATA: false,
          })
        }
      }
    )
    if (checkbox === 'tnc_LossTheft_DATA') {
      const defaultLegalConfigs = this.props.user.claimLegalConfigs?.LegalConfigs.map((config) => {
        if (config.legalConfig?.LegalConfigCode === 'TNC_AGREEMENT') {
          return {
            ...config,
            Active: !this.state[checkbox],
          }
        }
        return config
      })
      this.props.setClaimLegalConfigs({ LegalConfigs: defaultLegalConfigs })
    }
  }

  render = () => {
    let requestMode
    let ServiceTypeID = parseInt(this.props.request.selectedMode.ServiceTypeID)
    if (this.props.request.selectedMode) {
      if (_.includes([1, 11, 31], ServiceTypeID)) {
        requestMode = 'Pickup'
      } else if (_.includes([2, 12, 32], ServiceTypeID)) {
        requestMode = 'Carryin'
      } else if (_.includes([17, 23, 57, 58], ServiceTypeID)) {
        requestMode = 'Drop Off'
      } else if (ServiceTypeID === 5) {
        requestMode = 'Installation'
      } else if (ServiceTypeID === 6) {
        requestMode = 'Demo'
      } else if (ServiceTypeID === 7) {
        requestMode = 'Maintenance Service'
      } else if (ServiceTypeID === 43) {
        requestMode = 'Door to Door'
      } else if (ServiceTypeID === 52) {
        requestMode = 'Courier'
      } else if (ServiceTypeID === 46) {
        requestMode = 'Delivery'
      } else if (ServiceTypeID === 63) {
        requestMode = 'Reimbursement'
      } else if (ServiceTypeID === 65) {
        requestMode = 'Replacement'
      } else if (_.includes([64, 3], ServiceTypeID)) {
        requestMode = 'Onsite'
      } else {
        requestMode = 'Pickup'
      }
    }
    let productImageUrl = _.get(this.props, 'user.appConfigDetails.productImageUrl')
    let locale = _.get(this.props, 'user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
    let currencyCode = _.get(this.props, 'user.selectedCountryData.CurrencyCode')
    let flag_THEFT_OR_LOSS =
      !!_.get(this, 'props.user.claimLegalConfigs.LegalConfigs', []).length &&
      _.includes(
        [_.get(this, 'props.request.selectedDamageType.CoverageType', ''), _.get(this, 'props.track.trackDetails.coverageType', '')],
        'THEFT_OR_LOSS'
      )
    let consentCheckBoxValidationCheck = false
    const isRepairFlow = [1, 11, 2, 12, 17, 23, 66, 46, 65].includes(ServiceTypeID)
    const isReassignServiceLocationFlow = !_.get(this, 'props.track.trackDetails.isReassignServiceLocationFlow', false)
    let isMandotaryConsentSelected = false
    let isOptionalConsentSelected = false

    if (isRepairFlow && isReassignServiceLocationFlow && _.get(this, 'props.request.selectedMode.legalConfig', []).length) {
      isMandotaryConsentSelected = _.get(this, 'props.user.claimLegalConfigs.LegalConfigs', []).map((v) =>
        !v.ConsentOptional ? v.Selected : true
      )
      isOptionalConsentSelected = !_.get(this, 'props.user.claimLegalConfigs.LegalConfigs', []).find((v) =>
        v.ConsentOptional && v.UnselectedText ? v.Selected : true
      )
    }
    consentCheckBoxValidationCheck = !_.every(isMandotaryConsentSelected)
    let { address, City, State, Zipcode } = _.get(this, 'props.request.partnerServiceLocation', {})

    let isThumbEWCoverage =
      ['ThumbUS-Web'].includes(_.get(this, 'props.user.appConfigDetails.app', '')) &&
      [_.get(this, 'props.request.selectedDamageType.CoverageType', ''), _.get(this, 'props.track.trackDetails.coverageType', '')].includes(
        'EW'
      )
    const isConfirmAndPayBtnEnabled = isThumbEWCoverage
      ? false
      : (isReassignServiceLocationFlow && _.get(this.props, 'request.selectedMode.tokenizeFlow', false)) ||
        (_.get(this.props, 'request.adminFeeDetails.AdvanceChargeApplicable', false) &&
          !_.get(this.props, 'request.adminFeeDetails.PaymentStatus', true) &&
          _.get(this.props, 'request.adminFeeDetails.PaymentAmount', 0) > 0) ||
        (!_.get(this.props, 'request.raisedRequest.ConsumerServiceRequestID') &&
          _.get(this.props, 'request.adminFeeDetails.PaymentAmount', 0) > 0 &&
          (_.get(this.props, 'request.selectedMode').hasOwnProperty('IsAdvanceChargeApplicable')
            ? _.get(this.props, 'request.selectedMode.IsAdvanceChargeApplicable')
            : _.get(this.props, 'request.requestModesData.IsAdvanceChargeApplicable'))) ||
        (_.get(this, 'props.request.selectedMode.ServiceTypeID', 0) === 65 &&
          _.get(this, 'props.request.selectedMode.defectiveAmount', 0) > 0)
    const showWalkinPromptDetails =
      [2, 12].includes(ServiceTypeID) && _.get(this, 'props.user.appConfigDetails.showWalkinPromptDetails', false)
    return (
      <div className='confirm-details'>
        <BoxHolder className='confirm-box-holder remove-mt'>
          <div className='device-info-wrapper'>
            {this.props.selectedDeviceList.map((item, index) => {
              let selectedReason = (item.reasonsList || []).find((val) => val.isSelected) || {}
              let returnReason = selectedReason.text
              if ((selectedReason.text || '').toLowerCase() === 'others') {
                returnReason = item.returnDescption
              }
              return (
                <div key={index} className={'row m0'}>
                  <div className='col-sm-12 col-lg-3 mb20'>
                    <div className='device-wrapper'>
                      <img className='device-subcategory-img' src={productImageUrl + item.ProductSubCategoryID + '.png'} />
                      <div className='fontSmall'>
                        {getTranslationText('common', 'productName')}
                        <br />
                        <div className='mediumFontStyle fontMedium mt4'>{item.product?.ProductName || item?.ProductName}</div>

                        {item?.Carrier ? (
                          <div className='lightFontStyle fontSmall'>
                            {getTranslationText('common', 'carrier')}: {item.Carrier}
                          </div>
                        ) : (
                          ''
                        )}

                        {item?.Colour ? (
                          <div className='lightFontStyle fontSmall'>
                            {getTranslationText('common', 'color')}: {item.Colour}
                          </div>
                        ) : (
                          ''
                        )}

                        {item?.StorageCapacity ? (
                          <div className='lightFontStyle fontSmall'>
                            {getTranslationText('common', 'storage')}: {item.StorageCapacity}
                          </div>
                        ) : (
                          ''
                        )}

                        {item?.RAM ? (
                          <div className='lightFontStyle fontSmall'>
                            {getTranslationText('common', 'RAM')}: {item.RAM}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                  {!_.isEmpty(this.props.request.selectedReplacementOption) && _.includes([65, 46, 66], ServiceTypeID) ? (
                    <div className='col-sm-12 col-lg-3 mb20'>
                      <div className='device-image-wrapper'>
                        <img className='device-subcategory-img' src={productImageUrl + item.ProductSubCategoryID + '.png'} />
                        <div className='fontSmall'>
                          {getTranslationText('request', 'replacementDeviceDetails')}
                          <br />
                          <div className='mediumFontStyle fontMedium mt4'>
                            {_.get(this, 'props.request.selectedReplacementOption.ProductName', item.Product.ProductName)}

                            {_.get(this, 'props.request.selectedReplacementOption.ProductSkuAttributes.Carrier', '') ? (
                              <div className='lightFontStyle fontSmall'>
                                {`${getTranslationText('common', 'carrier')}: 
                              ${_.get(this, 'props.request.selectedReplacementOption.ProductSkuAttributes.Carrier', '')}`}
                              </div>
                            ) : (
                              ''
                            )}

                            {_.get(this, 'props.request.selectedReplacementOption.ProductSkuAttributes.Colour', '') ? (
                              <div className='lightFontStyle fontSmall'>
                                {`${getTranslationText('common', 'color')}: 
                              ${_.get(this, 'props.request.selectedReplacementOption.ProductSkuAttributes.Colour', '')}`}
                              </div>
                            ) : (
                              ''
                            )}

                            {_.get(this, 'props.request.selectedReplacementOption.ProductSkuAttributes.Storage', '') ? (
                              <div className='lightFontStyle fontSmall'>
                                {`${getTranslationText('common', 'storage')}: 
                              ${_.get(this, 'props.request.selectedReplacementOption.ProductSkuAttributes.Storage', '')}`}
                              </div>
                            ) : (
                              ''
                            )}

                            {_.get(this, 'props.request.selectedReplacementOption.ProductSkuAttributes.RAM', '') ? (
                              <div className='lightFontStyle fontSmall'>
                                {`${getTranslationText('common', 'RAM')}: 
                              ${_.get(this, 'props.request.selectedReplacementOption.ProductSkuAttributes.RAM', '')}`}
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : _.includes([65, 46], ServiceTypeID) ? (
                    <div className='col-sm-12 col-lg-3 mb20'>
                      <div className='device-wrapper'>
                        <img className='device-subcategory-img' src={productImageUrl + item.ProductSubCategoryID + '.png'} />
                        <div>
                          <div className='fontSmall'>{getTranslationText('request', 'replacementDeviceDetails')}</div>
                          <div className='mediumFontStyle fontMedium mt4'>
                            {getTranslationText('request', 'replacementStockAvailability')}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  {item.ProductUniqueID || item.DownloadedDeviceUniqueKey ? (
                    <div className='col-sm-12 col-lg-3 mb20'>
                      <div className='fontSmall'>
                        {getTranslationText('request', 'imeiOrSerial')}
                        <br />
                        <div className='mediumFontStyle fontMedium mt4'>
                          {!!item?.ProductUniqueID || !!item?.AlternateUniqueKey
                            ? `${
                                item?.ProductUniqueID +
                                (['NarwalUS-Web'].includes(_.get(this, 'props.user.appConfigDetails.app'))
                                  ? ` / ${item.AlternateUniqueKey}`
                                  : '')
                              }`
                            : item.DownloadedDeviceUniqueKey}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  {_.get(this.props, 'request.adminFeeDetails.AdvanceChargeApplicable') &&
                  !_.get(this.props, 'request.adminFeeDetails.PaymentStatus') &&
                  _.get(this.props, 'request.adminFeeDetails.PaymentAmount') ? (
                    <div className='col-sm-4 col-lg-3 mb20'>
                      <div className='bookFontStyle overrideCasing'>
                        <span>
                          {' '}
                          {flag_THEFT_OR_LOSS
                            ? getTranslationText('request', 'deductibleFees')
                            : getTranslationText('request', 'adminFees')}
                        </span>
                      </div>
                      <div className='mediumFontStyle mb8 fontMedium mt4'>
                        {getLocaleCurrency(locale, currencyCode, _.get(this.props, 'request.adminFeeDetails.PaymentAmount'))}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  {['refund', 'replacement'].includes(this.props.location.query.type) && returnReason && (
                    <div className='col-sm-12 col-lg-3 mb20 return-reasons-wrapper device-vertical-center'>
                      <div className='fontSmall'>
                        {getTranslationText('request', 'reasonForReturn')}
                        <br />
                        <div className='mediumFontStyle fontMedium mt4'>{returnReason}</div>
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
          <div className='row fontSmall m0'>
            {_.get(this, 'props.request.claimFormDetails.incidentType', '') || _.get(this, 'props.track.trackDetails.incidentType') ? (
              <div className='col-sm-4 col-lg-3 mb20'>
                <div className='fontSmall'>
                  {getTranslationText('request', 'incidentType')}
                  <br />
                  <div className='mediumFontStyle fontMedium mt4'>
                    {_.get(this, 'props.request.claimFormDetails.incidentType', '') || _.get(this, 'props.track.trackDetails.incidentType')}
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            <div className='col-sm-4 col-lg-3 mb20'>
              <div className='bookFontStyle overrideCasing'>
                <span>{getTranslationText('request', 'requestType')}</span>
              </div>
              <div className='mediumFontStyle fontMedium mt4 text-title-case'>
                {getTranslationText('request', getRequestTypeForServiceType(ServiceTypeID))}
              </div>
            </div>
            <div className='col-sm-4 col-lg-3 mb20'>
              <div className='bookFontStyle overrideCasing'>
                <span>
                  {_.includes([17, 23], ServiceTypeID)
                    ? getTranslationText('request', 'selectedServiceOption')
                    : _.includes([66, 46], ServiceTypeID)
                    ? getTranslationText('request', 'serviceMode')
                    : getTranslationText('request', 'selectedMode')}
                </span>
              </div>
              <div className='mediumFontStyle fontMedium mt4'>{this.props.request.selectedMode.Type}</div>
            </div>
            {!_.includes([17, 23], ServiceTypeID) && !_.isEmpty(this.props.request.dateAndTime) ? (
              <div className='col-sm-4 col-lg-3 mb20'>
                <div className='bookFontStyle overrideCasing '>
                  <span>
                    {requestMode.toLowerCase() === 'carryin'
                      ? getTranslationText('request', 'appointment')
                      : getTranslationText('request', requestMode.toLowerCase(), '')}
                    &nbsp;
                    {getTranslationText('request', 'dateAndTimeSlot')}
                  </span>
                </div>
                <div className='mediumFontStyle fontMedium mt4'>
                  {moment(this.props.request.dateAndTime.date.date).format('ddd, DD MMM')} |{' ' + this.props.request.dateAndTime.time.text}
                </div>
              </div>
            ) : (
              ''
            )}
            {_.isEmpty(this.props.request.partnerServiceLocation) && _.includes([23], this.props.request.selectedMode.ServiceTypeID) ? (
              ''
            ) : (
              <div className='col-sm-4 col-lg-3 mb20'>
                {_.isEmpty(this.props.request.partnerServiceLocation) && _.includes([23], this.props.request.selectedMode.ServiceTypeID) ? (
                  ''
                ) : (
                  <div className='bookFontStyle overrideCasing'>
                    <span>
                      {_.includes([17, 23], this.props.request.selectedMode.ServiceTypeID)
                        ? getTranslationText('request', 'collectionPointAddress')
                        : _.includes([65, 46], this.props.request.selectedMode.ServiceTypeID)
                        ? `${getTranslationText('common', 'deliveryAddress', 'Delivery Address')}`
                        : _.includes([66], this.props.request.selectedMode.ServiceTypeID)
                        ? `${getTranslationText('common', 'retailAddress')}`
                        : `${getTranslationText('request', requestMode.toLowerCase(), '')} ${getTranslationText('common', 'address')}`}
                    </span>
                  </div>
                )}
                {_.includes([1, 11, 3, 31, 5, 6, 7, 43, 46, 63, 64, 65], this.props.request.selectedMode.ServiceTypeID) ? (
                  <div className='mediumFontStyle fontMedium mt4'>
                    {_.get(this.props, 'request.displayUserAddress.value', []).length &&
                      _.get(this.props, 'request.displayUserAddress.value').map((v, i) => {
                        return v + (_.get(this.props, 'request.displayUserAddress.value').length !== i + 1 ? ', ' : '.')
                      })}
                    <div>{this.props.addressType.filter((addrType) => addrType.isSelected)[0].text}</div>
                  </div>
                ) : !_.isEmpty(this.props.request.partnerServiceLocation) ? (
                  <div className='mediumFontStyle fontMedium mt4'>
                    <div>{this.props.request.partnerServiceLocation.NameOfFirm || this.props.request.partnerServiceLocation.Name}</div>
                    {parseInt(this.props.request.selectedMode.ServiceTypeID) === 2 && (
                      <div>{_.get(this.props, 'request.partnerServiceLocation.ServiceLocationName')}</div>
                    )}
                    <div>
                      <span>
                        {_.includes([66], this.props.request.selectedMode.ServiceTypeID)
                          ? `${address}, ${City || ''}, ${State || ''}, ${Zipcode || ''}`
                          : this.props.request.partnerServiceLocation.address}
                      </span>
                    </div>
                    {parseInt(this.props.request.selectedMode.ServiceTypeID) === 2 && (
                      <div>
                        {getTranslationText('common', 'contact')}: {_.get(this.props, 'request.partnerServiceLocation.ContactNo1')}
                      </div>
                    )}
                  </div>
                ) : (
                  ''
                )}
                {_.includes([52], parseInt(this.props.request.selectedMode.ServiceTypeID)) && (
                  <div className='mediumFontStyle fontMedium mt4'>
                    <div>{_.get(this.props, 'request.requestModesData.selfCourierServiceLocations[0].Address')}</div>
                  </div>
                )}
              </div>
            )}
            {_.includes([1, 11, 17, 23, 31, 57, 58, 52], parseInt(this.props.request.selectedMode.ServiceTypeID)) &&
              !_.isEmpty(this.props.request.deliveryLocation) && (
                <div className='col-sm-4 col-lg-3 mb20'>
                  <div className='bookFontStyle overrideCasing'>
                    <span>
                      {_.includes([17, 23], ServiceTypeID)
                        ? getTranslationText('common', 'returnAddress')
                        : getTranslationText('common', 'deliveryAddress')}
                    </span>
                  </div>
                  <div className='mediumFontStyle fontMedium mt4'>
                    {_.get(this.props, 'request.displayDeliveryAddress.value', []).length &&
                      _.get(this.props, 'request.displayDeliveryAddress.value').map((v, i) => {
                        return v + (_.get(this.props, 'request.displayDeliveryAddress.value').length !== i + 1 ? ', ' : '.')
                      })}
                    {!_.includes([17, 23], ServiceTypeID) ? (
                      <div className='mb8'>
                        <span className='editButton' onClick={this.props.handleEditDeliveryAdd}>
                          {getTranslationText('common', 'edit')}
                        </span>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              )}
            {this.props.request.requesthandlingCharges ? (
              <div className='col-sm-4 col-lg-3 mb20'>
                <div className='bookFontStyle overrideCasing'>
                  <span>{getTranslationText('request', 'handlingCharges')}</span>
                </div>
                <div className='mediumFontStyle mb8 fontMedium mt4'>
                  {getLocaleCurrency(locale, currencyCode, this.props.request.requesthandlingCharges.charge)}
                </div>
              </div>
            ) : (
              ''
            )}
            {_.get(this.props, 'request.selectedMode.RequiresProofOfDamage') &&
            Object.keys(_.get(this.props, 'user.docServiceLargeFileDetails')).length ? (
              <div className='col-sm-4 col-lg-3 mb20'>
                <div className='bookfontStyle overrideCasing'>
                  <span>{getTranslationText('request', 'proofOfDamage')}</span>
                </div>
                <div className={'uploaded-video-container mt4 play-button'}>
                  <video
                    className='confirmation-damage-proof'
                    width='90'
                    src={_.get(this, 'props.user.docServiceLargeFileDetails.fileUrl')}
                    onClick={() => this.props.showProofOfDamage()}
                  />
                </div>
              </div>
            ) : (
              ''
            )}

            {_.includes([63], parseInt(this.props.request.selectedMode.ServiceTypeID)) ? (
              <>
                <div className='col-sm-4 col-lg-3 mb20'>
                  <div className='bookFontStyle overrideCasing'>
                    <span>{getTranslationText('request', 'nameOnCheck')}</span>
                  </div>
                  <div className='mediumFontStyle mb8 fontMedium mt4'>{_.get(this.props, 'request.userBuyoutDetails.UserName')}</div>
                </div>

                <div className='col-sm-4 col-lg-3 mb20'>
                  <div className='bookFontStyle overrideCasing'>
                    <span>{getTranslationText('request', 'emailIdForCheck')}</span>
                  </div>
                  <div className='mediumFontStyle mb8 fontMedium mt4'>{_.get(this.props, 'request.userBuyoutDetails.EmailID')}</div>
                </div>
              </>
            ) : (
              ''
            )}

            {this.props.additionalUpload && this.props.additionalUpload.imageList.length ? (
              <div className='col-sm-12 mb20'>
                <div className='bookFontStyle fontSmall mb4 overrideCasing'>
                  <span>{getTranslationText('request', 'additionalUploads')}</span>
                </div>
                {this.props.additionalUpload.imageList.length
                  ? this.props.additionalUpload.imageList.map((img, index) => (
                      <div key={index} className='uploaded-additional-img mt4'>
                        <BoxImagesComponent
                          loaderState={false}
                          isUploaded
                          imgFilePath={img.imagePath}
                          imageID={img.imagePath}
                          imgViewerHandler={this.props.ViewUploadedImage}
                        />
                      </div>
                    ))
                  : ''}
              </div>
            ) : (
              ''
            )}
          </div>

          {!isThumbEWCoverage &&
            _.includes([65], parseInt(this.props.request.selectedMode.ServiceTypeID)) &&
            _.get(this, 'props.request.adminFeeDetails.PaymentAmount', 0) <= 0 &&
            _.get(this, 'props.request.selectedMode.defectiveAmount', 0) > 0 && (
              <div className='boldFontStyle advance-exchange-info'>
                <span className='mb20 mt20 informationText'>{getTranslationText('request', 'extendedWarrentyChargeAndRefund')}</span>
              </div>
            )}

          {isReassignServiceLocationFlow &&
            _.includes([11, 12, 23, 2, 1, 46, 66, 65], parseInt(this.props.request.selectedMode.ServiceTypeID)) && (
              <React.Fragment>
                {!!_.get(this, 'props.request.selectedMode.legalConfig', []).length && (
                  <div className='col-sm-12 fontMedium  advance-exchange-info'>
                    {this.state.repairLegalConfig?.map((consent, index) => (
                      <>
                        <Checkbox
                          type='checkbox'
                          className='inline-ele-Casing'
                          key={index}
                          id={`repairConsentDATA` + index}
                          value={consent?.Selected}
                          handleOnChange={() => this.toggleRepairConsentClaimCheck(consent)}
                          otherProps={{
                            style: window.isDirectionRTL ? { marginLeft: '5px' } : { marginRight: '5px' },
                          }}
                        >
                          <span className='' onClick={() => {}}>
                            {consent?.ConsentText}
                            {consent?.externalReferences && consent.externalReferences.length > 0 ? (
                              <React.Fragment>
                                (
                                {consent.externalReferences.map((termsValue, i) => (
                                  <React.Fragment key={i}>
                                    <a href={termsValue.url} className='special-link-txt'>
                                      {termsValue.title}
                                    </a>
                                    {i < consent.externalReferences.length - 1 ? ' ' : ''}
                                  </React.Fragment>
                                ))}
                                )
                              </React.Fragment>
                            ) : (
                              ''
                            )}
                          </span>
                        </Checkbox>
                      </>
                    ))}
                  </div>
                )}
              </React.Fragment>
            )}

          {!isThumbEWCoverage &&
          _.includes([11, 12, 23, 2, 1, 65], parseInt(this.props.request.selectedMode.ServiceTypeID)) &&
          _.get(this, 'props.request.selectedMode.legalConfig', []).length == 0 &&
          _.get(this, 'props.request.selectedMode.defectiveAmount', 0) > 0 &&
          !_.get(this, 'props.request.selectedMode.isCardHoldSkipped', false) ? (
            <div className='col-sm-12 fontSmall advance-exchange-info'>
              <span>
                {getTranslationText('request', 'depositAmountInfo').replace(
                  /@DEFECTIVE_AMOUNT/,
                  getLocaleCurrency(locale, currencyCode, _.get(this.props, 'request.selectedMode.defectiveAmount'))
                )}
              </span>
              <br />
              {(!_.get(this.props, 'request.adminFeeDetails.AdvanceChargeApplicable', false) ||
                _.get(this.props, 'request.adminFeeDetails.PaymentAmount', 0) <= 0) && (
                <span>{getTranslationText('request', 'chargeAndRefund')}</span>
              )}
            </div>
          ) : (
            ''
          )}

          {showWalkinPromptDetails && (
            <>
              <hr />
              <p className='custom-heightlight-text-box mt30 mb30'>
                <InfoAlert />
                {getTranslationText('request', 'plsCallSelectedRepairLocation')}
              </p>
            </>
          )}

          <div className={'col-sm-12 buttons-container p0'}>
            <MediumButton
              className='button-small button-ghost'
              buttonText={getTranslationText('common', 'backLabel')}
              onClick={() => {
                this.props.handleBackButton()
                pushToAnalytics('Review_details_back_button_clicked', {
                  ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
                  ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
                })
              }}
            />
            <MediumButton
              className='button-small'
              onClick={() => {
                this.props.handleContinue(isOptionalConsentSelected)
                pushToAnalytics('Review_details_confirm_and_pay_button_clicked', {
                  ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
                  ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
                })
              }}
              disabled={consentCheckBoxValidationCheck || this.props.isEditUsrDetails}
              btnLoader={this.props.BtnStatusLoader}
              buttonText={
                isConfirmAndPayBtnEnabled ? getTranslationText('common', 'confirmAndPay') : getTranslationText('common', 'confirm')
              }
            />
          </div>
        </BoxHolder>
        <UserDetails {...this.props} />
      </div>
    )
  }
}

ConfirmDetails.propTypes = {
  handleBackButton: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
  // user: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  addressType: PropTypes.array.isRequired,
  newAddress: PropTypes.string.isRequired,
  deliveryAddressType: PropTypes.array.isRequired,
  deliveryAddress: PropTypes.string.isRequired,
  BtnStatusLoader: PropTypes.func.isRequired,
  mobileNo: PropTypes.any,
  handleAlternateMobile: PropTypes.func.isRequired,
  alternateMobile: PropTypes.any,
  handleEmailId: PropTypes.func.isRequired,
  emailId: PropTypes.any,
  handleEditDeliveryAdd: PropTypes.func.isRequired,
  additionalUpload: PropTypes.object,
  ViewUploadedImage: PropTypes.func,
  phnoLengthAr: PropTypes.array.isRequired,
  otherInputText: PropTypes.string,
  selectedDeviceList: PropTypes.array.isRequired,
  invalidFormat: PropTypes.bool,
}

const UserDetails = (props) => {
  const isEmailLogin = _.get(props, 'user.appConfigDetails.EnableEmailLogin', false)
  let phoneCode = _.get(props, 'user.userDetails.phoneCode')

  const getButtonStatus = () => {
    let btnStatus = regexCheck('validation', 'userName', props.userName)
    if (props.alternateMobile && props.alternateMobile.length) {
      btnStatus =
        regexCheck('validation', 'numeric', props.alternateMobile, props.phnoLengthAr) &&
        btnStatus &&
        !props.invalidFormat &&
        !(props.alternateMobile === props.mobileNo)
    }
    if (props.mobileNo && props.mobileNo.length) {
      btnStatus =
        regexCheck('validation', 'numeric', props.mobileNo, props.phnoLengthAr) &&
        btnStatus &&
        !props.invalidFormat &&
        !(props.alternateMobile === props.mobileNo)
    }
    if (props.emailId && props.emailId.length) {
      btnStatus = regexCheck('validation', 'emailId', props.emailId) && btnStatus
    } else {
      btnStatus = false
    }
    return btnStatus
  }

  const customerNameDisabled =
    _.get(props, 'user.appConfigDetails.customerNameDisabled', false) && props?.userName ? false : props.isEditUsrDetails

  return (
    <BoxHolder className='row mt8'>
      <div className={'col-sm-12 req-confirmation-usr-details ' + (window.isDirectionRTL ? ' pr0 ' : ' pl0')}>
        <h4 className='mb30'>{getTranslationText('common', 'yourDetails')}</h4>
        <div className='row'>
          <div className='col-sm-3 pt8 pb8'>
            <div className='bookFontStyle fontSmall mb4'>
              <span>{getTranslationText('common', 'name')}</span>
            </div>
            {customerNameDisabled ? (
              <Input
                inputType='text'
                value={props.userName}
                handleOnChange={props.handleUserName}
                className={{
                  label: 'mediumFontStyle fontSmall text-input',
                }}
              />
            ) : (
              <div>{props.userName || '-'}</div>
            )}
          </div>
          <div className='col-sm-3 pt8 pb8'>
            <div className='bookFontStyle fontSmall mb4'>
              <span>{getTranslationText('common', 'emailId')}</span>
            </div>
            {props.isEditUsrDetails && !isEmailLogin ? (
              <Input
                inputType='text'
                value={props.emailId}
                handleOnChange={props.handleEmailId}
                className={{
                  label: 'mediumFontStyle fontSmall text-input',
                }}
                placeholder='e.g.example@some.com'
                isDiabled={isEmailLogin}
              />
            ) : (
              <div>{props.emailId || '-'}</div>
            )}
          </div>
          <div className='col-sm-3 pt8 pb8'>
            <div className='bookFontStyle fontSmall mb4 overrideCasing'>
              {isEmailLogin
                ? getTranslationText('common', 'mobileNumber') + ' ' + getTranslationText('common', 'optional')
                : getTranslationText('common', 'mobileNumber')}
            </div>
            {props.isEditUsrDetails && isEmailLogin ? (
              <div className='mobile-no-input'>
                <Input
                  inputType='text'
                  value={props.mobileNo}
                  handleOnChange={props.handleMobileNo}
                  className={{
                    label: 'mediumFontStyle fontSmall text-input',
                  }}
                  placeholder={getTranslationText('common', 'mobileNumber')}
                  isDiabled={!isEmailLogin}
                >
                  <span className='pl8 mediumFontStyle fontSmall'>{'  + ' + phoneCode}</span>
                </Input>
              </div>
            ) : (
              <div>{props.mobileNo ? `+${phoneCode} ${props.mobileNo}` : '-'}</div>
            )}
          </div>
          {!isEmailLogin && (
            <div className='col-sm-3 pt8 pb8'>
              <div className='bookFontStyle fontSmall mb4 overrideCasing'>
                {getTranslationText('request', 'alternateMobileNumber') + ' ' + getTranslationText('common', 'optional')}
              </div>
              {props.isEditUsrDetails ? (
                <div className='mobile-no-input'>
                  <Input
                    inputType='text'
                    value={props.alternateMobile}
                    handleOnChange={props.handleAlternateMobile}
                    className={{
                      label: 'mediumFontStyle fontSmall text-input',
                    }}
                    placeholder={getTranslationText('common', 'mobileNumber')}
                  >
                    <span className='pl8 mediumFontStyle fontSmall'>{'  + ' + phoneCode}</span>
                  </Input>
                </div>
              ) : (
                <div>{props.alternateMobile ? `+${phoneCode} ${props.alternateMobile}` : '-'}</div>
              )}
            </div>
          )}
        </div>
        {props.isEditUsrDetails ? (
          <button className='fontMedium mediumFontStyle profile-link-button' id='cancel' onClick={props.cancelEditUserDetails}>
            {getTranslationText('common', 'cancel')}
          </button>
        ) : (
          <button className='fontMedium mediumFontStyle profile-link-button' id='edit' onClick={props.toggleEditUsrDetails}>
            {getTranslationText('common', 'edit')}
          </button>
        )}
      </div>
      {props.isEditUsrDetails && (
        <div className='col-sm-12 removePadding'>
          <div className='buttons-container'>
            <MediumButton
              id='save'
              onClick={props.saveUserDetails}
              buttonText={getTranslationText('common', 'save')}
              btnLoader={props.saveBtnLoader}
              disabled={!getButtonStatus()}
              className='button-small'
            />
          </div>
        </div>
      )}
    </BoxHolder>
  )
}

export class RequestNavBar extends React.Component {
  // handleClick = (e) => {
  //   let id = e.currentTarget.id.replace('req-nav-', '')
  //   let pathname = this.props.steps[parseInt(id)].pathname
  //   if (pathname) {
  //     this.props.handleBackPropogation(pathname, false)
  //   }
  // }

  render() {
    return (
      <div className='headingWrapper'>
        <Heading title={this.props.title} />
        {/* this.props.steps.map((step, index) => {
          return <div id={'req-nav-' + index} key={'key' + index}
            className={'nav-img-holder ' + (step.pathname ? 'clickable' : '')}
            onClick={this.handleClick}>
            <img src={step.image} />
            <div className={'nav-text-holder sentence-casing-uppercase boldFontStyle ' + (step.active ? 'active' : '')}>
              <span>{index + 1}
                {(this.props.activeNavAssetsTxtOnly || this.props.isMobileViewEnabled) && !step.active
                  ? ''
                  : ('. ' + step.text)}
              </span>
            </div>
          </div>
        }) */}
      </div>
    )
  }
}

RequestNavBar.propTypes = {
  title: PropTypes.string.isRequired,
  // handleBackPropogation: PropTypes.func.isRequired,
}

export const ModalUploadDocuments = (props) => {
  return (
    <div className='remove-mt fixed-height-override'>
      <div className='mt20 mb20 request-upload-video-upload'>
        <div className='request-video-upload'>
          {Object.keys(_.get(props, 'user.docServiceLargeFileDetails')).length ? (
            <div className={'uploaded-video-container'}>
              <video
                autoPlay
                src={props.user.docServiceLargeFileDetails.fileUrl}
                onClick={() => props.viewUploadVideo(props.user.docServiceLargeFileDetails.fileUrl)}
              />
            </div>
          ) : (
            <div className='additional-upload-container remove-padding shadow-none'>
              <div className=''>
                <div className='mt48 ml48'>
                  <label htmlFor='photo-input'>
                    <img src='/commonAssets/servifycare/Box.png' />
                  </label>
                </div>
                <input
                  id='photo-input'
                  accept='.3gp, .mp4, .mov, .flv, .wmv, .avi'
                  onChange={(e) => props.handleInvoiceUpload(e)}
                  type='file'
                />
              </div>
            </div>
          )}
        </div>
        {_.get(props, 'request.mediaInstructionsDetails.Instructions', []).length > 0 ? (
          <div className='col-md-6 upload-media-background'>
            <ul className=''>
              <p className='boldFontStyle mt8'>{getTranslationText('request', 'pleaseNote')}</p>
              {_.get(props, 'request.mediaInstructionsDetails.Instructions').map((val, index) => {
                return (
                  <li key={index} className='mt8 mb8 fontSmall'>
                    {val.description}
                  </li>
                )
              })}
            </ul>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export const UploadDocuments = (props) => {
  return (
    <BoxHolder className='remove-mt fixed-height-override'>
      <div className='row m0 request-upload-video-upload'>
        <div className='request-video-upload'>
          {Object.keys(_.get(props, 'user.docServiceLargeFileDetails', {})).length ? (
            <div className={'uploaded-video-container'}>
              <video
                src={props.user.docServiceLargeFileDetails.fileUrl}
                onClick={() => props.viewUploadVideo(props.user.docServiceLargeFileDetails.fileUrl)}
              />
            </div>
          ) : (
            <div className='additional-upload-container remove-padding'>
              <div className=''>
                <div className=''>
                  <label htmlFor='photo-input'>
                    <img src='/commonAssets/servifycare/Box.png' />
                  </label>
                </div>
                <input
                  id='photo-input'
                  accept='.3gp, .mp4, .mov, .flv, .wmv, .avi'
                  onChange={(e) => props.handleInvoiceUpload(e)}
                  type='file'
                />
              </div>
            </div>
          )}
        </div>

        {_.get(props, 'request.mediaInstructionsDetails.Instructions', []).length > 0 ? (
          <div className='col-md-7 upload-media-background'>
            <ul className=''>
              <p className='boldFontStyle mt8'>{getTranslationText('request', 'pleaseNote')}</p>
              {_.get(props, 'request.mediaInstructionsDetails.Instructions').map((val, index) => {
                return (
                  <li key={index} className='mt8 mb8 fontSmall'>
                    {val.description}
                  </li>
                )
              })}
            </ul>
          </div>
        ) : (
          ''
        )}

        <div className='col-sm-12 mt28 p0'>
          <div className='buttons-container'>
            <MediumButton
              className='button-small button-ghost'
              buttonText={getTranslationText('common', 'backLabel')}
              onClick={props.handleBackButton}
            />
            <MediumButton
              className='button-small'
              disabled={!props.user?.docServiceLargeFileDetails?.fileUrl}
              onClick={props.handleContinue}
              btnLoader={props.BtnStatusLoader}
              buttonText={getTranslationText('common', 'continue')}
            />
          </div>
        </div>
      </div>
    </BoxHolder>
  )
}

export const PaymentModes = (props) => {
  const isEmailLogin = _.get(props, 'user.appConfigDetails.EnableEmailLogin', false)
  let buttonDisabled = false
  buttonDisabled =
    !!props.buyOutFormInfo.EmailID &&
    !!props.buyOutFormInfo.UserName &&
    props?.phnoLengthAr.includes(props.buyOutFormInfo?.UserContactNumber?.length)

  const isFromReplacementOptions = _.get(props, 'location.query.from', '') === 'replacement-options'

  const handleReplacementFulfillment = () => {
    const requestObj = {
      Status: 'Pending for reimbursement',
      reimbursementConversion: true,
      Attributes: [
        {
          AttrName: 'CONSUMER_PAYOUT_MODE',
          AttrValue: _.get(props, 'request.buyoutModesDetails[0].PaymentMode'),
        },
        {
          AttrName: 'CONSUMER_LEGAL_NAME',
          AttrValue: _.get(props, 'buyOutFormInfo.UserName'),
        },
        {
          AttrName: 'CONSUMER_LEGAL_EMAIL_ID',
          AttrValue: _.get(props, 'buyOutFormInfo.EmailID'),
        },
        {
          AttrName: 'CONSUMER_LEGAL_CONTACT_NO',
          AttrValue: _.get(props, 'buyOutFormInfo.UserContactNumber'),
        },
      ],
      PlanCoverageID: _.get(props, 'track.trackDetails.PlanCoverageID'),
      ConsumerServiceRequestID: _.get(props, 'track.requestDetails.ConsumerServiceRequestID'),
    }
    props.setglobalLoaderStatus(true)
    props
      .storeReplacementOptions(requestObj)
      .then(() => {
        props.setglobalLoaderStatus(false)
        browserHistory.push({
          pathname: '/track',
          query: {
            id: window.btoa(_.get(props, 'track.requestDetails.ConsumerServiceRequestID').toString()),
          },
        })
      })
      .catch((e) => {
        console.log(e)
        props.setglobalLoaderStatus(false)
      })
  }

  const handleConfirmPayoutMode = () => {
    if (isFromReplacementOptions) {
      handleReplacementFulfillment()
    } else {
      props.handleContinue()
    }
  }

  return (
    <BoxHolder className='remove-mt fixed-height-override'>
      <div className='payment-modes-container'>
        <div className=''>
          {_.get(props, 'request.buyoutModesDetails', []).length
            ? props.request.buyoutModesDetails.map((v) => {
                return (
                  <RadioBox isSelected={v.PaymentModeID} key={`coverage-${v.PaymentModeID}`}>
                    <div className='boldFontStyle'>{v.DisplayText}</div>
                    <ul className='coverage-selection-list'>
                      <li className='fontSmall'>{v.Description}</li>
                    </ul>
                  </RadioBox>
                )
              })
            : ''}
        </div>

        <div className='mt20'>
          <p>{getTranslationText('request', 'enterCheckDetails')}</p>
          <div className='row'>
            <Input
              className={{
                formControl: 'col-sm-4 pt8 pb8',
                inputContainer: 'mediumFontStyle fontSmall input-text',
                label: 'bookFontStyle fontSmall mb4',
              }}
              label={getTranslationText('request', 'nameOnTheCheck')}
              inputType='text'
              id='UserName'
              handleOnChange={props.buyOutInputHandler}
              value={props.buyOutFormInfo.UserName}
            />

            <Input
              className={{
                formControl: 'col-sm-4 pt8 pb8',
                inputContainer: 'mediumFontStyle fontSmall input-text',
                label: 'bookFontStyle fontSmall mb4',
              }}
              label={getTranslationText('request', 'registeredEmailID')}
              inputType='text'
              id='EmailID'
              handleOnChange={props.buyOutInputHandler}
              value={props.buyOutFormInfo.EmailID}
              placeholder='e.g.example@some.com'
              isDisabled={isEmailLogin}
            />

            <Input
              className={{
                formControl: 'col-sm-4 pt8 pb8',
                inputContainer: 'mediumFontStyle fontSmall input-text',
                label: 'bookFontStyle fontSmall mb4',
              }}
              label={getTranslationText('common', 'mobileNumber')}
              placeholder={`${getTranslationText('login', 'enterMobileNumber')}`}
              inputType='number'
              id='UserContactNumber'
              handleOnChange={props.buyOutInputHandler}
              value={props.buyOutFormInfo?.UserContactNumber}
            />
          </div>
        </div>

        <div className='col-sm-12 mt28'>
          <div className='buttons-container'>
            <MediumButton
              className='button-small button-ghost'
              buttonText={getTranslationText('common', 'backLabel')}
              onClick={props.handleBackButton}
            />
            <MediumButton
              className='button-small'
              disabled={!buttonDisabled}
              onClick={handleConfirmPayoutMode}
              btnLoader={props.BtnStatusLoader}
              buttonText={getTranslationText('common', 'continue')}
            />
          </div>
        </div>
      </div>
    </BoxHolder>
  )
}

export const IssueSelection = (props) => {
  let continueBtnFlg = false
  if (props.issuesForType) {
    continueBtnFlg = !(_.compact(props.issuesForType.filter((issues) => issues.isSelected)).length && !props.btnDisabled)
  }

  let invoiceCheckFlg = _.get(props, 'request.selectedDeviceInfo.ProductConfig.IsInvoiceRequired') && props.docAlreadyAdded
  if (invoiceCheckFlg) {
    continueBtnFlg = !((props.uploadedInvoiceImgFlg && !continueBtnFlg) || false)
  }

  return (
    <BoxHolder className='remove-mt fixed-height-override'>
      <div className='row'>
        {props.issuesForType && props.issuesForType.length ? (
          <div className='col-sm-12'>
            <h6>{getTranslationText('request', 'selectMaxIssues')}</h6>
            <ShowIssuesByCategory {...props} />
          </div>
        ) : (
          ''
        )}
        {props.showOtherInput ? (
          <div className='col-sm-12 mb20'>
            <div className='boldFontStyle fontSmall mb8 overrideCasing'>{getTranslationText('request', 'otherIssue')}</div>
            <div className={'col-sm-5 ' + (window.isDirectionRTL ? ' pr0 ' : ' pl0')}>
              <textarea
                id={'other-issue-text'}
                type='text'
                placeholder='Enter issue description'
                value={props.otherInputText}
                className={'other-issue-text full-width'}
                onChange={props.handleOtherIssue}
              />
            </div>
          </div>
        ) : (
          ''
        )}
        {invoiceCheckFlg ? (
          <div className='col-sm-6 mb20 request-flow-invoice-upload'>
            <div className='boldFontStyle fontSmall mb8 overrideCasing'>
              <span>{getTranslationText('request', 'uploadInvoice')}</span>
            </div>
            <div className='multiple-invoice-container'>
              <div className='claim-form-invoice'>
                <BoxImagesComponent
                  loaderState={false}
                  isUploaded={false}
                  imgFilePath={<AddIcon />}
                  inputName='invoice-upload'
                  fileUploadHander={props.handleInvoiceUpload}
                />
              </div>
              {props.devices.productDocs && props.devices.productDocs.length && props.uploadedInvoiceImgFlg
                ? props.devices.productDocs.map((doc, index) => {
                    return (
                      <div key={index} className='claim-form-invoice'>
                        <BoxImagesComponent
                          loaderState={false}
                          isUploaded
                          imgFilePath={doc.FilePath}
                          imageID={doc.ConsumerProductDocumentID}
                          imgViewerHandler={props.ViewUploadInvoice}
                        />
                      </div>
                    )
                  })
                : ''}
            </div>
          </div>
        ) : (
          ''
        )}
        <div className={'mt40 col-sm-' + (invoiceCheckFlg ? '6' : '12')}>
          <div className='boldFontStyle fontSmall overrideCasing'>{getTranslationText('request', 'additionalInfo')}</div>
          <div className='row'>
            <div className='col-sm-12 mb8'>
              <div className='uploads'>
                <div className='additional-upload-container remove-padding'>
                  <div className='upload-icon'>
                    <div className='icon-container'>
                      <label htmlFor='photo-input' className='icon-label'>
                        <img src={invoicePhoto} />
                      </label>
                      <label htmlFor='photo-input' className='icon-txt bookFontStyle fontMicro'>
                        {getTranslationText('request', 'uploadImages')}
                      </label>
                    </div>
                    <InputComponent
                      id='photo-input'
                      type='file'
                      accept='.png, .jpg, .jpeg'
                      title=''
                      value=''
                      onChange={window.useDocService ? props.handleUploadAdditionalDocsToDS : props.handleAdditionalUploads}
                    />
                  </div>
                </div>
                {_.get(props, 'additionalUpload.imageList', []).length
                  ? props.additionalUpload.imageList.map((img, index) => {
                      // uploaded-doc-img
                      return (
                        <div key={index} className='uploaded-additional-img'>
                          <BoxImagesComponent
                            loaderState={false}
                            isUploaded
                            imgFilePath={img.imagePath}
                            imageID={img.uniqueId}
                            imgViewerHandler={props.ViewAddtionallyUploadedDocs}
                          />
                        </div>
                      )
                    })
                  : ''}
              </div>
            </div>
          </div>
        </div>
        {/* <div className='col-sm-12 Note_Text bookFontStyle fontSmall overrideCasing'>
          * {getTranslationText('common', 'mandatoryFields')}
          <br />
        </div> */}
        <div className='col-sm-12'>
          <div className='buttons-container'>
            <MediumButton
              className='button-small button-ghost'
              buttonText={getTranslationText('common', 'backLabel')}
              onClick={props.handleBackButton}
            />
            <MediumButton
              className='button-small'
              disabled={continueBtnFlg || props.BtnStatusLoader}
              onClick={props.handleContinue}
              btnLoader={props.BtnStatusLoader}
              buttonText={getTranslationText('common', 'continue')}
            />
          </div>
        </div>
      </div>
    </BoxHolder>
  )
}

IssueSelection.propTypes = {
  location: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
  devices: PropTypes.object.isRequired,
  handleBackButton: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
  issuesForType: PropTypes.array.isRequired,
  handleInvoiceUpload: PropTypes.func.isRequired,
  uploadedInvoiceImg: PropTypes.string.isRequired,
  uploadedInvoiceImgFlg: PropTypes.bool.isRequired,
  additionalUpload: PropTypes.object.isRequired,
  handleAdditionalUploads: PropTypes.func.isRequired,
  BtnStatusLoader: PropTypes.bool,
  ViewUploadInvoice: PropTypes.bool,
  showOtherInput: PropTypes.bool,
  otherInputText: PropTypes.string.isRequired,
  handleOtherIssue: PropTypes.func.isRequired,
  btnDisabled: PropTypes.bool,
  ViewAddtionallyUploadedDocs: PropTypes.func,
  docAlreadyAdded: PropTypes.bool,
  viewUploadVideo: PropTypes.func.isRequired,
  handleVideoUpload: PropTypes.func.isRequired,
}

export const CoverageSelection = (props) => {
  useEffect(() => {
    pushToAnalytics('Problem_type_page', {
      ConsumerID: _.get(props, 'request.selectedDeviceInfo.ConsumerID', ''),
      ConsumerProductID: _.get(props, 'request.selectedDeviceInfo.ConsumerProductID', ''),
    })
  })
  let selectedsoldPlanCoverages = _.find(props.soldPlanCoverages, (v) => {
    return v['isSelected']
  })
  let isLoss_TheftCoverage = (selectedsoldPlanCoverages && selectedsoldPlanCoverages['CoverageType'] == 'THEFT_OR_LOSS') || false
  let isCoverageConsentRequired = selectedsoldPlanCoverages?.legalConfigs?.find((val) => !val?.ConsentOptional)?.['Selected'] || false

  const coverageSelectionButtonDisabled = selectedsoldPlanCoverages?.legalConfigs?.find((val) => !val?.ConsentOptional)
    ? !props.soldPlanCoverages.find((data) => data.isSelected && isCoverageConsentRequired)
    : !props.soldPlanCoverages.find((data) => data.isSelected)

  return (
    <div className='coverage-selection-container'>
      <div className='row m0'>
        {props.soldPlanCoverages.map((coverage, index) => {
          return (
            <RadioBox
              key={`coverage-${index}`}
              handleOnClick={() => {
                props.handleCoverageSelection(coverage.SoldPlanCoverageID)
                pushToAnalytics('Problem_type_clicked', {
                  Problem_type: coverage.PlanCoverageDisplayName,
                  SoldPlanCoverageID: coverage.SoldPlanCoverageID,
                  CoverageType: coverage.CoverageType,
                  CoverageTypeID: coverage.CoverageTypeID,
                  ConsumerID: _.get(props, 'request.selectedDeviceInfo.ConsumerID', ''),
                  ConsumerProductID: _.get(props, 'request.selectedDeviceInfo.ConsumerProductID', ''),
                })
              }}
              className={{
                content: 'd-flex',
              }}
              isSelected={coverage.isSelected}
            >
              <>
                <div className='fontLarge boldFontStyle'>
                  {coverage.PlanCoverageDisplayName}
                  <sup>*</sup>
                </div>
                <ul className='coverage-selection-list'>
                  {coverage.CoverageBenefits.map((coverageBenefit, index) => (
                    <li key={'benefit-' + index}>{coverageBenefit}</li>
                  ))}
                </ul>
              </>
            </RadioBox>
          )
        })}
      </div>
      <div className='mt28'>
        <p>{getTranslationText('request', 'planT&C')}</p>
        {isLoss_TheftCoverage ? <p>{getTranslationText('request', 'Loss&TheftPlanT&C')}</p> : ''}
        {selectedsoldPlanCoverages?.legalConfigs?.map((option, id) => (
          <Checkbox
            type='checkbox'
            key={id}
            className='inline-ele-Casing'
            value={option.Selected}
            handleOnChange={() => props.toggleClaimCoverageConsentCheck(option.ApplicationLegalConfigID)}
            otherProps={{
              style: window.isDirectionRTL ? { marginLeft: '5px' } : { marginRight: '5px' },
            }}
          >
            <span className='' onClick={() => {}}>
              {option.ConsentText}
            </span>
          </Checkbox>
        ))}
        <div className='buttons-container'>
          <MediumButton
            className={`button-small button-ghost ${window.isDirectionRTL ? 'ml20 ' : 'mr20'}`}
            buttonText={getTranslationText('common', 'backLabel')}
            onClick={() => {
              browserHistory.goBack()
              pushToAnalytics('Problem_type_back_button_clicked', {
                ConsumerID: _.get(props, 'request.selectedDeviceInfo.ConsumerID', ''),
                ConsumerProductID: _.get(props, 'request.selectedDeviceInfo.ConsumerProductID', ''),
              })
            }}
          />
          <MediumButton
            className='button-small'
            buttonText={getTranslationText('common', 'continue')}
            disabled={coverageSelectionButtonDisabled}
            onClick={() => {
              props.confirmCoverageSelection(false)
              const coverage = props.soldPlanCoverages.find((data) => data.isSelected)
              pushToAnalytics('Problem_type_continue_button_clicked', {
                Problem_type: coverage.PlanCoverageDisplayName,
                SoldPlanCoverageID: coverage.SoldPlanCoverageID,
                CoverageType: coverage.CoverageType,
                CoverageTypeID: coverage.CoverageTypeID,
                ConsumerID: _.get(props, 'request.selectedDeviceInfo.ConsumerID', ''),
                ConsumerProductID: _.get(props, 'request.selectedDeviceInfo.ConsumerProductID', ''),
              })
            }}
          />
        </div>
      </div>
    </div>
  )
}

CoverageSelection.propTypes = {
  soldPlanCoverages: PropTypes.array.isRequired,
  confirmCoverageSelection: PropTypes.func.isRequired,
  handleCoverageSelection: PropTypes.func.isRequired,
}

export const AddressPreviewCard = ({ address, title }) => {
  return (
    <div className='address-preview-card p20'>
      <h3 className='fontMedium bookFontStyle'>{title}</h3>
      <p
        className='fontSmall mb0'
        dangerouslySetInnerHTML={{
          __html:
            address.reduce(
              (accumulator, addressComponentValue) => accumulator + (accumulator.length > 0 ? ',<br/>' : '') + addressComponentValue,
              ''
            ) + '.',
        }}
      />
    </div>
  )
}

AddressPreviewCard.propTypes = {
  address: PropTypes.array.isRequired,
}

export const ChooseReplacementOption = (props) => {
  return (
    <BoxHolder className='replacement-options-container'>
      <div className='bookFontStyle txt_noCase'>{getTranslationText('track', 'chooseReplacementSubtext')}</div>
      <div className='replacement-options-list mt40 mb28'>
        {props.replacementOptions &&
          props.replacementOptions.map((option) => (
            <RadioBox
              className={{ container: 'replacement-options-radio', content: 'replacement-options-radio-content' }}
              isSelected={option.isSelected}
              handleOnClick={() => {
                props.handleOptionSelection(option)
              }}
              key={option.index}
            >
              <div className='fontSmall mediumFontStyle'>
                <div className='mb4'>{option.ProductName}</div>
              </div>
              <div className='fontSmall lightFontStyle'>
                {_.get(option, 'ProductSkuAttributes.Carrier', '') && <div>{'Carrier: ' + option.ProductSkuAttributes.Carrier}</div>}
                {_.get(option, 'ProductSkuAttributes.Colour', '') && <div>{'Color: ' + option.ProductSkuAttributes.Colour}</div>}
                {_.get(option, 'ProductSkuAttributes.Storage', '') && <div>{'Storage: ' + option.ProductSkuAttributes.Storage}</div>}
                {_.get(option, 'ProductSkuAttributes.RAM', '') && <div>{'RAM: ' + option.ProductSkuAttributes.RAM}</div>}
                {_.get(option, 'nearest', '') && <div>{'Nearest store to you: ' + option.nearest} Mile</div>}
              </div>
            </RadioBox>
          ))}
      </div>
      <hr />
      <div className='replacement-options-buttons mt28'>
        <MediumButton
          className='button-small button-ghost mr20'
          buttonText={getTranslationText('common', 'backLabel')}
          onClick={browserHistory.goBack}
        />
        <MediumButton
          className='button-small mr20'
          buttonText={getTranslationText('common', 'confirm')}
          onClick={() => props.handleContinue()}
          disabled={!props.replacementOptions.some((option) => option.isSelected)}
        />
      </div>
    </BoxHolder>
  )
}
