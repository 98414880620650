export const api = {
  uri,
  coreUri,
  loginUri,
  consumerUri,
  recaptcha,
  googleApiKey,
  salt,
  docServiceUri,
}

export default {
  api: {
    uri,
    coreUri,
    loginUri,
    consumerUri,
    recaptcha,
    googleApiKey,
    salt,
    docServiceUri,
  },
}
