/* eslint-disable */
import { api } from '../config/variables'
import { setToaster } from './toastsAndLoaders'
import _ from 'lodash'
import { checkIfHasAccessToken } from '../utils/functions'
import { getTranslationText } from '../utils/languageTranslation.js'
const GOOGLE_MAPS_GEOCODING_API_KEY = api.googleApiKey
const GOOGLE_MAPS_GEOCODING_ROOT_URL = 'https://maps.googleapis.com/maps/api'

export const SET_USER_DETAILS = 'SET_USER_DETAILS'
export const SET_USER_ADDRESSES = 'SET_USER_ADDRESSES'
export const SET_USER_DOCS = 'SET_USER_DOCS'
export const SET_SIGNED_URL = 'SET_SIGNED_URL'
export const SET_UPLOAD_DETAILS = 'SET_UPLOAD_DETAILS'
export const SET_STORE_DOCS = 'SET_STORE_DOCS'
export const SET_USER_LOCATION = 'SET_USER_LOCATION'
export const SET_BROWSER_LOCATION = 'SET_BROWSER_LOCATION'
export const SET_USER_NOTIFICATION = 'SET_USER_NOTIFICATION'
export const SET_APP_CONFIG = 'SET_APP_CONFIG'
export const SET_APP_LEGAL_CONFIGS = 'SET_APP_LEGAL_CONFIGS'
export const SET_CLAIM_LEGAL_CONFIGS = 'SET_CLAIM_LEGAL_CONFIGS'
export const CLEAR_USER_DETAILS = 'CLEAR_USER_DETAILS'
export const SET_SLIDER_STATE = 'SET_SLIDER_STATE'
export const UPDATE_NEW_USER_TOKEN = 'UPDATE_NEW_USER_TOKEN'
export const IS_AN_GUEST_USER = 'IS_AN_GUEST_USER'
// export const SET_GLOBAL_APP_CONFIG = 'SET_GLOBAL_APP_CONFIG'
export const SELECTED_COUNTRY_DATA = 'SELECTED_COUNTRY_DATA'
export const SET_COUNTRIES = 'SET_COUNTRIES'
export const SET_BANNERS_LIST = 'SET_BANNERS_LIST'
export const SET_DISCOUNTS = 'SET_DISCOUNTS'
export const SET_LOGIN_REDIRECTIONURL = 'SET_LOGIN_REDIRECTIONURL'
export const SET_TNC_LINK = 'SET_TNC_LINK'
export const SET_PRIVACY_LINK = 'SET_PRIVACY_LINK'
export const SET_CONSUMER_DETAILS = 'SET_CONSUMER_DETAILS'
export const SET_COUNTRY_ADDRESS_CONFIG = 'SET_COUNTRY_ADDRESS_CONFIG'
export const SET_STATE_ADDRESS_CONFIG = 'SET_STATE_ADDRESS_CONFIG'
export const SET_DISTRICT_ADDRESS_CONFIG = 'SET_DISTRICT_ADDRESS_CONFIG'
export const UNSET_UPLOAD_DETAILS = 'UNSET_UPLOAD_DETAILS'
export const SET_DATA_CONSENT_LINK = 'SET_DATA_CONSENT_LINK'
export const SET_IS_SSO_FLOW = 'SET_IS_SSO_FLOW'
export const SET_DETECT_LOCATION = 'SET_DETECT_LOCATION'
export const SET_URL_DATA = 'SET_URL_DATA'
export const SET_LARGE_FILE_UPLOAD_DETAILS = 'SET_LARGE_FILE_UPLOAD_DETAILS'

import { BrandSpecificConfig, docServiceModuleName } from '../utils/constants'
import { fetchCity } from '../utils/KSACountryMapping'

// export const SET_CONSUMER_PRODUCT_PLANS = 'SET_CONSUMER_PRODUCT_PLANS'
// export const SET_CONSUMER_ID = 'SET_CONSUMER_ID'

// ------------------------------------
// Actions
// ------------------------------------
// export const setconsumerProductPlans = (payload) => {
//   return {
//     type: SET_CONSUMER_PRODUCT_PLANS,
//     payload
//   }
// }

export const setBannersList = (payload) => {
  return {
    type: SET_BANNERS_LIST,
    payload,
  }
}

export const setDiscounts = (payload) => {
  return {
    type: SET_DISCOUNTS,
    payload,
  }
}

export const guestUserLoggedIn = (payload) => {
  return {
    type: IS_AN_GUEST_USER,
    payload,
  }
}

export const updateNewUserData = () => {
  return {
    type: UPDATE_NEW_USER_TOKEN,
  }
}

export const setSliderState = () => {
  return {
    type: SET_SLIDER_STATE,
  }
}

export const clearConsumerDetails = () => {
  return {
    type: CLEAR_USER_DETAILS,
  }
}

export const setUserDetails = (payload) => {
  return {
    type: SET_USER_DETAILS,
    payload,
  }
}

export const sendotpreport = (payload) => {
  return {
    type: SET_CONSUMER_DETAILS,
    payload,
  }
}

export const setUserAddresses = (payload) => {
  return {
    type: SET_USER_ADDRESSES,
    payload,
  }
}

export const setUserDocs = (payload) => {
  return {
    type: SET_USER_DOCS,
    payload,
  }
}

export const setSignedUrl = (payload) => {
  return {
    type: SET_SIGNED_URL,
    payload,
  }
}

export const setStoreDocs = (payload) => {
  return {
    type: SET_STORE_DOCS,
    payload,
  }
}

export const setUploadDetails = () => {
  return {
    type: SET_UPLOAD_DETAILS,
  }
}

export const unsetSignedUrl = () => {
  return {
    type: UNSET_UPLOAD_DETAILS,
  }
}

const setUserLocation = (payload) => {
  return {
    type: SET_USER_LOCATION,
    payload,
  }
}

const setBrowserLocation = (payload) => {
  return {
    type: SET_BROWSER_LOCATION,
    payload,
  }
}

const setNotifications = (payload) => {
  // debugger // eslint-disable
  let unReadCount = 0
  payload.map((data) => {
    if (data.IsRead !== 1) {
      unReadCount++
    }
  })
  return {
    type: SET_USER_NOTIFICATION,
    notificationData: { payload, unReadCount },
  }
}

export const setAppLegalConfigs = (payload) => {
  return {
    type: SET_APP_LEGAL_CONFIGS,
    payload,
  }
}

export const setClaimLegalConfigs = (payload) => {
  return {
    type: SET_CLAIM_LEGAL_CONFIGS,
    payload,
  }
}

export const setPrefilledUserDetails = (payload) => {
  return {
    type: SET_URL_DATA,
    payload,
  }
}

export const setAppConfig = (payload) => {
  let env = process.env.NODE_ENV
  let urlMode = ''
  if (env === 'development') {
    urlMode = 'staging/'
  }
  return {
    type: SET_APP_CONFIG,
    payload: {
      ...payload,
      productImageUrl: payload.S3baseUrl + payload.ProductSubCategorybucket + '/' + 'subcategory/' + urlMode,
    },
  }
}

export const setSelectedCountryData = (payload) => {
  window.CountryID = payload && payload.CountryID
  window.CountryCode = payload && payload.CountryCode
  window.PhoneCode = payload && payload.PhoneCode
  return {
    type: SELECTED_COUNTRY_DATA,
    payload,
  }
}

// export const setGlobalAppConfig = (payload) => {
//   return {
//     type: SET_GLOBAL_APP_CONFIG,
//     payload
//   }
// }

export const setCountries = (payload) => {
  return {
    type: SET_COUNTRIES,
    payload,
    // payload: payload.filter(ctr => (ctr.CountryID !== 105))
  }
}

export const setLoginRedirectionUrl = (payload) => {
  return {
    payload,
    type: SET_LOGIN_REDIRECTIONURL,
  }
}

export const setCountryConfig = (payload) => {
  return {
    payload,
    type: SET_COUNTRY_ADDRESS_CONFIG,
  }
}

export const setStateListConfig = (payload) => {
  return {
    payload,
    type: SET_STATE_ADDRESS_CONFIG,
  }
}

export const setDistrictListConfig = (payload) => {
  return {
    payload,
    type: SET_DISTRICT_ADDRESS_CONFIG,
  }
}

export const setCurrentUserLocation = (payload) => {
  return {
    payload,
    type: SET_DETECT_LOCATION,
  }
}

export const setDSFileUploadDetails = (payload) => {
  return {
    payload,
    type: SET_LARGE_FILE_UPLOAD_DETAILS,
  }
}

// ------------------------------------
// Async Actions
// ------------------------------------

export const generateRedirectionUrl = (callback) => {
  return (dispatch, getState) => {
    let brandsList = []
    _.get(getState(), 'user.appConfigDetails.Products', []).map((product) => {
      product.Brand.map((brandInfo) => {
        if (!brandsList.includes(brandInfo.BrandID)) {
          brandsList.push(brandInfo.BrandID)
        }
      })
    })
    let body = JSON.stringify({
      callback: callback || window.location.origin + '/',
      Brands: brandsList,
    })
    return axios
      .post(api.coreUri + '/Consumer/generateSign', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success && _.get(response, 'data.data.callbackUrl')) {
          dispatch(setLoginRedirectionUrl(response.data.data.callbackUrl))
        } else {
          let err = new Error(response.data.msg || getTranslationText('error', 'somethingWentWrong'))
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

export const fetchDiscountsAsync = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj,
    Source: BrandSpecificConfig().header,
    getPlanDiscounts: true,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/fetchDiscountOffers', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setDiscounts(response.data.data.PlanDiscountOffers))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

export const fetchBannersAsync = () => {
  return (dispatch, getState) => {
    return axios
      .post(
        api.coreUri + '/Consumer/getPostBanners',
        {},
        {
          headers: {
            Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
            customHeader: true,
          },
        }
      )
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setBannersList(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

export const appDownloadLinkViaSmsAsync = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/Consumer/requestAppDownloadLink', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          // dispatch(setUserDocs(response.data.data))
          return response.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const deleteUserDocsAsync = (requestObj) => {
  let body = JSON.stringify({
    UserDocumentID: requestObj.UserDocumentID,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/Documents/deleteDocs', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          // dispatch(setUserDocs(response.data.data))
          return response.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const editNotificationAsync = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/Notification/editNotification', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          // @ToDo handle error case gracefully
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const addNewDeviceViaHomeAsync = (requestObj) => {
  let body = JSON.stringify({
    ConsumerID: requestObj.ConsumerID,
    ProductSubCategoryID: requestObj.ProductSubCategoryID,
    Number: requestObj.Number,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/addDevice', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setToaster(getTranslationText('success', 'deviceAdded'), 'success'))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getDeviceOverviewAsync = (requestObj) => {
  let body = JSON.stringify({
    ConsumerID: requestObj.ConsumerID,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/getConsumerProductPlans', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getClaimLegalConfigs = (params) => {
  let body = JSON.stringify({
    PlanCoverageID: params.PlanCoverageID,
    BrandID: params.brandID,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/Consumer/getApplicationLegalConfigs', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setClaimLegalConfigs(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getAppLegalConfigs = () => {
  let body = JSON.stringify({
    CountryCode: window.CountryCode,
    app: BrandSpecificConfig().header,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/Consumer/getApplicationLegalConfigs', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setAppLegalConfigs(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const loadAppConfig = () => {
  return (dispatch, getState) => {
    return axios
      .get(api.coreUri + '/Consumer/appConfig', {
        headers: {
          // App: 'Consumer-Web',
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setCountries(response.data.data.Countries))
          dispatch(setAppConfig(response.data.data))
          let userCountryID = _.get(getState(), 'user.userDetails.countryID')
          // let fallbackCountry = _.get(getState(), 'user.countries[0]')
          if (userCountryID) {
            let countryObj = response.data.data.Countries.find((val) => val.CountryID === userCountryID) || response.data.data.Countries[0]
            dispatch(
              setSelectedCountryData({
                ...countryObj,
                label: '+' + countryObj.PhoneCode,
                value: countryObj.PhoneCode,
              })
            )
          }
          // else {
          //   let countryObj = response.data.data.Countries[0]
          //   dispatch(
          //     setSelectedCountryData({
          //       ...countryObj,
          //       label: '+' + countryObj.PhoneCode,
          //       value: countryObj.PhoneCode
          //     })
          //   )
          // }
        } else {
          // console.log('Api Error: ', response.msg)
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getNotificationsAsync = (requestObj) => {
  let body = JSON.stringify({
    ConsumerID: requestObj.ConsumerID,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/Consumer/getNotifications', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setNotifications(response.data.data))
          // return (response.data.data)
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getAccessTokenFromCookie = () => {
  let accessToken = checkIfHasAccessToken()
  let body = JSON.stringify({
    externalAccessToken: accessToken,
  })
  return (dispatch) => {
    return axios
      .post(api.coreUri + '/Consumer/getTokenFromCookie', body, {
        headers: {
          // Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        dispatch(
          sendotpreport({
            success: false,
            data: { accessToken: { id: undefined } },
          })
        )
        // browserHistory.replace('/login')
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(sendotpreport(response.data))
          // return (response.data.data)
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          dispatch(
            sendotpreport({
              success: false,
              data: { accessToken: { id: undefined } },
            })
          )
          // browserHistory.replace('/login')
          throw err
        }
      })
  }
}

export const loginUsingSSOAsync = () => {
  let brandName = BrandSpecificConfig().header
  const urlParams = new URLSearchParams(window.location.search)
  let code = urlParams.get('code')
  if (brandName == 'Samsung-Web' && code) {
    brandName = 'Samsung-Unified'
  }
  let body = JSON.stringify({
    AuthorizationCode: code,
    Source: brandName,
  })
  return (dispatch) => {
    return axios
      .post(api.loginUri + '/SSO/Login', body, {
        headers: {
          countryCodeOnly: true,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        dispatch(
          sendotpreport({
            success: false,
            data: { accessToken: { id: undefined } },
          })
        )
        // RELOAD: IF SAMSUNG SSO API FAILS
        if (brandName == 'Samsung-Unified') {
          window.location.href = 'https://www.samsung.com/in/offer/samsung-care-plus/'
        }
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(
            sendotpreport({
              ...response.data,
              data: {
                ...response.data.data,
                consumerID: response.data?.data?.ConsumerID,
                accessToken: { ID: response.data?.data?.AccessToken },
              },
            })
          )
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          dispatch(
            sendotpreport({
              success: false,
              data: { accessToken: { id: undefined } },
            })
          )
          // RELOAD: IF SAMSUNG SSO API FAILS
          if (brandName == 'Samsung-Unified') {
            window.location.href = 'https://www.samsung.com/in/offer/samsung-care-plus/'
          }
          // browserHistory.replace('/login')
          throw err
        }
      })
  }
}

export const deleteNotificationsAsync = (requestObj) => {
  let body = JSON.stringify({
    ConsumerID: requestObj.ConsumerID,
    Deleted: requestObj.selectedIds,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/Notification/editNotification', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (!response.status === 200 || !response.data.success) {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        } else {
          dispatch(setToaster(getTranslationText('success', 'notificationsDeleted'), 'success'))
        }
      })
  }
}

export const getUserDetailsAsync = (reqobj = {}, forceFetch = true) => {
  return (dispatch, getState) => {
    const previousUserState = _.get(getState(), 'user.userDetails')
    // Adding cached data condition, call fetch if data is not already present and forceFetch flag is true
    if (!forceFetch && _.get(previousUserState, 'consumerID')) {
      return new Promise((resolve) => {
        resolve(previousUserState)
      })
    }

    const consumerDetails = _.get(getState(), 'user.consumerDetails.data')
    let body = JSON.stringify({
      consumerID: _.get(consumerDetails, 'consumerID', reqobj?.consumerID),
    })
    return axios
      .post(api.consumerUri + '/consumer/fetch', body, {
        headers: {
          Authorization: _.get(consumerDetails, 'accessToken.ID', reqobj?.accessToken),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          if (_.get(reqobj, 'setResponse', true)) {
            dispatch(setUserDetails(response.data.data))
            let appConfigDetails = _.get(getState(), 'user.appConfigDetails')
            if (!appConfigDetails) {
              window.CountryID = response.data.data.countryID
            }
            dispatch(guestUserLoggedIn(false))
          } else {
            return response.data.data
          }
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const updateUserDetailsAsync = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj.data,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.consumerUri + '/consumer/update', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (!response.status === 200 || !response.data.success) {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        } else {
          dispatch(updateNewUserData())
          dispatch(setUserDetails(response.data.data))
          dispatch(guestUserLoggedIn({ status: false }))
          return response.data
        }
      })
  }
}

export const getUserAddressesAsync = (requestObj) => {
  let body = JSON.stringify({
    ConsumerID: requestObj.ConsumerID,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerFavouritelocation/getUserLocations', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setUserAddresses(response.data.data))
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getUserDocsAsync = (requestObj) => {
  let body = JSON.stringify({
    ConsumerID: requestObj.ConsumerID,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/Documents/retrieveDocs', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          module: docServiceModuleName,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setUserDocs(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getSignedUrlAsync = (requestObj, file, documentId, storeDocsLocal = false) => {
  let body = JSON.stringify({
    ...requestObj.data,
    Key: requestObj.data.Key + '.' + requestObj.data.Type.split('/')[1],
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/FileUpload/getUrl', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(
            setSignedUrl({
              filepath: response.data.data,
              id: requestObj.data.Key,
              file,
              documentId,
              FileName: file.name,
              Type: file.type,
            })
          )
          return dispatch(uploadImageToS3(getState().user.s3Uploads.signedUrl, file, storeDocsLocal))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const uploadImageToS3 = (url, file, storeDocsLocal) => {
  let body = file
  return (dispatch, getState) => {
    return axios
      .put(url, body, {
        headers: {
          'Cache-Control': 'public,max-age=3600',
          'x-amz-acl': 'public-read',
          'Content-Type': file.type,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(setUploadDetails())
          if (storeDocsLocal) {
            dispatch(setStoreDocs())
          }
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const addConsumerDocToDSAsync = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify([
      {
        ConsumerID: requestObj.ConsumerID,
        DocumentID: requestObj.documentId,
        Type: requestObj.fileType,
        FileName: requestObj.docServiceUUID,
        FilePath: requestObj.uploadURL,
      },
    ])
    return axios
      .post(api.coreUri + '/Documents/addDocsForDS', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          module: docServiceModuleName,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        console.log(err)
        throw err
      })
      .then((response) => {
        if (!response.status === 200 || !response.data.success) {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        } else {
          dispatch(setToaster(getTranslationText('success', 'documentAdded'), 'success'))
        }
      })
  }
}

export const addLargeFileToDSAsync = (requestObj, file, storeDocsLocal = false, largeFileDocsLocal = true) => {
  return (dispatch, getState) => {
    dispatch(setDSFileUploadDetails(''))
    return axios
      .post(api.docServiceUri + '/internal/document/getS3PathForUpload', requestObj, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          module: docServiceModuleName,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        console.log(err)
        throw err
      })
      .then((response) => {
        if (!response.status === 200 || !response.data.success) {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        } else {
          return dispatch(setDSFileUploadDetails(response.data.data))
        }
      })
  }
}

export const uploadLargeFileToS3 = (url, body) => {
  return (dispatch, getState) => {
    return axios
      .put(url, body, {
        headers: {
          'Cache-Control': 'public,max-age=3600',
          'Content-Type': body.type,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200) {
          return
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const acknowledgeUploadDocToDSAsync = (docServiceUUID) => {
  return (dispatch, getState) => {
    return axios
      .post(
        api.docServiceUri + '/document/acknowledgeUpload',
        {
          UUID: [docServiceUUID],
        },
        {
          headers: {
            Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
            module: docServiceModuleName,
            customHeader: true,
          },
        }
      )
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        console.log(err)
        throw err
      })
      .then((response) => {
        if (!response.status === 200 || !response.data.success) {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        } else {
          return dispatch(setToaster(getTranslationText('success', 'documentAdded'), 'success'))
        }
      })
  }
}

export const getUploadDocToDSAsync = (docServiceUUID) => {
  return (dispatch, getState) => {
    return axios
      .post(
        api.docServiceUri + '/document/getRenderableUrl',
        {
          UUID: [docServiceUUID],
        },
        {
          headers: {
            Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
            module: docServiceModuleName,
            customHeader: true,
          },
        }
      )
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        console.log(err)
        throw err
      })
      .then((response) => {
        if (!response.status === 200 || !response.data.success) {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        } else {
          dispatch(
            setDSFileUploadDetails({
              ..._.get(getState(), 'user.docServiceLargeFileDetails'),
              fileUrl: response.data.data[0].url,
              Type: response.data.data[0].ContentType,
            })
          )
          return dispatch(setToaster(getTranslationText('success', 'documentAdded'), 'success'))
        }
      })
  }
}

export const addDocAsync = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify([
      {
        ConsumerID: requestObj.ConsumerID,
        DocumentID: getState().user.s3Uploads.documentId,
        Type: getState().user.s3Uploads.file.type,
        FileName: getState().user.s3Uploads.uuid + '.' + getState().user.s3Uploads.file.type.split('/')[1],
        FilePath: getState().user.s3Uploads.fileUrl,
      },
    ])
    return axios
      .post(api.coreUri + '/Documents/addDocs', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (!response.status === 200 || !response.data.success) {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        } else {
          dispatch(setToaster(getTranslationText('success', 'documentAdded'), 'success'))
        }
      })
  }
}

export const getGeoLocationAsync = (value) => {
  return (dispatch, getState) => {
    const isAECountry = _.get(getState(), 'user.selectedCountryData.CountryCode').toLowerCase() == 'ae'
    return new Promise((resolve) => {
      let { lat, lng } = value.geometry.location
      let formattedAddress = value.formatted_address
      let searchAddressComponents = value.address_components
      let searchPostalCode = ''
      let addressState
      let addressCity

      let street_number = ''
      let route = ''
      let premise = ''
      let subpremise = ''
      searchAddressComponents.forEach((map) => {
        if (map.types[0] === 'street_number') {
          street_number = map.short_name
        }
        if (map.types[0] === 'route') {
          route = map.short_name
        }
        if (map.types[0] === 'premise') {
          premise = map.short_name
        }
        if (map.types[0] === 'subpremise') {
          subpremise = map.short_name
        }

        if (map.types[0] === 'postal_code') {
          searchPostalCode = map.short_name
        }
        if (!addressCity) {
          if (map.types[0] === 'locality') {
            addressCity = map.long_name
          } else if (map.types[0] === 'administrative_area_level_2') {
            addressCity = map.long_name
          } else if (map.types[0] === 'administrative_area_level_1') {
            addressCity = map.long_name
          }
        }

        if (map.types[0] === 'administrative_area_level_1') {
          addressState = map.long_name
        }
      })
      dispatch(
        setUserLocation({
          latitude: lat(),
          longitude: lng(),
          landmark: formattedAddress,
          pincode: searchPostalCode,
          state: addressState,
          city: isAECountry ? fetchCity(addressCity) : addressCity,
          UserLandmark: ['US'].includes(_.get(getState(), 'user.selectedCountryData.CountryCode'))
            ? `${street_number} ${route}`
            : undefined,
          Address: ['US'].includes(_.get(getState(), 'user.selectedCountryData.CountryCode')) ? `${premise} ${subpremise}` : undefined,
        })
      )
      resolve()
    })
  }
}

export const getBrowserLocationAsync = () => {
  return (dispatch, getState) => {
    return axios
      .post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${GOOGLE_MAPS_GEOCODING_API_KEY}`)
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(setBrowserLocation(response.data))
        } else {
          let err = new Error('Get browser location failed')
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const setTncLink = (payload) => {
  return {
    type: SET_TNC_LINK,
    payload,
  }
}

export const setPrivacyLink = (payload) => {
  return {
    type: SET_PRIVACY_LINK,
    payload,
  }
}

export const setDataConsentLink = (payload) => {
  return {
    type: SET_DATA_CONSENT_LINK,
    payload,
  }
}

export const addAddressAsync = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ...requestObj.data,
      Country: getState().user.selectedCountryData.CountryCode,
    })
    return axios
      .post(api.coreUri + '/ConsumerFavouritelocation/addLocation', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (!response.status === 200 || !response.data.success) {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        } else {
          dispatch(setToaster(getTranslationText('success', 'addressAdded'), 'success'))
          return response.data
        }
      })
  }
}

export const updateAddressAsync = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ...requestObj.data,
      Country: getState().user.selectedCountryData.CountryCode,
    })
    return axios
      .post(api.coreUri + '/ConsumerFavouritelocation/updateLocation', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (!response.status === 200 || !response.data.success) {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        } else {
          dispatch(setToaster(getTranslationText('success', 'addressUpdated'), 'success'))
          return response.data
        }
      })
  }
}

export const deleteAddressAsync = (requestObj) => {
  let body = JSON.stringify({
    ConsumerFavouriteLocationID: requestObj.ConsumerFavouriteLocationID,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerFavouritelocation/deleteAddress', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (!response.status === 200 || !response.data.success) {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        } else {
          dispatch(setToaster(getTranslationText('success', 'addressDeleted'), 'success'))
        }
      })
  }
}

export const getCountriesAsync = () => {
  return (dispatch, getState) => {
    let selectedCountryID = _.get(getState(), 'user.selectedCountryData.CountryID')
    let body = JSON.stringify({
      countryConfig: true,
      specificCountryID: selectedCountryID,
    })
    return axios
      .post(api.coreUri + '/Consumer/getCountries', body, {
        headers: {
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.data.success) {
          return dispatch(setCountryConfig(response.data.data.countries[0].countryConfig))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

export const clearSessionAsync = (reqPayload) => {
  return (dispatch, getState) => {
    let authID = _.get(getState(), 'user.consumerDetails.data.accessToken.ID')
    let body = JSON.stringify({
      accessToken: authID,
    })
    return axios
      .post(api.coreUri + '/Consumer/clearSession', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
          CountryID: reqPayload.CountryID,
          CountryCode: reqPayload.CountryCode,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {})
  }
}

export const getStateListAsync = () => {
  return (dispatch, getState) => {
    return axios
      .post(
        api.coreUri + '/ConsumerServicerequest/getStateCodes',
        {},
        {
          headers: {
            Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
            customHeader: true,
          },
        }
      )
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.data.success) {
          return dispatch(setStateListConfig(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

export const getDistrictListAsync = (body) => {
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/District/getDistricts', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.data.success) {
          dispatch(setDistrictListConfig(response.data.data))
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

export const checkIfContractPurchasedFromThirdPartyAsync = (body) => {
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/checkIfContractPurchasedFromThirdParty', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(err.message, 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(err.message, 'error'))
          throw err
        }
      })
  }
}

export const setIsSSOFlow = (payload) => {
  return {
    type: SET_IS_SSO_FLOW,
    payload,
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_APP_CONFIG]: (state, action) => ({
    ...state,
    appConfigDetails: action.payload,
  }),
  [SET_APP_LEGAL_CONFIGS]: (state, action) => ({
    ...state,
    appLegalConfigs: action.payload,
  }),
  [SET_CLAIM_LEGAL_CONFIGS]: (state, action) => ({
    ...state,
    claimLegalConfigs: action.payload,
  }),
  [SET_USER_DETAILS]: (state, action) => ({
    ...state,
    userDetails: action.payload,
  }),
  [SET_USER_ADDRESSES]: (state, action) => ({
    ...state,
    locations: action.payload,
  }),
  [SET_USER_DOCS]: (state, action) => ({ ...state, documents: action.payload }),
  [SET_SIGNED_URL]: (state, action) => ({
    ...state,
    s3Uploads: {
      ...state.s3Uploads,
      signedUrl: action.payload.filepath,
      uuid: action.payload.id,
      file: action.payload.file,
      documentId: action.payload.documentId,
      FileName: action.payload.FileName,
    },
  }),
  [SET_STORE_DOCS]: (state, action) => {
    let storeDocs
    if (action.payload && action.payload.unset) {
      storeDocs = []
    } else if (action.payload && action.payload.remove && action.payload.fileUrl && state.storeDocs.length) {
      storeDocs = state.storeDocs.filter((doc) => {
        return doc.fileUrl !== action.payload.fileUrl
      })
    } else {
      let ar = state.storeDocs || []
      storeDocs = [...ar, { ...state.s3Uploads }]
    }
    return { ...state, storeDocs }
  },
  [SET_UPLOAD_DETAILS]: (state, action) => ({
    ...state,
    s3Uploads: {
      ...state.s3Uploads,
      fileUrl: state.s3Uploads.signedUrl.split('?')[0],
    },
  }),
  [SET_USER_LOCATION]: (state, action) => ({
    ...state,
    userLocation: action.payload,
  }),
  [SET_BROWSER_LOCATION]: (state, action) => ({
    ...state,
    browserLocation: action.payload,
  }),
  [SET_BROWSER_LOCATION]: (state, action) => ({
    ...state,
    browserLocation: action.payload,
  }),
  [SET_CONSUMER_DETAILS]: (state, action) => ({
    ...state,
    consumerDetails: action.payload,
  }),
  [UNSET_UPLOAD_DETAILS]: (state, action) => ({
    ...state,
    s3Uploads: {},
  }),
  [SET_USER_LOCATION]: (state, action) => ({ ...state, userLocation: action.payload }),
  [SET_BROWSER_LOCATION]: (state, action) => ({ ...state, browserLocation: action.payload }),
  [SET_BROWSER_LOCATION]: (state, action) => ({ ...state, browserLocation: action.payload }),
  [SET_CONSUMER_DETAILS]: (state, action) => ({ ...state, consumerDetails: action.payload }),
  [SET_TNC_LINK]: (state, action) => ({ ...state, tncLink: action.payload }),
  [SET_PRIVACY_LINK]: (state, action) => ({ ...state, privacyLink: action.payload }),
  [SET_DATA_CONSENT_LINK]: (state, action) => ({ ...state, dataLink: action.payload }),
  [CLEAR_USER_DETAILS]: (state, action) => ({ ...initialState }),
  [SET_SLIDER_STATE]: (state, action) => ({ ...state, sliderState: true }),
  [SET_USER_NOTIFICATION]: (state, action) => ({
    ...state,
    notifications: action.notificationData.payload,
    unReadCount: action.notificationData.unReadCount,
  }),
  [UPDATE_NEW_USER_TOKEN]: (state, action) => ({
    ...state,
    consumerDetails: {
      ...state.consumerDetails,
      data: {
        ...state.consumerDetails.data,
        isNew: false,
        profileComplete: true,
      },
    },
  }),
  [IS_AN_GUEST_USER]: (state, action) => ({
    ...state,
    isAnGuestUser: action.payload.status,
  }),
  // [SET_GLOBAL_APP_CONFIG]: (state, action) => ({ ...state, appConfigGlobal: action.payload }),
  [SELECTED_COUNTRY_DATA]: (state, action) => {
    return {
      ...state,
      selectedCountryData: action.payload,
    }
  },
  [SET_COUNTRIES]: (state, action) => ({
    ...state,
    countries: action.payload,
  }),
  [SET_BANNERS_LIST]: (state, action) => ({
    ...state,
    bannerList: action.payload,
  }),
  [SET_DISCOUNTS]: (state, action) => ({
    ...state,
    discounts: action.payload,
  }),
  [SET_LOGIN_REDIRECTIONURL]: (state, action) => ({
    ...state,
    loginRedirectionURL: action.payload,
  }),
  [SET_COUNTRY_ADDRESS_CONFIG]: (state, action) => ({
    ...state,
    countryAddressConfig: action.payload,
  }),
  [SET_STATE_ADDRESS_CONFIG]: (state, action) => ({
    ...state,
    stateAddressListConfig: action.payload,
  }),
  [SET_DISTRICT_ADDRESS_CONFIG]: (state, action) => ({
    ...state,
    districtAddressListConfig: action.payload,
  }),
  [SET_IS_SSO_FLOW]: (state, action) => ({
    ...state,
    isSSOFlow: action.payload,
  }),
  [SET_DETECT_LOCATION]: (state, action) => ({
    ...state,
    userDetectLocation: action.payload,
  }),
  [SET_URL_DATA]: (state, action) => ({
    ...state,
    prefilledUserDetails: action.payload,
  }),
  [SET_LARGE_FILE_UPLOAD_DETAILS]: (state, action) => ({
    ...state,
    docServiceLargeFileDetails: action.payload,
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  userDetails: {},
  s3Uploads: {},
  docServiceLargeFileDetails: {},
  selectedCountryData: {},
  consumerDetails: {},
  storeDocs: [],
  isAnGuestUser: true,
  isSSOFlow: false,
}

export default function userReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
