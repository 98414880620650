// ------------------------------------
// Constants
// ------------------------------------
import { api } from '../config/variables'
import { setToaster } from './toastsAndLoaders'
import _ from 'lodash'
import { getTranslationText } from '../utils/languageTranslation.js'
import { docServiceModuleName } from '../utils/constants'
export const SET_DEVICES = 'SET_DEVICES'
export const SET_PRODUCT_DOCS = 'SET_PRODUCT_DOCS'
export const SET_PRODUCT_BRAND_CONFIG = 'SET_PRODUCT_BRAND_CONFIG'
export const SERVIC_ESTIMATOR_DEVICE = 'SERVIC_ESTIMATOR_DEVICE'
export const SET_SERVICE_ESTIMATOR_PRICE_CARD = 'SET_SERVICE_ESTIMATOR_PRICE_CARD'
export const SET_DEVICE_PRODUCT_CATEGORY = 'SET_DEVICE_PRODUCT_CATEGORY'
export const SET_DEVICE_PRODUCT_BRAND = 'SET_DEVICE_PRODUCT_BRAND'
export const SET_DEVICE_PRODUCT_PRODUCT = 'SET_DEVICE_PRODUCT_PRODUCT'
export const SET_PLANS_ELIGIBLE_DEVICES = 'SET_PLANS_ELIGIBLE_DEVICES'
export const CLEAR_DEVICE_DETAILS = 'CLEAR_DEVICE_DETAILS'
export const SET_ELIGIBLE_DEVICE_PLAN_LIST = 'SET_ELIGIBLE_DEVICE_PLAN_LIST'
export const SET_SELECTED_ELIGIBLE_DEVICE = 'SET_SELECTED_ELIGIBLE_DEVICE'

// export const SET_DEVICE_DATE = 'SET_DEVICE_DATE'
// ------------------------------------
// Actions
// ------------------------------------
// export const setOrdersList = (payload) => {
//   return {
//     type: ORDERS_LIST,
//     // payload: (payload.supportedDevices || []).filter(deviceInfo => (deviceInfo.PurchasedViaStore))
//     //   // let purchaseDate = moment(deviceInfo.DateOfPurchase)
//     //   // if ( && moment().diff(purchaseDate, 'days') <= 15) {
//     //   //   return deviceInfo
//     //   // }
//     // })
//   }
// }

export const setServiceEstimatorPriceCard = (payload) => {
  return {
    type: SET_SERVICE_ESTIMATOR_PRICE_CARD,
    payload,
  }
}

export const setSeviceEstimatorDevice = (payload) => {
  return {
    type: SERVIC_ESTIMATOR_DEVICE,
    payload,
  }
}

export const setDevices = (payload) => {
  const existingSupportedDevices = payload.existingSupportedDevices || [] // To add to previous data if present
  let supportedDevices = (payload.supportedDevices || []).map((device) => ({
    ...device,
    showReplacementOption: device.CanApply,
    showRefundOption: device.OrderNo && device.CanApply,
  }))
  let groupedOrder = _.groupBy(
    (supportedDevices || []).filter((deviceInfo) => deviceInfo.OrderNo),
    (val) => val.OrderNo
  )
  return {
    type: SET_DEVICES,
    payload: {
      supportedDevices: [...existingSupportedDevices, ...supportedDevices],
      count: payload.count,
      currentPage: payload.currentPage,
      totalPages: payload.totalPages,
      ordersList: Object.keys(groupedOrder).map((val) => {
        let itemList = groupedOrder[val]
        return {
          OrderNo: val,
          itemList,
          DateOfPurchase: itemList[0].DateOfPurchase,
          showReplacementOption: !!itemList.find((item) => item.showReplacementOption),
          showRefundOption: !!itemList.find((item) => item.showRefundOption),
        }
      }),
    },
  }
}

const setProductDocs = (payload) => {
  return {
    type: SET_PRODUCT_DOCS,
    payload,
  }
}

export const setProductBrandConfig = (payload) => {
  return {
    type: SET_PRODUCT_BRAND_CONFIG,
    payload,
  }
}

const setDeviceProductCategory = (payload, type = 'category') => {
  switch (type.toLowerCase()) {
    case 'category':
      return {
        type: SET_DEVICE_PRODUCT_CATEGORY,
        payload,
      }
    case 'brand':
      return {
        type: SET_DEVICE_PRODUCT_BRAND,
        payload,
      }
    case 'product':
      return {
        type: SET_DEVICE_PRODUCT_PRODUCT,
        payload,
      }
  }
}

export const setPlansEligibleDevices = (payload) => {
  return {
    type: SET_PLANS_ELIGIBLE_DEVICES,
    payload,
  }
}

export const clearDeviceState = (payload) => {
  return {
    type: CLEAR_DEVICE_DETAILS,
    payload,
  }
}

export const setEligibleDevicePlansList = (payload) => {
  return {
    type: SET_ELIGIBLE_DEVICE_PLAN_LIST,
    payload,
  }
}

export const setSelectedEligiblePlanDeviceInfo = (payload) => {
  return {
    type: SET_SELECTED_ELIGIBLE_DEVICE,
    payload,
  }
}

// export const selectedDeviceData = (payload) => {
//   return {
//     type: SET_DEVICE_DATE,
//     payload
//   }
// }

// ------------------------------------
// Async Actions
// ------------------------------------
export const pendingDocsListAsync = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj.data,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/RequestDocument/getDocumentDetails', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const deleteDeviceProductDocs = (requestObj) => {
  let body = JSON.stringify({
    ConsumerProductDocumentID: requestObj.ConsumerProductDocumentID,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerProductDocument/deleteProductDocs', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const deleteDeviceAsync = (requestObj) => {
  let body = JSON.stringify({
    ConsumerProductID: requestObj.ConsumerProductID,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/deleteConsumerProduct', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          // dispatch(setToaster(getTranslationText('success', 'deviceDeletedSuccessfully'), 'success'))
          return response
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getDeviceRepairHistory = (request = {}) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ConsumerID: getState().user.userDetails.consumerID,
      getActiveRequests: request.getActiveRequests,
    })
    return axios
      .post(api.coreUri + '/ConsumerServicerequest/getConsumerServiceRequestDetails', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getPriceCardAsyn = (requestObj) => {
  let body = JSON.stringify({
    BrandID: requestObj.BrandID,
    ProductSubCategoryID: requestObj.ProductSubCategoryID,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ProductPricechart/getPriceCard', body, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setServiceEstimatorPriceCard(response.data.data.priceCard))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getIssuesListAsync = (requestObj) => {
  let body = JSON.stringify({
    ProductSubCategoryID: requestObj.ProductSubCategoryID,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/Issuemaster/getIssuesList', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const authenticateProductsAsync = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      ...requestObj,
      ConsumerID: getState().user.userDetails.consumerID,
    })
    return axios
      .post(api.coreUri + '/ConsumerProduct/verifyAndCreateProduct', body, {
        headers: {
          Authorization: getState().user.consumerDetails.data.accessToken.ID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const UserDevicePointsAsync = (requestObj) => {
  let body = JSON.stringify({
    ConsumerID: requestObj.ConsumerID,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/updateUserDevicePoints', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getProductsList = (requestObj) => {
  let body = JSON.stringify({
    ProductSubCategoryID: requestObj.categoryID,
    BrandID: requestObj.brandID,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/getProductList', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getProductsByBrand = (requestObj) => {
  let body = JSON.stringify({
    BrandID: requestObj.brandID,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/Product/fetchProductsByBrand', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          // return (response.data.data)
          let ProductList = response.data.data.ProductList.filter((product) => product.ProductSubCategoryID == requestObj.categoryID)
          dispatch(
            setProductBrandConfig({
              ...response.data.data,
              ProductList,
            })
          )
          return {
            ...response.data.data,
            ProductList,
          }
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const checkCancellationEligibility = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/checkCancellationEligibility', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getPlanCancellationOptions = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/getCancellationOptions', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const calculateRefund = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/calculateRefund', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const cancelContract = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/cancelPlanOrder', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getBillingHistory = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/getPaymentDetails', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const addDeviceAsync = (requestObj) => {
  let body = JSON.stringify({
    ...requestObj.data,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/addDevice', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (!response.status === 200 || !response.data.success) {
          let errMsg = response.data.msg
          if (response.data.msg === 'Serial Number Not Unique') {
            errMsg = 'Serial Number ' + (requestObj.data.ProductSubCategoryID === 12 ? 'or IMEI ' : '') + 'is not unique '
          }
          let err = new Error(errMsg)
          dispatch(setToaster(errMsg, 'error'))
          throw err
        } else {
          dispatch(setToaster(getTranslationText('success', 'deviceAdded'), 'success'))
          return response.data.data
        }
      })
  }
}

export const updateDevicesAsync = (requestObj) => {
  let body = JSON.stringify({
    ConsumerProductID: requestObj.ConsumerProductID,
    updateObj: requestObj.updateObj,
  })
  return (dispatch, getState) => {
    return axios
      .post(api.coreUri + '/ConsumerProduct/updateConsumerProduct', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (!response.status === 200 || !response.data.success) {
          const narwalAndLGBrandCheck =
            true || ['NarwalUS-Web', 'LGLA-Web'].includes(_.get(getState(), 'user.appConfigDetails.app', undefined))
          if (narwalAndLGBrandCheck) {
            let errMsg = response.data?.msg
            dispatch(setToaster(errMsg, 'error'))
            throw response.data
          } else {
            let errMsg = response.data.msg
            if (response.data.msg === 'Serial Number Not Unique') {
              errMsg = 'Serial Number ' + (requestObj.updateObj.ProductSubCategoryID === 12 ? 'or IMEI ' : '') + 'is not unique '
            }
            let err = new Error(errMsg)
            dispatch(setToaster(errMsg, 'error'))
            throw err
          }
        } else {
          dispatch(setToaster(getTranslationText('success', 'deviceDetailsUpdated'), 'success'))
        }
      })
  }
}

export const addProductDocToDSAsync = (requestObj) => {
  return (dispatch, getState) => {
    let body = JSON.stringify([
      {
        ConsumerProductDocumentID: 0, // hardcoded
        ConsumerProductID: requestObj.ConsumerProductID,
        DocumentID: requestObj.documentId,
        Type: requestObj.fileType,
        FileName: requestObj.docServiceUUID,
        FilePath: requestObj.uploadURL,
      },
    ])
    return axios
      .post(api.coreUri + '/ConsumerProductDocument/addProductDocsForDS', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          module: docServiceModuleName,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        console.log(err)
        throw err
      })
      .then((response) => {
        if (!response.status === 200 || !response.data.success) {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        } else {
          dispatch(setToaster(getTranslationText('success', 'documentAdded'), 'success'))
        }
      })
  }
}

export const addProductDocAsync = (requestObj, storeDocs) => {
  return (dispatch, getState) => {
    let body
    let updatedObj
    if (!_.isEmpty(storeDocs)) {
      updatedObj = storeDocs.map((data) => {
        return {
          ConsumerProductDocumentID: 0, // hardcoded
          ConsumerProductID: requestObj.ConsumerProductID,
          DocumentID: data.documentId,
          Type: data.file.type,
          FileName: data.uuid + '.' + data.file.type.split('/')[1],
          FilePath: data.uuid + '.' + data.file.type.split('/')[1],
        }
      })
      body = JSON.stringify(updatedObj)
    } else {
      body = JSON.stringify([
        {
          ConsumerProductDocumentID: 0, // hardcoded
          ConsumerProductID: requestObj.ConsumerProductID,
          DocumentID: getState().user.s3Uploads.documentId,
          Type: getState().user.s3Uploads.file.type,
          FileName: getState().user.s3Uploads.uuid + '.' + getState().user.s3Uploads.file.type.split('/')[1],
          FilePath: getState().user.s3Uploads.uuid + '.' + getState().user.s3Uploads.file.type.split('/')[1],
          // FilePath: getState().user.s3Uploads.fileUrl
        },
      ])
    }
    return axios
      .post(api.coreUri + '/ConsumerProductDocument/addProductDocs', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (!response.status === 200 || !response.data.success) {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getProductDocsAsync = (requestObj, AuthID) => {
  return (dispatch) => {
    let body = JSON.stringify(requestObj)
    return axios
      .post(api.coreUri + '/ConsumerProductDocument/retrieveProductDocs', body, {
        headers: {
          Authorization: AuthID,
          module: docServiceModuleName,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setProductDocs(response.data.data))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const getEligibleDeviceDetailsAsync = (requestObj, AuthID, ApiString) => {
  return (dispatch) => {
    let body = JSON.stringify(requestObj)
    return axios
      .post(api.coreUri + ApiString, body, {
        headers: {
          Authorization: AuthID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setDeviceProductCategory(response.data.data, requestObj['AdditionalFilter']))
          if (requestObj['AdditionalFilter'] === 'Product') {
            let ProductList = response.data.data.filter((product) => product.ProductSubCategoryID === requestObj.ProductSubCategoryID)
            dispatch(
              setProductBrandConfig({
                ...response.data.data,
                ProductList,
              })
            )
          }
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const addDeviceToSubscriptionAsync = (requestObj, AuthID) => {
  return (dispatch) => {
    let body = JSON.stringify(requestObj)
    return axios
      .post(api.coreUri + '/ConsumerProduct/addDeviceToSubscription', body, {
        headers: {
          Authorization: AuthID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const fetchConsumerProductsForEligiblePlansAsync = (requestObj, AuthID) => {
  return (dispatch) => {
    let body = JSON.stringify(requestObj)
    return axios
      .post(api.coreUri + '/ConsumerProduct/fetchConsumerProductsWithEligiblePlans', body, {
        headers: {
          Authorization: AuthID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setPlansEligibleDevices(response.data.data.supportedDevices))
          return response.data.data
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const fetchAllConsumerProductsForEligiblePlansAsync = (requestObj, AuthID, startPage, totalPageCount, supportedDevices, cb) => {
  return (dispatch) => {
    let returnData = (err, data) => {
      if (err) dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
      else dispatch(setPlansEligibleDevices(data))
      cb && cb(err, data)
    }
    let getData = (requestObj, AuthID, pageNo, totalPageCount, supportedDevices) => {
      // console.log('pageNo - ', pageNo)
      requestObj.pagination.pageNo = pageNo
      let body = JSON.stringify(requestObj)
      return axios
        .post(api.coreUri + '/ConsumerProduct/fetchConsumerProductsWithEligiblePlans', body, {
          headers: {
            Authorization: AuthID,
            customHeader: true,
          },
        })
        .catch((err) => {
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          returnData(err)
          throw err
        })
        .then((response) => {
          if (response.status === 200 && response.data.success) {
            supportedDevices = supportedDevices.concat(response.data.data.supportedDevices)
            if (pageNo >= totalPageCount) {
              return returnData(null, supportedDevices)
            } else getData(requestObj, AuthID, ++pageNo, totalPageCount, supportedDevices)
          } else {
            let err = new Error(response.data.msg)
            returnData(err)
            throw err
          }
        })
    }
    getData(requestObj, AuthID, startPage, totalPageCount, supportedDevices, cb)
  }
}

export const getPaginatedDevicesAsync = (requestObj, resetData) => {
  let body = JSON.stringify({
    consumerID: requestObj.ConsumerID,
    pagination: requestObj.pagination,
    searchQuery: requestObj.searchQuery,
    consumerProductID: requestObj.ConsumerProductID,
    protectedDevice: requestObj.protectedDevice,
    sendResponseInPascalCase: true,
    populateFields: [
      'brand',
      'productSubCategory',
      'subCategoryAttribute',
      'consumerProductAttributes',
      'product',
      'deviceState',
      'verificationStateMeta',
      'productConfig',
      'uniqueSubCategory',
      'policyDetails',
    ],
  })
  return (dispatch, getState) => {
    return axios
      .post(api.consumerUri + '/consumer-product/filter', body, {
        headers: {
          Authorization: _.get(getState(), 'user.consumerDetails.data.accessToken.ID'),
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          const existingSupportedDevices = _.get(getState(), 'devices.deviceDetails.supportedDevices')
          let devices = []
          if (requestObj.ConsumerProductID && existingSupportedDevices?.length) {
            devices = existingSupportedDevices.map((device) => {
              if (device.ConsumerProductID === requestObj.ConsumerProductID) {
                return {
                  ...device,
                  ...response.data.data.devices[0],
                }
              }
              return device
            })
          } else {
            devices = response.data.data.devices
          }
          let resp = {
            ...response.data.data,
            supportedDevices: devices,
            existingSupportedDevices: resetData ? [] : existingSupportedDevices,
          }

          dispatch(setDevices(resp))
          // dispatch(setOrdersList(resp))
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

export const checkDeviceEligibilityAsync = (requestObj, AuthID) => {
  return (dispatch) => {
    let body = JSON.stringify(requestObj)
    return axios
      .post(api.coreUri + '/ConsumerProduct/checkDeviceEligibilityForEnrollment', body, {
        headers: {
          Authorization: AuthID,
          customHeader: true,
        },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        throw err
      })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setEligibleDevicePlansList(response.data.data))
          return response.data.data[0]
        } else {
          let err = new Error(response.data.msg)
          dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
          throw err
        }
      })
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_DEVICES]: (state, action) => ({ ...state, deviceDetails: action.payload }),
  [SET_PRODUCT_DOCS]: (state, action) => ({ ...state, productDocs: action.payload }),
  [SET_PRODUCT_BRAND_CONFIG]: (state, action) => ({ ...state, productBrandConfig: action.payload }),
  [SERVIC_ESTIMATOR_DEVICE]: (state, action) => ({ ...state, serviceEstimatorSelectedDevice: action.payload }),
  [SET_SERVICE_ESTIMATOR_PRICE_CARD]: (state, action) => ({ ...state, priceCardDetails: action.payload }),
  [SET_DEVICE_PRODUCT_CATEGORY]: (state, action) => ({ ...state, deviceProductCategory: action.payload }),
  [SET_DEVICE_PRODUCT_BRAND]: (state, action) => ({ ...state, deviceProductBrand: action.payload }),
  [SET_DEVICE_PRODUCT_PRODUCT]: (state, action) => ({ ...state, devicePlanProductList: action.payload }),
  [SET_PLANS_ELIGIBLE_DEVICES]: (state, action) => ({ ...state, eligiblePlanDeviceList: action.payload }),
  [CLEAR_DEVICE_DETAILS]: (state) => ({
    ...state,
    deviceDetails: {},
    deviceProductCategory: {},
    deviceProductBrand: {},
    devicePlanProductList: {},
    eligiblePlanDeviceList: {},
  }),
  [SET_ELIGIBLE_DEVICE_PLAN_LIST]: (state, action) => ({ ...state, deviceEligiblePlansList: action.payload }),
  [SET_SELECTED_ELIGIBLE_DEVICE]: (state, action) => ({ ...state, selectedEligibleDeviceInfo: action.payload }),
  // [ORDERS_LIST]: (state, action) => ({ ...state, deviceDetails: {
  //     ...state.deviceDetails,
  //     ordersList: action.payload
  //   }
  // }),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  deviceDetails: {},
}

export default function userReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
