import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import {
  SelectDeviceComponent,
  ClaimFormComponent,
  RequestModes,
  IssueSelection,
  SlotsSelectionScreen,
  PlanHandlingTab,
  ConfirmDetails,
  OrderListComponent,
  ReturnOrderInfo,
  RequestNavBar,
  CoverageSelection,
  UploadDocuments,
  PaymentModes,
  ChooseReplacementOption,
} from './RequestSubComponents'
import {
  Heading,
  MediumButton,
  CustomIconComponent,
  ImageViewerModal,
  StepFlowPopUp,
  StepsIntrPopUp,
  ContactUsModal,
  RadioBox,
  BoxImagesComponent,
  RadioBtnPills,
} from '../../../components/UIComponents'
import { regexCheck, linkify } from '../../../utils/regex'
import { getAddress, getCurrentPosition } from '../../../utils/location'
import { fetchCity } from '../../../utils/KSACountryMapping'

import {
  supportedModesBasedonType,
  requestTypesList,
  fallbackAddressConfig,
  legalConfigCodes,
  docServiceModuleName,
} from '../../../utils/constants'
import { validateDevice, validateInputChangeForDevice, canUpload, isValidMobileNumber } from '../../../utils/validators'
import { Loader, Dropdown, Modal, Radio, Checkbox } from 'sfy-react'
import { getLocaleCurrency, getLocaleDate } from '../../../utils/locale'
import moment from 'moment'
import { getTranslationText } from '../../../utils/languageTranslation.js'
import { sortedAddressConfig, getLegalConsents, getFormattedTime } from '../../../utils/functions'
import _ from 'lodash'
import uuid from 'node-uuid'
import './Request.scss'
import pushToAnalytics from '../../../utils/Analytics'
import { postRequestAsync } from '../../../api/genericAPIs'
import { getDocServicePayload } from '../../../utils/functions'
const defHomeIcon = '/commonAssets/DefAddressTypeBtnIcons/Home.png'
const defOfficeIcon = '/commonAssets/DefAddressTypeBtnIcons/Office.png'
const defOtherIcon = '/commonAssets/DefAddressTypeBtnIcons/Other.png'
const activeHomeIcon = `/${appName}/images/AddressTypeBtnIcons/Home.png`
const activeOfficeIcon = `/${appName}/images/AddressTypeBtnIcons/Office.png`
const activeOtherIcon = `/${appName}/images/AddressTypeBtnIcons/Other.png`
const informationImg = '/commonAssets/information.png'
const unSuccessAlertImg = '/commonAssets/unSuccessAlert.png'

const successImg = '/commonAssets/successImg.png'
import informationAlert from '../../../../public/commonAssets/failure-alert.png'
import errorAlert from '../../../../public/commonAssets/error-alert.png'
const emptyBoxInstruction = {
  dropOffInstructions: {
    DropOffInstructionsTitle:
      'To protect your device & save time, follow these steps before you submit you device at any drop off location.',
    DropOffInstructions: [
      {
        index: 1,
        text: 'You will find a job sheet within the box. Place your device and the job sheet inside the box and then secure pack it.',
        image: 'https://s3-ap-southeast-1.amazonaws.com/s-upload/ups_instruction1.png',
      },
      {
        index: 2,
        text: 'Paste the label in a shipping pouch or attach it on the box using clear shipping tape before dropping it at the drop-off location.',
        image: 'https://s3-ap-southeast-1.amazonaws.com/s-upload/ups_instruction2.png',
      },
    ],
  },
}
import { ReactComponent as Phone } from '../../../../public/commonAssets/phone.svg'
class Request extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isNewDevice: false,
      SelectedDeviceCPId: 0,
      ClaimEligibilityModal: false,
      ClaimConsentModal: false,
      isRepairAllowed: false,
      generatedCSRID: 0,
      selectedDeviceData: this.props.request.selectedDeviceInfo || {},
      selectFrombtnStatus: true,
      claimFrombtnStatus: true,
      claimformInfo: {
        deviceDamageDate: '',
        deviceDamageTime: '',
        deviceDamagePlace: {},
        deviceDamageLocation: '',
        deviceOnState: null,
        deviceDamageDescp: '',
        alternateMobileNo: '',
        deviceDescpLength: 0,
        typesOfDamage: props.request.typesOfDamage
          ? props.request.typesOfDamage.map((obj) => ({ ...obj, isSelected: props.request.typesOfDamage === 1, text: obj.displayText }))
          : [],
        charlength: 100,
        deviceRepairAmount: '',
        policeReportDate: '',
      },
      landmark: _.get(props, 'request.userLocation.landmark', '') || _.get(props, 'request.userLocation.Landmark'),
      deliveryLandmark: '', // props.request.deliveryLocation ? props.request.deliveryLocation.landmark : '',
      locationObtained: !!_.get(props, 'request.userLocation.landmark') || !!_.get(props, 'request.userLocation.Landmark'),
      deliveryLocationObtained: !!_.get(props, 'request.deliveryLocation.landmark'),
      modeSelectStep: props.location.query.step ? parseInt(props.location.query.step) : 1,
      slotSelectStep: props.location.query.step ? parseInt(props.location.query.step) : 1,
      selectedMode:
        (props.location.query.mode && parseInt(props.location.query.mode)) ||
        (props.request.selectedMode && props.request.selectedMode.ServiceTypeID),
      firstActiveDate: props.request.dateAndTime
        ? props.request.dateAndTime.date
        : props.request.requestSlotsData && props.request.requestSlotsData.filter((date) => _.some(date.slots, { IsActive: true }))[0],
      issuesForType: props.request.requestIssuesForType
        ? props.request.requestIssuesForType.map((issue) => ({
            ...issue,
            text: issue.Issue,
            isSelected: false,
          }))
        : [],
      datesToShow: [],
      timesToShow: [],
      isAnAppleDevice: false,
      mobileCategory: true,
      addingDeviceslistbox: {
        categoryvalue: undefined,
        brandvalue: undefined,
        categoryenabled: false,
        brandenabled: false,
        productvalue: undefined,
        productenabled: false,
      },
      listofProducts: {
        categoryDataList: [],
        brandDataList: [],
        productDataList: [],
      },
      newDeviceData: {
        newDeviceTagName: '',
        newDeviceSerialNo: '',
        newDeviceImei1No: '',
        newDeviceImei2No: '',
        newDeviceModelNo: '',
        newDeviceWarrantyDate: '',
      },
      deviceRadiobtnvalue: null,
      defListValue: true,
      addressType: [
        { text: getTranslationText('common', 'home'), isSelected: false, deactiveImg: defHomeIcon, activeImg: activeHomeIcon },
        { text: getTranslationText('common', 'office'), isSelected: false, deactiveImg: defOfficeIcon, activeImg: activeOfficeIcon },
        {
          text: getTranslationText('AddressType', 'addressTypeOther'),
          isSelected: false,
          deactiveImg: defOtherIcon,
          activeImg: activeOtherIcon,
        },
      ],
      deliveryAddressType: [
        { text: getTranslationText('common', 'home'), isSelected: false, deactiveImg: defHomeIcon, activeImg: activeHomeIcon },
        { text: getTranslationText('common', 'office'), isSelected: false, deactiveImg: defOfficeIcon, activeImg: activeOfficeIcon },
        {
          text: getTranslationText('AddressType', 'addressTypeOther'),
          isSelected: false,
          deactiveImg: defOtherIcon,
          activeImg: activeOtherIcon,
        },
      ],
      newAddressFlg: this.props.request.newAddressFlgStore,
      newDeliveryAddressFlg: true, // this.props.request.newAddressFlgStore,
      newAddress: {},
      deliveryAddress: {},
      listofSupportedModes: 'repair',
      listofSupportedModesById: [1, 11, 2, 12, 3, 31, 32, 17, 23, 57, 58, 64],
      uploadedInvoiceImg: '',
      uploadedInvoiceImgFlg: _.get(this, 'props.devices.productDocs.length'),
      requestModeListLoader: false,
      showPickUpInstructionsModal: false,
      selectedPlanID: 0,
      additionalUpload: {
        imageList: [],
      },
      BtnStatusLoader: false,
      ClaimRaiseBtnLoader: false,
      showPurchasedPlansModal: false,
      needToActivatePlanFlg: false, // hardcoded
      enableActivation: false,
      activationPageDetails: {
        activationCode: '',
        activationDOP: '',
        activationInvoiceFiles: [],
        activationPurchaseCost: '',
      },
      docViewerObj: {
        viewUploadedDocs: false,
        FilePath: '',
        showRemoveBtn: false,
      },
      mobileNo: _.get(props, 'user.userDetails.mobileNo', ''),
      emailId: _.get(props, 'user.userDetails.emailID', ''),
      alternateMobile:
        _.get(props, 'request.claimFormDetails.alternateMobileNo') || _.get(props, 'user.userDetails.alternateMobileNo') || '',
      userName: _.get(props, 'user.userDetails.name', ''),
      isEditUsrDetails: false,
      sameDeliveryAddressCheck: false,
      isEditAddress: false,
      isEditAddressFlag: false,
      isLoaderSet: false,
      showOtherInput: false,
      otherInputText: '',
      btnDisabled: true,
      isServiceable: true,
      phnoLengthAr: [10],
      disableNormalRepair: !_.get(this, 'props.user.appConfigDetails.showNormalRepairOptions', true),
      payAndConfirmFlow: false,
      newDeviceDocsList: {},
      isInvoiceUploaded: false,
      showOrderNoInstr: false,
      selectedOrderDetails: this.props.request.selectedOrderDetails || {},
      showReturnStepsInstr: false,
      showClaimErrorPopUp: false,
      locationType: '',
      showStateSelectionModel: false,
      showDistrictSelectionModel: false,
      showPinCodeModal: false,
      pincode: '',
      userAddresslocationObj: {},
      selectedState: {},
      selectedDeliveryAddressState: {},
      selectedDistrict: {},
      selectedAddressStateID: '',
      selectedDeliveryAddressStateID: '',
      deliveryAddresslocationObj: {},
      isSavedDeliveryAddressEdited: false,
      isSavedUserAddressEdited: false,
      globalLoaderFlg: false,
      showCoolingPeriodModal: false,
      saveBtnLoader: false,
      uploadVideoFile: '',
      claimRaiseSuccessMessage: '',
      showVideoMediaModal: false,
      hideReuploadBtn: false,
      generatedReferenceID: '',
      buyOutFormInfo: {
        EmailID: _.get(this, 'props.user.userDetails.emailID'),
        UserName: _.get(this, 'props.user.userDetails.name'),
      },
      showDisplayPreviewModal: false,
      showDamageDescError: false,
      showTheftDetailsError: false,
      showContactUsModal: false,
      showDisClaimerModal: false,
      isDisClaimerModalClosed: false,
      showInvalidAddressModal: false,
      showAddressSuggestion: false,
      isRecommendedAddressChosen: false,
      detectLocationInstruction: false,
      replacementUpgradeModal: false,
      startVoiceRecorder: false,
      savedVoiceRecorder: false,
      showLossDetailsError: false,
      lossAffidavitConsentCheck: false,
      showNoStoreOptionModal: false,
      nextDayReplacementModal: false,
      showSerialQuestionModal: false,
      recievedDisclaimerOption: [],
      questionOption: _.get(this, 'props.user.appConfigDetails.productDisclaimer.options', []),
      showDiscalimerContactUsModal: false,
      showClaimConsentWarningModal: false,
    }
  }

  componentWillMount() {
    const dateFormat = _.get(this, 'props.user.selectedCountryData.countryConfig.DateFormat.DEFAULT', 'DD/MM/YYYY')
    const locale = _.get(this, 'props.user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
    const consumerServiceRequestID = _.get(this, 'props.track.trackDetails.ConsumerProduct.ConsumerServiceRequestID')
    window.scrollTo(0, 0)
    // if(this.props.payment && this.props.payment.paymentRequestStatus){=
    //   this.handleContinue()
    // }
    /********************** initial-page-load Logger API /**********************/
    if (window.useLogger) {
      let loggerObject = {
        apiName: 'initial-page-load-at-componentWillMount',
        value: {
          pathname: _.get(this, 'props.location.pathname', ''),
          paymentRequestStatus: _.get(this, 'props.payment.paymentRequestStatus'),
        },
        event: 'initial-page-load',
        identifiers: {
          ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
        },
      }
      this.props.loggerActionAsync(loggerObject)
    }
    /********************** initial-page-load Logger API /**********************/

    this.props.getCountriesAsync()

    this.props.getStateListAsync()

    if (
      (/confirm/.test(_.get(this, 'props.location.pathname', '')) && _.get(this, 'props.payment.paymentRequestStatus')) ||
      this.props.request?.retryPaymentStatus
    ) {
      this.props.setglobalLoaderStatus(true)
      this.handleContinue()
    } else {
      if (!/confirm/.test(_.get(this, 'props.location.pathname', '')) && _.get(this, 'props.payment.paymentRequestStatus')) {
        this.props.setPaymentRequestStatus(false)
      }
      this.props.setRequestFlowSource({
        ...this.props.request.source,
        from: this.props.location.query.from || _.get(this, 'props.request.source.from') || 'home',
        type: this.props.location.query.type,
      })
      this.props.setglobalLoaderStatus(true)
      let promises = [
        this.props.getUserAddressesAsync({
          ConsumerID: this.props.user.userDetails.consumerID,
          AuthID: this.props.user.consumerDetails.data.accessToken.ID,
        }),
        !consumerServiceRequestID && !_.get(this, 'props.location.pathname', '').includes('select-device')
          ? this.props.getPaginatedDevicesAsync(
              {
                ConsumerID: this.props.user.userDetails.consumerID,
                pagination: {
                  pageNo: 1,
                  itemsPerPage: 5,
                },
                protectedDevice: !this.props.user.appConfigDetails.showNormalRepairOptions,
              },
              true
            )
          : '',
      ]
      Promise.all(promises)
        .then(() => {
          this.props.setglobalLoaderStatus(false)
          this.setState({
            componentMounted: true,
          })
        })
        .catch(() => {
          this.props.setglobalLoaderStatus(false)
          this.setState({
            componentMounted: true,
          })
        })
      if (this.props.location.query.type) {
        // type : repair, install, serivce
        let idList = supportedModesBasedonType(this.props.location.query.type) || []
        if (idList.length === 0) {
          browserHistory.push('/')
        } else {
          this.setState({
            listofSupportedModes: this.props.location.query.type,
            listofSupportedModesById: idList,
          })
        }
      }
      this.checkIfUrlisManipulated()
      if (this.props.request.userLocation) {
        this.setState({
          addressType: this.state.addressType.map((address) => {
            if (address.text === this.props.request.userLocation.AddressType) {
              return {
                ...address,
                isSelected: true,
              }
            } else {
              return { ...address }
            }
          }),
          newAddress: {
            ...this.props.request.userLocation,
            city: _.get(this, 'props.request.userLocation.City', '') || _.get(this, 'props.request.userLocation.city', ''),
          },
          deliveryAddressType: this.state.deliveryAddressType.map((address) => {
            if (this.props.request.deliveryLocation && address.text === this.props.request.deliveryLocation.AddressType) {
              return {
                ...address,
                isSelected: true,
              }
            } else {
              return { ...address }
            }
          }),
          deliveryAddress: _.get(this, 'props.request.deliveryLocation', {}),
        })
      }
      this.setState({
        datesToShow: this.props.request.requestSlotsData
          ? this.props.request.requestSlotsData.map((date) => ({
              ...date,
              text: getLocaleDate(dateFormat, date.date),
              isSelected:
                this.state.firstActiveDate &&
                moment(date.date).format('DD-MM-YYYY') === moment(this.state.firstActiveDate.date).format('DD-MM-YYYY'),
              IsActive: !!date.slots.find((dt) => dt.IsActive),
            }))
          : [],
        timesToShow: this.state.firstActiveDate
          ? this.state.firstActiveDate.slots.map((time) => ({
              ...time,
              text: getFormattedTime(time.StartTimeVal, locale) + ' - ' + getFormattedTime(time.EndTimeVal, locale),
              isSelected: this.props.request.dateAndTime
                ? this.props.request.dateAndTime.time.text === time.StartTime + ' - ' + time.EndTime
                : false,
              status: !time.IsActive,
            }))
          : [],
        phnoLengthAr: _.get(this.props, 'user.selectedCountryData.countryConfig.MobileNumber.LENGTH', [10]),
      })
      this.props.getBrowserLocationAsync().then(() => {
        if (this.props.request.browserLocation) {
          let { lat, lng } = this.props.request.browserLocation.location
          this.setState({
            placesAutocompleteOptions: {
              location: new google.maps.LatLng(lat, lng),
              radius: 2000,
            },
          })
        }
      })
      if (!this.props.user.locations) {
        this.props.getUserAddressesAsync({
          ConsumerID: this.props.user.userDetails.consumerID,
          AuthID: this.props.user.consumerDetails.data.accessToken.ID,
        })
      }
    }
  }

  componentWillUnmount() {
    !this.state.payAndConfirmFlow ? this.props.clearRequestState() : this.props.setPaymentRequestStatus(false)
  }

  setDefaultDeviceData = () => {
    if (_.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID')) {
      let { ProductSubCategory, ProductSubCategoryID } = this.props.request.selectedDeviceInfo.ProductSubCategory
      let { BrandID, BrandName } = this.props.request.selectedDeviceInfo.Brand
      let { ProductName, ProductID } = this.props.request.selectedDeviceInfo.Product
      let productData = this.props.request.selectedDeviceInfo
      let productConfig = _.get(this, 'props.request.selectedDeviceInfo.ProductConfig', {})
      let uniqueIdrequired = false
      // let reqType = _.get(this, 'props.location.query.type')
      if (productConfig.IsUniqueIdMandatory) {
        if (ProductSubCategoryID === 12) {
          uniqueIdrequired = !(productData.ProductUniqueID && productData.ProductUniqueID.length)
        } else {
          uniqueIdrequired = !(productData.DownloadedDeviceUniqueKey && productData.DownloadedDeviceUniqueKey.length)
        }
      }
      this.setState({
        isAnAppleDevice: BrandID === 4,
        mobileCategory: ProductSubCategoryID === 12,
        uniqueIdrequired,
        defListValue: true,
        addingDeviceslistbox: {
          ...this.state.addingDeviceslistbox,
          categoryvalue: [{ text: ProductSubCategory, CategoryId: ProductSubCategoryID, value: ProductSubCategory }],
          brandvalue: [{ text: BrandName, BrandId: BrandID, value: BrandName }],
          productvalue: [
            {
              text: ProductName,
              ProductId: ProductID,
              productConfig: { ...this.props.request.selectedDeviceInfo.ProductConfig },
              value: ProductName,
            },
          ],
        },
        SelectedDeviceCPId: this.props.request.selectedDeviceInfo.ConsumerProductID,
        selectedDeviceData: this.props.request.selectedDeviceInfo,
        selectFrombtnStatus: uniqueIdrequired,
        deviceRadiobtnvalue: this.props.request.selectedDeviceInfo.IsUnderWarranty,
      })
    } else {
      this.setState({
        selectFrombtnStatus: true,
      })
    }
  }

  checkIfUrlisManipulated = () => {
    let currentPathName = this.props.location.pathname
    if (
      this.props.location.query.type &&
      requestTypesList()
        .map((val) => val.type.toLowerCase())
        .indexOf(this.props.location.query.type.toLowerCase()) >= 0 &&
      this.props.request.urlList
    ) {
      let updatedUrl = currentPathName + '?'
      let queryKeys = Object.keys(this.props.location.query).sort()
      updatedUrl += this.generatedUrlForStack(queryKeys)
      if (this.props.request.urlList.indexOf(updatedUrl) >= 0) {
        if (/\/select-device|select-order/.test(currentPathName)) {
          // check for device list & min-orders list
          /\/select-device/.test(currentPathName) && this.setDefaultDeviceData()
        } else if (/\/plan|claim-form|modes|slots|select-issue|confirm/.test(currentPathName)) {
          let selectedDeviceList = this.getselectedDevicesList()[0]
          if (_.isEmpty(selectedDeviceList)) {
            this.backRedirectionHandler()
          } else {
            this.setDefaultDeviceData()
            if (/\/plan/.test(currentPathName)) {
              if (!this.props.location.query.step || this.props.location.query.step !== '2') {
                this.props.setActivationPlan(undefined) // clear activation details
              } else if (this.props.location.query.step === '2') {
                this.checkPlanActivationButtonStatus()
              }
              let planID = 0
              let disableNormalRepair = !_.get(this, 'props.user.appConfigDetails.showNormalRepairOptions', true)
              this.props.clearSelectedDamageType()
              let activeGroup =
                this.props.request.eligiblePlansNew && this.props.request.eligiblePlansNew.filter((group) => group.GroupStatus === 1)[0]
              if (activeGroup && disableNormalRepair) {
                let activePlan = activeGroup.PlanObject[0]
                this.props.setSelectedDamageType(activePlan)
                planID = activePlan.SoldPlanID
              }
              this.setState({ selectedPlanID: planID })
            }
          }
        } else if (/\/select-reason/.test(currentPathName)) {
          //
        } else {
          this.backRedirectionHandler()
        }
      } else {
        this.backRedirectionHandler()
      }
    } else {
      browserHistory.replace('/')
    }
  }

  backRedirectionHandler = () => {
    let reqSource = _.get(this, 'props.request.source.from')
    const cpid = _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', '')
    if (reqSource === 'track') {
      browserHistory.replace({
        pathname: '/request/modes',
        query: {
          csrid: _.get(this, 'props.request.source.csrid'),
          claim: _.get(this, 'props.request.source.claim'),
        },
      })
    } else if (reqSource === 'mydevice') {
      browserHistory.replace('/devices?cpid=' + cpid ? window.btoa(cpid).toString() : '')
    } else if (reqSource === 'myorders') {
      browserHistory.replace('/purchaseHistory')
    } else {
      browserHistory.replace('/')
    }
  }

  componentWillReceiveProps(nextProps) {
    const dateFormat = _.get(this, 'props.user.selectedCountryData.countryConfig.DateFormat.DEFAULT', 'DD/MM/YYYY')
    const locale = _.get(this, 'props.user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
    // reset existing payment details if not on confirm route
    if (!/confirm/.test(_.get(nextProps, 'props.location.pathname', ''))) {
      _.get(this, 'props.payment.payableDetails.id') && this.resetPaymentDetails()
    }
    if (this.props !== nextProps) {
      let firstActiveDate = nextProps.request.dateAndTime
        ? nextProps.request.dateAndTime.date
        : nextProps.request.requestSlotsData &&
          nextProps.request.requestSlotsData.filter((date) => _.some(date.slots, { IsActive: true }))[0]
      this.setState({
        emailId: _.get(nextProps, 'user.userDetails.emailID', ''),
        alternateMobile:
          _.get(nextProps, 'user.userDetails.alternateMobileNo') || _.get(nextProps, 'request.claimFormDetails.alternateMobileNo') || '',
        landmark: _.get(nextProps, 'request.userLocation.landmark', ''),
        deliveryLandmark: (!this.state.isEditAddress && _.get(nextProps, 'request.deliveryLocation.landmark', '')) || '',
        locationObtained: !!_.get(nextProps, 'request.userLocation.landmark') || !!_.get(nextProps, 'request.userLocation.Landmark'),
        deliveryLocationObtained: !!_.get(nextProps, 'request.deliveryLocation.landmark'),
        modeSelectStep: nextProps.location.query.step ? parseInt(nextProps.location.query.step) : 1,
        slotSelectStep: nextProps.location.query.step ? parseInt(nextProps.location.query.step) : 1,
        firstActiveDate,
        datesToShow:
          nextProps.request.requestSlotsData && firstActiveDate
            ? nextProps.request.requestSlotsData.map((date) => ({
                ...date,
                text: getLocaleDate(dateFormat, date.date),
                isSelected: moment(date.date).format('DD-MM-YYYY') === moment(firstActiveDate.date).format('DD-MM-YYYY'),
                IsActive: !!date.slots.find((dt) => dt.IsActive),
              }))
            : [],
        timesToShow: firstActiveDate
          ? firstActiveDate.slots.map((time) => ({
              ...time,
              text: getFormattedTime(time.StartTimeVal, locale) + ' - ' + getFormattedTime(time.EndTimeVal, locale),
              isSelected: nextProps.request.dateAndTime
                ? nextProps.request.dateAndTime.time.text === time.StartTime + ' - ' + time.EndTime
                : !!(nextProps.request.requestSlotsData.filter((date) => _.some(date.slots, { IsActive: true }))[0].slots.length <= 1),
              status: !time.IsActive,
            }))
          : [],
        issuesForType: this.state.issuesForType
          ? this.state.issuesForType.map((issue) => ({
              ...issue,
              text: ['other', 'others', 'other issue'].includes(issue.DisplayText.toLowerCase()) ? issue.text : issue.Issue,
              isSelected: issue.isSelected,
            }))
          : [],
        additionalUpload: nextProps.request.additionalUploadInfo
          ? { ...nextProps.request.additionalUploadInfo }
          : { ...this.state.additionalUpload },
        claimformInfo: Object.keys(_.get(nextProps, 'request.claimFormDetails', {})).length
          ? nextProps.request.claimFormDetails
          : {
              deviceDamageDate: '',
              deviceDamageTime: '',
              deviceDamagePlace: {},
              deviceDamageLocation: '',
              alternateMobileNo: '',
              deviceOnState: null,
              deviceDamageDescp: '',
              deviceDescpLength: 0,
              typesOfDamage: nextProps.request.typesOfDamage
                ? nextProps.request.typesOfDamage.map((obj) => ({
                    ...obj,
                    isSelected: nextProps.request.typesOfDamage.length === 1,
                    text: obj.displayText,
                  }))
                : [],
              charlength: 100,
            },
        selectedOrderDetails: this.props.request.selectedOrderDetails || {},
      })
    }
  }

  generatedUrlForStack = (queryKeys) => {
    let localKeysArray = ''
    queryKeys.forEach((key, index) => {
      localKeysArray += (index === 0 ? '' : '&') + key + '=' + this.props.location.query[key]
    })
    return localKeysArray
  }

  // request flow select device event handlers
  handleDeviceSelection = (productObj, sendGtmEvent = false) => {
    this.clearNewDeviceState()
    this.props.setProductBrandConfig({})
    this.props.setAdditionalUploadData({
      imageList: [],
    })
    this.props.setAudioUploadData({})
    if (productObj.length) {
      this.setState({
        isAnAppleDevice: !!(productObj[0].BrandID === 4),
        mobileCategory: !!(productObj[0].ProductSubCategoryID === 12),
      })
      var pId = productObj[0].ConsumerProductID
      if (pId === -1) {
        let categorydata = this.props.user.appConfigDetails.Products.map((data) => {
          // filters based on type mentioned on url
          if (this.props.location.query.type) {
            if (_.includes(data.SupportedModes, this.props.location.query.type)) {
              return { text: data.ProductSubCategory, CategoryId: data.ProductSubCategoryID, value: data.ProductSubCategory }
            }
          } else {
            return { text: data.ProductSubCategory, CategoryId: data.ProductSubCategoryID, value: data.ProductSubCategory }
          }
        })
        this.setState(
          {
            uniqueIdrequired: false,
            defListValue: false,
            addingDeviceslistbox: {
              ...this.state.addingDeviceslistbox,
              brandvalue: {},
              productvalue: {},
              categoryenabled: true,
            },
            listofProducts: {
              ...this.state.listofProducts,
              categoryDataList: _.compact(categorydata),
            },
            deviceRadiobtnvalue: null,
            selectedDeviceData: {},
          },
          () => {
            if (_.get(this, 'state.listofProducts.categoryDataList', []).length === 1) {
              this.catergoryListHandler(this.state.listofProducts.categoryDataList)
            }
          }
        )
      } else {
        this.props.devices.deviceDetails.supportedDevices.find((productData) => {
          if (productData.ConsumerProductID === pId) {
            this.props.setSelectedDevice(productData)
            if (sendGtmEvent) {
              pushToAnalytics('Device_selected', {
                Device_Name: productData.Product.ProductName || '',
                Category: productData.ProductSubCategory.ProductSubCategory || '',
                Brand: productData.Brand.BrandName || '',
                Product: productData.Product.ProductID || '',
                ConsumerProductID: productData.ConsumerProductID || '',
                ConsumerID: productData.ConsumerID || '',
                Serial_No: productData.Product.ProductName || '',
                Device_Tag: productData.TagName || '',
              })
            }
            let { ProductSubCategory, ProductSubCategoryID } = productData.ProductSubCategory
            let { BrandID, BrandName } = productData.Brand
            let { ProductName, ProductID } = productData.Product
            let uniqueIdrequired = false
            // let reqType = _.get(this, 'props.location.query.type')
            if (productData.ProductConfig.IsUniqueIdMandatory) {
              if (ProductSubCategoryID === 12) {
                uniqueIdrequired = !(productData.ProductUniqueID && productData.ProductUniqueID.length)
              } else {
                uniqueIdrequired = !(productData.DownloadedDeviceUniqueKey && productData.DownloadedDeviceUniqueKey.length)
              }
            }
            this.setState(
              {
                uniqueIdrequired,
                defListValue: true,
                addingDeviceslistbox: {
                  ...this.state.addingDeviceslistbox,
                  categoryvalue: [{ text: ProductSubCategory, CategoryId: ProductSubCategoryID, value: ProductSubCategory }],
                  brandvalue: [{ text: BrandName, BrandId: BrandID, value: BrandName }],
                  productvalue: [
                    {
                      text: ProductName,
                      ProductId: ProductID,
                      productConfig: { ...productData.ProductConfig },
                      value: ProductName,
                    },
                  ],
                },
                selectedDeviceData: productData,
                SelectedDeviceCPId: pId,
                selectFrombtnStatus: uniqueIdrequired,
                deviceRadiobtnvalue: productData.IsUnderWarranty,
                newDeviceDocsList: {},
                isInvoiceUploaded: false,
              },
              () => {
                this.props.setglobalLoaderStatus(true)
                this.props
                  .getProductDocsAsync(
                    {
                      ConsumerProductID: productData.ConsumerProductID,
                    },
                    this.props.user.consumerDetails.data.accessToken.ID
                  )
                  .then(() => {
                    const isWarrantyCheckDetails = _.get(this, 'props.devices.productBrandConfig.WarrantyCheckDetails', false)
                    if (!isWarrantyCheckDetails) {
                      this.props
                        .getProductsByBrand({
                          brandID: productData.Brand.BrandID,
                          categoryID: this.state.addingDeviceslistbox.categoryvalue[0].CategoryId,
                        })
                        .then(() => {
                          this.props.setglobalLoaderStatus(false)
                        })
                    } else {
                      this.props.setglobalLoaderStatus(false)
                    }
                  })
                  .catch(() => {
                    this.props.setglobalLoaderStatus(false)
                    this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
                  })
              }
            )
          }
        })
      }
    } else {
      this.setState({
        uniqueIdrequired: false,
        selectedDeviceData: {},
        selectFrombtnStatus: true,
        addingDeviceslistbox: {
          ...this.state.addingDeviceslistbox,
          categoryvalue: [],
          categoryenabled: false,
        },
        listofProducts: {
          ...this.state.listofProducts,
          categoryDataList: [],
        },
      })
      this.clearNewDeviceState()
    }
  }

  catergoryListHandler = (val) => {
    if (val.length && !this.state.defListValue) {
      var categoryList = { ...this.state.addingDeviceslistbox }
      categoryList.categoryvalue = val
      let branddata
      this.props.user.appConfigDetails.Products.map((data) => {
        if (data.ProductSubCategoryID === val[0].CategoryId) {
          branddata = data.Brand.map((brand) => {
            return { text: brand.BrandName, BrandId: brand.BrandID, value: brand.BrandName }
          })
        }
      })
      categoryList.brandenabled = true
      this.setState(
        {
          listofProducts: {
            categoryDataList: [...this.state.listofProducts.categoryDataList],
            brandDataList: branddata,
            productDataList: [],
          },
          mobileCategory: val[0].CategoryId === 12,
          addingDeviceslistbox: {
            ...categoryList,
            brandvalue: branddata && branddata.length === 1 ? branddata : [],
          },
        },
        () => {
          if (_.get(this, 'state.listofProducts.brandDataList', []).length === 1) {
            this.brandListHandler(this.state.listofProducts.brandDataList)
          }
        }
      )
    } else {
      this.setState({
        isNewDevice: false,
        addingDeviceslistbox: {
          ...this.state.addingDeviceslistbox,
          brandvalue: [],
          brandenabled: false,
          categoryvalue: undefined,
        },
        listofProducts: {
          ...this.state.listofProducts,
          brandDataList: [],
        },
      })
      this.clearNewDeviceState()
    }
  }

  brandListHandler = (val) => {
    if (val.length && !this.state.defListValue) {
      this.setState({
        isAnAppleDevice: val[0].BrandId === 4,
      })
      this.setState({
        fetchingProductLoader: _.get(this, 'state.listofProducts.productDataList', []).length === 0,
      })
      let brandList = { ...this.state.addingDeviceslistbox }
      brandList.brandvalue = val

      this.props
        .getProductsByBrand({
          brandID: val[0].BrandId,
          categoryID: this.state.addingDeviceslistbox.categoryvalue[0].CategoryId,
        })
        .then((response) => {
          let productData = response.ProductList.map((data) => {
            // appending products only for the given modesID"s
            if (this.props.location.query.type) {
              if (_.intersection(this.state.listofSupportedModesById, data.SupportedModes).length) {
                return { text: data.ProductName, ProductId: data.ProductID, ...data, value: data.ProductName }
              }
            } else {
              return { text: data.ProductName, ProductId: data.ProductID, ...data, value: data.ProductName }
            }
          })
          brandList.productenabled = true
          this.setState({
            fetchingProductLoader: false,
            listofProducts: {
              categoryDataList: [...this.state.listofProducts.categoryDataList],
              brandDataList: [...this.state.listofProducts.brandDataList],
              productDataList: _.compact(productData),
            },
            addingDeviceslistbox: brandList,
          })
        })
        .catch(() => {
          this.setState({
            fetchingProductLoader: false,
          })
        })
    } else {
      this.setState({
        addingDeviceslistbox: {
          ...this.state.addingDeviceslistbox,
          brandvalue: undefined,
          productvalue: [],
          productenabled: false,
        },
        listofProducts: {
          categoryDataList: [...this.state.listofProducts.categoryDataList],
          brandDataList: [...this.state.listofProducts.brandDataList],
          productDataList: [],
        },
      })
      if (!this.state.defListValue) {
        this.setState({
          isAnAppleDevice: false,
        })
      }
      this.clearNewDeviceState()
    }
  }

  productListHandler = (val) => {
    // var flg
    if (val.length && !this.state.defListValue) {
      var productList = { ...this.state.addingDeviceslistbox }
      productList.productvalue = val
      // let reqType = _.get(this, 'props.location.query.type')
      // let isMobile = (productList.categoryvalue[0].CategoryId === 12)
      let uniqueIdrequired = val[0].ProductConfig && val[0].ProductConfig.IsUniqueIdMandatory
      this.setState({
        uniqueIdrequired,
        selectFrombtnStatus: uniqueIdrequired,
        addingDeviceslistbox: productList,
        isNewDevice: true,
      })
    } else {
      this.clearNewDeviceState()
      this.setState({
        addingDeviceslistbox: {
          ...this.state.addingDeviceslistbox,
          productvalue: this.state.uniqueIdrequired && !this.state.isNewDevice ? this.state.addingDeviceslistbox.productvalue : undefined,
        },
        isNewDevice: false,
        deviceRadiobtnvalue: this.state.isNewDevice ? false : this.state.selectedDeviceData.IsUnderWarranty,
        selectFrombtnStatus: !(this.state.defListValue && !this.state.selectFrombtnStatus),
      })
    }
  }

  clearNewDeviceState = () => {
    this.setState({
      newDeviceData: {
        newDeviceTagName: '',
        newDeviceSerialNo: '',
        newDeviceImei1No: null,
        newDeviceImei2No: null,
        newDeviceModelNo: '',
        newDeviceWarrntyStatus: null,
        newDeviceWarrantyDate: '',
      },
      deviceRadiobtnvalue: null,
      selectFrombtnStatus: true,
    })
  }

  handleAddNewDevice = (verdict) => {
    let productObj = verdict.productObj || {}
    let ConsumerID = this.props.user.userDetails.consumerID
    let AuthID = this.props.user.consumerDetails.data.accessToken.ID
    let devicedata = {
      TagName: this.state.newDeviceData.newDeviceTagName,
      ModelNo: this.state.newDeviceData.newDeviceModelNo,
      IsUnderWarranty: !!this.state.newDeviceData.newDeviceWarrntyStatus,
      WarrantyTill: this.state.newDeviceData.newDeviceWarrntyStatus
        ? moment(this.state.newDeviceData.newDeviceWarrantyDate).format('YYYY-MM-DD')
        : undefined,
      ...productObj,
    }
    let data = {
      ConsumerID,
      show: false,
      IsActive: 1,
      validationError: false,
      msg: '',
      showP: false,
      disable: false,
      ProductSubCategoryID: this.state.addingDeviceslistbox.categoryvalue[0].CategoryId,
      BrandID: this.state.addingDeviceslistbox.brandvalue[0].BrandId,
      ProductID: this.state.addingDeviceslistbox.productvalue[0].ProductId,
      ...devicedata,
    }
    let addedDeviceCPid
    this.props.setglobalLoaderStatus(true)
    this.props
      .addDeviceAsync({
        data,
        AuthID,
      })
      .then((response) => {
        addedDeviceCPid = response.ConsumerProductID
        this.setState({
          addingDeviceslistbox: {
            categoryvalue: [],
            brandvalue: [],
            categoryenabled: false,
            brandenabled: false,
            productvalue: [],
            productenabled: false,
          },
        })
        this.getDeviceDetails(addedDeviceCPid)
      })
      .catch(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  getDeviceDetails = (addedDeviceCPid) => {
    const ConsumerID = this.props.user.consumerDetails.data.consumerID
    this.props.setglobalLoaderStatus(true)
    this.props
      .getPaginatedDevicesAsync({
        ConsumerID,
        pagination: {
          pageNo: 1,
          itemsPerPage: 5,
        },
      })
      .then(() => {
        this.props.setglobalLoaderStatus(false)
        this.props.devices.deviceDetails.supportedDevices.find((productData) => {
          if (productData.ConsumerProductID === addedDeviceCPid) {
            this.props.setSelectedDevice(productData)
            !_.isEmpty(this.state.newDeviceDocsList) && this.addDocsToProduct()
            this.setState(
              {
                selectedDeviceData: productData,
                SelectedDeviceCPId: addedDeviceCPid,
                BtnStatusLoader: false,
                isNewDevice: false,
                addingDeviceslistbox: {
                  ...this.state.addingDeviceslistbox,
                  categoryenabled: false,
                  brandenabled: false,
                  productenabled: false,
                },
              },
              () => {
                this.setDefaultDeviceData()
                this.setSelectedDevicePostAction()
              }
            )
          }
        })
      })
      .catch(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  handleNewDeviceInputChange = (e) => {
    var dummyobj = { ...this.state.newDeviceData }
    var dummyobjKey = ''
    let productConfig = this.state.addingDeviceslistbox.productvalue[0].ProductConfig
    const isNarwalUSbrandCheck = ['NarwalUS-Web'].includes(_.get(this, 'props.user.appConfigDetails.app'))
    if (e) {
      let eventId = e.currentTarget.id
      let value = e.target.value
      if (eventId === 'device-tag-name') {
        if (/^[a-zA-Z0-9_ ]*$/.test(e.target.value) && value.length <= 15) {
          dummyobjKey = 'newDeviceTagName'
        }
      } else if (eventId === 'device-serial-no') {
        let keyId = 'DownloadedDeviceUniqueKey'
        if (this.state.addingDeviceslistbox.brandvalue[0].BrandId === 4) {
          keyId = regexCheck('validation', 'hasAlphachars', e.target.value) ? 'DownloadedDeviceUniqueKey' : 'ProductUniqueID'
        }
        let customEvent = {
          target: {
            id: keyId,
            value: e.target.value,
          },
        }
        let verdict = validateInputChangeForDevice(
          customEvent,
          this.state.addingDeviceslistbox.productvalue[0],
          _.get(this, 'props.devices.productBrandConfig.WarrantyCheckDetails', {})
        )
        if (verdict.isValid) {
          dummyobjKey = 'newDeviceSerialNo'
        } else {
          this.props.setToaster(verdict.msg, 'error')
          return
        }
      } else if (eventId === 'device-imei1-no' || eventId === 'device-imei2-no') {
        let isIMEI1 = eventId === 'device-imei1-no'
        let customEvent = {
          target: {
            id: isIMEI1 ? 'ProductUniqueID' : 'AlternateUniqueKey',
            value: e.target.value,
          },
        }
        let verdict = validateInputChangeForDevice(
          customEvent,
          !isNarwalUSbrandCheck ? this.state.addingDeviceslistbox.productvalue[0] : _.get(this, 'props.request.selectedDeviceInfo'),
          _.get(this, 'props.devices.productBrandConfig.WarrantyCheckDetails', {})
        )
        if (verdict.isValid) {
          dummyobjKey = isIMEI1 ? 'newDeviceImei1No' : 'newDeviceImei2No'
        } else {
          this.props.setToaster(verdict.msg, 'error')
          return
        }
      } else if (eventId === 'device-model-no') {
        if (/^[0-9A-Za-z]*$/.test(e.target.value) && value.length <= 10) {
          dummyobjKey = 'newDeviceModelNo'
        }
      } else if (eventId === 'device-warranty') {
        dummyobj.newDeviceWarrntyStatus = e.target.checked
        dummyobj.newDeviceWarrantyDate = !e.target.checked ? null : dummyobj.newDeviceWarrantyDate
        this.setState({
          deviceRadiobtnvalue: e.target.checked,
          selectFrombtnStatus: e.target.checked,
          newDeviceDocsList: {},
        })
      } else if (eventId === 'add-warranty-date') {
        dummyobj.newDeviceWarrantyDate = e.target.value
      }
      if (dummyobjKey !== '') {
        dummyobj[dummyobjKey] = value
      }
      this.setState(
        {
          newDeviceData: dummyobj,
          // selectFrombtnStatus: false
          // selectFrombtnStatus: this.handleBtnStatusLength(dummyobj)
        },
        () => {
          this.handleBtnStatusForUniueKey(productConfig)
        }
      )
    }
  }

  handleBtnStatusForUniueKey = (productConfig) => {
    let imeiRange
    if (productConfig && Object.keys(productConfig).length && productConfig.ValidIMEILength) {
      imeiRange = productConfig.ValidIMEILength
    }
    let reqType = _.get(this, 'props.location.query.type')
    let { newDeviceSerialNo, newDeviceImei1No, newDeviceImei2No, newDeviceWarrntyStatus, newDeviceWarrantyDate } = this.state.newDeviceData
    let btnStatus = false
    if (this.state.addingDeviceslistbox.categoryvalue[0].CategoryId === 12) {
      if (this.state.addingDeviceslistbox.brandvalue[0].BrandId === 4) {
        if (newDeviceSerialNo.length || this.state.uniqueIdrequired || reqType === 'repair') {
          let checkLength = /[A-z]/.test(newDeviceSerialNo.toUpperCase()) ? 12 : 15
          btnStatus = newDeviceSerialNo.length >= checkLength
        }
      } else {
        let checkLength = 15
        if (imeiRange && imeiRange.length) {
          checkLength = Math.min(...imeiRange)
        }
        btnStatus = !this.state.uniqueIdrequired
        if (newDeviceImei1No && (newDeviceImei1No.length || this.state.uniqueIdrequired || reqType === 'repair')) {
          btnStatus = newDeviceImei1No.length >= checkLength
        }
        if (newDeviceImei2No && newDeviceImei2No.length) {
          btnStatus = btnStatus && newDeviceImei2No.length >= checkLength
        }
        if (newDeviceSerialNo && newDeviceSerialNo.length) {
          btnStatus = btnStatus && newDeviceSerialNo.length >= 5
        }
      }
    } else {
      btnStatus = !this.state.uniqueIdrequired
      let ipLength = this.state.addingDeviceslistbox.brandvalue[0].BrandId === 4 ? 12 : 5
      if (newDeviceSerialNo.length) {
        btnStatus = newDeviceSerialNo.length >= ipLength
      }
    }
    btnStatus = btnStatus && (newDeviceWarrntyStatus ? !!newDeviceWarrantyDate : true)
    this.setState({
      selectFrombtnStatus: !btnStatus, // false means enabled
    })
  }

  // only if IsUniqueIdMandatory = true
  checkforUniqueKeys = (deviceinfo, brandId, categoryID) => {
    let hasAUniqueKey = false
    if (categoryID === 12) {
      if (brandId === 4) {
        if (deviceinfo.newDeviceSerialNo && deviceinfo.newDeviceSerialNo.length) {
          hasAUniqueKey = true
        }
      } else {
        if (deviceinfo.newDeviceImei1No && deviceinfo.newDeviceImei1No.length) {
          hasAUniqueKey = true
        }
      }
    } else {
      if (deviceinfo.newDeviceSerialNo && deviceinfo.newDeviceSerialNo.length) {
        hasAUniqueKey = true
      }
    }
    return hasAUniqueKey
  }

  setSelectedDeviceID = async () => {
    /** Temperary changes till apple Watch Claim in not allowed */
    const isNarwalUSbrandCheck = ['NarwalUS-Web'].includes(_.get(this, 'props.user.appConfigDetails.app'))
    if (
      this.state.addingDeviceslistbox.productenabled &&
      !_.get(this, 'state.addingDeviceslistbox.productvalue[0].isRepairAllowed', true)
    ) {
      this.setState({ isRepairAllowed: true })
      return
    } else {
      if (!_.get(this, 'state.selectedDeviceData.isRepairAllowed', true)) {
        this.setState({ isRepairAllowed: true })
        return
      }
    }
    if (this.state.addingDeviceslistbox.productenabled) {
      let errorfree = false
      let productVal = this.state.addingDeviceslistbox.productvalue[0].ProductConfig
      let newDeviceDatObj = {
        TagName: this.state.newDeviceData.newDeviceTagName,
        IsUnderWarranty: !!this.state.newDeviceData.newDeviceWarrntyStatus,
        WarrantyTill: this.state.newDeviceData.newDeviceWarrntyStatus
          ? moment(this.state.newDeviceData.newDeviceWarrantyDate).format('YYYY-MM-DD')
          : undefined,
        ProductUniqueID: this.state.newDeviceData.newDeviceImei1No || null,
        AlternateUniqueKey: this.state.newDeviceData.newDeviceImei2No || null,
        DownloadedDeviceUniqueKey: this.state.newDeviceData.newDeviceSerialNo || null,
      }
      if (
        this.state.addingDeviceslistbox.categoryvalue[0].CategoryId === 12 &&
        this.state.addingDeviceslistbox.brandvalue[0].BrandId === 4
      ) {
        if (!regexCheck('validation', 'hasAlphachars', this.state.newDeviceData.newDeviceSerialNo)) {
          newDeviceDatObj = {
            ...newDeviceDatObj,
            ProductUniqueID: this.state.newDeviceData.newDeviceSerialNo || null,
            DownloadedDeviceUniqueKey: null,
          }
        }
      }
      this.props.setglobalLoaderStatus(true)

      if (isNarwalUSbrandCheck && (!newDeviceDatObj?.ProductUniqueID || !newDeviceDatObj?.AlternateUniqueKey)) {
        const warningUpdateDevice = getTranslationText('warn', 'updateDevice').replace('@uniqeIDType', 'Serial Number')
        this.props.setToaster(warningUpdateDevice, 'warning')
        this.props.setglobalLoaderStatus(false)
        this.setState({
          BtnStatusLoader: false,
        })
        return
      }

      validateDevice(
        this.state.addingDeviceslistbox.productvalue[0],
        this.props.authenticateProductsAsync,
        newDeviceDatObj,
        _.get(this, 'props.devices.productBrandConfig.WarrantyCheckDetails', {}),
        _.get(this, 'props.user.appConfigDetails.app')
      )
        .then((data) => {
          this.props.setglobalLoaderStatus(false)
          if (data.isValid && !data.skipUpdateConsumerProduct) {
            this.handleAddNewDevice(data.productObj)
          } else if (data.isValid && data.skipUpdateConsumerProduct && data.verifyProduct) {
            this.props.setSelectedDevice(data.productObj)
            this.getDeviceDetails(data.productObj.ConsumerProductID)
          } else {
            if (productVal && productVal.IsUniqueIdMandatory) {
              if (!errorfree) {
                this.setState({
                  selectFrombtnStatus: true,
                })
              }
            }
            pushToAnalytics('Device_selected_continue_error', {
              Error_Message: data.msg || '',
              ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
              ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
            })
            this.props.setToaster(data.msg, 'error')
          }
        })
        .catch((e) => {
          pushToAnalytics('Device_selected_continue_error', {
            Error_Message: e.msg || '',
            ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
            ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
          })
          this.props.setglobalLoaderStatus(false)
        })
    } else {
      if (this.state.uniqueIdrequired || isNarwalUSbrandCheck) {
        let dataObj = {
          ProductUniqueID: this.state.newDeviceData.newDeviceImei1No || _.get(this, 'props.request.selectedDeviceInfo.ProductUniqueID'),
          AlternateUniqueKey:
            this.state.newDeviceData.newDeviceImei2No || _.get(this, 'props.request.selectedDeviceInfo.AlternateUniqueKey'),
          DownloadedDeviceUniqueKey: this.state.newDeviceData.newDeviceSerialNo || null,
        }
        if (this.state.newDeviceData.newDeviceSerialNo && this.state.addingDeviceslistbox.categoryvalue[0].CategoryId === 12) {
          dataObj = {
            ...dataObj,
            [regexCheck('validation', 'hasAlphachars', this.state.newDeviceData.newDeviceSerialNo) // eslint-disable-line
              ? 'DownloadedDeviceUniqueKey'
              : 'ProductUniqueID']: this.state.newDeviceData.newDeviceSerialNo.toUpperCase(),
          }
        }
        this.setState({
          BtnStatusLoader: true,
        })
        this.props.setglobalLoaderStatus(true)
        if (isNarwalUSbrandCheck && (!dataObj?.ProductUniqueID || !dataObj?.AlternateUniqueKey)) {
          const warningUpdateDevice = getTranslationText('warn', 'updateDevice').replace('@uniqeIDType', 'Serial Number')
          this.props.setToaster(warningUpdateDevice, 'warning')
          this.props.setglobalLoaderStatus(false)
          this.setState({
            BtnStatusLoader: false,
          })
          return
        }
        validateDevice(
          this.state.selectedDeviceData,
          this.props.authenticateProductsAsync,
          dataObj,
          _.get(this, 'props.devices.productBrandConfig.WarrantyCheckDetails', {}),
          _.get(this, 'props.user.appConfigDetails.app')
        ).then((data) => {
          this.props.setglobalLoaderStatus(false)
          if (data.isValid) {
            let productObj = data.productObj || {}
            let verificationStateMeta = this.state.selectedDeviceData.VerificationStateMeta || {}
            let updateObj = {
              WarrantyTill: verificationStateMeta.IsWarrantyEditable ? this.state.newDeviceData.WarrantyTill : undefined,
              ModelNo: this.state.newDeviceData.ModelNo,
              IsUnderWarranty: !!this.state.newDeviceData.WarrantyTill,
              TagName: this.state.newDeviceData.TagName,
              ProductUniqueID: dataObj?.ProductUniqueID,
              AlternateUniqueKey: dataObj?.AlternateUniqueKey,
            }
            this.setState({
              BtnStatusLoader: true,
            })
            this.props
              .updateDevicesAsync({
                AuthID: this.props.user.consumerDetails.data.accessToken.ID,
                ConsumerProductID: this.state.selectedDeviceData.ConsumerProductID,
                updateObj: {
                  ...updateObj,
                  ...productObj,
                },
              })
              .then(() => {
                this.props
                  .getPaginatedDevicesAsync({
                    ConsumerID,
                    ConsumerProductID: this.state.SelectedDeviceCPId,
                  })
                  .then(() => {
                    this.props.devices.deviceDetails.supportedDevices.find((productData) => {
                      if (productData.ConsumerProductID === this.state.SelectedDeviceCPId) {
                        this.setState(
                          {
                            selectedDeviceData: productData,
                            BtnStatusLoader: false,
                          },
                          () => {
                            this.setSelectedDevicePostAction()
                          }
                        )
                      }
                    })
                  })
              })
              .catch((e) => {
                this.setState({
                  BtnStatusLoader: false,
                })
                pushToAnalytics('Device_selected_continue_error', {
                  Error_Message: e.msg || '',
                  ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
                  ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
                })
                this.props.setglobalLoaderStatus(false)
              })
          } else {
            this.setState({
              selectFrombtnStatus: true,
              BtnStatusLoader: false,
            })
            this.props.setToaster(data.msg, 'error')
            pushToAnalytics('Device_selected_continue_error', {
              Error_Message: data.msg || '',
              ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
              ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
            })
          }
        })
      } else {
        // check device cooling period check
        let redirectionUrlPromise
        if (_.get(this, 'props.user.appConfigDetails.isRaiseClaimCoolingPeriodCheck', false)) {
          // to call airtel sepecific device plan details
          let productData = _.get(this, 'props.request.selectedDeviceInfo')
          let { mobileNo } = _.get(this, 'props.user.userDetails')
          let requestObject = {
            MobileNo: mobileNo,
            app: 'Airtel',
            ProductName: productData.Product.ProductName,
            ProductUniqueID: productData.ProductUniqueID,
            Brand: productData.Brand.BrandName,
            PlanID: _.get(productData, 'PolicyDetails[0].PlanID'),
          }
          redirectionUrlPromise = this.props
            .getConsumerPlanDetailsAsync(
              {
                ...requestObject,
              },
              this.props.user.consumerDetails.data.accessToken.ID
            )
            .then(() => {
              if (!_.get(this, 'props.request.coolingOffPlansDetails.SoldPlan.CanRaiseClaim', false)) {
                let errMsg = _.get(this, 'props.user.appConfigDetails.coolingPeriodClaimErrorMessage')
                this.props.setToaster(errMsg, 'error')
                pushToAnalytics('Device_selected_continue_error', {
                  Error_Message: errMsg || '',
                  ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
                  ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
                })
                let err = new Error(errMsg)
                throw err
              }
              this.props.setglobalLoaderStatus(false)
            })
            .catch((err) => {
              this.props.setglobalLoaderStatus(false)
              throw err
            })
        } else {
          redirectionUrlPromise = new Promise((resolve) => {
            resolve()
          })
        }
        redirectionUrlPromise.then(this.setSelectedDevicePostAction).catch((err) => {
          console.log('err', err)
        })
      }
    }
  }

  setSelectedDevicePostAction = () => {
    const appName = _.get(this, 'props.user.appConfigDetails.app', '')
    let isSubsciptionRepairFlow = ['OneplusNA-Web'].includes(appName)
    if (this.state.SelectedDeviceCPId !== 0) {
      this.props.setSelectedDevice({
        ...this.state.selectedDeviceData,
      })
      this.setState({
        isNewDevice: false,
      })
      let customerPlansPromise =
        isSubsciptionRepairFlow && _.get(this, 'state.selectedDeviceData.DeviceState.DeviceStateMeta.ShowRaiseClaim')
          ? this.props.fetchEligiblePlansNewAsync({
              ConsumerProductID: this.props.request.selectedDeviceInfo.ConsumerProductID,
            })
          : new Promise((resolve) => resolve())
      this.props.setglobalLoaderStatus(true)
      customerPlansPromise
        .then(() => {
          this.props.setglobalLoaderStatus(false)
          let checkforPlan =
            isSubsciptionRepairFlow && _.get(this, 'state.selectedDeviceData.DeviceState.DeviceStateMeta.ShowRaiseClaim')
              ? this.props.request.eligiblePlansNew.find((group) => _.includes([-2, 1], group.GroupStatus))
              : this.state.selectedDeviceData.PolicyDetails.find(
                  (planInfo) => planInfo.Status === 1 && planInfo.NoOfRepairsAllowed - planInfo.NoOfRepairsUsed > 0
                )
          let configDetails = _.get(this, 'props.user.appConfigDetails', {})
          configDetails = {
            showNormalRepairOptions: true,
            ...configDetails,
          }
          if (['install', 'service'].includes(this.props.location.query.type)) {
            browserHistory.push({
              pathname: '/request/modes',
              query: this.props.location.query,
            })
          } else if (!checkforPlan && configDetails.showNormalRepairOptions) {
            let planObject = { PlanType: 'normalflow', SoldPlanID: -1, isSetProgramatically: true }
            this.props.setSelectedDamageType(planObject)
            browserHistory.push({
              pathname: '/request/modes',
              query: this.props.location.query,
            })
          } else {
            let NavTxt = this.state.NavTabText
            let planID = 0
            this.props.clearSelectedDamageType()
            let planGroups =
              isSubsciptionRepairFlow && this.state.selectedDeviceData.DeviceState.DeviceStateMeta.ShowRaiseClaim
                ? this.props.request.eligiblePlansNew &&
                  this.props.request.eligiblePlansNew.filter((group) => _.includes([-7, -2, 0, 1], group.GroupStatus))
                : [
                    {
                      GroupID: 0,
                      GroupStatus: 1,
                      PlanObject: this.state.selectedDeviceData.PolicyDetails.filter(
                        (planInfo) => planInfo.Status === 1 && planInfo.NoOfRepairsAllowed - planInfo.NoOfRepairsUsed > 0
                      ),
                    },
                  ]
            let activeGroup = planGroups.filter((group) => group.GroupStatus === 1)[0]
            if (activeGroup) {
              let activePlan = activeGroup.PlanObject && activeGroup.PlanObject[0]
              this.props.setSelectedDamageType(activePlan)
              planID = activePlan && activePlan.SoldPlanID
            }
            this.setState({
              BtnStatusLoader: false,
              selectedPlanID: planID,
              uploadedInvoiceImgFlg: false,
              claimformInfo: {
                // reset claim from details
                deviceDamageDate: '',
                deviceDamageTime: '',
                deviceDamagePlace: {},
                deviceDamageLocation: '',
                alternateMobileNo: '',
                deviceOnState: null,
                deviceDamageDescp: '',
                deviceDescpLength: 0,
                charlength: 100,
              },
              NavTabText: NavTxt,
            })
            this.props
              .getProductDocsAsync(
                {
                  ConsumerProductID: this.state.selectedDeviceData.ConsumerProductID,
                },
                this.props.user.consumerDetails.data.accessToken.ID
              )
              .then(() => {
                if (checkforPlan) {
                  // ------------- CleverTap | Book Repair - Initiated --------/
                  const ProductName_ = _.get(this, 'state.selectedDeviceData.Product.ProductName', '')
                  const BrandName_ = _.get(this, 'state.selectedDeviceData.Brand.BrandName', '')
                  const ProductUniqueID_ = _.get(this, 'state.selectedDeviceData.ProductUniqueID', '')
                  const clevertapUserProfile = _.get(this, 'props.user', {})

                  pushToAnalytics(
                    'Book Repair - Initiated',
                    {
                      'Device Name': ProductName_,
                      Brand: BrandName_,
                      'Device IMEI / Serial Nos.': ProductUniqueID_,
                      ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', undefined),
                    },
                    clevertapUserProfile
                  )
                  // ------------- CleverTap | Book Repair - Initiated --------/
                  browserHistory.push({
                    pathname: '/request/plan',
                    query: this.props.location.query,
                  })
                } else {
                  this.props.setToaster(getTranslationText('error', 'noPlans'))
                }
              })
          }
        })
        .catch(() => {
          this.props.setglobalLoaderStatus(false)
        })
    }
  }

  checkFormat = (value) => {
    if (this.state.phnoLengthAr.includes(value.length) && !isNaN(value)) {
      this.setState(
        {
          invalidFormat: isValidMobileNumber(value, _.get(this, 'props.user.selectedCountryData.CountryCode')),
        },
        () => {
          this.state.invalidFormat && this.props.setToaster(getTranslationText('error', 'invalidMobileNumber'), 'error')
        }
      )
    }
    return true
  }

  // claim form event handlers
  handleClaimInputs = (e) => {
    var claimformInfo = { ...this.state.claimformInfo }
    if (e) {
      var claimFormKey = ''
      let eventID = e.currentTarget.id
      let eventValue = e.currentTarget.value
      if (eventID === 'damage-date') {
        claimFormKey = 'deviceDamageDate'
      } else if (eventID === 'damage-time') {
        claimFormKey = 'deviceDamageTime'
      } else if (regexCheck('onChange', 'damageLocation', eventValue) && eventID === 'device-damage-placeip' && eventValue.length <= 50) {
        // regex check for the place
        claimFormKey = 'deviceDamagePlace'
      } else if (
        regexCheck('onChange', 'damageLocation', eventValue) &&
        eventID === 'device-damage-locationip' &&
        eventValue.length <= 100
      ) {
        // regex check for the location(exact) at the time of damage
        claimFormKey = 'deviceDamageLocation'
      } else if (regexCheck('onChange', 'plainTxt', eventValue) && eventID === 'device-damage-descpip') {
        let minDescLength = 30
        let maxDescLength = 500
        let damageDescLength = eventValue.replace(/[\s]/g, '').length
        if (damageDescLength <= maxDescLength) {
          if (damageDescLength >= minDescLength) {
            this.setState({
              showDamageDescError: false,
            })
          } else {
            this.setState({
              showDamageDescError: true,
            })
          }
          claimFormKey = 'deviceDamageDescp'
        }
      } else if (eventID === 'screenOff-yes' || eventID === 'screenOff-no') {
        claimformInfo.deviceOnState = !!(eventID === 'screenOff-yes')
      } else if (eventID === 'alternateMobileNo') {
        if (
          this.checkFormat(e.currentTarget.value) &&
          regexCheck('onChange', 'numeric', eventValue, Math.max(...this.state.phnoLengthAr))
        ) {
          claimformInfo.alternateMobileNo = eventValue
          if (eventValue === _.get(this.props, 'user.userDetails.mobileNo')) {
            this.props.setToaster(getTranslationText('error', 'alternateMobileNumberFailed'), 'error')
            return
          }
          this.setState({ alternateMobile: eventValue })
        } else {
          return
        }
      } else if (eventID === 'repair-amount-ip') {
        // claimFormKey = 'deviceRepairAmount'
        if (regexCheck('onChange', 'numeric', eventValue, 5)) {
          claimformInfo['deviceRepairAmount'] = parseInt(eventValue)
        }
      } else if (eventID === 'repair-email') {
        if (regexCheck('onChange', 'emailId', eventValue, 40)) {
          claimformInfo['EmailID'] = eventValue
        }
      } else if (eventID === 'claim-usr-sign') {
        claimFormKey = 'signImgUrl'
      } else if (eventID === 'police-report-date') {
        claimFormKey = 'policeReportDate'
      } else if (eventID === 'police-report-number') {
        claimFormKey = 'policeReportNumber'
      } else if (eventID === 'date-of-theft') {
        claimFormKey = 'dateOfTheft'
      } else if (eventID === 'place-of-theft') {
        claimFormKey = 'placeOfTheft'
      } else if (eventID === 'law-enforcement-agency') {
        claimFormKey = 'policeAgency'
      } else if (regexCheck('onChange', 'plainTxt', eventValue) && eventID === 'theft-details') {
        let minDescLength = 30
        let maxDescLength = 500
        let theftDetailsLength = eventValue.replace(/[\s]/g, '').length
        if (theftDetailsLength <= maxDescLength) {
          if (theftDetailsLength >= minDescLength) {
            this.setState({
              showTheftDetailsError: false,
            })
          } else {
            this.setState({
              showTheftDetailsError: true,
            })
          }
          claimFormKey = 'theftDetails'
        }
      } else if (regexCheck('onChange', 'plainTxt', eventValue) && eventID === 'loss-details') {
        let minDescLength = 30
        let maxDescLength = 500
        let lossDetailsLength = eventValue.replace(/[\s]/g, '').length
        if (lossDetailsLength <= maxDescLength) {
          if (lossDetailsLength >= minDescLength) {
            this.setState({
              showDamageDescError: false,
            })
          } else {
            this.setState({
              showDamageDescError: true,
            })
          }
          claimFormKey = 'lossDetails'
        }
      } else if (eventID === 'loss-user-full-name') {
        claimFormKey = 'userFullName'
      } else if (eventID === 'loss-email-id') {
        if (regexCheck('onChange', 'emailId', eventValue, 40)) {
          claimformInfo['lossEmailId'] = eventValue
        }
      } else if (eventID === 'loss-mobile-number') {
        if (
          this.checkFormat(e.currentTarget.value) &&
          regexCheck('onChange', 'numeric', eventValue, Math.max(...this.state.phnoLengthAr))
        ) {
          claimformInfo.lossMobileNumber = eventValue
        } else {
          return
        }
      } else if (eventID === 'loss-billing-address') {
        claimFormKey = 'lossBillingAddress'
      }
      if (claimFormKey) {
        claimformInfo[claimFormKey] = eventValue
      }
      this.setState({ claimformInfo }, () => {
        this.props.setClaimDetails(this.state.claimformInfo)
      })
    }
  }

  toggleLossConsentCheck = () => {
    if (this.state.showAffidavitModal) {
      return
    } else {
      let claimformInfo = { ...this.state.claimformInfo }
      claimformInfo['lossConsentCheck'] = !claimformInfo['lossConsentCheck']
      this.setState({ claimformInfo }, () => {
        this.props.setClaimDetails(this.state.claimformInfo)
      })
    }
  }

  validateAffidavit = () => {
    this.setState({
      showAffidavitModal: true,
      lossAffidavitConsentCheck: false,
    })
  }

  validateClaimForm = async () => {
    try {
      const isRequireLocationPrompt = _.get(this, 'props.request.ClaimEligibilityList.requireLocationPrompt', false)
      if (isRequireLocationPrompt) {
        this.props.setglobalLoaderStatus(true)
        let location = await getCurrentPosition()
        if (location.coords.latitude) {
          const object = { CustomerCurrentLat: location.coords.latitude, CustomerCurrentLng: location.coords.longitude }
          this.props.setCurrentUserLocation(object)
          this.postValidateClaimCall(location.coords.latitude, location.coords.longitude)
        } else {
          this.setState({ detectLocationInstruction: true })
          this.props.setglobalLoaderStatus(false)
        }
      } else {
        this.postValidateClaimCall()
      }
    } catch {
      this.setState({ detectLocationInstruction: true })
      this.props.setglobalLoaderStatus(false)
    }
  }

  postValidateClaimCall = (latitude = undefined, longitude = undefined) => {
    this.props.setglobalLoaderStatus(true)
    this.props
      .checkClaimStatusAsync()
      .then((response) => {
        const selectedPlan = this.getSelectedPlan()
        if (response.data.ClaimRejected) {
          this.props.setglobalLoaderStatus(false)
          this.toggleClaimErrorPopUp(response)
          pushToAnalytics('Claim_details_plan_eligibility_pop_up', {
            ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
            ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
          })
        } else if (selectedPlan.SkipClaimApproval) {
          const decisionMatrixDetails = _.get(this, 'props.request.decisionMatrixDetails', false)
          this.props.setglobalLoaderStatus(true)
          let requestObj = {
            accountHolderFullName: _.get(this, 'props.request.claimFormDetails.userFullName', undefined),
            SoldPlanID: selectedPlan && selectedPlan.SoldPlanID,
            SoldPlanCoverageID: selectedPlan && selectedPlan.SoldPlanCoverageID,
            ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID'),
          }
          this.props.checkForHighRiskRequestAsync(requestObj).then((response) => {
            if (!response.highRiskRequest) {
              if (decisionMatrixDetails.IsDecisionMatrixEnabled) {
                this.props.setglobalLoaderStatus(true)
                let reqObject = {
                  SoldPlanID: selectedPlan && selectedPlan.SoldPlanID,
                  ConsumerID: this.props.user.userDetails.consumerID,
                  IsUrgent: false,
                  ConsumerServiceRequestID: 0,
                  Documents: [],
                  SoldPlanCoverageID: selectedPlan && selectedPlan.SoldPlanCoverageID,
                  PlanID: selectedPlan && selectedPlan.PlanID,
                  PlanCoverageID: selectedPlan && selectedPlan.PlanCoverageID,
                  DecisionMatrix: true,
                  ProductID: _.get(this.props, 'request.selectedDeviceInfo.ProductID'),
                  ProductSubCategoryID: _.get(this.props, 'request.selectedDeviceInfo.ProductSubCategoryID'),
                  BrandID: _.get(this.props, 'request.selectedDeviceInfo.BrandID'),
                  SkipClaimApproval: true,
                  ServiceTypeID: 10,
                  IsNonPartner: 0,
                  IsUnderRepair: false,
                  ClientServiceLocationID: _.get(this, 'props.request.partnerServiceLocation.ClientServiceLocationID', undefined),
                  DescriptionRecording: _.get(this, 'props.request.audioUploadInfo.audioUploadPath', undefined),
                  CustomerCurrentLat: latitude,
                  CustomerCurrentLng: longitude,
                }
                this.props
                  .createRequestAsync(reqObject)
                  .then((response) => {
                    this.setState({ generatedCSRID: response.data.ConsumerServiceRequestID })
                    this.requestRaisedSuccessModel()
                  })
                  .catch()
                  .finally(() => {
                    this.props.setglobalLoaderStatus(false)
                  })
              } else {
                // REDUCER SET: CONSENT DETAILS FOR PLAN COVERAGE
                this.props.setConsentCoverageConfig(_.get(this, 'props.user.claimLegalConfigs.LegalConfigs'))
                this.props.clearRequestModes()
                this.props.clearUserLocation('userLocation')
                this.props.setglobalLoaderStatus(false)
                this.props.setClaimDetails(this.state.claimformInfo)
                browserHistory.push({
                  pathname: '/request/modes',
                  query: {
                    ...this.props.location.query,
                    step: 1,
                  },
                })
              }
            } else {
              this.raiseNewClaimCall({ SkipClaimApproval: true, CustomerCurrentLat: latitude, CustomerCurrentLng: longitude })
            }
          })
          // ------------- CleverTap | Book Repair - Claim Form --------/
          let claimFormDetails = _.get(this, 'props.request.claimFormDetails', {})
          if (selectedPlan && selectedPlan.SkipClaimApproval && Object.keys(claimFormDetails).length) {
            const PlanName_ = selectedPlan.PlanDisplayName
            const SoldPlanID_ = selectedPlan.SoldPlanID
            const DeviceName_ = _.get(this, 'props.request.selectedDeviceInfo.Product.ProductName')
            const BrandName_ = _.get(this, 'props.request.selectedDeviceInfo.Brand.BrandName')
            const ProductUniqueID_ = _.get(this, 'props.request.selectedDeviceInfo.ProductUniqueID')
            const { deviceDamageDate, deviceDamagePlace, deviceDamageDescp } = claimFormDetails
            let SelectedDamageObj = claimFormDetails.typesOfDamage.filter((obj) => obj.isSelected)[0]
            const clevertapUserProfile = _.get(this, 'props.user', {})

            pushToAnalytics(
              'Book Repair - Claim Form',
              {
                Brand: BrandName_,
                'Plan Name': PlanName_,
                'Plan Sold ID': SoldPlanID_,
                'Device Name': DeviceName_,
                'Device IMEI': ProductUniqueID_,
                ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', undefined),
                'Date of Damage': moment(deviceDamageDate).format('DD MMM YYYY'),
                'Place of Damage': deviceDamagePlace,
                'Type of Damage': _.get(SelectedDamageObj, 'text', undefined),
                'Description of Damage': deviceDamageDescp,
                'Claim Type': 'Skip Claim Approval',
                CustomerCurrentLat: latitude,
                CustomerCurrentLng: longitude,
                Source: 'Web',
              },
              clevertapUserProfile
            )
          }
          // ------------- CleverTap | Book Repair - Claim Form --------/
        } else {
          this.raiseNewClaimCall()
        }
      })
      .catch((err) => {
        console.log(err)
        this.props.setglobalLoaderStatus(false)
      })
  }

  raiseNewClaimCall = (reqObject) => {
    this.props
      .raiseNewClaimAsync(reqObject)
      .then((response) => {
        this.props.setglobalLoaderStatus(false)
        // to call user profile update API (if Alternate mobile number and email field is present)
        let hasEmailID = _.get(this, 'props.request.selectedDamageType.ClaimForm.RequiresEmail', false)
        let hasAlternateMobileNumber = _.get(this, 'props.request.selectedDamageType.ClaimForm.RequiresAlternateNumber', false)
        let emailID = hasEmailID && _.get(this, 'props.request.claimFormDetails.EmailID', undefined)
        let alternateMobileNo = hasAlternateMobileNumber && _.get(this, 'props.request.claimFormDetails.alternateMobileNo', undefined)
        let updateProfilePromise
        if (alternateMobileNo || emailID) {
          updateProfilePromise = this.props.updateUserDetailsAsync({
            data: {
              consumerID: this.props.user.userDetails.consumerID,
              updateObj: {
                ...(alternateMobileNo ? { alternateMobileNo: alternateMobileNo } : {}),
                ...(emailID ? { emailID: emailID } : {}),
              },
            },
            AuthID: this.props.user.consumerDetails.data.accessToken.ID,
          })
        } else {
          updateProfilePromise = new Promise((resolve) => {
            resolve()
          })
        }
        updateProfilePromise.then(() => {
          this.setState(
            {
              generatedCSRID: response.data.ConsumerServiceRequestID,
              generatedReferenceID: response.data.ReferenceID,
            },
            () => {
              this.requestRaisedSuccessModel()
            }
          )
        })
      })
      .catch((err) => {
        console.log(err)
        this.props.setglobalLoaderStatus(false)
      })
  }

  toggleClaimErrorPopUp = (resp) =>
    this.setState({
      showClaimErrorPopUp: !this.state.showClaimErrorPopUp,
      claimErrorResp: resp,
    })

  // Slots
  handleAddressType = (e) => {
    const userCFLid = _.get(this, 'props.request.userLocation.ConsumerFavouriteLocationID', undefined)
    let id = parseInt(e.currentTarget.id.replace('addressType-', ''))
    const addrType = this.state.addressType.filter((address, index) => {
      return index === id
    })
    this.setState({
      addressType: this.state.addressType.map((address, index) => {
        return { ...address, isSelected: index === id }
      }),
      isSavedUserAddressEdited: !!userCFLid,
    })
    pushToAnalytics('Delivery_tag_address_selected', {
      ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
      ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
      tag_address: addrType[0].text,
    })
  }

  handleDeliveryAddressType = (e) => {
    // CSDR_id && isSavedDeliveryAddressEdited
    const deliveryCFLid = _.get(this, 'props.request.deliveryLocation.ConsumerFavouriteLocationID', undefined)
    let id = parseInt(e.currentTarget.id.replace('deliveryAddressType-', ''))
    const addrType = this.state.deliveryAddressType.filter((address, index) => {
      return index === id
    })
    this.setState({
      deliveryAddressType: this.state.deliveryAddressType.map((address, index) => {
        return { ...address, isSelected: index === id }
      }),
      isSavedDeliveryAddressEdited: !!deliveryCFLid,
    })
    pushToAnalytics('Delivery_tag_address_selected', {
      ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
      ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
      tag_address: addrType[0].text,
    })
  }

  handleNewInputAddress = (e) => {
    const userCFLid = _.get(this, 'props.request.userLocation.ConsumerFavouriteLocationID', undefined)
    if (regexCheck('onChange', 'plainTxt', e.target.value)) {
      let newAddress = this.state.newAddress
      newAddress = Object.assign(newAddress, { [e.target.name]: e.target.value })
      this.setState({
        newAddress,
        isSavedUserAddressEdited: !!userCFLid,
      })
    }
  }

  onChangeStateSelection = async (stateObject, addressDetailsType) => {
    let addressType = addressDetailsType == 'deliveryLocation' ? 'selectedDeliveryAddressState' : 'selectedState'
    await this.setState(
      {
        [addressType]: stateObject,
      },
      () => {
        if (addressDetailsType == 'deliveryLocation') {
          this.setState({
            deliveryAddress: {
              ...this.state.deliveryAddress,
              StateID: stateObject.StateID,
              state: stateObject.State,
              stateCode: stateObject.StateInitials,
            },
          })
        } else {
          this.setState({
            newAddress: {
              ...this.state.newAddress,
              StateID: stateObject.StateID,
              state: stateObject.State,
              stateCode: stateObject.StateInitials,
            },
          })
        }
      }
    )
  }

  handleNewDeliveryInputAddress = (e) => {
    const deliveryCFLid = _.get(this, 'props.request.deliveryLocation.ConsumerFavouriteLocationID', undefined)
    if (regexCheck('onChange', 'plainTxt', e.target.value)) {
      let deliveryAddress = this.state.deliveryAddress
      deliveryAddress = Object.assign(deliveryAddress, { [e.target.name]: e.target.value })
      this.setState({
        deliveryAddress,
        isSavedDeliveryAddressEdited: !!deliveryCFLid,
      })
    }
  }

  handleDateSelect = (e) => {
    const locale = _.get(this, 'props.user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
    let id = e.currentTarget.id.replace('datepill-', '')
    let selectedDate = this.state.datesToShow.find((_date, index) => parseInt(id) === index)
    this.setState({
      datesToShow: this.state.datesToShow.map((date, index) => ({
        ...date,
        isSelected: parseInt(id) === index,
      })),
      timesToShow: this.state.datesToShow
        .filter((_date, index) => index === parseInt(id))[0]
        .slots.map((time) => ({
          ...time,
          text: getFormattedTime(time.StartTimeVal, locale) + ' - ' + getFormattedTime(time.EndTimeVal, locale),
          isSelected: selectedDate.slots.length === 1,
          status: !time.IsActive,
        })),
    })
    pushToAnalytics('Select_preferred_day', {
      ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
      ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
    })
  }

  handleTimeSelect = (e) => {
    let id = e.currentTarget.id.replace('timepill-', '')
    this.setState({
      timesToShow: this.state.timesToShow.map((time, index) => ({
        ...time,
        isSelected: parseInt(id) === index,
      })),
    })
    pushToAnalytics('Select_preferred_time_slot', {
      ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
      ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
    })
  }

  dropoffDirections = () => {
    window.open(
      `https://www.google.com/maps?daddr=${this.props.request.partnerServiceLocation.Lat},
      ${this.props.request.partnerServiceLocation.Lng}&saddr=${this.props.request.userLocation.latitude},
      ${this.props.request.userLocation.longitude}&directionsmode=driving`,
      '_blank'
    )
  }

  // Request Issues event handlers
  handleIssueSelect = (e) => {
    let id = e.currentTarget.id.replace('issuepill-', '')
    let currentPill = this.state.issuesForType.find((_issue) => _issue.IssueID === parseInt(id))
    let selectedIssuesLength = this.state.issuesForType.filter((issue) => issue.isSelected).length
    let otherIssues = this.state.issuesForType.find(
      (issue) => issue.isSelected && ['other', 'others', 'other issue'].includes(issue.DisplayText.toLowerCase())
    )

    const MAX_ISSUES_ALLOWED = 4

    if (selectedIssuesLength < MAX_ISSUES_ALLOWED || (selectedIssuesLength === MAX_ISSUES_ALLOWED && currentPill.isSelected)) {
      if (['other', 'others', 'other issue'].includes(currentPill.DisplayText.toLowerCase()) && !currentPill.isSelected) {
        this.setState({
          showOtherInput: true,
          btnDisabled: true,
        })
      } else if (['other', 'others', 'other issue'].includes(currentPill.DisplayText.toLowerCase()) && currentPill.isSelected) {
        this.setState({
          showOtherInput: false,
          btnDisabled: false,
          otherInputText: '',
        })
      } else {
        this.setState({
          btnDisabled: otherIssues && otherIssues.isSelected ? !(this.state.otherInputText && this.state.otherInputText.length > 4) : false,
        })
      }
      this.setState({
        issuesForType: this.state.issuesForType.map((issue) => {
          return {
            ...issue,
            text: ['other', 'others', 'other issue'].includes(issue.DisplayText.toLowerCase()) ? issue.text : issue.Issue,
            isSelected: issue.IssueID === parseInt(id) ? !issue.isSelected : issue.isSelected,
          }
        }),
      })
    } else {
      this.props.setToaster(getTranslationText('request', 'selectMaxIssues'), 'error')
    }
  }

  // Other issue input handler
  handleOtherIssue = (e) => {
    let valueLength = regexCheck('getValue', 'characters', e.target.value).length
    if (e.target.value === '' || (regexCheck('onChange', 'plainTxt', e.target.value) && valueLength <= 200)) {
      this.setState(
        {
          otherInputText: e.target.value.replace(/[\s]/g, '').length <= 300 ? e.target.value : this.state.otherInputText,
        },
        () => {
          valueLength > 4 ? this.setState({ btnDisabled: false }) : this.setState({ btnDisabled: true })
        }
      )
    }
  }

  handleInvoiceUpload = async (e, callBack = () => {}) => {
    let file = e.target.files[0]
    let name = e.target.name
    let uniqueId = uuid.v1()
    const returnAfterS3Upload = e.returnAfterS3Upload
    return canUpload(e, { allowedTypes: ['image', 'pdf'] })
      .then(() => {
        return this.uploadImageFunc(name, file, uniqueId).then(() => {
          if (/\/select-reason/.test(this.props.location.pathname)) {
            callBack(this.props.user.s3Uploads.fileUrl)
          } else {
            if (returnAfterS3Upload) {
              return {
                success: true,
              }
            } else {
              this.setState(
                {
                  uploadedInvoiceImg: uniqueId,
                  uploadedInvoiceImgFlg: true,
                },
                () => {
                  pushToAnalytics('device_issue_image_upload', {
                    ConsumerID: _.get(this.props, 'request.selectedDeviceInfo.ConsumerID', ''),
                    ConsumerProductID: _.get(this.props, 'request.selectedDeviceInfo.ConsumerProductID', ''),
                  })
                  this.handleInvoiceUploadOnly()
                }
              )
            }
          }
        })
      })
      .catch((error) => this.props.setToaster(error.message, 'error'))
  }

  uploadToDocService = async (file) => {
    let uniqueId = uuid.v1()
    let clientRefId = uuid.v4()
    let token = _.get(this.props, 'user.consumerDetails.data.accessToken.ID')
    let data = new FormData()
    data.append('docType', 'ConsumerServiceRequestDocument')
    data.append('file', file)
    data.append('clientRefId', clientRefId)
    data.append('entity-id', uniqueId)
    data.append('entity-type', 'ConsumerServiceRequestDocument')
    data.append('module', docServiceModuleName)
    const response = await postRequestAsync(
      'internal/document/upload',
      data,
      { Authorization: token, module: docServiceModuleName },
      window.docServiceUri
    )
    return response
  }

  handlePoliceReportUpload = async (e) => {
    this.props.setglobalLoaderStatus(true)
    let file = e.target.files[0]
    return canUpload(e, { allowedTypes: ['image', 'pdf', 'application'] })
      .then(async () => {
        const response = await this.uploadToDocService(file)
        const claimformInfo = {
          ...this.state.claimformInfo,
          policeReportUrl: response.uploadURL,
          policeReportRenderableUrl: response.url,
          policeReportID: response.docServiceUUID,
        }
        this.setState({ claimformInfo }, () => {
          this.props.setClaimDetails(this.state.claimformInfo)
        })
        this.props.setglobalLoaderStatus(false)
      })
      .catch((error) => {
        console.log(error)
        this.props.setToaster(error.message, 'error')
        this.props.setglobalLoaderStatus(false)
      })
  }

  handleLargeFileUpload = async (e, callBack = () => {}) => {
    this.setState({
      uploadVideoFile: '',
    })
    this.props.setglobalLoaderStatus(true)
    const file = e.target.files[0]
    canUpload(e, { allowedTypes: ['video'] }).then(async () => {
      let clientRefId = uuid.v4()
      let data = new FormData()
      data.append('docType', 'ConsumerServiceRequestDocument')
      data.append('clientRefId', 'FECONS-' + clientRefId)
      data.append('entity-type', 'ConsumerID')
      data.append('entity-id', _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''))
      data.append('module', docServiceModuleName)
      data.append('fileName', file.name)
      data.append('mimeType', file.type)
      data.append('sizeInBytes', file.size)
      await this.props.addLargeFileToDSAsync(data, file)
      await this.props.uploadLargeFileToS3(this.props.user.docServiceLargeFileDetails.url, file)
      await this.props.acknowledgeUploadDocToDSAsync(this.props.user.docServiceLargeFileDetails.docServiceUUID)
      await this.props.getUploadDocToDSAsync(this.props.user.docServiceLargeFileDetails.docServiceUUID)
      callBack(this.props.user.docServiceLargeFileDetails.fileUrl)
      this.props.setglobalLoaderStatus(false)
    })
  }

  handleVideoUpload = async (e, callBack = () => {}) => {
    this.setState({
      uploadVideoFile: '',
    })
    let file = e.target.files[0]
    if (file) {
      if (file.size > 40000000) {
        this.props.setglobalLoaderStatus(false)
        this.props.setToaster('File is exceeding maximum size limit', 'error')
      } else {
        let name = e.target.name
        let uniqueId = uuid.v1()
        const returnAfterS3Upload = e.returnAfterS3Upload
        this.props.setglobalLoaderStatus(true)
        return canUpload(e, {
          allowedTypes: ['video'],
        })
          .then(() => {
            this.props.setglobalLoaderStatus(true)
            return this.uploadImageFunc(name, file, uniqueId)
              .then(() => {
                if (/\/select-reason/.test(this.props.location.pathname)) {
                  callBack(this.props.user.s3Uploads.fileUrl)
                } else if (/\/documents/.test(this.props.location.pathname)) {
                  this.setState(
                    {
                      uploadVideoFile: name,
                    },
                    () => {
                      callBack(this.props.user.s3Uploads.fileUrl)
                      return
                    }
                  )
                } else {
                  if (returnAfterS3Upload) {
                    return {
                      success: true,
                    }
                  } else {
                    this.setState(
                      {
                        uploadedInvoiceImg: uniqueId,
                        uploadedInvoiceImgFlg: true,
                      },
                      () => {
                        this.handleInvoiceUploadOnly()
                      }
                    )
                  }
                }
              })
              .catch(() => {
                this.props.setToaster('There was an error while uploading your video. Please try again!', 'error')
              })
              .finally(() => {
                this.props.setglobalLoaderStatus(false)
              })
          })
          .catch((error) => {
            console.log(error)
            this.props.setToaster('File format not supported', 'error')
          })
          .finally(() => {
            this.props.setglobalLoaderStatus(false)
          })
      }
    } else {
      this.props.setglobalLoaderStatus(false)
    }
  }

  docUploadFunc = (e, docUploadCallBack = () => {}) => {
    let file = e.target.files[0]
    let name = e.target.name
    let uniqueId = uuid.v1()
    this.props.setglobalLoaderStatus(true)
    canUpload(e, { allowedTypes: ['image', 'pdf'] })
      .then(() => {
        this.uploadImageFunc(name, file, uniqueId)
          .then(() => {
            docUploadCallBack()
          })
          .catch((err) => console.log('upload img error: ', err))
      })
      .catch((error) => this.props.setToaster(error.message, 'error'))
      .finally(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  uploadImageFunc = async (name, file, uniqueId) => {
    return this.props.getSignedUrlAsync(
      {
        AuthID: this.props.user.consumerDetails.data.accessToken.ID,
        data: {
          Type: file.type,
          Key: uniqueId,
        },
      },
      file,
      name
    )
  }

  handleInvoiceUploadOnly = () => {
    this.props.setglobalLoaderStatus(true)
    if (/claim-form/.test(this.props.location.pathname)) {
      this.props.setClaimDetails(this.state.claimformInfo)
    } else if (/select-device/.test(this.props.location.pathname)) {
      this.setState({
        newDeviceDocsList: {
          imgFilePath: this.props.user.s3Uploads.fileUrl,
          imageID: this.props.user.s3Uploads.uuid,
        },
        isInvoiceUploaded: true,
      })
    }
    if (this.state.isNewDevice) {
      this.props.setglobalLoaderStatus(false)
    } else {
      this.addDocsToProduct()
    }
  }

  addDocsToProduct = () => {
    this.props
      .addProductDocAsync({
        ConsumerProductID: this.props.request.selectedDeviceInfo.ConsumerProductID,
        AuthID: this.props.user.consumerDetails.data.accessToken.ID,
      })
      .then(() => {
        this.props.setglobalLoaderStatus(false)
        return this.props
          .getProductDocsAsync(
            {
              ConsumerProductID: this.props.request.selectedDeviceInfo.ConsumerProductID,
            },
            this.props.user.consumerDetails.data.accessToken.ID
          )
          .then(() => {
            if (/select-device/.test(this.props.location.pathname) && !this.state.isNewDevice) {
              this.setState({
                newDeviceDocsList: {
                  ...this.state.newDeviceDocsList,
                  imageID: this.props.devices.productDocs.find((img) => img.FileName === this.state.newDeviceDocsList.imageID)
                    .ConsumerProductDocumentID,
                },
              })
            }
          })
      })
      .catch(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  handleDamageTypeSelect = (e) => {
    let id = parseInt(e.currentTarget.id.replace('radio-', ''))
    if (id !== this.state.selectedPlanID) {
      this.props.setActivationPlan(undefined)
      let planObject
      const appName = _.get(this, 'props.user.appConfigDetails.app', '')
      let isSubsciptionRepairFlow = ['OneplusNA-Web'].includes(appName)
      let planGroups =
        isSubsciptionRepairFlow && _.get(this, 'props.request.selectedDeviceInfo.DeviceState.DeviceStateMeta.ShowRaiseClaim')
          ? this.props.request.eligiblePlansNew &&
            this.props.request.eligiblePlansNew.filter((group) => _.includes([-7, -2, 0, 1], group.GroupStatus))
          : [
              {
                GroupID: 0,
                GroupStatus: 1,
                PlanObject: this.props.request.selectedDeviceInfo.PolicyDetails.filter(
                  (planInfo) => planInfo.Status === 1 && planInfo.NoOfRepairsAllowed - planInfo.NoOfRepairsUsed > 0
                ),
              },
            ]
      /** fixes for more than one protection plan */
      for (let group of planGroups) {
        planObject = group.PlanObject.filter((plan) => plan.SoldPlanID === id)[0]
        if (planObject) {
          break
        }
      }
      planObject = planObject || { PlanType: 'normalflow', SoldPlanID: -1 }
      if (planObject) {
        this.setState({
          selectedPlanID: id,
          uploadedInvoiceImgFlg: false,
          claimformInfo: {
            deviceDamageDate: '',
            deviceDamageTime: '',
            deviceDamagePlace: {},
            deviceDamageLocation: '',
            alternateMobileNo: '',
            deviceOnState: null,
            deviceDamageDescp: '',
            deviceDescpLength: 0,
            charlength: 100,
            lossConsentCheck: false,
          },
        })
      }
      this.props.setSelectedDamageType(planObject)
    }
  }

  handlePlanActivationSelect = (e) => {
    let id
    if (/radio/.test(e.currentTarget.id)) {
      id = parseInt(e.currentTarget.id.replace('radio-', ''))
    } else {
      id = parseInt(e.currentTarget.id.replace('unpurchased-', ''))
    }
    let planObject
    for (let group of this.props.request.eligiblePlansNew) {
      planObject = group.PlanObject.filter((plan) => {
        if (/radio/.test(e.currentTarget.id)) {
          return plan.SoldPlanID === id
        } else {
          return plan.PlanID === id
        }
      })[0]
      if (planObject) {
        this.setState({
          selectedPlanID: id,
          uploadedInvoiceImgFlg: false,
          claimformInfo: {
            deviceDamageDate: '',
            deviceDamageTime: '',
            deviceDamagePlace: {},
            deviceDamageLocation: '',
            alternateMobileNo: '',
            deviceOnState: null,
            deviceDamageDescp: '',
            deviceDescpLength: 0,
            charlength: 75,
          },
        })
        break
      }
    }
    this.props.setActivationPlan(planObject)
  }

  // Service Modes event handlers
  handleModeSelect = (e) => {
    this.props.setClaimLegalConfigs({})
    let id = e.currentTarget.id.replace('radio-', '')
    if (this.props.request.selectedMode && this.props.request.selectedMode.ServiceTypeID !== parseInt(id)) {
      this.props.clearDateTimeSlots()
    }
    this.setState({
      selectedMode: parseInt(id),
    })
    this.props.setAddressFlgToStore(this.state.newAddressFlg)
    const selectedMode = this.props.request.requestModesData.modes.filter((mode) => mode.ServiceTypeID === parseInt(id))[0]
    this.props.setSelectedMode(selectedMode)
    pushToAnalytics('service_type_selected', {
      service_type: selectedMode.ServiceTypeCode || '',
      ServiceTypeID: selectedMode.ServiceTypeID || '',
      Category: selectedMode.Category || '',
      zip_code: _.get(this, 'props.request.userLocation.Zipcode', _.get(this, 'props.request.userLocation.PinCode', '')),
      ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
      ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
    })
  }

  detectBrowserLocation = async (addressKey) => {
    let location = await getCurrentPosition()
    let request = {
      location: new window.google.maps.LatLng(location.coords.latitude, location.coords.longitude),
    }
    let result = await getAddress(request)
    /********************** Detect Location Logger API /**********************/
    if (window.useLogger) {
      let loggerObject = {
        apiName: 'detectBrowserLocation',
        value: result.geometry || '',
        event: 'fetch-detect-google-address',
        identifiers: {
          ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
        },
      }
      this.props.loggerActionAsync(loggerObject)
    }
    /********************** Detect Location Logger API /**********************/
    return await this.props.getGeoLocationAsync(result, addressKey)
  }

  handleDetectClick = () => {
    this.props.setglobalLoaderStatus(true)
    this.detectBrowserLocation()
      .then(() => {
        this.props.setglobalLoaderStatus(false)
        this.setState(
          {
            selectedMode: undefined,
            landmark: this.props.request.userLocation.landmark,
            locationObtained: true,
            requestModeListLoader: true,
            newAddressFlg: true, // Users types in new address
            disableContinue: true,
          },
          () => {
            /********************** Set Detect Location Logger API /**********************/
            if (window.useLogger) {
              let loggerObject = {
                apiName: 'saveDetectLocationActionInRedcuer',
                value: _.get(this, 'props.request.userLocation'),
                event: 'set-detect-google-address-in-local-storage',
                identifiers: {
                  ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
                },
              }
              this.props.loggerActionAsync(loggerObject)
            }
            /********************** Set Detect Location Logger API /**********************/
            let addressConfig = _.get(this, 'props.user.countryAddressConfig.addressConfig', fallbackAddressConfig)
            const isDistrictMendatory = addressConfig.some((v) => v['keyName'] === 'District' && v['mandatory'])
            const isDistrictPresent = _.get(this, 'props.request.userLocation.District', undefined)
            if (isDistrictMendatory && !isDistrictPresent) {
              this.setUserAddressStateID().then(() => {
                if (_.get(this, 'props.request.userLocation.StateCode')) {
                  this.getDistrictListDetails(_.get(this, 'props.request.userLocation.StateCode'))
                }
              })
            } else {
              this.setUserAddressStateID().then(() => {
                this.fetchRequestModes(
                  {},
                  () => {
                    this.setState({
                      requestModeListLoader: false,
                      disableContinue: false,
                    })
                    if (!this.props.request.requestModesData.isServiceable) {
                      this.resetPlacesAutoCompleteValue()
                      this.props.setToaster(getTranslationText('error', 'serviceModesUnavailable'))
                    } else {
                      this.setDefaultSelectedMode()
                    }
                  },
                  () => {
                    this.resetPlacesAutoCompleteValue()
                  }
                )
              })
            }
          }
        )
      })
      .catch((e) => {
        console.log(e)
        this.props.setglobalLoaderStatus(false)
        this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
      })
  }

  handleDeliveryDetectClick = () => {
    this.props.setglobalLoaderStatus(true)
    this.detectBrowserLocation('deliveryLocation')
      .then(() => {
        this.props.setglobalLoaderStatus(false)
        this.setState(
          {
            deliveryLandmark: this.props.request.deliveryLocation.landmark,
            deliveryLocationObtained: true,
            newDeliveryAddressFlg: true,
            disableContinue: true,
            isLoaderSet: true,
          },
          () => {
            let addressConfig = _.get(this, 'props.user.countryAddressConfig.addressConfig', fallbackAddressConfig)
            const isDistrictMendatory = addressConfig.some((v) => v['keyName'] === 'District' && v['mandatory'])
            const isDistrictPresent = _.get(this, 'props.request.deliveryLocation.District', undefined)

            this.setDeliveryAddressStateID().then(() => {
              if (isDistrictMendatory && !isDistrictPresent) {
                if (_.get(this, 'props.request.deliveryLocation.StateCode')) {
                  this.getDistrictListDetails(_.get(this, 'props.request.deliveryLocation.StateCode'))
                }
              } else {
                this.fetchRequestModes(
                  {
                    Lat: _.get(this, 'props.request.deliveryLocation.latitude'),
                    Lng: _.get(this, 'props.request.deliveryLocation.longitude'),
                    Zipcode:
                      _.get(this, 'props.request.deliveryLocation.pincode', undefined) ||
                      _.get(this, 'props.request.deliveryLocation.PinCode', undefined) ||
                      _.get(this, 'props.request.deliveryLocation.Zipcode', undefined),
                    City: _.get(this, 'props.request.deliveryLocation.city', undefined),
                    State: _.get(this, 'props.request.deliveryLocation.state', undefined),
                    StateID: _.get(this, 'props.request.deliveryLocation.StateID', undefined),
                    District: _.get(this, 'props.request.deliveryLocation.District', undefined),
                  },
                  (requestModesData) => {
                    this.setState({
                      requestModeListLoader: false,
                      disableContinue: false,
                      isLoaderSet: false,
                      deliveryAddress: _.get(this, 'props.request.deliveryLocation', {}),
                      deliveryAddressType: this.state.deliveryAddressType.map((address) => {
                        return {
                          ...address,
                          isSelected:
                            this.props.request.deliveryLocation && address.text === this.props.request.deliveryLocation.AddressType,
                        }
                      }),
                      newDeliveryAddressFlg: true,
                    })
                    let isServiceable = false
                    _.forEach(requestModesData && requestModesData.modes, function (data) {
                      if (_.includes([17, 23, 1, 11], parseInt(data.ServiceTypeID)) && data.IsAvailable) {
                        isServiceable = true
                      }
                    })
                    this.setState({
                      isServiceable,
                    })
                  },
                  () => {
                    this.resetPlacesAutoCompleteValue({
                      isServiceable: false,
                    })
                  },
                  true
                )
              }
            })
          }
        )
      })
      .catch(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  getRequestModes = () => {
    this.setUserAddressStateID().then(() => {
      let addressConfig = _.get(this, 'props.user.countryAddressConfig.addressConfig', fallbackAddressConfig)
      const isDistrictMendatory = addressConfig.some((v) => v['keyName'] === 'District' && v['mandatory'])
      const isDistrictPresent = _.get(this, 'props.request.userLocation.District', undefined)
      if (isDistrictMendatory && !isDistrictPresent) {
        if (_.get(this, 'props.request.userLocation.StateCode')) {
          this.getDistrictListDetails(_.get(this, 'props.request.userLocation.StateCode'))
        }
      } else {
        this.fetchRequestModesAPi()
      }
    })
  }

  getDistrictListDetails = (StateCode) => {
    this.props.setglobalLoaderStatus(true)
    let stateCode = StateCode || this.props.request.userLocation.stateCode
    let countryCode = this.props.user.selectedCountryData.CountryCode
    let reqObj = {
      StateCode: `${stateCode}`,
      CountryCode: countryCode,
    }
    this.props
      .getDistrictListAsync(reqObj)
      .then((res) => {
        if (res && res.length) {
          this.setState({
            showDistrictSelectionModel: true,
          })
        } else {
          this.props.setToaster('No District Found.') // translation string
          this.setState({
            isLoaderSet: false,
          })
        }
      })
      .finally(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  fetchRequestModesAPi = () => {
    this.setState({
      lat: this.props.request.userLocation.latitude,
      lng: this.props.request.userLocation.longitude,
      requestModeListLoader: true,
      disableContinue: true,
    })
    this.fetchRequestModes(
      {},
      () => {
        // .then callBack
        this.setState({
          requestModeListLoader: false,
          disableContinue: false,
          newAddressFlg: true,
        })
        if (!this.props.request.requestModesData.isServiceable) {
          this.resetPlacesAutoCompleteValue({
            locationObtained: false,
            selectedMode: undefined,
            modeSelectStep: 1,
            newAddressFlg: false,
          })
          // this.props.clearUserLocation('userLocation')
          // this.props.clearRequestModes()
          this.props.setToaster(getTranslationText('error', 'serviceModesUnavailable'))
        } else {
          this.setDefaultSelectedMode()
        }
      },
      () => {
        // error callBack
        this.resetPlacesAutoCompleteValue({
          requestModeListLoader: false,
          disableContinue: false,
        })
      }
    )
  }

  setDefaultSelectedMode = () => {
    let availModes = this.props.request.requestModesData.modes.filter((mode) => mode.IsAvailable)
    if (availModes && availModes.length === 1) {
      this.setState({
        selectedMode: availModes[0].ServiceTypeID,
      })
      this.props.setAddressFlgToStore(this.state.newAddressFlg)
      this.props.setSelectedMode(availModes[0])
    } else {
      this.setState({
        selectedMode: undefined,
      })
    }
  }

  handleTypeaheadSelect = (address, locationType, claimform = false) => {
    if (claimform) {
      /********************** Claim-Form - Set Google Location Logger API /**********************/
      if (window.useLogger) {
        let loggerObject = {
          apiName: 'ClaimFormActionTypeAhead',
          value: address.geometry || '',
          event: 'claim-form-get-typeAhead-google-address-input',
          identifiers: {
            ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
          },
        }
        this.props.loggerActionAsync(loggerObject)
      }
      /********************** Claim-Form - Set Google Location Logger API /**********************/
    } else {
      /********************** Set Google Location Logger API /**********************/
      if (window.useLogger) {
        let loggerObject = {
          apiName: 'ActionTypeAhead',
          value: address.geometry || '',
          event: 'get-typeAhead-google-address-input',
          identifiers: {
            ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
          },
        }
        this.props.loggerActionAsync(loggerObject)
      }
      /********************** Set Google Location Logger API /**********************/
    }

    this.props.setPlaceOfDamageLocation({})
    const updatedState =
      locationType === 'userLocation'
        ? {
            selectedMode: undefined,
            disableContinue: true,
          }
        : {
            newDeliveryAddressFlg: true,
          }
    const locationObtainedType = locationType === 'userLocation' ? 'locationObtained' : 'deliveryLocationObtained'
    this.setState({ ...updatedState, locationType, [locationObtainedType]: true }, () => {
      this.props.getGeoLocationAsync(address, locationType).then(() => {
        if (claimform) {
          let claimformInfo = { ...this.state.claimformInfo }
          this.props.setPlaceOfDamageLocation({ ...this.props.request.userLocation })
          if (Object.keys(_.get(this, 'props.request.address_placeOfDamage', {})).length) {
            const { landmark, latitude, longitude } = this.props.request.address_placeOfDamage
            claimformInfo['deviceDamagePlace'] = {
              country: _.get(this, 'props.user.selectedCountryData.CountryCode', 'IN').toLowerCase(),
              lat: latitude,
              lng: longitude,
              landmark: landmark,
            }
          } else {
            claimformInfo['deviceDamagePlace'] = {}
          }
          this.setState(
            {
              claimformInfo,
            },
            () => {
              this.props.setClaimDetails(this.state.claimformInfo)
            }
          )
        } else {
          /********************** Set User Location Logger API /**********************/
          if (window.useLogger) {
            let loggerObject = {
              apiName: 'ActionTypeAheadSetAddressReducer',
              value: _.get(this, 'props.request.userLocation'),
              event: 'set-typeAhead-google-address-local-storage',
              identifiers: {
                ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
              },
            }
            this.props.loggerActionAsync(loggerObject)
          }
          /********************** Set User Location Logger API /**********************/
          let addressConfig = _.get(this, 'props.user.countryAddressConfig.addressConfig', fallbackAddressConfig)
          const isPinCodeEnabled = addressConfig.some((v) => (v['keyName'] === 'PinCode' || v['keyName'] === 'Zipcode') && v['mandatory'])
          if (isPinCodeEnabled && !this.props.request[locationType].pincode) {
            this.setState({
              showPinCodeModal: true,
              pincode: '',
              [locationObtainedType]: false,
            })
          } else {
            locationType === 'userLocation' ? this.getRequestModes() : this.getDeliveryModes()
          }
        }
      })
    })
  }

  setUserAddressStateID = async (userAddressLocation = []) => {
    let addressConfig = _.get(this, 'props.user.countryAddressConfig.addressConfig', fallbackAddressConfig)
    const isDistrictPresent = addressConfig.some((v) => v['keyName'] === 'District' && v['mandatory'])
    const isAddressState = _.get(this, 'props.request.userLocation.StateID', false)
    const isStateMendatory = addressConfig.some((v) => v['keyName'] === 'State' && v['mandatory'])
    let selectedAddressStateID
    let selectedAddressStateCode

    if (!isAddressState || (isDistrictPresent && !_.get(this, 'props.request.userLocation.StateCode', false))) {
      const userAddressState = _.get(this, 'props.request.userLocation.state', '') || userAddressLocation['State']
      const countryStateList = _.get(this, 'props.user.stateAddressListConfig', [])
      let selectedAddressState =
        (await countryStateList) && countryStateList.length && countryStateList.find((v) => userAddressState === v['State'])
      if (selectedAddressState && selectedAddressState['StateID']) {
        selectedAddressStateID = selectedAddressState['StateID']
        selectedAddressStateCode = selectedAddressState['StateCode']
      } else {
        selectedAddressState =
          (await countryStateList) && countryStateList.length && countryStateList.find((v) => userAddressState === v['StateInitials'])
        if (selectedAddressState && selectedAddressState['StateID']) {
          selectedAddressStateID = selectedAddressState['StateID']
          selectedAddressStateCode = selectedAddressState['StateCode']
        } else {
          if (isStateMendatory) {
            this.setState({
              showStateSelectionModel: true,
            })
          }
          return
        }
      }
    } else {
      selectedAddressStateID = isAddressState
      if (isDistrictPresent) {
        selectedAddressStateCode = _.get(this, 'props.request.userLocation.StateCode')
      }
    }

    await this.props.setUserRequestLocation({
      key: 'userLocation',
      value: {
        ..._.get(this, 'props.request.userLocation'),
        StateID: selectedAddressStateID,
        StateCode: selectedAddressStateCode,
      },
    })

    await this.setState(
      {
        selectedAddressStateID,
      },
      () => {
        new Promise((resolve) => {
          resolve()
        })
      }
    )
  }

  setDeliveryAddressStateID = async (deliveryAddressLocation = []) => {
    let addressConfig = _.get(this, 'props.user.countryAddressConfig.addressConfig', fallbackAddressConfig)
    const isDistrictPresent = addressConfig.some((v) => v['keyName'] === 'District' && v['mandatory'])
    const isAddressState = _.get(this, 'props.request.deliveryLocation.StateID', false)
    const isStateMendatory = addressConfig.some((v) => v['keyName'] === 'State' && v['mandatory'])
    let selectedDeliveryAddressStateID
    let selectedDeliveryAddressStateCode
    if (!isAddressState || (isDistrictPresent && !_.get(this, 'props.request.deliveryLocation.StateCode', false))) {
      const userAddressState = _.get(this, 'props.request.deliveryLocation.state', '') || deliveryAddressLocation['State']
      const countryStateList = _.get(this, 'props.user.stateAddressListConfig', [])
      let selectedAddressState =
        (await countryStateList) && countryStateList.length && countryStateList.find((v) => userAddressState === v['State'])
      if (selectedAddressState && selectedAddressState['StateID']) {
        selectedDeliveryAddressStateID = selectedAddressState['StateID']
        selectedDeliveryAddressStateCode = selectedAddressState['StateCode']
      } else {
        selectedAddressState =
          (await countryStateList) && countryStateList.length && countryStateList.find((v) => userAddressState === v['StateInitials'])
        if (selectedAddressState && selectedAddressState['StateID']) {
          selectedDeliveryAddressStateID = selectedAddressState['StateID']
          selectedDeliveryAddressStateCode = selectedAddressState['StateCode']
        } else {
          if (isStateMendatory) {
            this.setState({
              showStateSelectionModel: true,
            })
          }
          return
        }
      }
    } else {
      selectedAddressStateID = isAddressState
      if (isDistrictPresent) {
        selectedDeliveryAddressStateCode = _.get(this, 'props.request.deliveryLocation.StateCode')
      }
    }

    await this.props.setUserRequestLocation({
      key: 'deliveryLocation',
      value: {
        ..._.get(this, 'props.request.deliveryLocation'),
        StateID: selectedDeliveryAddressStateID,
        StateCode: selectedDeliveryAddressStateCode,
      },
    })

    await this.setState(
      {
        selectedDeliveryAddressStateID,
      },
      () => {
        new Promise((resolve) => {
          resolve()
        })
      }
    )
  }

  getDeliveryModes = () => {
    this.setState({
      lat: this.props.request.deliveryLocation.latitude,
      lng: this.props.request.deliveryLocation.longitude,
      requestModeListLoader: true,
      disableContinue: true,
      isLoaderSet: true,
    })

    let addressConfig = _.get(this, 'props.user.countryAddressConfig.addressConfig', fallbackAddressConfig)
    const isDistrictMendatory = addressConfig.some((v) => v['keyName'] === 'District' && v['mandatory'])
    const isDistrictPresent = _.get(this, 'props.request.deliveryLocation.District', undefined)
    this.setDeliveryAddressStateID().then(() => {
      if (isDistrictMendatory && !isDistrictPresent) {
        if (_.get(this, 'props.request.deliveryLocation.StateCode')) {
          this.getDistrictListDetails(_.get(this, 'props.request.deliveryLocation.StateCode'))
        }
      } else {
        this.fetchRequestModes(
          {
            Lat: _.get(this, 'props.request.deliveryLocation.latitude'),
            Lng: _.get(this, 'props.request.deliveryLocation.longitude'),
            Zipcode:
              _.get(this, 'props.request.deliveryLocation.pincode', undefined) ||
              _.get(this, 'props.request.deliveryLocation.PinCode', undefined) ||
              _.get(this, 'props.request.deliveryLocation.Zipcode', undefined),
            City: _.get(this, 'props.request.deliveryLocation.city', undefined),
            State: _.get(this, 'props.request.deliveryLocation.state', undefined),
            StateID: _.get(this, 'props.request.deliveryLocation.StateID', undefined),
            District: _.get(this, 'props.request.deliveryLocation.District', undefined),
          },
          async (requestModesData) => {
            let isServiceable = false
            await _.forEach(requestModesData && requestModesData.modes, function (data) {
              if (_.includes([17, 23, 1, 11], parseInt(data.ServiceTypeID)) && data.IsAvailable) {
                isServiceable = true
              }
            })
            await this.setState({
              isServiceable,
              requestModeListLoader: false,
              disableContinue: false,
              isLoaderSet: false,
              deliveryAddress: _.get(this, 'props.request.deliveryLocation', {}),
              deliveryAddressType: this.state.deliveryAddressType.map((address) => {
                return {
                  ...address,
                  isSelected: this.props.request.deliveryLocation && address.text === this.props.request.deliveryLocation.AddressType,
                }
              }),
              newDeliveryAddressFlg: true,
            })
          },
          () => {
            this.setState({
              requestModeListLoader: false,
              disableContinue: false,
              isLoaderSet: false,
            })
          },
          true
        )
      }
    })
  }

  handleUserAddressClick = async (locationID) => {
    let addressConfig = _.get(this, 'props.user.countryAddressConfig.addressConfig', fallbackAddressConfig)
    const isStateMendatory = addressConfig.some((v) => v['keyName'] === 'State' && v['mandatory'])

    const isDistrictMendatory = addressConfig.some((v) => v['keyName'] === 'District' && v['mandatory'])
    let locationObj = this.props.user.locations.filter((location) => location.ConsumerFavouriteLocationID === locationID)[0]
    const isDistrictPresent = _.get(locationObj, 'District', undefined) || _.get(this, 'props.request.userLocation.District', undefined)
    if (isStateMendatory && !locationObj.State) {
      this.setState({
        selectedState: {},
        showStateSelectionModel: true,
        userAddresslocationObj: locationObj,
      })
    } else {
      if (locationObj.StateID) {
        if (isDistrictMendatory && !isDistrictPresent) {
          this.setUserAddressStateID(locationObj, isDistrictMendatory).then(() => {
            if (_.get(this, 'props.request.userLocation.StateCode', locationObj.StateCode)) {
              this.setState(
                {
                  userAddresslocationObj: locationObj,
                },
                () => {
                  this.getDistrictListDetails(_.get(this, 'props.request.userLocation.StateCode', locationObj.StateCode))
                }
              )
            }
          })
        } else {
          let addressConfig = _.get(this, 'props.user.countryAddressConfig.addressConfig', fallbackAddressConfig)
          const isPinCodeEnabled = addressConfig.some((v) => (v['keyName'] === 'PinCode' || v['keyName'] === 'Zipcode') && v['mandatory'])
          if (isPinCodeEnabled && !locationObj.Zipcode) {
            this.setState(
              {
                selectedMode: undefined,
                showPinCodeModal: true,
                pincode: '',
                locationType: 'userLocation',
                locationObtained: !!locationObj.Landmark || !!locationObj.landmark,
              },
              () => {
                this.props.setUserRequestLocation({
                  key: 'userLocation',
                  value: {
                    ...locationObj,
                    latitude: parseFloat(locationObj.Lat ? locationObj.Lat : locationObj.latitude),
                    longitude: parseFloat(locationObj.Lng ? locationObj.Lng : locationObj.longitude),
                    city: locationObj.City || locationObj.city || undefined,
                    state: locationObj.State || locationObj.state || undefined,
                  },
                })
              }
            )
          } else {
            this.callUserAddressClick(locationObj)
          }
        }
      } else {
        if (isDistrictMendatory && !isDistrictPresent) {
          this.setUserAddressStateID(locationObj, isDistrictMendatory).then(() => {
            if (_.get(this, 'props.request.userLocation.StateCode')) {
              this.getDistrictListDetails(_.get(this, 'props.request.userLocation.StateCode'))
            }
          })
        } else {
          this.setUserAddressStateID(locationObj).then(() => {
            this.callUserAddressClick(locationObj)
          })
        }
      }
    }
  }

  callUserAddressClick = async (locationObj, selectedState) => {
    let city = locationObj?.City || locationObj?.city
    const isAECountry = _.get(this, 'props.user.selectedCountryData.CountryCode').toLowerCase() == 'ae'
    city = isAECountry ? fetchCity(city) : city

    /********************** Get UserSaved Location Logger API /**********************/
    if (window.useLogger) {
      let loggerObj = {
        apiName: 'GetUserSavedAddressInput',
        value: locationObj,
        event: 'get-user-saved-address',
        identifiers: {
          ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
        },
      }
      this.props.loggerActionAsync(loggerObj)
    }
    /********************** Get UserSaved  Location Logger API /**********************/
    await this.props.setUserRequestLocation({
      key: 'userLocation',
      value: {
        ...locationObj,
        landmark: locationObj.Landmark || locationObj.landmark,
        pincode: locationObj.PinCode || locationObj.Zipcode || locationObj.pincode,
        latitude: parseFloat(locationObj.Lat ? locationObj.Lat : locationObj.latitude),
        longitude: parseFloat(locationObj.Lng ? locationObj.Lng : locationObj.longitude),
        state: selectedState ? selectedState['State'] || locationObj.state : locationObj.State || locationObj.state,
        StateID:
          (selectedState && selectedState['StateID']) ||
          (locationObj && locationObj['StateID']) ||
          this.state.selectedAddressStateID ||
          undefined,
        City: city || undefined,
        DistrictName:
          (selectedState && selectedState.DistrictName) ||
          (locationObj && locationObj['DistrictName']) ||
          locationObj['District'] ||
          undefined,
        District:
          (selectedState && selectedState.DistrictName) ||
          (locationObj && locationObj['DistrictName']) ||
          locationObj['District'] ||
          undefined,
        DistrictID:
          (selectedState && selectedState.DistrictID) ||
          (locationObj && locationObj['DistrictID']) ||
          locationObj['DistrictID'] ||
          undefined,
      },
    })
    this.setState({
      selectedMode: undefined,
      newAddressFlg: false, // if default address has been selected
      lat: this.props.request.userLocation.latitude,
      lng: this.props.request.userLocation.longitude,
      requestModeListLoader: true,
      disableContinue: true,
    })
    pushToAnalytics(this.state.modeSelectStep === 2 ? 'zip_code' : 'Service_zip_code_selected', {
      zip_code: locationObj.PinCode || locationObj.Zipcode || locationObj.pincode || '',
      ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
      ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
    })
    this.fetchRequestModes(
      {
        Zipcode: locationObj.PinCode || locationObj.Zipcode || locationObj.pincode,
      },
      () => {
        this.setState({
          requestModeListLoader: false,
          disableContinue: false,
          userAddresslocationObj: {},
          selectedState: {},
        })
        if (!this.props.request.requestModesData.isServiceable) {
          this.resetPlacesAutoCompleteValue({
            locationObtained: false,
            selectedMode: undefined,
            modeSelectStep: 1,
            newAddressFlg: false,
          })
          this.props.setToaster(getTranslationText('error', 'serviceModesUnavailable'))
        } else {
          this.setDefaultSelectedMode()
        }
      },
      () => {
        this.resetPlacesAutoCompleteValue({
          requestModeListLoader: false,
          disableContinue: false,
          newAddressFlg: false,
        })
      }
    )
  }

  handleStateSelection = (stateObject) => {
    this.setState({ selectedState: stateObject })
  }

  handleDistrictSelection = (stateObject) => {
    this.setState({ selectedDistrict: stateObject })
  }

  countryStateSelection = () => {
    const { userAddresslocationObj, deliveryAddresslocationObj, selectedState } = this.state
    const isUserAddresslocationObj_ = !!Object.keys(userAddresslocationObj).length
    const isDeliveryAddresslocationObj_ = !!Object.keys(deliveryAddresslocationObj).length
    this.setState(
      {
        showStateSelectionModel: false,
      },
      () => {
        if (/slots/.test(this.props.location.pathname)) {
          if (!isDeliveryAddresslocationObj_) {
            this.props.setUserRequestLocation({
              key: 'deliveryLocation',
              value: {
                ...this.props.request.deliveryLocation,
                StateID:
                  (selectedState && selectedState['StateID']) ||
                  (locationObj && locationObj['StateID']) ||
                  this.state.selectedAddressStateID ||
                  undefined,
                StateCode: selectedState && selectedState['StateCode'],
                State: selectedState && selectedState['State'],
                state: selectedState && selectedState['State'],
              },
            })
            let addressConfig = _.get(this, 'props.user.countryAddressConfig.addressConfig', fallbackAddressConfig)
            const isDistrictMendatory = addressConfig.some((v) => v['keyName'] === 'District' && v['mandatory'])
            if (isDistrictMendatory) {
              this.getDistrictListDetails(selectedState['StateCode'])
            }
          } else {
            isDeliveryAddresslocationObj_ && this.callDeliveryAddressClick(deliveryAddresslocationObj, selectedState)
          }
        } else {
          if (!isUserAddresslocationObj_) {
            this.props.setUserRequestLocation({
              key: 'userLocation',
              value: {
                ...this.props.request.userLocation,
                StateID:
                  (selectedState && selectedState['StateID']) ||
                  (locationObj && locationObj['StateID']) ||
                  this.state.selectedAddressStateID ||
                  undefined,
                StateCode: selectedState && selectedState['StateCode'],
                State: selectedState && selectedState['State'],
                state: selectedState && selectedState['State'],
              },
            })
            let addressConfig = _.get(this, 'props.user.countryAddressConfig.addressConfig', fallbackAddressConfig)
            const isDistrictMendatory = addressConfig.some((v) => v['keyName'] === 'District' && v['mandatory'])
            if (isDistrictMendatory) {
              this.getDistrictListDetails(selectedState['StateCode'])
            } else {
              this.callUserAddressClick(_.get(this, 'props.request.userLocation', ''), selectedState)
            }
          } else {
            isUserAddresslocationObj_ && this.callUserAddressClick(userAddresslocationObj, selectedState)
            isDeliveryAddresslocationObj_ && this.callDeliveryAddressClick(deliveryAddresslocationObj, selectedState)
          }
        }
      }
    )
  }

  countryDistrictSelection = () => {
    const { userAddresslocationObj, deliveryAddresslocationObj, selectedDistrict } = this.state
    const isUserAddresslocationObj_ = !!Object.keys(userAddresslocationObj).length
    const isDeliveryAddresslocationObj_ = !!Object.keys(deliveryAddresslocationObj).length
    this.setState(
      {
        showDistrictSelectionModel: false,
      },
      () => {
        if (/slots/.test(this.props.location.pathname)) {
          if (isDeliveryAddresslocationObj_ || !!Object.keys(_.get(this, 'props.request.deliveryLocation', {})).length) {
            this.callDeliveryAddressClick(
              isDeliveryAddresslocationObj_ ? deliveryAddresslocationObj : this.props.request.deliveryLocation,
              selectedDistrict
            )
          }
        } else {
          this.callUserAddressClick(isUserAddresslocationObj_ ? userAddresslocationObj : this.props.request.userLocation, selectedDistrict)
        }
      }
    )
  }

  handleDeliveryAddressClick = async (locationID) => {
    let addressConfig = _.get(this, 'props.user.countryAddressConfig.addressConfig', fallbackAddressConfig)
    const isStateMendatory = addressConfig.some((v) => v['keyName'] === 'State' && v['mandatory'])
    const isDistrictMendatory = addressConfig.some((v) => v['keyName'] === 'District' && v['mandatory'])
    let locationObj = this.props.user.locations.filter((location) => location.ConsumerFavouriteLocationID === locationID)[0]
    const isDistrictPresent = _.get(locationObj, 'District', undefined) || _.get(this, 'props.request.deliveryLocation.District', undefined)
    if (isStateMendatory && !locationObj.State) {
      this.setState({
        selectedState: {},
        showStateSelectionModel: true,
        deliveryAddresslocationObj: locationObj,
      })
    } else {
      if (locationObj.StateID) {
        if (isDistrictMendatory && !isDistrictPresent) {
          this.setUserAddressStateID(locationObj, isDistrictMendatory).then(() => {
            if (_.get(this, 'props.request.deliveryLocation.StateCode')) {
              this.getDistrictListDetails(_.get(this, 'props.request.deliveryLocation.StateCode'))
            }
          })
        } else {
          this.callDeliveryAddressClick(locationObj)
        }
      } else {
        if (isDistrictMendatory && !isDistrictPresent) {
          this.setUserAddressStateID(locationObj, isDistrictMendatory).then(() => {
            if (_.get(this, 'props.request.deliveryLocation.StateCode')) {
              this.getDistrictListDetails(_.get(this, 'props.request.deliveryLocation.StateCode'))
            }
          })
        } else {
          this.setUserAddressStateID(locationObj).then(() => {
            this.callDeliveryAddressClick(locationObj)
          })
        }
      }
    }
  }

  callDeliveryAddressClick = async (locationObj, selectedState) => {
    this.props.setglobalLoaderStatus(true)
    await this.props.setUserRequestLocation({
      key: 'deliveryLocation',
      value: {
        ...locationObj,
        landmark: locationObj.Landmark || locationObj.landmark,
        pincode: locationObj.PinCode || locationObj.Zipcode || locationObj.pincode,
        latitude: parseFloat(locationObj.Lat || locationObj.latitude),
        longitude: parseFloat(locationObj.Lng || locationObj.longitude),
        state: selectedState ? selectedState['State'] || locationObj.state : locationObj.State,
        City: locationObj.City || locationObj.city || undefined,
        city: locationObj.City || locationObj.city || undefined,
        StateID:
          (selectedState && selectedState['StateID']) ||
          (locationObj && locationObj['StateID']) ||
          this.state.selectedDeliveryAddressStateID ||
          undefined,
        DistrictName:
          (selectedState && selectedState.DistrictName) ||
          (locationObj && locationObj['DistrictName']) ||
          locationObj['District'] ||
          undefined,
        District:
          (selectedState && selectedState.DistrictName) ||
          (locationObj && locationObj['DistrictName']) ||
          locationObj['District'] ||
          undefined,
        DistrictID: (selectedState && selectedState.DistrictID) || (locationObj && locationObj['DistrictID']) || undefined,
      },
    })
    await this.setState({
      newDeliveryAddressFlg: true,
      lat: this.props.request.userLocation.latitude,
      lng: this.props.request.userLocation.longitude,
      disableContinue: true,
      isLoaderSet: true,
      deliveryLandmark: _.get(this, 'props.request.deliveryLocation.landmark', ''),
    })
    this.fetchRequestModes(
      {
        Lat: this.props.request.deliveryLocation.latitude,
        Lng: this.props.request.deliveryLocation.longitude,
        Zipcode: locationObj.pincode || locationObj.PinCode || locationObj.Zipcode,
        City: _.get(this, 'props.request.deliveryLocation.city', undefined),
        State: _.get(this, 'props.request.deliveryLocation.state', undefined),
        StateID: _.get(this, 'props.request.deliveryLocation.StateID', undefined),
        District: _.get(this, 'props.request.deliveryLocation.District', undefined),
      },
      (requestModesData) => {
        this.setState({
          requestModeListLoader: false,
          disableContinue: false,
          isLoaderSet: false,
          deliveryAddress: _.get(this, 'props.request.deliveryLocation', {}),
          deliveryAddressType: this.state.deliveryAddressType.map((address) => {
            return {
              ...address,
              isSelected: this.props.request.deliveryLocation && address.text === this.props.request.deliveryLocation.AddressType,
            }
          }),
          newDeliveryAddressFlg: false,
          deliveryAddresslocationObj: {},
          selectedState: {},
          selectedDeliveryAddressStateID: '',
          selectedAddressStateID: '',
        })
        let isServiceable = false
        _.forEach(requestModesData && requestModesData.modes, function (data) {
          if (_.includes([17, 23, 1, 11], parseInt(data.ServiceTypeID)) && data.IsAvailable) {
            isServiceable = true
          }
        })
        this.setState({
          isServiceable,
        })
      },
      () => {
        this.resetPlacesAutoCompleteValue({
          requestModeListLoader: false,
          disableContinue: false,
          isLoaderSet: false,
        })
      },
      true
    )
    this.props.setglobalLoaderStatus(false)
  }

  resetPlacesAutoCompleteValue = (restObj) => {
    this.setState({
      requestModeListLoader: false,
      placesAutocompleteOptions: {
        location: '',
        radius: 0,
      },
      landmark: '',
      ...restObj,
    })
  }

  fetchRequestModes = (reqObj, callback = () => {}, errCallBack = () => {}, isDropoffCheck = false) => {
    const showDisClaimerModal = _.indexOf([17], _.get(this, 'props.user.appConfigDetails.ServiceModeConfigs.Disclaimers[0]', false)) >= 0
    if (showDisClaimerModal && this.state.isDisClaimerModalClosed) {
      this.setState({
        isDisClaimerModalClosed: false,
      })
    }
    let damageObj = _.get(this, 'props.request.claimFormDetails.typesOfDamage', []).filter((obj) => obj.isSelected)[0]
    reqObj = this.deviceInfoBasedonReqType(reqObj, false)
    if (_.get(this, 'props.location.query.type', 'repair').toLowerCase() === 'replacement') {
      reqObj = {
        ...reqObj,
        Benefit: 'REPLACEMENT',
        // StandardReplacement: true,
        // AdvanceExchange: true
      }
    }

    if (_.get(this, 'props.track.trackDetails.ConsumerProduct.ConsumerServiceRequestID')) {
      this.props.unsetSignedUrl({})
    }

    const selectedPlan = this.getSelectedPlan()
    if (selectedPlan) {
      reqObj = {
        ...reqObj,
        SoldPlanID: selectedPlan.SoldPlanID,
        PlanID: selectedPlan.PlanID,
        SoldPlanCoverageID: selectedPlan.SoldPlanCoverageID,
        PlanCoverageID: selectedPlan.PlanCoverageID,
      }
    }

    const soldPlanCoverages = _.get(this, 'props.request.ClaimEligibilityList.soldPlanCoverages', undefined)
    const SumInsured = _.get(
      _.find(soldPlanCoverages, (o) => o.isSelected),
      'soldPlanclaimDetails.SumInsured',
      undefined
    )

    const damageId_ = _.get(this, 'props.track.requestDetails.damage[0].damageId', undefined)

    /********************** Before fetchRequest Modes API calling - Logger API /**********************/
    if (window.useLogger) {
      let loggerObj = {
        apiName: 'ConsumerServicerequest/getServiceAvailabilityWithLocations',
        value: {
          Lat: this.props.request.userLocation.latitude,
          Lng: this.props.request.userLocation.longitude,
          SoldPlanID: reqObj.SoldPlanID || undefined,
          PlanID: reqObj.PlanID || undefined,
        },
        event: 'get-data-before-mode-api-calling',
        identifiers: {
          ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
        },
      }
      this.props.loggerActionAsync(loggerObj)
    }
    /********************** Before fetchRequest Modes API calling - Logger API /**********************/
    this.props
      .getRequestModesAsync(
        {
          damageId: damageObj ? damageObj.id : damageId_,
          Category: _.get(this, 'props.location.query.type', 'repair').toLowerCase(),
          Zipcode: this.props.request.userLocation.pincode,
          CountryID: this.props.user.selectedCountryData.CountryID,
          Radius: 80,
          Partnered: 1,
          raiseUnder: selectedPlan
            ? selectedPlan.PlanType === 'Secure'
              ? 'EW'
              : undefined
            : this.props.request.source && this.props.request.source.claim === 'true'
            ? 'CLAIM'
            : undefined,
          Lat: this.props.request.userLocation.latitude,
          Lng: this.props.request.userLocation.longitude,
          State: _.get(this, 'props.request.userLocation.state', this.state.selectedState.State),
          StateID: _.get(
            this,
            'props.request.userLocation.StateID',
            (this.state.selectedState && this.state.selectedState.StateID) || this.state.selectedAddressStateID
          ),
          DistrictID: _.get(
            this,
            'props.request.userLocation.DistrictID',
            this.state.selectedDistrict && this.state.selectedDistrict.DistrictID
          ),
          District: _.get(
            this,
            'props.request.userLocation.DistrictName',
            this.state.selectedDistrict && this.state.selectedDistrict.DistrictName
          ),
          City: _.get(this, 'props.request.userLocation.city') || _.get(this, 'props.request.userLocation.City'),
          ...reqObj,
          PlanCoverageID:
            _.get(this, 'props.track.trackDetails.PlanCoverageID', undefined) ||
            _.get(this, 'props.request.selectedDamageType.PlanCoverageID', undefined),
          SoldPlanCoverageID:
            _.get(this, 'props.track.trackDetails.SoldPlanCoverageID', undefined) ||
            _.get(this, 'props.request.selectedDamageType.SoldPlanCoverageID', undefined),
          allowedModes: _.get(this, 'props.track.trackDetails.DecisionMatrixModes', undefined),
          SumInsured,
          ConsumerServiceRequestID: _.get(this, 'props.track.trackDetails.ConsumerProduct.ConsumerServiceRequestID'),
        },
        this.props.user.consumerDetails.data.accessToken.ID,
        isDropoffCheck
      )
      .then((requestModesData) => {
        let reqObj = {
          Lat: this.props.request.userLocation.latitude.toString(),
          Lng: this.props.request.userLocation.longitude.toString(),
          CountryID: this.props.user.selectedCountryData.CountryID,
          CountryCode: this.props.user.selectedCountryData.CountryCode,
          BrandID: this.props.request.selectedDeviceInfo.BrandID,
          ProductID: this.props.request.selectedDeviceInfo.ProductID,
          ProductSubCategoryID: this.props.request.selectedDeviceInfo.ProductSubCategoryID,
          PlanID: this.props.request.selectedDamageType.PlanID,
          StateID: _.get(
            this,
            'props.request.userLocation.StateID',
            (this.state.selectedState && this.state.selectedState.StateID) || this.state.selectedAddressStateID
          ),
          Zipcode: this.props.request.userLocation.pincode,
        }

        let isServiceTypeID = requestModesData.modes.findIndex((v) => v.ServiceTypeID === 23)

        if (isServiceTypeID > -1) {
          this.props.setglobalLoaderStatus(true)
          this.props
            .getEmptyBoxServiceAvailabilityAsync(reqObj)
            .then(() => {
              this.props.setglobalLoaderStatus(true)
            })
            .finally(() => {
              this.props.setglobalLoaderStatus(false)
            })
        }

        const containsAEWithoutDefective = requestModesData?.modes?.some((serviceMode) => serviceMode.ServiceTypeID === 65)
        if (containsAEWithoutDefective) {
          this.props.setglobalLoaderStatus(true)
          this.props
            .getAEWithoutDefectiveServiceAvailability(reqObj)
            .catch((err) => {
              console.log(err)
            })
            .finally(() => {
              this.props.setglobalLoaderStatus(false)
            })
        }

        callback(requestModesData)
      })
      .catch(() => {
        errCallBack()
      })
  }

  onChangeTypeahead = async (address, claimForm = false) => {
    if (!address.length && claimForm) {
      this.state.claimformInfo['deviceDamagePlace'] = {}
      this.props.setClaimDetails(this.state.claimformInfo)
    }
    await this.setState({
      landmark: address,
      locationObtained: false,
      selectedMode: undefined,
      modeSelectStep: 1,
      newAddressFlg: true, // Users types in new address
    })
    _.get(this, 'props.request.userLocation.landmark') && this.props.clearUserLocation('userLocation', address)
    _.get(this, 'props.request.requestModesData.modes') && this.props.clearRequestModes()
    if (this.state.modeSelectStep === 2) {
      let newUrl = '/request/modes?type=' + this.props.request.source.type
      // this.props.addToVisitedUrl(newUrl)
      browserHistory.replace(newUrl)
    }
  }

  onChangeDeliveryTypeahead = (address) => {
    this.setState({
      deliveryLandmark: address,
      deliveryLocationObtained: false,
      newDeliveryAddressFlg: true,
      deliveryAddress: {},
      deliveryAddressType: this.state.deliveryAddressType.map((address) => {
        return { ...address, isSelected: false }
      }),
    })
  }
  selectServiceLocation = async (e) => {
    if (_.includes([66], this.state.selectedMode)) {
      const selectedPartnerServiceLocationData = this.props.request.selectedReplacementOption.ServiceLocations.find(
        (location) => location.ClientServiceLocationID == parseInt(e.currentTarget.id)
      )
      await this.setState({
        selectedPartnerServiceLocation: selectedPartnerServiceLocationData,
      })
      this.props.setPartnerServiceLocation(selectedPartnerServiceLocationData)
      pushToAnalytics('Service_location_selected', {
        Service_Location_Zip: selectedPartnerServiceLocationData?.Zipcode || selectedPartnerServiceLocationData?.PinCode || '',
        ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
        ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
      })
      this.navToConfirmationPage()
    } else {
      const selectedPartnerServiceLocationData = this.props.request.requestModesData.serviceLocations.find(
        (location) =>
          location.ClientServiceLocationID == parseInt(e.currentTarget.id) ||
          location.PartnerServiceLocationID == parseInt(e.currentTarget.id) ||
          location.ClientServiceLocationID == e.currentTarget.id
      )
      await this.setState({
        selectedPartnerServiceLocation: selectedPartnerServiceLocationData,
      })
      pushToAnalytics('Service_location_selected', {
        Service_Location_Zip: selectedPartnerServiceLocationData?.Zipcode || selectedPartnerServiceLocationData?.PinCode || '',
        ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
        ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
      })
    }
  }

  handleDropOffItemClick = (e) => {
    if ([17, 23].indexOf(parseInt(this.props.location.query.mode)) >= 0 && this.props.location.query.changeMode) {
      browserHistory.replace('/track?id=' + this.props.location.query.changeMode)
      return
    }
    let dropoffEventId = _.get(this, 'props.request.requestModesData.dropOffLocations[0].ServiceLocationCode')
    const userLocation = _.get(this, 'props.request.userLocation', '')
    let dropoffObj
    let externalSlots = undefined
    if (_.includes([17, 23, 57, 58], this.state.selectedMode)) {
      dropoffObj = this.props.request.requestModesData.dropOffLocations
        .filter((location) => location.ServiceLocationCode === (this.state.dropoffEventId || dropoffEventId))
        .map((obj) => ({ ...obj, Lat: parseFloat(obj.Lat), Lng: parseFloat(obj.Lng) }))[0]
      if (dropoffObj && !dropoffObj.PartnerServiceLocationID) {
        dropoffObj = {
          ...dropoffObj,
          PartnerID: this.props.request.requestModesData.PartnerID,
          PartnerServiceLocationID: this.props.request.requestModesData.PartnerServiceLocationID,
        }
      }
      this.props.setPartnerServiceLocation(dropoffObj)
    } else if (_.includes([66], this.state.selectedMode)) {
      dropoffObj = this.props.request.selectedReplacementOption.ServiceLocations.find(
        (location) => location.ClientServiceLocationID == parseInt(e.currentTarget.id)
      )
      this.props.setPartnerServiceLocation(dropoffObj)
      this.navToConfirmationPage()
      return
    } else {
      externalSlots = this.state.selectedPartnerServiceLocation?.externalSlots
      this.props.setPartnerServiceLocation(this.state.selectedPartnerServiceLocation)
    }
    if (_.includes([17, 23, 57, 58], this.state.selectedMode)) {
      // when drop off service location is avaialble
      dropoffObj && dropoffObj.WorkingHours && this.props.setRequestSlots(dropoffObj.WorkingHours)
      this.postDropoffSelect()
    } else {
      this.props.setglobalLoaderStatus(true)
      pushToAnalytics('Shipping_location_continue_button_clicked', {
        Service_Location_Zip: _.get(this, 'props.request.partnerServiceLocation.ZipCode', ''),
        ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
        ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
      })
      this.props
        .getRequestSlotsAsync({
          Pincode: userLocation.pincode || userLocation.PinCode || userLocation.Zipcode,
          CurrentDate: moment().format('YYYY-MM-DD'),
          CurrentTime: moment().format('hh:mm:ss'),
          DeliveryMode: !_.includes([17, 23, 57, 58], this.props.request.selectedMode.ServiceTypeID)
            ? 1 // hardcode
            : undefined,
          Lat: this.props.request.userLocation.latitude,
          Lng: this.props.request.userLocation.longitude,
          PartnerID: this.props.request.partnerServiceLocation
            ? this.props.request.partnerServiceLocation.PartnerID
            : this.props.request.requestModesData.PartnerID,
          ProductID: this.props.request.selectedDeviceInfo && this.props.request.selectedDeviceInfo.ProductID,
          PartnerServiceLocationID: !_.includes([17, 23, 57, 58], this.props.request.selectedMode.ServiceTypeID)
            ? _.get(this, 'state.selectedPartnerServiceLocation.PartnerServiceLocationID', '')
            : undefined,
          ServiceTypeID: this.props.request.selectedMode.ServiceTypeID,
          DropOffCenter: dropoffObj,
          Zipcode: dropoffObj ? dropoffObj.PinCode : undefined,
          externalSlots,
        })
        .then(() => {
          this.props.setglobalLoaderStatus(false)
          if (this.props.request.requestSlotsData) {
            this.postDropoffSelect()
          }
        })
        .catch(() => {
          this.props.setglobalLoaderStatus(false)
        })
    }
  }

  postDropoffSelect = () => {
    browserHistory.push({
      pathname: '/request/slots',
      query: {
        ...this.props.location.query,
        step: this.props.location.query.changeMode || _.includes([2, 12, 32], this.props.request.selectedMode.ServiceTypeID) ? 2 : 1,
      },
    })
  }

  handleBackButton = () => {
    const showDisClaimerModal = _.indexOf([17], _.get(this, 'props.user.appConfigDetails.ServiceModeConfigs.Disclaimers[0]', false)) >= 0
    if (showDisClaimerModal && this.state.isDisClaimerModalClosed) {
      this.setState({
        isDisClaimerModalClosed: false,
      })
    }
    this.props.removeVisitedUrl()
    browserHistory.goBack()
  }

  normalRepairReqFlow = () => {
    const serviceTypeID = this.props.request.selectedMode.ServiceTypeID
    /********************** Initiate createRequest API calling - Logger API /**********************/
    if (window.useLogger) {
      let loggerObj = {
        apiName: 'initiate-/ConsumerServicerequest/createRequest',
        value: {
          userLocation: _.get(this, 'props.request.userLocation'),
          Lat: (_.get(this, 'props.request.userLocation.latitude') || _.get(this, 'props.request.userLocation.Lat', '')).toString(),
          Lng: (_.get(this, 'props.request.userLocation.longitude') || _.get(this, 'props.request.userLocation.Lng', '')).toString(),
          ServiceTypeID: _.get(this, 'props.request.selectedMode.ServiceTypeID'),
          PaymentProcessID: _.get(this, 'props.payment.payableDetails'),
        },
        event: 'initiate-createRequest-api-calling',
        identifiers: {
          ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
        },
      }
      this.props.loggerActionAsync(loggerObj)
    }
    /********************** Initiate createRequest API calling - Logger API /**********************/
    this.props.setglobalLoaderStatus(true)
    // drop off claim/ normal handled over here
    const AuthID = this.props.user.consumerDetails.data.accessToken.ID
    const ConsumerID = this.props.user.userDetails.consumerID
    const selectedPlanInfo = this.getSelectedPlan()
    const userLocation = _.get(this, 'props.request.userLocation')
    const dateAndTime = _.get(this, 'props.request.dateAndTime')
    const isDropoffSlotsData = _.includes([17, 23], serviceTypeID)
    let scheduledDateTime = undefined
    let scheduledFromTime = undefined
    let scheduledToTime = undefined
    const isDropOffLocationAvailable = _.get(this, 'props.request.requestModesData.isDropOffLocationAvailable')
    if (isDropoffSlotsData) {
      if (isDropOffLocationAvailable) {
        scheduledDateTime = _.get(this, 'props.request.requestSlotsData[0].date')
        scheduledFromTime = _.get(this, 'props.request.requestSlotsData[0].slots[0].StartTimeVal')
        scheduledToTime = _.get(this, 'props.request.requestSlotsData[0].slots[0].EndTimeVal')
      } else {
        scheduledDateTime = _.get(this, 'props.request.requestModesData.dropOffVendorList[0].slots[0].date')
        scheduledFromTime = _.get(this, 'props.request.requestModesData.dropOffVendorList[0].slots[0].slots[0].StartTimeVal')
        scheduledToTime = _.get(this, 'props.request.requestModesData.dropOffVendorList[0].slots[0].slots[0].EndTimeVal')
      }
    }

    if (_.get(this, 'props.request.selectedMode.logisticsSuppressed', false)) {
      scheduledDateTime = moment().format('YYYY-MM-DD')
      scheduledFromTime = moment().format('hh:mm:ss')
      scheduledToTime = moment().format('hh:mm:ss')
    }

    const isAnDropoffFlow = _.includes([17, 23, 57, 58], serviceTypeID)
    const isAdvanceExchangeFlow = _.includes([65], serviceTypeID)
    let PartnerServiceLocationID = isAnDropoffFlow
      ? _.get(this, 'props.request.requestModesData.dropOffServicePartner.PartnerServiceLocationID')
      : isAdvanceExchangeFlow
      ? _.get(this, 'props.request.requestModesData.advanceExchangeDetails.PartnerServiceLocationID')
      : null
    let PartnerID = isAnDropoffFlow
      ? _.get(this, 'props.request.requestModesData.dropOffServicePartner.PartnerID')
      : isAdvanceExchangeFlow
      ? _.get(this, 'props.request.requestModesData.advanceExchangeDetails.PartnerID')
      : null
    let DeliveryMode = isAnDropoffFlow ? _.get(this, 'props.request.requestModesData.dropOffServicePartner.DeliveryMode') : null

    let claimRequestIDMapping = {
      1: 11,
      2: 12,
      17: 23,
    }
    let dataObject = {
      DescriptionRecording: _.get(this, 'props.request.audioUploadInfo.audioUploadPath'),
      Address: userLocation.Address || '',
      SoldPlanID: selectedPlanInfo && selectedPlanInfo.SoldPlanID,
      PaymentID: _.get(this, 'props.payment.paymentID'),
      PaymentProcessID: _.get(this, 'props.payment.payableDetails.id') || _.get(this, 'props.payment.telrPayCreateDetails.order.ref'),
      AddressType: userLocation.AddressType,
      ConsumerID,
      IsNonPartner: _.get(this, 'props.request.partnerServiceLocation.IsNonPartner'),
      IsUrgent: false,
      Landmark: userLocation.Landmark || userLocation.landmark,
      UserLandmark: _.get(userLocation, 'Uneditable', false) ? undefined : userLocation.UserLandmark || undefined,
      Lat: (_.get(this, 'props.request.userLocation.latitude') || _.get(this, 'props.request.userLocation.Lat', '')).toString(),
      Lng: (_.get(this, 'props.request.userLocation.longitude') || _.get(this, 'props.request.userLocation.Lng', '')).toString(),
      ScheduledDateTime: (dateAndTime && dateAndTime.date.date) || scheduledDateTime,
      ScheduledFromTime: (dateAndTime && dateAndTime.time.StartTimeVal) || scheduledFromTime,
      ScheduledToTime: (dateAndTime && dateAndTime.time.EndTimeVal) || scheduledToTime,
      Zipcode: userLocation.pincode || userLocation.PinCode || userLocation.Zipcode,
      State: userLocation.state || userLocation.State,
      StateID: userLocation.StateID,
      StateCode: userLocation.stateCode,
      District: userLocation.District,
      City: userLocation.city || userLocation.City,
      ConsumerFavouriteLocationID: userLocation && userLocation.ConsumerFavouriteLocationID,
      PartnerID:
        PartnerID !== null
          ? PartnerID
          : _.get(this, 'props.request.partnerServiceLocation.PartnerID')
          ? this.props.request.partnerServiceLocation.PartnerID
          : this.props.request.requestModesData.PartnerID,
      PartnerServiceLocationID:
        PartnerServiceLocationID !== null
          ? PartnerServiceLocationID
          : _.get(this, 'props.request.partnerServiceLocation.PartnerServiceLocationID')
          ? this.props.request.partnerServiceLocation.PartnerServiceLocationID
          : this.props.request.requestModesData.PartnerServiceLocationID,
      ConsumerServiceRequestID: 0,
      Documents: [],
      Issues: _.get(this, 'props.request.selectedIssues', []).length ? _.get(this, 'props.request.selectedIssues', undefined) : undefined,
      ServiceTypeID: selectedPlanInfo
        ? claimRequestIDMapping[serviceTypeID]
          ? claimRequestIDMapping[serviceTypeID]
          : serviceTypeID
        : serviceTypeID,
      deliveryAddress: _.includes([1, 11, 31, 57, 58, 43], serviceTypeID)
        ? {
            CountryID: this.props.user.selectedCountryData.CountryID,
            ...this.props.request.deliveryLocation,
            Landmark: _.get(this, 'props.request.deliveryLocation.Landmark') || _.get(this, 'props.request.deliveryLocation.landmark'),
            UserLandmark: _.get(this, 'props.request.deliveryLocation.Uneditable', false)
              ? undefined
              : _.get(this, 'props.request.deliveryLocation.UserLandmark', undefined),
            Lat: (_.get(this, 'props.request.deliveryLocation.latitude') || _.get(this, 'props.request.deliveryLocation.Lat')).toString(),
            Lng: (_.get(this, 'props.request.deliveryLocation.longitude') || _.get(this, 'props.request.deliveryLocation.Lng')).toString(),
            Zipcode:
              _.get(this, 'props.request.deliveryLocation.pincode') ||
              _.get(this, 'props.request.deliveryLocation.PinCode') ||
              _.get(this, 'props.request.deliveryLocation.Zipcode'),
            City:
              _.get(this, 'props.request.deliveryLocation.city', undefined) ||
              _.get(this, 'props.request.deliveryLocation.City', undefined),
            State:
              _.get(this, 'props.request.deliveryLocation.state', undefined) ||
              _.get(this, 'props.request.deliveryLocation.State', undefined),
            Address: _.get(this, 'props.request.deliveryLocation.Address', ''),
            StateID: _.get(this, 'props.request.deliveryLocation.StateID', undefined),
            District: _.get(this, 'props.request.deliveryLocation.District', undefined),
            ConsumerFavouriteLocationID:
              _.get(this, 'props.request.deliveryLocation.ConsumerFavouriteLocationID', undefined) ||
              _.get(this, 'props.request.userLocation.ConsumerFavouriteLocationID', undefined),
          }
        : _.includes([17, 23], serviceTypeID)
        ? {
            CountryID: this.props.user.selectedCountryData.CountryID,
            ...this.props.request.deliveryLocation,
            Landmark: _.get(this, 'props.request.userLocation.Landmark') || _.get(this, 'props.request.userLocation.landmark'),
            UserLandmark: _.get(this, 'props.request.userLocation.Uneditable', false)
              ? undefined
              : _.get(this, 'props.request.userLocation.UserLandmark', undefined),
            Lat: (_.get(this, 'props.request.userLocation.latitude') || _.get(this, 'props.request.userLocation.Lat')).toString(),
            Lng: (_.get(this, 'props.request.userLocation.longitude') || _.get(this, 'props.request.userLocation.Lng')).toString(),
            Zipcode:
              _.get(this, 'props.request.userLocation.pincode') ||
              _.get(this, 'props.request.userLocation.PinCode') ||
              _.get(this, 'props.request.userLocation.Zipcode'),
            City: _.get(this, 'props.request.userLocation.city', undefined) || _.get(this, 'props.request.userLocation.City', undefined),
            State: _.get(this, 'props.request.userLocation.state', undefined) || _.get(this, 'props.request.userLocation.State', undefined),
            Address: _.get(this, 'props.request.userLocation.Address', ''),
            StateID: _.get(this, 'props.request.userLocation.StateID', undefined),
            District: _.get(this, 'props.request.userLocation.District', undefined),
            ConsumerFavouriteLocationID: _.get(this, 'props.request.userLocation.ConsumerFavouriteLocationID', undefined),
          }
        : undefined,
      DropOffCenter: isAnDropoffFlow ? this.props.request.partnerServiceLocation : undefined,
      SoldPlanCoverageID: selectedPlanInfo && selectedPlanInfo.SoldPlanCoverageID,
      PlanCoverageID: selectedPlanInfo && selectedPlanInfo.PlanCoverageID,
      ClientServiceLocationID: _.get(this, 'props.request.partnerServiceLocation.ClientServiceLocationID', undefined),
      CustomerCurrentLat: _.get(this, 'props.user.userDetectLocation.CustomerCurrentLat', undefined),
      CustomerCurrentLng: _.get(this, 'props.user.userDetectLocation.CustomerCurrentLng', undefined),
    }
    if (_.get(this, 'props.request.selectedMode.ServiceTypeID') === 43) {
      dataObject = {
        ...dataObject,
        ..._.get(this, 'props.request.requestModesData.doorToDoorDetail', {}),
        IsClaimRequest: false,
      }
    }
    if ([1, 17, 23, 52].includes(serviceTypeID)) {
      // add delivery mode for the modes (pickup, dropoff, self courier)
      dataObject['DeliveryMode'] =
        DeliveryMode ||
        (_.get(this, 'props.request.requestModesData.logisticPartner.isChooseLogisticsPartner')
          ? this.props.request.selectedVendor.VendorID
          : this.props.request.requestModesData.DeliveryMode)
    }

    if ([46].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))) {
      let selectedDeviceList = this.getselectedDevicesList()
      dataObject['docs'] = !_.isEmpty(this, 'props.user.s3Uploads')
        ? [
            {
              ConsumerProductID: selectedDeviceList[0].ConsumerProductID,
              FilePath: _.get(this, 'props.user.s3Uploads.fileUrl'),
              FileName: _.get(this, 'props.user.s3Uploads.FileName'),
              Type: _.get(this, 'props.user.s3Uploads.Type'),
            },
          ]
        : undefined

      dataObject['PartnerServiceLocationID'] =
        _.get(this, 'props.request.requestModesData.replacementDetails[0].PartnerServiceLocationID') ||
        _.get(this, 'props.request.requestModesData.replacementDetails.PartnerServiceLocationID')
      dataObject['PartnerID'] =
        _.get(this, 'props.request.requestModesData.replacementDetails[0].PartnerID') ||
        _.get(this, 'props.request.requestModesData.replacementDetails.PartnerID')
    } else if ([63].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))) {
      let selectedDeviceList = this.getselectedDevicesList()
      dataObject['docs'] = !_.isEmpty(this, 'props.user.docServiceLargeFileDetails')
        ? [
            {
              ConsumerProductID: selectedDeviceList[0].ConsumerProductID,
              FilePath: _.get(this, 'props.user.docServiceLargeFileDetails.uploadURL'),
              FileName: _.get(this, 'props.user.docServiceLargeFileDetails.fileName'),
              Type: _.get(this, 'props.user.docServiceLargeFileDetails.Type'),
            },
          ]
        : undefined

      !_.isEmpty(this, 'props.request.audioUploadInfo.imageList') &&
        dataObject['docs'].push({
          ConsumerProductID: selectedDeviceList[0].ConsumerProductID,
          FilePath: _.get(this, 'props.request.audioUploadInfo.imageList[0].imageUploadPath'),
          FileName: _.get(this, 'props.request.audioUploadInfo.imageList[0].docServiceUUID'),
          Type: _.get(this, 'props.user.s3Uploads.Type', 'image/png'),
        })

      dataObject['PartnerServiceLocationID'] = _.get(this, 'props.request.requestModesData.buyoutDetails.PartnerServiceLocationID')
      dataObject['PartnerID'] = _.get(this, 'props.request.requestModesData.buyoutDetails.PartnerID')

      dataObject['Attributes'] = [
        {
          AttrName: 'CONSUMER_PAYOUT_MODE',
          AttrValue: _.get(this, 'props.request.buyoutModesDetails[0].PaymentMode'),
        },
        {
          AttrName: 'CONSUMER_LEGAL_NAME',
          AttrValue: _.get(this, 'props.request.userBuyoutDetails.UserName'),
        },
        {
          AttrName: 'CONSUMER_LEGAL_EMAIL_ID',
          AttrValue: _.get(this, 'props.request.userBuyoutDetails.EmailID'),
        },
        {
          AttrName: 'CONSUMER_LEGAL_CONTACT_NO',
          AttrValue: _.get(this, 'props.request.userBuyoutDetails.UserContactNumber'),
        },
      ]
    } else {
      let selectedDeviceList = this.getselectedDevicesList()
      dataObject['docs'] = !_.isEmpty(this, 'props.request.audioUploadInfo.imageList')
        ? [
            {
              ConsumerProductID: selectedDeviceList[0].ConsumerProductID,
              FilePath: _.get(this, 'props.request.audioUploadInfo.imageList[0].imageUploadPath'),
              FileName: _.get(this, 'props.request.audioUploadInfo.imageList[0].docServiceUUID'),
              Type: _.get(this, 'props.user.s3Uploads.Type', 'image/png'),
            },
          ]
        : undefined
    }

    if (
      [17, 23].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID'))) &&
      _.get(this, 'props.request.emptyBoxServiceable.isEmptyBoxServiceable', false)
    ) {
      dataObject['EmptyBoxLocationID'] = _.get(this, 'props.request.emptyBoxServiceable.PartnerServiceLocationID')
    }
    if ([65, 46, 66].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))) {
      dataObject['DefectivePartnerServiceLocationID'] = _.get(this, 'props.request.advanceExchangeDetails.PartnerServiceLocationID')
      dataObject['DeviceOptions'] = _.map(_.get(this, 'props.request.replacementMatrixDetails', undefined), function (row) {
        return _.omit(row, ['ServiceLocations'])
      })
      dataObject['SelectedReplacementOption'] = _.get(this, 'props.request.selectedReplacementOption.Option', undefined)
    }

    if ([64].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))) {
      dataObject['PartnerServiceLocationID'] = _.get(
        this,
        'props.request.requestModesData.OnsiteDetails.CLAIM_ONSITE.PartnerServiceLocationID'
      )
      dataObject['PartnerID'] = _.get(this, 'props.request.requestModesData.OnsiteDetails.CLAIM_ONSITE.PartnerID')
    }

    dataObject = this.deviceInfoBasedonReqType(dataObject, true)
    let reqResponse
    if (
      (selectedPlanInfo && selectedPlanInfo.SkipClaimApproval) ||
      isAnDropoffFlow ||
      [11, 12, 46, 63].includes(dataObject['ServiceTypeID'])
    ) {
      /*
      11: claim pick up
      17: repair drop off
      23: claim drop off
      57: refund drop off
      58: replacement drop off
      plan with auto approval flow
      */
      this.setState({ globalLoaderFlg: true })
      /********************** createRequest API calling - Logger API /**********************/
      if (window.useLogger) {
        let loggerObj = {
          apiName: '/ConsumerServicerequest/createRequest',
          value: {
            userLocation: _.get(this, 'props.request.userLocation'),
            Lat: (_.get(this, 'props.request.userLocation.latitude') || _.get(this, 'props.request.userLocation.Lat', '')).toString(),
            Lng: (_.get(this, 'props.request.userLocation.longitude') || _.get(this, 'props.request.userLocation.Lng', '')).toString(),
            PartnerID: dataObject.PartnerID || '',
            PartnerServiceLocationID: dataObject.PartnerServiceLocationID || '',
            ScheduledDateTime: dataObject.ScheduledFromTime || '',
            ScheduledFromTime: dataObject.ScheduledFromTime || '',
            ScheduledToTime: dataObject.ScheduledToTime || '',
            SoldPlanID: dataObject.SoldPlanID || '',
            PaymentProcessID: dataObject.PaymentProcessID || '',
            ServiceTypeID: dataObject.ServiceTypeID || '',
          },
          event: 'createRequest-api-calling',
          identifiers: {
            ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
          },
        }
        this.props.loggerActionAsync(loggerObj)
      }
      /********************** createRequest API calling - Logger API /**********************/
      reqResponse = this.props.createRequestAsync(dataObject)
    } else {
      // normal request
      switch (parseInt(serviceTypeID)) {
        case 2:
          dataObject['ServiceRequestType'] = 'Now'
          dataObject['IsNonPartner'] = 0
          reqResponse = this.props.carryInRequestAsync(dataObject)
          break
        case 3: // Onsite repair
        case 5: // Install
        case 6: // Demo
        case 7: // Maintenance
          reqResponse = this.props.nonRepairRequestAsync(dataObject)
          break
        case 43: // door to door replacement
          reqResponse = this.props.doortoDoorReplacementAsync(dataObject)
          break
        default:
          // 1: pickup
          // 52: self courier
          reqResponse = this.props.pickUpRequestAsync(dataObject)
      }
    }
    let selectedPlan = this.getSelectedPlan()
    if (selectedPlan && selectedPlan.SkipClaimApproval) {
      // ------------- Book Repair - Claim Raised Initiated --------/
      const PlanName_ = selectedPlan.PlanDisplayName
      const SoldPlanID_ = selectedPlan.SoldPlanID
      const DeviceName_ = _.get(this, 'props.request.selectedDeviceInfo.Product.ProductName')
      const BrandName_ = _.get(this, 'props.request.selectedDeviceInfo.Brand.BrandName')
      const ProductUniqueID_ = _.get(this, 'props.request.selectedDeviceInfo.ProductUniqueID')
      const clevertapUserProfile = _.get(this, 'props.user', {})

      pushToAnalytics(
        'Book Repair - Claim Raised Initiated',
        {
          Brand: BrandName_,
          'Plan Name': PlanName_,
          'Plan Sold ID': SoldPlanID_,
          'Device Name': DeviceName_,
          'Device IMEI': ProductUniqueID_,
          ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', undefined),
          'Claim Type': 'Skip Claim Approval',
          Source: 'Web',
        },
        clevertapUserProfile
      )
      // ------------- CleverTap | Book Repair - Claim Raised Initiated --------/
    }

    reqResponse
      .then((res) => {
        let { emailID, alternateMobileNo } = this.props.user.userDetails
        if (this.state.emailId !== (emailID || '') || this.state.alternateMobile !== (alternateMobileNo || '')) {
          this.updateUserDetails()
        }
        if ([63].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))) {
          this.props.setDSFileUploadDetails({})
        }
        this.props.unsetSignedUrl({})
        this.props.getNotificationsAsync({
          ConsumerID,
          AuthID,
        })
        // ------------- CleverTap | Book Repair - Claim Raised --------/
        let claimFormDetails = _.get(this, 'props.request.claimFormDetails', {})
        if (selectedPlan && selectedPlan.SkipClaimApproval && Object.keys(claimFormDetails).length) {
          const PlanName_ = selectedPlan.PlanDisplayName
          const SoldPlanID_ = selectedPlan.SoldPlanID
          const DeviceName_ = _.get(this, 'props.request.selectedDeviceInfo.Product.ProductName')
          const BrandName_ = _.get(this, 'props.request.selectedDeviceInfo.Brand.BrandName')
          const ProductUniqueID_ = _.get(this, 'props.request.selectedDeviceInfo.ProductUniqueID')
          const { deviceDamageDate, deviceDamagePlace, deviceDamageDescp } = claimFormDetails
          let SelectedDamageObj = claimFormDetails.typesOfDamage.filter((obj) => obj.isSelected)[0]
          const clevertapUserProfile = _.get(this, 'props.user', {})

          pushToAnalytics(
            'Book Repair - Claim Raised',
            {
              Brand: BrandName_,
              'Plan Name': PlanName_,
              'Plan Sold ID': SoldPlanID_,
              'Device Name': DeviceName_,
              'Device IMEI': ProductUniqueID_,
              ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', undefined),
              'Date of Damage': (deviceDamageDate && moment(deviceDamageDate).format('DD MMM YYYY')) || undefined,
              'Place of Damage': deviceDamagePlace,
              'Type of Damage': _.get(SelectedDamageObj, 'text', undefined),
              'Description of Damage': deviceDamageDescp,
              'Service Request no.': _.get(res, 'data.ConsumerServiceRequestID', undefined),
              'Claim Intimation No.': _.get(res, 'data.ReferenceID', undefined),
              'Claim Type': 'Skip Claim Approval',
              Source: 'Web',
            },
            clevertapUserProfile
          )
        }
        // ------------- CleverTap | Book Repair - Claim Raised --------/
        this.props.unsetChargePaymentId()
        this.redirectToTrack()
        this.setState({ globalLoaderFlg: false })
      })
      .catch(() => {
        /** If createRequest gives an error then payAndConfirmFlow should be set to false otherwise
        in componentWillUnMount it will not clear Request State  */
        this.setState({ payAndConfirmFlow: false, globalLoaderFlg: false })
      })
      .finally(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  claimRepairReqFlow = () => {
    const serviceTypeID = this.props.request.selectedMode.ServiceTypeID

    const dateAndTime = _.get(this, 'props.request.dateAndTime')
    const selectedPlan = this.getSelectedPlan()
    const isSelfCourier = [52].includes(parseInt(serviceTypeID)) || false
    const AuthID = this.props.user.consumerDetails.data.accessToken.ID
    const ConsumerID = this.props.user.userDetails.consumerID
    const isAnDropoffFlow = _.includes([17, 23, 57, 58], serviceTypeID)
    let selectedDeviceList = this.getselectedDevicesList()
    let deiliveryStoreAddress = _.get(this, 'props.request.partnerServiceLocation.address', undefined)
    const clientServiceLocationID = _.get(this, 'props.request.partnerServiceLocation.ClientServiceLocationID', undefined)
    const consumerServiceRequestID = _.get(this, 'props.track.requestDetails.ConsumerServiceRequestID')

    const isDropoffSlotsData = _.includes([17, 23], serviceTypeID)
    let scheduledDateTime = undefined
    let scheduledFromTime = undefined
    let scheduledToTime = undefined

    /********************** to call schedule claimRepair Logger API /**********************/
    if (window.useLogger) {
      let loggerObject = {
        apiName: 'schedule repair method calling',
        value: {
          userLocation: _.get(this, 'props.request.userLocation'),
          pathname: _.get(this, 'props.location.pathname', ''),
          pathCSR_Id: _.get(this, 'props.location.query.csrid', ''),
          ConsumerServiceRequestID: consumerServiceRequestID,
          ServiceTypeID: _.get(this, 'props.request.selectedMode.ServiceTypeID'),
        },
        event: 'schedule-repair-method-calling',
        identifiers: {
          ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
        },
      }
      this.props.loggerActionAsync(loggerObject)
    }
    /********************** to call schedule claimRepair Logger API /**********************/

    const isDropOffLocationAvailable = _.get(this, 'props.request.requestModesData.isDropOffLocationAvailable')
    if (isDropoffSlotsData) {
      if (isDropOffLocationAvailable) {
        scheduledDateTime = _.get(this, 'props.request.requestSlotsData[0].date')
        scheduledFromTime = _.get(this, 'props.request.requestSlotsData[0].slots[0].StartTimeVal')
        scheduledToTime = _.get(this, 'props.request.requestSlotsData[0].slots[0].EndTimeVal')
      } else {
        scheduledDateTime = _.get(this, 'props.request.requestModesData.dropOffVendorList[0].slots[0].date')
        scheduledFromTime = _.get(this, 'props.request.requestModesData.dropOffVendorList[0].slots[0].slots[0].StartTimeVal')
        scheduledToTime = _.get(this, 'props.request.requestModesData.dropOffVendorList[0].slots[0].slots[0].EndTimeVal')
      }
    }

    if (_.get(this, 'props.request.selectedMode.logisticsSuppressed', false)) {
      scheduledDateTime = moment().format('YYYY-MM-DD')
      scheduledFromTime = moment().format('hh:mm:ss')
      scheduledToTime = moment().format('hh:mm:ss')
    }

    if (_.includes([66], serviceTypeID)) {
      let address, City, State, Zipcode
      address = _.get(this, 'props.request.partnerServiceLocation.address', undefined)
      City = _.get(this, 'props.request.partnerServiceLocation.City', undefined)
      State = _.get(this, 'props.request.partnerServiceLocation.State', undefined)
      Zipcode = _.get(this, 'props.request.partnerServiceLocation.Zipcode', undefined)
      deiliveryStoreAddress = `${address}, ${City}, ${State}, ${Zipcode}`
    }
    let requestObj = {
      DescriptionRecording: _.get(this, 'props.request.audioUploadInfo.audioUploadPath', undefined),
      Address: [1, 11, 17, 23, 43, 46].includes(parseInt(this.props.request.selectedMode.ServiceTypeID))
        ? _.get(this, 'props.request.userLocation.Address', '')
        : undefined,
      AddressType: [1, 11, 17, 23, 43, 46].includes(parseInt(serviceTypeID)) ? this.props.request.userLocation.AddressType : undefined,
      Landmark: _.get(this, 'props.request.userLocation.landmark') || _.get(this, 'props.request.userLocation.Landmark'),
      UserLandmark: _.get(this, 'props.request.userLocation.Uneditable', false)
        ? undefined
        : _.get(this, 'props.request.userLocation.UserLandmark', undefined),
      City: _.get(this, 'props.request.userLocation.city', undefined) || _.get(this, 'props.request.userLocation.City', undefined),
      State: _.get(this, 'props.request.userLocation.state', undefined) || _.get(this, 'props.request.userLocation.State', undefined),
      StateID: _.get(this, 'props.request.userLocation.StateID'),
      District: _.get(this, 'props.request.userLocation.District'),
      Lat: (_.get(this, 'props.request.userLocation.latitude') || _.get(this, 'props.request.userLocation.Lat', '')).toString(),
      Lng: (_.get(this, 'props.request.userLocation.longitude') || _.get(this, 'props.request.userLocation.Lng', '')).toString(),
      ConsumerFavouriteLocationID: _.get(this, 'props.request.userLocation.ConsumerFavouriteLocationID', undefined),
      ScheduledDateTime: (!isSelfCourier && dateAndTime && dateAndTime.date.date) || scheduledDateTime,
      ScheduledFromTime: (!isSelfCourier && dateAndTime && dateAndTime.time.StartTimeVal) || scheduledFromTime,
      ScheduledToTime: (!isSelfCourier && dateAndTime && dateAndTime.time.EndTimeVal) || scheduledToTime,
      ConsumerServiceRequestID: consumerServiceRequestID,
      IsUrgent: false,
      ServiceTypeID: serviceTypeID,
      Zipcode:
        _.get(this, 'props.request.userLocation.pincode') ||
        _.get(this, 'props.request.userLocation.PinCode') ||
        _.get(this, 'props.request.userLocation.Zipcode'),
      ConsumerID,
      BrandID: selectedDeviceList[0].BrandID,
      ConsumerProductID: selectedDeviceList[0].ConsumerProductID,
      ProductSubCategoryID: selectedDeviceList[0].ProductSubCategoryID,
      IsUnderWarranty: selectedDeviceList[0].IsUnderWarranty,
      PartnerID: this.props.request.partnerServiceLocation
        ? this.props.request.partnerServiceLocation.PartnerID
        : this.props.request.requestModesData.PartnerID,
      PartnerServiceLocationID: this.props.request.partnerServiceLocation
        ? this.props.request.partnerServiceLocation.PartnerServiceLocationID
        : this.props.request.requestModesData.PartnerServiceLocationID,
      deliveryAddress: _.includes([1, 11, 3, 31, 17, 23, 64], serviceTypeID)
        ? {
            ...this.props.request.deliveryLocation,
            Address: _.get(this, 'props.request.deliveryLocation.Address', ''),
            Landmark: _.get(this, 'props.request.deliveryLocation.Landmark') || _.get(this, 'props.request.deliveryLocation.landmark'),
            UserLandmark: _.get(this, 'props.request.deliveryLocation.Uneditable', false)
              ? undefined
              : _.get(this, 'props.request.deliveryLocation.UserLandmark', undefined),
            Lat: (_.get(this, 'props.request.deliveryLocation.latitude') || _.get(this, 'props.request.deliveryLocation.Lat')).toString(),
            Lng: (_.get(this, 'props.request.deliveryLocation.longitude') || _.get(this, 'props.request.deliveryLocation.Lng')).toString(),
            Zipcode:
              _.get(this, 'props.request.deliveryLocation.pincode') ||
              _.get(this, 'props.request.deliveryLocation.PinCode') ||
              _.get(this, 'props.request.deliveryLocation.Zipcode'),
            City:
              _.get(this, 'props.request.deliveryLocation.city', undefined) ||
              _.get(this, 'props.request.deliveryLocation.City', undefined),
            State:
              _.get(this, 'props.request.deliveryLocation.state', undefined) ||
              _.get(this, 'props.request.deliveryLocation.State', undefined),
            StateID: _.get(this, 'props.request.deliveryLocation.StateID', undefined),
            District: _.get(this, 'props.request.deliveryLocation.District', undefined),
            ConsumerFavouriteLocationID:
              _.get(this, 'props.request.deliveryLocation.ConsumerFavouriteLocationID', undefined) ||
              _.get(this, 'props.request.userLocation.ConsumerFavouriteLocationID', undefined),
          }
        : undefined,
      DeliveryMode: 1, // hardcoded
      ServiceRequestType: 'Now', // hardcoded
      IsNonPartner: 0, // hardcoded
      DropOffCenter: isAnDropoffFlow && _.get(this, 'props.request.partnerServiceLocation'),
      SoldPlanID: selectedPlan && selectedPlan.SoldPlanID,
      PlanID: selectedPlan && selectedPlan.PlanID,
      SoldPlanCoverageID:
        (selectedPlan && selectedPlan.SoldPlanCoverageID) || _.get(this, 'props.track.trackDetails.SoldPlanCoverageID', undefined),
      PlanCoverageID: (selectedPlan && selectedPlan.PlanCoverageID) || _.get(this, 'props.track.trackDetails.PlanCoverageID', undefined),
      PaymentID: _.get(this, 'props.payment.paymentID'),
      PaymentProcessID: _.get(this, 'props.payment.payableDetails.id') || _.get(this, 'props.payment.telrPayCreateDetails.order.ref'),
      ClientServiceLocationID: _.get(this, 'props.request.partnerServiceLocation.ClientServiceLocationID', undefined),
      ClientServiceLocationDetails: clientServiceLocationID
        ? {
            ClientServiceLocationID: clientServiceLocationID,
            ServiceLocationName: _.get(this, 'props.request.partnerServiceLocation.ServiceLocationName', undefined),
            ServiceLocationAddress: _.get(this, 'props.request.partnerServiceLocation.address', undefined),
            address: deiliveryStoreAddress,
            ContactNo1: _.get(this, 'props.request.partnerServiceLocation.ContactNo1', undefined),
          }
        : undefined,
      CustomerCurrentLat: _.get(this, 'props.user.userDetectLocation.CustomerCurrentLat', undefined),
      CustomerCurrentLng: _.get(this, 'props.user.userDetectLocation.CustomerCurrentLng', undefined),
    }
    if (![17, 23, 57, 58].includes(parseInt(this.props.request.selectedMode.ServiceTypeID))) {
      const ProductUniqueID = _.get(this, 'props.track.trackDetails.ConsumerProduct.ProductUniqueID', undefined)
      const DownloadedDeviceUniqueKey = _.get(this, 'props.track.trackDetails.ConsumerProduct.DownloadedDeviceUniqueKey', undefined)
      if (selectedDeviceList[0].ProductSubCategoryID === 12) {
        if (selectedDeviceList[0].BrandID === 4) {
          // for apple devices Serial/IMEI
          requestObj['ProductUniqueID'] = selectedDeviceList[0].ProductUniqueID || ProductUniqueID
        } else {
          // check for serial or imei
          if (selectedDeviceList[0].ProductUniqueID && selectedDeviceList[0].ProductUniqueID.length) {
            requestObj['ProductUniqueID'] = selectedDeviceList[0].ProductUniqueID || ProductUniqueID
          } else {
            requestObj['ProductUniqueID'] = selectedDeviceList[0].DownloadedDeviceUniqueKey || DownloadedDeviceUniqueKey
          }
        }
      } else {
        requestObj['DownloadedDeviceUniqueKey'] = selectedDeviceList[0].DownloadedDeviceUniqueKey || DownloadedDeviceUniqueKey
        requestObj['ProductUniqueID'] = ProductUniqueID
      }
    }
    if (this.props.request.selectedMode.ServiceTypeID === 1) {
      requestObj['DeliveryMode'] = this.props.request.requestModesData.logisticPartner.isChooseLogisticsPartner
        ? this.props.request.selectedVendor.VendorID
        : // ? this.props.request.selectedVendor.VendorID
          this.props.request.requestModesData.DeliveryMode
    }
    if ([17, 23, 57, 58].includes(this.props.request.selectedMode.ServiceTypeID)) {
      requestObj['DeliveryMode'] = this.props.request.requestModesData.dropOffServicePartner
        ? this.props.request.requestModesData.dropOffServicePartner.DeliveryMode
        : this.props.request.requestModesData.DeliveryMode
    }

    if ([46].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))) {
      requestObj['docs'] = !_.isEmpty(this, 'props.user.s3Uploads')
        ? [
            {
              ConsumerProductID: _.get(this, 'props.track.trackDetails.ConsumerProduct.ConsumerProductID'),
              FilePath: _.get(this, 'props.user.s3Uploads.fileUrl'),
              FileName: _.get(this, 'props.user.s3Uploads.FileName'),
              Type: _.get(this, 'props.user.s3Uploads.Type'),
            },
          ]
        : undefined

      requestObj['PartnerServiceLocationID'] =
        _.get(this, 'props.request.requestModesData.replacementDetails[0].PartnerServiceLocationID') ||
        _.get(this, 'props.request.requestModesData.replacementDetails.PartnerServiceLocationID')
      requestObj['PartnerID'] =
        _.get(this, 'props.request.requestModesData.replacementDetails[0].PartnerID') ||
        _.get(this, 'props.request.requestModesData.replacementDetails.PartnerID')
    } else if ([63].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))) {
      requestObj['docs'] = !_.isEmpty(this, 'props.user.docServiceLargeFileDetails')
        ? [
            {
              ConsumerProductID: _.get(this, 'props.track.trackDetails.ConsumerProduct.ConsumerProductID'),
              FilePath: _.get(this, 'props.user.docServiceLargeFileDetails.uploadURL'),
              FileName: _.get(this, 'props.user.docServiceLargeFileDetails.fileName'),
              Type: _.get(this, 'props.user.docServiceLargeFileDetails.Type'),
            },
          ]
        : undefined

      requestObj['PartnerServiceLocationID'] = _.get(this, 'props.request.requestModesData.buyoutDetails.PartnerServiceLocationID')
      requestObj['PartnerID'] = _.get(this, 'props.request.requestModesData.buyoutDetails.PartnerID')

      requestObj['Attributes'] = [
        {
          AttrName: 'CONSUMER_PAYOUT_MODE',
          AttrValue: _.get(this, 'props.request.buyoutModesDetails[0].PaymentMode'),
        },
        {
          AttrName: 'CONSUMER_LEGAL_NAME',
          AttrValue: _.get(this, 'props.request.userBuyoutDetails.UserName'),
        },
        {
          AttrName: 'CONSUMER_LEGAL_EMAIL_ID',
          AttrValue: _.get(this, 'props.request.userBuyoutDetails.EmailID'),
        },
        {
          AttrName: 'CONSUMER_LEGAL_CONTACT_NO',
          AttrValue: _.get(this, 'props.request.userBuyoutDetails.UserContactNumber'),
        },
      ]
    } else {
      requestObj['docs'] = !_.isEmpty(this, 'props.user.s3Uploads')
        ? [
            {
              ConsumerProductID: selectedDeviceList[0].ConsumerProductID,
              FilePath: _.get(this, 'props.user.s3Uploads.fileUrl'),
              FileName: _.get(this, 'props.user.s3Uploads.FileName'),
              Type: _.get(this, 'props.user.s3Uploads.Type'),
            },
          ]
        : undefined
    }

    if (
      [17, 23].includes(this.props.request.selectedMode.ServiceTypeID) &&
      _.get(this, 'props.request.emptyBoxServiceable.isEmptyBoxServiceable', false)
    ) {
      requestObj['EmptyBoxLocationID'] = _.get(this, 'props.request.emptyBoxServiceable.PartnerServiceLocationID')
    }
    if ([65, 46, 66].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))) {
      requestObj['DefectivePartnerServiceLocationID'] = _.get(this, 'props.request.advanceExchangeDetails.PartnerServiceLocationID')
      requestObj['DeviceOptions'] = _.map(_.get(this, 'props.request.replacementMatrixDetails', undefined), function (row) {
        return _.omit(row, ['ServiceLocations'])
      })
      requestObj['SelectedReplacementOption'] = _.get(this, 'props.request.selectedReplacementOption.Option', undefined)
    }

    if ([65].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))) {
      requestObj['PartnerServiceLocationID'] = _.get(this, 'props.request.requestModesData.advanceExchangeDetails.PartnerServiceLocationID')
      requestObj['PartnerID'] = _.get(this, 'props.request.requestModesData.advanceExchangeDetails.PartnerID')
    }

    if ([64].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))) {
      requestObj['PartnerServiceLocationID'] = _.get(
        this,
        'props.request.requestModesData.OnsiteDetails.CLAIM_ONSITE.PartnerServiceLocationID'
      )
      requestObj['PartnerID'] = _.get(this, 'props.request.requestModesData.OnsiteDetails.CLAIM_ONSITE.PartnerID')
    }

    requestObj['HoldAmount'] = _.get(this, 'props.payment.holdAmount', undefined)

    const isReAssisngedASCFlow =
      [12, 2].includes(parseInt(serviceTypeID)) &&
      _.get(this, 'props.user.appConfigDetails.showReassignServiceCentre', false) &&
      _.get(this, 'props.track.trackDetails.isReassignServiceLocationFlow')

    this.props.setglobalLoaderStatus(true)

    if (isReAssisngedASCFlow) {
      const requestObject = {
        ConsumerServiceRequestID: requestObj.ConsumerServiceRequestID,
        ScheduledDateTime: requestObj.ScheduledDateTime,
        ScheduledFromTime: requestObj.ScheduledFromTime,
        ScheduledToTime: requestObj.ScheduledToTime,
        Address: requestObj.Address,
        Landmark: requestObj.Landmark,
        Lat: requestObj.Lat,
        Lng: requestObj.Lng,
        Zipcode: requestObj.Zipcode,
        reassignPSL: true,
        serviceLocation: _.get(this, 'props.request.partnerServiceLocation'),
      }
      this.props
        .updateServiceAvailabilityAsync(requestObject)
        .then(() => {
          this.props.setglobalLoaderStatus(false)
          this.props.getNotificationsAsync({
            ConsumerID,
            AuthID,
          })
          this.requestRaisedSuccessModel(true)
          this.setState({ globalLoaderFlg: false })
        })
        .catch(() => {
          this.setState({ globalLoaderFlg: false })
          this.props.setglobalLoaderStatus(false)
        })
    } else {
      let request // api call variable
      if (this.props.request.source && this.props.request.source.claim === true) {
        // request on a device under claim
        let claimRequestIDMapping = {
          1: 11,
          2: 12,
        }
        this.setState({ globalLoaderFlg: true })
        requestObj['ExternalTimezoneString'] = _.get(this, 'props.request.partnerServiceLocation.TimezoneString', undefined)

        requestObj['ServiceTypeID'] =
          claimRequestIDMapping[this.props.request.selectedMode.ServiceTypeID] || this.props.request.selectedMode.ServiceTypeID

        let requestAPI = [65].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
          ? this.props.createRequestAsync({ ...requestObj, skipRaiseClaim: true })
          : _.get(this, 'props.request.requestModesData.routeServiceRequestCall')
          ? this.props.routeServiceRequestAsync()
          : this.props.scheduleClaimRepairAsync(requestObj)
        request = requestAPI.then(() => {
          this.setState({ globalLoaderFlg: false })
          let { emailID, alternateMobileNo } = this.props.user.userDetails
          if (this.state.emailId !== (emailID || '') || this.state.alternateMobile !== (alternateMobileNo || '')) {
            this.updateUserDetails()
          }
        })
      } else {
        // request on a device under rejected claim
        request = this.props.scheduleNonClaimRepairAsync(requestObj)
      }
      this.props.setglobalLoaderStatus(true)
      request
        .then(() => {
          if ([63].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))) {
            this.props.setDSFileUploadDetails({})
          }
          this.props.unsetSignedUrl({})
          this.props.setglobalLoaderStatus(false)
          this.props.getNotificationsAsync({
            ConsumerID,
            AuthID,
          })
          this.redirectToTrack()
          this.setState({ globalLoaderFlg: false })
        })
        .catch(() => {
          this.setState({ globalLoaderFlg: false })
          this.props.setglobalLoaderStatus(false)
        })
    }
  }

  handleNavtoIssueSelectionTab = () => {
    let selectedPlan = this.getSelectedPlan()
    const RequiresIssueSelection = _.get(this.props, 'request.selectedDamageType.RequiresIssueSelection', false)
    const RequiresClaimFormCheck = _.get(this.props, 'request.selectedDamageType.RequiresClaimForm', false)
    const selectedIssues = _.get(this.props, 'request.selectedIssues', [])
    let skipIssueSelection =
      this.props.location.query.csrid ||
      (selectedPlan && !selectedPlan.RequiresIssueSelection) ||
      (!RequiresClaimFormCheck && RequiresIssueSelection && !!selectedIssues.length) // EW plans
    if (skipIssueSelection || ['install', 'service', 'refund', 'replacement'].includes(this.props.request.source.type)) {
      if ([1, 11, 17, 23, 31, 52, 65].includes(parseInt(this.props.request.selectedMode.ServiceTypeID))) {
        this.setState({
          BtnStatusLoader: true,
        })
        this.props
          .fetchPickupInstructionsAsync({
            AuthID: this.props.user.consumerDetails.data.accessToken.ID,
            data: {
              ProductID: this.props.request.selectedDeviceInfo.ProductID,
              BrandID: this.props.request.selectedDeviceInfo.BrandID,
              ProductSubCategoryID: this.props.request.selectedDeviceInfo.ProductSubCategoryID,
              action: 'pickupInstructions',
            },
          })
          .then(() => {
            this.setState({
              showPickUpInstructionsModal: true,
              BtnStatusLoader: false,
              pickupInstructionValues: this.props.request.pickupInstructionsList.Instructions.map((data) => {
                return {
                  ...data,
                  isSelected: false,
                }
              }),
            })
            pushToAnalytics(
              [17, 23].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
                ? 'Return_address_instruction_pop_up'
                : 'Delivery_address_instruction_pop_up',
              {
                ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
                ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
              }
            )
          })
          .catch(() => {
            this.setState({
              BtnStatusLoader: false,
            })
          })
      } else {
        this.navToConfirmationPage()
      }
    } else {
      this.setState({
        ClaimFormDocState: false,
      })
      this.props.setglobalLoaderStatus(true)
      this.props
        .getRequestIssuesForTypeAsync({
          ProductSubCategoryID: _.get(this, 'props.request.selectedDeviceInfo.ProductSubCategoryID'),
          ProductID: _.get(this, 'props.request.selectedDeviceInfo.ProductID'),
          BrandID: _.get(this, 'props.request.selectedDeviceInfo.BrandID'),
          PlanCoverageID:
            (selectedPlan && selectedPlan.PlanCoverageID) || _.get(this, 'props.track.trackDetails.PlanCoverageID', undefined),
        })
        .then(() => {
          this.props.setglobalLoaderStatus(false)
          this.setState(
            {
              issuesForType: this.props.request.requestIssuesForType.map((issue) => ({
                ...issue,
                text: issue.Issue,
                isSelected: false,
              })),
            },
            () => {
              if (this.props.request.docAlreadyAdded === undefined) {
                this.props.setInvoiceDocVisibilityState(!!(this.props.devices.productDocs && this.props.devices.productDocs.length))
              }
              if ([1, 31].includes(parseInt(this.props.request.selectedMode.ServiceTypeID))) {
                this.setState({
                  ClaimFormDocState: !!(_.get(this, 'props.devices.productDocs', []).length >= 1),
                })
              }
              browserHistory.push({
                pathname: '/request/select-issue',
                query: this.props.location.query,
              })
            }
          )
        })
        .catch(() => {
          this.props.setglobalLoaderStatus(false)
        })
    }
  }

  toggleSameDeliveryAddressCheck = () => {
    this.setState({
      sameDeliveryAddressCheck: !this.state.sameDeliveryAddressCheck,
      isEditAddress: false,
      isServiceable: !this.state.sameDeliveryAddressCheck,
      deliveryAddress: {},
      deliveryLandmark: '',
      newDeliveryAddressFlg: true,
    })
  }

  handleEditDeliveryAdd = () => {
    this.setState(
      {
        sameDeliveryAddressCheck: false,
        isEditAddress: true,
        isEditAddressFlag: true,
        deliveryLandmark: '',
        newDeliveryAddressFlg: true,
      },
      () => {
        browserHistory.push({
          pathname: '/request/slots',
          query: {
            ...this.props.location.query,
            step: 1,
          },
        })
      }
    )
  }

  saveAddresses = () => {
    this.setState({ showDisplayPreviewModal: false })
    const userCFLid = _.get(this, 'props.request.userLocation.ConsumerFavouriteLocationID', undefined)
    const deliveryCFLid = _.get(this, 'props.request.deliveryLocation.ConsumerFavouriteLocationID', undefined)
    const { newAddressFlg, sameDeliveryAddressCheck } = this.state
    let userAddressPromise
    if (userCFLid && !newAddressFlg) {
      userAddressPromise = this.updateSavedAddress('userLocation')
    } else {
      userAddressPromise = this.AddnewAddress('userLocation')
    }

    let deliveryAddressPromise
    if (deliveryCFLid) {
      if (!sameDeliveryAddressCheck) {
        deliveryAddressPromise = this.updateSavedAddress('deliveryLocation')
      }
    } else {
      if (!sameDeliveryAddressCheck) {
        deliveryAddressPromise = this.AddnewAddress('deliveryLocation')
      } else {
        deliveryAddressPromise = new Promise((resolve) => resolve())
      }
    }

    Promise.all([userAddressPromise, deliveryAddressPromise])
      .then(() => {
        if (_.get(this, 'props.request.selectedMode.logisticsSuppressed', false)) {
          this.handleNavtoIssueSelectionTab()
        } else {
          this.addressSlotsRirection()
        }
        return
      })
      .catch((err) => {
        console.error(err)
      })
  }

  handleContinue = (setStateFlg = false) => {
    if (setStateFlg) {
      this.setState({
        showDisplayPreviewModal: false,
        showPickUpInstructionsModal: false,
      })
      if (/confirm/.test(_.get(this, 'props.location.pathname', ''))) {
        this.setState({
          showClaimConsentWarningModal: true,
        })
        return
      }
    }

    if (this.state.newAddressFlg) {
      /** added this for address and addressType for newAdress */
      let usrLocation = _.get(this, 'props.request.userLocation')
      if (Object.keys(usrLocation).length > 1 && !usrLocation.Address && !usrLocation.AddressType) {
        this.props.setUserRequestLocation({
          key: 'userLocation',
          value: {
            ...this.props.request.userLocation,
            AddressType:
              this.state.addressType.filter((addrType) => addrType.isSelected).length <= 0
                ? ''
                : this.state.addressType.filter((addrType) => addrType.isSelected)[0].text,
          },
        })
        pushToAnalytics(
          [17, 23].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
            ? 'Return_address_details_continue_button_clicked'
            : 'Delivery_address_details_continue_button_clicked',
          {
            ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
            ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
            [[17, 23].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID'))) ? 'Return_Address' : 'deliveryLocation']:
              {
                ...this.props.request.userLocation,
                AddressType:
                  this.state.addressType.filter((addrType) => addrType.isSelected).length <= 0
                    ? ''
                    : this.state.addressType.filter((addrType) => addrType.isSelected)[0].text,
              },
          }
        )
      }
    }

    if (this.state.isEditAddressFlag) {
      let addressSavingPromise1
      let addressSavingPromise2
      if (this.state.isEditAddress || !this.state.sameDeliveryAddressCheck) {
        addressSavingPromise1 = (async () => {
          await this.props.setUserRequestLocation({
            key: 'deliveryLocation',
            value: {
              ...this.props.request.deliveryLocation,
              Address: this.state.deliveryAddress.Address,
              Landmark: this.state.deliveryAddress.Landmark || this.state.deliveryAddress.landmark,
              City: this.state.deliveryAddress.city,
              AddressType: this.state.deliveryAddressType.filter((addrType) => addrType.isSelected)[0].text,
              UserLandmark: _.get(this, 'state.deliveryAddress.UserLandmark', ''),
            },
          })
          pushToAnalytics(
            [17, 23].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
              ? 'Return_address_details_continue_button_clicked'
              : 'Delivery_address_details_continue_button_clicked',
            {
              ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
              ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
              [[17, 23].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
                ? 'Return_Address'
                : 'deliveryLocation']: {
                ...this.props.request.deliveryLocation,
                Address: this.state.deliveryAddress.Address,
                Landmark: this.state.deliveryAddress.Landmark || this.state.deliveryAddress.landmark,
                City: this.state.deliveryAddress.city,
                AddressType: this.state.deliveryAddressType.filter((addrType) => addrType.isSelected)[0].text,
                UserLandmark: _.get(this, 'state.deliveryAddress.UserLandmark', ''),
              },
            }
          )
          return new Promise((resolve) => resolve())
        })()
        this.setState({
          isEditAddressFlag: false,
          isSavedDeliveryAddressEdited: false,
          isEditAddress: false,
        })
      } else {
        (async () => {
          await this.props.setUserRequestLocation({
            key: 'deliveryLocation',
            value: {
              ...this.props.request.userLocation,
            },
          })
          pushToAnalytics(
            [17, 23].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
              ? 'Return_address_details_continue_button_clicked'
              : 'Delivery_address_details_continue_button_clicked',
            {
              ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
              ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
              [[17, 23].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
                ? 'Return_Address'
                : 'deliveryLocation']: {
                ...this.props.request.userLocation,
              },
            }
          )
          addressSavingPromise1 = new Promise((resolve) => resolve())
        })()
      }
      const deliveryCFLid = _.get(this, 'props.request.deliveryLocation.ConsumerFavouriteLocationID', undefined)
      if (deliveryCFLid) {
        if (this.state.isSavedDeliveryAddressEdited) {
          addressSavingPromise2 = addressSavingPromise1.then(() => {
            this.updateSavedAddress('deliveryLocation')
          })
        } else {
          addressSavingPromise2 = new Promise((resolve) => resolve())
        }
      } else {
        if (!this.state.sameDeliveryAddressCheck) {
          addressSavingPromise2 = addressSavingPromise1.then(() => {
            this.AddnewAddress('deliveryLocation')
          })
        } else {
          addressSavingPromise2 = new Promise((resolve) => resolve())
        }
      }
      Promise.all([addressSavingPromise1, addressSavingPromise2])
        .then(async () => {
          await this.addressStringBucket()
          browserHistory.push({
            pathname: '/request/confirm',
            query: {
              ...this.props.location.query,
              step: 2,
              type: this.props.location.query.type,
            },
          })
        })
        .catch((e) => {
          console.error('address saving..' + e)
        })
      if (/confirm/.test(_.get(this, 'props.location.pathname', ''))) {
        this.props.setglobalLoaderStatus(true)
      } else {
        return
      }
    }

    if (/documents/.test(this.props.location.pathname)) {
      if ([46].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))) {
        this.navToConfirmationPage()
      }
      if ([63].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))) {
        this.props.setglobalLoaderStatus(true)
        let requestObject = {
          ServiceTypeID: _.get(this, 'props.request.selectedMode.ServiceTypeID'),
        }
        this.props
          .getPayoutModesAsync(requestObject)
          .then(() => {
            browserHistory.push({
              pathname: '/request/paymentmodes',
              query: {
                ...this.props.location.query,
                step: 2,
                type: this.props.location.query.type,
              },
            })
          })
          .finally(() => {
            this.props.setglobalLoaderStatus(false)
          })
      }
    }

    if (/paymentmodes/.test(this.props.location.pathname)) {
      this.props.setglobalLoaderStatus(true)
      const isEmailLogin = _.get(this, 'props.user.appConfigDetails.EnableEmailLogin', false)
      let updateProfilePromise
      if (!isEmailLogin) {
        let UserEmailID = _.get(this, 'props.user.userDetails.emailID')
        if (UserEmailID !== this.state.buyOutFormInfo.EmailID) {
          updateProfilePromise = this.props.updateUserDetailsAsync({
            data: {
              consumerID: this.props.user.userDetails.consumerID,
              updateObj: {
                emailID: this.state.buyOutFormInfo.EmailID,
              },
            },
            AuthID: this.props.user.consumerDetails.data.accessToken.ID,
          })
        } else {
          updateProfilePromise = new Promise((resolve) => {
            resolve()
          })
        }
      } else {
        updateProfilePromise = new Promise((resolve) => {
          resolve()
        })
      }
      updateProfilePromise
        .then(() => {
          this.props.storeLastKnowPathName({
            path: '/request/paymentmodes',
          })
          this.props.setUserBuyOutDetails(this.state.buyOutFormInfo)
          this.navToConfirmationPage()
        })
        .finally(() => {
          this.props.setglobalLoaderStatus(false)
        })
    }

    if (/slots/.test(this.props.location.pathname)) {
      if ([52].includes(parseInt(this.props.request.selectedMode.ServiceTypeID))) {
        this.handleNavtoIssueSelectionTab()
      } else {
        if (this.state.slotSelectStep === 1) {
          const { sameDeliveryAddressCheck } = this.state
          let addressSetPromise = (async () => {
            if (!sameDeliveryAddressCheck) {
              await this.props.setUserRequestLocation({
                key: 'deliveryLocation',
                value: {
                  ...this.props.request.deliveryLocation,
                  State: _.get(this, 'state.deliveryAddress.state'),
                  Address: this.state.deliveryAddress.Address,
                  Landmark: _.get(this, 'state.deliveryAddress.Landmark') || _.get(this, 'state.deliveryAddress.landmark'),
                  City: _.get(this, 'state.deliveryAddress.city'),
                  AddressType: this.state.deliveryAddressType.filter((addrType) => addrType.isSelected)[0].text,
                  UserLandmark: _.get(this, 'state.deliveryAddress.UserLandmark', ''),
                },
              })
              pushToAnalytics(
                [17, 23].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
                  ? 'Return_address_details_continue_button_clicked'
                  : 'Delivery_address_details_continue_button_clicked',
                {
                  ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
                  ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
                  [[17, 23].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
                    ? 'Return_Address'
                    : 'deliveryLocation']: {
                    ...this.props.request.deliveryLocation,
                    State: _.get(this, 'state.deliveryAddress.state'),
                    Address: this.state.deliveryAddress.Address,
                    Landmark: _.get(this, 'state.deliveryAddress.Landmark') || _.get(this, 'state.deliveryAddress.landmark'),
                    City: _.get(this, 'state.deliveryAddress.city'),
                    AddressType: this.state.deliveryAddressType.filter((addrType) => addrType.isSelected)[0].text,
                    UserLandmark: _.get(this, 'state.deliveryAddress.UserLandmark', ''),
                  },
                }
              )
            } else {
              await this.props.setUserRequestLocation({
                key: 'deliveryLocation',
                value: {
                  ...this.props.request.userLocation,
                  Address: this.state.newAddress.Address,
                  State: _.get(this, 'state.newAddress.state'),
                  Landmark: _.get(this, 'state.newAddress.Landmark') || _.get(this, 'state.newAddress.landmark'),
                  City: _.get(this, 'state.newAddress.city'),
                  AddressType: this.state.addressType.filter((addrType) => addrType.isSelected)[0].text,
                  UserLandmark: _.get(this, 'state.newAddress.UserLandmark', ''),
                },
              })
              pushToAnalytics(
                [17, 23].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
                  ? 'Return_address_details_continue_button_clicked'
                  : 'Delivery_address_details_continue_button_clicked',
                {
                  ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
                  ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
                  [[17, 23].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
                    ? 'Return_Address'
                    : 'deliveryLocation']: {
                    ...this.props.request.userLocation,
                    Address: this.state.newAddress.Address,
                    State: _.get(this, 'state.newAddress.state'),
                    Landmark: _.get(this, 'state.newAddress.Landmark') || _.get(this, 'state.newAddress.landmark'),
                    City: _.get(this, 'state.newAddress.city'),
                    AddressType: this.state.addressType.filter((addrType) => addrType.isSelected)[0].text,
                    UserLandmark: _.get(this, 'state.newAddress.UserLandmark', ''),
                  },
                }
              )
            }
            await this.props.setUserRequestLocation({
              key: 'userLocation',
              value: {
                ...this.props.request.userLocation,
                Address: this.state.newAddress.Address,
                State: _.get(this, 'state.newAddress.state'),
                Landmark: _.get(this, 'state.newAddress.Landmark') || _.get(this, 'state.newAddress.landmark'),
                City: _.get(this, 'state.newAddress.city'),
                AddressType: this.state.addressType.filter((addrType) => addrType.isSelected)[0].text,
                UserLandmark: _.get(this, 'state.newAddress.UserLandmark', ''),
              },
            })
            return new Promise((resolve) => resolve())
          })()
          addressSetPromise.then(async () => {
            const shouldValidateAddress = _.get(this, 'props.user.appConfigDetails.isAddressVerficationRequired')
            let isAddressValid = !shouldValidateAddress || (await this.validateAddress())
            await this.addressStringBucket()
            if (isAddressValid == -1) {
              // blocked address
              this.setState({
                showInvalidAddressModal: true,
                showAddressSuggestion: false,
                isRecommendedAddressChosen: false,
                showDisplayPreviewModal: false,
              })
              return
            } else if (isAddressValid == 1) {
              // varified address
              this.setState({
                showInvalidAddressModal: false,
                showAddressSuggestion: false,
                isRecommendedAddressChosen: false,
              })
            } else if (isAddressValid == 0) {
              // verified with warnings
              this.setState({
                showInvalidAddressModal: false,
                showAddressSuggestion: true,
                isRecommendedAddressChosen: true,
              })
            }
            if (Object.keys(_.get(this, 'props.request.addrValidationDetails.suggestionObject', {})).length > 0) {
              this.setState({
                showAddressSuggestion: true,
                isRecommendedAddressChosen: true,
              })
            }
            this.setState({
              showDisplayPreviewModal: true,
            })
            pushToAnalytics(
              [17, 23].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
                ? 'Return_address_verification_pop_up'
                : 'Delivery_address_verification_pop_up',
              {
                ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
                ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
                [[17, 23].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
                  ? 'Return_Address'
                  : 'deliveryLocation']: _.get(this, 'props.request.deliveryLocation', ''),
              }
            )
          })
        } else {
          this.props
            .setDateAndTimeAsync({
              date: this.state.datesToShow.filter((date) => date.isSelected)[0],
              time: this.state.timesToShow.filter((time) => time.isSelected)[0],
            })
            .then(() => {
              if ([1, 11, 3, 31, 17, 23, 57, 58, 43, 64].indexOf(parseInt(this.props.location.query.mode)) >= 0) {
                if (this.props.location.query.changeMode) {
                  let ConsumerID = this.props.user.userDetails.consumerID
                  this.props
                    .updateServiceLocationAsync({
                      ConsumerID,
                      ConsumerServiceRequestID: parseInt(atob(this.props.location.query.changeMode)),
                      DropOffCenter: this.props.request.partnerServiceLocation,
                      ScheduledDateTime: this.props.request.dateAndTime.date.date,
                      ScheduledFromTime: this.props.request.dateAndTime.time.StartTimeVal,
                      ScheduledToTime: this.props.request.dateAndTime.time.EndTimeVal,
                    })
                    .then(() => {
                      this.props.setToaster('Dropoff location changed!', 'success')
                      browserHistory.replace('/track?id=' + this.props.location.query.changeMode)
                    })
                    .catch(() => {
                      browserHistory.replace('/track?id=' + this.props.location.query.changeMode)
                    })
                  return
                }
                if (this.state.newAddress.length < 1 || (!this.state.sameDeliveryAddressCheck && this.state.deliveryAddress.length < 1)) {
                  this.props.setToaster('Please enter the address!!!', 'error')
                } else if (this.state.addressType.filter((addrType) => addrType.isSelected).length <= 0) {
                  this.props.setToaster('Please select an address type!!!!', 'error')
                } else {
                  this.props.setUserRequestLocation({
                    key: 'userLocation',
                    value: {
                      ...this.props.request.userLocation,
                      Address: this.state.newAddress.Address,
                      AddressType: this.state.addressType.filter((addrType) => addrType.isSelected)[0].text,
                    },
                  })
                  if (!this.state.sameDeliveryAddressCheck) {
                    this.props.setUserRequestLocation({
                      key: 'deliveryLocation',
                      value: {
                        ...this.props.request.deliveryLocation,
                        Address: this.state.deliveryAddress.Address,
                        AddressType: this.state.deliveryAddressType.filter((addrType) => addrType.isSelected)[0].text,
                      },
                    })
                  } else {
                    this.props.setUserRequestLocation({
                      key: 'deliveryLocation',
                      value: {
                        ...this.props.request.userLocation,
                        Address: this.state.newAddress.Address,
                        AddressType: this.state.addressType.filter((addrType) => addrType.isSelected)[0].text,
                      },
                    })
                  }
                  this.handleNavtoIssueSelectionTab()
                }
              } else {
                this.handleNavtoIssueSelectionTab()
              }
            })
        }
      }
    } else if (/plan/.test(this.props.location.pathname)) {
      this.setState(
        {
          landmark: '',
          locationObtained: false,
          selectedMode: undefined,
          modeSelectStep: 1,
          newAddressFlg: false, // Users types in new address,
          claimformInfo: {
            // reset claim from details
            deviceDamageDate: '',
            deviceDamageTime: '',
            deviceDamagePlace: {},
            deviceDamageLocation: '',
            alternateMobileNo: '',
            deviceOnState: null,
            deviceDamageDescp: '',
            deviceDescpLength: 0,
            charlength: 100,
          },
        },
        () => {
          this.props.setClaimDetails({})
        }
      )
      if (this.props.request.activationPlanDetails && !this.props.location.query.step) {
        browserHistory.push({
          pathname: this.props.location.pathname,
          query: {
            ...this.props.location.query,
            step: 2,
          },
        })
        this.state.activationPageDetails.activationCode &&
          this.setState({
            // reset previous activation data
            enableActivation: false,
            activationPageDetails: {
              activationCode: '',
              activationDOP: '',
              activationInvoiceFiles: [],
              activationPurchaseCost: '',
            },
          })
      } else if (this.props.request.activationPlanDetails && this.props.location.query.step && this.props.location.query.step === '2') {
        // handle Activation
        this.props.setglobalLoaderStatus(true)
        let activationPlanDetails = this.props.request.activationPlanDetails
        let acitvationDetails = {
          code: this.state.activationPageDetails.activationCode,
          DateOfPurchase:
            (activationPlanDetails.PlanConfig.RequiresDateOfPurchase && this.state.activationPageDetails.activationDOP) || undefined,
          ProductPurchaseCost:
            (activationPlanDetails.PlanConfig.RequiresProductPurchaseCost &&
              parseInt(this.state.activationPageDetails.activationPurchaseCost)) ||
            undefined,
        }
        this.props
          .activatePlanAsync({
            ConsumerID: this.props.user.userDetails.consumerID,
            ConsumerProductID: this.props.request.selectedDeviceInfo.ConsumerProductID,
            Status: 1,
            ActivationCodes: [
              {
                ActivationCode: acitvationDetails.code,
                Group: this.props.request.activationPlanDetails.Group,
              },
            ],
            ProductPurchaseCost: acitvationDetails.ProductPurchaseCost,
            DateOfPurchase: acitvationDetails.DateOfPurchase,
          })
          .then(() => {
            this.props.setglobalLoaderStatus(false)
            this.handleClaimRedirect()
            const { PlanName } = _.get(this, 'props.store.selectedPlanDetails')
            const { ValidTillMonths } = _.get(this, 'props.store.selectedPlanDetails.PlanConfig')
            const { BrandName } = _.get(this, 'props.store.selectedPlanDetails.EntityDetails[0]')
            const { ProductObj, PlanPrice } = _.get(this, 'props.store.purchaseEligibilityInfo', {})
            const { SoldPlanID } = _.get(this, 'props.request.selectedDamageType.activationPlanDetails', undefined)
            const { emailID, mobileNo, consumerID } = _.get(this, 'props.user.userDetails', false)
            // ------------- CleverTap | Plan Sales - Plan Activated --------/
            const clevertapUserProfile = _.get(this, 'props.user', {})

            pushToAnalytics(
              'Plan Sales - Plan Activated',
              {
                Email: emailID || undefined,
                'Mobile Number': mobileNo || undefined,
                ConsumerID: consumerID || undefined,
                'Plan Sold ID': SoldPlanID,
                'Plan Name': PlanName,
                Brand: BrandName,
                'Policy Name': PlanName,
                'Plan Validity': ValidTillMonths,
                'Plan Price': PlanPrice ? PlanPrice.PlanPrice : undefined,
                'IMEI or Serial': ProductObj ? ProductObj.ProductUniqueID : undefined,
                ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', undefined),
                Source: 'Web',
              },
              clevertapUserProfile
            )
            // ------------- CleverTap | Plan Sales - Plan Activated --------/
          })
          .catch(() => {
            this.props.setglobalLoaderStatus(false)
            const { PlanName } = _.get(this, 'props.store.selectedPlanDetails')
            const { ValidTillMonths } = _.get(this, 'props.store.selectedPlanDetails.PlanConfig')
            const { BrandName } = _.get(this, 'props.store.selectedPlanDetails.EntityDetails[0]')
            const { ProductObj, PlanPrice } = _.get(this, 'props.store.purchaseEligibilityInfo', {})
            const { emailID, mobileNo, consumerID } = _.get(this, 'props.user.userDetails', false)
            // ------------- CleverTap | Plan Sales - Plan Activated Failed --------/
            const clevertapUserProfile = _.get(this, 'props.user', {})

            pushToAnalytics(
              'Plan Sales - Plan Activated Failed',
              {
                Email: emailID || undefined,
                'Mobile Number': mobileNo || undefined,
                ConsumerID: consumerID || undefined,
                'Plan Name': PlanName,
                Brand: BrandName,
                'Policy Name': PlanName,
                'Plan Validity': ValidTillMonths,
                'Plan Price': PlanPrice ? PlanPrice.PlanPrice : undefined,
                'IMEI or Serial': ProductObj ? ProductObj.ProductUniqueID : undefined,
                ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', undefined),
                Source: 'Web',
              },
              clevertapUserProfile
            )
            // ------------- CleverTap | Plan Sales - Plan Activated Failed --------/
          })
      } else {
        this.handleClaimRedirect()
      }
    } else if (/modes/.test(this.props.location.pathname) || /choose-replacement/.test(this.props.location.pathname)) {
      if (_.get(this, 'props.request.selectedMode.legalConfig', false)) {
        this.props.setClaimLegalConfigs({ LegalConfigs: this.props.request.selectedMode?.legalConfig })
      }

      const showDisClaimerModal =
        _.includes([1, 11], this.props.request.selectedMode.ServiceTypeID) &&
        _.indexOf([17], _.get(this, 'props.user.appConfigDetails.ServiceModeConfigs.Disclaimers[0]', false)) >= 0
      if (showDisClaimerModal && !this.state.isDisClaimerModalClosed) {
        this.setState({
          showDisClaimerModal: true,
        })
        return
      }
      // pick up mode logic for address
      this.props.setAdditionalUploadData({
        imageList: [],
      })

      if (
        _.get(this, 'props.request.requestModesData.logisticPartner.isDedicatedDialog') &&
        _.includes([1, 11], this.props.request.selectedMode.ServiceTypeID)
      ) {
        let vendorId = this.props.request.requestModesData.VendorList[0]
        this.setState({
          showLogisticPartners: true,
          vendorInfo: vendorId,
        })
        this.props.setLogisticPartner(vendorId)
      } else if (
        _.get(this, 'props.request.requestModesData.logisticPartner.isChooseLogisticsPartner') &&
        _.includes([1, 11], this.props.request.selectedMode.ServiceTypeID)
      ) {
        let dummyobj = this.props.request.requestModesData.VendorList
        dummyobj.sort(this.arrayofObjectsSortHandler)
        this.setState({
          showLogisticPartners: true,
          logisticPartnerList: dummyobj.map((data, index) => {
            return {
              ...data,
              isSelected: index === 0,
            }
          }),
        })
      } else if (
        _.includes([1, 11], this.props.request.selectedMode.ServiceTypeID) &&
        _.get(this, 'props.request.requestModesData.VendorList[0].VendorInstructions.Instructions', []).length
      ) {
        let vendorId = this.props.request.requestModesData.VendorList[0]
        this.setState(
          {
            selectedVendorId: vendorId,
          },
          () => {
            this.toggleLogisticsInstructions()
          }
        )
        this.props.setLogisticPartner(vendorId)
      } else if (
        _.includes([17, 23], this.props.request.selectedMode.ServiceTypeID) &&
        _.get(this, 'props.request.emptyBoxServiceable.isEmptyBoxServiceable', false)
      ) {
        this.setState({
          emptyBoxInstructionModal: true,
        })
      } else {
        this.handleModeConfirmation()
      }
      // ------------- CleverTap | Book Repair - Service Mode --------/
      const selectedPlan = this.getSelectedPlan()
      if (selectedPlan) {
        const PlanName_ = selectedPlan.PlanDisplayName
        const SoldPlanID_ = selectedPlan.SoldPlanID
        const DeviceName_ = _.get(this, 'props.request.selectedDeviceInfo.Product.ProductName')
        const BrandName_ = _.get(this, 'props.request.selectedDeviceInfo.Brand.BrandName')
        const ProductUniqueID_ = _.get(this, 'props.request.selectedDeviceInfo.ProductUniqueID')
        const ServiceModeType_ = _.get(this, 'props.request.selectedMode.Type')
        const LocationAddress_ = _.get(this, 'props.request.userLocation.landmark')
        const clevertapUserProfile = _.get(this, 'props.user', {})

        pushToAnalytics(
          'Book Repair - Service Mode',
          {
            Brand: BrandName_,
            'Plan Name': PlanName_,
            'Plan Sold ID': SoldPlanID_,
            'Device Name': DeviceName_,
            'Device IMEI': ProductUniqueID_,
            ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', undefined),
            'Service Mode Type': ServiceModeType_,
            'Location Address': LocationAddress_,
            'Claim Type': 'Skip Claim Approval',
            Source: 'Web',
          },
          clevertapUserProfile
        )
      }
      // ------------- CleverTap | Book Repair - Service Mode --------/
    } else if (/claim-form/.test(this.props.location.pathname)) {
      browserHistory.push('/track?id=' + window.btoa(this.state.generatedCSRID).toString())
    } else if (/confirm/.test(this.props.location.pathname)) {
      let isThumbEWCoverage =
        ['ThumbUS-Web'].includes(_.get(this, 'props.user.appConfigDetails.app', '')) &&
        [
          _.get(this, 'props.request.selectedDamageType.CoverageType', ''),
          _.get(this, 'props.track.trackDetails.coverageType', ''),
        ].includes('EW')
      const isReAssisngedASCFlow =
        [12, 2].includes(parseInt(this.props.request.selectedMode.ServiceTypeID)) &&
        _.get(this, 'props.user.appConfigDetails.showReassignServiceCentre', false) &&
        _.get(this, 'props.track.trackDetails.isReassignServiceLocationFlow')
      const TOKENIZE_FLOW = isReAssisngedASCFlow ? false : _.get(this, 'props.request.selectedMode.tokenizeFlow', false)

      let isAdvanceChargeApplicable
      const shouldCollectDepositAmount = _.get(this, 'props.request.selectedMode.defectiveAmount', 0) > 0
      if (_.get(this, 'props.request.selectedMode').hasOwnProperty('IsAdvanceChargeApplicable')) {
        isAdvanceChargeApplicable = _.get(this, 'props.request.selectedMode.IsAdvanceChargeApplicable')
      } else {
        isAdvanceChargeApplicable = _.get(this, 'props.request.requestModesData.IsAdvanceChargeApplicable')
      }

      if (TOKENIZE_FLOW || isAdvanceChargeApplicable || shouldCollectDepositAmount) {
        this.setState({
          payAndConfirmFlow: true,
        })
      }

      let PartnerServiceLocationID = [17, 23, 57, 58].includes(_.get(this, 'props.request.selectedMode.ServiceTypeID'))
        ? _.get(this, 'props.request.requestModesData.dropOffServicePartner.PartnerServiceLocationID')
        : null
      let PartnerID = [17, 23, 57, 58].includes(_.get(this, 'props.request.selectedMode.ServiceTypeID'))
        ? _.get(this, 'props.request.requestModesData.dropOffServicePartner.PartnerID')
        : null
      if (_.get(this, 'props.request.selectedMode.ServiceTypeID') === 43) {
        PartnerServiceLocationID = _.get(this, 'props.request.requestModesData.doorToDoorDetail.PartnerServiceLocationID')
        PartnerID = _.get(this, 'props.request.requestModesData.doorToDoorDetail.PartnerID')
      }

      if (_.get(this, 'props.request.selectedMode.ServiceTypeID') === 46) {
        PartnerServiceLocationID =
          _.get(this, 'props.request.requestModesData.replacementDetails[0].PartnerServiceLocationID') ||
          _.get(this, 'props.request.requestModesData.replacementDetails.PartnerServiceLocationID')
        PartnerID =
          _.get(this, 'props.request.requestModesData.replacementDetails[0].PartnerID') ||
          _.get(this, 'props.request.requestModesData.replacementDetails.PartnerID')
      }

      if (_.get(this, 'props.request.selectedMode.ServiceTypeID') === 65) {
        PartnerServiceLocationID = _.get(this, 'props.request.requestModesData.advanceExchangeDetails.PartnerServiceLocationID')
        PartnerID = _.get(this, 'props.request.requestModesData.advanceExchangeDetails.PartnerID')
      }

      let damageObj = _.get(this, 'props.request.claimFormDetails.typesOfDamage', []).filter((obj) => obj.isSelected)[0]
      let selectedPlan = this.getSelectedPlan()
      let advancePayReqObj = {}
      let ServiceTypeID = parseInt(this.props.request.selectedMode.ServiceTypeID)
      switch (ServiceTypeID) {
        case 1:
          ServiceTypeID = 11
          break
        case 2:
          ServiceTypeID = 12
          break
        case 17:
          ServiceTypeID = 23
          break
        case 52:
          ServiceTypeID = 52
          break
      }
      let deviceList = this.getselectedDevicesList()

      if (
        TOKENIZE_FLOW ||
        ((isAdvanceChargeApplicable || shouldCollectDepositAmount) && !_.get(this, 'props.payment.paymentRequestStatus'))
      ) {
        let { emailID, alternateMobileNo } = this.props.user.userDetails
        if (this.state.emailId !== (emailID || '') || this.state.alternateMobile !== (alternateMobileNo || '')) {
          this.updateUserDetails()
        }

        const damageId_ = _.get(this, 'props.track.requestDetails.damage[0].damageId', undefined)
        advancePayReqObj = {
          SoldPlanID: selectedPlan && selectedPlan.SoldPlanID,
          PlanID: selectedPlan && selectedPlan.PlanID,
          SoldPlanCoverageID: selectedPlan && selectedPlan.SoldPlanCoverageID,
          PlanCoverageID: selectedPlan && selectedPlan.PlanCoverageID,
          CurrencyID: this.props.user.selectedCountryData.currencies[0].CurrencyID,
          ConsumerID: this.props.user.consumerDetails.data.consumerID,
          ServiceTypeID,
          action: 'update',
          DamageTypeID: damageObj ? damageObj.id : damageId_,
          IsClaimRequest: !!(selectedPlan && selectedPlan.SoldPlanID),
          PartnerServiceLocationID:
            PartnerServiceLocationID !== null
              ? PartnerServiceLocationID
              : _.get(this, 'props.request.partnerServiceLocation.PartnerServiceLocationID')
              ? _.get(this, 'props.request.partnerServiceLocation.PartnerServiceLocationID')
              : this.props.request.requestModesData.PartnerServiceLocationID,
          PartnerID:
            PartnerID !== null
              ? PartnerID
              : _.get(this, 'props.request.partnerServiceLocation.PartnerID')
              ? _.get(this, 'props.request.partnerServiceLocation.PartnerID')
              : this.props.request.requestModesData.PartnerID,
          AuthorizeOnlyTxn: isThumbEWCoverage
            ? undefined
            : _.get(this, 'props.request.adminFeeDetails.PaymentAmount', 0) <= 0 && shouldCollectDepositAmount
            ? true
            : undefined,
        }
        if (deviceList.length === 1) {
          advancePayReqObj = {
            ...advancePayReqObj,
            ConsumerServiceRequestID: deviceList[0].ConsumerServiceRequestID,
            ConsumerProductID: deviceList[0].ConsumerProductID,
          }
        } else {
          advancePayReqObj = {
            ...advancePayReqObj,
            ProductInfo: deviceList.map((device) => ({
              ConsumerProductID: device.ConsumerProductID,
              ConsumerServiceRequestID: device.ConsumerServiceRequestID,
            })),
          }
        }
      }

      // ------------- CleverTap | Book Repair - Confirm Claim Details --------/
      let claimFormDetails = _.get(this, 'props.request.claimFormDetails', {})
      if (
        selectedPlan &&
        selectedPlan.SkipClaimApproval &&
        Object.keys(claimFormDetails).length &&
        !(this.props.payment && this.props.payment.paymentRequestStatus)
      ) {
        const PlanName_ = selectedPlan.PlanDisplayName
        const SoldPlanID_ = selectedPlan.SoldPlanID
        const DeviceName_ = _.get(this, 'props.request.selectedDeviceInfo.Product.ProductName')
        const BrandName_ = _.get(this, 'props.request.selectedDeviceInfo.Brand.BrandName')
        const ProductUniqueID_ = _.get(this, 'props.request.selectedDeviceInfo.ProductUniqueID')
        const ServiceModeType_ = _.get(this, 'props.request.selectedMode.Type')
        const LocationAddress_ = _.get(this, 'props.request.userLocation.landmark')

        const { deviceDamageDate, deviceDamagePlace, deviceDamageDescp } = claimFormDetails
        let SelectedDamageObj = claimFormDetails.typesOfDamage.filter((obj) => obj.isSelected)[0]

        const ServiceDate_ = _.get(this, 'props.request.dateAndTime.date.Date')
        const StartTime = _.get(this, 'props.request.dateAndTime.time.StartTime', undefined)
        const EndTime = _.get(this, 'props.request.dateAndTime.time.EndTime', undefined)
        const { emailID, mobileNo } = _.get(this, 'props.user.userDetails')
        const clevertapUserProfile = _.get(this, 'props.user', {})

        pushToAnalytics(
          'Book Repair - Confirm Claim Details',
          {
            Brand: BrandName_,
            'Plan Name': PlanName_,
            'Plan Sold ID': SoldPlanID_,
            'Device Name': DeviceName_,
            'Device IMEI': ProductUniqueID_,
            ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', undefined),
            'Service Mode Type': ServiceModeType_,
            'Service Date': ServiceDate_,
            'Service Timeslot': StartTime ? `${StartTime} - ${EndTime}` : undefined,
            'Mobile Number': mobileNo,
            Email: emailID,
            Address: LocationAddress_,
            'Date of Damage': moment(deviceDamageDate).format('DD MMM YYYY'),
            'Place of Damage': deviceDamagePlace,
            'Type of Damage': _.get(SelectedDamageObj, 'text', undefined),
            'Description of Damage': deviceDamageDescp,
            'Claim Type': 'Skip Claim Approval',
            Source: 'Web',
          },
          clevertapUserProfile
        )
      }

      // ------------- CleverTap | Book Repair - Confirm Claim Details --------/
      if ([63].includes(this.props.request.selectedMode.ServiceTypeID)) {
        let isNormalRepair = this.props.request.source.recreateReqFlg && !selectedPlan
        if (
          (_.get(this, 'props.location.query.csrid') || _.get(this, 'props.track.requestDetails.ConsumerServiceRequestID')) &&
          !isNormalRepair
        ) {
          this.claimRepairReqFlow()
        } else {
          try {
            this.normalRepairReqFlow()
          } catch (err) {
            console.log('err: ', err)
          }
        }
      } else {
        /********************** handleContinue_function_invocation Logger API /**********************/
        if (window.useLogger) {
          let loggerObject = {
            apiName: 'handleContinue_function_invocation',
            value: {
              pathname: _.get(this, 'props.location.pathname', ''),
              paymentRequestStatus: _.get(this, 'props.payment.paymentRequestStatus'),
            },
            event: 'handleContinue_function_invocation',
            identifiers: {
              ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
            },
          }
          this.props.loggerActionAsync(loggerObject)
        }
        /********************** handleContinue_function_invocation Logger API /**********************/
        if (
          (TOKENIZE_FLOW || isAdvanceChargeApplicable || shouldCollectDepositAmount) &&
          !_.get(this, 'props.payment.paymentRequestStatus') &&
          !isThumbEWCoverage
        ) {
          let csrID = _.get(this, 'props.track.requestDetails.ConsumerServiceRequestID')
          const appName = _.get(this, 'props.user.appConfigDetails.app', '')
          let isAirtelUser = ['Airtel-Web'].includes(appName)
          if (csrID && !isAirtelUser) {
            // csrdid is available they check for the payment (Break flow)

            // CALL - TOKENIZE_FLOW API, AND PASS CONSENT CHECKED FLOW
            // FETCHED THE API RESOPONSE AND PASS IT TO
            let promiseTokenizeFlowPromise = new Promise((resolve) => {
              resolve()
            })
            if (TOKENIZE_FLOW) {
              promiseTokenizeFlowPromise = this.props.getPaymentActionsAsync({
                planID: selectedPlan && selectedPlan.PlanID,
                countryID: this.props.user.selectedCountryData.CountryID,
                checkForTokenization: TOKENIZE_FLOW,
                consents: this.props.user.claimLegalConfigs?.LegalConfigs,
              })
            }
            promiseTokenizeFlowPromise
              .then((tokenize_res) => {
                this.props
                  .getAdvancePaymentDetails({
                    ConsumerServiceRequestID: csrID,
                    SoldPlanCoverageID: _.get(this, 'props.track.trackDetails.SoldPlanCoverageID', undefined),
                    damageId:
                      _.get(this, 'props.request.claimFormDetails.typesOfDamage', []).find((damageType) => damageType.isSelected)?.id ||
                      undefined,
                  })
                  .then((response) => {
                    this.props.setglobalLoaderStatus(false)
                    // TOKENIZE FLOW  - IS RELATED TO TOKENIZE CARD(1 DOLLAR / MINIMUM DEDUCTIBLE) || MAX DEDCTIBLE
                    if (
                      TOKENIZE_FLOW ||
                      (response.AdvanceChargeApplicable && !response.PaymentStatus && response.PaymentAmount > 0) ||
                      shouldCollectDepositAmount
                    ) {
                      this.props.setglobalLoaderStatus(true)
                      this.props
                        .advancePayAsync({ ...advancePayReqObj, ..._.pick(tokenize_res, ['tokenizeFlow', 'chargeMaxDeductible']) })
                        .then(() => {
                          this.props.setglobalLoaderStatus(false)
                          if (this.state.payAndConfirmFlow && !(this.props.payment && this.props.payment.paymentRequestStatus)) {
                            this.props.storeLastKnowPathName({
                              path: '/request/confirm',
                              params: {
                                ...this.props.location.query,
                              },
                            })
                            browserHistory.replace({
                              pathname: '/payment',
                            })
                          }
                        })
                        .catch(() => {
                          this.props.setglobalLoaderStatus(false)
                        })
                    } else {
                      let isNormalRepair = this.props.request.source.recreateReqFlg && !selectedPlan
                      if (
                        (_.get(this, 'props.location.query.csrid') || _.get(this, 'props.track.requestDetails.ConsumerServiceRequestID')) &&
                        !isNormalRepair
                      ) {
                        // with claim whether rejected or not
                        this.claimRepairReqFlow()
                      } else {
                        // multiple modes on devices with extended warranty & no claim being handled here
                        try {
                          this.normalRepairReqFlow()
                        } catch (err) {
                          console.log('err: ', err)
                        }
                      }
                    }
                  })
                  .catch(() => {
                    this.props.setglobalLoaderStatus(false)
                  })
              })
              .catch(() => {
                this.props.setglobalLoaderStatus(false)
              })
          } else if (csrID && isAirtelUser) {
            this.props.setglobalLoaderStatus(true)
            this.props
              .advancePayAsync(advancePayReqObj)
              .then(() => {
                this.props.setglobalLoaderStatus(false)
                if (this.state.payAndConfirmFlow && !(this.props.payment && this.props.payment.paymentRequestStatus)) {
                  this.props.storeLastKnowPathName({
                    path: '/request/confirm',
                    params: {
                      ...this.props.location.query,
                    },
                  })
                  browserHistory.replace({
                    pathname: '/payment',
                  })
                }
              })
              .catch(() => {
                this.props.setglobalLoaderStatus(false)
              })
          } else {
            // CALL - TOKENIZE_FLOW API, AND PASS CONSENT CHECKED FLOW
            // FETCHED THE API RESOPONSE AND PASS IT TO
            this.props.setglobalLoaderStatus(true)
            let promiseTokenizeFlowPromise
            if (TOKENIZE_FLOW) {
              promiseTokenizeFlowPromise = this.props.getPaymentActionsAsync({
                planID: selectedPlan && selectedPlan.PlanID,
                countryID: this.props.user.selectedCountryData.CountryID,
                checkForTokenization: TOKENIZE_FLOW,
                consents: this.props.user.claimLegalConfigs?.LegalConfigs,
              })
            } else {
              promiseTokenizeFlowPromise = Promise.resolve()
            }
            promiseTokenizeFlowPromise
              .then((tokenize_res) => {
                this.props
                  .getAdvancePaymentDetails({
                    SoldPlanID: selectedPlan && selectedPlan.SoldPlanID,
                    ConsumerProductID: deviceList && deviceList[0].ConsumerProductID,
                    CountryID: _.get(this, 'props.user.selectedCountryData.CountryID'),
                    SoldPlanCoverageID: selectedPlan && selectedPlan.SoldPlanCoverageID,
                    damageId:
                      _.get(this, 'props.request.claimFormDetails.typesOfDamage', []).find((damageType) => damageType.isSelected)?.id ||
                      undefined,
                  })
                  .then((response) => {
                    if (
                      TOKENIZE_FLOW ||
                      (response.AdvanceChargeApplicable && !response.PaymentStatus && response.PaymentAmount > 0) ||
                      shouldCollectDepositAmount
                    ) {
                      this.props.setglobalLoaderStatus(true)
                      // TOKENIZE FLOW  - IS RELATED TO TOKENIZE CARD(1 DOLLAR / MINIMUM DEDUCTIBLE) || MAX DEDCTIBLE
                      this.props
                        .advancePayAsync({ ...advancePayReqObj, ..._.pick(tokenize_res, ['tokenizeFlow', 'chargeMaxDeductible']) })
                        .then(() => {
                          this.props.setglobalLoaderStatus(false)
                          if (this.state.payAndConfirmFlow && !(this.props.payment && this.props.payment.paymentRequestStatus)) {
                            this.props.storeLastKnowPathName({
                              path: '/request/confirm',
                              params: {
                                ...this.props.location.query,
                              },
                            })
                            browserHistory.replace({
                              pathname: '/payment',
                            })
                          } else {
                            let isNormalRepair = this.props.request.source.recreateReqFlg && !selectedPlan
                            if (
                              (_.get(this, 'props.location.query.csrid') ||
                                _.get(this, 'props.track.requestDetails.ConsumerServiceRequestID')) &&
                              !isNormalRepair
                            ) {
                              this.claimRepairReqFlow()
                            } else {
                              try {
                                this.normalRepairReqFlow()
                              } catch (err) {
                                console.log('err: ', err)
                              }
                            }
                          }
                        })
                        .catch(() => {
                          this.props.setglobalLoaderStatus(false)
                        })
                    } else {
                      let isNormalRepair = this.props.request.source.recreateReqFlg && !selectedPlan
                      if (
                        (_.get(this, 'props.location.query.csrid') || _.get(this, 'props.track.requestDetails.ConsumerServiceRequestID')) &&
                        !isNormalRepair
                      ) {
                        this.claimRepairReqFlow()
                      } else {
                        try {
                          this.normalRepairReqFlow()
                        } catch (err) {
                          console.log('err: ', err)
                        }
                      }
                    }
                  })
                  .catch((err) => {
                    console.log(err)
                    this.props.setglobalLoaderStatus(false)
                  })
              })
              .catch(() => {
                this.props.setglobalLoaderStatus(false)
              })
          }
        } else {
          let isNormalRepair = this.props.request.source.recreateReqFlg && !selectedPlan

          /********************** to call claim/normal repair Logger API /**********************/
          if (window.useLogger) {
            let loggerObject = {
              apiName: 'claim repair method calling',
              value: {
                pathname: _.get(this, 'props.location.pathname', ''),
                pathCSR_Id: _.get(this, 'props.location.query.csrid', ''),
                ConsumerServiceRequestID: _.get(this, 'props.track.requestDetails.ConsumerServiceRequestID'),
                recreateReqFlg: _.get(this, 'props.request.source.recreateReqFlg'),
                ServiceTypeID: _.get(this, 'props.request.selectedMode.ServiceTypeID'),
                isNormalRepairFlow: isNormalRepair,
              },
              event: 'claim-repair-method-calling',
              identifiers: {
                ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
              },
            }
            this.props.loggerActionAsync(loggerObject)
          }
          /********************** to call claim/normal repair Logger API /**********************/
          if (
            (_.get(this, 'props.location.query.csrid') || _.get(this, 'props.track.requestDetails.ConsumerServiceRequestID')) &&
            !isNormalRepair
          ) {
            // with claim whether rejected or not
            this.claimRepairReqFlow()
          } else {
            // multiple modes on devices with extended warranty & no claim being handled here
            try {
              this.normalRepairReqFlow()
            } catch (err) {
              console.log('err: ', err)
            }
          }
        }
      }
    } else if (/select-issue/.test(this.props.location.pathname)) {
      let selectedIssuesList = []
      const RequiresIssueSelection = _.get(this.props, 'request.selectedDamageType.RequiresIssueSelection', false)
      this.setState(
        {
          issuesForType: this.state.issuesForType.map((issue) => {
            issue.Issue = ['other', 'others', 'other issue'].includes(issue.DisplayText.toLowerCase())
              ? this.state.otherInputText || issue.Issue
              : issue.Issue
            return {
              ...issue,
              IssueText: issue.Issue,
            }
          }),
        },
        () => {
          selectedIssuesList = this.state.issuesForType.filter((issues) => issues.isSelected)
          this.props.setIssues(selectedIssuesList)
          pushToAnalytics('Select_issue_continue_button_clicked', {
            ConsumerID: _.get(this.props, 'request.selectedDeviceInfo.ConsumerID', ''),
            ConsumerProductID: _.get(this.props, 'request.selectedDeviceInfo.ConsumerProductID', ''),
            Issues:
              (selectedIssuesList &&
                selectedIssuesList.length > 0 &&
                selectedIssuesList.map((issue) => {
                  return issue.DisplayText
                })) ||
              [],
          })
          if (RequiresIssueSelection) {
            this.checkForClaimForm(selectedIssuesList)
          }
        }
      )
      if ([1, 11, 17, 23, 31, 57, 58].indexOf(parseInt(this.props.request.selectedMode.ServiceTypeID)) >= 0) {
        this.setState({
          BtnStatusLoader: true,
        })
        this.props
          .fetchPickupInstructionsAsync({
            AuthID: this.props.user.consumerDetails.data.accessToken.ID,
            data: {
              ProductID: this.props.request.selectedDeviceInfo.ProductID,
              BrandID: this.props.request.selectedDeviceInfo.BrandID,
              ProductSubCategoryID: this.props.request.selectedDeviceInfo.ProductSubCategoryID,
              action: 'pickupInstructions',
            },
          })
          .then(() => {
            this.setState({
              showPickUpInstructionsModal: true,
              BtnStatusLoader: false,
              pickupInstructionValues: this.props.request.pickupInstructionsList.Instructions.map((data) => {
                return {
                  ...data,
                  isSelected: false,
                }
              }),
            })
          })
          .catch(() => {
            this.setState({
              BtnStatusLoader: false,
            })
          })
      } else {
        let csrId = this.props.request.raisedRequest?.ConsumerServiceRequestID
        const normalRepairAllowed = _.get(this, 'props.user.appConfigDetails.showNormalRepairOptions', false)
        if (csrId || normalRepairAllowed) {
          this.navToConfirmationPage()
        } else {
          browserHistory.push({
            pathname: '/request/modes',
            query: {
              ...this.props.location.query,
              step: 1,
            },
          })
        }
      }
    }
  }

  handleClaimRedirect = () => {
    this.setState({
      landmark: '',
      locationObtained: false,
      selectedMode: undefined,
      modeSelectStep: 1,
      newAddressFlg: false, // Users types in new address
    })
    this.props.clearRequestModes()
    this.props.setUserRequestLocation({
      key: 'userLocation',
      value: {
        landmark: '',
        pincode: '',
        latitude: '',
        longitude: '',
      },
    })
    let selectedPlan = this.getSelectedPlan()
    if (selectedPlan) {
      // ------------- CleverTap | Book Repair - Select Plan --------/
      const PlanName_ = selectedPlan.PlanDisplayName
      const ValidityDate_ = moment(selectedPlan.ValidityDate).format('DD MMM YYYY')
      const SoldPlanID_ = selectedPlan.SoldPlanID
      const DeviceName_ = _.get(this, 'props.request.selectedDeviceInfo.Product.ProductName')
      const BrandName_ = _.get(this, 'props.request.selectedDeviceInfo.Brand.BrandName')
      const ProductUniqueID_ = _.get(this, 'props.request.selectedDeviceInfo.ProductUniqueID')
      const clevertapUserProfile = _.get(this, 'props.user', {})
      pushToAnalytics(
        'Book Repair - Select Plan',
        {
          Brand: BrandName_,
          'Plan Name': PlanName_,
          'Plan Sold ID': SoldPlanID_,
          'Device Name': DeviceName_,
          'Device IMEI / Serial Nos.': ProductUniqueID_,
          ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', undefined),
          'Plan Expiry Date': ValidityDate_,
          Source: 'Web',
        },
        clevertapUserProfile
      )
      // ------------- CleverTap | Book Repair - Select Plan --------/
      this.props.setglobalLoaderStatus(true)
      const defaultLegalConfigs = this.props.user.claimLegalConfigs?.LegalConfigs?.map((config) => {
        return {
          ...config,
          Active: true,
        }
      })
      this.props.setClaimLegalConfigs({ LegalConfigs: defaultLegalConfigs })
      this.props
        .asyncClaimEligibility({
          ConsumerID: this.props.user.userDetails.consumerID,
          SoldPlanID: selectedPlan.SoldPlanID,
        })
        .then(() => {
          this.props.setglobalLoaderStatus(false)
          let { soldPlanCoverages } = _.get(this, 'props.request.ClaimEligibilityList', {})
          let activeCoverage = [] // based on coverages from now on
          if (soldPlanCoverages && soldPlanCoverages.length) {
            activeCoverage = soldPlanCoverages.filter((data) => data.CanRaiseClaim)
          }
          const IsUnderCoolingPeriod = activeCoverage && activeCoverage.length && activeCoverage[0].IsUnderCoolingPeriod
          if (IsUnderCoolingPeriod) {
            this.setState({
              showCoolingPeriodModal: true,
            })
          } else {
            if (activeCoverage.length) {
              soldPlanCoverages = soldPlanCoverages.map((data) => {
                if (activeCoverage.length === 1) {
                  return {
                    ...data,
                    isSelected: data.CanRaiseClaim,
                  }
                } else {
                  return data
                }
              })
              this.setState(
                {
                  soldPlanCoverages,
                },
                () => {
                  // checking lost & theft for single coverage plan
                  if (activeCoverage.length === 1) {
                    this.confirmCoverageSelection(true) // with pre-selected coverage
                  } else {
                    this.goToCoverageSelection()
                  }
                }
              )
            } else {
              const hideCoverageInstructionModal = _.get(this, 'props.user.appConfigDetails.hideCoverageInstructionModal', '')
              if (hideCoverageInstructionModal) {
                if (!_.get(this, 'props.request.ClaimEligibilityList.IsEligible', false)) {
                  this.openClaimEligibilityModal()
                } else {
                  this.openClaimInstructions()
                }
              } else {
                this.openClaimEligibilityModal()
              }
            }
          }
        })
        .catch((e) => {
          console.log(e)
          this.props.setglobalLoaderStatus(false)
        })
    } else {
      browserHistory.push({
        pathname: '/request/modes',
        query: {
          ...this.props.location.query,
          step: 1,
        },
      })
    }
  }

  resetPaymentDetails = () => {
    this.props.clearPaymentDetails()
  }

  redirectToTrack = () => {
    this.setState(
      {
        BtnStatusLoader: false,
        /** for clearing prefilled data raise claim */
        payAndConfirmFlow: false,
      },
      () => {
        this.resetPaymentDetails()
        let csrId = this.props.request.raisedRequest.ConsumerServiceRequestID
        if (_.get(this, 'state.additionalUpload.imageList', []).length) {
          let uploadInfo = []
          if (_.get(this, 'state.additionalUpload.imageList', []).length) {
            this.state.additionalUpload.imageList.map((info) => {
              uploadInfo.push({
                ConsumerServiceRequestID: csrId,
                Type: 'Image',
                FilePath: info.imageUploadPath || info.imagePath,
                ...(window.useDocService && { FileName: info.docServiceUUID }),
              })
            })
          }
          let uploadAdditionalDocsPromise
          if (window.useDocService) {
            uploadAdditionalDocsPromise = this.props.uploadAdditionalDocsToDSAsync({ uploadInfo })
          } else {
            uploadAdditionalDocsPromise = this.props.uploadAdditionalDocsAsync({ uploadInfo })
          }
          uploadAdditionalDocsPromise.then(() => {
            this.requestRaisedSuccessModel(csrId)
          })
        } else {
          this.requestRaisedSuccessModel(csrId)
        }
      }
    )
  }

  requestRaisedSuccessModel = () => {
    let text
    let ProductName = _.get(this, 'props.request.selectedDeviceInfo.Product.ProductName')
    let ProductSubCategory = _.get(this, 'props.request.selectedDeviceInfo.ProductSubCategory.ProductSubCategory')
    switch (_.get(this, 'props.request.selectedMode.ServiceTypeID')) {
      case 46:
        this.setState({ claimRaiseSuccessMessage: getTranslationText('request', 'replacementRequestSuccess') })
        break

      case 63:
        text = `${getTranslationText('request', 'reimbursementRequestSuccess')} - ${_.get(
          this,
          'props.request.userBuyoutDetails.EmailID'
        )}.`
        this.setState({ claimRaiseSuccessMessage: text })
        break

      case 23 || 11:
        this.setState({ claimRaiseSuccessMessage: getTranslationText('request', 'claimRequestSuccess') })
        break

      case 12:
        this.setState({ claimRaiseSuccessMessage: getTranslationText('request', 'carryInRequestSuccess') })
        break

      default:
        if (localStorage.getItem('languageCode') === 'AR') {
          text = `لقد تلقينا طلب المطالبة الخاص بك عن
          ${ProductName} (${ProductSubCategory}) ،
          وسنتصل بك في حالة الحاجة إلى أي معلومات إضافية `
        } else if (_.get(this, 'props.request.retryHoldPaymentStatus', undefined)) {
          text = getTranslationText('request', 'holdPaymentClaimRequest')
        } else if ([65].includes(_.get(this, 'props.request.selectedMode.ServiceTypeID'))) {
          text = `${getTranslationText('request', 'haveExchangeReceived')} 
          ${ProductName} (${ProductSubCategory}), 
          ${getTranslationText('request', 'additionalInformation')}`
        } else {
          text = `${getTranslationText('request', 'haveReceivedDevice')} 
          ${ProductName} (${ProductSubCategory}), 
          ${getTranslationText('request', 'additionalInformation')}`
        }
        this.setState({ claimRaiseSuccessMessage: text })
        break
    }
    this.setState({ showRepairRaisedSuccessModal: true })
    pushToAnalytics('Claim_raised_success_popup', {
      ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
      ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
    })
  }

  validateAddress = async (type = 'userLocation') => {
    try {
      this.props.setglobalLoaderStatus(true)
      await this.props.clearAddressValidationDetails()
      const {
        pincode,
        Address,
        City,
        city,
        stateCode,
        state,
        UserLandmark,
        District,
        StateMeta = {},
        PinCode,
      } = type === 'userLocation' ? this.props.request.userLocation : this.props.request.deliveryLocation

      const ConsumerID = _.get(this, 'props.user.userDetails.consumerID')
      const addressObject = {
        Address: Address || '',
        Zipcode: pincode || PinCode,
        City: City || city || '',
        State: StateMeta?.State || state || stateCode,
        District: District || undefined,
        UserLandmark: UserLandmark || undefined,
      }
      const response = await this.props.validateAddressAsync({
        ConsumerID,
        AddressObject: addressObject,
        CountryID: _.get(this, 'props.user.selectedCountryData.CountryID'),
      })
      this.props.setglobalLoaderStatus(false)
      return _.get(response, 'verificationStatus', 1)
    } catch (err) {
      console.log(err)
      this.props.setglobalLoaderStatus(false)
      return false
    }
  }

  AddnewAddress = async (type = 'userLocation') => {
    // add address wont be called for drop off modes (17, 57, 58)
    if ([1, 11, 3, 31, 5, 6, 7, 23, 43, 46, 63, 65, 64].includes(this.props.request.selectedMode.ServiceTypeID)) {
      let AuthID = this.props.user.consumerDetails.data.accessToken.ID
      let ConsumerID = this.props.user.userDetails.consumerID
      /** added address and addressType */
      const {
        landmark,
        latitude,
        Lat,
        Lng,
        longitude,
        pincode,
        Zipcode,
        PinCode,
        Address,
        AddressType,
        City,
        city,
        State,
        state,
        StateID,
        Landmark,
        UserLandmark,
        District,
      } =
        type === 'userLocation'
          ? {
              ...this.props.request.userLocation,
              ...(this.state.isRecommendedAddressChosen ? _.get(this, 'props.request.addrValidationDetails.suggestionObject', {}) : {}),
            }
          : this.props.request.deliveryLocation

      let addrVerificationDetails = {}

      let isSmartyVerificationSource = [1].includes(_.get(this, 'props.request.addrValidationDetails.verificationStatus', ''))
      if (
        isSmartyVerificationSource ||
        (this.state.isRecommendedAddressChosen && Object.keys(_.get(this, 'props.request.addrValidationDetails', {})).length > 0)
      ) {
        addrVerificationDetails.verificationSource = _.get(this, 'props.request.addrValidationDetails.verificationSource')
      }
      /********************** before add address Logger API /**********************/
      if (window.useLogger) {
        let loggerObject = {
          apiName: 'repair-add-address-api-call-payload',
          value: {
            userLocation: _.get(this, 'props.request.userLocation'),
            Landmark: Landmark || landmark,
            Lat: latitude || Lat,
            Lng: longitude || Lng,
            Zipcode: pincode || Zipcode || PinCode,
            City: City || city || '',
            State: State || state,
            smartyAddrVerificationDetails: addrVerificationDetails,
            smartyMetaObject: _.get(this, 'props.request.addrValidationDetails.metaObject'),
            smartySuggestionObject: _.get(this, 'props.request.addrValidationDetails.suggestionObject', {}),
            ConsumerServiceRequestID: _.get(this, 'props.track.requestDetails.ConsumerServiceRequestID', undefined),
          },
          event: 'initial-page-load',
          identifiers: {
            ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
          },
        }
        this.props.loggerActionAsync(loggerObject)
      }
      /********************** initial-page-load Logger API /**********************/
      await this.props
        .addAddressAsync({
          AuthID,
          data: {
            Address,
            AddressType,
            ConsumerID,
            Landmark: Landmark || landmark,
            Lat: latitude || Lat,
            Lng: longitude || Lng,
            Zipcode: pincode || Zipcode || PinCode,
            City: City || city || '',
            State: State || state,
            StateID: StateID || undefined,
            District: District || undefined,
            UserLandmark: UserLandmark || undefined,
            metaObject: _.get(this, 'props.request.addrValidationDetails.metaObject'),
            ...addrVerificationDetails,
          },
        })
        .then((response) => {
          /********************** API response add address Logger API /**********************/
          if (window.useLogger) {
            let loggerObject = {
              apiName: 'repair-add-address-api-response',
              value: {
                userLocation: _.get(this, 'props.request.userLocation'),
                addAddressApiRepsone: response,
                ConsumerServiceRequestID: _.get(this, 'props.track.requestDetails.ConsumerServiceRequestID', undefined),
              },
              event: 'initial-page-load',
              identifiers: {
                ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
              },
            }
            this.props.loggerActionAsync(loggerObject)
          }
          /********************** API response add address Logger API /**********************/

          this.props.clearAddressValidationDetails()
          this.setState(
            {
              newAddressFlg: false,
              isSavedUserAddressEdited: false,
              isSavedDeliveryAddressEdited: false,
              isRecommendedAddressChosen: false,
              showAddressSuggestion: false,
            },
            async () => {
              const res = await this.props.getUserAddressesAsync({
                ConsumerID,
                AuthID,
              })
              this.props.setUserRequestLocation({
                key: type,
                value: {
                  ...response.data,
                  StateMeta: res?.find((cfl) => cfl.ConsumerFavouriteLocationID === response.data.ConsumerFavouriteLocationID)?.StateMeta,
                },
              })
            }
          )
        })
    }
  }

  updateSavedAddress = async (type = 'userLocation') => {
    // add address wont be called for drop off modes (17, 57, 58)
    if ([1, 11, 3, 31, 5, 6, 7, 23, 43, 46, 63, 65, 64].includes(this.props.request.selectedMode.ServiceTypeID)) {
      let AuthID = this.props.user.consumerDetails.data.accessToken.ID
      let ConsumerID = this.props.user.userDetails.consumerID
      /** added address and addressType */
      const {
        latitude,
        longitude,
        pincode,
        Address,
        AddressType,
        City,
        city,
        state,
        State,
        StateID,
        ConsumerFavouriteLocationID,
        Landmark,
        UserLandmark,
        District,
      } =
        type === 'userLocation'
          ? {
              ...this.props.request.userLocation,
              ...(this.state.isRecommendedAddressChosen ? _.get(this, 'props.request.addrValidationDetails.suggestionObject', {}) : {}),
            }
          : this.props.request.deliveryLocation
      this.props.setglobalLoaderStatus(true)

      let addrVerificationDetails = {}
      let isSmartyVerificationSource = [1].includes(_.get(this, 'props.request.addrValidationDetails.verificationStatus', ''))
      if (
        isSmartyVerificationSource ||
        (this.state.isRecommendedAddressChosen && Object.keys(_.get(this, 'props.request.addrValidationDetails', {})).length > 0)
      ) {
        addrVerificationDetails.verificationSource = _.get(this, 'props.request.addrValidationDetails.verificationSource')
      }

      /********************** before update address Logger API /**********************/
      if (window.useLogger) {
        let loggerObject = {
          apiName: 'repair-update-address-api-calling-payload',
          value: {
            userLocation: _.get(this, 'props.request.userLocation'),
            Lat: latitude,
            Lng: longitude,
            Zipcode: pincode,
            City: City || city || '',
            State: State || state,
            ConsumerFavouriteLocationID,
            smartyAddrVerificationDetails: addrVerificationDetails,
            smartySuggestionObject: _.get(this, 'props.request.addrValidationDetails.suggestionObject', {}),
            smartyMetaObject: _.get(this, 'props.request.addrValidationDetails.metaObject'),
            ConsumerServiceRequestID: _.get(this, 'props.track.requestDetails.ConsumerServiceRequestID', undefined),
          },
          event: 'initial-page-load',
          identifiers: {
            ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
          },
        }
        this.props.loggerActionAsync(loggerObject)
      }
      /********************** before update address Logger API /**********************/

      await this.props
        .updateAddressAsync({
          AuthID,
          data: {
            Address,
            AddressType,
            ConsumerID,
            Landmark,
            Lat: latitude,
            Lng: longitude,
            Zipcode: pincode,
            City: City || city || '',
            State: State || state,
            StateID: StateID || undefined,
            ConsumerFavouriteLocationID,
            UserLandmark: UserLandmark || undefined,
            District: District || undefined,
            metaObject: _.get(this, 'props.request.addrValidationDetails.metaObject'),
            ...addrVerificationDetails,
          },
        })
        .then((response) => {
          /********************** API response update address Logger API /**********************/
          if (window.useLogger) {
            let loggerObject = {
              apiName: 'api-response-repair-update-address',
              value: {
                userLocation: _.get(this, 'props.request.userLocation'),
                updateAddressApiRepsone: response,
                ConsumerServiceRequestID: _.get(this, 'props.track.requestDetails.ConsumerServiceRequestID', undefined),
              },
              event: 'initial-page-load',
              identifiers: {
                ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
              },
            }
            this.props.loggerActionAsync(loggerObject)
          }
          /********************** API response update address Logger API /**********************/

          this.props.clearAddressValidationDetails()
          this.setState(
            {
              isSavedUserAddressEdited: false,
              isSavedDeliveryAddressEdited: false,
              isRecommendedAddressChosen: false,
              showAddressSuggestion: false,
            },
            async () => {
              const res = await this.props.getUserAddressesAsync({
                ConsumerID,
                AuthID,
              })
              this.props.setUserRequestLocation({
                key: type,
                value: {
                  ...response.data,
                  StateMeta: res?.find((cfl) => cfl.ConsumerFavouriteLocationID === response.data.ConsumerFavouriteLocationID)?.StateMeta,
                },
              })
            }
          )
        })
        .finally(() => {
          this.props.setglobalLoaderStatus(false)
        })
    }
  }

  updateUserDetails = () => {
    return this.props.updateUserDetailsAsync({
      data: {
        consumerID: this.props.user.userDetails.consumerID,
        updateObj: {
          ...(this.state.alternateMobile ? { alternateMobileNo: this.state.alternateMobile } : {}),
          ...(this.state.emailId ? { emailID: this.state.emailId } : {}),
          ...(this.state.mobileNo ? { mobileNo: this.state.mobileNo } : {}),
          ...(this.state.userName ? { name: this.state.userName } : {}),
        },
      },
      AuthID: this.props.user.consumerDetails.data.accessToken.ID,
    })
  }

  saveUserDetails = () => {
    const { emailID, mobileNo, alternateMobileNo, name } = this.props.user.userDetails

    if (
      this.state.emailId !== (emailID || '') ||
      this.state.alternateMobile !== (alternateMobileNo || '') ||
      this.state.mobileNo !== (mobileNo || '') ||
      this.state.userName !== (name || '')
    ) {
      this.setState({
        saveBtnLoader: true,
      })
      this.updateUserDetails()
        .then(() => {
          this.setState({
            saveBtnLoader: false,
            isEditUsrDetails: false,
          })
          this.props.setToaster(getTranslationText('success', 'profileUpdated'), 'success')
        })
        .catch((e) => {
          console.log(e)
          this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
          this.setState({
            saveBtnLoader: false,
          })
        })
    } else {
      this.setState({
        isEditUsrDetails: false,
      })
    }
  }

  cancelEditUserDetails = () => {
    this.setState({
      mobileNo: _.get(this.props, 'user.userDetails.mobileNo', ''),
      emailId: _.get(this.props, 'user.userDetails.emailID', ''),
      alternateMobile:
        _.get(this.props, 'request.claimFormDetails.alternateMobileNo') || _.get(this.props, 'user.userDetails.alternateMobileNo') || '',
      userName: _.get(this.props, 'user.userDetails.name', ''),
      isEditUsrDetails: false,
    })
  }

  checkForIssueSelection = () => {
    this.props.setglobalLoaderStatus(true)
    this.props.setDecisionMatrixDetails(undefined)
    this.state.showClaimInstructions && this.closeClaimInstructions()
    let selectedPlan = this.getSelectedPlan()
    let requestObject = {
      PlanCoverageID: selectedPlan.PlanCoverageID,
      ProductSubCategoryID: _.get(this.props, 'request.selectedDeviceInfo.ProductSubCategoryID'),
      BrandID: _.get(this.props, 'request.selectedDeviceInfo.BrandID'),
      SoldPlanID: selectedPlan.SoldPlanID,
    }
    this.props
      .checkDecisionMatrixAsync(requestObject)
      .then(() => {
        const RequiresIssueSelection = _.get(this.props, 'request.selectedDamageType.RequiresIssueSelection', false)
        const RequiresClaimFormCheck = _.get(this.props, 'request.selectedDamageType.RequiresClaimForm', false)
        if (!RequiresClaimFormCheck && RequiresIssueSelection) {
          this.setState({
            ClaimFormDocState: false,
          })
          this.props.setglobalLoaderStatus(true)
          this.props
            .getRequestIssuesForTypeAsync({
              ProductSubCategoryID: _.get(this, 'props.request.selectedDeviceInfo.ProductSubCategoryID'),
              ProductID: _.get(this, 'props.request.selectedDeviceInfo.ProductID'),
              BrandID: _.get(this, 'props.request.selectedDeviceInfo.BrandID'),
              PlanCoverageID:
                (selectedPlan && selectedPlan.PlanCoverageID) || _.get(this, 'props.track.trackDetails.PlanCoverageID', undefined),
            })
            .then(() => {
              this.props.setglobalLoaderStatus(false)
              this.setState(
                {
                  issuesForType: this.props.request.requestIssuesForType.map((issue) => ({
                    ...issue,
                    text: issue.Issue,
                    isSelected: false,
                  })),
                },
                () => {
                  if (this.props.request.docAlreadyAdded === undefined) {
                    this.props.setInvoiceDocVisibilityState(!!(this.props.devices.productDocs && this.props.devices.productDocs.length))
                  }
                  browserHistory.push({
                    pathname: '/request/select-issue',
                    query: this.props.location.query,
                  })
                }
              )
            })
            .catch(() => {
              this.props.setglobalLoaderStatus(false)
            })
        } else {
          this.checkForClaimForm()
        }
      })
      .catch(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  openClaimInstructions = () => {
    this.closeClaimEligibilityModal()
    let selectedPlan = this.getSelectedPlan()
    if (
      _.get(this, 'props.request.ClaimEligibilityList.soldPlan.claimIntro', []).length &&
      selectedPlan?.RequiresClaimForm &&
      selectedPlan.CoverageType !== 'THEFT_OR_LOSS'
    ) {
      this.setState({
        showClaimInstructions: true,
      })
      pushToAnalytics('Problem_type_service_fee_popup', {
        ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
        ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
      })
    } else {
      this.checkForIssueSelection()
    }
  }

  goToCoverageSelection = () => {
    browserHistory.push({
      pathname: '/request/coverage-selection',
    })
  }

  toggleCoverageInstrPopUp = () => {
    this.setState({
      showCoverageSelectionPopUp: !this.state.showCoverageSelectionPopUp,
    })
  }

  handleCoverageSelection = (coverageId) => {
    this.props.clearClaimFormValues()
    let soldPlanCoverages = this.state.soldPlanCoverages.map((coverage) => {
      return {
        ...coverage,
        isSelected: coverage.SoldPlanCoverageID === coverageId,
      }
    })
    this.setState({
      soldPlanCoverages,
    })
  }

  toggleClaimCoverageConsentCheck = async () => {
    let soldPlanCoverages = this.state.soldPlanCoverages.map((coverage) => {
      return {
        ...coverage,
        legalConfigs: coverage.legalConfigs?.map((consentConfig) => ({
          ...consentConfig,
          Selected: !consentConfig.Selected,
        })),
      }
    })
    await this.setState({
      soldPlanCoverages,
    })
  }

  incidentTypeSelection = async (selectedCoverage) => {
    let claimFormValue = await _.find(
      _.get(this, 'props.request.selectedDamageType.ClaimForm.forms'),
      (o) => o?.IncendentTypeID == selectedCoverage.IncendentTypeID || o?.IncidentTypeID == selectedCoverage.IncendentTypeID
    )
    await this.props.setPlaceOfDamageLocation({})
    await this.props.setSelectedDamageType({
      ...this.props.request.selectedDamageType,
      ...{ LostTheftClaimForm: claimFormValue },
    })
    await this.props.clearUserLocation('userLocation')
    await this.setState({
      claimformInfo: {
        ...this.state.claimformInfo,
        policeReportUrl: '',
        policeReportRenderableUrl: '',
        policeReportID: '',
        deviceDamagePlace: {},
        IncidentTypeID: selectedCoverage.IncendentTypeID,
        incidentType: selectedCoverage.value,
      },
    })
    await this.props.setClaimDetails(this.state.claimformInfo)
  }

  confirmCoverageSelection = (preSelected = false) => {
    let selectedCoverage = this.state.soldPlanCoverages.find((data) => data.isSelected)
    const isProductDisclaimerRequired = _.get(this, 'props.user.appConfigDetails.isProductDisclaimerRequired', false)

    const serializedProductCode = _.get(this, 'props.user.appConfigDetails.productDisclaimer.serializedProductCode', [])
    const selectedDeviceProductCode = _.get(this.props, 'request.selectedDeviceInfo.Product.ProductCode', [])

    if (
      isProductDisclaimerRequired &&
      _.includes(serializedProductCode, selectedDeviceProductCode) &&
      _.includes(['ADH'], selectedCoverage.CoverageType)
    ) {
      this.setState({
        showSerialQuestionModal: true,
      })
    } else {
      this.postClaimCoverageSelection(preSelected)
    }
  }

  postClaimCoverageSelection = (preSelected) => {
    let selectedCoverage = this.state.soldPlanCoverages.find((data) => data.isSelected)
    if (selectedCoverage.CoverageType === 'IW') {
      // Do not let the user go ahead if it's In-Warranty coverage, just show contact us Modal.
      this.setState({ showContactUsModal: true })
      pushToAnalytics('Problem_type_In_Manufacturer_warranty_popup', {
        ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
        ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
      })
      return
    }
    this.props.setClaimEligibility({
      ...this.props.request.ClaimEligibilityList,
      soldPlanCoverages: this.state.soldPlanCoverages,
      Title: selectedCoverage.Title,
      Description: selectedCoverage.Description,
      ButtonText: selectedCoverage.ButtonText,
      CanRaiseClaim: selectedCoverage.CanRaiseClaim,
    })
    this.props.setSelectedDamageType({
      ...this.props.request.selectedDamageType,
      // selectedCoverage
      PlanCoverageID: selectedCoverage.PlanCoverageID,
      RequiresClaimForm: selectedCoverage.RequiresClaimForm,
      RequiresIssueSelection: selectedCoverage.RequiresIssueSelection,
      SkipClaimApproval: selectedCoverage.SkipClaimApproval,
      SoldPlanCoverageID: selectedCoverage.SoldPlanCoverageID,
      CoverageType: selectedCoverage.CoverageType,
      LostTheftClaimForm: undefined,
      ...(selectedCoverage.ClaimForm && { ClaimForm: selectedCoverage.ClaimForm }),
    })
    this.props.setglobalLoaderStatus(true)
    this.props
      .getClaimLegalConfigs({
        PlanCoverageID: selectedCoverage.PlanCoverageID,
        brandID: _.get(this.props, 'request.selectedDeviceInfo.BrandID'),
      })
      .then(() => {
        this.props.setglobalLoaderStatus(false)
        !preSelected && this.goToCoverageSelection()
        let cc = _.get(this, 'props.user.selectedCountryData.CountryCode', 'other').toLowerCase()
        if (!legalConfigCodes[cc]) cc = 'other'
        var consents = getLegalConsents(_.get(this, 'props.user.claimLegalConfigs.LegalConfigs', []), [legalConfigCodes[cc].claim])
        if (consents.length && consents[0].ApplicationLegalConfigID && consents[0].legalConfig && consents[0].legalConfig.Text) {
          this.openClaimConsentModal(consents[0].legalConfig.Text, true, false)
        } else {
          this.props.setClaimLegalConfigs({
            LegalConfigs: [...(this.props.user.claimLegalConfigs?.LegalConfigs ?? []), ...(selectedCoverage?.legalConfigs ?? [])],
          })
          const hideCoverageInstructionModal = _.get(this, 'props.user.appConfigDetails.hideCoverageInstructionModal', '')
          if (hideCoverageInstructionModal) {
            if (!_.get(this, 'props.request.ClaimEligibilityList.IsEligible', false)) {
              this.openClaimEligibilityModal()
            } else {
              this.openClaimInstructions()
            }
          } else {
            this.openClaimEligibilityModal()
          }
        }
      })
  }

  checkForClaimForm = async (selectedIssuesList = []) => {
    this.props.setConsentCoverageConfig({})
    this.state.showClaimInstructions && this.closeClaimInstructions()
    let selectedPlan = this.getSelectedPlan()
    if (selectedPlan && selectedPlan.RequiresClaimForm) {
      this.setState({
        ClaimFormDocState: !!(this.props.devices.productDocs && this.props.devices.productDocs.length >= 1),
      })
      this.props.setglobalLoaderStatus(true)
      let hasdamageType = selectedPlan.ClaimForm && selectedPlan.ClaimForm.RequiresTypeOfDamage
      let damageTypePromise = hasdamageType
        ? this.props.getTypeOfDamageAsync({
            ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', undefined),
            ConsumerID: this.props.user.userDetails.consumerID,
            SoldPlanID: selectedPlan.SoldPlanID,
            SoldPlanCoverageID: selectedPlan.SoldPlanCoverageID,
            fetchPlanSpecificData: true,
          })
        : new Promise((resolve) => resolve())
      damageTypePromise
        .then(() => {
          this.props.setglobalLoaderStatus(false)
          this.setState(
            {
              claimformInfo: {
                ...this.state.claimformInfo,
                typesOfDamage: hasdamageType
                  ? this.props.request.typesOfDamage.map((obj) => {
                      return {
                        ...obj,
                        text: obj.displayText,
                        isSelected: this.props.request.typesOfDamage.length === 1,
                      }
                    })
                  : [],
              },
            },
            () => {
              this.props.setClaimDetails({})
              this.props.setUserRequestLocation({
                key: 'userLocation',
                value: {
                  landmark: '',
                  pincode: '',
                  latitude: '',
                  longitude: '',
                },
              })
              this.props.setglobalLoaderStatus(false)
              browserHistory.push({
                pathname: '/request/claim-form',
                query: this.props.location.query,
              })
            }
          )
        })
        .catch(() => {
          this.props.setglobalLoaderStatus(false)
        })
    } else {
      // EW plan handling
      const SkipClaimApprovalCheck = _.get(this, 'props.request.selectedDamageType.SkipClaimApproval', false)
      const decisionMatrixDetails = _.get(this, 'props.request.decisionMatrixDetails', false)
      if (SkipClaimApprovalCheck) {
        this.props.setglobalLoaderStatus(true)
        let requestObj = {
          SoldPlanID: selectedPlan && selectedPlan.SoldPlanID,
          SoldPlanCoverageID: selectedPlan && selectedPlan.SoldPlanCoverageID,
          ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID'),
        }
        await this.props
          .checkForHighRiskRequestAsync(requestObj)
          .then((response) => {
            if (!response.highRiskRequest) {
              if (decisionMatrixDetails.IsDecisionMatrixEnabled) {
                this.props.setglobalLoaderStatus(true)
                let reqObject = {
                  SoldPlanID: selectedPlan && selectedPlan.SoldPlanID,
                  ConsumerID: this.props.user.userDetails.consumerID,
                  IsUrgent: false,
                  ConsumerServiceRequestID: 0,
                  Documents: [],
                  SoldPlanCoverageID: selectedPlan && selectedPlan.SoldPlanCoverageID,
                  PlanCoverageID: selectedPlan && selectedPlan.PlanCoverageID,
                  PlanID: selectedPlan && selectedPlan.PlanID,
                  DecisionMatrix: true,
                  ProductID: _.get(this.props, 'request.selectedDeviceInfo.ProductID'),
                  ProductSubCategoryID: _.get(this.props, 'request.selectedDeviceInfo.ProductSubCategoryID'),
                  BrandID: _.get(this.props, 'request.selectedDeviceInfo.BrandID'),
                  SkipClaimApproval: true,
                  ServiceTypeID: 10,
                  IsNonPartner: 0,
                  IsUnderRepair: false,
                  Issues: selectedIssuesList && selectedIssuesList.length ? selectedIssuesList : undefined,
                  ClientServiceLocationID: _.get(this, 'props.request.partnerServiceLocation.ClientServiceLocationID', undefined),
                  DescriptionRecording: _.get(this, 'props.request.audioUploadInfo.audioUploadPath', undefined),
                }
                this.props
                  .createRequestAsync(reqObject)
                  .then((response) => {
                    this.setState({
                      generatedCSRID: response.data.ConsumerServiceRequestID,
                    })
                    this.requestRaisedSuccessModel()
                  })
                  .catch()
                  .finally(() => {
                    this.props.setglobalLoaderStatus(false)
                  })
              } else {
                // REDUCER SET: CONSENT DETAILS FOR PLAN COVERAGE
                this.props.setConsentCoverageConfig(_.get(this, 'props.user.claimLegalConfigs.LegalConfigs'))
                browserHistory.push({
                  pathname: '/request/modes',
                  query: {
                    ...this.props.location.query,
                    step: 1,
                  },
                })
              }
            } else {
              this.props.setglobalLoaderStatus(true)
              this.props
                .raiseNewClaimAsync({ SkipClaimApproval: true })
                .then((response) => {
                  this.setState(
                    {
                      generatedCSRID: response.data.ConsumerServiceRequestID,
                      generatedReferenceID: response.data.ReferenceID,
                    },
                    () => {
                      this.requestRaisedSuccessModel()
                    }
                  )
                })
                .finally(() => {
                  this.props.setglobalLoaderStatus(false)
                })
            }
          })
          .finally(() => {
            this.props.setglobalLoaderStatus(false)
          })
      } else {
        this.props.setglobalLoaderStatus(true)
        this.props
          .raiseNewClaimAsync()
          .then((response) => {
            this.setState(
              {
                generatedCSRID: response.data.ConsumerServiceRequestID,
                generatedReferenceID: response.data.ReferenceID,
              },
              () => {
                this.requestRaisedSuccessModel()
              }
            )
          })
          .finally(() => {
            this.props.setglobalLoaderStatus(false)
          })
      }
    }
  }

  getSelectedPlan = () => {
    let planDetails = _.get(this, 'props.request.selectedDamageType', {})
    return planDetails.PlanID ? planDetails : undefined
  }

  openClaimEligibilityModal = () => {
    this.setState({
      ClaimEligibilityModal: true,
    })
    pushToAnalytics('Problem_type_claim_limit_popup', {
      ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
      ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
    })
  }

  openClaimConsentModal = (text) => {
    this.setState({
      ClaimConsentModal: true,
      ClaimConsentText: text,
    })
  }

  closeClaimConsentModal = (claimEligibilityModal) => {
    this.setState(
      {
        ClaimConsentModal: false,
      },
      () => {
        if (claimEligibilityModal) {
          const hideCoverageInstructionModal = _.get(this, 'props.user.appConfigDetails.hideCoverageInstructionModal', '')
          if (hideCoverageInstructionModal) {
            if (!_.get(this, 'props.request.ClaimEligibilityList.IsEligible', false)) {
              this.openClaimEligibilityModal()
            } else {
              this.openClaimInstructions()
            }
          } else {
            this.openClaimEligibilityModal()
          }
        }
      }
    )
  }

  closeClaimInstructions = () => {
    this.setState({
      showClaimInstructions: false,
    })
  }

  closeLogisticInstructions = () => {
    this.setState({
      showLogisticInstructions: false,
    })
    pushToAnalytics('service_type_shipping_instruction_pop_up_closed', {
      ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
      ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
    })
  }

  closeClaimEligibilityModal = () => {
    this.setState({
      ClaimEligibilityModal: false,
    })
  }

  closeIsRepairAllowedModel = () => {
    this.setState({
      isRepairAllowed: false,
    })
  }

  closeCountryStateSelection = () => {
    this.setState({
      showStateSelectionModel: false,
      userAddresslocationObj: {},
      deliveryAddresslocationObj: {},
      selectedState: {},
    })
  }

  closeCountryDistrictSelection = () => {
    this.setState({
      showDistrictSelectionModel: false,
      userAddresslocationObj: {},
      deliveryAddresslocationObj: {},
      selectedDistrict: {},
    })
  }

  closeCoolingPeriodModal = () => {
    this.setState({
      showCoolingPeriodModal: false,
    })
  }

  handleRefModes = (c) => {
    window.modes = c
  }

  handleRefSlots = (c) => {
    window.slots = c
  }

  closeModal = (modalName) => {
    this.setState({ [modalName]: false })
  }

  toggleEmptyBoxInstructions = () => {
    this.setState({ emptyBoxInstructionModal: false }, () => {
      this.handleModeConfirmation()
    })
  }

  handleModeConfirmation = () => {
    if (_.includes([1, 11, 3, 31, 5, 6, 7, 43, 46, 63, 64, 65], this.state.selectedMode) || _.includes([17, 23], this.state.selectedMode)) {
      if (this.state.newAddressFlg) {
        this.setState({
          newAddress: this.props.request.userLocation,
          addressType: this.state.addressType.map((addr) => {
            return {
              ...addr,
              isSelected: false,
            }
          }),
          deliveryAddressType: this.state.deliveryAddressType.map((addr) => {
            return {
              ...addr,
              isSelected: false,
            }
          }),
        })
      } else {
        // In case of new address make address input disabled & address type selected
        let addressList = this.state.addressType
        addressList = addressList.map((data) => {
          return {
            ...data,
            isSelected: data.text === this.props.request.userLocation.AddressType,
          }
        })
        this.setState({
          addressType: addressList,
          newAddress: {
            ...this.props.request.userLocation,
            city: _.get(this, 'props.request.userLocation.City', '') || _.get(this, 'props.request.userLocation.city', ''),
            UserLandmark: _.get(this, 'props.request.userLocation.UserLandmark', ''),
          },
        })
      }
      if (_.includes([17, 23], this.state.selectedMode)) {
        this.toggleLogisticsInstructions()
        return
      } else if (_.includes([46, 63, 65], this.state.selectedMode)) {
        this.setState(
          {
            BtnStatusLoader: false,
          },
          () => {
            this.props.setglobalLoaderStatus(false)
            let query = this.props.location.query
            query['mode'] = this.state.selectedMode.toString()
            browserHistory.push({
              pathname: '/request/slots',
              query: query,
            })
          }
        )
      } else if (_.get(this, 'props.request.selectedMode.logisticsSuppressed', false)) {
        this.props.setglobalLoaderStatus(false)
        let query = this.props.location.query
        query['mode'] = this.state.selectedMode.toString()
        browserHistory.push({
          // ?mode=3&step=2
          pathname: '/request/slots',
          query: query,
        })
      } else {
        const userLocation = _.get(this, 'props.request.userLocation', '')
        this.props.setglobalLoaderStatus(true)
        let slotReqObj = {
          Pincode: userLocation.pincode || userLocation.PinCode || userLocation.Zipcode,
          CurrentDate: moment().format('YYYY-MM-DD'),
          CurrentTime: moment().format('hh:mm:ss'),
          DeliveryMode:
            this.props.request.requestModesData.logisticPartner &&
            this.props.request.requestModesData.logisticPartner.isChooseLogisticsPartner
              ? this.state.selectedVendorId.VendorID
              : // ? this.props.request.selectedVendor.VendorID
                this.props.request.requestModesData.DeliveryMode,
          Lat: this.props.request.userLocation.latitude,
          Lng: this.props.request.userLocation.longitude,

          PartnerServiceLocationID: this.props.request.requestModesData.PartnerServiceLocationID,
          PartnerID: this.props.request.partnerServiceLocation
            ? this.props.request.partnerServiceLocation.PartnerID
            : this.props.request.requestModesData.PartnerID,
          ProductID: this.props.request.selectedDeviceInfo && this.props.request.selectedDeviceInfo.ProductID,
          ServiceTypeID: this.props.request.selectedMode.ServiceTypeID,
          ConsumerProductID: _.includes([3, 5, 6, 7, 64], this.state.selectedMode)
            ? this.props.request.selectedDeviceInfo.ConsumerProductID
            : undefined,
        }
        if (this.props.request.selectedMode.ServiceTypeID === 43) {
          slotReqObj = {
            ...slotReqObj,
            ...this.props.request.requestModesData.doorToDoorDetail,
          }
        }
        if (!_.get(this, 'props.request.selectedMode.logisticsSuppressed', false)) {
          this.props
            .getRequestSlotsAsync(slotReqObj)
            .then(() => {
              this.props.setglobalLoaderStatus(false)
              let query = this.props.location.query
              query['mode'] = this.state.selectedMode.toString()
              browserHistory.push({
                // ?mode=3&step=2
                pathname: '/request/slots',
                query: query,
              })
            })
            .catch(() => {
              this.props.setglobalLoaderStatus(false)
            })
        }
      }
    } else if (_.includes([52], this.state.selectedMode)) {
      if (this.state.newAddressFlg) {
        this.setState({
          newAddress: '',
          addressType: this.state.addressType.map((addr) => {
            return { ...addr, isSelected: false }
          }),
        })
      } else {
        // In case of new address make address input disabled & address type selected
        let addressList = this.state.addressType
        addressList = addressList.map((data) => {
          return {
            ...data,
            isSelected: data.text === this.props.request.userLocation.AddressType,
          }
        })
        this.setState({
          addressType: addressList,
          newAddress: this.props.request.userLocation,
        })
      }
      let query = this.props.location.query
      query['mode'] = this.state.selectedMode.toString()
      browserHistory.push({
        // ?mode=3&step=2
        pathname: '/request/slots',
        query: query,
      })
    } else if (_.includes([66], this.state.selectedMode)) {
      this.props.setglobalLoaderStatus(false)
      let query = this.props.location.query
      query['mode'] = this.state.selectedMode.toString()
      browserHistory.push({
        pathname: '/request/modes',
        query: {
          ...this.props.location.query,
          step: 2,
        },
      })
    } else {
      this.setState(
        {
          BtnStatusLoader: true,
        },
        () => {
          const queryType = _.get(this, 'props.location.query.type', 'repair').toLowerCase()
          let url =
            '/request/modes?' +
            (this.props.request.source && this.props.request.source.claim ? 'claim=' + this.props.request.source.claim + '&' : '') +
            (this.props.location.query.csrid ? 'csrid=' + this.props.location.query.csrid + '&' : '') +
            ('mode=' + this.state.selectedMode + '&') +
            ('step=' + (this.state.modeSelectStep + 1) + '') +
            ('&type=' + queryType)
          this.props.addToVisitedUrl(url)
          window.location.assign(url)
        }
      )
    }
  }

  LogisticPartnerConfirmation = () => {
    this.setState({
      showLogisticPartners: false,
    })
    if (this.props.request.requestModesData.logisticPartner.isDedicatedDialog) {
      // this.handleModeConfirmation()
      if (_.get(this, 'props.request.requestModesData.VendorList[0].VendorInstructions.Instructions', []).length) {
        this.toggleLogisticsInstructions()
      } else {
        this.handleModeConfirmation()
      }
    } else {
      let vendorId = this.state.logisticPartnerList.find((data) => data.isSelected)
      this.props.setLogisticPartner(vendorId)
      this.setState(
        {
          selectedVendorId: vendorId,
        },
        () => {
          if (_.get(vendorId, 'VendorInstructions.Instructions', []).length) {
            this.toggleLogisticsInstructions()
          } else {
            this.handleModeConfirmation()
          }
        }
      )
    }
  }

  handleLogisticPartnerSelection = (id) => {
    if (id) {
      this.setState({
        logisticPartnerList: this.state.logisticPartnerList.map((data) => {
          return {
            ...data,
            isSelected: id === data.VendorID ? !data.isSelected : false,
          }
        }),
      })
    }
  }

  closeLogisticModal = () => {
    this.setState({
      showLogisticPartners: false,
    })
  }

  arrayofObjectsSortHandler = (a, b) => {
    let comparison = 0
    if (a.Priority > b.Priority) {
      comparison = 1
    } else if (a.Priority < b.Priority) {
      comparison = -1
    }
    return comparison
  }

  navToConfirmationPage = () => {
    this.setState(
      {
        showPickUpInstructionsModal: false,
      },
      () => {
        let confirRedirectPromise = new Promise((resolve) => {
          resolve()
        })
        if (this.props.request.requestModesData.CalculateHandlingCharges) {
          this.props.setHandlingCharges()
          let deviceList = this.getselectedDevicesList()
          let chargesPayload = {
            ConsumerProductID: deviceList[0].ConsumerProductID,
            ServiceTypeID: this.props.request.selectedMode.ServiceTypeID,
          }
          if (deviceList.length > 1) {
            chargesPayload = {
              ...chargesPayload,
              ConsumerProductID: undefined,
              ProductInfo: deviceList.map((device) => ({
                ConsumerProductID: device.ConsumerProductID,
              })),
            }
          }
          confirRedirectPromise = this.props.getHandlingCharges(chargesPayload)
        }
        this.props.setglobalLoaderStatus(true)
        confirRedirectPromise
          .then(() => {
            this.props.setglobalLoaderStatus(false)
            browserHistory.push({
              pathname: '/request/confirm',
              query: this.props.location.query,
            })
          })
          .catch(() => {
            this.props.setglobalLoaderStatus(false)
          })
      }
    )
  }

  setSelectedInstruction = (e) => {
    if (e) {
      const isTriggeredByAnchorTag = /^(<a)|(<strong)+/.test(e.target?.outerHTML)
      if (isTriggeredByAnchorTag) return

      let id = parseInt(e.currentTarget.id.replace('instr-', ''))
      if (id >= 0) {
        this.setState({
          pickupInstructionValues: this.state.pickupInstructionValues.map((instr, index) => {
            return {
              ...instr,
              isSelected: index === id ? !instr.isSelected : instr.isSelected,
            }
          }),
        })
      }
    }
  }

  toggleImeiModal = () => {
    this.setState({
      showSerialImeiModal: !this.state.showSerialImeiModal,
    })
  }

  toggleLogisticsInstructions = (e, flag) => {
    /** dropOffInstructions if dropOff is selected */
    let dropOffInstructions =
      [17, 23, 57, 58].includes(_.get(this, 'props.request.selectedMode.ServiceTypeID')) &&
      _.get(this, 'props.request.requestModesData.dropOffInstructions.DropOffInstructionsTitle')
        ? true
        : null
    this.setState(
      {
        showLogisticInstructions:
          (dropOffInstructions !== null
            ? dropOffInstructions && !this.state.showLogisticInstructions
            : this.props.request.requestModesData.dropOffVendorList && !this.state.showLogisticInstructions) &&
          !(_.includes([17, 23], this.state.selectedMode) && _.get(this, 'props.request.emptyBoxServiceable.isEmptyBoxServiceable', false)),
        dropoffEventId: e && typeof flag !== 'boolean' ? e.currentTarget.id : this.state.dropoffEventId,
      },
      () => {
        if (this.state.showLogisticInstructions) {
          pushToAnalytics('service_type_shipping_instruction_pop_up', {
            ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
            ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
          })
        }
        if (_.includes([17, 23, 57, 58], this.state.selectedMode)) {
          if (
            _.get(this, 'props.request.emptyBoxServiceable.isEmptyBoxServiceable', false) ||
            !this.props.request.requestModesData.dropOffVendorList ||
            (typeof flag === 'boolean' && flag)
          ) {
            this.handleDropOffItemClick()
          } else {
            let vendor = this.props.request.requestModesData.dropOffVendorList[0]
            this.setState({
              selectedVendorId: vendor,
            })
            this.props.setLogisticPartner(vendor)
          }
        } else if (_.includes([1, 11], this.state.selectedMode) && typeof flag === 'boolean' && flag) {
          this.handleModeConfirmation()
        }
      }
    )
  }

  checkFileTypeValidation = (fileType, docId) => {
    if (docId === 'additional-photo' && fileType === 'audio') {
      this.props.setToaster(getTranslationText('error', 'validImage'), 'error')
      return false
    } else if (docId === 'additional-audio' && fileType === 'image') {
      this.props.setToaster(getTranslationText('error', 'validAudio'), 'error')
      return false
    } else {
      return true
    }
  }

  handleAdditionalUploads = (e) => {
    let id = e.target.id.replace('-input', '')
    let docId = id === 'photo' ? 'additional-photo' : 'additional-audio'
    let file = e.target.files[0]
    let fileType = e.target.files[0].type.split('/')[0]
    canUpload(e, { allowedTypes: ['image', 'audio'] })
      .then(() => {
        if (this.checkFileTypeValidation(fileType, docId)) {
          let uniqueId = uuid.v1()
          this.props.setglobalLoaderStatus(true)
          this.props
            .getSignedUrlAsync(
              {
                AuthID: this.props.user.consumerDetails.data.accessToken.ID,
                data: {
                  Type: file.type,
                  Key: uniqueId,
                },
              },
              file,
              docId
            )
            .then(() => {
              this.props.setglobalLoaderStatus(false)
              let additionalUpload = this.state.additionalUpload
              if (id === 'photo') {
                additionalUpload['imageList'].push({
                  imagePath: this.props.user.s3Uploads.fileUrl,
                  uniqueId,
                })
              }
              this.setState(
                {
                  additionalUpload: { ...additionalUpload },
                },
                () => {
                  this.props.setAudioUploadData({
                    ...additionalUpload,
                  })
                  this.props.setAdditionalUploadData({
                    ...additionalUpload,
                  })
                }
              )
            })
            .catch(() => {
              this.props.setglobalLoaderStatus(false)
            })
        }
      })
      .catch((error) => this.props.setToaster(error.message, 'error'))
  }

  handleUploadAdditionalDocsToDS = (e) => {
    let id
    let docId
    let file
    let fileType
    if (e.type !== 'change') {
      fileType = e.type.split('/')[0]
      docId = 'additional-audio'
      file = e
    } else {
      id = e.target.id.replace('-input', '')
      docId = id === 'photo' ? 'additional-photo' : 'additional-audio'
      file = e.target.files[0]
      fileType = e.target.files[0].type.split('/')[0]
    }
    const token = _.get(this, 'props.user.consumerDetails.data.accessToken.ID')
    this.props.setglobalLoaderStatus(true)
    canUpload(e, { allowedTypes: ['image', 'audio'] }, 'audio')
      .then(async () => {
        if (this.checkFileTypeValidation(fileType, docId)) {
          let uniqueId = uuid.v4()
          try {
            const payload = getDocServicePayload(file, 'ConsumerServiceRequestDocument')
            const response = await postRequestAsync(
              'internal/document/upload',
              payload,
              { Authorization: token, module: docServiceModuleName },
              window.docServiceUri
            )
            let additionalUpload = this.state.additionalUpload
            if (id === 'photo') {
              additionalUpload['imageList'].push({
                imagePath: response.url,
                imageUploadPath: response.uploadURL,
                uniqueId,
                docServiceUUID: response.docServiceUUID,
              })
            } else {
              additionalUpload['audioUploadPath'] = response.uploadURL
              additionalUpload['audioDocServiceUUID'] = response.docServiceUUID
              additionalUpload['streamUrl'] = response.streamUrl
            }
            this.setState(
              {
                additionalUpload: { ...additionalUpload },
              },
              () => {
                this.props.setAudioUploadData({
                  ...additionalUpload,
                })
                this.props.setAdditionalUploadData({
                  ...additionalUpload,
                })
              }
            )
            this.props.setglobalLoaderStatus(false)
          } catch (err) {
            this.props.setglobalLoaderStatus(false)
            this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
            console.log(err)
          }
        }
      })
      .catch((err) => {
        this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
        console.log(err)
        this.props.setglobalLoaderStatus(false)
      })
  }

  closePurchasedPlanModal = () => {
    this.setState({
      showPurchasedPlansModal: false,
    })
  }

  ViewAddtionallyUploadedDocs = (e) => {
    let id = this.state.additionalUpload.imageList.find((img) => img.uniqueId === e.currentTarget.id)
    if (id) {
      this.setDocViewerObject({
        FilePath: id.imagePath,
        uniqueId: id.uniqueId,
        showRemoveBtn: true,
        isAdditionalUpload: true,
      })
    }
  }

  ViewUploadInvoice = (e) => {
    let id = this.props.devices.productDocs.find((img) => img.ConsumerProductDocumentID === parseInt(e.currentTarget.id))
    if (id) {
      this.setDocViewerObject({
        FilePath: id.FilePath,
        uniqueId: id.ConsumerProductDocumentID,
        showRemoveBtn: true,
      })
    }
  }

  handleRemoveUploadedPoliceReport = () => {
    this.setDocViewerObject({
      FilePath: '',
      uniqueId: '',
      showRemoveBtn: false,
      removeImgFunc: undefined,
    })
    const claimformInfo = {
      ...this.state.claimformInfo,
      policeReportUrl: '',
      policeReportRenderableUrl: '',
      policeReportID: '',
    }
    this.setState({ claimformInfo }, () => {
      this.props.setClaimDetails(this.state.claimformInfo)
    })
  }

  ViewUploadedPoliceReport = () => {
    let filePath = _.get(this, 'props.request.claimFormDetails.policeReportRenderableUrl')
    let fileId = _.get(this, 'props.request.claimFormDetails.policeReportID')
    if (filePath) {
      this.setDocViewerObject({
        FilePath: filePath,
        uniqueId: fileId,
        showRemoveBtn: true,
        removeImgFunc: this.handleRemoveUploadedPoliceReport,
      })
    }
  }

  ViewUploadedImage = (e) => {
    let id = e.currentTarget.id
    if (id) {
      this.setDocViewerObject({
        FilePath: id,
      })
    }
  }

  setDocViewerObject = (obj) => {
    this.setState({
      docViewerObj: {
        viewUploadedDocs: true,
        FilePath: obj.FilePath,
        uniqueId: obj.uniqueId,
        showRemoveBtn: obj.showRemoveBtn,
        isAdditionalUpload: obj.isAdditionalUpload,
        removeImgFunc: obj.removeImgFunc,
      },
    })
  }

  unsetDocViewerObj = (docState = {}, additionalState = {}, callBack) => {
    this.setState(
      {
        docViewerObj: {
          viewUploadedDocs: false,
          FilePath: '',
          ...docState,
        },
        ...additionalState,
      },
      () => {
        callBack && callBack()
      }
    )
  }

  deleteUploadedDoc = () => {
    if (this.state.docViewerObj.removeImgFunc) {
      this.unsetDocViewerObj(
        {
          removeImgFunc: this.state.docViewerObj.removeImgFunc,
        },
        {},
        () => {
          this.state.docViewerObj.removeImgFunc()
        }
      )
    } else if (this.state.docViewerObj.isAdditionalUpload) {
      this.unsetDocViewerObj(
        {},
        {
          additionalUpload: {
            ...this.state.additionalUpload,
            imageList: this.state.additionalUpload.imageList.filter((img) => img.uniqueId !== this.state.docViewerObj.uniqueId),
          },
        },
        () => {
          this.props.setToaster(getTranslationText('success', 'documentDeleted'), 'success')
          this.props.setAdditionalUploadData(this.state.additionalUpload)
          this.props.setAudioUploadData({})
        }
      )
    } else {
      if (this.state.newDeviceDocsList) {
        this.unsetDocViewerObj(
          {},
          {
            newDeviceDocsList: {},
          }
        )
      }
      if (this.state.isNewDevice) {
        return
      }
      this.props.setglobalLoaderStatus(true)
      this.props
        .deleteDeviceProductDocs({
          ConsumerProductDocumentID: parseInt(this.state.docViewerObj.uniqueId),
          AuthID: this.props.user.consumerDetails.data.accessToken.ID,
        })
        .then(() => {
          this.unsetDocViewerObj(
            {},
            {
              isInvoiceUploaded: !_.isEmpty(this.state.newDeviceDocsList),
              uploadedInvoiceImgFlg: false,
            }
          )
          this.props.setToaster(getTranslationText('success', 'documentDeleted'), 'success')
          this.props
            .getProductDocsAsync(
              {
                ConsumerProductID: this.props.request.selectedDeviceInfo.ConsumerProductID,
              },
              this.props.user.consumerDetails.data.accessToken.ID
            )
            .finally(() => {
              this.props.setglobalLoaderStatus(false)
            })
        })
        .catch(() => {
          this.props.setglobalLoaderStatus(false)
        })
    }
  }

  toggleEditUsrDetails = () => {
    this.setState({
      isEditUsrDetails: !this.state.isEditUsrDetails,
    })
  }

  // handler for mobile number On confirn route
  handleMobileNo = (e) => {
    if (this.checkFormat(e.target.value) && regexCheck('onChange', 'numeric', e.target.value, Math.max(...this.state.phnoLengthAr))) {
      if (e.target.value === _.get(this.props, 'user.userDetails.alternateMobile')) {
        this.props.setToaster(getTranslationText('error', 'MobileNumberFailed'), 'error')
      }
      this.setState({
        mobileNo: e.target.value,
      })
    }
  }
  // handler for email id On confirn route
  handleEmailId = (e) => {
    if (regexCheck('onChange', 'emailId', e.target.value)) {
      this.setState({
        emailId: e.target.value,
      })
    }
  }
  // handler for alternate mobile number On confirn route
  handleAlternateMobile = (e) => {
    if (this.checkFormat(e.target.value) && regexCheck('onChange', 'numeric', e.target.value, Math.max(...this.state.phnoLengthAr))) {
      if (e.target.value === _.get(this.props, 'user.userDetails.mobileNo')) {
        this.props.setToaster(getTranslationText('error', 'alternateMobileNumberFailed'), 'error')
      }
      this.setState({
        alternateMobile: e.target.value,
      })
    }
  }

  handleUserName = (e) => {
    if (regexCheck('onChange', 'userName', e.target.value)) {
      this.setState({
        userName: e.target.value,
      })
    }
  }

  closePickUPinstrModal = () => {
    this.setState({
      showPickUpInstructionsModal: false,
    })
  }

  handlePlanActivationChange = (e) => {
    if (e.target.name === 'activationPurchaseCost' && regexCheck('validation', 'hasAlphachars', e.target.value)) {
      return
    } else if (e.target.name === 'activationCode' && /([^A-z^0-9]|_|\^|\\)+/.test(e.target.value)) {
      return
    } else if (e.target.name === 'activationInvoiceFiles') {
      let uploadImg = false
      this.props.setglobalLoaderStatus(true)
      return this.handleInvoiceUpload({ ...e, returnAfterS3Upload: true })
        .then((response = {}) => {
          uploadImg = response.success
          if (uploadImg) {
            return this.addDocsToProduct()
          }
          this.props.setToaster(getTranslationText('error', 'invoiceUploadFailed'), 'error')
        })
        .then(() => {
          uploadImg &&
            this.setState(
              {
                activationPageDetails: {
                  ...this.state.activationPageDetails,
                  activationInvoiceFiles: [...this.state.activationPageDetails.activationInvoiceFiles, e.target.files[0]],
                },
              },
              this.checkPlanActivationButtonStatus
            )
        })
        .finally(() => {
          this.props.setglobalLoaderStatus(false)
        })
    }
    this.setState(
      {
        activationPageDetails: {
          ...this.state.activationPageDetails,
          [e.target.name]:
            e.target.name === 'activationInvoiceFiles'
              ? [...this.state.activationPageDetails.activationInvoiceFiles, e.target.files[0]]
              : e.target.value,
        },
      },
      this.checkPlanActivationButtonStatus
    )
  }

  checkPlanActivationButtonStatus = () => {
    let flag = true
    if (this.props.request.activationPlanDetails) {
      let config = this.props.request.activationPlanDetails.PlanConfig
      let dummyObj = {}
      dummyObj.activationCode = this.state.activationPageDetails.activationCode
      if (config.RequiresDateOfPurchase && this.props.request.selectedDeviceInfo.VerificationStateMeta.VerificationStateID !== 2) {
        dummyObj.activationDOP = this.state.activationPageDetails.activationDOP
      }
      if (config.RequiresInvoiceOfDevice && this.props.request.selectedDeviceInfo.VerificationStateMeta.VerificationStateID !== 2) {
        dummyObj.activationInvoiceFiles = this.state.activationPageDetails.activationInvoiceFiles
      }
      if (config.RequiresProductPurchaseCost) {
        dummyObj.activationPurchaseCost = this.state.activationPageDetails.activationPurchaseCost
      }
      for (let key in dummyObj) {
        if (!this.state.activationPageDetails[key]) {
          flag = false
        } else if (key === 'activationPurchaseCost' && !parseInt(this.state.activationPageDetails[key])) {
          flag = false
        } else if (key === 'activationInvoiceFiles' && this.state.activationPageDetails[key].length === 0) {
          flag = false
        } else if (key === 'activationCode' && this.state.activationPageDetails[key].length !== 8) {
          flag = false
        }
      }
    } else {
      flag = false
    }
    this.setState({
      enableActivation: flag,
    })
  }

  openActivationInvoiceImageViewer = (fileIndex) => {
    const InvoiceImgIndex = fileIndex
    this.setDocViewerObject({
      FilePath: URL.createObjectURL(this.state.activationPageDetails.activationInvoiceFiles[InvoiceImgIndex]),
      showRemoveBtn: true,
      removeImgFunc: () => {
        let activationInvoiceFiles = this.state.activationPageDetails.activationInvoiceFiles.filter((data, idx) => {
          if (idx !== InvoiceImgIndex) {
            return data
          }
        })
        this.setState(
          {
            activationPageDetails: {
              ...this.state.activationPageDetails,
              activationInvoiceFiles: activationInvoiceFiles,
            },
          },
          this.checkPlanActivationButtonStatus
        )
      },
    })
  }

  validateProfileDetails = () => {
    if (!this.state.phnoLengthAr.includes(this.state.mobileNo.length)) {
      return false
    } else if (!/\w+@\w+\.\w+/.test(this.state.emailId)) {
      return false
    } else {
      return true
    }
  }

  handleTypeOfDamageSelect = (e) => {
    let id = e.currentTarget.id.replace('typesOfDamage-', '')
    let selectedIndex = parseInt(id)
    this.setState(
      {
        claimformInfo: {
          ...this.state.claimformInfo,
          typesOfDamage: this.state.claimformInfo.typesOfDamage.map((dam, index) => {
            return {
              ...dam,
              isSelected: index === selectedIndex,
            }
          }),
        },
      },
      () => {
        this.props.setClaimDetails(this.state.claimformInfo)
      }
    )
  }

  handleDatePickerOnChange = (e, id) => {
    if (id === 'add-warranty-date') {
      let productConfig = this.state.addingDeviceslistbox.productvalue[0].ProductConfig
      this.setState(
        {
          selectFrombtnStatus: false,
          newDeviceData: {
            ...this.state.newDeviceData,
            newDeviceWarrantyDate: e,
          },
        },
        () => {
          this.handleBtnStatusForUniueKey(productConfig)
        }
      )
    } else if (id === 'activationDOP') {
      this.setState(
        {
          activationPageDetails: {
            ...this.state.activationPageDetails,
            activationDOP: moment(e).format('YYYY-MM-DD'),
          },
        },
        this.checkPlanActivationButtonStatus
      )
    } else if (
      id === 'repair-date' ||
      id === 'police-report-date' ||
      id === 'date-of-theft' ||
      id === 'damage-date' ||
      id === 'date-of-loss'
    ) {
      const idToKeyNameMapping = {
        'repair-date': 'devicerepairDate',
        'police-report-date': 'policeReportDate',
        'date-of-theft': 'dateOfTheft',
        'damage-date': 'deviceDamageDate',
        'date-of-loss': 'dateOfLoss',
      }
      this.setState(
        {
          claimformInfo: {
            ...this.state.claimformInfo,
            [idToKeyNameMapping[id]]: e,
          },
        },
        () => {
          this.props.setClaimDetails(this.state.claimformInfo)
        }
      )
    }
  }

  toggleOrdersInstr = () => {
    this.setState({
      showOrderNoInstr: !this.state.showOrderNoInstr,
    })
  }

  initOrderQuery = (selectedOrder) => {
    let order = {
      ...selectedOrder,
      itemList: selectedOrder.itemList.filter((item) => item.isSelected),
    }
    this.props.setSelectedOrder(order)
    this.setState({
      selectedOrderDetails: order,
      showReturnStepsInstr: true,
    })
  }

  handleReturnFlowStepsPopUp = (actionType) => {
    this.setState(
      {
        showReturnStepsInstr: false,
      },
      () => {
        if (actionType === 'continue') {
          this.props
            .fetchReturnReasons({
              BrandID: this.getselectedDevicesList()[0].BrandID,
            })
            .then(() => {
              browserHistory.push({
                pathname: '/request/select-reason',
                query: this.props.location.query,
              })
            })
            .catch(() => {})
        }
      }
    )
  }

  getselectedDevicesList = () => {
    let selectedDeviceList = []
    if (['refund', 'replacement'].includes(_.get(this, 'props.location.query.type'))) {
      selectedDeviceList = _.get(this, 'props.request.selectedOrderDetails.itemList', []).filter((item) => item.isSelected)
    } else {
      selectedDeviceList = [this.props.request.selectedDeviceInfo]
    }
    return selectedDeviceList
  }

  deviceInfoBasedonReqType = (reqPayload, raiseReqFlow) => {
    let selectedDeviceList = this.getselectedDevicesList()
    let reqType = _.get(this, 'props.location.query.type', 'repair').toLowerCase()
    if (['refund', 'replacement'].includes(reqType) && selectedDeviceList.length > 1) {
      reqPayload['ProductInfo'] = selectedDeviceList.map((info) => ({
        ProductID: info.ProductID,
        ProductUniqueID: (raiseReqFlow && (info.ProductUniqueID || info.DownloadedDeviceUniqueKey)) || undefined,
        ConsumerProductID: info.ConsumerProductID,
        ProductSubCategoryID: info.ProductSubCategoryID,
        BrandID: info.BrandID,
        Reasons: raiseReqFlow
          ? _.compact(
              info.reasonsList.map((reason) => {
                if (reason.isSelected) {
                  if (reason.text.toLowerCase() === 'others') {
                    return { ...reason, ReturnDescription: info.returnDescption }
                  }
                  return reason
                }
              })
            )
          : undefined,
        DeviceImage: raiseReqFlow ? info.uploadedImageUrl : undefined,
        StandardReplacement: reqType === 'replacement' ? info.StandardReplacement : undefined,
        AdvanceExchange: reqType === 'replacement' ? info.AdvanceExchange : undefined,
      }))
    } else {
      reqPayload = {
        ...reqPayload,
        ProductUniqueID:
          (raiseReqFlow && (selectedDeviceList[0].ProductUniqueID || selectedDeviceList[0].DownloadedDeviceUniqueKey)) || undefined,
        ProductID: selectedDeviceList[0].ProductID,
        ConsumerProductID: selectedDeviceList[0].ConsumerProductID,
        ProductSubCategoryID: selectedDeviceList[0].ProductSubCategoryID,
        BrandID: selectedDeviceList[0].BrandID,
        StandardReplacement: reqType === 'replacement' ? selectedDeviceList[0].StandardReplacement : undefined,
        AdvanceExchange: reqType === 'replacement' ? selectedDeviceList[0].AdvanceExchange : undefined,
        DeviceImage: raiseReqFlow ? selectedDeviceList[0].uploadedImageUrl : undefined,
        Reasons: raiseReqFlow
          ? _.compact(
              (selectedDeviceList[0].reasonsList || []).map((reason) => {
                if (reason.isSelected) {
                  if (reason.text.toLowerCase() === 'others') {
                    return { ...reason, ReturnDescription: selectedDeviceList[0].returnDescption }
                  }
                  return reason
                }
              })
            )
          : undefined,
      }
    }
    return reqPayload
  }

  handleRepairInvoiceUpload = (e) => {
    let docId = 'additional-photo'
    let file = e.target.files[0]
    let fileType = e.target.files[0].type.split('/')[0]
    const token = _.get(this, 'props.user.consumerDetails.data.accessToken.ID')
    this.props.setglobalLoaderStatus(true)
    canUpload(e, { allowedTypes: ['image', 'pdf'] })
      .then(async () => {
        if (this.checkFileTypeValidation(fileType, docId)) {
          try {
            const payload = getDocServicePayload(file, 'ConsumerServiceRequestDocument')
            const response = await postRequestAsync(
              'internal/document/upload',
              payload,
              { Authorization: token, module: docServiceModuleName },
              window.docServiceUri
            )
            this.setState(
              {
                claimformInfo: {
                  ...this.state.claimformInfo,
                  repairInvoiceUrl: response.streamUrl,
                  repairInvoice: response.uploadURL,
                },
              },
              () => {
                this.props.setClaimDetails(this.state.claimformInfo)
              }
            )
            this.props.setglobalLoaderStatus(false)
          } catch (err) {
            this.props.setglobalLoaderStatus(false)
            this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
            console.log(err)
          }
        }
      })
      .catch((err) => {
        this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
        console.log(err)
        this.props.setglobalLoaderStatus(false)
      })
  }

  viewRepairInvoice = () => {
    this.setDocViewerObject({
      FilePath: this.state.claimformInfo.repairInvoiceUrl,
      showRemoveBtn: true,
      removeImgFunc: () => {
        this.setState(
          {
            claimformInfo: {
              ...this.state.claimformInfo,
              repairInvoiceUrl: undefined,
            },
          },
          () => {
            this.props.setClaimDetails(this.state.claimformInfo)
          }
        )
      },
    })
  }

  getPinCodeConfiguration = () => {
    const addressConfig = _.get(this, 'props.user.countryAddressConfig.addressConfig', fallbackAddressConfig)
    return addressConfig.find((v) => (v['keyName'] === 'PinCode' || v['keyName'] === 'Zipcode') && v['mandatory'])
  }

  setPinCodeSelection = async () => {
    await this.props.setUserRequestLocation({
      key: this.state.locationType,
      value: {
        ..._.get(this, `props.request[${this.state.locationType}]`),
        pincode: this.state.pincode,
      },
    })
    this.setState({ showPinCodeModal: false }, () => {
      this.state.locationType === 'userLocation' ? this.getRequestModes() : this.getDeliveryModes()
    })
  }

  closeRepairSuccessModal = (csrId_) => {
    let csrId = _.get(this, 'props.request.raisedRequest.ConsumerServiceRequestID', csrId_)
    this.props.setTrackHoldPaymentStatus(undefined)
    this.setState(
      {
        showRepairRaisedSuccessModal: false,
      },
      () => {
        this.setState(
          {
            globalLoaderFlg: false,
          },
          () => {
            browserHistory.push('/track?id=' + window.btoa(csrId).toString())
            this.props.storeLastKnowPathName({
              path: '/request/confirm',
            })
          }
        )
      }
    )
  }

  viewUploadVideo = () => {
    this.setState({
      showVideoMediaModal: true,
    })
  }

  closeModalHandler = (stateModelName) => {
    let newState = {
      [stateModelName]: false,
    }
    if (stateModelName === 'showVideoMediaModal') {
      newState.hideReuploadBtn = false
    } else if (stateModelName === 'showDisClaimerModal') {
      this.setState(
        {
          isDisClaimerModalClosed: true,
        },
        () => {
          this.handleContinue()
        }
      )
    }
    this.setState(newState)
  }

  showProofOfDamage = () => {
    this.setState({
      showVideoMediaModal: true,
      hideReuploadBtn: true,
    })
  }

  buyOutInputHandler = (e) => {
    if (e.target.value) {
      switch (e.target.id) {
        case 'EmailID':
          if (regexCheck('onChange', 'emailId', e.target.value, 40)) {
            this.setState({
              buyOutFormInfo: { ...this.state.buyOutFormInfo, EmailID: e.target.value },
            })
          }
          break
        case 'UserName':
          if (regexCheck('onChange', 'userName', e.target.value)) {
            this.setState({
              buyOutFormInfo: { ...this.state.buyOutFormInfo, UserName: e.target.value },
            })
          }
          break

        case 'UserContactNumber':
          if (regexCheck('onChange', 'numeric', e.target.value, Math.max(...this.state.phnoLengthAr))) {
            this.setState({
              buyOutFormInfo: { ...this.state.buyOutFormInfo, UserContactNumber: e.target.value },
            })
          }
          break
      }
    } else {
      this.setState({
        buyOutFormInfo: { ...this.state.buyOutFormInfo, [e.target.id]: '' },
      })
    }
  }

  addressPreviewDisplayToggle = () => {
    this.setState({ showDisplayPreviewModal: false })
  }

  addressSlotsRirection = () => {
    this.setState(
      {
        isSavedUserAddressEdited: false,
        isSavedDeliveryAddressEdited: false,
        showDisplayPreviewModal: false,
      },
      () => {
        // new route for upload docs
        if ([46, 63, 65, 17, 23].includes(parseInt(this.props.request.selectedMode.ServiceTypeID))) {
          // to call api for fetching media instruction
          this.props.setglobalLoaderStatus(true)
          let requestObject = {
            Action: 'proofOfDamageInstructions',
            ProductSubCategoryID: _.get(this, 'props.request.selectedDeviceInfo.ProductSubCategoryID'),
          }
          const RequiresProofOfdamage = _.get(this, 'props.request.selectedMode.RequiresProofOfDamage')
          let promise
          if (RequiresProofOfdamage) {
            promise = this.props.getServicetypeDetailsAsync(requestObject)
          } else {
            promise = new Promise((resolve) => {
              resolve()
            })
          }
          promise.finally(() => {
            this.props.setglobalLoaderStatus(false)
            this.props.storeLastKnowPathName({
              path: '/request/slots',
              params: {
                ...this.props.location.query,
              },
            })
            if (RequiresProofOfdamage) {
              this.props.setDSFileUploadDetails({})
              browserHistory.push({
                pathname: '/request/documents',
                query: {
                  ...this.props.location.query,
                },
              })
            } else {
              if ([46].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))) {
                this.navToConfirmationPage()
              } else if ([65, 17, 23].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))) {
                // Show pickUp instruction modal for Adavance Exchange flow
                this.handleNavtoIssueSelectionTab()
              } else if ([63].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))) {
                this.props.setglobalLoaderStatus(true)
                let requestObject = {
                  ServiceTypeID: _.get(this, 'props.request.selectedMode.ServiceTypeID'),
                }
                this.props
                  .getPayoutModesAsync(requestObject)
                  .then(() => {
                    browserHistory.push({
                      pathname: '/request/paymentmodes',
                      query: {
                        ...this.props.location.query,
                        step: 2,
                        type: this.props.location.query.type,
                      },
                    })
                  })
                  .finally(() => {
                    this.props.setglobalLoaderStatus(false)
                  })
              } else {
                this.navToConfirmationPage()
              }
            }
          })
        } else {
          browserHistory.push({
            pathname: '/request/slots',
            query: {
              ...this.props.location.query,
              step: 2,
            },
          })
        }
      }
    )
  }

  isPostalCode = (keyName) => {
    const postalCodeKeyNames = ['PinCode', 'pinCode', 'pincode', 'ZipCode', 'zipCode', 'zipcode']
    return postalCodeKeyNames.includes(keyName)
  }

  addressStringBucket = async () => {
    let addressConfig = _.get(this, 'props.user.countryAddressConfig.addressConfig', fallbackAddressConfig)
    addressConfig = await sortedAddressConfig(addressConfig)
    addressConfig = await addressConfig.sort((a, b) => {
      return a.addressPreviewOrder - b.addressPreviewOrder
    })

    let array_1 = []
    let isLandmark = !!_.get(this, 'props.request.userLocation')['Landmark']
    let isUserLandmark = !!_.get(this, 'props.request.userLocation')['UserLandmark']
    await addressConfig.map((definedConifg) => {
      Object.keys(_.get(this, 'props.request.userLocation')).map((addressValue) => {
        if (
          !!_.get(this, 'props.request.userLocation')[addressValue] &&
          !array_1.includes(_.get(this, 'props.request.userLocation')[addressValue])
        ) {
          if (
            definedConifg['keyName'] == addressValue ||
            definedConifg['keyName'].toLowerCase() == addressValue.toLowerCase() ||
            (this.isPostalCode(definedConifg['keyName']) && this.isPostalCode(addressValue))
          ) {
            array_1.push(_.get(this, 'props.request.userLocation')[addressValue])
          }
        }
      })
    })

    if (isLandmark && isUserLandmark) {
      array_1.splice(0, 1)
    }
    await this.props.setAddressPreviewDetails({
      key: 'displayUserAddress',
      value: array_1,
    })

    let array_2 = []
    let isDeliveryLandmark = !!_.get(this, 'props.request.deliveryLocation')['Landmark']
    let isDeliveryUserLandmark = !!_.get(this, 'props.request.deliveryLocation')['UserLandmark']
    if (
      _.includes([1, 11, 17, 23, 31, 57, 58, 52], parseInt(this.props.request.selectedMode.ServiceTypeID)) &&
      !_.isEmpty(this, 'props.request.deliveryLocation')
    ) {
      addressConfig.map((definedConifg) => {
        Object.keys(_.get(this, 'props.request.deliveryLocation')).map((addressValue) => {
          if (
            !!_.get(this, 'props.request.deliveryLocation')[addressValue] &&
            !array_2.includes(_.get(this, 'props.request.deliveryLocation')[addressValue])
          ) {
            if (definedConifg['keyName'] == addressValue || definedConifg['keyName'].toLowerCase() == addressValue.toLowerCase()) {
              array_2.push(_.get(this, 'props.request.deliveryLocation')[addressValue])
            }
          }
        })
      })
      if (isDeliveryLandmark && isDeliveryUserLandmark) {
        array_2.splice(0, 1)
      }
      await this.props.setAddressPreviewDetails({
        key: 'displayDeliveryAddress',
        value: array_2,
      })
    }

    if (Object.keys(_.get(this, 'props.request.addrValidationDetails.suggestionObject', {}))) {
      let array_3 = []
      addressConfig.map((definedConifg) => {
        Object.keys(_.get(this, 'props.request.addrValidationDetails.suggestionObject', {})).map((addressValue) => {
          if (
            !!_.get(this, 'props.request.addrValidationDetails.suggestionObject')[addressValue] &&
            !array_3.includes(_.get(this, 'props.request.deliveryLocation')[addressValue])
          ) {
            if (definedConifg['keyName'] == addressValue || definedConifg['keyName'].toLowerCase() == addressValue.toLowerCase()) {
              array_3.push(_.get(this, 'props.request.addrValidationDetails.suggestionObject')[addressValue])
            }
          }
        })
      })
      await this.props.setAddressPreviewDetails({
        key: 'recommendedAddress',
        value: array_3,
      })
    }
  }

  handleReplacement = async () => {
    let PartnerID = [46].includes(_.get(this, 'props.request.selectedMode.ServiceTypeID'))
      ? _.get(this, 'props.request.requestModesData.replacementDetails.PartnerID')
      : _.get(this, 'props.request.requestModesData.advanceExchangeDetails.PartnerID')
    let selectedPlan = this.getSelectedPlan()
    let requestObject = {
      CountryID: _.get(this, 'props.user.selectedCountryData.CountryID'),
      PartnerID,
      ConsumerProductID: this.props.request.selectedDeviceInfo.ConsumerProductID,
      ServiceTypeID: _.get(this, 'props.request.selectedMode.ServiceTypeID'),
      PlanID: this.props.request.selectedDamageType.PlanID,
      PlanCoverageID: (selectedPlan && selectedPlan.PlanCoverageID) || _.get(this, 'props.track.trackDetails.PlanCoverageID', undefined),
    }
    this.props.setglobalLoaderStatus(true)
    this.props
      .getReplacementOptionsAsync(requestObject)
      .then(() => {
        if (_.isEmpty(this.props.request.replacementMatrixDetails)) {
          this.setState({ nextDayReplacementModal: true })
        } else if (this.props.request.replacementMatrixDetails[0].AllocationType == 'ALTERNATE') {
          let updatedReplacementOption = this.props.request.replacementMatrixDetails.map((option) => ({ ...option, isSelected: false }))
          this.props.setReplacementMatrixDetails(updatedReplacementOption)
          browserHistory.push({
            pathname: '/request/choose-replacement',
            query: this.props.location.query,
          })
        } else if (['EXACT', 'LIKE-TO-LIKE'].includes(this.props.request.replacementMatrixDetails[0].AllocationType)) {
          this.props.setReplacementOption(this.props.request.replacementMatrixDetails[0])
          this.handleContinue()
        } else if (this.props.request.replacementMatrixDetails[0].AllocationType == 'UPGRADE') {
          this.props.setReplacementOption(this.props.request.replacementMatrixDetails[0])
          this.setState({
            replacementUpgradeModal: true,
          })
        } else if (!_.isEmpty(this.props.request.replacementMatrixDetails)) {
          this.props.setReplacementOption(this.props.request.replacementMatrixDetails[0])
          this.handleContinue()
        }
      })
      .catch()
      .finally(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  noStoreReplacementOption = () => {
    this.setState({
      showNoStoreOptionModal: true,
    })
  }

  handleSameDayReplacement = async () => {
    let PartnerID = _.get(this, 'props.request.requestModesData.replacementDetails.PartnerID')
    let selectedDeviceList = this.getselectedDevicesList()
    let requestObject = {
      CountryID: _.get(this, 'props.user.selectedCountryData.CountryID'),
      PartnerID,
      PlanID: this.props.request.selectedDamageType.PlanID,
      PlanCoverageID:
        _.get(this, 'props.track.trackDetails.PlanCoverageID', undefined) ||
        _.get(this, 'props.request.selectedDamageType.PlanCoverageID', undefined),
      SoldPlanCoverageID:
        _.get(this, 'props.track.trackDetails.SoldPlanCoverageID', undefined) ||
        _.get(this, 'props.request.selectedDamageType.SoldPlanCoverageID', undefined),
      Category: _.get(this, 'props.location.query.type', 'repair').toLowerCase(),
      Zipcode: this.props.request.userLocation.pincode,
      Radius: 80,
      Partnered: 1,
      State: _.get(this, 'props.request.userLocation.state', this.state.selectedState.State),
      StateID: _.get(
        this,
        'props.request.userLocation.StateID',
        (this.state.selectedState && this.state.selectedState.StateID) || this.state.selectedAddressStateID
      ),
      isInternalModule: false,
      ProductID: selectedDeviceList[0].ProductID,
      ConsumerProductID: selectedDeviceList[0].ConsumerProductID,
      ProductSubCategoryID: selectedDeviceList[0].ProductSubCategoryID,
      BrandID: selectedDeviceList[0].BrandID,
      SoldPlanID:
        _.get(this, 'props.track.trackDetails.SoldPlanID', undefined) ||
        _.get(this, 'props.request.selectedDamageType.SoldPlanID', undefined),
      ServiceTypeID: _.get(this, 'props.request.selectedMode.ServiceTypeID'),
      serviceLocations: _.get(this, 'props.request.requestModesData.collectionLocations', undefined),
    }
    this.props.setglobalLoaderStatus(true)
    this.props
      .getSameDayReplacementOptionsAsync(requestObject)
      .then(() => {
        if (_.isEmpty(this.props.request.replacementMatrixDetails)) {
          this.noStoreReplacementOption()
        } else if (_.isEmpty(this.props.request.replacementMatrixDetails[0].ServiceLocations)) {
          this.noStoreReplacementOption()
        } else if (this.props.request.replacementMatrixDetails[0].AllocationType == 'ALTERNATE') {
          let updatedReplacementOption = this.props.request.replacementMatrixDetails.map((option) => ({ ...option, isSelected: false }))
          this.props.setReplacementMatrixDetails(updatedReplacementOption)
          browserHistory.push({
            pathname: '/request/choose-replacement',
            query: this.props.location.query,
          })
        } else if (['EXACT', 'LIKE-TO-LIKE'].includes(this.props.request.replacementMatrixDetails[0].AllocationType)) {
          this.props.setReplacementOption(this.props.request.replacementMatrixDetails[0])
          this.handleContinue()
        } else if (this.props.request.replacementMatrixDetails[0].AllocationType == 'UPGRADE') {
          this.props.setReplacementOption(this.props.request.replacementMatrixDetails[0])
          this.setState({
            replacementUpgradeModal: true,
          })
        } else if (!_.isEmpty(this.props.request.replacementMatrixDetails)) {
          this.props.setReplacementOption(this.props.request.replacementMatrixDetails[0])
          this.handleContinue()
        }
      })
      .catch()
      .finally(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  handleOptionSelection = async (selectedOption) => {
    let replacementOptions = this.props.request.replacementMatrixDetails
    replacementOptions = replacementOptions.map((option) => {
      if (option.index == selectedOption.index) {
        return { ...option, isSelected: true }
      }
      return { ...option, isSelected: false }
    })
    this.props.setReplacementOption(selectedOption)
    this.props.setReplacementMatrixDetails(replacementOptions)
  }

  handleVoiceRecorder = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        this.setState(
          {
            startVoiceRecorder: true,
            savedVoiceRecorder: false,
          },
          () => {
            this.voiceRecorderCallingFuction(stream)
          }
        )
      })
      .catch(() => {
        this.setState({
          startVoiceRecorder: false,
          savedVoiceRecorder: false,
        })
      })
  }

  voiceRecorderCallingFuction = (stream) => {
    window.rec = new MediaRecorder(stream)
    window.rec.start()
    let audioChunks = []
    window.rec.ondataavailable = (e) => {
      audioChunks.push(e.data)
      if (rec.state == 'inactive') {
        let blob = new Blob(audioChunks, {
          type: 'audio/mp3',
        })
        let recordedAudio = document.getElementById('recordedAudio')
        recordedAudio.src = URL.createObjectURL(blob)
        recordedAudio.controls = true
        recordedAudio.autoplay = false
        this.sendVoiceRecordedData(blob)
      }
    }
  }

  sendVoiceRecordedData = (blob) => {
    let file = new File([blob], 'voiceRecording.mp3', {
      type: 'audio/mp3',
    })
    window.useDocService ? this.handleUploadAdditionalDocsToDS(file) : this.handleAdditionalUploads(file)
  }

  stopVoiceRecorderHandler = () => {
    this.setState({
      startVoiceRecorder: false,
      savedVoiceRecorder: true,
    })
    window.rec.stop()
  }

  deleteVoiceRecorderHandler = () => {
    this.setState(
      {
        savedVoiceRecorder: false,
      },
      () => {
        let recordedAudio = document.getElementById('recordedAudio')
        recordedAudio.controls = false
        recordedAudio.autoplay = false
        this.props.setAudioUploadData({})
      }
    )
  }

  playUploadedAudio = () => {
    let recordedAudio = document.getElementById('recordedAudio')
    recordedAudio.controls = true
    recordedAudio.autoplay = false
    const audio = new Audio(_.get(this, 'props.request.audioUploadInfo.streamUrl', ''))
    audio.play()
  }

  toggleAffidavitClaimCheck = () => {
    this.setState(
      {
        lossAffidavitConsentCheck: !this.state.lossAffidavitConsentCheck,
      },
      () => {
        let claimformInfo = { ...this.state.claimformInfo }
        claimformInfo['lossConsentCheck'] = true
        this.setState({ claimformInfo }, () => {
          this.props.setClaimDetails(this.state.claimformInfo)
          const defaultLegalConfigs = this.props.user.claimLegalConfigs?.LegalConfigs.map((config) => {
            if (config?.legalConfig?.LegalConfigCode == 'ACKNOWLEDGEMENT') {
              return config
            }
            if (config?.ConsentOptional == false) {
              return config
            } else {
              return {
                ...config,
                Active: false,
              }
            }
          })
          this.props.setClaimLegalConfigs({ LegalConfigs: defaultLegalConfigs })
        })
      }
    )
  }

  toggleRepairStandardCharges = () => {
    this.setState({
      showStandardChargeModal: true,
    })
  }

  handleModeSelectionContinue = () => {
    const isWalkintMode = [2, 12].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
    let selectedPlan = this.getSelectedPlan()
    const isSameDayReplacementMode = [66].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
    const isDeviceReplacementMode = [46, 65].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
    const showReplacementOption = _.get(this, 'props.user.appConfigDetails.showReplacementOption', false)
    if (isWalkintMode && selectedPlan?.SoldPlanID) {
      this.getServiceAvailabilityResolution()
    } else if (showReplacementOption) {
      if (isDeviceReplacementMode) {
        this.handleReplacement()
      } else if (isSameDayReplacementMode) {
        this.handleSameDayReplacement()
      } else {
        this.handleContinue()
      }
    } else {
      this.handleContinue()
    }
  }

  getServiceAvailabilityResolution = () => {
    this.props.setglobalLoaderStatus(true)
    const serviceLocations = _.get(this, 'props.request.requestModesData.serviceLocations', [])
    const serviceLocationsUpdatedData = serviceLocations.map((value) => {
      return { ClientServiceLocationID: value.ClientServiceLocationID || null, PartnerServiceLocationID: value.PartnerServiceLocationID }
    })
    let requestObject = {
      countryID: _.get(this, 'props.user.selectedCountryData.CountryID'),
      serviceLocations: serviceLocationsUpdatedData,
      brandID: _.get(this, 'props.request.selectedDeviceInfo.BrandID') || _.get(this, 'props.track.trackDetails.ConsumerProduct.BrandID'),
      soldPlanID:
        _.get(this, 'props.track.trackDetails.SoldPlanID', undefined) ||
        _.get(this, 'props.request.selectedDamageType.SoldPlanID', undefined),
    }
    // const serviceLocationsUpdatedData = serviceLocations && serviceLocations.map(serviceLocationsData => _.pick(serviceLocationsData,['ClientServiceLocationID', 'PartnerServiceLocationID']))
    this.props
      .getServiceAvailabilityResolutionRateAsync(requestObject)
      .then(() => {})
      .finally(() => {
        this.handleContinue()
        this.props.setglobalLoaderStatus(false)
      })
  }

  handleQuestionOptionSelect = async (dataObject, e) => {
    let selectedMatrix = e.currentTarget.id.split('-')
    await this.setState({
      questionOption: this.state.questionOption.map((value, index) => {
        if (index == selectedMatrix[0]) {
          value.disclaimerOptions.map((v, i) => {
            if (i == selectedMatrix[1]) {
              Object.assign(v, { isSelected: true })
            } else {
              Object.assign(v, { isSelected: false })
            }
          })
        }
        return value
      }),
    })

    let updatedQuestionOption = this.state.questionOption
    updatedQuestionOption = updatedQuestionOption.map((disclaimer) => {
      let value
      disclaimer.disclaimerOptions.map((disclaimerValue, dIndex) => {
        if (disclaimerValue.isSelected == true) {
          value = {
            disclaimerID: disclaimer.disclaimerID,
            optionID: dIndex + 1,
          }
        } else {
          return
        }
      })
      return value
    })
    await this.setState({
      recievedDisclaimerOption: updatedQuestionOption.filter(Boolean),
    })
  }

  handleSubmitProductDisclaimer = () => {
    const rules = _.get(this, 'props.user.appConfigDetails.productDisclaimer.rules', [])
    this.setState({ showSerialQuestionModal: false }, () => {
      let intersection = _.isEqual(rules, this.state.recievedDisclaimerOption)
      if (intersection) {
        this.setState({
          showDiscalimerContactUsModal: true,
        })
        return
      } else {
        this.postClaimCoverageSelection(false)
      }
    })
  }

  closeProductDisclaimer = async () => {
    await this.setState({
      showSerialQuestionModal: false,
    })
  }

  closeDiscalimerContactUsModal = () => {
    this.setState({
      showDiscalimerContactUsModal: false,
    })
  }

  render() {
    let renderDom = null
    let reqType = _.get(this, 'props.location.query.type')
    let params = this.props.params.p
    let navTabText = getTranslationText('request', 'selectDevices')
    const isContinueStateSelectionDisabled = Object.keys(this.state.selectedState).length > 0
    const isContinueDistrictSelectionDisabled = Object.keys(this.state.selectedDistrict).length > 0
    const locale = _.get(this, 'props.user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
    const currencyCode = _.get(this, 'props.user.selectedCountryData.CurrencyCode')
    let isDropOffMode = [17, 23].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
    let isAdvanceExchangeMode = [65].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
    switch (params) {
      case 'select-device': {
        renderDom = (
          <SelectDeviceComponent
            {...this.props}
            isNewDevice={this.state.isNewDevice}
            devicelist={this.props.devices.deviceDetails?.supportedDevices}
            newDeviceData={this.state.newDeviceData}
            handleDeviceSelection={this.handleDeviceSelection}
            mobileCategory={this.state.mobileCategory}
            setSelectedDeviceID={this.setSelectedDeviceID}
            deviceInfo={this.state.selectedDeviceData}
            btnStatus={this.state.selectFrombtnStatus}
            listofProducts={this.state.listofProducts}
            listboxdata={this.state.addingDeviceslistbox}
            catergoryListHandler={this.catergoryListHandler}
            brandListHandler={this.brandListHandler}
            productListHandler={this.productListHandler}
            handleNewDeviceInputChange={this.handleNewDeviceInputChange}
            handleBackButton={this.handleBackButton}
            deviceRadiobtnvalue={this.state.deviceRadiobtnvalue}
            isAnAppleDevice={this.state.isAnAppleDevice}
            listofSupportedModes={this.state.listofSupportedModes}
            uniqueIdrequired={this.state.uniqueIdrequired}
            BtnStatusLoader={this.state.BtnStatusLoader}
            fetchingProductLoader={this.state.fetchingProductLoader}
            disableNormalRepair={this.state.disableNormalRepair}
            isInvalidIMEI={this.state.isInvalidIMEI}
            handleDatePickerOnChange={this.handleDatePickerOnChange}
            toggleImeiModal={this.toggleImeiModal}
            newDeviceDocsList={this.state.newDeviceDocsList}
            setDocViewerObject={this.setDocViewerObject}
            productBrandConfig={_.get(this, 'props.devices.productBrandConfig')}
            isInvoiceUploaded={this.state.isInvoiceUploaded}
            toggleOrdersInstr={this.toggleOrdersInstr}
            handleInvoiceUpload={this.handleInvoiceUpload}
            uploadedInvoiceImgFlg={this.state.uploadedInvoiceImgFlg}
          />
        )
        break
      }
      case 'select-order': {
        navTabText = getTranslationText('request', 'selectProducts')
        let devicesList =
          this.props.devices.deviceDetails.supportedDevices.filter((device) => device.showReplacementOption || device.showRefundOption) ||
          []
        if (devicesList.length && ['refund', 'replacement'].includes(reqType)) {
          renderDom = <OrderListComponent {...this.props} handleBackButton={this.handleBackButton} initOrderQuery={this.initOrderQuery} />
        } else {
          renderDom = <div />
          browserHistory.replace('/')
        }
        break
      }
      case 'select-reason':
        navTabText = getTranslationText('request', 'returnDetails')
        renderDom = (
          <ReturnOrderInfo
            {...this.props}
            setDocViewerObject={this.setDocViewerObject}
            handleBackButton={this.handleBackButton}
            handleInvoiceUpload={this.handleInvoiceUpload}
            selectedOrderDetails={this.state.selectedOrderDetails}
          />
        )
        break
      case 'plan':
        navTabText =
          this.props.location.query.step && this.props.location.query.step === '2'
            ? getTranslationText('request', 'activatePlan')
            : getTranslationText('request', 'selectPlanType')
        renderDom = (
          <PlanHandlingTab
            {...this.props}
            selectedPlanID={this.state.selectedPlanID}
            handleDamageTypeSelect={this.handleDamageTypeSelect}
            handlePlanActivationSelect={this.handlePlanActivationSelect}
            handlePlanActivationChange={this.handlePlanActivationChange}
            handleBackButton={this.handleBackButton}
            handleContinue={this.handleContinue}
            setDefaultDeviceData={this.setDefaultDeviceData}
            handleBackPropogation={this.handleBackPropogation}
            needToActivatePlanFlg={this.props.location.query.step && this.props.location.query.step === '2'}
            enableActivation={this.state.enableActivation}
            activationPageDetails={this.state.activationPageDetails}
            disableNormalRepair={this.state.disableNormalRepair}
            handleDatePickerOnChange={this.handleDatePickerOnChange}
            openActivationInvoiceImageViewer={this.openActivationInvoiceImageViewer}
            BtnStatusLoader={this.state.BtnStatusLoader}
          />
        )
        break
      case 'claim-form':
        navTabText = getTranslationText('request', 'claimForm')
        if (_.isEmpty(this.props.request.selectedDamageType)) {
          renderDom = <div />
          browserHistory.replace('/')
        } else {
          renderDom = (
            <ClaimFormComponent
              handleClaimInputs={this.handleClaimInputs}
              {...this.props}
              claimformInfo={this.state.claimformInfo}
              ClaimRaiseBtnLoader={this.state.ClaimRaiseBtnLoader}
              validateClaimForm={this.validateClaimForm}
              phnoLengthAr={this.state.phnoLengthAr}
              handleDatePickerOnChange={this.handleDatePickerOnChange}
              handleBackButton={this.handleBackButton}
              handleContinue={this.handleContinue}
              setDefaultDeviceData={this.setDefaultDeviceData}
              handleBackPropogation={this.handleBackPropogation}
              invoiceDocAlreadyAdded={this.state.ClaimFormDocState}
              uploadedInvoiceImg={this.state.uploadedInvoiceImg}
              uploadedInvoiceImgFlg={this.state.uploadedInvoiceImgFlg}
              handleInvoiceUpload={this.handleInvoiceUpload}
              ViewUploadInvoice={this.ViewUploadInvoice}
              ViewUploadedPoliceReport={this.ViewUploadedPoliceReport}
              handleTypeOfDamageSelect={this.handleTypeOfDamageSelect}
              handleRepairInvoiceUpload={this.handleRepairInvoiceUpload}
              invalidFormat={this.state.invalidFormat}
              viewRepairInvoice={this.viewRepairInvoice}
              showClaimSuccessModal={this.state.showClaimSuccessModal}
              handlePoliceReportUpload={this.handlePoliceReportUpload}
              showDamageDescError={this.state.showDamageDescError}
              showTheftDetailsError={this.state.showTheftDetailsError}
              showLossDetailsError={this.state.showLossDetailsError}
              startVoiceRecorder={this.state.startVoiceRecorder}
              savedVoiceRecorder={this.state.savedVoiceRecorder}
              handleVoiceRecorder={this.handleVoiceRecorder}
              stopVoiceRecorderHandler={this.stopVoiceRecorderHandler}
              playUploadedAudio={this.playUploadedAudio}
              deleteVoiceRecorderHandler={this.deleteVoiceRecorderHandler}
              incidentTypeSelection={this.incidentTypeSelection}
              onChangeTypeahead={(address) => {
                this.onChangeTypeahead(address, 'claimForm')
              }}
              handleTypeaheadSelect={(address) => {
                this.handleTypeaheadSelect(address, 'userLocation', 'claimForm')
              }}
              placesAutocomplete={{
                options: {
                  ...this.state.placesAutocompleteOptions,
                  componentRestrictions: {
                    country: _.get(this, 'props.user.selectedCountryData.CountryCode', 'IN').toLowerCase(),
                  },
                },
                landmark: this.state.landmark,
              }}
              toggleLossConsentCheck={this.toggleLossConsentCheck}
              validateAffidavit={this.validateAffidavit}
            />
          )
        }
        break
      case 'modes':
        navTabText =
          this.state.modeSelectStep === 2 ? getTranslationText('request', 'locations') : getTranslationText('request', 'selectModes')
        renderDom = (
          <RequestModes
            {...this.props}
            handleModeSelectionContinue={this.handleModeSelectionContinue}
            toggleRepairStandardCharges={this.toggleRepairStandardCharges}
            ref={this.handleRefModes}
            selectServiceLocation={this.selectServiceLocation}
            selectedPartnerServiceLocation={this.state.selectedPartnerServiceLocation}
            handleContinue={this.handleContinue}
            placesAutocomplete={{
              options: {
                ...this.state.placesAutocompleteOptions,
                componentRestrictions: {
                  country: _.get(this, 'props.user.selectedCountryData.CountryCode', 'IN').toLowerCase(),
                },
              },
              landmark: this.state.landmark,
            }}
            modeSelectStep={this.state.modeSelectStep}
            onChangeTypeahead={this.onChangeTypeahead}
            handleTypeaheadSelect={(address) => {
              this.handleTypeaheadSelect(address, 'userLocation')
            }}
            handleDetectClick={this.handleDetectClick}
            selectedMode={this.state.selectedMode}
            locationObtained={this.state.locationObtained}
            handleModeSelect={this.handleModeSelect}
            handleBackButton={this.handleBackButton}
            requestModeListLoader={this.state.requestModeListLoader}
            handleUserAddressClick={this.handleUserAddressClick}
            handleDropOffItemClick={this.handleDropOffItemClick}
            setDefaultDeviceData={this.setDefaultDeviceData}
            handleBackPropogation={this.handleBackPropogation}
            BtnStatusLoader={this.state.BtnStatusLoader}
            toggleLogisticsInstructions={this.toggleLogisticsInstructions}
            isLoaderSet={this.state.isLoaderSet}
          />
        )
        break
      case 'slots':
        navTabText =
          this.state.slotSelectStep === 1
            ? [46, 65].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
              ? getTranslationText('request', 'deliveryAddressDetails')
              : [17, 23].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
              ? `${getTranslationText('common', 'returnAddress')} (${getTranslationText('request', 'returnAddressSubTitle')})`
              : `${getTranslationText('common', 'address')} (${getTranslationText('request', 'returnAddressSubTitle')})`
            : getTranslationText('common', 'date')
        renderDom = (
          <SlotsSelectionScreen
            {...this.props}
            ref={this.handleRefSlots}
            datesToShow={this.state.datesToShow}
            newAddressFlg={this.state.newAddressFlg}
            newDeliveryAddressFlg={this.state.newDeliveryAddressFlg}
            timesToShow={this.state.timesToShow}
            handleDateSelect={this.handleDateSelect}
            addressType={this.state.addressType}
            deliveryAddressType={this.state.deliveryAddressType}
            handleNewInputAddress={this.handleNewInputAddress}
            onChangeStateSelection={this.onChangeStateSelection}
            selectedState={this.state.selectedState}
            handleNewDeliveryInputAddress={this.handleNewDeliveryInputAddress}
            partnerServiceLocation={this.props.request.partnerServiceLocation}
            newAddress={this.state.newAddress}
            deliveryAddress={this.state.deliveryAddress}
            toggleSameDeliveryAddressCheck={this.toggleSameDeliveryAddressCheck}
            sameDeliveryAddressCheck={this.state.sameDeliveryAddressCheck}
            selectedMode={this.state.selectedMode}
            handleAddressType={this.handleAddressType}
            handleDeliveryAddressType={this.handleDeliveryAddressType}
            handleTimeSelect={this.handleTimeSelect}
            handleBackButton={this.handleBackButton}
            handleContinue={this.handleContinue}
            dropoffDirections={this.dropoffDirections}
            disableContinue={!this.state.timesToShow.filter((time) => time.isSelected)[0]}
            setDefaultDeviceData={this.setDefaultDeviceData}
            handleBackPropogation={this.handleBackPropogation}
            BtnStatusLoader={this.state.BtnStatusLoader}
            placesAutocomplete={{
              options: {
                ...this.state.placesAutocompleteOptions,
                componentRestrictions: {
                  country: _.get(this, 'props.user.selectedCountryData.CountryCode', 'IN').toLowerCase(),
                },
              },
              landmark: this.state.deliveryLandmark,
            }}
            slotSelectStep={this.state.slotSelectStep}
            onChangeDeliveryTypeahead={this.onChangeDeliveryTypeahead}
            handleTypeaheadSelect={(address) => {
              this.handleTypeaheadSelect(address, 'deliveryLocation')
            }}
            handleDetectClick={this.handleDetectClick}
            handleDeliveryDetectClick={this.handleDeliveryDetectClick}
            handleDeliveryAddressClick={this.handleDeliveryAddressClick}
            locationObtained={this.state.deliveryLocationObtained}
            landmark={this.state.landmark}
            isEditAddress={this.state.isEditAddress}
            isEditAddressFlag={this.state.isEditAddressFlag}
            isLoaderSet={this.state.isLoaderSet}
            isServiceable={this.state.isServiceable}
            userLocation={this.props.request.userLocation}
          />
        )
        break
      case 'select-issue':
        navTabText = getTranslationText('request', 'selectIssues')
        renderDom = (
          <IssueSelection
            {...this.props}
            uploadedInvoiceImg={this.state.uploadedInvoiceImg}
            uploadedInvoiceImgFlg={this.state.uploadedInvoiceImgFlg}
            issuesForType={this.state.issuesForType}
            handleIssueSelect={this.handleIssueSelect}
            handleInvoiceUpload={this.handleInvoiceUpload}
            handleContinue={this.handleContinue}
            handleBackButton={this.handleBackButton}
            ViewUploadInvoice={this.ViewUploadInvoice}
            ViewAddtionallyUploadedDocs={this.ViewAddtionallyUploadedDocs}
            setDefaultDeviceData={this.setDefaultDeviceData}
            additionalUpload={this.state.additionalUpload}
            handleBackPropogation={this.handleBackPropogation}
            handleAdditionalUploads={this.handleAdditionalUploads}
            BtnStatusLoader={this.state.BtnStatusLoader}
            showOtherInput={this.state.showOtherInput}
            otherInputText={this.state.otherInputText}
            handleOtherIssue={this.handleOtherIssue}
            btnDisabled={this.state.btnDisabled}
            docAlreadyAdded={this.state.ClaimFormDocState}
            handleUploadAdditionalDocsToDS={this.handleUploadAdditionalDocsToDS}
          />
        )
        break
      case 'documents':
        navTabText = getTranslationText('request', 'uploadDocuments')
        renderDom = (
          <UploadDocuments
            {...this.props}
            handleInvoiceUpload={this.handleLargeFileUpload}
            handleContinue={this.handleContinue}
            handleBackButton={this.handleBackButton}
            handleBackPropogation={this.handleBackPropogation}
            BtnStatusLoader={this.state.BtnStatusLoader}
            btnDisabled={this.state.btnDisabled}
            docAlreadyAdded={this.state.ClaimFormDocState}
            uploadVideoFile={this.state.uploadVideoFile}
            viewUploadVideo={this.viewUploadVideo}
          />
        )
        break
      case 'paymentmodes':
        navTabText = 'Payment Modes'
        renderDom = (
          <PaymentModes
            {...this.props}
            handleContinue={this.handleContinue}
            handleBackButton={this.handleBackButton}
            handleBackPropogation={this.handleBackPropogation}
            BtnStatusLoader={this.state.BtnStatusLoader}
            btnDisabled={this.state.btnDisabled}
            buyOutInputHandler={this.buyOutInputHandler}
            buyOutFormInfo={this.state.buyOutFormInfo}
            phnoLengthAr={this.state.phnoLengthAr}
          />
        )
        break
      case 'confirm':
        navTabText = getTranslationText('request', 'reviewDetails')
        if (this.props.request.selectedMode) {
          renderDom = (
            <ConfirmDetails
              {...this.props}
              handleContinue={this.handleContinue}
              handleBackButton={this.handleBackButton}
              addressType={this.state.addressType}
              newAddress={this.state.newAddress}
              invalidFormat={this.state.invalidFormat}
              deliveryAddressType={_.get(this, 'props.request.deliveryLocation.AddressType', [])}
              deliveryAddress={_.get(this, 'props.request.deliveryLocation.Address', [])}
              setDefaultDeviceData={this.setDefaultDeviceData}
              handleBackPropogation={this.handleBackPropogation}
              BtnStatusLoader={this.state.BtnStatusLoader}
              handleMobileNo={this.handleMobileNo}
              handleEmailId={this.handleEmailId}
              handleAlternateMobile={this.handleAlternateMobile}
              mobileNo={this.state.mobileNo}
              emailId={this.state.emailId}
              alternateMobile={this.state.alternateMobile}
              toggleEditUsrDetails={this.toggleEditUsrDetails}
              userName={this.state.userName}
              isEditUsrDetails={this.state.isEditUsrDetails}
              handleEditDeliveryAdd={this.handleEditDeliveryAdd}
              ViewUploadInvoice={this.ViewUploadInvoice}
              cancelEditUserDetails={this.cancelEditUserDetails}
              ViewUploadedImage={this.ViewUploadedImage}
              phnoLengthAr={this.state.phnoLengthAr}
              saveBtnLoader={this.state.saveBtnLoader}
              handleUserName={this.handleUserName}
              saveUserDetails={this.saveUserDetails}
              showProofOfDamage={this.showProofOfDamage}
              additionalUpload={this.state.additionalUpload}
              selectedDeviceList={this.getselectedDevicesList()}
            />
          )
        } else {
          renderDom = <div />
          browserHistory.replace('/')
        }
        break
      case 'choose-replacement':
        navTabText = getTranslationText('track', 'chooseReplacement')
        renderDom = (
          <ChooseReplacementOption
            replacementOptions={this.props.request.replacementMatrixDetails}
            handleContinue={this.handleContinue}
            handleOptionSelection={this.handleOptionSelection}
          />
        )
        break
      case 'coverage-selection':
        navTabText = getTranslationText('request', 'selectCoverage')
        renderDom = (
          <CoverageSelection
            {...this.props}
            soldPlanCoverages={this.state.soldPlanCoverages}
            handleCoverageSelection={this.handleCoverageSelection}
            toggleClaimCoverageConsentCheck={this.toggleClaimCoverageConsentCheck}
            confirmCoverageSelection={this.confirmCoverageSelection}
            consentSelectedsoldPlanCoverages={this.state.consentSelectedsoldPlanCoverages}
          />
        )
        break
      default:
        browserHistory.replace('/pagenotfound')
    }
    return (
      <div className='mb48'>
        {this.state.componentMounted ? (
          <div className='row'>
            {navTabText.length > 0 && <RequestNavBar title={navTabText} />}
            <div className='col-sm-12 request-flow-content-holder'>{renderDom}</div>
          </div>
        ) : (
          <div className='row'>
            <div className='col-sm-12 request-flow-content-holder'>
              {this.state.globalLoaderFlg && (
                <div className='global-loader-class'>
                  <Loader isContentLoader />
                </div>
              )}
            </div>
          </div>
        )}
        {this.state.showClaimInstructions ? (
          <Modal className='sfy-modal claim-instructions-modal' showModal={this.state.showClaimInstructions}>
            <CustomIconComponent
              className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
              onClick={this.closeClaimInstructions}
            />
            <div className='claim-instructions'>
              <div className='custom-modal-title mb20 text-center boldFontStyle fontLarge'>
                {getTranslationText('request', 'claimInstructionsTitle')}
              </div>
              <div className='custom-modal-content'>
                {_.get(this, 'props.request.ClaimEligibilityList.soldPlan.claimIntro', []).map((instruction, index) => {
                  const planAmount = this.props.request.ClaimEligibilityList?.soldPlan?.planAmount
                  var createMarkup = function () {
                    return {
                      __html: linkify(
                        (instruction.htmlText || instruction.text || '').replace(
                          /@ADMIN_FEE/g,
                          getLocaleCurrency(locale, currencyCode, planAmount)
                        )
                      ),
                    }
                  }
                  return (
                    <div key={index} className='instruction mb8'>
                      {instruction.image && (
                        <div className='img mediumFontStyle'>
                          <img src={instruction.image} />
                        </div>
                      )}
                      <div className='text'>
                        <span dangerouslySetInnerHTML={createMarkup()} />
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className='center-align'>
                <MediumButton
                  className='mt20'
                  onClick={this.checkForIssueSelection}
                  buttonText={getTranslationText('common', 'continue')}
                />
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )}
        {this.state.isRepairAllowed ? (
          <Modal className='sfy-modal empty-fields-modal' showModal={this.state.isRepairAllowed}>
            <div className='modal-cross-icon'>
              <CustomIconComponent
                className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
                onClick={this.closeIsRepairAllowedModel}
              />
            </div>
            <img className='modal-icon-width' src={informationAlert} />
            <div className='text-center boldFontStyle fontLarge mt20 m10'>{getTranslationText('common', 'customerSupport')}</div>
            <div className='row'>
              <div className='col-sm-12 mb20'>
                {'Please contact Support Team on'}&nbsp;
                <span
                  className='modalTextLink'
                  onClick={() => {
                    window.location.href = 'mailto:' + _.get(this, 'props.user.appConfigDetails.footerLinks.support.email')
                  }}
                >
                  {_.get(this, 'props.user.appConfigDetails.footerLinks.support.email')}
                </span>
                &nbsp;or <span className='modalText'>{_.get(this, 'props.user.appConfigDetails.CallCenterNumber')}</span>&nbsp;
                {'to help you raise your request'}
              </div>
              <div className='col-sm-12 center-align'>
                <MediumButton buttonText={getTranslationText('common', 'okay')} onClick={this.closeIsRepairAllowedModel} />
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )}
        {this.state.showLogisticPartners && (_.get(this, 'state.logisticPartnerList', []).length || this.state.vendorInfo) ? (
          <Modal className='sfy-modal logistic-partners-list' showModal={this.state.showLogisticPartners}>
            <div className='row'>
              <div className='col-sm-10 col-10'>
                <Heading
                  title={
                    this.props.request.requestModesData.logisticPartner.isDedicatedDialog
                      ? getTranslationText('request', 'assistedPickup')
                      : getTranslationText('request', 'selectLogistics')
                  }
                />
              </div>
              <CustomIconComponent
                className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
                onClick={this.closeLogisticModal}
              />
              <div className='col-sm-12 mt20 mb20'>
                {this.props.request.requestModesData.logisticPartner.isDedicatedDialog ? (
                  <div>
                    <div className='boldFontStyle fontSmall mb8'>{this.state.vendorInfo.DisplayName}</div>
                    <div className='bookFontStyle fontSmall mb8'>{this.state.vendorInfo.Remarks}</div>
                  </div>
                ) : (
                  this.state.logisticPartnerList.map((data) => {
                    return (
                      <div
                        key={'vendorId-' + data.VendorID}
                        className='logistic-partner'
                        id={'vendorId-' + data.VendorID}
                        onClick={() => this.handleLogisticPartnerSelection(data.VendorID)}
                      >
                        <div>
                          <Radio
                            classes='radio-styling'
                            value={data.VendorName}
                            name='switiching-on'
                            selectedValue={data.isSelected && data.VendorName}
                            handleOnUpdate={() => this.handleLogisticPartnerSelection(data.VendorID)}
                          />
                        </div>
                        <div className='logistic-partner-info'>
                          <div className='boldFontStyle fontMedium'>{data.DisplayName}</div>
                          <div className='bookFontStyle fontSmall'>{data.Remarks}</div>
                        </div>
                      </div>
                    )
                  })
                )}
              </div>
              <div className='col-sm-12 text-center'>
                <MediumButton
                  buttonText={getTranslationText('common', 'confirm')}
                  onClick={this.LogisticPartnerConfirmation}
                  disabled={
                    this.props.request.requestModesData.logisticPartner.isDedicatedDialog
                      ? false
                      : !this.state.logisticPartnerList.find((data) => data.isSelected)
                  }
                />
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )}
        {(/slots/.test(this.props.location.pathname) || /select-issue/.test(this.props.location.pathname)) &&
        this.state.showPickUpInstructionsModal ? (
          <Modal className='sfy-modal pickup-instruction-modal' showModal={this.state.showPickUpInstructionsModal}>
            <div className='text-center boldFontStyle fontLarge mb20'>{getTranslationText('common', 'instructions')}</div>
            <div className='row mb28'>
              <div className='col-10'>
                <div className='boldFontStyle fontMedium mt20'>{getTranslationText('request', 'instruction')} </div>
              </div>
              <CustomIconComponent
                className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
                onClick={this.closePickUPinstrModal}
              />
            </div>
            <div className='row'>
              {this.state.pickupInstructionValues.map((instr, index) => {
                return (
                  <div key={index} className='col-sm-12'>
                    <div className='instruction-holder mb20' id={'instr-' + index} onClick={this.setSelectedInstruction}>
                      <span>
                        <Checkbox type='checkbox' value={instr.isSelected} handleOnChange={this.setSelectedInstruction} />
                      </span>
                      <span>
                        <div className='boldFontStyle fontMedium'>{instr.title}</div>
                        <div className='bookFontStyle fontSmall'>
                          <span dangerouslySetInnerHTML={{ __html: instr.description }} />
                        </div>
                      </span>
                    </div>
                  </div>
                )
              })}
              <div className='col-sm-12 text-center'>
                <MediumButton
                  buttonText={getTranslationText('common', 'continue')}
                  onClick={() => {
                    this.navToConfirmationPage()
                    pushToAnalytics(
                      [17, 23].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
                        ? 'Return_address_instruction_pop_up_continue'
                        : 'Delivery_address_instruction_pop_up_continue',
                      {
                        ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
                        ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
                      }
                    )
                  }}
                  disabled={this.state.pickupInstructionValues.find((instr) => !instr.isSelected)}
                />
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )}
        {this.state.ClaimEligibilityModal ? (
          <Modal className='sfy-modal empty-fields-modal' showModal={this.state.ClaimEligibilityModal}>
            <div className='modal-cross-icon'>
              <CustomIconComponent
                className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
                onClick={this.closeClaimEligibilityModal}
              />
            </div>
            <img className='modal-icon-width' src={informationAlert} />

            <div className='text-center boldFontStyle fontLarge mt20 m10'>
              {this.props.request.ClaimEligibilityList.CanRaiseClaim
                ? this.props.request.ClaimEligibilityList.Title
                : getTranslationText('request', 'cannotRaiseRequest')}
            </div>
            <div className='row'>
              <div
                className='col-sm-12 mb20 text-center'
                dangerouslySetInnerHTML={{ __html: linkify(this.props.request.ClaimEligibilityList.Description) }}
              />
              <div className='col-sm-12 center-align'>
                <MediumButton
                  buttonText={
                    this.props.request.ClaimEligibilityList.CanRaiseClaim
                      ? this.props.request.ClaimEligibilityList.ButtonText
                      : getTranslationText('common', 'okay')
                  }
                  onClick={
                    this.props.request.ClaimEligibilityList.CanRaiseClaim ? this.openClaimInstructions : this.closeClaimEligibilityModal
                  }
                />
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )}
        {this.state.ClaimConsentModal ? (
          <Modal className='sfy-modal empty-fields-modal' showModal={this.state.ClaimConsentModal}>
            <div className='modal-cross-icon'>
              <CustomIconComponent
                className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
                onClick={() => {
                  this.closeClaimConsentModal(false)
                }}
              />
            </div>
            <img className='modal-icon-width' src={informationAlert} />
            <div className='text-center boldFontStyle fontLarge mt20 m10'>{getTranslationText('request', 'pleaseNote')}</div>

            <div className='row'>
              <div className='col-sm-12 mb20' dangerouslySetInnerHTML={{ __html: linkify(this.state.ClaimConsentText) }} />
              <div className='col-sm-12 center-align'>
                <MediumButton
                  className='button-ghost m20'
                  buttonText={getTranslationText('request', 'cancelClaim')}
                  onClick={() => {
                    this.closeClaimConsentModal(false)
                  }}
                />
                <MediumButton
                  buttonText={getTranslationText('request', 'continueWithClaim')}
                  onClick={() => {
                    this.closeClaimConsentModal(true)
                  }}
                />
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )}
        {this.state.showCoolingPeriodModal && (
          <Modal className='sfy-modal empty-fields-modal' showModal={this.state.showCoolingPeriodModal}>
            <div className='modal-cross-icon'>
              <CustomIconComponent
                className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
                onClick={this.closeCoolingPeriodModal}
              />
            </div>
            <img className='modal-icon-width' src={informationAlert} />
            <div className='text-center boldFontStyle fontLarge mt20 m10'>{`Coverage Status`}</div>

            <div className='row'>
              <div
                className='col-sm-12 mb20'
                dangerouslySetInnerHTML={{ __html: linkify('Coverage is under cooling period/Coverage is inactive') }}
              />
              <div className='col-sm-12 center-align'>
                <MediumButton buttonText={getTranslationText('common', 'okay')} onClick={this.closeCoolingPeriodModal} />
              </div>
            </div>
          </Modal>
        )}
        {this.state.showClaimErrorPopUp && (
          <Modal className='sfy-modal claim-instructions-modal' showModal={this.state.showClaimErrorPopUp}>
            <img className='modal-icon-width' src={errorAlert} />
            <div className='text-center boldFontStyle fontLarge mt20 m10'>{this.props.request.ClaimEligibilityList.Title}</div>
            <div dangerouslySetInnerHTML={{ __html: this.state.claimErrorResp.msg }} />
            <div className={'mt28 center-align'}>
              <MediumButton
                buttonText={getTranslationText('common', 'okay')}
                onClick={() => {
                  let csrid = this.state.claimErrorResp.data.ConsumerServiceRequestID
                  if (csrid) {
                    this.closeRepairSuccessModal(csrid)
                  } else {
                    browserHistory.replace('/')
                  }
                }}
              />
            </div>
          </Modal>
        )}
        {this.state.docViewerObj.viewUploadedDocs && this.state.docViewerObj.FilePath ? (
          <ImageViewerModal
            show={this.state.docViewerObj.viewUploadedDocs}
            className='Request-flow-docs-viewer'
            removeBtnStatus={/plan/.test(this.props.location.pathname) || this.state.docViewerObj.showRemoveBtn}
            deleteDocsHandler={this.deleteUploadedDoc}
            closeIconHandler={() => {
              this.unsetDocViewerObj(
                {},
                {
                  currentInvoiceDoc: undefined,
                }
              )
            }}
            imagePath={this.state.docViewerObj.FilePath}
          />
        ) : (
          ''
        )}

        {this.state.emptyBoxInstructionModal ? (
          <Modal className='sfy-modal claim-instructions-modal' showModal={this.state.showLogisticInstructions}>
            <CustomIconComponent
              className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
              onClick={() => this.closeModal('emptyBoxInstructionModal')}
            />
            <div className='row claim-instructions'>
              <div className='col-sm-12 custom-modal-title mb8'>
                <Heading title={getTranslationText('common', 'instructions')} />
              </div>
              <div className='col-sm-12 custom-modal-content'>
                <div className='mediumFontStyle fontSmall mb20'>
                  {_.get(emptyBoxInstruction, 'dropOffInstructions.DropOffInstructionsTitle')}
                </div>
                {emptyBoxInstruction.dropOffInstructions.DropOffInstructions.map((instruction) => {
                  return (
                    <div className='instruction mb8' key={instruction.index}>
                      {instruction.image && (
                        <div className='img'>
                          <img src={instruction.image} />
                        </div>
                      )}
                      <div className='text'>{instruction.text}</div>
                    </div>
                  )
                })}
              </div>
              <div className={'col-sm-12 mt20 center-align'}>
                <MediumButton buttonText={getTranslationText('common', 'okay')} onClick={(e) => this.toggleEmptyBoxInstructions(e, true)} />
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )}

        {this.state.showLogisticInstructions ? (
          <Modal className='sfy-modal claim-instructions-modal' showModal={this.state.showLogisticInstructions}>
            <CustomIconComponent
              className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
              onClick={this.closeLogisticInstructions}
            />
            <div className='row claim-instructions'>
              <div className='col-sm-12 text-center mb20 boldFontStyle fontLarge'>{getTranslationText('common', 'instructions')}</div>
              <div className='col-sm-12 custom-modal-content'>
                <div className='mediumFontStyle fontSmall mb20'>
                  {![17, 23, 57, 58].includes(_.get(this, 'props.request.selectedMode.ServiceTypeID')) &&
                    _.get(this, 'props.request.selectedVendor.VendorInstructions.InstructionTitle')}
                  {[17, 23, 57, 58].includes(_.get(this, 'props.request.selectedMode.ServiceTypeID')) &&
                    _.get(this, 'props.request.requestModesData.dropOffInstructions.DropOffInstructionsTitle')}
                </div>
                {![17, 23, 57, 58].includes(_.get(this, 'props.request.selectedMode.ServiceTypeID')) &&
                  _.get(this, 'props.request.selectedVendor.VendorInstructions') &&
                  this.props.request.selectedVendor.VendorInstructions.Instructions.map((instruction) => {
                    return (
                      <div className='instruction mb8' key={instruction.index}>
                        {instruction.image && (
                          <div className='img'>
                            <img src={instruction.image} />
                          </div>
                        )}
                        <div className='text'>{instruction.text}</div>
                      </div>
                    )
                  })}
                {[17, 23, 57, 58].includes(_.get(this, 'props.request.selectedMode.ServiceTypeID')) &&
                  _.get(this, 'props.request.requestModesData.dropOffInstructions.DropOffInstructionsTitle') &&
                  this.props.request.requestModesData.dropOffInstructions.DropOffInstructions.map((instruction) => {
                    return (
                      <div className='instruction mb8' key={instruction.index}>
                        {instruction.image && (
                          <div className='img'>
                            <img src={instruction.image} />
                          </div>
                        )}
                        <div className='text'>{instruction.text}</div>
                      </div>
                    )
                  })}
              </div>
              <div className={'col-sm-12 mt20 center-align'}>
                <MediumButton
                  buttonText={getTranslationText('common', 'okay')}
                  onClick={(e) => this.toggleLogisticsInstructions(e, true)}
                />
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )}
        {this.state.showStateSelectionModel && (
          <Modal className='sfy-modal empty-fields-modal' showModal={this.state.showStateSelectionModel}>
            <CustomIconComponent
              className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
              onClick={this.closeCountryStateSelection}
            />
            <div className=''>
              <div className='custom-modal-title mb20'>
                <Heading title={getTranslationText('request', 'selectYourState')} />
              </div>
              <div className='custom-modal-content'>
                {_.get(this, 'props.user.stateAddressListConfig.length') > 0 && (
                  <Dropdown
                    id='state-address-dropdown'
                    options={this.props.user.stateAddressListConfig.map((state) => ({
                      ...state,
                      label: state.State,
                      value: state.State,
                    }))}
                    handleOnChange={this.handleStateSelection}
                    value={(this.state.selectedState && this.state.selectedState.State) || ''}
                    searchText={getTranslationText('common', 'searchByStateText')}
                    filterBy='State'
                    placeholder={getTranslationText('store', 'selectStateLabel')}
                  />
                )}
              </div>
              <div className='center-align'>
                <MediumButton
                  className='mt20'
                  disabled={!isContinueStateSelectionDisabled}
                  onClick={this.countryStateSelection}
                  buttonText={getTranslationText('common', 'continue')}
                />
              </div>
            </div>
          </Modal>
        )}
        {this.state.showDistrictSelectionModel && (
          <Modal className='sfy-modal empty-fields-modal' showModal={this.state.showDistrictSelectionModel}>
            <CustomIconComponent
              className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
              onClick={this.closeCountryDistrictSelection}
            />
            <div className=''>
              <div className='custom-modal-title mb20'>
                <Heading title={getTranslationText('request', 'selectYourDistrict')} />
              </div>
              <div className='custom-modal-content'>
                {_.get(this, 'props.user.stateAddressListConfig.length') > 0 && (
                  <Dropdown
                    id='district-address-dropdown'
                    options={this.props.user.districtAddressListConfig.map((state) => ({
                      ...state,
                      label: state.DistrictID,
                      value: state.DistrictName,
                    }))}
                    handleOnChange={this.handleDistrictSelection}
                    value={(this.state.selectedDistrict && this.state.selectedDistrict.DistrictName) || ''}
                    searchText={getTranslationText('common', 'searchByDistrictText')}
                    filterBy='DistrictName'
                    placeholder={getTranslationText('store', 'selectDistrictLabel')}
                  />
                )}
              </div>
              <div className='center-align'>
                <MediumButton
                  className='mt20'
                  disabled={!isContinueDistrictSelectionDisabled}
                  onClick={this.countryDistrictSelection}
                  buttonText={getTranslationText('common', 'continue')}
                />
              </div>
            </div>
          </Modal>
        )}
        {this.state.showPinCodeModal && (
          <Modal className='sfy-modal empty-fields-modal' showModal={this.state.showPinCodeModal}>
            <CustomIconComponent
              className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
              onClick={() => {
                this.setState({ showPinCodeModal: false })
              }}
            />
            <div className='custom-modal-title mb20 d-flex'>
              <h5 className='pr-2'>{getTranslationText('request', 'serviceAreaAdditionalDetails')}</h5>
            </div>
            <div className='custom-modal-content'>
              <div className='bookFontStyle mb8'>{this.getPinCodeConfiguration().label}</div>
              <div id='input-container' className='input-container'>
                <input
                  name='pinCode'
                  className='input pl-2'
                  value={this.state.pincode || ''}
                  type='tel'
                  placeholder={`${getTranslationText('common', 'enter')} ${this.getPinCodeConfiguration().label}`}
                  onChange={(e) => {
                    let pinCodeType = this.getPinCodeConfiguration().regexFunc || 'NUMERIC'
                    if (
                      regexCheck('onChange', pinCodeType.toLowerCase(), e.target.value, Math.max(...this.getPinCodeConfiguration().length))
                    ) {
                      this.setState({ pincode: e.target.value })
                    }
                  }}
                />
              </div>
            </div>
            <MediumButton
              className={'mt20 ' + (window.isDirectionRTL ? ' right-align' : ' left-align')}
              disabled={!this.getPinCodeConfiguration().length.includes(this.state.pincode.length)}
              onClick={this.setPinCodeSelection}
              buttonText={getTranslationText('common', 'continue')}
            />
          </Modal>
        )}
        {_.get(this, 'props.user.appConfigDetails.BillOrderInstructions', []).length && this.state.showOrderNoInstr ? (
          <StepsIntrPopUp
            title={getTranslationText('common', 'instructions')}
            toggle={this.toggleOrdersInstr}
            show
            InstrData={this.props.user.appConfigDetails.BillOrderInstructions.map((instr, index) => {
              return 'Step ' + (index + 1) + ' : ' + instr
            })}
            confirmationTxt={getTranslationText('common', 'okay')}
          />
        ) : (
          ''
        )}
        {this.state.showReturnStepsInstr && (
          <StepFlowPopUp
            title={getTranslationText('request', 'returnPurchase')}
            toggle={this.handleReturnFlowStepsPopUp}
            stepsList={[
              getTranslationText('request', 'returnDetails'),
              getTranslationText('request', 'serviceMode'),
              getTranslationText('request', 'addressDate'),
              getTranslationText('request', 'scheduleVisit'),
            ]}
          />
        )}
        {this.state.showRepairRaisedSuccessModal ? (
          <Modal className='sfy-modal successfully-added-device-content' showModal={this.state.showRepairRaisedSuccessModal}>
            <CustomIconComponent
              className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
              onClick={() => browserHistory.replace('/')}
            />
            <div className='text-center'>
              <img src={_.get(this, 'props.request.retryHoldPaymentStatus', undefined) ? unSuccessAlertImg : successImg} />
              <div className='boldFontStyle fontLarge mt20 mb8'>
                {_.get(this, 'props.track.trackDetails.isReassignServiceLocationFlow')
                  ? getTranslationText('request', 'repairLocationsChanged')
                  : _.get(this, 'props.request.retryHoldPaymentStatus', undefined)
                  ? getTranslationText('request', 'deviceExchangeOnHold')
                  : [65].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
                  ? getTranslationText('success', 'exchangeRequestRaised')
                  : [46, 66].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
                  ? getTranslationText('request', 'replacementRaised')
                  : _.includes(['THEFT_OR_LOSS'], _.get(this, 'props.request.selectedDamageType.CoverageType', ''))
                  ? getTranslationText('request', 'claimRepairSubmit')
                  : getTranslationText('request', 'requestRaised')}
              </div>
              <div className='bookFontStyle fontSmall mt8'>
                {_.get(this, 'props.track.trackDetails.isReassignServiceLocationFlow') ? (
                  <p>
                    {getTranslationText('request', 'yourRepairLocation')} <br />
                    <span className='boldFontStyle'>{_.get(this, 'props.request.partnerServiceLocation.address', undefined)}</span>
                  </p>
                ) : (
                  <span>{this.state.claimRaiseSuccessMessage}</span>
                )}
              </div>
              {_.get(this, 'props.track.trackDetails.isReassignServiceLocationFlow') ||
              _.get(this, 'props.track.trackDetails.ConsumerProduct.ConsumerServiceRequestID') ? (
                ''
              ) : (
                <div className='boldFontStyle fontSmall mb20 mt8'>
                  {getTranslationText('track', 'referenceId')}: &nbsp;
                  {_.get(this, 'props.request.raisedRequest.ReferenceID') || this.state.generatedReferenceID}
                </div>
              )}
              <MediumButton
                className='boldFontStyle fontMedium mt8'
                onClick={() => {
                  let csrid = this.state.generatedCSRID
                  if (csrid) {
                    this.closeRepairSuccessModal(csrid)
                  } else {
                    this.closeRepairSuccessModal()
                  }
                  pushToAnalytics('Claim_raised_track_request_screen', {
                    ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
                    ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
                    csrid: csrid || undefined,
                  })
                }}
                buttonText={getTranslationText('common', 'okay')}
              />
            </div>
          </Modal>
        ) : (
          ''
        )}
        {this.state.showVideoMediaModal ? (
          <Modal className='sfy-modal successfully-added-device-content' showModal={true || this.state.showVideoMediaModal}>
            <CustomIconComponent
              className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
              onClick={() => this.closeModalHandler('showVideoMediaModal')}
            />
            <div className='text-center'>
              <video className='video-width' controls src={_.get(this, 'props.user.docServiceLargeFileDetails.fileUrl')} autoPlay />
              {!this.state.hideReuploadBtn && (
                <React.Fragment>
                  <MediumButton
                    className='button-small button-ghost upload-video-file-btn mt8'
                    buttonText='Re upload Video'
                    onClick={() => document.getElementById('video-media-upload').click()}
                  />
                  <input
                    onChange={(e) => {
                      this.closeModalHandler('showVideoMediaModal')
                      this.handleLargeFileUpload(e)
                    }}
                    type='file'
                    id='video-media-upload'
                    accept='.3gp, .mp4, .mov, .flv, .wmv'
                    style={{ display: 'none' }}
                  />
                </React.Fragment>
              )}
            </div>
          </Modal>
        ) : (
          ''
        )}

        {/slots/.test(this.props.location.pathname) && this.state.showDisplayPreviewModal && (
          <Modal className='sfy-modal empty-fields-modal' show={this.state.showDisplayPreviewModal}>
            <div className='modal-cross-icon'>
              <CustomIconComponent
                className='text-right'
                onClick={() => {
                  this.setState({ showAddressSuggestion: false })
                  this.addressPreviewDisplayToggle()
                  if (isAdvanceExchangeMode)
                    pushToAnalytics(
                      [17, 23].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
                        ? 'Return_address_verification_pop_up_cancel'
                        : 'Delivery_address_verification_pop_up_cancel',
                      {
                        ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
                        ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
                      }
                    )
                }}
              />
            </div>
            {this.state.showAddressSuggestion ? (
              <>
                <div className='boldFontStyle fontLarge mb8 text-center'>
                  {_.get(this, 'props.request.addrValidationDetails.heading', '')}
                </div>
                <div className='fontSmall text-center'>{_.get(this, 'props.request.addrValidationDetails.message', '')}</div>
                <div className='mt48 text-center address-suggestion-container'>
                  <RadioBox
                    isSelected={this.state.isRecommendedAddressChosen}
                    handleOnClick={() => {
                      this.setState({ isRecommendedAddressChosen: true })
                    }}
                  >
                    <div className='boldFontStyle fontSmall mb8'>
                      {getTranslationText('request', 'suggestedAddress', 'Suggested Address')}
                    </div>
                    <div className='pr20 fontSmall'>
                      {_.get(this, 'props.request.recommendedAddress.value', []).length &&
                        _.get(this, 'props.request.recommendedAddress.value').map((v, i) => {
                          return v + (_.get(this, 'props.request.recommendedAddress.value').length !== i + 1 ? ', ' : '.')
                        })}
                    </div>
                  </RadioBox>
                  <RadioBox
                    isSelected={!this.state.isRecommendedAddressChosen}
                    handleOnClick={() => {
                      this.setState({ isRecommendedAddressChosen: false })
                    }}
                  >
                    <div className='boldFontStyle fontSmall mb8'>{getTranslationText('request', 'enteredAddress', 'Entered Address')}</div>
                    <div className='pr20 fontSmall'>
                      {_.get(this, 'props.request.displayUserAddress.value', []).length &&
                        _.get(this, 'props.request.displayUserAddress.value').map((v, i) => {
                          return v + (_.get(this, 'props.request.displayUserAddress.value').length !== i + 1 ? ', ' : '.')
                        })}
                    </div>
                  </RadioBox>
                </div>
              </>
            ) : (
              <>
                <div className='boldFontStyle fontLarge mb20 text-center'>
                  {_.get(this, 'props.request.addrValidationDetails.heading', '')
                    ? _.get(this, 'props.request.addrValidationDetails.heading', '')
                    : isDropOffMode
                    ? getTranslationText('request', 'returnTitleConfirmAddress')
                    : isAdvanceExchangeMode
                    ? getTranslationText('request', 'deliveryAddressTitle')
                    : getTranslationText('request', 'titleConfirmAddress')}
                </div>

                {_.get(this, 'props.request.addrValidationDetails.message', '') ? (
                  <div className='text-center mb8'>{_.get(this, 'props.request.addrValidationDetails.message', '')}</div>
                ) : isDropOffMode ? (
                  <div className='text-center mb8'>{getTranslationText('request', 'confirmReturnAddress')}</div>
                ) : isAdvanceExchangeMode ? (
                  <div className='text-center mb8'>{getTranslationText('request', 'confirmExchangeAddress')}</div>
                ) : (
                  <>
                    <div className='text-center'>{getTranslationText('request', 'displayConfirmAddress')}</div>
                    <div className='text-center mb8'>{getTranslationText('request', 'confirmAddress')}</div>
                  </>
                )}

                <div className='box-flex'>
                  <div className='flex-component'>
                    <div className='fontLarge'>
                      {isDropOffMode
                        ? getTranslationText('common', 'returnAddress')
                        : isAdvanceExchangeMode
                        ? getTranslationText('common', 'deliveryAddress')
                        : getTranslationText('common', 'address')}
                    </div>
                    &emsp;&emsp;
                    <p className='pr-2'>
                      {_.get(this, 'props.request.displayUserAddress.value', []).length &&
                        _.get(this, 'props.request.displayUserAddress.value').map((v, i) => {
                          return v + (_.get(this, 'props.request.displayUserAddress.value').length !== i + 1 ? ', ' : '.')
                        })}
                    </p>
                  </div>
                  {_.includes([1, 11, 31, 57, 58, 52], parseInt(this.props.request.selectedMode.ServiceTypeID)) &&
                    !_.isEmpty(this, 'props.request.deliveryLocation') && (
                      <div className='flex-component'>
                        <div className='fontLarge'>{getTranslationText('common', 'deliveryAddress')}</div>&emsp;&emsp;
                        <p className='pr-2'>
                          {_.get(this, 'props.request.displayDeliveryAddress.value', []).length &&
                            _.get(this, 'props.request.displayDeliveryAddress.value').map((v, i) => {
                              return v + (_.get(this, 'props.request.displayDeliveryAddress.value').length !== i + 1 ? ', ' : '.')
                            })}
                        </p>
                      </div>
                    )}
                </div>
              </>
            )}
            {_.get(this, 'props.request.addrValidationDetails.warning', '') ? (
              <div className='fontSmall text-center mt20 mb8'>{_.get(this, 'props.request.addrValidationDetails.warning', '')}</div>
            ) : (
              ''
            )}
            <div className='row'>
              <div className='col-sm-12 center-align'>
                <MediumButton
                  className={'mt8'}
                  onClick={() => {
                    this.saveAddresses()
                    pushToAnalytics(
                      [17, 23].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
                        ? 'Return_address_verification_pop_up_confirm'
                        : 'Delivery_address_verification_pop_up_confirm',
                      {
                        ConsumerID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerID', ''),
                        ConsumerProductID: _.get(this, 'props.request.selectedDeviceInfo.ConsumerProductID', ''),
                        [[17, 23].includes(parseInt(_.get(this, 'props.request.selectedMode.ServiceTypeID')))
                          ? 'Return_Address'
                          : 'deliveryLocation']: _.get(this, 'props.request.deliveryLocation', ''),
                      }
                    )
                  }}
                  buttonText={getTranslationText('common', 'confirm')}
                />
              </div>
            </div>
            {[-1, 0].includes(_.get(this, 'props.request.addrValidationDetails.verificationStatus', '')) ? (
              <div
                className='lightFontStyle fontMedium special-link-txt text-center pt8'
                onClick={() => {
                  this.setState({
                    showDisplayPreviewModal: false,
                    showInvalidAddressModal: false,
                    showAddressSuggestion: false,
                    isRecommendedAddressChosen: false,
                  })
                  this.props.clearAddressValidationDetails()
                }}
              >
                {getTranslationText('profile', 'editAddress')}
              </div>
            ) : (
              ''
            )}
          </Modal>
        )}

        {this.state.replacementUpgradeModal ? (
          <Modal className='sfy-modal empty-fields-modal' showModal={this.state.replacementUpgradeModal}>
            <div className='modal-cross-icon'>
              <CustomIconComponent
                className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
                onClick={() => {
                  this.closeModalHandler('replacementUpgradeModal')
                }}
              />
            </div>
            <h4 className='text-center'>{getTranslationText('request', 'titleReplacementUpgrade')}</h4>
            <div className='text-center fontLarge m10'>{getTranslationText('request', 'replacementUpgrade')}</div>
            <div className='fontSmall mb8 box-flex'>
              <div className='flex-component'>
                <p className='fontLarge'>{_.get(this, 'props.request.selectedReplacementOption.ProductName', '')}</p>
                {_.get(this, 'props.request.selectedReplacementOption.ProductSkuAttributes.Carrier', '') ? (
                  <p className='lightFontStyle fontSmall'>
                    {`${getTranslationText('common', 'carrier')}: 
                  ${_.get(this, 'props.request.selectedReplacementOption.ProductSkuAttributes.Carrier', '')}`}
                  </p>
                ) : (
                  ''
                )}

                {_.get(this, 'props.request.selectedReplacementOption.ProductSkuAttributes.Colour', '') ? (
                  <p className='lightFontStyle fontSmall'>
                    {`${getTranslationText('common', 'color')}: 
                  ${_.get(this, 'props.request.selectedReplacementOption.ProductSkuAttributes.Colour', '')}`}
                  </p>
                ) : (
                  ''
                )}

                {_.get(this, 'props.request.selectedReplacementOption.ProductSkuAttributes.Storage', '') ? (
                  <p className='lightFontStyle fontSmall'>
                    {`${getTranslationText('common', 'storage')}: 
                  ${_.get(this, 'props.request.selectedReplacementOption.ProductSkuAttributes.Storage', '')}`}
                  </p>
                ) : (
                  ''
                )}

                {_.get(this, 'props.request.selectedReplacementOption.ProductSkuAttributes.RAM', '') ? (
                  <p className='lightFontStyle fontSmall'>
                    {`${getTranslationText('common', 'RAM')}: 
                  ${_.get(this, 'props.request.selectedReplacementOption.ProductSkuAttributes.RAM', '')}`}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className='row center-align'>
              <div className='col-sm-12 center-align'>
                <MediumButton
                  className='m20'
                  buttonText={getTranslationText('common', 'continue')}
                  onClick={() => {
                    this.setState({ replacementUpgradeModal: false }, () => {
                      this.handleContinue()
                    })
                  }}
                />
              </div>
              <div className='center-align special-link-txt' onClick={() => this.closeModalHandler('replacementUpgradeModal')}>
                {getTranslationText('common', 'backLabel')}
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )}
        {/* nextDayReplacementModal */}
        {this.state.nextDayReplacementModal ? (
          <Modal className='sfy-modal empty-fields-modal' showModal={this.state.nextDayReplacementModal}>
            <div className='modal-cross-icon'>
              <CustomIconComponent
                className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
                onClick={() => {
                  this.closeModalHandler('nextDayReplacementModal')
                }}
              />
            </div>
            <div className='text-center boldFontStyle fontLarge mt20 m10'>
              {getTranslationText('request', 'replacementDeviceUnavailable')}
            </div>
            <div className='text-center mt10'>{getTranslationText('request', 'replacementDeviceUnavailableDesc')}</div>
            <div className='row center-align'>
              <div className='col-sm-12 center-align mt8 mb8'>
                <MediumButton
                  className='m20'
                  buttonText={getTranslationText('common', 'continue')}
                  onClick={() => {
                    this.setState({ nextDayReplacementModal: false }, () => {
                      this.handleContinue()
                    })
                  }}
                />
              </div>
              <div className='center-align special-link-txt' onClick={() => this.closeModalHandler('nextDayReplacementModal')}>
                {getTranslationText('request', 'reviewOtherOptions')}
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )}

        {this.state.showDisClaimerModal && (
          <Modal className='sfy-modal claim-instructions-modal' showModal={this.state.showDisClaimerModal}>
            <img className='modal-icon-width' src={informationImg} />
            <div className='text-center boldFontStyle fontLarge mt20 m10'>{getTranslationText('request', 'walkInDisclaimer')}</div>
            <div className={'mt28 center-align'}>
              <MediumButton
                buttonText={getTranslationText('common', 'okay')}
                onClick={() => this.closeModalHandler('showDisClaimerModal')}
              />
            </div>
          </Modal>
        )}

        {this.state.showNoStoreOptionModal && (
          <Modal className='sfy-modal claim-instructions-modal' showModal={this.state.showNoStoreOptionModal}>
            <div className='modal-cross-icon'>
              <CustomIconComponent
                className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
                onClick={() => {
                  this.closeModalHandler('showNoStoreOptionModal')
                }}
              />
            </div>
            <img className='modal-icon-width' src={informationImg} />
            <div className='text-center boldFontStyle fontLarge mt4'>{getTranslationText('request', 'sameDayReplacementUnavailable')}</div>
            <div className='text-center lighFontStyle fontSmall'>
              {getTranslationText('request', 'sameDayReplacementUnavailableDetails')}
            </div>
            <div className={'mt28 center-align'}>
              <MediumButton
                buttonText={getTranslationText('common', 'continue')}
                onClick={() => this.closeModalHandler('showNoStoreOptionModal')}
              />
            </div>
          </Modal>
        )}

        <ContactUsModal
          {...this.props}
          showContactUsModal={this.state.showContactUsModal}
          closeContactUsModal={() => {
            this.setState({ showContactUsModal: false })
          }}
          additionalInfo={getTranslationText('request', 'inWarrantyNote')}
        />

        <Modal className='sfy-modal claim-instructions-modal' showModal={this.state.showDiscalimerContactUsModal}>
          <CustomIconComponent
            className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
            onClick={this.closeDiscalimerContactUsModal}
          />
          <div className='claim-instructions'>
            <div className='custom-modal-title mb20'>
              <p className='fontExtraLarge20'>
                {getTranslationText('request', 'redirectingSamsungManufacturer')} <br /> {getTranslationText('request', 'warrantySupport')}
              </p>
              <p className='mt8 mb28 fontMedium'>{getTranslationText('request', 'titleDescriptionSamsung')}</p>
            </div>
            <div className='custom-modal-content mb20'>
              <div className='instruction contact-us'>
                <div className='connect-servify fontMedium bookFontStyle'>
                  {getTranslationText('request', 'samsungWarrantyHotline')}
                  <span className='boldFontStyle'>
                    {' '}
                    {_.get(this, 'props.user.appConfigDetails.footerLinks.support.number') || '1800 121 4984'}{' '}
                  </span>
                </div>
                <div className='connect-servify fontMedium bookFontStyle'>
                  {getTranslationText('request', 'scheduleRepairOnline')}{' '}
                  <a
                    className='boldFontStyle'
                    target='_blank'
                    href='https://www.samsung.com/us/support/service/locations/?product=N0060222'
                    rel='noreferrer'
                  >
                    Samsung.com{' '}
                  </a>
                  {getTranslationText('request', 'website')}
                </div>
              </div>
              <br />
            </div>
            <p className='mt8 mb20 fontMedium'>{getTranslationText('request', 'contactSamsungSupport')}</p>
            <div className='center-align'>
              <MediumButton
                className='mt20'
                onClick={this.closeDiscalimerContactUsModal}
                buttonText={getTranslationText('common', 'okay')}
              />
            </div>
          </div>
        </Modal>
        {this.state.showClaimConsentWarningModal && (
          <Modal className='sfy-modal claim-instructions-modal' showModal={this.state.showClaimConsentWarningModal}>
            <CustomIconComponent
              className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
              onClick={() => {
                this.closeModalHandler('showClaimConsentWarningModal')
              }}
            />
            <div className='claim-instructions'>
              <div className='custom-modal-title mb20'>
                <p className='fontExtraLarge20 center-align'>{getTranslationText('request', 'importantRead')}</p>
                <p className='mt8 mb28 fontMedium center-align'>
                  {this.props.request.selectedMode?.legalConfig?.map(
                    (consent, index) =>
                      consent?.ConsentOptional && (
                        <span key={index} className='' onClick={() => {}}>
                          {consent?.UnselectedText}
                        </span>
                      )
                  )}
                </p>
              </div>
              <div className='center-align'>
                <MediumButton
                  className='mt20'
                  onClick={() => {
                    this.closeModalHandler('showClaimConsentWarningModal'), this.handleContinue()
                  }}
                  buttonText={getTranslationText('common', 'continue')}
                />
              </div>
            </div>
          </Modal>
        )}

        {this.state.showAffidavitModal && (
          <Modal className='sfy-modal affidavit-modal' showModal={this.state.showAffidavitModal}>
            <div className='modal-cross-icon'>
              <CustomIconComponent
                className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
                onClick={() => {
                  this.closeModalHandler('showAffidavitModal')
                }}
              />
            </div>
            <div className='text-center boldFontStyle fontLarge mt20 m10'>{getTranslationText('request', 'addidavitSubmission')}</div>
            <div className='mb8'>{getTranslationText('request', 'reviewDetails')}</div>
            <div className='row background-grey mb20'>
              <div className='col-md-4'>
                <div className='lightFontStyle fontSmall mb4'>{getTranslationText('request', 'incidentType')}</div>
                <div>{_.get(this, 'props.request.claimFormDetails.incidentType', '')}</div>
              </div>

              <div className='col-md-4'>
                <div className='lightFontStyle fontSmall mb4'>{getTranslationText('request', 'dateOfLoss')}</div>
                <div>{moment(_.get(this, 'props.request.claimFormDetails.dateOfLoss', '')).format('MM/DD/YYYY')}</div>
              </div>

              <div className='col-md-4'>
                <div className='lightFontStyle fontSmall mb4'>{getTranslationText('request', 'placeLossLastKnown')}</div>
                <div>{_.get(this, 'props.request.claimFormDetails.deviceDamagePlace.landmark', '')}</div>
              </div>

              <div className='col-md-4 mt20'>
                <div className='lightFontStyle fontSmall mb4'>{getTranslationText('request', 'imeiOrSerial')}</div>
                <div>{_.get(this, 'props.request.selectedDeviceInfo.ProductUniqueID')}</div>
              </div>

              <div className='col-md-4 mt20'>
                <div className='lightFontStyle fontSmall mb4'>{getTranslationText('request', 'deviceManufacturer')}</div>
                <div>{_.get(this, 'props.request.selectedDeviceInfo.Brand.BrandName')}</div>
              </div>

              <div className='col-md-4 mt20'>
                <div className='lightFontStyle fontSmall mb4'>{getTranslationText('store', 'deviceModelLabel')}</div>
                <div>{_.get(this, 'props.request.selectedDeviceInfo.Product.ProductName')}</div>
              </div>

              <div className='col-md-4 mt20'>
                <div className='lightFontStyle fontSmall mb4'>{getTranslationText('request', 'lossUserFullName')}</div>
                <div>{_.get(this, 'props.request.claimFormDetails.userFullName', '')}</div>
              </div>

              <div className='col-md-4 mt20'>
                <div className='lightFontStyle fontSmall mb4'>{getTranslationText('request', 'lossEmail')}</div>
                <div>{_.get(this, 'props.request.claimFormDetails.lossEmailId', '')}</div>
              </div>

              <div className='col-md-4 mt20'>
                <div className='lightFontStyle fontSmall mb4'>{getTranslationText('common', 'mobileNumber')}</div>
                <div>{_.get(this, 'props.request.claimFormDetails.lossMobileNumber', '')}</div>
              </div>

              <div className='col-md-4 mt20'>
                <div className='lightFontStyle fontSmall mb4'>{getTranslationText('request', 'billingAddres')}</div>
                <div>{_.get(this, 'props.request.claimFormDetails.lossBillingAddress', '')}</div>
              </div>

              <div className='col-md-4 mt20'>
                <div className='lightFontStyle fontSmall mb4'>{getTranslationText('request', 'photoID')}</div>
                <BoxImagesComponent
                  loaderState={false}
                  isUploaded={true}
                  imgFilePath={_.get(this, 'props.request.claimFormDetails.policeReportRenderableUrl', '')}
                  inputName='police-report'
                />
              </div>

              <div className='col-md-12 mt20'>
                <div className='lightFontStyle fontSmall mb4'>{getTranslationText('request', 'incidentDetailsLabel')}</div>
                <div>{_.get(this, 'props.request.claimFormDetails.lossDetails', '')}</div>
              </div>
            </div>
            <div className='mb8'>{getTranslationText('request', 'claimgAgreement')}</div>
            <div className='row'>
              <div className='col-md-12 fontMedium inline-ele-Casing'>
                <Checkbox
                  type='checkbox'
                  value={this.state.lossAffidavitConsentCheck}
                  handleOnChange={this.toggleAffidavitClaimCheck}
                  otherProps={{
                    style: window.isDirectionRTL ? { marginLeft: '5px' } : { marginRight: '5px' },
                  }}
                >
                  <span className='' onClick={this.toggleAffidavitClaimCheck}>
                    {
                      getLegalConsents(_.get(this, 'props.user.claimLegalConfigs.LegalConfigs', []), ['ACKNOWLEDGEMENT'])[0].legalConfig
                        .Text
                    }
                  </span>
                </Checkbox>
              </div>
              <div className='col-md-4 mt20'>
                <div className='lightFontStyle fontSmall mb4'>{getTranslationText('login', 'yourname')}</div>
                <div>{_.get(this, 'props.request.claimFormDetails.userFullName', '')}</div>
              </div>

              <div className='col-md-4 mt20'>
                <div className='lightFontStyle fontSmall mb4'>{getTranslationText('request', 'lossAffidavitDate')}</div>
                <div>{moment().format('MM/DD/YYYY')}</div>
              </div>
            </div>

            <div className={'mt28 center-align'}>
              <MediumButton
                buttonText={'Acknowledge'}
                disabled={!this.state.lossAffidavitConsentCheck}
                onClick={() => {
                  this.closeModalHandler('showAffidavitModal')
                  this.validateClaimForm()
                }}
              />
            </div>
          </Modal>
        )}

        <Modal className='sfy-modal' showModal={this.state.showInvalidAddressModal}>
          <div className='text-center'>{<img className='modal-icon-width' src='/commonAssets/failure-alert.png' />}</div>
          <div className='boldFontStyle fontLarge mb8 text-center'>{_.get(this, 'props.request.addrValidationDetails.heading', '')}</div>
          <div className='fontSmall mb20 text-center'>{_.get(this, 'props.request.addrValidationDetails.message', '')}</div>
          <div className='fontSmall mb8 box-flex'>
            <p className='flex-component'>
              {_.get(this, 'props.request.displayUserAddress.value', []).length &&
                _.get(this, 'props.request.displayUserAddress.value').map((v, i) => {
                  return v + (_.get(this, 'props.request.displayUserAddress.value').length !== i + 1 ? ', ' : '.')
                })}
            </p>
          </div>
          <div className='row'>
            <div className='col-sm-12 text-center'>
              <MediumButton
                onClick={() => {
                  this.setState({ showInvalidAddressModal: false, showAddressSuggestion: false, isRecommendedAddressChosen: true })
                  this.props.clearAddressValidationDetails()
                }}
                buttonText={getTranslationText('profile', 'editAddress')}
              />
            </div>
          </div>
          <div className='fontSmall text-center mt20 mb8'>{_.get(this, 'props.request.addrValidationDetails.warning', '')}</div>
          <div className='d-flex justify-center text-center'>
            <div className='custom-modal-content'>
              <div className='instruction contact-us mb8'>
                <div
                  className='connect-servify footer-links bookFontStyle'
                  onClick={() => {
                    window.location.href =
                      'mailto:' + _.get(this, 'props.user.appConfigDetails.footerLinks.support.email', undefined) ||
                      _.get(this, 'props.user.appConfigDetails.BrandConnect.email[0]', 'support@servify.in')
                  }}
                >
                  <img src={'/commonAssets/MailId@3x.png'} />
                  <b className='primaryFontColor'>
                    {_.get(this, 'props.user.appConfigDetails.footerLinks.support.email', undefined) ||
                      _.get(this, 'props.user.appConfigDetails.BrandConnect.email[0]', 'support@servify.in')}
                  </b>
                </div>
              </div>
              <div className='instruction contact-us mb8'>
                <div className='connect-servify fontSmall bookFontStyle'>
                  <Phone />
                  <b className='primaryFontColor ml8' dir='ltr'>
                    {_.get(this, 'props.user.appConfigDetails.footerLinks.support.number') || '1800 121 4984'}
                  </b>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {this.state.showStandardChargeModal && (
          <Modal className='sfy-modal standard-charge-modal' showModal={this.state.showStandardChargeModal}>
            <CustomIconComponent
              className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
              onClick={() => {
                this.closeModalHandler('showStandardChargeModal')
              }}
            />
            <div className='text-center boldFontStyle fontLarge mt20 mb20'>{`Standard Out of Warranty repair charges`}</div>

            <div className='table-header row'>
              <div className='col-4'>
                <div>{`Description`}</div>
              </div>

              <div className='col-4'>
                <div>{`Metro Cities`}</div>
              </div>

              <div className='col-4'>
                <div>{`Other Cities`}</div>
              </div>
            </div>

            <div className='row table-body mb20'>
              {_.get(this, 'props.user.appConfigDetails.standardChargesData.standardCharges').map((v) => {
                return (
                  <>
                    <div className='col-4 mt8 mb8'>
                      <div>{v.Description}</div>
                    </div>
                    <div className='col-4 mt8 mb8'>
                      <div className='boldFontStyle'>{v.MetroCities}</div>
                    </div>
                    <div className='col-4 mt8 mb8'>
                      <div className='boldFontStyle'>{v.Others}</div>
                    </div>
                  </>
                )
              })}
            </div>

            <div className='row mt8 mb8 note-text-box'>
              <div className='col-md-12 mt8 mb8'>
                <div>Note:</div>
                {_.get(this, 'props.user.appConfigDetails.standardChargesData.notes').map((v) => {
                  return (
                    <>
                      <div className='fontSmall lightFontStyle'>• {v}</div>
                    </>
                  )
                })}
              </div>
            </div>

            <div className={'mt20 center-align'}>
              <MediumButton
                buttonText={getTranslationText('common', 'okay')}
                onClick={() => {
                  this.closeModalHandler('showStandardChargeModal')
                }}
              />
            </div>
          </Modal>
        )}

        {this.state.detectLocationInstruction ? (
          <Modal className='sfy-modal empty-fields-modal' showModal={this.state.detectLocationInstruction}>
            <div className='modal-cross-icon'>
              <CustomIconComponent
                className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
                onClick={() => this.setState({ detectLocationInstruction: false })}
              />
            </div>
            <img className='modal-icon-width' src={informationAlert} />
            <div className='text-center boldFontStyle fontLarge mt20 m10'>{getTranslationText('request', 'allowLocation')}</div>
            <div className='text-center mt20'> {getTranslationText('request', 'needAccessToLocation')}</div>
            <div className='text-center mb20'> {getTranslationText('request', 'settingLocationEnable')}</div>
            <div className='row'>
              <div className='col-sm-12 center-align'>
                <MediumButton
                  buttonText={getTranslationText('common', 'okay')}
                  onClick={() => this.setState({ detectLocationInstruction: false })}
                />
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )}
        {this.state.showSerialQuestionModal ? (
          <Modal className='sfy-modal empty-fields-modal' showModal={this.state.showSerialQuestionModal}>
            <div className='modal-cross-icon'>
              <CustomIconComponent
                className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
                onClick={this.closeProductDisclaimer}
              />
            </div>
            <div className='text-center boldFontStyle fontLarge mt20 mb28'>
              {getTranslationText('request', 'additionalDeviceInfoTitle')}
            </div>
            {this.state.questionOption &&
              this.state.questionOption.length &&
              this.state.questionOption.map((data, index) => (
                <div className='d-flex justify-space-around' key={index}>
                  <div className='width60'>{data.disclaimerMessage}</div>
                  <div className='d-flex justify-space-around'>
                    <RadioBtnPills
                      prefix={index}
                      optionList={data.disclaimerOptions}
                      onClick={(e) => this.handleQuestionOptionSelect(data, e)}
                    />
                  </div>
                </div>
              ))}

            <div className='row mt28'>
              <div className='col-sm-12 center-align'>
                <MediumButton
                  disabled={this.state.recievedDisclaimerOption.length !== this.state.questionOption.length}
                  buttonText={getTranslationText('common', 'submit')}
                  onClick={this.handleSubmitProductDisclaimer}
                />
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )}
      </div>
    )
  }

  static propTypes = {
    params: PropTypes.object.isRequired,
    devices: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    getGeoLocationAsync: PropTypes.func.isRequired,
    getBrowserLocationAsync: PropTypes.func.isRequired,
    setSelectedDevice: PropTypes.func.isRequired,
    request: PropTypes.object.isRequired,
    setToaster: PropTypes.func.isRequired,
    getRequestModesAsync: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    raiseNewClaimAsync: PropTypes.func.isRequired,
    getRequestSlotsAsync: PropTypes.func.isRequired,
    setPartnerServiceLocation: PropTypes.func.isRequired,
    setSelectedMode: PropTypes.func.isRequired,
    setDateAndTimeAsync: PropTypes.func.isRequired,
    setSelectedDamageType: PropTypes.func.isRequired,
    getRequestIssuesForTypeAsync: PropTypes.func.isRequired,
    setIssues: PropTypes.func.isRequired,
    scheduleClaimRepairAsync: PropTypes.func.isRequired,
    scheduleNonClaimRepairAsync: PropTypes.func.isRequired,
    clearRequestModes: PropTypes.func.isRequired,
    clearUserLocation: PropTypes.func.isRequired,
    getSignedUrlAsync: PropTypes.func.isRequired,
    addProductDocAsync: PropTypes.func.isRequired,
    getProductDocsAsync: PropTypes.func.isRequired,
    getProductsList: PropTypes.func.isRequired,
    addDeviceAsync: PropTypes.func.isRequired,
    getPaginatedDevicesAsync: PropTypes.func.isRequired,
    setUserRequestLocation: PropTypes.func.isRequired,
    getUserAddressesAsync: PropTypes.func.isRequired,
    addAddressAsync: PropTypes.func.isRequired,
    pickUpRequestAsync: PropTypes.func.isRequired,
    carryInRequestAsync: PropTypes.func.isRequired,
    nonRepairRequestAsync: PropTypes.func.isRequired,
    // clearFullState: PropTypes.func.isRequired,
    setAddressFlgToStore: PropTypes.func.isRequired,
    clearDateTimeSlots: PropTypes.func.isRequired,
    activatePlanAsync: PropTypes.func.isRequired,
    clearRequestState: PropTypes.func.isRequired,
    // scheduleRequestInstallDemoAsync: PropTypes.func.isRequired,
    authenticateProductsAsync: PropTypes.func.isRequired,
    getNotificationsAsync: PropTypes.func.isRequired,
    setLogisticPartner: PropTypes.func.isRequired,
    updateDevicesAsync: PropTypes.func.isRequired,
    fetchPickupInstructionsAsync: PropTypes.func.isRequired,
    setRequestFlowSource: PropTypes.func.isRequired,
    getAdvancePaymentDetails: PropTypes.func.isRequired,
    addToVisitedUrl: PropTypes.func.isRequired,
    clearUrlList: PropTypes.func.isRequired,
    clearSelectedDamageType: PropTypes.func.isRequired,
    setInvoiceDocVisibilityState: PropTypes.func.isRequired,
    setAdditionalUploadData: PropTypes.func.isRequired,
    asyncClaimEligibility: PropTypes.func.isRequired,
    getClaimLegalConfigs: PropTypes.func.isRequired,
    uploadAdditionalDocsAsync: PropTypes.func.isRequired,
    setClaimDetails: PropTypes.func.isRequired,
    updateServiceLocationAsync: PropTypes.func.isRequired,
    getProductsByBrand: PropTypes.func.isRequired,
    setRequestSlots: PropTypes.func.isRequired,
    updateUserDetailsAsync: PropTypes.func.isRequired,
    setActivationPlan: PropTypes.func.isRequired,
    setglobalLoaderStatus: PropTypes.func.isRequired,
    fetchEligiblePlansNewAsync: PropTypes.func.isRequired,
    deleteDeviceProductDocs: PropTypes.func.isRequired,
    getTypeOfDamageAsync: PropTypes.func.isRequired,
    setPaymentRequestStatus: PropTypes.func.isRequired,
    storeLastKnowPathName: PropTypes.func.isRequired,
    advancePayAsync: PropTypes.func.isRequired,
    payment: PropTypes.object.isRequired,
    setSelectedOrder: PropTypes.func.isRequired,
    removeVisitedUrl: PropTypes.func.isRequired,
    fetchReturnReasons: PropTypes.func.isRequired,
    setHandlingCharges: PropTypes.func.isRequired,
    getHandlingCharges: PropTypes.func.isRequired,
    doortoDoorReplacementAsync: PropTypes.func.isRequired,
    createRequestAsync: PropTypes.func.isRequired,
    getServiceTypesList: PropTypes.func.isRequired,
    routeServiceRequestAsync: PropTypes.func,
    getConsumerPlanDetailsAsync: PropTypes.func,
    setClaimEligibility: PropTypes.func,
    checkClaimStatusAsync: PropTypes.func,
    clearPaymentDetails: PropTypes.func,
    updateAddressAsync: PropTypes.func.isRequired,
    getCountriesAsync: PropTypes.func,
    getStateListAsync: PropTypes.func,
    checkDecisionMatrixAsync: PropTypes.func.isRequired,
    setDecisionMatrixDetails: PropTypes.func.isRequired,
    getPayoutModesAsync: PropTypes.func,
    setUserBuyOutDetails: PropTypes.func,
    getPaymentActionsAsync: PropTypes.func.isRequired,
    setConsentCoverageConfig: PropTypes.func.isRequired,
  }
}

export default Request
