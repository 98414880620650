import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { BoxHolder, Heading, MediumButton, Accordion, CustomIconComponent } from '../../../components/UIComponents'
import { Carousel, Slider, Modal } from 'sfy-react'
import _ from 'lodash'
import { requestTypesList } from '../../../utils/constants'
import { getTranslationText } from '../../../utils/languageTranslation.js'
import { ReactComponent as RightArrow } from '../../../../public/commonAssets/right-arrow.svg'
import pushToAnalytics from '../../../utils/Analytics.js'
import { ReactComponent as Email } from '../../../../public/commonAssets/email-web.svg'
import { ReactComponent as Phone } from '../../../../public/commonAssets/phone.svg'

export const TrackRequestContainer = (props) => {
  let carouselSlideCount = 0
  return (
    <BoxHolder className='home-track-request profile-track-request row'>
      <div className={'col-12 mt20 track-info-slider ' + (props.trackReqInfo.length === 1 ? 'hide-my-button' : '')}>
        <Slider
          spacing={0}
          config={[
            { breakpoint: 0, items: 1 }, // for screen width above 0 show 1 item per slide
            { breakpoint: 600, items: 1 }, // for screen width above 600 show 2 items per slide
            { breakpoint: 992, items: 1 }, // for screen width above 992 show 3 items per slide
            { breakpoint: 1200, items: 1 }, // for screen width above 1200 show 4 items per slide
          ]}
          activeSlideIndex={0}
          isRTL={window.isDirectionRTL}
        >
          {Array.isArray(props.trackReqInfo) && props.trackReqInfo.length
            ? props.trackReqInfo.map((data, index) => {
                carouselSlideCount++
                return (
                  <div className={`slide-wrapper ${props.trackReqInfo.length === 1 ? `pl8 pr8` : ``}`} key={index}>
                    <div className='col-sm-12 mb8 track-info-header'>
                      <Heading title={getTranslationText('track', 'trackRequest')} />
                      <div className='fontSmall mt8'>
                        {getTranslationText('track', 'referenceId') + ': '}
                        {data.ConsumerServiceRequest.ReferenceID}
                      </div>
                    </div>
                    <hr className='col-sm-10' />
                    <div className='device-img'>
                      <img
                        src={
                          props.productImageUrl && data.ProductSubCategoryID
                            ? props.productImageUrl + data.ProductSubCategoryID + '.png'
                            : ''
                        }
                      />
                    </div>
                    {/* <div className='request-edit-div' onClick={props.handleTrackRequest}
            id={'id-' + data.ConsumerServiceRequest.ConsumerServiceRequestID}>
            <div className='request-content fontMedium'>
              <div className='mediumFontStyle'>{data.Product && data.Product.ProductName}</div>
              <div className='bookFontStyle'>{data.ConsumerServiceRequest && data.ConsumerServiceRequest.Status}</div>
              <div className='bookFontStyle'>
                <span>
                  {data.ConsumerServiceRequest &&
                    getLocaleDate(props.dateFormat, data.ConsumerServiceRequest.CreatedDate)}
                </span>
              </div>
            </div>
          </div> */}
                    <div
                      className='request-edit-div'
                      onClick={props.handleTrackRequest}
                      id={'id-' + data.ConsumerServiceRequest.ConsumerServiceRequestID}
                    >
                      <div className='request-content fontMedium'>
                        <div className='mediumFontStyle'>{data.product && data.product.ProductName}</div>
                        <div>{data.brand && data.brand.BrandName}</div>
                        {/* <div className='bookFontStyle'>
                <span>{data.ConsumerServiceRequest && moment(data.ConsumerServiceRequest.CreatedDate).format('DD MMM YYYY')}</span>
              </div> */}
                      </div>
                    </div>
                    <hr className='col-sm-10' />
                    <div className='bookFontStyle col-sm-12 pb8'>
                      <div className='boldFontStyle'>{getTranslationText('track', 'currentStatus')}</div>
                      <div>
                        {data.ConsumerServiceRequest &&
                          _.get(data, 'ConsumerServiceRequest.lastDisplayInfo.DisplayText', data.ConsumerServiceRequest.Status)}
                      </div>
                    </div>
                    <div className='col-sm-12 track-info-pagination Note_Text mt8 mb8'>
                      {props.trackReqInfo.length > 1 && carouselSlideCount + '/' + props.trackReqInfo.length}
                    </div>
                  </div>
                )
              })
            : []}
        </Slider>
        {/* <Carousel
          items={props.trackReqInfo.filter((item) => item)}
          individualBanner={(data, index) => {
            carouselSlideCount++
            return (
              
            )
          }}
        /> */}
      </div>
    </BoxHolder>
  )
}
TrackRequestContainer.propTypes = {
  productImageUrl: PropTypes.string,
  trackReqInfo: PropTypes.array.isRequired,
  handleTrackRequest: PropTypes.func.isRequired,
}

export const WarrantyPlansCarousel = (props) => {
  return (
    <BoxHolder>
      <div className='row'>
        <div className='col-sm-12'>
          <div className='recommendations-heading mb20 text-left'>
            <Heading wordBreak title={getTranslationText('common', 'recommended')} />
          </div>
        </div>
        <div className='col-sm-12 mb20'>
          <Carousel
            items={props.recommendedPlans.filter((item) => item)}
            showDots
            showArrows={false}
            className={{
              dotContainer: 'sfy-slider-dots',
            }}
            individualBanner={(plan) => (
              <div key={plan.PlanCode} className='slideContent'>
                <div className='slider-content-img mb8'>
                  <div>
                    <img src={plan.PlanEmailImage} />
                  </div>
                </div>
                <div className='plan-descp-content'>
                  <div
                    className='fontSmall header-recommended'
                    dangerouslySetInnerHTML={{
                      __html: _.get(plan, 'PlanConfig.AlternatePlanDisplayName', plan.PlanConfig.PlanName),
                    }}
                  />
                  <div className='bookFontStyle fontSmall'>{plan.PlanPrice}</div>
                </div>
                <div className='buy-plans-btn mt28'>
                  <MediumButton buttonText='Buy Now' id={plan.PlanCode} onClick={props.handleRecommendedPlanSelection} />
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </BoxHolder>
  )
}
WarrantyPlansCarousel.propTypes = {
  isLoaded: PropTypes.bool,
  recommendedPlans: PropTypes.array.isRequired,
  handleRecommendedPlanSelection: PropTypes.func.isRequired,
}

export const ServicesDiv = (props) => {
  let mailLink = _.get(props, 'user.appConfigDetails.footerLinks.support.email', 'support@servify.in')
  let devicesList = _.get(props, 'devices.deviceDetails.supportedDevices', [])
  let showRefundOption = _.get(props, 'user.appConfigDetails.showRefundOption', false)
  let showReplacementOption = _.get(props, 'user.appConfigDetails.showReplacementOption', false)
  let serviceOptions = props.user.appConfigDetails
  let externalClaimURL = _.get(props, 'user.appConfigDetails.ActionLinks.repair', '')
  if (devicesList.length) {
    if (showRefundOption) {
      showRefundOption = false
      showRefundOption = !!devicesList.find((device) => device.showRefundOption)
    }
    if (showReplacementOption) {
      showReplacementOption = false
      showReplacementOption = !!devicesList.find((device) => device.showReplacementOption)
    }
  } else {
    showRefundOption = false
    showReplacementOption = false
  }
  let servicesObj = {
    showServicing: true,
    showInstallAndDemo: true,
    showServiceEstimator: true,
    // common
    showGetInTouch: true,
    ShowContactDetailsInModal: false,
    ...serviceOptions,
    showRepairOptions: serviceOptions.showRepairOptions && !serviceOptions.ShowRaiseClaim,
    ShowRaiseClaim: serviceOptions.ShowRaiseClaim && !serviceOptions.showNormalRepairOptions,
    showReplacementOption,
    showRefundOption,
  }
  let modifiedOptions = requestTypesList().filter((type) => {
    let key = type.key
    type.onClick = props.clearRequestState
    if (key === 'showRepairOptions' && !_.get(serviceOptions, 'isRepairAllowed', true)) {
      type.onClick = props.openModal
      type.path = null
    }
    if (key === 'showRepairOptions' && externalClaimURL.length) {
      type.path = externalClaimURL
    }
    if (servicesObj[key]) {
      return type
    }
  })

  return (modifiedOptions && modifiedOptions.length > 0) || props.showManageSubscriptionButton || servicesObj.showGetInTouch ? (
    <BoxHolder>
      <div className='mb20'>
        <Heading wordBreak title={getTranslationText('common', 'getServiceLabel')} />
      </div>
      <div className={_.get(props, 'store.recommendedPlanList', []).length ? 'list-of-services' : 'services-container'}>
        {modifiedOptions.map((serviceOpt) => (
          <ServicesDivSubComponents
            key={serviceOpt.text}
            label={serviceOpt.text}
            location={serviceOpt.path}
            imgSrc={serviceOpt.image}
            onClick={() => {
              if (serviceOpt.type === 'claim') {
                pushToAnalytics('File_claim_button_click', {
                  ConsumerID: _.get(props, 'user.userDetails.consumerID', ''),
                })
              }
              serviceOpt.onClick()
            }}
            externalClaimURL={_.get(props, 'user.appConfigDetails.ActionLinks.repair', '')}
          />
        ))}

        {props.showManageSubscriptionButton && (
          <ServicesDivSubComponents
            loaderState={props.loaderState}
            label={getTranslationText('home', 'manageSubscription')}
            removemargin
            imgSrc={`/${appName}/images/subscription.png`}
            onClick={
              props.isContractPurchasedFromThirdParty
                ? () =>
                    window.open(
                      process?.env?.NODE_ENV == 'production'
                        ? 'https://h20212-3.www2.hp.com/Service/Subscription/us/en/?jumpid=XXXXXXXXXXXXXXXX'
                        : 'https://genuiitga.austin.hp.com/Service/Subscription/us/en/?jumpid=XXXXXXXXXXXXXXXX',
                      '_blank'
                    )
                : props.openManageSubscriptionModal
            }
          />
        )}

        {servicesObj.showGetInTouch && (
          <ServicesDivSubComponents
            loaderState={props.loaderState}
            label={getTranslationText('common', 'getInTouchLabel')}
            removemargin
            imgSrc={`/${appName}/images/GetinTouch.png`}
            onClick={() =>
              window.fcWidget
                ? window.fcWidget.open()
                : servicesObj.ShowContactDetailsInModal
                ? props.openContactUsModal
                : () => {
                    if (!props.loaderState) {
                      window.location.href = 'mailto:' + mailLink
                    }
                  }
            }
          />
        )}
      </div>
    </BoxHolder>
  ) : (
    <div className='services-container-empty'></div>
  )
}
ServicesDiv.propTypes = {
  user: PropTypes.object.isRequired,
  openContactUsModal: PropTypes.func.isRequired,
  openModal: PropTypes.func,
  clearRequestState: PropTypes.func,
}

export const ServicesDivSubComponents = (props) => {
  return (
    <Link
      className={`service-item ${props.removemargin ? ' remove-bottom-marign' : ''}`}
      to={props.loaderState ? '' : props.location}
      target={props.location === props.externalClaimURL ? '_blank' : ''}
      onClick={props.onClick}
    >
      <img className='service-icon' src={props.imgSrc} />
      <div className={'service-title overrideCasing' + (props.loaderState ? 'loader-cursor' : '')}>{props.label}</div>
      <RightArrow />
    </Link>
  )
}
ServicesDivSubComponents.propTypes = {
  label: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  location: PropTypes.string,
  removemargin: PropTypes.bool,
  onClick: PropTypes.func,
  externalClaimURL: PropTypes.string,
}

export const FaqListContainer = (props) => {
  return (
    <BoxHolder className='home-faq-container'>
      <div className='row'>
        <div className='col-sm-12 mb20'>
          <Heading wordBreak title={getTranslationText('common', 'faq')} />
        </div>
        <div className='col-sm-12'>
          <Accordion titleClassName='boldFontStyle fontSmall' data={props.faqList} />
        </div>
      </div>
    </BoxHolder>
  )
}
FaqListContainer.propTypes = {
  faqList: PropTypes.array.isRequired,
}

export const ManageSubscriptionModal = (props) => {
  let mailLink = _.get(props, 'user.appConfigDetails.BrandConnect.email[0]', 'support@servify.in')
  return (
    <Modal className='sfy-modal claim-instructions-modal' showModal={props.showManageSubscriptionModal}>
      <CustomIconComponent
        className={'close-icon ' + (window.isDirectionRTL ? 'appear-left text-left ' : 'appear-right text-right')}
        onClick={() => props.closeModal('showManageSubscriptionModal')}
      />
      <div className='claim-instructions'>
        <div className='custom-modal-title mb20'>
          <p className='fontExtraLarge20'>{getTranslationText('home', 'manageSubscription')} </p>
          <>To manage subscriptions, reach out to us using the provided contact information.</>
        </div>
        <div className='custom-modal-content'>
          <div className='instruction contact-us mb8'>
            <div
              className='connect-servify footer-links bookFontStyle'
              onClick={() => {
                window.location.href = 'mailto:' + _.get(props, 'user.appConfigDetails.footerLinks.support.email') || mailLink
              }}
            >
              <Email />
              <b className='ml8'>{_.get(props, 'user.appConfigDetails.footerLinks.support.email') || mailLink}</b>
            </div>
          </div>
          <br />
          <div className='instruction contact-us mb8'>
            <div className='connect-servify fontSmall bookFontStyle'>
              <Phone />
              <b className='ml8'>{_.get(props, 'user.appConfigDetails.footerLinks.support.number') || '1800 121 4984'}</b>
            </div>
          </div>
        </div>
        <div className='center-align'>
          <MediumButton
            className='button-small mt20'
            onClick={() => props.closeModal('showManageSubscriptionModal')}
            buttonText={getTranslationText('common', 'close')}
          />
        </div>
      </div>
    </Modal>
  )
}
ManageSubscriptionModal.propTypes = {
  showContactUsModal: PropTypes.bool.isRequired,
  closeContactUsModal: PropTypes.func.isRequired,
}
