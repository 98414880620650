import { api } from '../../../config/variables'
import { setToaster } from '../../../store/toastsAndLoaders'
import { guestUserLoggedIn, sendotpreport, setUserDetails } from '../../../store/user'
import _ from 'lodash'
import { getTranslationText } from '../../../utils/languageTranslation.js'
import pushToAnalytics from '../../../utils/Analytics'

export const generateOTPAsync = (reqobj, isEmailLogin = false) => {
  return (dispatch, getState) => {
    let request = reqobj
    isEmailLogin = _.get(getState(), 'user.appConfigDetails.EnableEmailLogin', false)
    if (!isEmailLogin && !request.customerReferenceID) {
      request.phoneCode = '+' + window.PhoneCode
    }
    let bdy = JSON.stringify({
      ...request,
    })
    return axios
      .post(api.consumerUri + '/consumer/requestOTP', bdy, { headers: { customHeader: true } })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then((response) => {
        if (response.status !== 200 || !response.data.success) {
          let err = new Error(
            getTranslationText('error', response.data.statusCode) || response.data.msg || getTranslationText('error', 'somethingWentWrong')
          )
          dispatch(setToaster(err.message, 'error'))
          throw err
        } else {
          return response.data
        }
      })
  }
}

export const verifyOTPAsync = (requestObj, { allowPartialSuccess = false } = {}) => {
  return (dispatch, getState) => {
    // const isEmailLogin = _.get(getState(), 'user.appConfigDetails.EnableEmailLogin')
    let bodyObj = {
      ...requestObj,
      captcha: requestObj.captcha,
      consentArray: requestObj.consentArray || [],
    }

    let bdy = JSON.stringify(bodyObj)
    let verifyOTPResponse
    return axios
      .post(api.consumerUri + '/consumer/verifyOTPAndCreateConsumer', bdy, { headers: { customHeader: true } })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then(({ data, status }) => {
        const { data: actualData, msg: message = 'Something went wrong', success: isLoginSuccess } = data
        const isSuccess = status === 200
        const isCompleteSuccess = isSuccess && isLoginSuccess

        verifyOTPResponse = {
          MobileNo: requestObj.mobileNo,
          isLoginSuccess,
          message,
          data: actualData,
          hasMultipleContacts: !!(Array.isArray(actualData.existingContact) && actualData.existingContact.length),
        }

        if (!isCompleteSuccess && !allowPartialSuccess) {
          const error = new Error(message)
          dispatch(setToaster(error.message, 'error'))
          throw error
        }
        if (!isLoginSuccess) {
          return { ...actualData, message, isLoginSuccess }
        }
        if (verifyOTPResponse.hasMultipleContacts) {
          return { ...actualData, message, isLoginSuccess, hasMultipleContacts: true }
        }
      })
      .then(() => {
        if (verifyOTPResponse.isLoginSuccess) {
          if (window.fcWidget) {
            window.fcWidget?.user?.clear()
            window.isFCUserTokenSet = false
          }
          dispatch(sendotpreport(verifyOTPResponse))

          if (verifyOTPResponse.data.consumer) {
            verifyOTPResponse.data.profileComplete = !!(
              _.get(verifyOTPResponse, 'data.consumer.name') && _.get(verifyOTPResponse, 'data.consumer.emailID')
            )
            dispatch(setUserDetails(verifyOTPResponse.data.consumer))
            if (verifyOTPResponse.data.profileComplete || verifyOTPResponse.data.isNew) {
              const clevertapUserProfile = _.get(getState(), 'user.userDetails', {})
              // ------------ Clever Tap | store plan verify otp guest login ------------
              window.location.pathname.includes('store') &&
                pushToAnalytics(
                  'Plan Sales - OTP Verified',
                  {
                    'Mobile Number': requestObj.mobileNo,
                    OTP: requestObj.OTP,
                    'Resend-OTP': '',
                    ConsumerID: _.get(getState(), 'user.userDetails.consumerID', ''),
                  },
                  clevertapUserProfile
                )
              // ------------ Clever Tap | store plan verify otp guest login ------------
            }
          }
          verifyOTPResponse.data.profileComplete && dispatch(guestUserLoggedIn({ status: false }))
          return {
            ...verifyOTPResponse.data,
            message: verifyOTPResponse.message,
            isLoginSuccess: verifyOTPResponse.isLoginSuccess,
            hasMultipleContacts: verifyOTPResponse.hasMultipleContacts,
          }
        } else {
          return {
            ...verifyOTPResponse.data,
            message: verifyOTPResponse.message,
            isLoginSuccess: false,
            ConsumerID: false,
          }
        }
      })
      .catch(() => {
        return {
          ...verifyOTPResponse.data,
          message: getTranslationText('error', 'somethingWentWrong'),
          isLoginSuccess: false,
        }
      })
  }
}

// Verify user and Login with Auth token in case the user logging in with a new unlinked contact
export const verifyTokenAsync = (requestObj) => {
  return (dispatch, getState) => {
    let bodyObj = {
      ...requestObj,
    }
    let bdy = JSON.stringify(bodyObj)
    let verifyTokenResponse
    return axios
      .post(api.consumerUri + '/consumer/verifyTokenAndCreateConsumer', bdy, {
        headers: { Authorization: _.get(getState(), 'user.consumerDetails.data.authorization'), customHeader: true },
      })
      .catch((err) => {
        dispatch(setToaster(getTranslationText('error', 'somethingWentWrong'), 'error'))
        // console.log(err)
        throw err
      })
      .then(({ data, status }) => {
        const { data: payload, msg: message = 'Something went wrong', success: isLoginSuccess } = data
        const isSuccess = status === 200
        const isCompleteSuccess = isSuccess && isLoginSuccess
        verifyTokenResponse = {
          MobileNo: requestObj.mobileNo,
          isLoginSuccess,
          message,
          data: payload,
        }

        if (!isCompleteSuccess && !allowPartialSuccess) {
          const error = new Error(message)
          dispatch(setToaster(error.message, 'error'))
          throw error
        }
        if (!isLoginSuccess) {
          return { ...payload, message, isLoginSuccess }
        }
      })
      .then(() => {
        if (verifyTokenResponse.isLoginSuccess) {
          dispatch(sendotpreport(verifyTokenResponse))

          if (verifyTokenResponse.data.consumer) {
            verifyTokenResponse.data.profileComplete = !!(
              _.get(verifyTokenResponse, 'data.consumer.name') && _.get(verifyTokenResponse, 'data.consumer.emailID')
            )
            dispatch(setUserDetails(verifyTokenResponse.data.consumer))
            if (verifyTokenResponse.data.profileComplete || verifyOTPResponse.data.isNew) {
              const clevertapUserProfile = _.get(getState(), 'user.userDetails', {})
              // ------------ Clever Tap | store plan verify otp guest login ------------
              window.location.pathname.includes('store') &&
                pushToAnalytics(
                  'Plan Sales - OTP Verified',
                  {
                    'Mobile Number': requestObj.mobileNo,
                    OTP: requestObj.OTP,
                    'Resend-OTP': '',
                    ConsumerID: _.get(getState(), 'user.userDetails.consumerID', ''),
                  },
                  clevertapUserProfile
                )
              // ------------ Clever Tap | store plan verify otp guest login ------------
            }
          }
          verifyTokenResponse.data.profileComplete && dispatch(guestUserLoggedIn({ status: false }))
          return {
            ...verifyTokenResponse.data,
            message: verifyTokenResponse.message,
            isLoginSuccess: verifyTokenResponse.isLoginSuccess,
            hasMultipleContacts: verifyTokenResponse.hasMultipleContacts,
          }
        } else {
          return {
            ConsumerID: false,
          }
        }
      })
      .catch(() => {
        return {
          ...verifyTokenResponse.data,
          message: getTranslationText('error', 'somethingWentWrong'),
          isLoginSuccess: false,
        }
      })
  }
}
