import PropTypes from 'prop-types'
import React from 'react'
import './Devices.scss'
import { browserHistory } from 'react-router'
import {
  SidebarDevices,
  MainHeaderDevices,
  ListingPlan,
  ServicesDiv,
  DeviceDocsWidget,
  DeviceDetails,
  AddingDevices,
  ServiceHistoryWidget,
  AddingEligibleDevices,
  ProtectEligibleDevices,
  BillingHistory,
} from './DevicesSubComponents'
import {
  ImageViewerModal,
  ContactUsModal,
  RaiseARequestSupportModal,
  StepsIntrPopUp,
  ConfirmationPopUp,
  CustomIconComponent,
  MediumButton,
  RadioBox,
} from '../../../components/UIComponents'
import { Modal, Dropdown } from 'sfy-react'
import { removeVisitedUrl } from '../../../store/request'
import RatingModal from '../../../components/UIComponents/ReusableComponents/RatingModal'
import uuid from 'node-uuid'
import moment from 'moment'
import { validateDevice, validateInputChangeForDevice, canUpload } from '../../../utils/validators'
import { regexCheck } from '../../../utils/regex'
import _ from 'lodash'
import { getTranslationText } from '../../../utils/languageTranslation.js'
import { getLocaleCurrency, getLocaleDate } from '../../../utils/locale'
import { postRequestAsync } from '../../../api/genericAPIs'
import { getDocServicePayload } from '../../../utils/functions'
import { BrandSpecificConfig, docServiceModuleName } from '../../../utils/constants'
import pushToAnalytics from '../../../utils/Analytics'

const ITEMS_PER_PAGE = 5
class Devices extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tagNameBtnLoader: false,
      isEditable: false, // for making device details editiable
      DeviceDetailsEditstatus: false,
      showDeleteDeviceModal: false,
      Dbtnstatus: false, // save btn disabled status
      selectedDevice: 0,
      selectedDeviceDetails: {},
      DeviceDetailsvalues: {
        ProductDeliveryDate: '',
        WarrantyTill: '',
        DownloadedDeviceUniqueKey: '',
        ProductUniqueID: '', // IMEI
        AlternateUniqueKey: '', // serial no/IMEI 1
      },
      tempDeviceDetailValues: {},
      isAnAppleDevice: false,
      InputDataValue: {
        tagvalue: null,
        serialnovalue: '',
        imei1value: '',
        imei2value: '',
        modelnovalue: null,
        underWarrentyValue: null,
        warrantyDate: null,
      },
      addingDeviceslistbox: {
        categoryvalue: undefined,
        brandvalue: undefined,
        placeholder: 'Select a device',
        brandenabled: false,
        productvalue: undefined,
        productenabled: false,
      },
      errors: {},
      devicesInfoFieldsStatus: false,
      addingDevicesbtnstatus: false,
      enabledateEdit: false,
      updateWarranty: [
        { text: getTranslationText('common', 'yes'), isSelected: false },
        { text: getTranslationText('common', 'no'), isSelected: true },
      ],
      devicePlans: [],
      editTagNameFlg: false,
      showAddedDeviceModal: false,
      showEligiblePlanAddedDeviceModal: false,
      showDeviceDocsModal: false,
      showContactUsModal: false,
      // Loaders state
      // devicedependentLoader: true,
      deviceTagName: '',
      AddDeviceBtnLoader: false,
      updateDeviceInfoLoader: false,
      showRatingModal: false,
      ratingbtnDisabled: true,
      ratingoptns: {
        options: [],
      },
      ratingval: 0,
      usrFeedbck: '',
      selectedServiceRequest: {},
      searchDivToggle: false,
      selectedDocImg: {},
      newDeviceDocsList: {},
      hasOrderType: false,
      showOrderNoInstr: false,
      searchValue: '',
      isSearchOn: false, // if true, search query will be used to filter, if false, all the devices will be fetched
      deviceDocsLoader: false,
      deviceRepairHistoryLoader: false,
      selectedDeviceData: {},
      fetchingBrandLoader: false,
      eligiblePlanDeviceList: [],
      devicesPlanListbtnstatus: false,
      devicePlanBtnStatusLoader: false,
      DeviceIsEnrollmentPlan: false,
      raiseClaimAfterUpdateDevice: false,
      setSerialNumberError: false,
      showReviewCancelPlan: false,
      refundData: {},
      showPlanCancellationReasons: false,
      showPlanCancellationOptions: false,
      showPlanCancellationResult: false,
      selectedPlanCancellationReason: '',
      planCancellationOptions: [],
      selectedPlanCancellationOption: {},
      cancellationOptionDescription: '',
      planCancellationReasons: [],
      noSelectedDeviceFlow: false,
      showBillingHistory: false,
      billingHistoryData: {},
      planCanellationResult: {},
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0)
    // clearing the track request details
    this.props.setTrackDetails({})
    this.props.setRequestDetails({})
    this.props.clearDeviceState({})
    this.props.setDeviceAdditionModal(false)
    if (/add-device/.test(this.props.location.pathname)) {
      this.props.setglobalLoaderStatus(false)
      if (_.get(this.props, 'selectedPlanDetails.selectedFlow', false)) {
        this.props.setglobalLoaderStatus(true)
        let AuthID = _.get(this.props, 'user.consumerDetails.data.accessToken.ID')
        let requestObject = {
          PlanArray: [_.get(this.props, 'selectedPlanDetails.PlanID')],
          ConsumerID: _.get(this.props, 'user.userDetails.consumerID'),
        }
        let ApiString = '/ConsumerProduct/getPlanSubCategories'
        this.props
          .getEligibleDeviceDetailsAsync(requestObject, AuthID, ApiString)
          .then(async () => {
            if (_.get(this, 'props.devices.deviceProductCategory', []).length) {
              let categorydata = await _.get(this, 'props.devices.deviceProductCategory', []).map((data) => {
                return { text: data.ProductSubCategory, CategoryId: data.ProductSubCategoryID, value: data.ProductSubCategory }
              })
              await this.setState({
                listofProducts: {
                  categoryDataList: categorydata,
                  brandDataList: [],
                  productDataList: [],
                },
              })
            }
          })
          .finally(() => {
            this.props.setglobalLoaderStatus(false)
          })
      } else {
        let categorydata =
          _.get(this, 'props.user.appConfigDetails.Products', []).length &&
          _.get(this, 'props.user.appConfigDetails.Products', []).map((data) => {
            return { text: data.ProductSubCategory, CategoryId: data.ProductSubCategoryID, value: data.ProductSubCategory }
          })
        this.setState({
          listofProducts: {
            categoryDataList: categorydata,
            brandDataList: [],
            productDataList: [],
          },
        })
      }
    } else if (/add-eligible-devices/.test(this.props.location.pathname)) {
      if (_.get(this.props, 'selectedPlanDetails.selectedFlow', false)) {
        this.props.setEligibleDevicePlansList([])
        let arr = _.get(this, 'props.devices.eligiblePlanDeviceList', []).map((data) => {
          if (!data.ConsumerServiceRequestID) {
            let { ConsumerProductID, TagName, BrandID, ProductSubCategoryID } = data
            let ProductName = data.Product.ProductName
            return { ConsumerProductID, TagName, BrandID, ProductSubCategoryID, ProductName, value: ProductName }
          }
        })
        this.setState({
          eligiblePlanDeviceList: _.compact(arr),
        })
      } else {
        browserHistory.replace('/')
      }
    } else if (/protect-device/.test(this.props.location.pathname)) {
      this.props.setglobalLoaderStatus(false)
      // this.props.clearMyPlanDetails()
    } else {
      this.props.clearMyPlanDetails()
      this.getInitialDevices()
    }
    // fallback categorydata
    let categorydata =
      _.get(this, 'props.user.appConfigDetails.Products', []).length &&
      _.get(this, 'props.user.appConfigDetails.Products', []).map((data) => {
        return { text: data.ProductSubCategory, CategoryId: data.ProductSubCategoryID, value: data.ProductSubCategory }
      })
    this.setState({
      listofProducts: {
        categoryDataList: categorydata,
        brandDataList: [],
        productDataList: [],
      },
    })
    pushToAnalytics('My - Devices', {
      ConsumerID: _.get(this, 'props.user.userDetails.consumerID', ''),
    })
  }

  getInitialDevices = async () => {
    try {
      let ConsumerID = this.props.user.userDetails.consumerID
      this.props.setglobalLoaderStatus(true)

      const searchValueFromURL = this.props.location?.query?.cpid || ''

      this.setState({ searchValue: searchValueFromURL })

      await this.props.getPaginatedDevicesAsync(
        {
          searchQuery: searchValueFromURL || undefined,
          ConsumerID,
          pagination: {
            pageNo: 1,
            itemsPerPage: 5,
          },
        },
        true
      )
      if (_.get(this, 'props.devices.deviceDetails.supportedDevices', []).length) {
        this.setState({
          productImageUrl: this.props.user.appConfigDetails.productImageUrl,
        })
        let deviceIndex = 0
        if (this.props.location?.query?.cpid) {
          this.props.devices.deviceDetails.supportedDevices.find((device, index) => {
            if (device.ConsumerProductID === parseInt(this.props.location.query.cpid)) {
              deviceIndex = index
            }
          })
        }
        await this.loadUpdatedDeviceData(deviceIndex)
      } else {
        this.props.setglobalLoaderStatus(false)
        if (!_.get(this.props, 'user.appConfigDetails.showAddDevice', true)) {
          this.props.setToaster(getTranslationText('error', 'noDeviceAvailable'), 'error')
          browserHistory.replace('/')
        } else {
          browserHistory.replace('/devices/add-device')
        }
      }
    } catch (e) {
      this.props.setglobalLoaderStatus(false)
    }
  }

  handleOnDeviceFilter = async (e) => {
    const searchValue = e.target.value
    this.setState({ searchValue })
    // Below line is to make sure on every change of character, page number is reset to 1.
    const pageNo = this.state.searchValue?.length !== searchValue?.trim()?.length ? 1 : this.props.devices.deviceDetails.currentPage

    if (searchValue?.trim()?.length > 2) {
      try {
        this.setState({ isSearchOn: true })
        e.target.blur() // So that the user cannot type while fetching the details
        this.props.setglobalLoaderStatus(true)
        setTimeout(async () => {
          await this.props.getPaginatedDevicesAsync(
            {
              ConsumerID: this.props.user.userDetails.consumerID,
              searchQuery: searchValue,
              pagination: {
                pageNo,
                itemsPerPage: 5,
              },
            },
            true
          )
          this.props.setglobalLoaderStatus(false)
          e.target.focus() // So that the user can type again after fetching all the details
        }, 1000)
      } catch (e) {
        console.log(e)
        this.props.setglobalLoaderStatus(false)
      }
    } else if (this.state.isSearchOn) {
      e.target.blur() // So that the user cannot type while fetching the details
      await this.getInitialDevices()
      this.setState({ isSearchOn: false }, () => {
        // Setting to false so that it is not called again for less than 3 characters
        e.target.focus() // So that the user can type again after fetching all the details
      })
    }
  }

  handleShowMoreDeviceClick = async () => {
    try {
      this.props.setglobalLoaderStatus(true)
      const requestObject = {
        ConsumerID: this.props.user.userDetails.consumerID,
        pagination: {
          itemsPerPage: ITEMS_PER_PAGE,
          pageNo: this.props.devices.deviceDetails.currentPage + 1,
        },
        isShowMoreClicked: true,
      }
      if (this.state.searchValue?.trim()?.length > 2) {
        requestObject.searchQuery = this.state.searchValue
      }
      await this.props.getPaginatedDevicesAsync(requestObject)
      const element = document.getElementById(`devices-list`)
      element.scrollTo(0, element.scrollHeight)
      this.props.setglobalLoaderStatus(false)
    } catch (e) {
      console.log(e)
      this.props.setglobalLoaderStatus(false)
    }
  }

  checkIfOrderNoApplicable = (deviceInfo) => {
    let orderCheckDetails = _.get(this, 'props.devices.productBrandConfig.WarrantyCheckDetails.AllowedValues', []).find(
      (configVal) => configVal.Type.toLowerCase() === 'order no.'
    )
    if (orderCheckDetails) {
      orderCheckDetails = !!orderCheckDetails.ProductList.find((id) => id === deviceInfo.ProductID)
    }
    return orderCheckDetails
  }

  loadUpdatedDeviceData = async (deviceIndex) => {
    this.setState(
      {
        devicedependentLoader: true,
      },
      async () => {
        const element = document.getElementById(`devices-list`)
        element && element.scrollTo(0, element.scrollHeight)
      }
    )
    await this.onSelectionofDevice(deviceIndex)
  }

  loadDeviceHistory = async (deviceIndex) => {
    let AuthID = this.props.user.consumerDetails.data.accessToken.ID
    this.setState({
      deviceDocsLoader: true,
      deviceRepairHistoryLoader: true,
    })
    this.props.setglobalLoaderStatus(true)
    const supportedDevices = _.get(this, 'props.devices.deviceDetails.supportedDevices', [])
    try {
      const [deviceRepairHistoryDetails] = await Promise.all([
        await this.props.getDeviceRepairHistory(),
        await this.props.getProductDocsAsync({ ConsumerProductID: supportedDevices[deviceIndex].ConsumerProductID }, AuthID),
      ])
      this.setState({
        deviceRepairHistoryLoader: false,
        deviceDocsLoader: false,
      })
      if (deviceRepairHistoryDetails.length) {
        const cpId = supportedDevices.length && supportedDevices[deviceIndex].ConsumerProductID
        this.setState({
          deviceServiceHistory: _.compact(
            deviceRepairHistoryDetails.map((data) => {
              if (data.ConsumerProductID === cpId) {
                return data
              }
            })
          ),
        })
      }
      this.props.setglobalLoaderStatus(false)
    } catch (e) {
      console.log(e)
      this.setState({
        deviceRepairHistoryLoader: false,
      })
      this.props.setglobalLoaderStatus(false)
    }
  }

  UpdateDeviceDetails = (verdict) => {
    const narwalAndLGBrandCheck = ['NarwalUS-Web', 'LGLA-Web'].includes(_.get(this, 'props.user.appConfigDetails.app'))
    const isLGBrandCheck = ['LGLA-Web'].includes(_.get(this, 'props.user.appConfigDetails.app'))
    let verificationStateMeta = this.state.selectedDeviceDetails.VerificationStateMeta || {
      IsUniqueIdEditable: true,
      IsWarrantyEditable: true,
    }
    let ConsumerID = this.props.user.userDetails.consumerID
    let ConsumerProductID = this.state.selectedDeviceDetails.ConsumerProductID
    let AuthID = this.props.user.consumerDetails.data.accessToken.ID
    let updateObj = {
      ProductUniqueID:
        (narwalAndLGBrandCheck && !this.state.selectedDeviceDetails?.ProductUniqueID) || verificationStateMeta.IsUniqueIdEditable
          ? this.state.DeviceDetailsvalues.ProductUniqueID || ''
          : undefined,
      AlternateUniqueKey:
        (narwalAndLGBrandCheck && !this.state.selectedDeviceDetails?.AlternateUniqueKey) || verificationStateMeta.IsUniqueIdEditable
          ? this.state.DeviceDetailsvalues.AlternateUniqueKey || ''
          : undefined,
      WarrantyTill: verificationStateMeta?.IsWarrantyEditable ? this.state.DeviceDetailsvalues.WarrantyTill : undefined,
      ProductDeliveryDate: isLGBrandCheck ? this.state?.DeviceDetailsvalues?.ProductDeliveryDate : undefined,
      ModelNo: this.state.selectedDeviceDetails?.ModelNo,
      IsUnderWarranty: !!this.state.DeviceDetailsvalues?.WarrantyTill,
      TagName: this.state.selectedDeviceDetails?.TagName,
      DownloadedDeviceUniqueKey: _.get(this, 'state.DeviceDetailsvalues.DownloadedDeviceUniqueKey')
        ? _.get(this, 'state.DeviceDetailsvalues.DownloadedDeviceUniqueKey').toUpperCase()
        : '',
    }
    if (verdict?.productObj) {
      updateObj = {
        ...updateObj,
        ...verdict.productObj,
      }
    }
    let oldDetails = this.state.selectedDeviceDetails
    if (
      oldDetails &&
      (oldDetails.ProductUniqueID !== updateObj.ProductUniqueID ||
        oldDetails.AlternateUniqueKey !== updateObj.AlternateUniqueKey ||
        oldDetails.DownloadedDeviceUniqueKey !== updateObj.DownloadedDeviceUniqueKey ||
        oldDetails.WarrantyTill !== updateObj.WarrantyTill ||
        oldDetails?.ProductDeliveryDate !== updateObj?.ProductDeliveryDate)
    ) {
      this.props.setglobalLoaderStatus(true)
      this.props
        .updateDevicesAsync({
          ConsumerProductID,
          AuthID,
          updateObj: {
            ...updateObj,
            WarrantyTill: updateObj.WarrantyTill ? moment(updateObj.WarrantyTill).format('YYYY-MM-DD') : undefined,
            ProductDeliveryDate: updateObj?.ProductDeliveryDate ? moment(updateObj.ProductDeliveryDate).format('YYYY-MM-DD') : undefined,
          },
        })
        .then(() => {
          this.props
            .getPaginatedDevicesAsync(
              {
                ConsumerID,
                ConsumerProductID,
              },
              true
            )
            .then(() => {
              this.props.setglobalLoaderStatus(false)
              const supportedDevices = _.get(this, 'props.devices.deviceDetails.supportedDevices', [])
              this.setState(
                {
                  isEditable: false,
                  DeviceDetailsEditstatus: false,
                  updateDeviceInfoLoader: false,
                  selectedDeviceDetails: supportedDevices[this.state.selectedDevice],
                },
                () => {
                  this.props.setglobalLoaderStatus(false)
                  this.loadUpdatedDeviceData(this.state.selectedDevice)
                }
              )
            })
            .catch(() => {
              this.props.setglobalLoaderStatus(false)
              this.setState({
                isEditable: true,
                DeviceDetailsEditstatus: false,
                updateDeviceInfoLoader: false,
                InputDataValue: {
                  serialnovalue: '',
                  AlternateUniqueKey: '',
                },
              })
            })
        })
        .catch((data) => {
          if (narwalAndLGBrandCheck) {
            this.setState({
              setAlternateUniqueKeyError: !!Object.keys(data?.ProductUniqueID || []).length,
              setProductUniqueIDError: !!Object.keys(data?.AlternateUniqueKey || []).length,
              setNarWalLGErrorMessage: data,
              updateDeviceInfoLoader: false,
            })
          } else {
            this.loadUpdatedDeviceData(this.state.selectedDevice)
            this.setState({
              isEditable: false,
              DeviceDetailsEditstatus: false,
              updateDeviceInfoLoader: false,
            })
          }
          this.props.setglobalLoaderStatus(false)
        })
    } else {
      this.props.setglobalLoaderStatus(false)
      this.setState({
        isEditable: false,
        DeviceDetailsEditstatus: false,
        updateDeviceInfoLoader: false,
      })
    }
  }

  handleSelectDevice = (deviceIndex) => {
    this.setState({
      editTagNameFlg: false,
      selectedDevice: deviceIndex,
      isEditable: false,
      DeviceDetailsEditstatus: false,
      Dbtnstatus: false,
      raiseClaimAfterUpdateDevice: false,
    })
    this.onSelectionofDevice(deviceIndex)
  }

  onSelectionofDevice = (deviceIndex) => {
    this.props.setglobalLoaderStatus(true)
    const supportedDevices = _.get(this, 'props.devices.deviceDetails.supportedDevices', [])

    this.setState({ DeviceIsEnrollmentPlan: false })
    let AuthID = this.props.user.consumerDetails.data.accessToken.ID
    let requestObject = {
      BrandID: supportedDevices[deviceIndex]['BrandID'],
      ProductID: supportedDevices[deviceIndex]['ProductID'],
      ConsumerProductID: supportedDevices[deviceIndex]['ConsumerProductID'],
      ProductSubCategoryID: supportedDevices[deviceIndex]['ProductSubCategoryID'],
      ConsumerID: _.get(this.props, 'user.userDetails.consumerID'),
      app: _.get(this.props, 'user.appConfigDetails.AppName'),
    }
    const disable_change_payment_method = BrandSpecificConfig().header == 'RealmeEU-Web'
    this.props
      .checkDeviceEligibilityAsync(requestObject, AuthID)
      .then((response) => {
        this.props
          .getPurchasedPlansAsync(supportedDevices[deviceIndex]['ConsumerProductID'])
          .then(async (res) => {
            let cardDetails = {}
            let isPlanCancellable = false
            let fetchedPlanCancellationReasons = []
            let soldPlanIDForPlanCancellation = null
            let soldPlanCodeForPlanCancellation = null
            if (res && res.PlanArray && res.PlanArray.length) {
              res.PlanArray = await _.orderBy(res.PlanArray, ['Status'], ['asc'])
              let indexOfZero = res.PlanArray.map((i) => i.Status > -1)
              indexOfZero = _.findIndex(indexOfZero, (e) => {
                return e == false
              })
              let rightArray = res.PlanArray.splice(indexOfZero + 1, res.PlanArray.length)
              res.PlanArray = [...rightArray, ...res.PlanArray]
              isPlanCancellable =
                _.get(this.props, 'user.appConfigDetails.ShowContractCancellationButton', false) && !res.PlanArray[0].CancelReason
              if (
                !disable_change_payment_method &&
                res &&
                res.PlanArray &&
                res.PlanArray.length > 0 &&
                res.PlanArray[0].Category !== 'NORMAL' &&
                res.PlanArray[0].RequiresExternalPayment === 'SERVIFY_PAYMENT'
              ) {
                cardDetails = await this.props.fetchCardDetailsAsync(
                  res.PlanArray[0].SoldPlanID,
                  res.PlanArray[0].Status,
                  res.PlanArray[0].PlanName,
                  res.PlanArray[0].GroupSoldPlanID || undefined
                )
              }
              if (isPlanCancellable) {
                const eligibility = await this.props.checkCancellationEligibility({
                  SoldPlanID: res.PlanArray[0].SoldPlanID,
                  ConsumerID: _.get(this.props, 'user.userDetails.consumerID', undefined),
                })
                isPlanCancellable =
                  eligibility?.isCancellable && eligibility?.cancellationReasons && eligibility?.cancellationReasons.length > 0
                fetchedPlanCancellationReasons = eligibility?.cancellationReasons || []
                soldPlanIDForPlanCancellation = isPlanCancellable ? res.PlanArray[0].SoldPlanID : 0
                soldPlanCodeForPlanCancellation = isPlanCancellable ? res.PlanArray[0].SoldPlanCode : ''
              }
            }
            this.props
              .getProductsByBrand({
                brandID: supportedDevices[deviceIndex].BrandID,
              })
              .then(() => {
                let hasOrderType = !!this.checkIfOrderNoApplicable(supportedDevices[deviceIndex])
                this.setState(
                  {
                    devicedependentLoader: false,
                    selectedDevice: deviceIndex,
                    selectedDeviceDetails: supportedDevices[deviceIndex],
                    devicePlans: res.PlanArray,
                    cardDetails: cardDetails,
                    isPlanCancellable: isPlanCancellable,
                    soldPlanIDForPlanCancellation: soldPlanIDForPlanCancellation,
                    soldPlanCodeForPlanCancellation: soldPlanCodeForPlanCancellation,
                    planCancellationReasons: fetchedPlanCancellationReasons,
                    DeviceIsEnrollmentPlan: response ? response.IsEnrollmentPlan : false,
                    DeviceDetailsvalues: {
                      ProductDeliveryDate: supportedDevices[deviceIndex].ProductDeliveryDate,
                      WarrantyTill: supportedDevices[deviceIndex].WarrantyTill,
                      DownloadedDeviceUniqueKey: supportedDevices[deviceIndex].DownloadedDeviceUniqueKey,
                      ProductUniqueID: supportedDevices[deviceIndex].ProductUniqueID,
                      AlternateUniqueKey: supportedDevices[deviceIndex].AlternateUniqueKey,
                    },
                    warrantyStatus: supportedDevices[deviceIndex].IsUnderWarranty,
                    deviceTagName: supportedDevices[deviceIndex].TagName || '',
                    hasOrderType,
                  },
                  () => {
                    const isSamsungSSOLoginFlow =
                      !supportedDevices[deviceIndex]['ConsumerServiceRequestID'] &&
                      _.get(this, 'props.user.appConfigDetails.app', '').includes('Samsung-Web') &&
                      this.props.location.query.cpid

                    if (this.state.raiseClaimAfterUpdateDevice || isSamsungSSOLoginFlow) {
                      this.setState(
                        {
                          raiseClaimAfterUpdateDevice: false,
                        },
                        () => {
                          this.handleServiceMode(null, 'claim')
                        }
                      )
                    } else {
                      if (this.props.location.query?.cpid) {
                        if (supportedDevices[deviceIndex]?.ConsumerProductReferenceID === this.props.location.query?.cpid) {
                          if (this.props.location.query?.action) {
                            _.get(this, 'props.user.appConfigDetails.showNormalRepairOptions')
                              ? this.handleServiceMode(null, 'repair')
                              : this.handleServiceMode(null, 'claim')
                          }
                        }
                      }
                      if (
                        supportedDevices[deviceIndex]['ConsumerServiceRequestID'] &&
                        _.get(this, 'props.user.appConfigDetails.app', '').includes('Samsung-Web') &&
                        this.props.location.query.cpid
                      ) {
                        this.handleTrack()
                      }
                      this.props.setglobalLoaderStatus(false)
                    }
                  }
                )
                this.loadDeviceHistory(deviceIndex)
              })
              .catch(() => {
                this.setState({
                  AddDeviceBtnLoader: false,
                  InputDataValue: {
                    tagvalue: '',
                    serialnovalue: '',
                    imei1value: '',
                    imei2value: '',
                    modelnovalue: '',
                    underWarrentyValue: null,
                    warrantyDate: null,
                  },
                  DeviceIsEnrollmentPlan: false,
                })
              })
          })
          .catch(() => {
            this.props.setglobalLoaderStatus(false)
          })
      })
      .catch(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  updateValues = (e) => {
    let verdict = validateInputChangeForDevice(
      e,
      this.state.selectedDeviceDetails,
      this.props.devices.productBrandConfig.WarrantyCheckDetails,
      _.get(this, 'props.user.appConfigDetails.app')
    )
    if (verdict.isValid) {
      this.setState({
        DeviceDetailsvalues: {
          ...this.state.DeviceDetailsvalues,
          [e.target.id]: e.target.value,
        },
      })
    } else {
      this.props.setToaster(verdict.msg, 'error')
    }
  }

  DevicehandleClick = (e) => {
    const narwalAndLGBrandCheck = ['NarwalUS-Web', 'LGLA-Web'].includes(_.get(this, 'props.user.appConfigDetails.app'))
    if (e.currentTarget.id === 'device-tag') {
      this.setState({
        editTagNameFlg: false,
      })
    } else if (e.currentTarget.id === 'devicebtn') {
      // let warrentyflg
      let errorFree = true
      if (
        this.state.warrantyStatus &&
        !this.state.DeviceDetailsvalues.WarrantyTill &&
        !this.state.isAnAppleDevice &&
        !narwalAndLGBrandCheck
      ) {
        this.props.setToaster(getTranslationText('error', 'warrantyDate'), 'error')
        errorFree = false
      }
      if (errorFree) {
        this.props.setglobalLoaderStatus(true)
        validateDevice(
          this.state.selectedDeviceDetails,
          this.props.authenticateProductsAsync,
          this.state.DeviceDetailsvalues,
          this.props.devices.productBrandConfig?.WarrantyCheckDetails,
          _.get(this, 'props.user.appConfigDetails.app')
        ).then((data) => {
          if (data.isValid && !data.skipUpdateConsumerProduct) {
            this.clearAddDeviceState()
            this.setState({
              updateDeviceInfoLoader: true,
            })
            this.props.setglobalLoaderStatus(false)
            this.UpdateDeviceDetails(data)
          } else if (data.isValid && data.skipUpdateConsumerProduct && data.verifyProduct) {
            let ConsumerID = this.props.user.consumerDetails.data.consumerID
            let payload = { ConsumerID }
            if (this.state.selectedDeviceDetails?.ConsumerProductID) {
              payload.ConsumerProductID = this.state.selectedDeviceDetails?.ConsumerProductID
            } else {
              payload.pagination = {
                itemsPerPage: ITEMS_PER_PAGE,
                pageNo: 1,
              }
            }
            this.props
              .getPaginatedDevicesAsync(payload, true)
              .then(() => {
                this.props.setToaster(data.msg, 'success')
                this.props.setglobalLoaderStatus(false)
                this.setState(
                  {
                    isEditable: false,
                    DeviceDetailsEditstatus: false,
                    updateDeviceInfoLoader: false,
                  },
                  () => {
                    this.props.setglobalLoaderStatus(false)
                    this.loadUpdatedDeviceData(this.state.selectedDevice)
                  }
                )
              })
              .catch(() => {
                this.props.setglobalLoaderStatus(false)
                this.setState({
                  isEditable: false,
                  DeviceDetailsEditstatus: false,
                  updateDeviceInfoLoader: false,
                })
              })
          } else {
            this.props.setglobalLoaderStatus(false)
            this.setState({
              updateDeviceInfoLoader: false,
              setSerialNumberError: true,
            })
            this.props.setToaster(data.msg, 'error')
          }
        })
      }
    } else {
      let flg = this.state.isEditable
      if (flg) {
        flg = false
        let dateFlg = false
        this.state.tempupdateWarranty.map((data) => {
          if (data.text.toLowerCase() === 'yes' && data.isSelected) {
            dateFlg = true
          }
        })
        this.setState({
          updateWarranty: this.state.tempupdateWarranty,
          warrantyStatus: dateFlg,
          DeviceDetailsvalues: this.state.tempDeviceDetailValues,
        })
      } else {
        flg = true // making data editable
        let wFlg = this.state.updateWarranty.map((data) => {
          if (data.text.toLowerCase() === 'yes') {
            return {
              ...data,
              isSelected: !!this.state.selectedDeviceDetails.IsUnderWarranty,
            }
          } else {
            return {
              ...data,
              isSelected: !this.state.selectedDeviceDetails.IsUnderWarranty,
            }
          }
        })
        this.setState({
          tempDeviceDetailValues: this.state.DeviceDetailsvalues,
          tempupdateWarranty: wFlg,
          updateWarranty: wFlg,
        })
      }
      this.setState({
        isEditable: flg,
        DeviceDetailsEditstatus: flg,
      })
    }
  }

  toggleWarrentyStatus = (e) => {
    let pillid = parseInt(e.currentTarget.id.replace('warranty-', ''))
    this.setState({
      updateWarranty: this.state.updateWarranty.map((data, index) => {
        return {
          ...data,
          isSelected: index === pillid,
        }
      }),
    })
    this.setState({
      DeviceDetailsvalues: {
        ...this.state.DeviceDetailsvalues,
        WarrantyTill: '',
      },
      warrantyStatus: !(pillid === 1),
    })
  }

  catergoryListHandler = (val) => {
    if (val.length) {
      var categoryList = { ...this.state.addingDeviceslistbox }
      categoryList.categoryvalue = val
      categoryList.placeholder = 'Select a brand'
      let branddata
      if (_.get(this.props, 'selectedPlanDetails.selectedFlow', false)) {
        this.setState({ fetchingBrandLoader: true }, () => {
          let AuthID = _.get(this.props, 'user.consumerDetails.data.accessToken.ID')
          let requestObject = {
            PlanArray: [_.get(this.props, 'selectedPlanDetails.PlanID')],
            ConsumerID: _.get(this.props, 'user.userDetails.consumerID'),
            AdditionalFilter: 'Brand',
            ProductSubCategoryID: val[0]['CategoryId'],
          }
          let ApiString = '/ConsumerProduct/getPlanBrandsForSubcategories'
          this.props.setglobalLoaderStatus(true)
          this.props
            .getEligibleDeviceDetailsAsync(requestObject, AuthID, ApiString)
            .then(async () => {
              if (_.get(this, 'props.devices.deviceProductBrand', []).length) {
                branddata = _.get(this, 'props.devices.deviceProductBrand', []).map((brand) => {
                  return { text: brand.BrandName, BrandId: brand.BrandID, value: brand.BrandName }
                })
                const addingDeviceslistbox = {
                  categoryvalue: val,
                  brandvalue: branddata && branddata.length === 1 ? branddata : [],
                }
                this.setState(
                  {
                    listofProducts: {
                      categoryDataList: [...this.state.listofProducts.categoryDataList],
                      brandDataList: branddata,
                      productDataList: [],
                    },
                    addingDeviceslistbox,
                    addingDevicesbtnstatus: false,
                    errors: { brandsPresent: !branddata.length },
                  },
                  () => {
                    if (_.get(this, 'state.addingDeviceslistbox.brandvalue', []).length === 1) {
                      this.brandListHandler(branddata)
                    }
                  }
                )
              }
            })
            .finally(() => {
              this.setState({
                fetchingBrandLoader: false,
              })
              this.props.setglobalLoaderStatus(false)
            })
        })
      } else {
        _.get(this, 'props.user.appConfigDetails.Products', []).length &&
          _.get(this, 'props.user.appConfigDetails.Products', []).map((data) => {
            if (data.ProductSubCategoryID === val[0].CategoryId) {
              branddata = data.Brand.map((brand) => {
                return { text: brand.BrandName, BrandId: brand.BrandID, value: brand.BrandName }
              })
            }
          })
        const addingDeviceslistbox = {
          categoryvalue: val,
          brandvalue: branddata && branddata.length === 1 ? branddata : [],
        }
        this.setState(
          {
            listofProducts: {
              categoryDataList: [...this.state.listofProducts.categoryDataList],
              brandDataList: branddata,
              productDataList: [],
            },
            addingDeviceslistbox,
            addingDevicesbtnstatus: false,
            errors: { brandsPresent: !branddata.length },
          },
          () => {
            if (_.get(this, 'state.addingDeviceslistbox.brandvalue', []).length === 1) {
              this.brandListHandler(branddata)
            }
          }
        )
      }
    } else {
      this.setState({
        isAnAppleDevice: false,
        addingDeviceslistbox: {
          ...this.state.addingDeviceslistbox,
          categoryvalue: [],
          brandvalue: [],
          placeholder: 'Select a device',
          brandenabled: false,
          productvalue: [],
          productenabled: false,
        },
        listofProducts: {
          categoryDataList: [...this.state.listofProducts.categoryDataList],
          brandDataList: [],
          productDataList: [],
        },
      })
      this.clearAddDeviceInputFields()
    }
  }

  brandListHandler = (val) => {
    if (val.length) {
      var brandList = {
        ...this.state.addingDeviceslistbox,
      }
      brandList.brandvalue = val
      this.setState({
        fetchingProductLoader: _.get(this, 'state.listofProducts.productDataList', []).length === 0,
      })

      if (_.get(this.props, 'selectedPlanDetails.selectedFlow', false)) {
        let AuthID = _.get(this.props, 'user.consumerDetails.data.accessToken.ID')
        let requestObject = {
          PlanArray: [_.get(this.props, 'selectedPlanDetails.PlanID')],
          ConsumerID: _.get(this.props, 'user.userDetails.consumerID'),
          AdditionalFilter: 'Product',
          BrandID: val[0]['BrandId'],
          ProductSubCategoryID: this.state.addingDeviceslistbox.categoryvalue[0].CategoryId,
        }
        this.props.setglobalLoaderStatus(true)
        let ApiString = '/ConsumerProduct/getFilteredPlanProducts'
        this.props.getEligibleDeviceDetailsAsync(requestObject, AuthID, ApiString).then(async () => {
          let productData = _.get(this, 'props.devices.devicePlanProductList', []).map((data) => {
            return {
              text: data.ProductName,
              ProductId: data.ProductID,
              value: data.ProductName,
              ...data,
            }
          })
          this.setState({
            fetchingProductLoader: false,
            isAnAppleDevice: parseInt(val[0].BrandId) === 4,
            listofProducts: {
              categoryDataList: [...this.state.listofProducts.categoryDataList],
              brandDataList: [...this.state.listofProducts.brandDataList],
              productDataList: productData,
            },
            addingDeviceslistbox: {
              categoryvalue: this.state.addingDeviceslistbox.categoryvalue,
              brandvalue: val,
            },
            addingDevicesbtnstatus: false,
            errors: { productsPresent: !productData.length },
          })
          this.props.setglobalLoaderStatus(false)
        })
      } else {
        this.props.setglobalLoaderStatus(true)
        this.props
          .getProductsByBrand({
            brandID: val[0].BrandId,
            categoryID: this.state.addingDeviceslistbox.categoryvalue[0].CategoryId,
          })
          .then((response) => {
            let productData = response.ProductList.map((data) => {
              return {
                text: data.ProductName,
                ProductId: data.ProductID,
                value: data.ProductName,
                ...data,
              }
            })
            this.setState({
              fetchingProductLoader: false,
              isAnAppleDevice: parseInt(val[0].BrandId) === 4,
              listofProducts: {
                categoryDataList: [...this.state.listofProducts.categoryDataList],
                brandDataList: [...this.state.listofProducts.brandDataList],
                productDataList: productData,
              },
              addingDeviceslistbox: {
                categoryvalue: this.state.addingDeviceslistbox.categoryvalue,
                brandvalue: val,
              },
              addingDevicesbtnstatus: false,
              errors: { productsPresent: !productData.length },
            })
          })
          .finally(() => {
            this.props.setglobalLoaderStatus(false)
          })
      }
    } else {
      if (this.state.addingDeviceslistbox.brandenabled) {
        this.setState({
          addingDeviceslistbox: {
            ...this.state.addingDeviceslistbox,
            brandvalue: undefined,
            placeholder: 'Select a brand',
            productvalue: [],
            productenabled: false,
          },
          listofProducts: {
            categoryDataList: [...this.state.listofProducts.categoryDataList],
            brandDataList: [...this.state.listofProducts.brandDataList],
            productDataList: [],
          },
        })
      }
      this.clearAddDeviceInputFields()
    }
  }

  productListHandler = (val) => {
    if (val.length) {
      var productList = { ...this.state.addingDeviceslistbox }
      productList.productvalue = val
      productList.placeholder = 'Select a product'
      this.setState({
        hasOrderType: !!this.checkIfOrderNoApplicable(val[0]),
        addingDevicesbtnstatus: true,
        addingDeviceslistbox: productList,
        devicesInfoFieldsStatus: true,
      })
    } else {
      this.clearAddDeviceInputFields()
      this.setState({
        addingDeviceslistbox: {
          ...this.state.addingDeviceslistbox,
          productvalue: undefined,
        },
        listofProducts: {
          categoryDataList: [...this.state.listofProducts.categoryDataList],
          brandDataList: [...this.state.listofProducts.brandDataList],
          productDataList: [...this.state.listofProducts.productDataList],
        },
      })
    }
  }

  clearAddDeviceInputFields = () => {
    this.setState({
      InputDataValue: {
        tagvalue: null,
        serialnovalue: '',
        imei1value: '',
        imei2value: '',
        modelnovalue: null,
        underWarrentyValue: null,
        warrantyDate: null,
      },
      devicesInfoFieldsStatus: false,
      addingDevicesbtnstatus: false,
      newDeviceDocsList: {},
    })
  }

  handleNewDeviceDataIputChange = (e) => {
    var dummyobj = { ...this.state.InputDataValue }
    var dummyobjKey = ''
    if (e) {
      let value = e.target.value
      if (e.target.id === 'input-tag-name') {
        if (regexCheck('onChange', 'userName', value, 30)) {
          dummyobjKey = 'tagvalue'
        }
      } else if (e.target.id === 'input-serial-no') {
        this.setState({ setSerialNumberError: false })
        let keyId = 'DownloadedDeviceUniqueKey'
        if (this.state.addingDeviceslistbox.brandvalue[0].BrandId === 4) {
          keyId = regexCheck('validation', 'hasAlphachars', e.target.value) ? 'DownloadedDeviceUniqueKey' : 'ProductUniqueID'
        }
        let customEvent = {
          target: {
            id: keyId,
            value: e.target.value,
          },
        }
        let verdict = validateInputChangeForDevice(
          customEvent,
          this.state.addingDeviceslistbox.productvalue[0],
          this.props.devices.productBrandConfig.WarrantyCheckDetails
        )
        if (verdict.isValid) {
          dummyobjKey = 'serialnovalue'
        } else {
          this.props.setToaster(verdict.msg, 'error')
          return
        }
      } else if (e.target.id === 'input-imei1-no' || e.target.id === 'input-imei2-no') {
        let keyName = e.target.id === 'input-imei1-no' ? 'imei1value' : 'imei2value'
        let customEvent = {
          target: {
            id: keyName === 'imei1value' ? 'ProductUniqueID' : 'AlternateUniqueKey',
            value: e.target.value,
          },
        }
        let verdict = validateInputChangeForDevice(
          customEvent,
          this.state.addingDeviceslistbox.productvalue[0],
          this.props.devices.productBrandConfig.WarrantyCheckDetails
        )
        if (verdict.isValid) {
          dummyobjKey = keyName
        } else {
          this.props.setToaster(verdict.msg, 'error')
          return
        }
      } else if (e.currentTarget.id === 'device-warranty' && this.state.devicesInfoFieldsStatus) {
        dummyobj.underWarrentyValue = e.target.checked
        dummyobj.warrantyDate = !e.target.checked ? null : dummyobj.warrantyDate
        this.setState({
          addingDevicesbtnstatus: e.target.checked
            ? !!dummyobj.warrantyDate
            : !!_.get(this, 'state.addingDeviceslistbox.productvalue[0].value'),
          ...(!e.target.checked && { newDeviceDocsList: {} }),
        })
      }
      if (dummyobjKey !== '') {
        dummyobj[dummyobjKey] = value
      }
      this.setState({
        InputDataValue: dummyobj,
      })
    }
  }

  addNewDevice = (verdict) => {
    this.props.setglobalLoaderStatus(true)
    let productObj = verdict.productObj || {}
    let ConsumerID = this.props.user.userDetails.consumerID
    let AuthID = this.props.user.consumerDetails.data.accessToken.ID
    let devicedata = {
      TagName: this.state.InputDataValue.tagvalue,
      ModelNo: this.state.InputDataValue.modelnovalue,
      WarrantyTill: this.state.InputDataValue.warrantyDate
        ? moment(this.state.InputDataValue.warrantyDate).format('YYYY-MM-DD')
        : undefined,
      ProductUniqueID: this.state.InputDataValue.imei1value || '',
      AlternateUniqueKey: this.state.InputDataValue.imei2value || '',
      DownloadedDeviceUniqueKey: this.state.InputDataValue.serialnovalue || '',
      ...productObj,
    }
    // if (this.state.hasOrderType) {
    //   devicedata = {
    //     ...devicedata,
    //     ProductUniqueID: devicedata.DownloadedDeviceUniqueKey,
    //     DownloadedDeviceUniqueKey: undefined
    //   }
    // }
    let data = {
      ConsumerID,
      show: false,
      IsUnderWarranty: this.state.InputDataValue.underWarrentyValue,
      IsActive: 1,
      validationError: false,
      msg: '',
      showP: false,
      // 'disable': false,
      ProductSubCategoryID: this.state.addingDeviceslistbox.categoryvalue[0].CategoryId,
      BrandID: this.state.addingDeviceslistbox.brandvalue[0].BrandId,
      ProductID: this.state.addingDeviceslistbox.productvalue[0].ProductId,
      ...devicedata,
    }
    let appName = _.get(this, 'props.user.appConfigDetails.app', undefined)
    this.props
      .addDeviceAsync({
        data,
        AuthID,
      })
      .then((response) => {
        pushToAnalytics('My - Devices - Device Added', data)
        if (_.get(this.props, 'selectedPlanDetails.selectedFlow', false)) {
          const { GroupSoldPlanID, PlanID } = _.get(this.props, 'selectedPlanDetails')
          const AuthID = _.get(this.props, 'user.consumerDetails.data.accessToken.ID')
          const requestObject = {
            GroupSoldPlanID: GroupSoldPlanID,
            ConsumerProductID: response.ConsumerProductID,
            PlanID: PlanID,
            ConsumerID: _.get(this.props, 'user.userDetails.consumerID'),
            app: _.get(this.props, 'user.appConfigDetails.app'),
          }
          this.props
            .addDeviceToSubscriptionAsync(requestObject, AuthID)
            .then(() => {
              addedDeviceCPid = response.ConsumerProductID
              this.getDeviceDetails()
            })
            .catch(() => {
              this.props.setglobalLoaderStatus(false)
              this.setState({
                AddDeviceBtnLoader: false,
                InputDataValue: {
                  tagvalue: '',
                  serialnovalue: '',
                  imei1value: '',
                  imei2value: '',
                  modelnovalue: '',
                  underWarrentyValue: null,
                  warrantyDate: null,
                },
              })
            })
        } else if (appName === 'ServifyCare-Web') {
          let AuthID = this.props.user.consumerDetails.data.accessToken.ID
          const { BrandID, ProductID, ProductSubCategoryID, ConsumerProductID } = response
          let requestObject = {
            BrandID,
            ProductID,
            ProductSubCategoryID,
            ConsumerID: _.get(this.props, 'user.userDetails.consumerID'),
            ConsumerProductID,
            app: appName,
          }
          this.props
            .checkDeviceEligibilityAsync(requestObject, AuthID)
            .then(() => {
              addedDeviceCPid = response.ConsumerProductID
              this.getDeviceDetails()
            })
            .catch(() => {
              this.setState({
                AddDeviceBtnLoader: false,
                InputDataValue: {
                  tagvalue: '',
                  serialnovalue: '',
                  imei1value: '',
                  imei2value: '',
                  modelnovalue: '',
                  underWarrentyValue: null,
                  warrantyDate: null,
                },
              })
            })
        } else {
          this.getDeviceDetails()
        }
      })
      .catch(() => {
        this.setState({
          AddDeviceBtnLoader: false,
          InputDataValue: {
            tagvalue: '',
            serialnovalue: '',
            imei1value: '',
            imei2value: '',
            modelnovalue: '',
            underWarrentyValue: null,
            warrantyDate: null,
          },
        })
      })
  }

  getDeviceDetails = async (addedDeviceCPid) => {
    let ConsumerID = this.props.user.consumerDetails.data.consumerID
    let AuthID = this.props.user.consumerDetails.data.accessToken.ID
    this.props.setglobalLoaderStatus(true)
    const payload = { ConsumerID, AuthID }
    if (addedDeviceCPid) {
      payload.ConsumerProductID = addedDeviceCPid
    } else {
      payload.pagination = {
        itemsPerPage: ITEMS_PER_PAGE,
        pageNo: 1,
      }
    }
    this.props
      .getPaginatedDevicesAsync(payload, true)
      .then(async () => {
        this.props.setglobalLoaderStatus(false)
        const supportedDevices = _.get(this, 'props.devices.deviceDetails.supportedDevices', [])
        if (supportedDevices.length) {
          this.props.devices.deviceDetails.supportedDevices.find((data, index) => {
            if (data.ConsumerProductID === addedDeviceCPid) {
              this.setState(
                {
                  selectedDeviceDetails: data,
                  selectedDevice: index,
                  AddDeviceBtnLoader: false,
                  productImageUrl: this.props.user.appConfigDetails.productImageUrl,
                },
                async () => {
                  if (!_.isEmpty(this.state.newDeviceDocsList)) {
                    if (window.useDocService) {
                      await this.props.addProductDocToDSAsync({
                        ConsumerProductID: _.get(this.state, 'selectedDeviceDetails.ConsumerProductID'),
                        documentId: 'deviceDocs-upload',
                        fileType: _.get(this, 'state.newDeviceDocsList.fileType'),
                        fileName: _.get(this, 'state.newDeviceDocsList.fileName'),
                        uploadURL: _.get(this, 'state.newDeviceDocsList.imgFilePath'),
                        docServiceUUID: _.get(this, 'state.newDeviceDocsList.docServiceUUID'),
                      })
                      this.setState({
                        newDeviceDocsList: {},
                      })
                    } else {
                      this.addDocsToProduct()
                    }
                  }
                  this.loadUpdatedDeviceData(index)
                  browserHistory.push('/devices')
                  if (
                    _.get(this.props, 'selectedPlanDetails.selectedFlow', false) ||
                    _.get(this.props, 'selectedPlanDetails.IsEnrollmentPlan', false)
                  ) {
                    this.props.setSelectedEligiblePlanDeviceInfo([])
                    this.setState({
                      showEligiblePlanAddedDeviceModal: true,
                      devicesPlanListbtnstatus: false,
                      devicePlanBtnStatusLoader: false,
                    })
                  } else {
                    this.setState({
                      showAddedDeviceModal: true,
                    })
                  }
                }
              )
            } else {
              this.setState(
                {
                  selectedDeviceDetails: data,
                  selectedDevice: 0,
                  AddDeviceBtnLoader: false,
                  productImageUrl: this.props.user.appConfigDetails.productImageUrl,
                  showAddedDeviceModal: true,
                  noSelectedDeviceFlow: true,
                },
                () => {
                  browserHistory.replace('/devices')
                }
              )
            }
          })
        } else {
          this.setState({ AddDeviceBtnLoader: false })
          browserHistory.replace('/devices/add-device')
        }
      })
      .catch(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  handleNewDeviceValidations = () => {
    let dataObj = {
      ProductUniqueID: this.state.InputDataValue.imei1value,
      AlternateUniqueKey: this.state.InputDataValue.imei2value,
      DownloadedDeviceUniqueKey: this.state.InputDataValue.serialnovalue,
      TagName: _.get(this.state, 'InputDataValue.tagvalue', undefined),
    }
    if (this.state.InputDataValue.serialnovalue && this.state.addingDeviceslistbox.brandvalue[0].BrandId === 4) {
      if (!regexCheck('validation', 'hasAlphachars', this.state.InputDataValue.serialnovalue)) {
        dataObj = {
          ...dataObj,
          DownloadedDeviceUniqueKey: '',
          ProductUniqueID: this.state.InputDataValue.serialnovalue,
        }
      }
    }
    this.props.setglobalLoaderStatus(true)
    validateDevice(
      this.state.addingDeviceslistbox.productvalue[0],
      this.props.authenticateProductsAsync,
      dataObj,
      this.props.devices.productBrandConfig.WarrantyCheckDetails,
      _.get(this, 'props.user.appConfigDetails.app')
    )
      .then((data) => {
        this.props.setglobalLoaderStatus(false)
        if (data.isValid && !data.skipUpdateConsumerProduct) {
          this.setState({
            AddDeviceBtnLoader: true,
          })
          this.addNewDevice(data)
        } else if (data.isValid && data.skipUpdateConsumerProduct && data.verifyProduct) {
          this.getDeviceDetails()
        } else {
          this.setState({ setSerialNumberError: true })
          this.props.setToaster(data.msg, 'error')
        }
      })
      .catch(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  handleAddedDeviceModal = () => {
    this.setState({
      showAddedDeviceModal: false,
    })
  }

  addPlanInAddedDevice = () => {
    // redirection in select plan page
    this.props.setSelectedEligiblePlanDeviceInfo(this.state.selectedDeviceDetails)
    this.handleAddedDeviceModal()
    this.props.setSelectedPurchasedPlanDetails()
    browserHistory.push('/devices/protect-device')
  }

  handleEligiblePlanAddedDeviceModal = () => {
    this.setState(
      {
        showEligiblePlanAddedDeviceModal: false,
      },
      () => {
        this.props.clearMyPlanDetails()
      }
    )
  }

  handleServiceMode = (e, customTargetID) => {
    let currentTargetId = null
    if (e) {
      e.persist()
      currentTargetId = e.currentTarget.id
    } else if (customTargetID) currentTargetId = customTargetID
    let selectedDeviceDetails = this.state.selectedDeviceDetails
    if (_.includes(['claim', 'repair'], currentTargetId))
      pushToAnalytics('File_claim_button_click', {
        ConsumerID: _.get(this, 'props.user.userDetails.consumerID', ''),
      })
    // to call airtel sepecific device plan details
    let isRaiseClaimCoolingPeriodCheck = _.get(this, 'props.user.appConfigDetails.isRaiseClaimCoolingPeriodCheck', false)
    let redirectionUrlPromise
    if (isRaiseClaimCoolingPeriodCheck && _.includes(['claim', 'repair'], currentTargetId)) {
      let {
        mobileNo,
        // app
      } = _.get(this, 'props.user.userDetails')
      let requestObject = {
        MobileNo: mobileNo,
        app: 'Airtel',
        ProductName: selectedDeviceDetails.Product.ProductName,
        ProductUniqueID: selectedDeviceDetails.ProductUniqueID,
        Brand: selectedDeviceDetails.Brand.BrandName,
        PlanID: _.get(selectedDeviceDetails, 'PolicyDetails[0].PlanID'),
      }
      redirectionUrlPromise = this.props
        .getConsumerPlanDetailsAsync(
          {
            ...requestObject,
          },
          this.props.user.consumerDetails.data.accessToken.ID
        )
        .then(() => {
          if (!_.get(this, 'props.request.coolingOffPlansDetails.SoldPlan.CanRaiseClaim', false)) {
            let errMsg = _.get(this, 'props.user.appConfigDetails.coolingPeriodClaimErrorMessage')
            this.props.setToaster(errMsg, 'error')
            let err = new Error(errMsg)
            throw err
          }
          this.props.setglobalLoaderStatus(false)
        })
        .catch((err) => {
          this.props.setglobalLoaderStatus(false)
          throw err
        })
    } else {
      redirectionUrlPromise = new Promise((resolve) => {
        resolve()
      })
    }

    redirectionUrlPromise
      .then(() => {
        /** Temperary changes till apple Watch Claim in not allowed */
        if (
          (!_.get(this, 'props.user.appConfigDetails.isRepairAllowed', true) ||
            !_.get(this, 'state.selectedDeviceDetails.isRepairAllowed', true)) &&
          _.includes(['claim', 'repair'], currentTargetId)
        ) {
          this.setState({
            isRepairAllowed: true,
          })
          return
        }
        // Manatory IMEI required checks
        const narwalAndLGBrandCheck = ['NarwalUS-Web', 'LGLA-Web'].includes(_.get(this, 'props.user.appConfigDetails.app'))
        let selectedProductInfo = this.state.selectedDeviceDetails
        let uniqueIDValueMissing = selectedProductInfo.ProductConfig?.IsUniqueIdMandatory
          ? selectedProductInfo.ProductSubCategoryID === 12
            ? !selectedProductInfo.ProductUniqueID && !selectedProductInfo.AlternateUniqueKey
            : narwalAndLGBrandCheck
            ? !(selectedProductInfo?.ProductUniqueID && selectedProductInfo?.AlternateUniqueKey)
            : !selectedProductInfo.DownloadedDeviceUniqueKey
          : narwalAndLGBrandCheck
          ? !(selectedProductInfo?.ProductUniqueID && selectedProductInfo?.AlternateUniqueKey)
          : false
        // invoice upload check when IsInvoiceRequired is true
        if (selectedProductInfo.ProductConfig.IsInvoiceRequired && selectedProductInfo.IsUnderWarranty) {
          if (!_.get(this, 'props.devices.productDocs', []).length) {
            this.props.setToaster(getTranslationText('warn', 'uploadInvoice'), 'warning')
            return
          }
        }
        if (
          (selectedProductInfo.ProductConfig.IsUniqueIdMandatory ||
            selectedProductInfo.ProductConfig.isSerializedProduct ||
            narwalAndLGBrandCheck) &&
          _.includes(['repair', 'claim'], currentTargetId) &&
          uniqueIDValueMissing
        ) {
          let uniqeIDType = this.state.hasOrderType ? 'Order number' : 'Serial Number'
          if (selectedProductInfo.ProductSubCategoryID === 12) {
            uniqeIDType = 'IMEI'
          }
          const warningUpdateDevice = getTranslationText('warn', 'updateDevice').replace('@uniqeIDType', uniqeIDType)
          this.props.setToaster(warningUpdateDevice, 'warning')
          this.setState({
            raiseClaimAfterUpdateDevice: true,
          })
          let wFlg = this.state.updateWarranty.map((data) => {
            if (data.text.toLowerCase() === 'yes') {
              return {
                ...data,
                isSelected: !!selectedProductInfo.IsUnderWarranty,
              }
            } else {
              return {
                ...data,
                isSelected: selectedProductInfo.IsUnderWarranty ? false : !selectedProductInfo.IsUnderWarranty,
              }
            }
          })
          this.setState({
            isEditable: true,
            tempupdateWarranty: wFlg,
            updateWarranty: wFlg,
            DeviceDetailsEditstatus: true,
          })
          return
        }
        this.props.setglobalLoaderStatus(true)
        this.props.clearRequestState()
        const appName = _.get(this, 'props.user.appConfigDetails.app', '')
        let isSubsciptionRepairFlow = ['OneplusNA-Web'].includes(appName)

        let customerPlansPromise =
          _.includes(['repair', 'claim'], currentTargetId) &&
          isSubsciptionRepairFlow &&
          _.get(selectedDeviceDetails, 'DeviceState.DeviceStateMeta.ShowRaiseClaim')
            ? this.props.fetchEligiblePlansNewAsync({
                ConsumerProductID: selectedDeviceDetails.ConsumerProductID,
              })
            : new Promise((resolve) => resolve())
        customerPlansPromise
          .then(() => {
            this.props.setglobalLoaderStatus(false)
            let mailLink = _.get(this, 'props.user.appConfigDetails.footerLinks.support.email', 'support@servify.in')
            let checkforPlan =
              isSubsciptionRepairFlow && _.get(selectedDeviceDetails, 'DeviceState.DeviceStateMeta.ShowRaiseClaim')
                ? this.props.request.eligiblePlansNew &&
                  this.props.request.eligiblePlansNew.find((group) => _.includes([-2, 1], group.GroupStatus))
                : this.state.selectedDeviceDetails.PolicyDetails.find(
                    (planInfo) => planInfo.Status === 1 && planInfo.NoOfRepairsAllowed - planInfo.NoOfRepairsUsed > 0
                  )
            let path = '/request/modes'
            if (checkforPlan) {
              path = '/request/plan'
              // ------------- CleverTap | Book Repair - Initiated --------/
              const ProductName_ = _.get(selectedDeviceDetails, 'Product.ProductName', '')
              const BrandName_ = _.get(selectedDeviceDetails, 'Brand.BrandName', '')
              const ProductUniqueID_ = _.get(selectedDeviceDetails, 'ProductUniqueID', '')
              const clevertapUserProfile = _.get(this, 'props.user', {})
              pushToAnalytics(
                'Book Repair - Initiated',
                {
                  'Device Name': ProductName_,
                  Brand: BrandName_,
                  'Device IMEI / Serial Nos.': ProductUniqueID_,
                  ConsumerProductID: selectedDeviceDetails.ConsumerProductID || undefined,
                },
                clevertapUserProfile
              )
              // ------------- CleverTap | Book Repair - Initiated --------/
            } else {
              let planObject = {
                PlanType: 'normalflow',
                SoldPlanID: -1,
                isSetProgramatically: true,
              }
              this.props.setSelectedDamageType(planObject)
            }
            this.props.setSelectedDevice(this.state.selectedDeviceDetails)
            switch (currentTargetId) {
              case 'repair':
                browserHistory.push({
                  pathname: path,
                  query: {
                    from: 'mydevice',
                    type: 'repair',
                  },
                })
                break
              case 'claim':
                if (checkforPlan) {
                  browserHistory.push({
                    pathname: '/request/plan',
                    query: {
                      from: 'mydevice',
                      type: 'repair',
                    },
                  })
                } else {
                  this.props.setToaster(getTranslationText('error', 'noEligiblePlans'))
                }
                break
              case 'install':
                browserHistory.push({
                  pathname: path,
                  query: {
                    from: 'mydevice',
                    type: 'install',
                  },
                })
                break
              case 'estimate':
                this.props.setSeviceEstimatorDevice({
                  cpId: selectedDeviceDetails.ConsumerProductID,
                  from: 'mydevice',
                })
                browserHistory.push('/service-estimator')
                break
              case 'service':
                browserHistory.push({
                  pathname: path,
                  query: {
                    from: 'mydevice',
                    type: 'service',
                  },
                })
                break
              case 'contact':
                if (_.get(this, 'props.user.appConfigDetails.ShowContactDetailsInModal')) {
                  this.setState({
                    showContactUsModal: true,
                  })
                } else {
                  window.location.href = 'mailto:' + mailLink
                }
                break
            }
          })
          .catch((err) => {
            console.log(err)
            this.props.setglobalLoaderStatus(false)
          })
      })
      .catch((err) => {
        console.log('error: ', err)
      })
  }

  closeContactUsModal = () => {
    this.setState({
      showContactUsModal: false,
    })
  }

  handleTrack = () => {
    browserHistory.push('/track?id=' + window.btoa(this.state.selectedDeviceDetails.ConsumerServiceRequestID.toString()))
    this.props.setSelectedDevice(this.state.selectedDeviceDetails)
  }

  deleteModalOpen = () => {
    this.setState({
      showDeleteDeviceModal: true,
    })
  }

  deleteModalClose = () => {
    this.setState({
      showDeleteDeviceModal: false,
    })
  }

  handleDelete = () => {
    let ConsumerID = this.props.user.userDetails.consumerID
    this.deleteModalClose()
    this.props.setglobalLoaderStatus(true)
    this.props
      .deleteDeviceAsync({
        ConsumerProductID: this.state.selectedDeviceDetails.ConsumerProductID,
        AuthID: this.props.user.consumerDetails.data.accessToken.ID,
      })
      .then(() => {
        // this.props.setglobalLoaderStatus(false)
        this.props
          .getPaginatedDevicesAsync(
            {
              ConsumerID,
              pagination: {
                pageNo: 1,
                itemsPerPage: 5,
              },
            },
            true
          )
          .then(() => {
            this.props.setglobalLoaderStatus(false)
            this.props.setToaster(getTranslationText('success', 'deviceDeletedSuccessfully'), 'success')
            if (_.get(this, 'props.devices.deviceDetails.supportedDevices', []).length) {
              let deviceIndex = 0
              this.loadUpdatedDeviceData(deviceIndex)
            } else {
              browserHistory.push('/')
            }
          })
      })
      .catch(() => {
        this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
        this.props.setglobalLoaderStatus(false)
      })
  }

  clearAddDeviceState = (callback = () => {}) => {
    this.setState(
      {
        isAnAppleDevice: false,
        isRepairAllowed: false,
        InputDataValue: {
          tagvalue: '',
          serialnovalue: '',
          imei1value: '',
          imei2value: '',
          modelnovalue: '',
          underWarrentyValue: null,
          warrantyDate: null,
        },
        addingDeviceslistbox: {
          categoryvalue: [],
          brandvalue: [],
          placeholder: 'Select a device',
          brandenabled: false,
          productvalue: [],
          productenabled: false,
        },
        devicesInfoFieldsStatus: false,
        addingDevicesbtnstatus: false,
        listofProducts: {
          ...this.state.listofProducts,
          brandDataList: [],
          productDataList: [],
        },
      },
      callback
    )
  }

  openDeviceDocsGallery = (e) => {
    this.setState({
      showDeviceDocsModal: true,
      selectedDocImg: e,
    })
  }

  handleDeviceDocsDel = () => {
    if (!_.isEmpty(this.state.selectedDocImg)) {
      this.setState({
        deviceDocsLoader: true,
      })
      if (/add-device/.test(this.props.location.pathname)) {
        this.setState(
          {
            newDeviceDocsList: {},
          },
          () => {
            this.setState({ showDeviceDocsModal: false })
          }
        )
        return
      }
      this.props
        .deleteDeviceProductDocs({
          ConsumerProductDocumentID: parseInt(this.state.selectedDocImg.ConsumerProductDocumentID),
          AuthID: this.props.user.consumerDetails.data.accessToken.ID,
        })
        .then(() => {
          this.setState({ showDeviceDocsModal: false })
          this.props.setToaster(getTranslationText('success', 'documentDeleted'), 'success')
          this.props
            .getProductDocsAsync(
              {
                ConsumerProductID: this.state.selectedDeviceDetails.ConsumerProductID,
              },
              this.props.user.consumerDetails.data.accessToken.ID
            )
            .then(() => {
              this.setState({
                deviceDocsLoader: false,
              })
            })
            .catch(() => {
              this.setState({
                deviceDocsLoader: false,
              })
            })
        })
        .catch(() => {
          this.setState({
            deviceDocsLoader: false,
          })
        })
    }
  }

  uploadProductDocToDS = async (e) => {
    const documentId = e.target.name
    const file = e.target.files[0]
    const uniqueId = uuid.v4()
    const fileExtension = file?.name?.split('.')?.pop()
    const token = _.get(this, 'props.user.consumerDetails.data.accessToken.ID')
    this.props.setglobalLoaderStatus(true)
    canUpload(e, { allowedTypes: ['image', 'pdf'] })
      .then(async () => {
        let payload = getDocServicePayload(file, 'ConsumerProductDocument')
        const response = await postRequestAsync(
          'internal/document/upload',
          payload,
          { Authorization: token, module: docServiceModuleName },
          window.docServiceUri
        )
        if (this.props.params.p === 'add-device') {
          this.setState({
            newDeviceDocsList: {
              imgFilePath: response.uploadURL,
              viewFilePath: response.url,
              imageID: uniqueId,
              fileType: file?.type,
              fileName: uniqueId + (fileExtension ? '.' + fileExtension : ''),
              docServiceUUID: response.docServiceUUID,
            },
          })
        } else {
          await this.props.addProductDocToDSAsync({
            ConsumerProductID: _.get(this.state, 'selectedDeviceDetails.ConsumerProductID'),
            documentId,
            fileType: file?.type,
            fileName: uniqueId + (fileExtension ? '.' + fileExtension : ''),
            ...response,
          })
          await this.props.getProductDocsAsync(
            {
              ConsumerProductID: _.get(this.state, 'selectedDeviceDetails.ConsumerProductID'),
            },
            this.props.user.consumerDetails.data.accessToken.ID
          )
          this.setState({
            uploadedInvoiceImg: uniqueId,
            uploadedInvoiceImgFlg: true,
          })
        }
        this.props.setglobalLoaderStatus(false)
      })
      .catch((err) => {
        this.props.setToaster(err.message, 'error')
        console.log(err)
        this.props.setglobalLoaderStatus(false)
      })
  }

  handleUploadDocs = (e) => {
    let file = e.target.files[0]
    let uniqueId = uuid.v1()
    canUpload(e, { allowedTypes: ['image', 'pdf'] })
      .then(() => {
        this.setState({
          deviceDocsLoader: true,
        })
        this.props.setglobalLoaderStatus(true)
        this.props
          .getSignedUrlAsync(
            {
              AuthID: this.props.user.consumerDetails.data.accessToken.ID,
              data: {
                Type: file.type,
                Key: uniqueId,
              },
            },
            file,
            e.target.name
          )
          .then(() => {
            this.props.setglobalLoaderStatus(false)
            if (this.props.params.p === 'add-device') {
              this.setState({
                newDeviceDocsList: {
                  imgFilePath: this.props.user.s3Uploads.fileUrl,
                  imageID: this.props.user.s3Uploads.uuid,
                },
              })
            } else {
              this.addDocsToProduct(uniqueId)
            }
          })
          .catch(() => {})
      })
      .catch((error) => this.props.setToaster(error.message, 'error'))
  }

  addDocsToProduct = (uniqueId) => {
    this.props
      .addProductDocAsync({
        ConsumerProductID: this.state.selectedDeviceDetails.ConsumerProductID,
        AuthID: this.props.user.consumerDetails.data.accessToken.ID,
      })
      .then(() => {
        this.props.getProductDocsAsync(
          {
            ConsumerProductID: this.state.selectedDeviceDetails.ConsumerProductID,
          },
          this.props.user.consumerDetails.data.accessToken.ID
        )
        this.setState({
          uploadedInvoiceImg: uniqueId,
          uploadedInvoiceImgFlg: true,
          deviceDocsLoader: false,
        })
      })
      .catch(() => {
        this.setState({
          deviceDocsLoader: false,
        })
      })
  }

  toggleEditableOptions = (e) => {
    this.setState({
      [e.currentTarget.id]: true,
    })
  }

  handleTagNameInputValue = (e) => {
    if (e.which === 13) {
      this.updateDeviceTagName()
    } else {
      if (regexCheck('onChange', 'userName', e.target.value, 30)) {
        this.setState({
          deviceTagName: e.target.value,
        })
      }
    }
  }

  updateDeviceTagName = async () => {
    if ((this.state.deviceTagName || '') !== (this.state.selectedDeviceDetails.TagName || '')) {
      let ConsumerID = this.props.user.userDetails.consumerID
      if (!this.state.tagNameBtnLoader) {
        this.setState({
          tagNameBtnLoader: true,
        })
        try {
          await this.props.updateDevicesAsync({
            ConsumerProductID: this.state.selectedDeviceDetails.ConsumerProductID,
            updateObj: {
              TagName: this.state.deviceTagName,
            },
          })
          this.setState({ editTagNameFlg: false, tagNameBtnLoader: false })
          await this.props.getPaginatedDevicesAsync(
            {
              ConsumerID,
              ConsumerProductID: this.state.selectedDeviceDetails.ConsumerProductID,
            },
            true
          )
          const supportedDevices = _.get(this, 'props.devices.deviceDetails.supportedDevices', [])
          this.setState({ selectedDeviceDetails: supportedDevices[this.state.selectedDevice] }, () => {
            this.loadUpdatedDeviceData(this.state.selectedDevice)
          })
        } catch (e) {
          console.log(e)
          this.setState({
            editTagNameFlg: false,
            tagNameBtnLoader: false,
          })
        }
      }
    } else {
      this.setState({
        editTagNameFlg: false,
      })
    }
  }

  handleBackBtnClick = () => {
    removeVisitedUrl()
    browserHistory.goBack()
  }

  handlePendingReqAction = (request) => {
    if (!this.state.showRatingModal && request) {
      let srcTypeId = parseInt(request.ConsumerServiceRequest.ServiceTypeID)
      let index = 0
      if (Object.keys(this.props.user.appConfigDetails.Feedbacks).includes(srcTypeId.toString())) {
        index = srcTypeId
      }
      this.setState({
        ratingbtnDisabled: true,
        usrFeedbck: '',
        showRatingModal: true,
        ratingval: 0,
        feedBackOptnsList: this.props.user.appConfigDetails.Feedbacks[index],
        selectedServiceRequest: request,
      })
    }
    this.setState({
      showRatingModal: !this.state.showRatingModal,
    })
  }

  handleRatingInput = (e) => {
    var txt = e.target.value
    if (regexCheck('onChange', 'plainTxt', txt, 50)) {
      this.setState({ usrFeedbck: txt })
    } else {
      e.preventDefault()
    }
  }

  handlePillSelect = (e) => {
    let pillId = e.currentTarget.id.replace('ratingpill-', '')
    this.setState({
      ratingoptns: {
        ...this.state.ratingoptns,
        options: this.state.ratingoptns.options.map((pill, index) => {
          return {
            ...pill,
            isSelected: index === parseInt(pillId) ? !pill.isSelected : pill.isSelected,
          }
        }),
      },
    })
  }

  handlefeedback = () => {
    let userProblems = ''
    this.state.ratingoptns.options.find((pills) => {
      if (pills.isSelected) {
        userProblems += pills.text + ', '
      }
    })
    userProblems = (userProblems.length && userProblems.substring(0, userProblems.length - 2)) || ''
    this.handlePendingReqAction()
    this.props
      .servicefeedbackAsync({
        AuthID: this.props.user.consumerDetails.data.accessToken.ID,
        ConsumerServiceRequestID: this.state.selectedServiceRequest.ConsumerServiceRequest.ConsumerServiceRequestID,
        ratingval: this.state.ratingval,
        userProblems,
        feedback: this.state.usrFeedbck,
      })
      .then(() => {
        this.loadDeviceHistory(this.state.selectedDevice)
      })
  }

  starRating = (id) => {
    if (id !== this.state.ratingval) {
      this.setState({
        ratingbtnDisabled: false,
        ratingval: id,
      })

      let indexFeedbackOptns = this.state.feedBackOptnsList.map((optnList) => {
        return {
          ...optnList,
          options: optnList.options.map((data) => {
            return { text: data, isSelected: false }
          }),
        }
      })
      this.setState({
        ratingoptns: {
          ...indexFeedbackOptns[id - 1],
        },
      })
    }
  }

  handleDatePickerOnChange = (date, id) => {
    if (id === 'WarrantyTill') {
      this.setState({
        DeviceDetailsvalues: {
          ...this.state.DeviceDetailsvalues,
          WarrantyTill: date,
        },
      })
    } else if (id === 'add-warranty-date') {
      this.setState({
        addingDevicesbtnstatus: this.state.InputDataValue.underWarrentyValue
          ? !!date
          : !!_.get(this, 'state.addingDeviceslistbox.productvalue[0].value'),
        InputDataValue: {
          ...this.state.InputDataValue,
          warrantyDate: date,
        },
      })
    } else if (id === 'ProductDeliveryDate') {
      this.setState({
        DeviceDetailsvalues: {
          ...this.state.DeviceDetailsvalues,
          ProductDeliveryDate: date,
        },
      })
    }
  }

  handleRedirection = async (customLink, Link) => {
    if (_.get(this, 'props.user.appConfigDetails.SkipRedirection', false)) {
      if (window.useLogger) {
        let loggerObj = {
          apiName: 'Devices - handleRedirection',
          value: {
            customLink: customLink,
            Link: Link,
          },
          event: 'before setTncLink from Devices',
          identifiers: {
            ConsumerID: _.get(this, 'props.user.userDetails.consumerID'),
          },
        }
        this.props.loggerActionAsync(loggerObj)
      }
      await this.props.setTncLink(Link)
      localStorage.setItem('planBenefitsLink', Link)
      return customLink
    } else {
      return Link
    }
  }

  toggleOrdersInstr = () => {
    this.setState({
      showOrderNoInstr: !this.state.showOrderNoInstr,
    })
  }

  clearNewDeviceState = () => {
    this.setState({
      newDeviceData: {
        newDeviceTagName: '',
        newDeviceSerialNo: '',
        newDeviceImei1No: null,
        newDeviceImei2No: null,
        newDeviceModelNo: '',
        newDeviceWarrntyStatus: null,
        newDeviceWarrantyDate: '',
      },
      deviceRadiobtnvalue: null,
      selectFrombtnStatus: true,
    })
  }

  handleDeviceSelection = (productObj) => {
    this.clearNewDeviceState()
    // this.props.setEligibleDevicePlansList([]) // shoulb ne uncomment
    if (productObj.length) {
      this.setState({
        isAnAppleDevice: !!(productObj[0].BrandID === 4),
        mobileCategory: !!(productObj[0].ProductSubCategoryID === 12),
      })
      var pId = productObj[0].ConsumerProductID
      if (pId === -1) {
        // redirect to new device addition page
        let categorydata =
          _.get(this, 'props.user.appConfigDetails.Products', []).length &&
          _.get(this, 'props.user.appConfigDetails.Products', []).map((data) => {
            // filters based on type mentioned on url
            if (this.props.location.query.type) {
              if (_.includes(data.SupportedModes, this.props.location.query.type)) {
                return {
                  text: data.ProductSubCategory,
                  CategoryId: data.ProductSubCategoryID,
                  value: data.ProductSubCategory,
                }
              }
            } else {
              return {
                text: data.ProductSubCategory,
                CategoryId: data.ProductSubCategoryID,
              }
            }
          })
        this.setState(
          {
            uniqueIdrequired: false,
            defListValue: false,
            addingDeviceslistbox: {
              ...this.state.addingDeviceslistbox,
              categoryenabled: true,
            },
            listofProducts: {
              ...this.state.listofProducts,
              categoryDataList: _.compact(categorydata),
            },
            deviceRadiobtnvalue: null,
          },
          () => {
            if (_.get(this, 'state.listofProducts.categoryDataList', []).length === 1) {
              this.catergoryListHandler(this.state.listofProducts.categoryDataList)
            }
          }
        )
      } else {
        this.props.devices.deviceDetails.supportedDevices.find((productData) => {
          if (productData.ConsumerProductID === pId) {
            this.props.setSelectedDevice(productData)
            let { ProductSubCategory, ProductSubCategoryID } = productData.ProductSubCategory
            let { BrandID, BrandName } = productData.Brand
            let { ProductName, ProductID } = productData.Product
            let uniqueIdrequired = false
            // let reqType = _.get(this, 'props.location.query.type')
            if (productData.ProductConfig.IsUniqueIdMandatory) {
              if (ProductSubCategoryID === 12) {
                uniqueIdrequired = !(productData.ProductUniqueID && productData.ProductUniqueID.length)
              } else {
                uniqueIdrequired = !(productData.DownloadedDeviceUniqueKey && productData.DownloadedDeviceUniqueKey.length)
              }
            }
            this.setState({
              uniqueIdrequired,
              defListValue: true,
              addingDeviceslistbox: {
                ...this.state.addingDeviceslistbox,
                categoryvalue: [
                  {
                    text: ProductSubCategory,
                    CategoryId: ProductSubCategoryID,
                    value: ProductSubCategory,
                  },
                ],
                brandvalue: [
                  {
                    text: BrandName,
                    BrandId: BrandID,
                    value: BrandName,
                  },
                ],
                productvalue: [
                  {
                    text: ProductName,
                    ProductId: ProductID,
                    ProductConfig: {
                      ...productData.ProductConfig,
                    },
                  },
                ],
                TagName: productData.TagName,
                ProductUniqueID: productData.ProductUniqueID || productData.DownloadedDeviceUniqueKey,
              },
              selectedDeviceData: productData,
              SelectedDeviceCPId: pId,
              selectFrombtnStatus: uniqueIdrequired,
              deviceRadiobtnvalue: productData.IsUnderWarranty,
              newDeviceDocsList: {},
              isInvoiceUploaded: false,
              devicesPlanListbtnstatus: true,
              devicePlanBtnStatusLoader: false,
            })
          }
        })
      }
    } else {
      this.setState({
        uniqueIdrequired: false,
        selectedDeviceData: {},
        selectFrombtnStatus: true,
        addingDeviceslistbox: {
          ...this.state.addingDeviceslistbox,
          categoryvalue: [],
          categoryenabled: false,
        },
        listofProducts: {
          ...this.state.listofProducts,
          categoryDataList: [],
        },
        devicesPlanListbtnstatus: false,
        devicePlanBtnStatusLoader: false,
      })
      // this.clearNewDeviceState()
    }
  }

  addEligibleDevicesInPlans = (selectedPlanInfo = {}) => {
    let requestObject
    let ConsumerProductID
    this.setState({
      devicesPlanListbtnstatus: true,
      devicePlanBtnStatusLoader: true,
    })
    this.props.setglobalLoaderStatus(true)
    const AuthID = _.get(this.props, 'user.consumerDetails.data.accessToken.ID')
    if (_.get(this.props, 'selectedPlanDetails.selectedFlow', false)) {
      ConsumerProductID = _.get(this, 'state.selectedDeviceData.ConsumerProductID')
      const { GroupSoldPlanID, PlanID } = _.get(this.props, 'selectedPlanDetails')
      requestObject = {
        GroupSoldPlanID: GroupSoldPlanID,
        ConsumerProductID,
        PlanID: PlanID,
        ConsumerID: _.get(this.props, 'user.userDetails.consumerID'),
        app: _.get(this.props, 'user.appConfigDetails.app'),
      }
    } else {
      this.props.setSelectedPurchasedPlanDetails(selectedPlanInfo)
      const { GroupSoldPlanID, PlanID } = selectedPlanInfo
      ConsumerProductID = _.get(this, 'props.devices.selectedEligibleDeviceInfo.ConsumerProductID', [])
      requestObject = {
        GroupSoldPlanID: GroupSoldPlanID,
        ConsumerProductID,
        PlanID: PlanID,
        ConsumerID: _.get(this.props, 'user.userDetails.consumerID'),
        app: _.get(this.props, 'user.appConfigDetails.app'),
      }
    }

    this.props
      .addDeviceToSubscriptionAsync(requestObject, AuthID)
      .then(() => {
        this.getDeviceDetails()
      })
      .catch(() => {
        this.props.setglobalLoaderStatus(false)
        this.setState({
          AddDeviceBtnLoader: false,
          InputDataValue: {
            tagvalue: '',
            serialnovalue: '',
            imei1value: '',
            imei2value: '',
            modelnovalue: '',
            underWarrentyValue: null,
            warrantyDate: null,
            devicesPlanListbtnstatus: true,
            devicePlanBtnStatusLoader: false,
          },
        })
      })
  }

  closeEventModal = (modelName) => {
    this.setState({
      [modelName]: false,
    })
  }

  showBillingHistory = (state, data) => {
    this.setState({
      showBillingHistory: state,
      billingHistoryData: data,
    })
  }

  onClickChangePaymentMethod = (plan) => {
    let reqObj = {
      data: {
        ConsumerID: _.get(this.props, 'user.userDetails.consumerID', undefined),
        CountryID: _.get(this.props, 'user.selectedCountryData.CountryID', undefined),
        CurrencyID: _.get(this.props, 'user.selectedCountryData.currencies[0].CurrencyID', undefined),
        PaymentMode: 'EMI',
        PlanArray: [
          {
            PlanID: plan.PlanID,
          },
        ],
        action: 'update',
        app: _.get(this.props, 'user.appConfigDetails.AppName', undefined),
        isSubscriptionPayment: true,
        isCardChange: true,
        SoldPlanID: plan.SoldPlanID,
        PaymentInfoDetails: {
          PlanArray: [
            {
              PlanID: plan.PlanID,
            },
          ],
          Source: _.get(this.props, 'user.appConfigDetails.AppName', undefined),
          CountryID: _.get(this.props, 'user.selectedCountryData.CountryID', undefined),
          CountryCode: _.get(this.props, 'user.selectedCountryData.currencies[0].CurrencyCode', undefined),
          Status: 1,
          CurrencyID: _.get(this.props, 'user.selectedCountryData.currencies[0].CurrencyID', undefined),
          isInternalModule: false,
          interface: 'consumerWeb',
          app: _.get(this.props, 'user.appConfigDetails.AppName', undefined),
        },
      },
    }
    this.props.setglobalLoaderStatus(true)
    try {
      this.props
        .getPayableAmountForNonLoginFlow(reqObj, true)
        .then(() => {
          this.props.setglobalLoaderStatus(false)
          pushToAnalytics('Change Payment Method', {
            ConsumerID: _.get(this, 'props.user.userDetails.consumerID', ''),
            SoldPlanID: plan.SoldPlanID,
            PlanName: plan.PlanName,
            PlanID: plan.PlanID,
            screen_class: 'My Devices',
          })
          this.props.storeLastKnowPathName({
            path: '/devices',
            params: {
              ...this.props.location.query,
            },
          })
          browserHistory.push({
            pathname: '/payment',
            query: {
              [this.props.location.query.region ? 'region' : '']: this.props.location.query.region,
            },
          })
        })
        .catch(() => {
          this.props.setglobalLoaderStatus(false)
        })
    } catch (e) {
      console.log('e - ', e)
      this.props.setglobalLoaderStatus(false)
    }
  }

  setShowPlanCancellationResult = (status, result = {}) => {
    this.setState({
      showPlanCancellationResult: status,
      planCanellationResult: result,
    })
  }

  setShowPlanCancellationOptions = (status) => {
    this.setState({
      showPlanCancellationOptions: status,
    })
  }
  handlePlanCancellationOptionSelection = (selectedOption) => {
    let updated = this.state.planCancellationOptions.map((option) => {
      return { ...option, isSelected: selectedOption.CancellationOption === option.CancellationOption }
    })
    this.setState({
      selectedPlanCancellationOption: selectedOption,
      planCancellationOptions: updated,
    })
  }

  setShowPlanCancellationReasons = (status) => {
    this.setState({
      showPlanCancellationReasons: status,
      selectedPlanCancellationReason: this.state.planCancellationReasons.length > 0 ? this.state.planCancellationReasons[0] : '',
    })
  }

  initiatePlanCancellation = async () => {
    try {
      this.setShowReviewCancelPlan(false)
      this.props.setglobalLoaderStatus(true)
      // console.log('selectedPlanCancellationOption - ', this.state.selectedPlanCancellationOption)
      const response = await this.props.cancelContract({
        SoldPlanID: this.state.soldPlanIDForPlanCancellation,
        ReasonCode: this.state.selectedPlanCancellationReason?.CancellationReasonCode,
        CancellationOption: this.state.selectedPlanCancellationOption?.CancellationOption,
        ConsumerID: _.get(this.props, 'user.userDetails.consumerID', undefined),
      })
      console.log('response - ', response)
      this.setShowPlanCancellationResult(true, response)
      this.props.setglobalLoaderStatus(false)
    } catch (e) {
      this.props.setglobalLoaderStatus(false)
    }
  }
  fetchPlanCancellationOptions = async (reasonObj) => {
    try {
      this.props.setglobalLoaderStatus(true)
      // console.log('soldPlanIDForPlanCancellation - ', this.state.soldPlanIDForPlanCancellation)
      const resp = await this.props.getPlanCancellationOptions({
        SoldPlanID: this.state.soldPlanIDForPlanCancellation,
        ReasonCode: reasonObj?.CancellationReasonCode,
        ConsumerID: _.get(this.props, 'user.userDetails.consumerID', undefined),
      })
      this.props.setglobalLoaderStatus(false)
      if (!resp.success || !resp.data.length) return this.setShowPlanCancellationResult(true, resp)
      if (resp.data && resp.data.length > 1) {
        let updated = resp.data.map((option) => {
          return { ...option, isSelected: false }
        })
        this.setState(
          {
            planCancellationOptions: updated,
            selectedPlanCancellationOption: {},
          },
          () => {
            this.setShowPlanCancellationOptions(true)
          }
        )
        this.props.setglobalLoaderStatus(false)
      } else if (resp.data && resp.data.length == 1) {
        this.setState(
          {
            selectedPlanCancellationOption: resp.data[0],
            cancellationOptionDescription: resp.data[0].CancellationOptionDescription,
          },
          () => {
            this.calculateRefund()
          }
        )
      }
    } catch (e) {
      this.props.setglobalLoaderStatus(false)
    }
  }

  calculateRefund = async () => {
    try {
      this.setShowPlanCancellationOptions(false)
      this.props.setglobalLoaderStatus(true)
      // console.log('selectedPlanCancellationOption - ', this.state.selectedPlanCancellationOption)
      const response = await this.props.calculateRefund({
        SoldPlanID: this.state.soldPlanIDForPlanCancellation,
        ReasonCode: this.state.selectedPlanCancellationReason?.CancellationReasonCode,
        CancellationOption: this.state.selectedPlanCancellationOption?.CancellationOption,
        ConsumerID: _.get(this.props, 'user.userDetails.consumerID', undefined),
      })
      // console.log('response - ', response)
      this.props.setglobalLoaderStatus(false)
      if (!response.success || !response?.data?.isCancellable) return this.setShowPlanCancellationResult(true, response)
      if (response?.data?.isCancellable) {
        this.setRefundData(response.data)
        this.setShowReviewCancelPlan(true)
      }
    } catch (e) {
      this.props.setglobalLoaderStatus(false)
    }
  }

  initiateCancelPlan = () => {
    this.setState({
      cancellationOptionDescription: '',
    })
    if (this.state.planCancellationReasons.length === 1) {
      this.setState(
        {
          selectedPlanCancellationReason: this.state.planCancellationReasons[0],
        },
        () => {
          this.fetchPlanCancellationOptions(this.state.planCancellationReasons[0])
        }
      )
    } else {
      this.setShowPlanCancellationReasons(true)
    }
  }

  setShowReviewCancelPlan = (status) => {
    this.setState({
      showReviewCancelPlan: status,
    })
  }

  setRefundData = (data) => {
    this.setState({ refundData: data })
  }

  setCurrentPage = (pageNo, SoldPlanID, planDetails) => {
    this.viewBillingHistory(SoldPlanID, planDetails, pageNo)
  }

  viewBillingHistory = async (SoldPlanID, planDetails, currentPage = 1) => {
    this.props.setglobalLoaderStatus(true)
    try {
      const obj = {
        SoldPlanID,
        ConsumerID: _.get(this.props, 'user.userDetails.consumerID', undefined),
        app: BrandSpecificConfig().header,
        pagination: {
          currentPage: currentPage,
          itemsPerPage: 10,
        },
      }
      const history = await this.props.getBillingHistory(obj)
      // console.log('hisotry - ', history)
      if (history?.data && history?.data?.PaymentDetails && history?.data?.PaymentDetails?.length) {
        this.showBillingHistory(true, { ...history.data, planDetails })
      } else {
        this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
      }
      this.props.setglobalLoaderStatus(false)
    } catch (e) {
      this.props.setglobalLoaderStatus(false)
    }
  }

  render() {
    let renderDom = ''
    const isProtectBtn = _.get(this, 'props.devices.deviceEligiblePlansList', []).length
    const dateFormat = _.get(this, 'props.user.selectedCountryData.countryConfig.DateFormat.DEFAULT', 'DD/MM/YYYY')
    const locale = _.get(this, 'props.user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
    const currencyCode = _.get(this, 'props.user.selectedCountryData.CurrencyCode', '')
    const hideWarrantyToggle = _.get(this, 'props.user.appConfigDetails.hideWarrantyToggle', false)
    const hideWarrantyStatus = _.get(this, 'props.user.appConfigDetails.HideWarrantyStatus', false)
    if (this.props.params.p === 'add-device') {
      if (_.get(this.props, 'user.appConfigDetails.showAddDevice', true)) {
        renderDom = (
          <div className='adding-first-device mb48 mt20'>
            <AddingDevices
              handleinputdata={this.handleNewDeviceDataIputChange}
              fetchingProductLoader={this.state.fetchingProductLoader}
              isAnAppleDevice={this.state.isAnAppleDevice}
              handleBackBtnClick={this.handleBackBtnClick}
              handleDatePickerOnChange={this.handleDatePickerOnChange}
              clearAddDeviceState={this.clearAddDeviceState}
              AddDeviceBtnLoader={this.state.AddDeviceBtnLoader}
              addDevice={this.handleNewDeviceValidations}
              listofProducts={this.state.listofProducts}
              addingDevicesbtnstatus={this.state.addingDevicesbtnstatus}
              InputDataValue={this.state.InputDataValue}
              devicesInfoFieldsStatus={this.state.devicesInfoFieldsStatus}
              listboxdata={this.state.addingDeviceslistbox}
              catergoryListHandler={this.catergoryListHandler}
              brandListHandler={this.brandListHandler}
              productListHandler={this.productListHandler}
              toggleOrdersInstr={this.toggleOrdersInstr}
              handleUploadDocs={this.handleUploadDocs}
              newDeviceDocsList={this.state.newDeviceDocsList}
              openDeviceDocsGallery={this.openDeviceDocsGallery}
              hasOrderType={this.state.hasOrderType}
              fetchingBrandLoader={this.state.fetchingBrandLoader}
              dateFormat={dateFormat}
              selectedPlanDetails={this.props.selectedPlanDetails}
              errors={this.state.errors}
              uploadProductDocToDS={this.uploadProductDocToDS}
              setSerialNumberError={this.state.setSerialNumberError}
              hideWarrantyToggle={hideWarrantyToggle}
              {...this.props}
            />
          </div>
        )
      }
    } else if (this.props.params.p === 'add-eligible-devices') {
      renderDom = (
        <div className='adding-first-device mb48 mt20'>
          <AddingEligibleDevices
            handleDeviceSelection={this.handleDeviceSelection}
            listboxdata={this.state.addingDeviceslistbox}
            listofProducts={this.state.listofProducts}
            deviceInfo={this.state.selectedDeviceData}
            addEligibleDevicesInPlans={this.addEligibleDevicesInPlans}
            eligiblePlanDeviceList={this.state.eligiblePlanDeviceList}
            devicesPlanListbtnstatus={this.state.devicesPlanListbtnstatus}
            devicePlanBtnStatusLoader={this.state.devicePlanBtnStatusLoader}
            handleBackButton={this.handleBackBtnClick}
            hideWarrantyToggle={hideWarrantyToggle}
            {...this.props}
          />
        </div>
      )
    } else if (this.props.params.p === 'protect-device') {
      renderDom = (
        <div className='adding-first-device mb48 mt20'>
          <ProtectEligibleDevices
            {...this.props}
            addingDeviceslistbox={this.state.addingDeviceslistbox}
            handleBackBtnClick={this.handleBackBtnClick}
            addEligibleDevicesInPlans={this.addEligibleDevicesInPlans}
            dateFormat={dateFormat}
            locale={locale}
            currencyCode={currencyCode}
          />
        </div>
      )
    } else {
      renderDom = (
        <div className='row mb48 mt8'>
          {this.state.showBillingHistory ? (
            <BillingHistory
              {...this.props}
              billingHistoryData={this.state.billingHistoryData}
              setCurrentPage={this.setCurrentPage}
              showBillingHistory={this.showBillingHistory}
            />
          ) : (
            <>
              <div className='col-sm-3'>
                <div className='row'>
                  <div className='col-sm-12 device-sider-section'>
                    {_.get(this, 'props.devices.deviceDetails.supportedDevices') && (
                      <SidebarDevices
                        options={this.state.options}
                        supportedDevices={_.get(this, 'props.devices.deviceDetails.supportedDevices', [])}
                        showMore={this.state.showMore}
                        showMoreLength={this.state.showMoreLength}
                        searchValue={this.state.searchValue}
                        handleShowMoreClick={this.handleShowMoreDeviceClick}
                        handleOnFilter={this.handleOnDeviceFilter}
                        selectedDevice={this.state.selectedDevice}
                        handleSelect={this.handleSelectDevice}
                        productImageUrl={this.state.productImageUrl}
                        user={this.props.user}
                        searchDivToggle={this.state.searchDivToggle}
                        clearMyPlanDetails={this.props.clearMyPlanDetails}
                        {...this.props}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className='col-sm-9'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <MainHeaderDevices
                      selectedDeviceDetails={this.state.selectedDeviceDetails}
                      devicePlans={this.state.devicePlans}
                      handleTrack={this.handleTrack}
                      deleteModalToggle={this.deleteModalOpen}
                      productImageUrl={this.state.productImageUrl}
                      updateDeviceTagName={this.updateDeviceTagName}
                      handleTagNameInputValue={this.handleTagNameInputValue}
                      toggleEditableOptions={this.toggleEditableOptions}
                      tagNameBtnLoader={this.state.tagNameBtnLoader}
                      deviceTagName={this.state.deviceTagName}
                      DeviceIsEnrollmentPlan={this.state.DeviceIsEnrollmentPlan}
                      protectSelectedDevice={this.addPlanInAddedDevice}
                      editTagNameFlg={this.state.editTagNameFlg}
                    />
                  </div>
                </div>
                <div className='row subwidgets-marginfixes'>
                  {this.state.devicePlans && this.state.devicePlans.length > 0 && (
                    <div className='col-sm-6 col-lg-4'>
                      <ListingPlan
                        {...this.props}
                        devicePlans={this.state.devicePlans}
                        cardDetails={this.state.cardDetails}
                        isPlanCancellable={this.state.isPlanCancellable}
                        handleRedirection={this.handleRedirection}
                        onClickChangePaymentMethod={this.onClickChangePaymentMethod}
                        setShowReviewCancelPlan={this.setShowReviewCancelPlan}
                        setRefundData={this.setRefundData}
                        initiateCancelPlan={this.initiateCancelPlan}
                        viewBillingHistory={this.viewBillingHistory}
                      />
                    </div>
                  )}
                  {Object.keys(_.get(this, 'state.selectedDeviceDetails', {})).length > 0 &&
                    BrandSpecificConfig().header !== 'AppleEU-Web' && (
                      <div className='col-sm-6 col-lg-4'>
                        <ServicesDiv
                          handleServiceMode={this.handleServiceMode}
                          user={this.props.user}
                          selectedDeviceDetails={this.state.selectedDeviceDetails}
                        />
                      </div>
                    )}
                  {this.state.isRepairAllowed && (
                    <RaiseARequestSupportModal
                      user={this.props.user}
                      showRaiseARequestSupportModal={this.state.isRepairAllowed}
                      closeModal={() => this.setState({ isRepairAllowed: false })}
                    />
                  )}
                  <div className='col-sm-6 col-lg-4'>
                    <DeviceDetails
                      {...this.props}
                      isEditable={this.state.isEditable}
                      isAnAppleDevice={this.state.isAnAppleDevice}
                      Datavalue={this.state.DeviceDetailsvalues}
                      handleClick={this.DevicehandleClick}
                      editstatus={this.state.DeviceDetailsEditstatus}
                      updateValues={this.updateValues}
                      warrantyStatus={this.state.warrantyStatus}
                      warrantyStatusval={this.state.selectedDeviceDetails.IsUnderWarranty}
                      handleDatePickerOnChange={this.handleDatePickerOnChange}
                      selectedDeviceDetails={this.state.selectedDeviceDetails}
                      Dbtnstatus={this.state.Dbtnstatus}
                      warrantyDetails={this.state.updateWarranty}
                      toggleWarrentyStatus={this.toggleWarrentyStatus}
                      loaderState={this.state.updateDeviceInfoLoader}
                      hasOrderType={this.state.hasOrderType}
                      dateFormat={dateFormat}
                      locale={locale}
                      hideWarrantyStatus={hideWarrantyStatus}
                      setAlternateUniqueKeyError={this.state.setAlternateUniqueKeyError}
                      setProductUniqueIDError={this.state.setProductUniqueIDError}
                      setNarWalLGErrorMessage={this.state.setNarWalLGErrorMessage}
                    />
                  </div>
                  {BrandSpecificConfig().header !== 'LGLA-Web' && BrandSpecificConfig().header !== 'AppleEU-Web' && (
                    <div className='col-sm-6 col-lg-4'>
                      <ServiceHistoryWidget
                        deviceServiceHistory={this.state.deviceServiceHistory}
                        handlePendingReqAction={this.handlePendingReqAction}
                        dateFormat={dateFormat}
                      />
                    </div>
                  )}
                  {!_.get(this, 'props.user.appConfigDetails.hideDeviceDocsUpload', false) && (
                    <div className='col-sm-6 col-lg-4'>
                      <DeviceDocsWidget
                        loaderState={this.state.deviceDocsLoader}
                        handleUploadDocs={this.handleUploadDocs}
                        uploadProductDocToDS={this.uploadProductDocToDS}
                        deviceDocs={this.props.devices.productDocs}
                        openDeviceDocsGallery={this.openDeviceDocsGallery}
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )
    }
    return (
      <div>
        {renderDom}
        {this.state.showReviewCancelPlan && (
          <Modal className='sfy-modal cancel-contract-confirm' showModal={this.state.showReviewCancelPlan}>
            <div className='modal-cross-icon'>
              <CustomIconComponent className='text-right' onClick={() => this.setShowReviewCancelPlan(false)} />
            </div>
            <div className='text-left'>
              <div className='boldFontStyle mt16 cancel-contract-confirm-text'>
                {getTranslationText('device', 'planCancelRequestSummary')}
              </div>
              {this.state?.cancellationOptionDescription.length > 0 && (
                <div className='mt16 cancel-contract-review-text'>{this.state?.cancellationOptionDescription}</div>
              )}
              <div className='mt16 cancel-contract-review-text-parent'>
                <div className='cancel-contract-review-text'>{getTranslationText('device', 'contractID')}</div>
                <div className='cancel-contract-review-text'>{this.state.soldPlanCodeForPlanCancellation}</div>
              </div>
              <div className='mt16 cancel-contract-review-text-parent'>
                <div className='cancel-contract-review-text'>{getTranslationText('device', 'cancellationInitiationDate')}</div>
                <div className='cancel-contract-review-text'>
                  {this.state.refundData?.CancellationInitiationDate
                    ? getLocaleDate(dateFormat, this.state.refundData.CancellationInitiationDate)
                    : '-'}
                </div>
              </div>
              {this.state.refundData.PlanCancellationDate >= this.state.refundData.CancellationInitiationDate && (
                <div className='mt16 cancel-contract-review-text-parent'>
                  <div className='cancel-contract-review-text'>{getTranslationText('device', 'effectiveCancellationDate')}</div>
                  <div className='cancel-contract-review-text'>
                    {this.state.refundData?.PlanCancellationDate
                      ? getLocaleDate(dateFormat, this.state.refundData.PlanCancellationDate)
                      : '-'}
                  </div>
                </div>
              )}
              <div className='mt16 cancel-contract-review-text-parent'>
                <div className='cancel-contract-review-text'>{getTranslationText('device', 'coverageEndDate')}</div>
                <div className='cancel-contract-review-text'>
                  {this.state.refundData?.CoverageEndDate ? getLocaleDate(dateFormat, this.state.refundData.CoverageEndDate) : '-'}
                </div>
              </div>
              {this.state?.refundData?.RefundAmount || this.state?.refundData?.RefundAmount == 0 ? (
                <div className='mt16 cancel-contract-review-text-parent'>
                  <div className='cancel-contract-review-text'>{getTranslationText('device', 'totalRefund')}</div>
                  <div className='cancel-contract-review-text'>
                    {getLocaleCurrency(locale, currencyCode, this.state.refundData.RefundAmount)}
                  </div>
                </div>
              ) : (
                ''
              )}
              {this.state.refundData.RefundAmount > 0 && this.state.refundData.RefundProcessingType === 'SERVIFY_AUTO_REFUND' && (
                <div className='mt16 cancel-contract-review-text text-note'>{getTranslationText('device', 'refundNote')}</div>
              )}
              <div className='mt40'>
                <MediumButton
                  className='button-small cancel-contract-confirm-btn-text-no'
                  buttonText={getTranslationText('device', 'keepContract')}
                  onClick={() => this.setShowReviewCancelPlan(false)}
                />
                <MediumButton
                  className='button-small button-ghost ml16 cancel-contract-confirm-btn-text-yes'
                  buttonText={getTranslationText('device', 'cancelContract')}
                  onClick={() => {
                    this.setShowReviewCancelPlan(false)
                    this.initiatePlanCancellation()
                  }}
                  disabled={
                    !(
                      this.state.refundData?.CancellationInitiationDate &&
                      this.state.refundData?.PlanCancellationDate &&
                      this.state.refundData?.CoverageEndDate
                    )
                  }
                />
              </div>
            </div>
          </Modal>
        )}

        {this.state.showPlanCancellationReasons && (
          <Modal className='sfy-modal cancel-contract-reasons' showModal={this.state.showPlanCancellationReasons}>
            <div className='modal-cross-icon'>
              <CustomIconComponent className='text-right' onClick={() => this.setShowPlanCancellationReasons(false)} />
            </div>
            <div className='text-left'>
              <div className='cancel-contract-heading mt8'>{getTranslationText('device', 'reasonForCancellation')}</div>
              <div className='mt20 mb24'>
                <div className='cancel-contract-subheading mb4'>{getTranslationText('device', 'reason')}</div>
                <Dropdown
                  id='plan-cancel-dropdown'
                  classObject={{
                    container: `mb36`,
                  }}
                  options={this.state.planCancellationReasons.map((reason) => ({
                    ...reason,
                    label: reason.CancellationReasonDescription,
                    value: reason.CancellationReasonDescription,
                  }))}
                  handleOnChange={(obj) => {
                    this.setState({
                      selectedPlanCancellationReason: obj,
                    })
                  }}
                  value={this.state.selectedPlanCancellationReason.CancellationReasonDescription || ''}
                  optionLength={this.state.planCancellationReasons.length}
                />
              </div>
              <div className='mt40'>
                <MediumButton
                  className='button-small cancel-contract-confirm-btn-text-no'
                  buttonText={getTranslationText('device', 'cancelContract')}
                  onClick={() => {
                    this.setShowPlanCancellationReasons(false)
                    this.fetchPlanCancellationOptions(this.state.selectedPlanCancellationReason)
                  }}
                />
              </div>
            </div>
          </Modal>
        )}
        {this.state.showPlanCancellationOptions && (
          <Modal className='sfy-modal cancel-contract-options' showModal={this.state.showPlanCancellationOptions}>
            <div className='modal-cross-icon'>
              <CustomIconComponent className='text-right' onClick={() => this.setShowPlanCancellationOptions(false)} />
            </div>
            <div className='text-left'>
              <div className='boldFontStyle cancel-contract-option-title mt8'>{getTranslationText('device', 'cancellationOption')}</div>
              <div className='mt32'>
                {this.state.planCancellationOptions.length > 0 &&
                  this.state.planCancellationOptions.map((option) => (
                    <RadioBox
                      key={option.CancellationOption}
                      name={'plan-cancellation-options-radio'}
                      className={{ container: 'plan-cancellation-options-radio' }}
                      isSelected={option.isSelected}
                      handleOnClick={() => this.handlePlanCancellationOptionSelection(option)}
                    >
                      <div className='mt12 plan-cancellation-option-heading'>{option.CancellationOptionDisplay}</div>
                      <div className='mt8 plan-cancellation-option-desc'>{option.CancellationOptionDescription}</div>
                    </RadioBox>
                  ))}
              </div>
              <div className='mt40'>
                <MediumButton
                  className='button-small cancel-contract-confirm-btn-text-no'
                  buttonText={getTranslationText('common', 'continue')}
                  onClick={this.calculateRefund}
                  disabled={!this.state.selectedPlanCancellationOption?.CancellationOption}
                />
              </div>
            </div>
          </Modal>
        )}
        {this.state.showPlanCancellationResult && (
          <Modal className='sfy-modal cancel-contract-result' showModal={this.state.showPlanCancellationResult}>
            <div className='modal-cross-icon'>
              <CustomIconComponent
                className='text-right'
                onClick={() => {
                  this.setShowPlanCancellationResult(false)
                  if (this.state?.planCanellationResult?.success) this.handleSelectDevice(this.state.selectedDevice)
                }}
              />
            </div>
            <div className='text-center'>
              <img src={!this.state?.planCanellationResult?.success ? '/commonAssets/failed.png' : '/commonAssets/successImg.png'} />
              <div className='cancel-contract-result-heading mt20'>
                {getTranslationText(
                  'device',
                  !this.state?.planCanellationResult?.success ? 'cancellationRequestFailed' : 'cancellationRequestAccepted'
                )}
              </div>
              {!this.state?.planCanellationResult?.success ? (
                <div className='cancel-contract-result-desc mt20 mb20'>{this.state?.planCanellationResult?.msg}</div>
              ) : (
                <>
                  <div className='cancel-contract-result-desc mt20'>
                    {getTranslationText('device', 'youWillReceiveConfirmation')}
                    {this.state.refundData.RefundAmount > 0 && this.state.refundData.RefundProcessingType === 'SERVIFY_AUTO_REFUND' && (
                      <span className='cancel-contract-result-desc ml5'>{getTranslationText('device', 'refundNote')}</span>
                    )}
                  </div>
                  <div className='cancel-contract-result-desc mt4 mb20'>{getTranslationText('device', 'hopeToSeeYouBack')}</div>
                </>
              )}

              <MediumButton
                className='button-small cancel-contract-confirm-btn-text-no'
                buttonText={getTranslationText('common', 'okay')}
                onClick={() => {
                  this.setShowPlanCancellationResult(false)
                  if (this.state?.planCanellationResult?.success) this.handleSelectDevice(this.state.selectedDevice)
                }}
              />
            </div>
          </Modal>
        )}
        {this.state.showAddedDeviceModal && Object.keys(_.get(this, 'state.selectedDeviceDetails', {})).length ? (
          <Modal className='sfy-modal successfully-added-device-content' showModal={this.state.showAddedDeviceModal}>
            <div className='modal-cross-icon'>
              <CustomIconComponent className='text-right' onClick={() => this.closeEventModal('showAddedDeviceModal')} />
            </div>
            <div className='text-center'>
              {<img src={'/commonAssets/successImg.png'} />}
              <div className='boldFontStyle fontLarge mt20'>{`${getTranslationText('common', 'great')}!`}</div>
              <div className='bookFontStyle fontSmall mb20 mt8'>
                {this.state.noSelectedDeviceFlow
                  ? getTranslationText('device', 'yourDevicesAdded')
                  : `${getTranslationText('device', 'addedDevice')} 
                ${this.state.selectedDeviceDetails.Product.ProductName} 
                (${this.state.selectedDeviceDetails?.ProductSubCategory?.ProductSubCategory}) 
                ${getTranslationText('device', 'listDevices')}.`}
              </div>
              {isProtectBtn ? (
                <MediumButton
                  className='button-small'
                  buttonText={getTranslationText('common', 'protectNow')}
                  onClick={this.addPlanInAddedDevice}
                />
              ) : (
                <MediumButton
                  className='button-small'
                  buttonText={getTranslationText('common', 'okay')}
                  onClick={this.handleAddedDeviceModal}
                />
              )}
            </div>
          </Modal>
        ) : (
          ''
        )}
        {this.state.showEligiblePlanAddedDeviceModal && Object.keys(_.get(this, 'state.selectedDeviceDetails', {})).length ? (
          <Modal className='sfy-modal successfully-added-device-content' showModal={this.state.showEligiblePlanAddedDeviceModal}>
            <div className='modal-cross-icon'>
              <CustomIconComponent className='text-right' onClick={() => this.closeEventModal('showEligiblePlanAddedDeviceModal')} />
            </div>
            <div className='text-center'>
              <img src={`/commonAssets/servifycare/planProtect.png`} />
              <div className='boldFontStyle fontLarge mt20'>{getTranslationText('device', 'deviceProtect')}</div>
              <div className='boldFontStyle fontLarge mt8 special-link-txt'>{getTranslationText('common', 'deviceProtected')}</div>
              <div className='bookFontStyle fontSmall mb20 mt8'>
                <span>
                  {' '}
                  {`${getTranslationText('device', 'your')} ${this.state.selectedDeviceDetails.Product.ProductName} 
                  (${this.state.selectedDeviceDetails?.ProductSubCategory?.ProductSubCategory}) 
                  ${getTranslationText('request', 'isProtected')} ${_.get(this.props, 'selectedPlanDetails.PlanDisplayName')}`}
                  .
                </span>
              </div>
              <MediumButton buttonText={getTranslationText('common', 'okay')} onClick={this.handleEligiblePlanAddedDeviceModal} />
            </div>
          </Modal>
        ) : (
          ''
        )}
        {this.state.showRatingModal ? (
          <RatingModal
            show={this.state.showRatingModal}
            setGlobalLoaderStatus={this.props.setglobalLoaderStatus}
            toggleModal={this.handlePendingReqAction}
            handleRatingInput={this.handleRatingInput}
            ratingbtnDisabled={this.state.ratingbtnDisabled}
            ratingoptns={this.state.ratingoptns}
            ratingval={this.state.ratingval}
            usrFeedbck={this.state.usrFeedbck}
            handlePillSelect={this.handlePillSelect}
            starRating={this.starRating}
            handlefeedback={this.handlefeedback}
          />
        ) : (
          ''
        )}
        {this.state.showDeviceDocsModal ? (
          <ImageViewerModal
            show={this.state.showDeviceDocsModal}
            closeIconHandler={() => {
              this.setState({ showDeviceDocsModal: false })
            }}
            removeBtnStatus
            deleteDocsHandler={this.handleDeviceDocsDel}
            imagePath={
              this.state.selectedDocImg.viewFilePath || this.state.selectedDocImg.FilePath || this.state.selectedDocImg.imgFilePath
            }
            type={this.state.selectedDocImg.Type}
          />
        ) : (
          ''
        )}
        {this.state.showDeleteDeviceModal ? (
          <ConfirmationPopUp
            show={this.state.showDeleteDeviceModal}
            title={getTranslationText('device', 'deleteDeviceTitle')}
            toggle={this.deleteModalClose}
            confirmationTxt={getTranslationText('device', 'deleteDeviceConfirmation')}
            handleConfirmation={this.handleDelete}
          />
        ) : (
          ''
        )}
        {_.get(this, 'props.user.appConfigDetails.ShowContactDetailsInModal') && this.state.showContactUsModal && (
          <ContactUsModal
            {...this.props}
            showContactUsModal={this.state.showContactUsModal}
            closeContactUsModal={this.closeContactUsModal}
          />
        )}
        {_.get(this, 'props.user.appConfigDetails.BillOrderInstructions', []).length > 0 && this.state.showOrderNoInstr && (
          <StepsIntrPopUp
            title={getTranslationText('common', 'instructions')}
            toggle={this.toggleOrdersInstr}
            show
            InstrData={this.props.user.appConfigDetails.BillOrderInstructions.map((instr, index) => {
              return 'Step ' + (index + 1) + ' : ' + instr
            })}
            confirmationTxt={getTranslationText('common', 'okay')}
          />
        )}
      </div>
    )
  }

  static propTypes = {
    params: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    request: PropTypes.object.isRequired,
    devices: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    getPaginatedDevicesAsync: PropTypes.func.isRequired,
    updateDevicesAsync: PropTypes.func.isRequired,
    addDeviceAsync: PropTypes.func.isRequired,
    setToaster: PropTypes.func,
    getProductsList: PropTypes.func.isRequired,
    setSelectedDevice: PropTypes.func.isRequired,
    getDeviceRepairHistory: PropTypes.func.isRequired,
    getProductDocsAsync: PropTypes.func.isRequired,
    deleteDeviceAsync: PropTypes.func.isRequired,
    authenticateProductsAsync: PropTypes.func.isRequired,
    deleteDeviceProductDocs: PropTypes.func.isRequired,
    addProductDocAsync: PropTypes.func.isRequired,
    getSignedUrlAsync: PropTypes.func.isRequired,
    getProductsByBrand: PropTypes.func.isRequired,
    checkCancellationEligibility: PropTypes.func.isRequired,
    getPlanCancellationOptions: PropTypes.func.isRequired,
    cancelContract: PropTypes.func.isRequired,
    getBillingHistory: PropTypes.func.isRequired,
    fetchEligiblePlansNewAsync: PropTypes.func.isRequired,
    setglobalLoaderStatus: PropTypes.func.isRequired,
    setSeviceEstimatorDevice: PropTypes.func.isRequired,
    setSelectedDamageType: PropTypes.func.isRequired,
    servicefeedbackAsync: PropTypes.func.isRequired,
    setTncLink: PropTypes.func.isRequired,
    setPrivacyLink: PropTypes.func.isRequired,
    getConsumerPlanDetailsAsync: PropTypes.func,
    clearRequestState: PropTypes.func,
    loggerActionAsync: PropTypes.func,
    setTrackDetails: PropTypes.func,
    setRequestDetails: PropTypes.func,
    getEligibleDeviceDetailsAsync: PropTypes.func,
    addDeviceToSubscriptionAsync: PropTypes.func,
    clearDeviceState: PropTypes.func,
    clearMyPlanDetails: PropTypes.func,
    fetchConsumerProductsForEligiblePlansAsync: PropTypes.func,
    fetchAllConsumerProductsForEligiblePlansAsync: PropTypes.func,
    setEligibleDevicePlansList: PropTypes.func,
    checkDeviceEligibilityAsync: PropTypes.func,
    setSelectedEligiblePlanDeviceInfo: PropTypes.func,
    setSelectedPurchasedPlanDetails: PropTypes.func,
  }
}

export default Devices
