import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import {
  BoxHolder,
  Heading,
  MediumButton,
  BoxImagesComponent,
  RadioBtnPills,
  CustomIconComponent,
  RadioBox,
} from '../../../components/UIComponents'
import { Input, Dropdown, Switch, Slider, DatePicker, Tooltip, Table, Pagination } from 'sfy-react'
import moment from 'moment'
import _ from 'lodash'
import { BrandSpecificConfig, requestTypesList } from '../../../utils/constants.js'
import { deviceCountFormatter, sortAlphabetically } from '../../../utils/functions.js'
import { getTranslationText } from '../../../utils/languageTranslation.js'
import { getLocaleCurrency, getLocaleDate } from '../../../utils/locale'
import { ReactComponent as AddIcon } from '../../../../public/commonAssets/add-plus.svg'
import { ReactComponent as DeleteSvg } from '../../../../public/commonAssets/Delete.svg'
import { ReactComponent as SearchIcon } from '../../../../public/commonAssets/Search.svg'
import { ReactComponent as DownArrow } from '../../../../public/commonAssets/down-arrow.svg'
import { ReactComponent as CalendarIcon } from '../../../../public/commonAssets/calendar.svg'
import { ReactComponent as InfoIcon } from '../../../../public/commonAssets/info-alert.svg'
import { ReactComponent as RightArrow } from '../../../../public/commonAssets/right-arrow.svg'
const InfoImg = '/commonAssets/info-alert.svg'
import pushToAnalytics from '../../../utils/Analytics'
const leftArrowImg = '/commonAssets/left.svg'

export class AddingDevices extends React.Component {
  componentWillMount() {
    window.scrollTo(0, 0)
    this.props.clearAddDeviceState(() => {
      if (_.get(this, 'props.listofProducts.categoryDataList', []).length === 1) {
        this.props.catergoryListHandler(this.props.listofProducts.categoryDataList)
      }
    })
  }

  render() {
    let mobileflg = _.get(this, 'props.listboxdata.categoryvalue[0].CategoryId') === 12
    let productValue = _.get(this, 'props.listboxdata.productvalue[0]', {})
    let orderCheckDetails = this.props.hasOrderType
    let isInvoiceMandatory = !!(_.get(productValue, '.IsInvoiceRequired') && _.get(this, 'props.InputDataValue.warrantyDate'))
    let continueBtnStatus = this.props.addingDevicesbtnstatus
    if (isInvoiceMandatory) {
      continueBtnStatus = continueBtnStatus && !!_.get(this, 'props.newDeviceDocsList.imgFilePath')
    }

    const curdate = new Date()
    let uploadInvoice = _.get(this, 'props.newDeviceDocsList', {})
    let title
    let sub_title
    const disabledPostfix = this.props.devicesInfoFieldsStatus ? '' : 'disabledLabel'
    const addDevicesTitle = getTranslationText('device', 'addDevicesTitle').replace(
      '@planName',
      _.get(this, 'props.selectedPlanDetails.PlanName')
    )
    const addDeviceTitle = getTranslationText('device', 'addDeviceTitle')
    const addDeviceSubTitle = getTranslationText('device', 'addDevice')
    const addDevicesSubTitle = 'Give them the care they deserve!'
    if (localStorage.getItem('checkTitlePath') === 'device') {
      title = addDeviceTitle
      sub_title = addDeviceSubTitle
    } else {
      title = addDevicesTitle
      sub_title = addDevicesSubTitle
    }
    const language = localStorage.getItem('languageCode')
    const locale = _.get(this, 'props.user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en-IN')
    return (
      <div>
        <BoxHolder>
          <div className='boldFontStyle mb20 first-device-heading'>{title}</div>
          <div className='bookFontStyle fontLarge mt20'>{sub_title} </div>
          <div className='adding-devices'>
            <div className='device-data-list'>
              <div className='bookFontStyle fontSmall'>{getTranslationText('common', 'category')}</div>
              <Dropdown
                id={getTranslationText('common', 'category')}
                classObject={{
                  container: `mt8 mb8 list-type-ahead`,
                }}
                value={!_.isEmpty(this.props.listboxdata.categoryvalue) ? this.props.listboxdata.categoryvalue[0].value : ''}
                options={sortAlphabetically(this.props.listofProducts.categoryDataList, 'value')}
                placeholder={getTranslationText('addingDevices', 'selectCatg')}
                handleOnChange={(item) => {
                  this.props.catergoryListHandler([item])
                }}
                filterBy='value'
                searchText='Search'
                showTooltip
              />
            </div>
            <div className='device-data-list'>
              <div className='bookFontStyle fontSmall'>{getTranslationText('common', 'brand')}</div>
              <Dropdown
                id={getTranslationText('common', 'brand')}
                classObject={{
                  container: `mt8 mb8 list-type-ahead`,
                }}
                value={!_.isEmpty(this.props.listboxdata.brandvalue) ? this.props.listboxdata.brandvalue[0].value : ''}
                options={sortAlphabetically(this.props.listofProducts.brandDataList, 'value')}
                placeholder={getTranslationText('device', 'selectBrand')}
                handleOnChange={(item) => {
                  this.props.brandListHandler([item])
                }}
                filterBy='value'
                searchText='Search'
                isDisabled={!this.props.listofProducts.brandDataList.length}
                showTooltip
              />
              {this.props.errors.brandsPresent && <div className={`error mb20`}>{getTranslationText('error', 'noResultsFound')}</div>}
            </div>
            <div className='device-data-list'>
              <div className='bookFontStyle fontSmall'>{getTranslationText('common', 'product')}</div>
              <Dropdown
                id={getTranslationText('common', 'product')}
                classObject={{
                  container: `mt8 mb8 list-type-ahead`,
                }}
                value={!_.isEmpty(this.props.listboxdata.productvalue) ? this.props.listboxdata.productvalue[0].value : ''}
                options={sortAlphabetically(this.props.listofProducts.productDataList, 'value')}
                placeholder={getTranslationText('device', 'selectProduct')}
                handleOnChange={(item) => {
                  this.props.productListHandler([item])
                }}
                filterBy='value'
                searchText='Search'
                isDisabled={!this.props.listofProducts.productDataList.length}
                showTooltip
              />
              {this.props.errors?.productsPresent && <div className={`error mb20`}>{getTranslationText('error', 'noResultsFound')}</div>}
            </div>
            {['ServifyCare-Web', 'HpCare-Web'].includes(_.get(this, 'props.user.appConfigDetails.app')) && (
              <p
                className='no-device-helping-text'
                onClick={() => {
                  window.location.href = 'mailto:' + _.get(this, 'props.user.appConfigDetails.footerLinks.support.email')
                }}
              >
                Unable to find your product? Please reach out to us at{' '}
                <span className='termsfontColor'>{_.get(this, 'props.user.appConfigDetails.footerLinks.support.email', '')}</span> or
                <span className='termsfontColor'> {_.get(this, 'props.user.appConfigDetails.footerLinks.support.number', '')}</span> for
                assistance.
              </p>
            )}
          </div>
        </BoxHolder>
        <BoxHolder className='device-sub-data'>
          <Heading wordBreak title={getTranslationText('device', 'devicesInfo')} className={disabledPostfix} />
          <div className='device-input-wrapper mt20'>
            <Input
              className={{
                formControl: 'device-input',
                label: 'overrideCasing fontSmall',
              }}
              label={getTranslationText('common', 'deviceTagLabel') + ' ' + getTranslationText('common', 'optional')}
              inputType='text'
              id='input-tag-name'
              placeholder={getTranslationText('device', 'placeholderMyDevice')}
              handleOnChange={this.props.handleinputdata}
              value={this.props.InputDataValue.tagvalue}
              isDisabled={!this.props.devicesInfoFieldsStatus}
            />
            <div className='device-input'>
              <Input
                className={{
                  input: 'txt_upperCase',
                  label: 'overrideCasing fontSmall',
                }}
                label={
                  this.props.isAnAppleDevice && mobileflg
                    ? getTranslationText('common', 'serialNumberLabel') + ' ' + getTranslationText('common', 'optional')
                    : orderCheckDetails
                    ? getTranslationText('common', 'orderNo')
                    : getTranslationText('common', 'serialNumber') + ' ' + getTranslationText('common', 'optional')
                }
                inputType='text'
                id='input-serial-no'
                placeholder={getTranslationText('device', 'placeholderSerialNo')}
                handleOnChange={this.props.handleinputdata}
                value={this.props.InputDataValue.serialnovalue}
                isDisabled={!this.props.devicesInfoFieldsStatus}
              />
              {orderCheckDetails && (
                <div
                  className={_.get(this, 'props.listboxdata.productvalue', []).length ? 'super-text' : 'disabledLabel'}
                  onClick={this.props.toggleOrdersInstr}
                >
                  {getTranslationText('common', 'help')}?
                </div>
              )}
              {this.props.setSerialNumberError && <span className='error-text'>{getTranslationText('device', 'serialNoErrorMsg')}</span>}
            </div>
            {this.props.isAnAppleDevice || !mobileflg ? (
              ''
            ) : (
              <Input
                className={{
                  formControl: 'device-input',
                  label: 'overrideCasing fontSmall',
                }}
                label={getTranslationText('device', 'imei1')}
                inputType='text'
                id='input-imei1-no'
                placeholder={getTranslationText('device', 'placeholderImei')}
                handleOnChange={this.props.handleinputdata}
                value={this.props.InputDataValue.imei1value || ''}
                isDisabled={!this.props.devicesInfoFieldsStatus}
              />
            )}
            {this.props.isAnAppleDevice || !mobileflg ? (
              ''
            ) : (
              <Input
                className={{
                  formControl: 'device-input',
                  label: 'overrideCasing fontSmall',
                }}
                label={getTranslationText('device', 'imei2')}
                inputType='text'
                id='input-imei2-no'
                placeholder={getTranslationText('device', 'placeholderImei')}
                handleOnChange={this.props.handleinputdata}
                value={this.props.InputDataValue.imei2value || ''}
                isDisabled={!this.props.devicesInfoFieldsStatus}
              />
            )}
          </div>
          <div className='row mt20'>
            {this.props.isAnAppleDevice || this.props.hideWarrantyToggle ? (
              ''
            ) : (
              <div className='col-sm-12 mb20'>
                <div className={`bookFontStyle fontSmall mb8 ${disabledPostfix}`}>{getTranslationText('common', 'isDeviceWarranty')}</div>
                <div className='d-flex'>
                  <Switch
                    id='device-warranty'
                    disabled={!this.props.devicesInfoFieldsStatus}
                    checked={!!this.props.InputDataValue.underWarrentyValue}
                    otherProps={{ onChange: this.props.handleinputdata }}
                  />
                </div>
                {this.props.InputDataValue.underWarrentyValue && (
                  <div className='add-device-warranty-date'>
                    <div className='bookFontStyle fontSmall mt20 mb8'>{getTranslationText('common', 'warrantyTillDate')}</div>
                    <DatePicker
                      placeholder={this.props.dateFormat}
                      format={this.props.dateFormat.toLowerCase()}
                      maxDay={new Date(curdate.getFullYear() + 10, curdate.getMonth(), curdate.getDate())}
                      minDay={new Date()}
                      handleOnChange={(date) => this.props.handleDatePickerOnChange(date, 'add-warranty-date')}
                      date={this.props.InputDataValue.warrantyDate || ''}
                      locale={locale}
                      language={language}
                    >
                      <CalendarIcon />
                    </DatePicker>
                  </div>
                )}
              </div>
            )}
            {isInvoiceMandatory && (
              <div className={'col-sm-12 mt20'}>
                <div className='row'>
                  <div className='col-sm-12 fontSmall'>{getTranslationText('request', 'uploadInvoice')}</div>
                  <div className='col-3 img-min-width'>
                    <BoxImagesComponent
                      loaderState={false}
                      isUploaded={uploadInvoice.imgFilePath}
                      imgFilePath={window.useDocService ? uploadInvoice.viewFilePath : uploadInvoice.imgFilePath}
                      imageID={uploadInvoice.imageID}
                      inputName='deviceDocs-upload'
                      inputId='upload-img-ip-id'
                      imgViewerHandler={() => this.props.openDeviceDocsGallery(uploadInvoice)}
                      fileUploadHander={window.useDocService ? this.props.uploadProductDocToDS : this.props.handleUploadDocs}
                    />
                  </div>
                  {/* this.props.newDeviceDocsList.length
                    ? this.props.newDeviceDocsList.map(doc => (
                      <div className='col-3 img-min-width'>
                        <BoxImagesComponent loaderState={false} isUploaded
                          imgFilePath={doc.imgFilePath}
                          imageID={doc.imageID}
                          imgViewerHandler={() => this.props.openDeviceDocsGallery(doc)} />
                      </div>
                    ))
                    : ''
                  */}
                </div>
                <div className='bookFontStyle fontSmall mt8 Note_Text'>{getTranslationText('device', 'deviceDocUploadNote')}</div>
              </div>
            )}

            <div className='col-sm-12 mt20 add-device-footer-btns'>
              <MediumButton
                className='button-small button-ghost'
                buttonText={getTranslationText('common', 'backLabel')}
                onClick={this.props.handleBackBtnClick}
              />
              {_.get(this, 'props.user.appConfigDetails.hideAddNewDevice') ? (
                ''
              ) : (
                <MediumButton
                  className='button-small'
                  buttonText={getTranslationText('device', 'addDeviceLabel')}
                  onClick={this.props.addDevice}
                  btnLoader={this.props.AddDeviceBtnLoader}
                  disabled={!continueBtnStatus}
                />
              )}
            </div>
          </div>
        </BoxHolder>
      </div>
    )
  }
}
AddingDevices.propTypes = {
  handleinputdata: PropTypes.func.isRequired,
  InputDataValue: PropTypes.object.isRequired,
  catergoryListHandler: PropTypes.func.isRequired,
  brandListHandler: PropTypes.func.isRequired,
  productListHandler: PropTypes.func.isRequired,
  devicesInfoFieldsStatus: PropTypes.bool.isRequired,
  addingDevicesbtnstatus: PropTypes.bool.isRequired,
  listboxdata: PropTypes.object.isRequired,
  addDevice: PropTypes.func.isRequired,
  listofProducts: PropTypes.object.isRequired,
  isAnAppleDevice: PropTypes.bool.isRequired,
  clearAddDeviceState: PropTypes.func.isRequired,
  AddDeviceBtnLoader: PropTypes.bool.isRequired,
  fetchingProductLoader: PropTypes.bool,
  handleBackBtnClick: PropTypes.func.isRequired,
  handleDatePickerOnChange: PropTypes.func.isRequired,
  handleUploadDocs: PropTypes.func.isRequired,
  // newDeviceDocsList: PropTypes.any,
  toggleOrdersInstr: PropTypes.func.isRequired,
  openDeviceDocsGallery: PropTypes.func.isRequired,
  hasOrderType: PropTypes.bool.isRequired,
  fetchingBrandLoader: PropTypes.bool,
}

export class ProtectEligibleDevices extends React.Component {
  render() {
    const productvalue = _.get(this, 'props.devices.selectedEligibleDeviceInfo.Product.ProductName')
    const categoryvalue = _.get(this, 'props.devices.selectedEligibleDeviceInfo.ProductSubCategory.ProductSubCategory')
    let EligiblePlanList = _.get(this, 'props.devices.deviceEligiblePlansList', [])
    let titleText =
      EligiblePlanList.length > 1
        ? `Under which plan would you like to protect your ${productvalue} (${categoryvalue})?`
        : `Please confirm you want to protect your ${productvalue} (${categoryvalue}) under this plan`
    return (
      <div className='protect-eligible-devices-container'>
        <BoxHolder>
          <div className='fontLarge mb28'>{titleText}</div>
          <div className='mt20 mb20 list-of-myplans-container'>
            {EligiblePlanList.map((plan, index) => {
              let dateLable1
              let dateLable2
              let planDetailsDate1
              let planDetailsDate2
              if (plan.IsEnrollmentPlan) {
                if (plan.Status == 1) {
                  switch (plan.GroupSoldPlanStatus) {
                    case 1:
                    case -4:
                      // active plans
                      dateLable1 = getTranslationText('subscription', 'coverageStartDate')
                      dateLable2 = getTranslationText('subscription', 'nextBillingDate')
                      planDetailsDate1 = getLocaleDate(this.props.dateFormat, plan.CoverageStartDate)
                      planDetailsDate2 = getLocaleDate(this.props.dateFormat, plan.NextBillingDate)
                      break

                    case -5:
                      // active plans // scenario 3 & 4
                      dateLable1 = getTranslationText('subscription', 'cancellationDate')
                      dateLable2 = getTranslationText('subscription', 'coverageEndDate')
                      planDetailsDate1 = getLocaleDate(this.props.dateFormat, plan.PlanCancellationDate)
                      planDetailsDate2 = getLocaleDate(this.props.dateFormat, plan.CoverageEndDate)
                      break

                    case -3:
                      // canceled plans
                      dateLable1 = getTranslationText('subscription', 'planPurchaseDate')
                      dateLable2 = getTranslationText('subscription', 'cancellationDate')
                      planDetailsDate1 = getLocaleDate(this.props.dateFormat, plan.DateOfPurchase)
                      planDetailsDate2 = getLocaleDate(this.props.dateFormat, plan.PlanCancellationDate)
                      break
                    default:
                      // active plan scenario -> 1 & 2
                      dateLable1 = getTranslationText('subscription', 'coverageStartDate')
                      dateLable2 = getTranslationText('subscription', 'nextBillingDate')
                      planDetailsDate1 = getLocaleDate(this.props.dateFormat, plan.CoverageStartDate)
                      planDetailsDate2 = getLocaleDate(this.props.dateFormat, plan.NextBillingDate)
                      break
                  }
                } else if (plan.Status == -4) {
                  switch (plan.GroupSoldPlanStatus) {
                    case -3:
                      // canceled plans // scenario 5 & 6
                      dateLable1 = getTranslationText('subscription', 'planPurchaseDate')
                      dateLable2 = getTranslationText('subscription', 'cancellationDate')
                      planDetailsDate1 = getLocaleDate(this.props.dateFormat, plan.DateOfPurchase)
                      planDetailsDate2 = getLocaleDate(this.props.dateFormat, plan.PlanCancellationDate)
                      break

                    default:
                      dateLable1 = getTranslationText('subscription', 'planPurchaseDate')
                      dateLable2 = getTranslationText(
                        'subscription',
                        BrandSpecificConfig().header === 'AppleEU-Web' ? 'planCancellationDate' : 'cancellationDate'
                      )
                      planDetailsDate1 = getLocaleDate(this.props.dateFormat, plan.DateOfPurchase)
                      planDetailsDate2 = getLocaleDate(this.props.dateFormat, plan.PlanCancellationDate)
                      break
                  }
                } else {
                  switch (plan.Status) {
                    case -6:
                    case -5:
                    case -4:
                    case 3:
                    case 4:
                    case -9:
                      dateLable1 = getTranslationText('subscription', 'planPurchaseDate')
                      dateLable2 = getTranslationText(
                        'subscription',
                        BrandSpecificConfig().header === 'AppleEU-Web' ? 'planCancellationDate' : 'cancellationDate'
                      )
                      planDetailsDate1 = getLocaleDate(this.props.dateFormat, plan.DateOfPurchase)
                      planDetailsDate2 = getLocaleDate(this.props.dateFormat, plan.PlanCancellationDate)
                      break

                    default:
                      dateLable1 = getTranslationText('subscription', 'coverageStartDate')
                      dateLable2 = getTranslationText('subscription', 'nextBillingDate')
                      planDetailsDate1 = getLocaleDate(this.props.dateFormat, plan.CoverageStartDate)
                      planDetailsDate2 = getLocaleDate(this.props.dateFormat, plan.NextBillingDate)
                  }
                }
              } else {
                switch (plan.Status) {
                  case 1:
                    // active plans - one time purchase plans
                    dateLable1 = getTranslationText('subscription', 'coverageStartDate')
                    dateLable2 = getTranslationText('subscription', 'coverageEndDate')
                    planDetailsDate1 = getLocaleDate(this.props.dateFormat, plan.CoverageStartDate)
                    planDetailsDate2 = getLocaleDate(this.props.dateFormat, plan.CoverageEndDate || plan.ValidityDate)
                    break

                  case -3:
                    dateLable1 = getTranslationText('subscription', 'coverageStartDate')
                    dateLable2 = getTranslationText('subscription', 'coverageEndDate')
                    planDetailsDate1 = getLocaleDate(this.props.dateFormat, plan.CoverageStartDate)
                    planDetailsDate2 = getLocaleDate(this.props.dateFormat, plan.CoverageEndDate || plan.ValidityDate)
                    break

                  default:
                    dateLable1 = getTranslationText('subscription', 'planPurchaseDate')
                    dateLable2 = getTranslationText(
                      'subscription',
                      BrandSpecificConfig().header === 'AppleEU-Web' ? 'planCancellationDate' : 'cancellationDate'
                    )
                    planDetailsDate1 = getLocaleDate(this.props.dateFormat, plan.CoverageStartDate)
                    planDetailsDate2 = getLocaleDate(this.props.dateFormat, plan.PlanCancellationDate)
                    break
                }
              }

              return (
                <div key={index}>
                  <div className='col-md-4'>
                    <div className='list-of-myplans mb20' id={`radio-${plan.SoldPlanID}`}>
                      <RadioBox key={`plan-${index}`} isSelected={EligiblePlanList.length === 1}>
                        <>
                          {plan.Status == 1 && plan.GroupSoldPlanStatus == -5 ? (
                            plan.CancellationDaysLeft > 0 ? (
                              <div className='coverage-starts'>
                                {getTranslationText('subscription', 'planWillCancelInDays', '', '@days@', plan.CancellationDaysLeft)}
                              </div>
                            ) : plan.CoverageDaysLeft > 0 ? (
                              <div className='coverage-starts'>
                                {getTranslationText('subscription', 'coverageEndsInDays', '', '@days@', plan.CoverageDaysLeft)}
                              </div>
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}
                          {/* 3 and 4 */}

                          {plan.Status == 1 && plan.CoolingPeriodDays > 0 ? (
                            <div className='coverage-starts'>
                              {getTranslationText('subscription', 'coverageStartsInDays', '', '@days@', plan.CoolingPeriodDays)}
                            </div>
                          ) : (
                            ''
                          )}
                          {/* 1 and 2 */}

                          <div className='plan-div-container no-margin'>
                            <div className='plan-img1'>
                              <img src={plan.PlanEmailImage} />
                            </div>
                            <div className='plan-div-content'>
                              <div className='boldFontStyle fontMedium mb4 special-link-txt'>{plan.PlanDisplayName}</div>
                            </div>
                          </div>

                          <div className='lightFontStyle fontMedium overrideCasing overrideCasing mt20'>
                            {plan.NoOfDevicesCovered && plan.NoOfDevicesCovered > 0
                              ? `${
                                  deviceCountFormatter(plan.NoOfDevicesCovered) +
                                  ' ' +
                                  getTranslationText('device', 'devicesProtectedPostfix')
                                }`
                              : getTranslationText('device', 'noDevicesProtected')}
                          </div>

                          <div className='plan-validity mt20 pb8'>
                            <div className='plan-date'>
                              <div className='lightFontStyle smallFont dateLable'>{dateLable1}</div>
                              <div className='text-date bookFontStyle fontLarge'>{planDetailsDate1}</div>
                            </div>
                            <div className='plan-date'>
                              <div className='lightFontStyle smallFont dateLable'>{dateLable2}</div>
                              <div className='text-date bookFontStyle fontLarge'>{planDetailsDate2}</div>
                            </div>
                          </div>
                        </>
                      </RadioBox>
                    </div>
                  </div>
                  <hr size='2' className='col-sm-12 mb20 col-lg-12 device-list-seperator' />
                  <div className='col-sm-12 mt20 add-device-footer-btns'>
                    <MediumButton
                      className='button-small button-ghost'
                      buttonText={getTranslationText('common', 'backLabel')}
                      onClick={this.props.handleBackBtnClick}
                    />
                    <MediumButton
                      className='button-small'
                      buttonText={getTranslationText('common', 'confirm')}
                      onClick={() => this.props.addEligibleDevicesInPlans(plan)}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </BoxHolder>
      </div>
    )
  }
  static propTypes = {
    addEligibleDevicesInPlans: PropTypes.func.isRequired,
    handleBackBtnClick: PropTypes.func.isRequired,
  }
}

export class AddingEligibleDevices extends React.Component {
  selectedValue = () => {
    var objLength = Object.getOwnPropertyNames(this.props.deviceInfo).length
    if (objLength) {
      let { ConsumerProductID, TagName, BrandID, ProductSubCategoryID } = this.props.deviceInfo
      let ProductName = this.props.deviceInfo.Product.ProductName
      return [
        {
          ConsumerProductID,
          TagName,
          BrandID,
          ProductSubCategoryID,
          ProductName,
          value: ProductName,
        },
      ]
    } else {
      let selectedObj = []
      if (this.props.listboxdata.categoryenabled) {
        selectedObj = [
          {
            ConsumerProductID: -1,
            TagName: '',
            BrandID: -1,
            ProductName: `+${getTranslationText('common', 'addNewDevice')}`,
            value: `+${getTranslationText('common', 'addNewDevice')}`,
          },
        ]
      }
      return selectedObj
    }
  }

  attachDeviceTypeahead = (typeahead) => {
    this.deviceListTypeahead = typeahead
  }

  getDeviceSelectionFocus = () => {
    let instance = this.deviceListTypeahead.getInstance()
    instance && instance.focus()
  }

  render() {
    const appConfigDetails = _.get(this, 'props.user.appConfigDetails')
    let isFieldEditable = false
    const postFix = this.props.uniqueIdrequired ? '' : ' ' + getTranslationText('common', 'optional')
    let appDeviceSerialLabel = `${getTranslationText('common', 'serialNumberLabel')} ${postFix}`
    if (!this.props.isNewDevice && _.get(this, 'props.deviceInfo.ProductUniqueID')) {
      appDeviceSerialLabel = `${getTranslationText('common', 'serialNumber')} ${postFix}`
    }
    // btnStatus true mean disabled
    let continueBtnStatus = this.props.devicesPlanListbtnstatus

    let orderCheckDetails = _.get(this, 'props.productBrandConfig.WarrantyCheckDetails.AllowedValues', []).find(
      (configVal) => configVal.Type === 'Order No.'
    )
    if (orderCheckDetails) {
      let selectedProductInfo = _.get(this, 'props.deviceInfo')
      if (this.props.isNewDevice) {
        selectedProductInfo = _.get(this, 'props.listboxdata.productvalue[0]')
      }
      orderCheckDetails = !!orderCheckDetails.ProductList.find((id) => id === selectedProductInfo.ProductID)
    }

    let selectedDeviceOption = this.selectedValue()
    return (
      <div className='request-device-selection'>
        <div className='mb8'>
          <BoxHolder>
            <Heading title={'Select Device'} />
          </BoxHolder>
        </div>
        <BoxHolder>
          <div className='device-selection'>
            <div className='device-list-input'>
              <div className='bookFontStyle fontSmall overrideCasing'>{getTranslationText('common', 'myDevice')}</div>
              <Dropdown
                id={getTranslationText('common', 'myDevice')}
                classObject={{
                  container: `device-list-selection mt8 mb8 list-type-ahead`,
                }}
                value={!_.isEmpty(selectedDeviceOption) ? selectedDeviceOption[0].value : ''}
                options={sortAlphabetically(this.props.eligiblePlanDeviceList, 'value')}
                placeholder={getTranslationText('common', 'selectDevice')}
                handleOnChange={(item) => {
                  this.props.handleDeviceSelection([item])
                }}
                filterBy='value'
                searchText='Search'
                showTooltip
              />
            </div>
            <div className='device-input'>
              <div className='bookFontStyle fontSmall'>{getTranslationText('common', 'category')}</div>
              <Dropdown
                id={getTranslationText('common', 'category')}
                classObject={{
                  container: `mt8 mb8 list-type-ahead`,
                }}
                value={!_.isEmpty(this.props.listboxdata.categoryvalue) ? this.props.listboxdata.categoryvalue[0].value : ''}
                options={sortAlphabetically(this.props.listofProducts.categoryDataList, 'value')}
                placeholder={getTranslationText('addingDevices', 'selectCatg')}
                handleOnChange={(item) => {
                  this.props.catergoryListHandler([item])
                }}
                filterBy='value'
                searchText='Search'
                isDisabled={!this.props.listboxdata.categoryenabled}
                showTooltip
              />
            </div>
            <div className='device-input'>
              <div className='bookFontStyle fontSmall'>{getTranslationText('common', 'brand')}</div>
              <Dropdown
                id={getTranslationText('common', 'brand')}
                classObject={{
                  container: `mt8 mb8 list-type-ahead`,
                }}
                value={!_.isEmpty(this.props.listboxdata.brandvalue) ? this.props.listboxdata.brandvalue[0].value : ''}
                options={sortAlphabetically(this.props.listofProducts.brandDataList, 'value')}
                placeholder={getTranslationText('common', 'selectBrand')}
                handleOnChange={(item) => {
                  this.props.brandListHandler([item])
                }}
                filterBy='value'
                searchText='Search'
                isDisabled={!this.props.listofProducts.brandDataList.length}
                showTooltip
              />
            </div>
          </div>
        </BoxHolder>
        <BoxHolder className='DeviceSubDataReq'>
          <Heading
            wordBreak
            title={getTranslationText('device', 'devicesInfo')}
            className={!this.props.isNewDevice ? 'disabledLabel' : ''}
          />
          <div className='row mt20'>
            <Input
              className={{
                formControl: 'device-input',
                label: 'overrideCasing',
              }}
              label={getTranslationText('common', 'deviceTagLabel')}
              inputType='text'
              id='device-tag-name'
              placeholder={getTranslationText('device', 'placeholderMyDevice')}
              handleOnChange={this.props.handleNewDeviceInputChange}
              value={this.props.listboxdata.TagName}
              isDisabled={!this.props.isNewDevice}
            />
            <div
              className={
                'device-input ' +
                (this.props.isNewDevice ||
                ((this.props.isAnAppleDevice || orderCheckDetails) && isFieldEditable && this.props.uniqueIdrequired)
                  ? ''
                  : 'ml20 disabledLabel')
              }
            >
              <Input
                className={{
                  input: 'txt_upperCase',
                  label: 'overrideCasing',
                }}
                label={
                  this.props.isAnAppleDevice
                    ? this.props.mobileCategory
                      ? appDeviceSerialLabel
                      : `${getTranslationText('common', 'serialNumber')}`
                    : getTranslationText('common', orderCheckDetails ? 'orderNumber' : 'serialNumber') +
                      (this.props.uniqueIdrequired && !this.props.mobileCategory ? '' : ' ' + getTranslationText('common', 'optional'))
                }
                inputType='text'
                id='device-serial-no'
                placeholder={getTranslationText('device', 'placeholderSerialNo')}
                handleOnChange={this.props.handleNewDeviceInputChange}
                value={this.props.listboxdata.ProductUniqueID}
                isDisabled={
                  !(
                    this.props.isNewDevice ||
                    ((this.props.isAnAppleDevice || orderCheckDetails) && isFieldEditable && this.props.uniqueIdrequired)
                  )
                }
              />
              {orderCheckDetails && (
                <div className={'super-text'} onClick={this.props.toggleOrdersInstr}>
                  {getTranslationText('common', 'Help')}?
                </div>
              )}
            </div>
            {this.props.isAnAppleDevice && !this.props.isNewDevice && _.get(this, 'props.deviceInfo.ProductUniqueID') ? (
              <Input
                className={{
                  formControl: 'device-input',
                }}
                label={`${getTranslationText('device', 'imei1')}`}
                inputType='text'
                placeholder={getTranslationText('device', 'placeholderImei')}
                isDisabled
              />
            ) : (
              ''
            )}
            {this.props.isAnAppleDevice || !this.props.mobileCategory ? (
              ''
            ) : (
              <div className='device-input'>
                <Input
                  label={`${getTranslationText('device', 'imei1')} ${
                    this.props.uniqueIdrequired ? '' : getTranslationText('common', 'optional')
                  }`}
                  inputType='text'
                  id='device-imei1-no'
                  placeholde={getTranslationText('device', 'placeholderImei')}
                  isDisabled={!isFieldEditable}
                  handleOnChange={this.props.handleNewDeviceInputChange}
                />
                {this.props.isInvalidIMEI && appConfigDetails && appConfigDetails.showContactUsOption && appConfigDetails.contactUsLink ? (
                  <div className='invalid-imei-error mb20 boldFontStyle'>
                    {getTranslationText('common', 'forSupportClick')}
                    <a href={appConfigDetails.contactUsLink} target='_blank' rel='noreferrer'>
                      {` ${getTranslationText('common', 'here')}`}
                    </a>
                  </div>
                ) : (
                  ''
                )}
              </div>
            )}
            {this.props.isAnAppleDevice || !this.props.mobileCategory ? (
              ''
            ) : (
              <Input
                clasName={{
                  formControl: 'device-input',
                }}
                label={`getTranslationText('device', 'imei2')`}
                inputType='text'
                id='device-imei2-no'
                placeholde={getTranslationText('device', 'placeholderImei')}
                isDisabled={!this.props.isNewDevice}
                handleOnChange={this.props.handleNewDeviceInputChange}
                value={this.props.isNewDevice ? this.props.newDeviceData.newDeviceImei2No : this.props.deviceInfo.AlternateUniqueKey}
              />
            )}
          </div>
          <div className='row mt20'>
            {this.props.hideWarrantyToggle || (this.props.isAnAppleDevice && this.props.isNewDevice) ? (
              ''
            ) : (
              <div className={'col-sm-12 ' + (!this.props.isNewDevice ? 'disabledLabel' : '')}>
                <div className={'bookFontStyle fontSmall mb8 ' + (!this.props.isNewDevice ? 'disabledLabel' : '')}>
                  {getTranslationText('common', 'isDeviceWarranty')}
                </div>
                <Switch
                  id='device-warranty'
                  disabled={!this.props.isNewDevice}
                  checked={
                    _.get(this, 'props.listboxdata.categoryvalue', []).length
                      ? _.get(this, 'props.listboxdata.productenabled')
                        ? _.get(this, 'props.listboxdata.productvalue', []).length
                          ? this.props.deviceRadiobtnvalue
                          : null
                        : this.props.deviceInfo.IsUnderWarranty
                      : null
                  }
                  otherProps={{ onChange: this.props.isNewDevice ? this.props.handleNewDeviceInputChange : '' }}
                />
              </div>
            )}
            {this.props.deviceInfo.WarrantyTill && this.props.deviceRadiobtnvalue && !this.props.isAnAppleDevice ? (
              <div
                className={
                  'col-sm-12 mb8 bookFontStyle fontSmall addDevice-input-field ' + (!this.props.isNewDevice ? 'disabledLabel' : '')
                }
              >
                {getTranslationText('common', 'warrantyTillDate')} &nbsp;
                <span className='bookFontStyle'>{moment(this.props.deviceInfo.WarrantyTill).format('DD/MM/YYYY')}</span>
              </div>
            ) : (
              ''
            )}
            <div className='col-sm-12 mt20 add-device-footer-btns'>
              <MediumButton className='button-small button-ghost' buttonText='Cancel' onClick={this.props.handleBackButton} />
              <MediumButton
                className='button-small'
                buttonText={getTranslationText('common', 'protectNow')}
                onClick={this.props.addEligibleDevicesInPlans}
                btnLoader={this.props.devicePlanBtnStatusLoader}
                disabled={!continueBtnStatus}
              />
            </div>
          </div>
        </BoxHolder>
      </div>
    )
  }

  static propTypes = {
    deviceInfo: PropTypes.object,
    isNewDevice: PropTypes.bool.isRequired,
    handleDeviceSelection: PropTypes.func.isRequired,
    addEligibleDevicesInPlans: PropTypes.func.isRequired,
    listboxdata: PropTypes.object.isRequired,
    listofProducts: PropTypes.object.isRequired,
    catergoryListHandler: PropTypes.func.isRequired,
    productListHandler: PropTypes.func.isRequired,
    brandListHandler: PropTypes.func.isRequired,
    newDeviceData: PropTypes.object.isRequired,
    handleNewDeviceInputChange: PropTypes.func.isRequired,
    deviceRadiobtnvalue: PropTypes.bool,
    isAnAppleDevice: PropTypes.bool.isRequired,
    handleBackButton: PropTypes.func.isRequired,
    mobileCategory: PropTypes.bool.isRequired,
    uniqueIdrequired: PropTypes.bool,
    devicePlanBtnStatusLoader: PropTypes.bool,
    isInvalidIMEI: PropTypes.bool.isRequired,
    eligiblePlanDeviceList: PropTypes.any,
    devicesPlanListbtnstatus: PropTypes.bool,
    fetchingProductLoader: PropTypes.bool,
    toggleOrdersInstr: PropTypes.any,
  }
}
export class BillingHistory extends React.Component {
  render() {
    const dateFormat = _.get(this, 'props.user.selectedCountryData.countryConfig.DateFormat.DEFAULT', 'DD/MM/YYYY')
    const locale = _.get(this, 'props.user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
    const currencyCode = _.get(this, 'props.user.selectedCountryData.CurrencyCode', '')
    return (
      <>
        <div
          className='mediumFontStyle purple-link billing-history-back mt16'
          onClick={() => {
            this.props.showBillingHistory(false)
          }}
        >
          <img src={leftArrowImg} className='billing-history-back-img' />
          <span className='ml4 fontMedium'>{getTranslationText('common', 'backLabel')}</span>
        </div>
        <div className='billing-history-container col-sm-12 mt16'>
          <div className='col-sm-12 billing-history-title mediumFontStyle'>{getTranslationText('device', 'billingHistory')}</div>
          {this.props.billingHistoryData?.InitialPaymentLink || this.props.billingHistoryData?.PaymentLink ? (
            <div className='col-sm-12'>
              <div className='billing-history-payment-msg mt16'>
                {this.props.billingHistoryData?.planDetails?.Status === 14
                  ? getTranslationText('device', 'planActivationPendingMsg')
                  : getTranslationText('device', 'autoDebitFailedMsg')
                      .replace('@amount@', getLocaleCurrency(locale, currencyCode, this.props.billingHistoryData?.planDetails?.PlanAmount))
                      .replace('@plan_name@', this.props.billingHistoryData?.planDetails?.PlanName)
                      .replace('@pending_months@', this.props.billingHistoryData?.planDetails?.NoOfPaymentPending)
                      .replace(
                        '@billing_date@',
                        this.props.billingHistoryData?.planDetails?.EffectiveDate
                          ? getLocaleDate(dateFormat, this.props.billingHistoryData?.planDetails?.EffectiveDate)
                          : ''
                      )
                      .replace(
                        '@grace_period_enddate@',
                        this.props.billingHistoryData?.planDetails?.HoldPeriodDate
                          ? getLocaleDate(dateFormat, this.props.billingHistoryData?.planDetails?.HoldPeriodDate)
                          : ''
                      )}
                <span className='billing-history-paynow purple-link mediumFontStyle'>
                  <a
                    onClick={() =>
                      window.open(
                        this.props.billingHistoryData?.InitialPaymentLink || this.props.billingHistoryData?.PaymentLink || '',
                        '_blank'
                      )
                    }
                    // href={redirectionUrl}
                    target='_blank'
                    className='inline-ele-Casing mb8'
                    rel='noreferrer'
                  >
                    <span>{getTranslationText('common', 'pay')}</span>
                  </a>
                </span>
              </div>
            </div>
          ) : (
            ''
          )}

          <div className='col-sm-12 mt16'>
            <Table
              className={{
                tableContainer: 'billing-history-table',
              }}
            >
              <Table.Header className='billing-history-table-bg'>
                <Table.Row>
                  <Table.Heading classObject={{ header: 'billing-history-table-header mediumFontStyle' }}>
                    {getTranslationText('device', 'transactionID')}
                  </Table.Heading>
                  <Table.Heading classObject={{ header: 'billing-history-table-header mediumFontStyle' }}>
                    {getTranslationText('device', 'billingCycleDate')}
                  </Table.Heading>
                  <Table.Heading classObject={{ header: 'billing-history-table-header mediumFontStyle' }}>
                    {getTranslationText('device', 'actualBilledDate')}
                  </Table.Heading>
                  <Table.Heading classObject={{ header: 'billing-history-table-header mediumFontStyle' }}>
                    {getTranslationText('device', 'transactionAmount')}
                  </Table.Heading>
                  <Table.Heading classObject={{ header: 'billing-history-table-header mediumFontStyle' }}>
                    {getTranslationText('device', 'gracePeriodEndDate')}
                  </Table.Heading>
                  <Table.Heading classObject={{ header: 'billing-history-table-header mediumFontStyle' }}>
                    {getTranslationText('device', 'transactionStatus')}
                  </Table.Heading>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.billingHistoryData?.PaymentDetails?.length &&
                  this.props.billingHistoryData?.PaymentDetails.map((obj, key) => {
                    return (
                      <Table.Row key={key}>
                        <Table.Data className='billing-history-table-data'>{obj.PaymentGatewayTransactionID || '-'}</Table.Data>
                        <Table.Data className='billing-history-table-data'>
                          {obj.NextBillingDate ? getLocaleDate(dateFormat, obj.NextBillingDate) : '-'}
                        </Table.Data>
                        <Table.Data className='billing-history-table-data'>
                          {obj.BilledDate ? getLocaleDate(dateFormat, obj.BilledDate) : '-'}
                        </Table.Data>
                        <Table.Data className='billing-history-table-data'>
                          {obj.Amount ? getLocaleCurrency(locale, currencyCode, obj.Amount) : '-'}
                        </Table.Data>
                        <Table.Data className='billing-history-table-data'>
                          {obj.HoldPeriodDate ? getLocaleDate(dateFormat, obj.HoldPeriodDate) : '-'}
                        </Table.Data>
                        <Table.Data>
                          <div
                            className={`${'billing-history-table-data-status mediumFontStyle'} ${
                              obj?.Status === 1 ? 'status-green' : obj?.Status === 0 ? 'status-orange' : 'status-red'
                            }`}
                          >
                            {obj?.StatusTextObject?.statusText || '-'}
                          </div>
                        </Table.Data>
                      </Table.Row>
                    )
                  })}
              </Table.Body>
            </Table>
            {this.props.billingHistoryData?.PaymentDetails?.length && (
              <Pagination
                pages={this.props.billingHistoryData?.pagination?.maxPages}
                paginate={(pageNo) => {
                  this.props.setCurrentPage(
                    pageNo,
                    this.props.billingHistoryData?.planDetails?.SoldPlanID,
                    this.props.billingHistoryData?.planDetails
                  )
                }}
                activePage={this.props.billingHistoryData?.pagination?.currentPage}
                paginationInfo={`${
                  this.props.billingHistoryData?.pagination?.currentPage === 1
                    ? 1
                    : (this.props.billingHistoryData?.pagination?.currentPage - 1) *
                        this.props.billingHistoryData?.pagination?.itemsPerPage +
                      1
                } - ${
                  this.props.billingHistoryData?.pagination?.currentPage === this.props.billingHistoryData?.pagination?.maxPages
                    ? this.props.billingHistoryData?.pagination?.totalItems
                    : this.props.billingHistoryData?.pagination?.itemsPerPage * this.props.billingHistoryData?.pagination?.currentPage
                } ${getTranslationText('device', 'of')} ${this.props.billingHistoryData?.pagination?.totalItems}`}
                className={{ container: 'billing-history-table-pagination' }}
              />
            )}
          </div>
        </div>
      </>
    )
  }
  static propTypes = {
    billingHistoryData: PropTypes.object,
    setCurrentPage: PropTypes.func.isRequired,
    showBillingHistory: PropTypes.func.isRequired,
  }
}
export class SidebarDevices extends React.Component {
  render() {
    let showAddDevice = _.get(this.props, 'user.appConfigDetails.showAddDevice', true)
    const supportedDevices = this.props.devices.deviceDetails?.supportedDevices
    return (
      <div className='row'>
        {showAddDevice && (
          <div className='col-sm-12'>
            <AddMoreDevicesBlock {...this.props} />
          </div>
        )}
        <div className='col-sm-12 bookFontStyle fontSmall'>
          <BoxHolder className='fixed-height-override box-holder-marginOverride pb16'>
            <div className='row'>
              <div className='col-sm-12 device-view-info'>
                <div className='overview-wrapper overrideCasing'>
                  <SearchIcon className='search-icon-input' />
                  <span className='search-input'>
                    <input
                      className='form-control fontSmall'
                      id='search-device-input'
                      type='text'
                      placeholder={getTranslationText('device', 'searchDevice')}
                      value={this.props.searchValue}
                      aria-label='Search'
                      onChange={this.props.handleOnFilter}
                      autoComplete='off'
                    />
                  </span>
                </div>
                <hr size='2' className='device-list-seperator' />
                <div className='overview-wrapper'>
                  {getTranslationText('device', 'totalDevices')}: {this.props.devices.deviceDetails?.count}
                </div>
              </div>
              <div id='devices-list' className='col-sm-12 devices-list'>
                {supportedDevices?.length ? (
                  supportedDevices.map((device, index) => {
                    return (
                      <SidebarDeviceBlock key={index} indexId={index} supportedDevices={supportedDevices} device={device} {...this.props} />
                    )
                  })
                ) : (
                  <div className='text-center  no-devices'>{getTranslationText('device', 'noDevices')}</div>
                )}
              </div>
              {this.props.devices.deviceDetails?.currentPage < this.props.devices.deviceDetails?.totalPages && (
                <div className='col-sm-12 additional-devices-info'>
                  <hr size='2' className='device-list-seperator' />
                  <div
                    className='text-center special-link-txt'
                    onClick={this.props.handleShowMoreClick}
                    onKeyDown={(e) => {
                      if (e.key === 13) {
                        this.props.handleShowMoreClick()
                      }
                    }}
                  >
                    <span>{getTranslationText('common', 'showMoreText')}</span>
                    <DownArrow className='ml8 mr8' />
                  </div>
                </div>
              )}
            </div>
          </BoxHolder>
        </div>
      </div>
    )
  }
}
SidebarDevices.propTypes = {
  handleOnFilter: PropTypes.func,
  supportedDevices: PropTypes.array,
  showMoreLength: PropTypes.any,
  handleShowMoreClick: PropTypes.func,
  selectedDevice: PropTypes.number,
  user: PropTypes.object.isRequired,
  searchValue: PropTypes.string.isRequired,
  clearMyPlanDetails: PropTypes.func,
}
SidebarDevices.defaultProps = {
  selectedDevice: 0,
}

export class SidebarDeviceBlock extends React.Component {
  handleSelectLocal = () => {
    const deviceIndex = this.props.supportedDevices.findIndex(
      (supportedDevice) => supportedDevice.ConsumerProductID === this.props.device.ConsumerProductID
    )
    !this.props.loaderState && this.props.handleSelect(deviceIndex)
  }
  render() {
    let deviceInfo = this.props.device
    let deviceState =
      deviceInfo.ConsumerServiceRequestID && deviceInfo.ConsumerServiceRequestID !== 0 && deviceInfo.DeviceState.DeviceStateID === 1
        ? getTranslationText('device', 'inRepair')
        : deviceInfo.DeviceState.DeviceStateID !== 1
        ? _.get(deviceInfo, 'DeviceState.Description', '').length
          ? deviceInfo.DeviceState.Description
          : deviceInfo.DeviceState.DeviceState
        : ''
    let benefitsList = !deviceInfo.ConsumerServiceRequestID && _.get(deviceInfo, 'BenefitDetails', [])
    return (
      <div
        className={
          'sidebar-device-block ' +
          (this.props.selectedDevice === this.props.indexId ? 'active' : '') +
          (this.props.loaderState ? ' loader-cursor ' : '')
        }
        onClick={this.handleSelectLocal}
      >
        <div className=''>
          <div className='d-flex justify-space-between content fontSmall col-sm-12'>
            <div className='boldFontStyle'>{_.get(deviceInfo, 'Product.ProductName', '')}</div>
            {benefitsList.length > 0 &&
              benefitsList.map((benefit) => (
                <span key={benefit.BenefitDescription} className='availDiscount'>
                  {benefit.BenefitDescription}
                </span>
              ))}
            <div className='selected-arrow'>
              {this.props.selectedDevice === this.props.indexId ? <RightArrow className='white-arrow' /> : <RightArrow />}
            </div>
          </div>
          <div className='col-sm-12 bookFontStyle'>{deviceState}</div>
        </div>
      </div>
    )
  }

  static propTypes = {
    // deviceImageId: PropTypes.string.isRequired,
    selectedDevice: PropTypes.number.isRequired,
    options: PropTypes.array,
    indexId: PropTypes.number.isRequired,
    device: PropTypes.object.isRequired,
    handleSelect: PropTypes.func.isRequired,
    loaderState: PropTypes.bool,
  }

  static defaultProps = {
    device: {},
  }
}

export const AddMoreDevicesBlock = (props) => {
  return (
    <BoxHolder className='fixed-height-override box-holder-marginOverride pb20 pt20'>
      <div className='col-sm-12 purple-link bookFontStyle'>
        <Link
          onClick={() => {
            pushToAnalytics('My - Devices - Add Device', {
              ConsumerID: _.get(props, 'user.userDetails.consumerID', ''),
            })
            props.clearMyPlanDetails()
          }}
          to='/devices/add-device'
          className='inline-ele-Casing add-icon-input'
        >
          <AddIcon />
          <span>{getTranslationText('device', 'addMoreDevices', 'Add device')}</span>
        </Link>
      </div>
    </BoxHolder>
  )
}

// main header
export const MainHeaderDevices = (props) => {
  let productSubCategoryID = _.get(props, 'selectedDeviceDetails.ProductSubCategoryID')
  let hasPlan = false
  let plans = _.get(props, 'devicePlans', [])
  hasPlan =
    plans.length &&
    plans.some((plan) => plan.Status === 0 || plan.Status === 1 || plan.Status === 2 || plan.Status === 8 || plan.Status === 9)
  return (
    <BoxHolder className='box-holder-marginOverride'>
      <div className='device-details-container'>
        <div className='device-details'>
          <img
            className='device-image'
            src={props.productImageUrl && productSubCategoryID && props.productImageUrl + productSubCategoryID + '.png'}
          />
          <div className='main-header-content'>
            <div className='boldFontStyle fontLarge'>{_.get(props, 'selectedDeviceDetails.Product.ProductName', '-')}</div>
            <div className={'bookFontStyle fontMedium mb4'}>{_.get(props, 'selectedDeviceDetails.Brand.BrandName', '-')}</div>
            <div className='row'>
              {(props.selectedDeviceDetails.ConsumerServiceRequestID === 0 ||
                props.selectedDeviceDetails.ConsumerServiceRequestID === null) &&
              (props.selectedDeviceDetails.DeviceState ? props.selectedDeviceDetails.DeviceState.DeviceStateID === 1 : true) &&
              (props.selectedDeviceDetails.DeviceState && props.selectedDeviceDetails.DeviceState.DeviceStateMeta
                ? props.selectedDeviceDetails.DeviceState.DeviceStateMeta.IsDeviceDeletable === 1
                : true) &&
              !hasPlan ? (
                <div className='col-sm-12 track-nav-btn cursor-pointer' onClick={!props.loaderState && props.deleteModalToggle}>
                  <DeleteSvg className='mr4' />
                  <span className={'fontMedium ' + (props.loaderState ? ' loader-cursor ' : '')}>
                    {getTranslationText('common', 'delete', 'Delete')}
                  </span>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className='bookFontStyle fontMedium device-tag-details'>
          {!props.editTagNameFlg ? (
            <>
              {props.selectedDeviceDetails.TagName && <div>{props.selectedDeviceDetails.TagName}</div>}
              <div
                id='editTagNameFlg'
                className='edit-content'
                onClick={(e) => {
                  !props.loaderState && props.toggleEditableOptions(e)
                }}
              >
                {props.selectedDeviceDetails.TagName ? (
                  <CustomIconComponent
                    className={'cursor-pointer edit device-tag-edit' + (props.loaderState ? ' loader-cursor ' : '')}
                    id='editTagNameFlg'
                    onClick={(e) => {
                      !props.loaderState && props.toggleEditableOptions(e)
                    }}
                    alt='Edit'
                    iconTag='edit'
                  />
                ) : (
                  ''
                )}
                {props.selectedDeviceDetails.TagName ? getTranslationText('common', 'editTag') : getTranslationText('common', 'tagDevice')}
              </div>
            </>
          ) : (
            <div className='device-input-tag'>
              <Input
                className={{
                  formControl: 'mr4 tag-input',
                  input: 'bookFontStyle',
                }}
                inputType='text'
                id='device-tag-value'
                placeholder={getTranslationText('device', 'tagName')}
                handleOnChange={props.handleTagNameInputValue}
                value={props.deviceTagName}
                otherProps={{
                  onKeyPress: props.handleTagNameInputValue,
                }}
              />
              <MediumButton
                className='fontMedium button-small'
                style={{ padding: '0px' }}
                onClick={props.updateDeviceTagName}
                id='device-tag-btn'
                buttonText={getTranslationText('common', 'save')}
                btnLoader={props.tagNameBtnLoader}
              />
            </div>
          )}
        </div>
        {(props.selectedDeviceDetails.ConsumerServiceRequestID > 0 && props.selectedDeviceDetails.DeviceState.DeviceStateID === 1) ||
        props.DeviceIsEnrollmentPlan ? (
          <div className={'device-action-details'}>
            {props.DeviceIsEnrollmentPlan && (
              <MediumButton
                className='button-ghost button-small'
                buttonText={getTranslationText('common', 'protectNow')}
                onClick={() => props.protectSelectedDevice()}
              />
            )}
            {props.selectedDeviceDetails.ConsumerServiceRequestID > 0 && props.selectedDeviceDetails.DeviceState.DeviceStateID === 1 && (
              <MediumButton
                className='button-ghost button-small'
                buttonText={getTranslationText('common', 'track')}
                onClick={!props.loaderState && props.handleTrack}
              />
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </BoxHolder>
  )
}
MainHeaderDevices.propTypes = {
  productImageUrl: PropTypes.string,
  selectedDeviceDetails: PropTypes.object.isRequired,
  devicePlans: PropTypes.object.isRequired,
  handleTrack: PropTypes.func.isRequired,
  deleteModalToggle: PropTypes.func.isRequired,
  tagNameBtnLoader: PropTypes.bool.isRequired,
  editTagNameFlg: PropTypes.bool.isRequired,
  loaderState: PropTypes.bool,
  handleTagNameInputValue: PropTypes.func.isRequired,
  updateDeviceTagName: PropTypes.func.isRequired,
  toggleEditableOptions: PropTypes.func.isRequired,
  deviceTagName: PropTypes.string,
  protectSelectedDevice: PropTypes.func.isRequired,
  DeviceIsEnrollmentPlan: PropTypes.bool.isRequired,
}
MainHeaderDevices.defaultProps = {
  selectedDeviceDetails: {},
}

// Extend & under protection
export const ListingPlan = (props) => {
  let redirectionUrl
  const handleRedirectionUrl = async (plan) => {
    if (window.useLogger) {
      let loggerObj = {
        apiName: 'DeviceSubComponents - handleRedirection trigger',
        value: {
          AboutLink: plan.AboutLink,
          TermsAndConditionsLink: plan.TermsAndConditionsLink,
        },
        event: 'before handleRedirection from DeviceSubComponents',
        identifiers: {
          ConsumerID: _.get(props, 'user.userDetails.consumerID'),
        },
      }
      props.loggerActionAsync(loggerObj)
    }
    redirectionUrl = await props.handleRedirection('/terms-conditions', plan.AboutLink || plan.TermsAndConditionsLink)
    if (window.useLogger) {
      let loggerObj = {
        apiName: 'DeviceSubComponents - open redirectionUrl',
        value: {
          redirectionUrl: redirectionUrl,
        },
        event: 'open  redirectionUrl from DeviceSubComponents',
        identifiers: {
          ConsumerID: _.get(props, 'user.userDetails.consumerID'),
        },
      }
      props.loggerActionAsync(loggerObj)
    }
    window.open(redirectionUrl, '_blank')
  }

  const initiateCancelPlan = async () => {
    props.initiateCancelPlan()
  }

  const locale = _.get(props, 'user.selectedCountryData.countryConfig.Locale.DEFAULT', 'en')
  const currencyCode = _.get(props, 'user.selectedCountryData.CurrencyCode')
  const dateFormat = _.get(props, 'user.selectedCountryData.countryConfig.DateFormat.DEFAULT', 'DD/MM/YYYY')
  const disable_change_payment_method = BrandSpecificConfig().header == 'RealmeEU-Web'
  return (
    <BoxHolder className='devices-plans-container'>
      {/* fixed-height-override */}
      <div className='row'>
        <div className={'col-sm-12 ' + (props.devicePlans.length === 1 ? 'no-slider' : '')}>
          {props.devicePlans && props.devicePlans.length > 0 && (
            <Slider
              spacing={10}
              config={[
                { breakpoint: 0, items: 1 }, // for screen width above 0 show 1 item per slide
                { breakpoint: 1200, items: 1 }, // for screen width above 1200 show 4 items per slide
              ]}
              isRTL={window.isDirectionRTL}
            >
              {props.devicePlans.map((plan, index) => {
                let dateLable1
                let dateLable2
                let planDetailsDate1
                let planDetailsDate2
                let planStatus
                let planDuration =
                  plan.CoverageEndDate && plan.CoverageStartDate
                    ? moment(plan.CoverageEndDate).year() - moment(plan.CoverageStartDate).year()
                    : 0
                if (plan.IsEnrollmentPlan) {
                  if (plan.Status == 1) {
                    switch (plan.GroupSoldPlanStatus) {
                      case 1:
                      case -4:
                        // active plans
                        dateLable1 = getTranslationText('subscription', 'coverageStartDate')
                        dateLable2 = getTranslationText('subscription', 'nextBillingDate')
                        planDetailsDate1 = getLocaleDate(dateFormat, plan.CoverageStartDate)
                        planDetailsDate2 = getLocaleDate(dateFormat, plan.NextBillingDate)
                        if (plan.Status == 1 && BrandSpecificConfig().header === 'AppleEU-Web' && planDuration === 5) {
                          planDetailsDate2 = getTranslationText('subscription', 'ongoingUntilCancelled')
                        }
                        break

                      case -5:
                        // active plans // scenario 3 & 4
                        dateLable1 = getTranslationText('subscription', 'cancellationDate')
                        dateLable2 = getTranslationText('subscription', 'coverageEndDate')
                        planDetailsDate1 = getLocaleDate(dateFormat, plan.PlanCancellationDate)
                        planDetailsDate2 = getLocaleDate(dateFormat, plan.CoverageEndDate)
                        break

                      case -3:
                      case -9:
                        // canceled plans
                        dateLable1 = getTranslationText('subscription', 'planPurchaseDate')
                        dateLable2 = getTranslationText('subscription', 'cancellationDate')
                        planDetailsDate1 = getLocaleDate(dateFormat, plan.DateOfPurchase)
                        planDetailsDate2 = getLocaleDate(dateFormat, plan.PlanCancellationDate)
                        break

                      default:
                        // active plan scenario -> 1 & 2
                        dateLable1 = getTranslationText('subscription', 'coverageStartDate')
                        dateLable2 = getTranslationText('subscription', 'nextBillingDate')
                        planDetailsDate1 = getLocaleDate(dateFormat, plan.CoverageStartDate)
                        planDetailsDate2 = getLocaleDate(dateFormat, plan.NextBillingDate)
                        break
                    }
                  } else if (plan.Status == -4) {
                    switch (plan.GroupSoldPlanStatus) {
                      case -3:
                        // canceled plans // scenario 5 & 6
                        dateLable1 = getTranslationText('subscription', 'planPurchaseDate')
                        dateLable2 = getTranslationText('subscription', 'cancellationDate')
                        planDetailsDate1 = getLocaleDate(dateFormat, plan.DateOfPurchase)
                        planDetailsDate2 = getLocaleDate(dateFormat, plan.PlanCancellationDate)
                        break

                      default:
                        dateLable1 = getTranslationText('subscription', 'planPurchaseDate')
                        dateLable2 = getTranslationText(
                          'subscription',
                          BrandSpecificConfig().header === 'AppleEU-Web' ? 'planCancellationDate' : 'cancellationDate'
                        )
                        planDetailsDate1 = getLocaleDate(dateFormat, plan.DateOfPurchase)
                        planDetailsDate2 = getLocaleDate(dateFormat, plan.PlanCancellationDate)
                        break
                    }
                  } else {
                    switch (plan.Status) {
                      case 2:
                      case -9: //on hold plans
                        dateLable1 = getTranslationText('subscription', 'planPurchaseDate')
                        dateLable2 = getTranslationText('subscription', 'coverageEndDate')
                        planDetailsDate1 = getLocaleDate(dateFormat, plan.DateOfPurchase)
                        planDetailsDate2 = getLocaleDate(dateFormat, plan.CoverageEndDate)
                        if (plan.Status == -9 && BrandSpecificConfig().header === 'AppleEU-Web' && planDuration === 5) {
                          planDetailsDate2 = getTranslationText('subscription', 'ongoingUntilCancelled')
                        }
                        break

                      case -6:
                      case -4:
                      case 3:
                      case 4:
                        dateLable1 = getTranslationText('subscription', 'planPurchaseDate')
                        dateLable2 = getTranslationText(
                          'subscription',
                          BrandSpecificConfig().header === 'AppleEU-Web' ? 'planCancellationDate' : 'cancellationDate'
                        )
                        planDetailsDate1 = getLocaleDate(dateFormat, plan.DateOfPurchase)
                        planDetailsDate2 = getLocaleDate(dateFormat, plan.PlanCancellationDate)
                        break

                      default:
                        dateLable1 = getTranslationText('subscription', 'coverageStartDate')
                        dateLable2 = getTranslationText('subscription', 'nextBillingDate')
                        planDetailsDate1 = getLocaleDate(dateFormat, plan.CoverageStartDate)
                        planDetailsDate2 = getLocaleDate(dateFormat, plan.NextBillingDate)
                        if (plan.Status == 1 && BrandSpecificConfig().header === 'AppleEU-Web' && planDuration === 5) {
                          planDetailsDate2 = getTranslationText('subscription', 'ongoingUntilCancelled')
                        }
                    }
                  }
                } else {
                  switch (plan.Status) {
                    case 0:
                    case 2:
                      dateLable1 = getTranslationText('subscription', 'planPurchaseDate')
                      dateLable2 = getTranslationText('subscription', 'coverageEndDate')
                      planDetailsDate1 = getLocaleDate(dateFormat, plan.DateOfPurchase)
                      planDetailsDate2 = getLocaleDate(dateFormat, plan.CoverageEndDate)
                      break

                    case 1:
                    // active plans - one time purchase plans
                    case -9: // on hold plans
                      dateLable1 = getTranslationText('subscription', 'coverageStartDate')
                      dateLable2 = getTranslationText('subscription', 'coverageEndDate')
                      planDetailsDate1 = getLocaleDate(dateFormat, plan.CoverageStartDate)
                      planDetailsDate2 = getLocaleDate(dateFormat, plan.CoverageEndDate || plan.ValidityDate)
                      if (BrandSpecificConfig().header === 'AppleEU-Web' && planDuration === 5) {
                        planDetailsDate2 = getTranslationText('subscription', 'ongoingUntilCancelled')
                      }
                      break

                    case -3:
                      dateLable1 = getTranslationText('subscription', 'coverageStartDate')
                      dateLable2 = getTranslationText('subscription', 'coverageEndDate')
                      planDetailsDate1 = getLocaleDate(dateFormat, plan.CoverageStartDate)
                      planDetailsDate2 = getLocaleDate(dateFormat, plan.CoverageEndDate || plan.ValidityDate)
                      break

                    case 14:
                      dateLable1 = getTranslationText('subscription', 'coverageStartDate')
                      dateLable2 = getTranslationText('subscription', 'coverageEndDate')
                      planDetailsDate1 = getLocaleDate(dateFormat, plan.CoverageStartDate)
                      planDetailsDate2 = getLocaleDate(dateFormat, plan.CoverageEndDate || plan.ValidityDate)
                      break

                    default:
                      dateLable1 = getTranslationText('subscription', 'planPurchaseDate')
                      dateLable2 = getTranslationText(
                        'subscription',
                        BrandSpecificConfig().header === 'AppleEU-Web' ? 'planCancellationDate' : 'cancellationDate'
                      )
                      planDetailsDate1 = getLocaleDate(dateFormat, plan.CoverageStartDate)
                      planDetailsDate2 = getLocaleDate(dateFormat, plan.PlanCancellationDate)
                      break
                  }
                }

                if (plan.Status == 1) {
                  if (plan.GroupSoldPlanStatus) {
                    switch (plan.GroupSoldPlanStatus) {
                      case -9:
                        planStatus = getTranslationText('device', 'frozen')
                        break
                      case 4:
                      case 1:
                      case -5:
                        planStatus = getTranslationText('device', 'active')
                        break
                      case -3:
                        planStatus = getTranslationText('device', 'cancelled')
                        break
                    }
                  } else {
                    planStatus = getTranslationText('device', 'active')
                  }
                } else {
                  switch (plan.Status) {
                    case -6:
                      planStatus = getTranslationText('device', 'planTransferred')
                      break
                    case -5:
                      planStatus = getTranslationText('device', 'planDowngraded')
                      break
                    case 5:
                      planStatus = getTranslationText('device', 'planUpgraded')
                      break
                    case -4:
                    case 3:
                    case 4:
                      planStatus = getTranslationText('device', 'cancelled')
                      break
                    case -3:
                      planStatus = getTranslationText('device', 'expired')
                      break
                    case -9:
                      planStatus = getTranslationText('device', 'onhold')
                      break
                    case 1:
                      planStatus = getTranslationText('device', 'active')
                      break
                    case 2:
                    case 8:
                    case 9:
                      planStatus = getTranslationText('device', 'activationPending')
                      break
                    case 10:
                      planStatus = getTranslationText('device', 'benefitsAvailed')
                      break
                    case 14:
                      planStatus = getTranslationText('device', 'paymentPending')
                      break
                  }
                }
                let labelText
                // let cursorDisabled = false
                if (plan.Status == 1 && plan.GroupSoldPlanStatus == -5) {
                  if (plan.CancellationDaysLeft > 0) {
                    labelText = getTranslationText('subscription', 'planWillCancelInDays', '', '@days@', plan.CancellationDaysLeft)
                  } else if (plan.CoverageDaysLeft > 0) {
                    labelText = getTranslationText('subscription', 'coverageEndsInDays', '', '@days@', plan.CoverageDaysLeft)
                  }
                } else if (plan.Status == 1 && plan.CoolingPeriodDays > 0) {
                  labelText = getTranslationText('subscription', 'coverageStartsInDays', '', '@days@', plan.CoolingPeriodDays)
                } else if (plan.Status == -6) {
                  labelText = getTranslationText('subscription', 'planTransferredToNewDevice')
                } else if (plan.Status == 10) {
                  labelText = getTranslationText('subscription', 'planBenefitsAvailed')
                } else if (plan.Status == -5) {
                  labelText = getTranslationText('subscription', 'planHasBeenDowngraded')
                  // cursorDisabled = true
                } else if (plan.Status == 5) {
                  labelText = getTranslationText('subscription', 'planHasBeenUpgraded')
                  // cursorDisabled = true
                }
                return (
                  <div key={index}>
                    <div className='plan-header-container'>
                      <div className={'plan-heading bookFontStyle ' + (window.isDirectionRTL ? ' pr0 ' : ' pl0')}>
                        {getTranslationText('device', 'protectionPlan')}
                      </div>
                      <div
                        className={`${
                          plan.Status === -9 ? 'payment-onhold-bg' : plan.Status === 1 ? 'active-plan-bg' : 'cancelled-plan-bg'
                        }`}
                      >
                        <div
                          className={`${
                            plan.Status === -9 ? 'payment-onhold' : plan.Status === 1 ? 'active-plan' : 'cancelled-plan'
                          } bookFontStyle fontMedium`}
                        >
                          {planStatus}
                        </div>
                      </div>
                    </div>
                    <div className='device-warrenty-container mt8'>
                      <div className='warrenty-device-img'>
                        {plan && plan.PlanEmailImage ? <img src={plan.PlanEmailImage} /> : <div className='alt-image' />}
                      </div>
                      <div className='warrenty-device-content'>
                        <div className='mediumFontStyle plan-title fontMedium' style={{ cursor: 'default' }}>
                          {plan.PlanName}
                        </div>
                      </div>
                    </div>

                    {_.includes([-6, 10, 5, -5, 14], plan.Status) ? (
                      ''
                    ) : (
                      <div className='row mt8'>
                        <div className='col-6'>
                          <div className='lightFontStyle payment-method-title dateLable'>{dateLable1}</div>
                          <div className='text-date mediumFontStyle payment-method-val'>{planDetailsDate1}</div>
                        </div>
                        <div className='col-6'>
                          <div className='lightFontStyle payment-method-title dateLable'>{dateLable2}</div>
                          <div className='text-date mediumFontStyle payment-method-val'>{planDetailsDate2}</div>
                        </div>
                      </div>
                    )}

                    {[-6, 10].includes(plan.Status) ? (
                      ''
                    ) : (
                      <div
                        className={
                          (plan.CancellationDaysLeft || plan.CoolingPeriodDays || plan.CoolingPeriodDays ? `view-tnc ` : '') +
                          ` col-sm-12 mt8 plan-descp`
                        }
                      >
                        <div className='mediumFontStyle fontMedium purple-link plan-benefit center-align'>
                          <a
                            onClick={() => handleRedirectionUrl(plan)}
                            href={redirectionUrl}
                            target='_blank'
                            className='inline-ele-Casing mb8'
                            rel='noreferrer'
                          >
                            <span>{getTranslationText('device', 'planBenefits')}</span>
                          </a>
                        </div>
                        {BrandSpecificConfig().header === 'AppleEU-Web' && plan.Status == 1 && plan.PlanCancellationDate && (
                          <div className='lightFontStyle smallFont plan-cancellation-note mt8'>
                            {getTranslationText('device', 'cancellationRequestNote').replace(
                              '@plan_cancellation_date@',
                              plan.PlanCancellationDate ? getLocaleDate(dateFormat, plan.PlanCancellationDate) : ''
                            )}
                          </div>
                        )}
                        {props.isPlanCancellable && (
                          <div className='mediumFontStyle fontMedium center-align cancel-contract'>
                            <a
                              onClick={initiateCancelPlan}
                              href={redirectionUrl}
                              target='_blank'
                              className='inline-ele-Casing mb8'
                              rel='noreferrer'
                            >
                              <span>{getTranslationText('device', 'cancelContract')}</span>
                            </a>
                          </div>
                        )}
                      </div>
                    )}
                    {labelText ? <div className='plan-view-coverage boldFontStyle'>{labelText}</div> : ''}
                    {!disable_change_payment_method &&
                    plan.Category !== 'NORMAL' &&
                    plan.FrequencyUnit &&
                    plan.RequiresExternalPayment === 'SERVIFY_PAYMENT' ? (
                      <>
                        <div className='row mt8'>
                          <div className='col-sm-12 divider'>
                            {plan.Status === -9 ? (
                              <>
                                <div className='lightFontStyle smallFont auto-debit-failed-msg mt16'>
                                  {getTranslationText('device', 'autoDebitFailedMsg')
                                    .replace('@amount@', getLocaleCurrency(locale, currencyCode, plan.PlanAmount))
                                    .replace('@plan_name@', plan.PlanName)
                                    .replace('@pending_months@', plan.NoOfPaymentPending)
                                    .replace('@billing_date@', plan.EffectiveDate ? getLocaleDate(dateFormat, plan.EffectiveDate) : '')
                                    .replace(
                                      '@grace_period_enddate@',
                                      plan.HoldPeriodDate ? getLocaleDate(dateFormat, plan.HoldPeriodDate) : ''
                                    )}
                                </div>
                                <div className='mediumFontStyle fontMedium purple-link plan-benefit center-align'>
                                  <a
                                    onClick={() => window.open(plan.PaymentLink || '', '_blank')}
                                    // href={redirectionUrl}
                                    target='_blank'
                                    className='inline-ele-Casing mb8'
                                    rel='noreferrer'
                                  >
                                    <span>{getTranslationText('common', 'pay')}</span>
                                  </a>
                                </div>
                              </>
                            ) : (
                              ''
                            )}
                            <div className='lightFontStyle payment-method-title mt16'>{getTranslationText('device', 'paymentMethod')}</div>
                            {props.cardDetails && props.cardDetails.Type === 'card' ? (
                              <>
                                <div className='lightFontStyle payment-method-label mt4'>
                                  {getTranslationText('device', 'cardNumber')}
                                  <span className='boldFontStyle payment-method-val fontMedium mt4 ml4'>
                                    {props.cardDetails && props.cardDetails.LastFourDigits
                                      ? 'XXXXXXXXXXXX' + props.cardDetails.LastFourDigits
                                      : '-'}
                                  </span>
                                </div>
                                <div className='lightFontStyle payment-method-label mt4'>
                                  {getTranslationText('device', 'cardType')}
                                  <span className='mediumFontStyle payment-method-val fontMedium text-title-case mt4 ml4'>
                                    {props.cardDetails && props.cardDetails.Brand ? props.cardDetails.Brand : '-'}
                                  </span>
                                </div>
                                <div className='lightFontStyle payment-method-label mt4'>
                                  {getTranslationText('device', 'expiry')}
                                  <span className='mediumFontStyle payment-method-val fontMedium mt4 ml4'>
                                    {props.cardDetails && props.cardDetails.ExpiryMonth && props.cardDetails.ExpiryYear
                                      ? (props.cardDetails.ExpiryMonth.toString().length < 2
                                          ? '0' + props.cardDetails.ExpiryMonth
                                          : props.cardDetails.ExpiryMonth) +
                                        '/' +
                                        props.cardDetails.ExpiryYear
                                      : '-'}
                                  </span>
                                </div>
                              </>
                            ) : (
                              ''
                            )}
                            {props.cardDetails && props.cardDetails.Type === 'sepa_debit' ? (
                              <>
                                <div className='lightFontStyle payment-method-label mt4'>
                                  {getTranslationText('device', 'iban') + ':'}
                                  <span className='boldFontStyle payment-method-val mt4 ml4'>
                                    {props.cardDetails && props.cardDetails.LastFourDigits
                                      ? 'XXXXXXXXXXXX' + props.cardDetails.LastFourDigits
                                      : '-'}
                                  </span>
                                </div>
                                <div className='lightFontStyle payment-method-label mt4'>
                                  {getTranslationText('device', 'paymentMode') + ':'}
                                  <span className='mediumFontStyle payment-method-val text-title-case mt4 ml4'>
                                    {getTranslationText('device', 'sepa')}
                                  </span>
                                </div>
                                <div className='lightFontStyle payment-method-label mt4'>
                                  {getTranslationText('device', 'bankCode') + ':'}
                                  <span className='mediumFontStyle payment-method-val mt4 ml4'>
                                    {props.cardDetails && props.cardDetails.BankCode ? props.cardDetails.BankCode : '-'}
                                  </span>
                                </div>
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <div className='row mt16'>
                          <div className='col-6'>
                            <div className='lightFontStyle smallFont dateLable'>
                              {BrandSpecificConfig().header !== 'AppleEU-Web' ? (
                                getTranslationText('device', 'subscriptionFee')
                              ) : (
                                <>
                                  {getTranslationText('device', 'premium')}
                                  <Tooltip
                                    content={getTranslationText(
                                      'device',
                                      'IPTStatement_' + _.get(props, 'user.selectedCountryData.CountryCode', 'IN')
                                    )}
                                    position='right'
                                  >
                                    <InfoIcon className='ml8' />
                                  </Tooltip>
                                </>
                              )}
                            </div>
                            <div className='text-date mediumFontStyle fontLarge'>
                              {getLocaleCurrency(locale, currencyCode, plan.PlanAmount)}
                              {_.get(plan, 'FrequencyUnitDisplay')
                                ? `/${getTranslationText('common', plan.FrequencyUnitDisplay.toLowerCase()) || plan.FrequencyUnitDisplay} `
                                : ''}
                            </div>
                            {BrandSpecificConfig().header === 'AppleEU-Web' && (
                              <div className='fontSmall'>({getTranslationText('device', 'vatExempt')})</div>
                            )}
                          </div>
                          {[1, -9].includes(plan.Status) && plan.NextBillingDate ? (
                            <div className='col-6'>
                              <div className='lightFontStyle smallFont dateLable'>{getTranslationText('device', 'nextBillingDate')}</div>
                              <div className='text-date mediumFontStyle fontLarge'>{getLocaleDate(dateFormat, plan.NextBillingDate)}</div>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                    {!disable_change_payment_method &&
                    [1, -9, 10].includes(plan.Status) &&
                    plan.Category !== 'NORMAL' &&
                    plan.NextBillingDate &&
                    plan.RequiresExternalPayment === 'SERVIFY_PAYMENT' &&
                    (plan.FrequencyUnit === 'MONTH' || plan.FrequencyUnit === 'YEAR') ? (
                      [1, 10].includes(plan.Status) && [1, 0].includes(plan.TransactionStatus) ? (
                        <div className='mediumFontStyle fontMedium purple-link plan-benefit center-align'>
                          <a onClick={() => props.onClickChangePaymentMethod(plan)} className='inline-ele-Casing mb8'>
                            <span>{getTranslationText('device', 'changePaymentMethod')}</span>
                          </a>
                        </div>
                      ) : [1, -9].includes(plan.Status) && [5, -2].includes(plan.TransactionStatus) ? (
                        <div className='bookFontStyle fontMedium plan-benefit center-align disabled-text'>
                          <span>{getTranslationText('device', 'changePaymentMethod')}</span>
                          <Tooltip content={getTranslationText('device', 'optionDisabled')} position='top'>
                            <img className='ml4 info-icon-disabled' src={InfoImg} />
                          </Tooltip>
                        </div>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                    {plan.Category !== 'NORMAL' && plan.RequiresExternalPayment === 'SERVIFY_PAYMENT' ? (
                      <div className='mediumFontStyle fontMedium center-align purple-link mt16'>
                        <a
                          onClick={() => {
                            props.viewBillingHistory(plan.SoldPlanID, plan)
                          }}
                          // href={redirectionUrl}
                          target='_blank'
                          className='inline-ele-Casing mb8'
                          rel='noreferrer'
                        >
                          <span>{getTranslationText('device', 'viewBillingHistory')}</span>
                        </a>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                )
              })}
            </Slider>
          )}
        </div>
      </div>
    </BoxHolder>
  )
}
ListingPlan.propTypes = {
  devicePlans: PropTypes.array.isRequired,
  handleRedirection: PropTypes.func.isRequired,
}

// services div
export const ServicesDiv = (props) => {
  let serviceOptnList = _.get(props, 'user.appConfigDetails')
  serviceOptnList = {
    showNormalRepairOptions: true,
    showRepairOptions: true,
    showServiceEstimator: true,
    showServicing: true,
    showInstallAndDemo: true,
    showGetInTouch: true,
    ShowRaiseClaim: false,
    ...serviceOptnList,
    showRefundOption: false,
    showReplacementOption: false,
  }

  let IsServiceable =
    _.get(props, 'selectedDeviceDetails.ProductConfig.IsServiceable', true) &&
    _.get(props, 'selectedDeviceDetails.DeviceState.DeviceStateMeta.IsServiceable', true)
  IsServiceable = IsServiceable && !props.selectedDeviceDetails.ConsumerServiceRequestID

  let claimOtpnFlg = IsServiceable && _.get(props, 'selectedDeviceDetails.DeviceState.DeviceStateMeta.ShowRaiseClaim', false) // device level key
  let repairOtpnFlg = IsServiceable && serviceOptnList.showRepairOptions
  if (!serviceOptnList.showNormalRepairOptions && (repairOtpnFlg || claimOtpnFlg)) {
    let planList = props.selectedDeviceDetails.PolicyDetails.find(
      (planInfo) => planInfo.Status === 1 && planInfo.NoOfRepairsAllowed - planInfo.NoOfRepairsUsed > 0
    )
    repairOtpnFlg = !!planList && repairOtpnFlg
    // claimOtpnFlg = !!planList && claimOtpnFlg
  }

  let productSupportedModes = _.get(props, 'selectedDeviceDetails.ProductSubCategory.SupportedModes', [])
  productSupportedModes = [...productSupportedModes]
  let modifiedOptions = requestTypesList().filter((option) => {
    let key = option.key
    if (key === 'ShowRaiseClaim') {
      if (serviceOptnList[key] && claimOtpnFlg) {
        return option
      }
    } else if (key === 'showRepairOptions') {
      if (serviceOptnList[key] && repairOtpnFlg && productSupportedModes.includes('repair')) {
        return option
      }
    } else {
      if (serviceOptnList[key]) {
        if (option.skipRepairCheck) {
          // for estimate
          return option
        } else {
          if (IsServiceable && productSupportedModes.includes(option.type)) {
            return option
          }
        }
      }
    }
  })

  return (
    <BoxHolder>
      <div className='row'>
        <div className='col-sm-12 mb20'>
          <Heading wordBreak title={getTranslationText('common', 'getServiceLabel')} />
        </div>
        <div className='col-sm-12'>
          {Object.keys(props.selectedDeviceDetails).length > 0 && (
            <div>
              {modifiedOptions &&
                modifiedOptions.length > 0 &&
                modifiedOptions.map((serviceType, index) => (
                  <ServicesDivSubComponents
                    id={serviceType.type}
                    key={index}
                    onServiceClick={props.handleServiceMode}
                    label={serviceType.text}
                    srcImg={serviceType.image}
                    appName={_.get(props, 'user.appConfigDetails.app')}
                  />
                ))}
              <ServicesDivSubComponents
                id='contact'
                onServiceClick={() => (window.fcWidget ? window.fcWidget.open() : props.handleServiceMode())}
                label={getTranslationText('common', 'getInTouchLabel')}
                srcImg={`/${appName}/images/GetinTouch.png`}
                removemargin
              />
            </div>
          )}
        </div>
      </div>
    </BoxHolder>
  )
}
ServicesDiv.propTypes = {
  handleServiceMode: PropTypes.func.isRequired,
  selectedDeviceDetails: PropTypes.object.isRequired,
}

export const ServicesDivSubComponents = (props) => {
  if (
    ['ServifyCare-Web', 'HpCare-Web', 'SamsungUS-Web', 'SamsungEU-Web'].includes(props.appName) &&
    ['Book a repair'].includes(props.label)
  ) {
    // for apps mentioned above - book a repair button treated as Raise a claim [ShowRaiseAClaim should be present in deviceMeta]
    return ''
  } else {
    return (
      <div
        className={'services-div-devices' + (props.removemargin ? ' remove-bottom-marign' : '')}
        id={props.id}
        onClick={props.onServiceClick}
      >
        <div className='services-div-img'>
          <img src={props.srcImg} />
        </div>
        <div className='services-div-content special-link-txt-black'>
          <div className='boldFontStyle fontSmall overrideCasing '>{props.label}</div>
        </div>
        <div className='services-div-arrow'>
          <RightArrow />
        </div>
      </div>
    )
  }
}
ServicesDivSubComponents.propTypes = {
  id: PropTypes.string.isRequired,
  onServiceClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  srcImg: PropTypes.string.isRequired,
  removemargin: PropTypes.bool,
}

// Device Details

export class DeviceDetails extends React.Component {
  dateRef = React.createRef()
  WarrantyDescription = (warrantyDetails) => {
    return (
      <div>
        {`${warrantyDetails.WarrantyType}`}&nbsp;
        <span className='bookFontStyle fontSmall overrideCasing'>({`${warrantyDetails.WarrantyDescription}`})</span>
      </div>
    )
  }
  render() {
    const narwalAndLGBrandCheck = ['NarwalUS-Web', 'LGLA-Web'].includes(_.get(this, 'props.user.appConfigDetails.app'))
    const appleEUCheck = ['AppleEU-Web'].includes(_.get(this, 'props.user.appConfigDetails.app'))
    const isLGbrandCheck = ['LGLA-Web'].includes(_.get(this, 'props.user.appConfigDetails.app'))
    const active_plan = this.props.selectedDeviceDetails?.PolicyDetails?.find((planInfo) => planInfo.Status === 1)

    const isLGbrandSerialNumberPresent =
      isLGbrandCheck && !!this.props.selectedDeviceDetails?.ProductUniqueID && !!this.props.selectedDeviceDetails.ProductDeliveryDate

    const isNarwalUSbrandCheck = ['NarwalUS-Web'].includes(_.get(this, 'props.user.appConfigDetails.app'))
    const isNarwalAndLGSerialNumberPresent =
      isNarwalUSbrandCheck && !!this.props.selectedDeviceDetails?.ProductUniqueID && !!this.props.selectedDeviceDetails?.AlternateUniqueKey

    let verificationStateMeta = this.props.selectedDeviceDetails.VerificationStateMeta || {
      DisplayWarrantyStatus: true,
      IsUniqueIdEditable: true,
      IsWarrantyEditable: true,
    }

    if (narwalAndLGBrandCheck) {
      verificationStateMeta.IsUniqueIdEditable = true
    }

    let device_editable = false
    if (narwalAndLGBrandCheck) {
      if (isNarwalAndLGSerialNumberPresent || isLGbrandSerialNumberPresent) {
        device_editable = false
      } else if (Object.keys(active_plan || {}).length > 0 && verificationStateMeta.IsUniqueIdEditable && !this.props.editstatus) {
        device_editable = true
      } else {
        device_editable = false
      }
    } else {
      if (this.props.selectedDeviceDetails.IsVerified) {
        device_editable = false
      }
      if (verificationStateMeta.IsUniqueIdEditable) {
        device_editable = true
      }
    }

    if (this.props.selectedDeviceDetails.ConsumerServiceRequestID) {
      device_editable = false
    }

    let orderCheckDetails = this.props.hasOrderType
    const curdate = new Date()
    const language = localStorage.getItem('languageCode')
    const locale = _.get(this, 'props.locale', 'en-IN')
    return (
      <BoxHolder>
        <div className='row' id='device-details'>
          <div className='col-sm-12 mb20 device-details-heading'>
            <div className='row row-remove-flex'>
              <div className='col-sm-9'>
                <Heading className='device-details-headings' title={getTranslationText('device', 'deviceDetails')} />
              </div>
              {device_editable ? (
                <div className={'col-sm-3 edit-icon ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}>
                  <div
                    id='devicespan'
                    className='edit-content'
                    onClick={(e) => {
                      !this.props.loaderState && this.props.handleClick(e)
                    }}
                  >
                    <CustomIconComponent
                      className={'edit device-tag-edit mr4' + (this.props.loaderState ? ' loader-cursor ' : '')}
                      onClick={(e) => {
                        !this.props.loaderState && this.props.handleClick(e)
                      }}
                      id='devicespan'
                      alt='Cancel'
                      iconTag='edit'
                    />
                    {getTranslationText('common', 'edit')}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className='col-sm-12 device-details-index'>
            {!this.props.hideWarrantyStatus ? (
              <div className='device-details-status mb20 mt8'>
                {!narwalAndLGBrandCheck &&
                this.props.isEditable &&
                verificationStateMeta &&
                verificationStateMeta.IsWarrantyEditable &&
                this.props.selectedDeviceDetails.BrandID !== 4 ? (
                  <div>
                    <div className='bookFontStyle fontSmall mt8 overrideCasing'>{getTranslationText('device', 'deviceWarranty')}</div>
                    <RadioBtnPills
                      prefix='warranty'
                      noSlots
                      optionList={this.props.warrantyDetails}
                      onClick={this.props.toggleWarrentyStatus}
                    />
                  </div>
                ) : !narwalAndLGBrandCheck && verificationStateMeta.DisplayWarrantyStatus ? (
                  !this.props.selectedDeviceDetails.ProductUniqueID &&
                  !this.props.selectedDeviceDetails.DownloadedDeviceUniqueKey &&
                  this.props.selectedDeviceDetails.isAppleBrandID ? (
                    ''
                  ) : (
                    <div>
                      <div className='bookFontStyle fontSmall overrideCasing'>{getTranslationText('device', 'warrantyStatus')}</div>
                      <div className='boldFontStyle fontSmall'>
                        {this.props.selectedDeviceDetails.WarrantyType && this.props.selectedDeviceDetails.isAppleBrandID
                          ? this.WarrantyDescription(this.props.selectedDeviceDetails)
                          : this.props.selectedDeviceDetails.IsUnderWarranty
                          ? getTranslationText('device', 'warrantyIn')
                          : getTranslationText('device', 'notInWarranty')}
                      </div>
                    </div>
                  )
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}

            {!narwalAndLGBrandCheck &&
            !this.props.hideWarrantyStatus &&
            this.props.warrantyStatus &&
            (moment(this.props.Datavalue.WarrantyTill, 'YYYY-MM-DD hh:mm:ss A Z').isValid() ||
              this.props.Datavalue.WarrantyTill === null ||
              this.props.Datavalue.WarrantyTill === '') ? (
              <div className='device-details-status mb20 mt8'>
                <div className='bookFontStyle fontSmall'>{getTranslationText('common', 'warrantyTillDate')}</div>
                <div className='boldFontStyle fontSmall'>
                  {this.props.isEditable && verificationStateMeta.IsWarrantyEditable ? (
                    <DatePicker
                      placeholder={this.props.dateFormat}
                      format={this.props.dateFormat.toLowerCase()}
                      maxDay={new Date(curdate.getFullYear() + 1, curdate.getMonth(), curdate.getDate())}
                      minDay={new Date()}
                      handleOnChange={(date) => this.props.handleDatePickerOnChange(date, 'WarrantyTill')}
                      date={this.props.Datavalue.WarrantyTill || ''}
                      locale={locale}
                      language={language}
                    >
                      <CalendarIcon />
                    </DatePicker>
                  ) : (
                    <div className='maindetails-label'>
                      {this.props.Datavalue.WarrantyTill ? getLocaleDate(this.props.dateFormat, this.props.Datavalue.WarrantyTill) : '-'}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              ''
            )}

            {!narwalAndLGBrandCheck && (
              <DeviceDetailsComponent
                type='text'
                id='DownloadedDeviceUniqueKey'
                Iphandler={this.props.updateValues}
                placeholder={getTranslationText('device', 'placeholderDeviceUniqKey')}
                titleClassName='overrideCasing'
                label={orderCheckDetails ? 'Order No' : getTranslationText('common', 'serialNumber')}
                isEditable={this.props.isEditable && this.props.selectedDeviceDetails.VerificationStateMeta?.IsUniqueIdEditable}
                values={
                  this.props.selectedDeviceDetails.BrandID === 4 && this.props.Datavalue.AlternateUniqueKey
                    ? this.props.Datavalue.AlternateUniqueKey
                    : this.props.Datavalue.DownloadedDeviceUniqueKey
                }
              />
            )}
            {appleEUCheck ? (
              this.props.selectedDeviceDetails.ProductSubCategoryID === 12 &&
              (this.props.Datavalue.AlternateUniqueKey === this.props.Datavalue.ProductUniqueID ||
                this.props.Datavalue.DownloadedDeviceUniqueKey === this.props.Datavalue.ProductUniqueID ||
                !this.props.Datavalue.ProductUniqueID) ? (
                ''
              ) : (
                <div className={'device-details-status mb20 mt8'}>
                  <DeviceDetailsComponent
                    type='text'
                    id='ProductUniqueID'
                    isEditable={this.props.isEditable && verificationStateMeta.IsUniqueIdEditable}
                    Iphandler={this.props.updateValues}
                    values={this.props.Datavalue.ProductUniqueID}
                    label={
                      this.props.selectedDeviceDetails.BrandID === 4
                        ? getTranslationText('device', 'imei')
                        : getTranslationText('device', 'imei1')
                    }
                    placeholder={getTranslationText('device', 'placeholderImei')}
                  />
                </div>
              )
            ) : (
              ''
            )}
            {!appleEUCheck &&
            !narwalAndLGBrandCheck &&
            _.includes([12, 22, 13, 84], this.props.selectedDeviceDetails.ProductSubCategoryID) ? (
              <div>
                <div className={'device-details-status mb20 mt8'}>
                  <DeviceDetailsComponent
                    type='text'
                    id='ProductUniqueID'
                    isEditable={this.props.isEditable && verificationStateMeta.IsUniqueIdEditable}
                    Iphandler={this.props.updateValues}
                    values={this.props.Datavalue.ProductUniqueID}
                    label={
                      this.props.selectedDeviceDetails.BrandID === 4
                        ? getTranslationText('device', 'imei')
                        : getTranslationText('device', 'imei1')
                    }
                    placeholder={getTranslationText('device', 'placeholderImei')}
                  />
                </div>
                {this.props.selectedDeviceDetails.BrandID === 4 ? (
                  ''
                ) : (
                  <DeviceDetailsComponent
                    isEditable={this.props.isEditable && verificationStateMeta.IsUniqueIdEditable}
                    type='text'
                    id='AlternateUniqueKey'
                    Iphandler={this.props.updateValues}
                    label={getTranslationText('device', 'imei2')}
                    values={this.props.Datavalue.AlternateUniqueKey}
                    placeholder={getTranslationText('device', 'placeholderImei')}
                    removeMargin
                  />
                )}
              </div>
            ) : (
              ''
            )}

            {narwalAndLGBrandCheck ? (
              <div>
                <div className={'device-details-status mb20 mt8'}>
                  <DeviceDetailsComponent
                    isError={this.props.setProductUniqueIDError}
                    isEditable={this.props.isEditable && !this.props.selectedDeviceDetails?.ProductUniqueID}
                    type='text'
                    id='ProductUniqueID'
                    titleClassName={this.props.setProductUniqueIDError ? 'inputError' : ''}
                    Iphandler={this.props.updateValues}
                    values={this.props.Datavalue.ProductUniqueID}
                    label={`${getTranslationText('common', 'serialNumber')} ${!isLGbrandCheck ? ' (Robot)' : ''}`}
                    placeholder={getTranslationText('device', 'placeholderDeviceUniqKey')}
                  />
                </div>
                {this.props.setProductUniqueIDError && (
                  <span className='error-text'>{getTranslationText('device', 'serialNoErrorMsg')}</span>
                )}
                {isNarwalUSbrandCheck ? (
                  <div className={'device-details-status mb20 mt8'}>
                    <DeviceDetailsComponent
                      isError={this.props.setAlternateUniqueKeyError}
                      isEditable={this.props.isEditable && !this.props.selectedDeviceDetails?.AlternateUniqueKey}
                      type='text'
                      id='AlternateUniqueKey'
                      titleClassName={this.props.setAlternateUniqueKeyError ? 'inputError' : ''}
                      Iphandler={this.props.updateValues}
                      label={getTranslationText('common', 'serialNumber') + ' (Charging Dock)'}
                      values={this.props.Datavalue.AlternateUniqueKey}
                      placeholder={getTranslationText('device', 'placeholderDeviceUniqKey')}
                      removeMargin
                    />
                  </div>
                ) : (
                  ''
                )}
                {this.props.setAlternateUniqueKeyError && (
                  <span className='error-text'>{getTranslationText('device', 'serialNoErrorMsg')}</span>
                )}
              </div>
            ) : (
              ''
            )}

            {isLGbrandCheck ? (
              <div className='device-details-status mb20 mt8'>
                <div className='bookFontStyle fontSmall'>{getTranslationText('device', 'deliveryDate')}</div>
                <div ref={this.dateRef} className='boldFontStyle fontSmall'>
                  {this.props?.isEditable && !this.props.Datavalue?.ProductDeliveryDate ? (
                    <DatePicker
                      placeholder={this.props.dateFormat}
                      format={this.props.dateFormat.toLowerCase()}
                      minDay={new Date(curdate.getFullYear() - 10, curdate.getMonth(), curdate.getDate())}
                      maxDay={new Date()}
                      handleOnChange={(date) => this.props.handleDatePickerOnChange(date, 'ProductDeliveryDate')}
                      date={this.props.Datavalue.ProductDeliveryDate || ''}
                      locale={locale}
                      language={language}
                    >
                      <CalendarIcon
                        onClick={() => {
                          this.dateRef.current.querySelector('input').click()
                        }}
                      />
                    </DatePicker>
                  ) : (
                    <div className='maindetails-label'>
                      {this.props.Datavalue.ProductDeliveryDate
                        ? getLocaleDate(this.props.dateFormat, this.props.Datavalue.ProductDeliveryDate)
                        : '-'}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
            {this.props.isEditable ? (
              <div className='row'>
                <div className='col-sm-12 mt20'>
                  <MediumButton
                    buttonText={getTranslationText('common', 'cancel')}
                    onClick={this.props.handleClick}
                    id='cancelbtn'
                    disabled={this.props.Dbtnstatus}
                    className='button-small button-ghost mt8 mr8'
                  />
                  <MediumButton
                    buttonText={getTranslationText('common', 'save')}
                    className='button-small save-button mt8'
                    id='devicebtn'
                    onClick={this.props.handleClick}
                    disabled={this.props.Dbtnstatus}
                  />
                </div>
              </div>
            ) : (
              ' '
            )}
          </div>
        </div>
      </BoxHolder>
    )
  }
}
DeviceDetails.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  Datavalue: PropTypes.object.isRequired,
  updateValues: PropTypes.func.isRequired,
  Dbtnstatus: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  warrantyStatus: PropTypes.bool,
  warrantyDetails: PropTypes.array.isRequired,
  toggleWarrentyStatus: PropTypes.func.isRequired,
  selectedDeviceDetails: PropTypes.object.isRequired,
  loaderState: PropTypes.bool,
  editstatus: PropTypes.bool.isRequired,
  handleDatePickerOnChange: PropTypes.func.isRequired,
  hasOrderType: PropTypes.bool.isRequired,
}

export const DeviceDetailsComponent = (props) => {
  return (
    <div className={'device-details-status mb20 mt8' + (props.removeMargin ? ' remove-bottom-marign' : '')}>
      <div className={'bookFontStyle fontMedium device-details-labels ' + props.titleClassName}>{props.label}</div>
      <div className='bookFontStyle fontMedium'>
        {props.isEditable ? (
          <Input
            className={{
              formControl: 'mt4',
              input: 'input-text ' + (props?.isError ? 'inputTextError' : ''),
            }}
            inputType='text'
            id={props.id}
            placeholder={props.placeholder}
            handleOnChange={props.Iphandler}
            value={props.values || ''}
            otherProps={{
              required: true,
            }}
          />
        ) : (
          <div className='maindetails-label txt_upperCase mt4'>{props.values || '-'}</div>
        )}
      </div>
    </div>
  )
}
DeviceDetailsComponent.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  Iphandler: PropTypes.func.isRequired,
  values: PropTypes.string,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  removeMargin: PropTypes.bool,
  titleClassName: PropTypes.string,
}

export const ServiceHistoryWidget = (props) => {
  return (
    <BoxHolder className='service-history-list'>
      <Heading wordBreak title={getTranslationText('service', 'serviceHistory')} />
      <div className='mt20 service-history-container'>
        {props.deviceServiceHistory && props.deviceServiceHistory.length ? (
          props.deviceServiceHistory.map((data, index) => {
            let showRating = _.get(data, 'ConsumerServiceRequest.lastDisplayInfo.ShowRating', false)
            let serviceType = _.get(data, 'ConsumerServiceRequest.servicetype.Type', '')
            const status = _.get(data, 'ConsumerServiceRequest.lastDisplayInfo.DisplayText', data.ConsumerServiceRequest.Status)
            return (
              <div key={index}>
                <div
                  className={'service-history-items ' + (showRating ? 'show-cursor' : '')}
                  key={index}
                  onClick={() => {
                    showRating && props.handlePendingReqAction(data)
                  }}
                >
                  <div className='bookFontStyle fontSmall product-details'>
                    <div className='boldFontStyle fontSmall service-type'>{serviceType}</div>
                    <div className='bookFontStyle fontSmall'>
                      {getLocaleDate(props.dateFormat, data.ConsumerServiceRequest.UpdatedDate)}
                    </div>
                    <div className='bookFontStyle fontSmall mb8'>{status}</div>
                  </div>
                  {showRating ? (
                    <div className='service-history-chevron'>
                      <RightArrow />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <hr size='2' className='col-sm-12 mt20 mb20' />
              </div>
            )
          })
        ) : (
          <div className='bookFontStyle fontSmall mt20 mb20'>{getTranslationText('device', 'noDeviceHistory')}</div>
        )}
      </div>
    </BoxHolder>
  )
}
ServiceHistoryWidget.propTypes = {
  deviceServiceHistory: PropTypes.array,
  handlePendingReqAction: PropTypes.func,
}

export const DeviceDocsWidget = (props) => {
  return (
    <BoxHolder className='device-docs-widget'>
      <div className='row'>
        <div className='col-sm-12 mb20'>
          <Heading
            className='device-details-headings'
            wordBreak
            title={getTranslationText('device', 'addDeviceDocs', 'Add device documents')}
          />
        </div>
        <div className='col-sm-12'>
          <div className='row'>
            <div className='col-3 img-min-width'>
              <BoxImagesComponent
                loaderState={props.loaderState}
                isUploaded={false}
                imgFilePath={<AddIcon />}
                inputName='deviceDocs-upload'
                inputId={'upload-img-ip-id'}
                fileUploadHander={window.useDocService ? props.uploadProductDocToDS : props.handleUploadDocs}
              />
            </div>
            {props.deviceDocs &&
              props.deviceDocs.length > 0 &&
              props.deviceDocs.map((data) => {
                return (
                  <div key={data.ConsumerProductDocumentID}>
                    <div className='col-3 img-min-width'>
                      <BoxImagesComponent
                        loaderState={props.loaderState}
                        isUploaded
                        imgFilePath={data.FilePath}
                        imgViewerHandler={() => props.openDeviceDocsGallery(data)}
                        imageID={data.ConsumerProductDocumentID}
                        type={data.Type}
                      />
                    </div>
                  </div>
                )
              })}
          </div>
          <div className='bookFontStyle fontSmall mt20 mb20 note_text'>{getTranslationText('device', 'deviceDocUploadNote')}</div>
        </div>
      </div>
    </BoxHolder>
  )
}
DeviceDocsWidget.propTypes = {
  handleUploadDocs: PropTypes.func.isRequired,
  deviceDocs: PropTypes.array.isRequired,
  openDeviceDocsGallery: PropTypes.func.isRequired,
  loaderState: PropTypes.bool,
}
