import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { appDownloadLinkViaSmsAsync, setTncLink, setPrivacyLink } from '../../store/user'
import { setToaster } from '../../store/toastsAndLoaders'
import { FooterHeading, FooterMiniHeading, MediumButton, FooterText } from '../UIComponents'
import { Input } from 'sfy-react'
import { Footer as SFYFooter } from 'sfy-react'
import './Footer.scss'
import { BrandSpecificConfig, defaultFooterLinks, menuLangTranslationMapping } from '../../utils/constants'
import { regexCheck } from '../../utils/regex'
import _ from 'lodash'
import { getTranslationText } from '../../utils/languageTranslation.js'
import getFooterData from '../../api/getFooterData'
import getAppDownloadLink from '../../api/getAppDownloadLink'
import safePromise from '../../utils/safePromise'
import { isValidMobileNumber } from '../../utils/validators'
import { ReactComponent as Email } from '../../../public/commonAssets/email-web.svg'
import { ReactComponent as Phone } from '../../../public/commonAssets/phone.svg'

const brandConfig = require('../../utils/constants').BrandSpecificConfig()

export class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appLinkMobileNo: '',
      phnoLengthAr: this.props.user.selectedCountryData.AllowedMobileLengths
        ? this.props.user.selectedCountryData.AllowedMobileLengths.split(',').map((int) => parseInt(int))
        : [10],
      downloadsection: _.get(this, 'props.user.appConfigDetails.downloadsection', null),
      footerText: _.get(this, 'props.user.appConfigDetails.footerLinks.footerText', ''),
    }
  }

  canShowAppDownloadLink = () => {
    const showDownloadContent = _.get(this, 'props.user.appConfigDetails.showDownloadAppFlg', false)
    const appDownloadRoutesFlg = _.get(this, 'props.user.appConfigDetails.appDownloadRoutesFlg', ['/'])
    return appDownloadRoutesFlg.includes('/' + this.props.pathName.split('/')[1]) && showDownloadContent
  }

  fetchAppDownloadLink = async () => {
    const [error, response] = await safePromise(this.props.getAppDownloadLink())
    if (error) {
      // log error to elastic
    }
    const downloadsection = _.get(response, 'data.data', '') || _.get(this, 'props.user.appConfigDetails.downloadsection')
    this.setState({ downloadsection })
  }

  fetchFooterSignature = async () => {
    const [error, response] = await safePromise(this.props.getFooterData())
    if (error) {
      // log error to elastic
      return
    }
    const footerText = _.get(response, 'data.data.signature', this.state.footerText)
    this.setState({ footerText })
  }

  autoFetchAppDownloadLink = () => {
    this.canShowAppDownloadLink() && this.fetchAppDownloadLink()
  }

  componentDidMount = () => {
    this.fetchFooterSignature()
    this.autoFetchAppDownloadLink()
  }

  componentDidUpdate = (prevProps) => {
    const hasLanguageChanged = prevProps.languageCode !== this.props.languageCode
    if (hasLanguageChanged) {
      this.fetchFooterSignature()
      this.autoFetchAppDownloadLink()
    }
  }

  // to check mobile number formate and validation
  checkFormat = (value) => {
    if (this.state.phnoLengthAr.includes(value.length) && !isNaN(value)) {
      this.setState(
        {
          invalidFormat: isValidMobileNumber(value, _.get(this, 'props.user.selectedCountryData.CountryCode')),
        },
        () => {
          this.state.invalidFormat && this.props.setToaster(getTranslationText('error', 'invalidMobileNumber'), 'error')
        }
      )
    }
    return true
  }

  handleAppLinkNo = (e) => {
    if (this.checkFormat(e.target.value) && regexCheck('onChange', 'numeric', e.target.value, Math.max(...this.state.phnoLengthAr))) {
      this.setState({
        appLinkMobileNo: e.target.value,
      })
    }
  }

  getAppDownloadLink = () => {
    this.props
      .appDownloadLinkViaSmsAsync({
        CountryCode: '+ ' + this.props.user.selectedCountryData.PhoneCode,
        MobileNo: this.state.appLinkMobileNo,
        Source: BrandSpecificConfig().header,
      })
      .then((response) => {
        response.success && this.props.setToaster(getTranslationText('success', 'appLink'), 'success')
        this.setState({
          appLinkMobileNo: '',
        })
      })
  }
  render() {
    return (
      <div>
        {this.canShowAppDownloadLink() && this.state.downloadsection && (
          <FooterDownload
            {...this.props}
            phnoLengthAr={this.state.phnoLengthAr}
            downloadsection={this.state.downloadsection}
            appLinkMobileNo={this.state.appLinkMobileNo}
            invalidFormat={this.state.invalidFormat}
            handleAppLinkNo={this.handleAppLinkNo}
            getAppDownloadLink={this.getAppDownloadLink}
          />
        )}
        {brandConfig.ClientName === 'OnePlus' ? (
          <div className='row oneplus-footer'>
            {!_.isEmpty(this.props.user.selectedCountryData) && (
              <FooterComponent {...this.props} selectedCountryDetails={this.props.user.selectedCountryData} />
            )}
          </div>
        ) : (
          this.props.pathName !== '/privacy-policy' &&
          this.props.pathName !== '/terms-conditions' &&
          this.props.pathName !== '/online-consent' && (
            <React.Fragment>
              {!_.get(this, 'props.user.appConfigDetails.SkipStaticFooter', false) && this.props.pathName !== '/about-us' && <FooterInfo />}
              {!_.get(this, 'props.user.appConfigDetails.SkipFooter', false) && <FooterConnect {...this.props} />}
              <FooterServify
                {..._.get(this, 'props.user.appConfigDetails.footerLinks', {})}
                footerImage={_.get(this, 'props.user.appConfigDetails.footerLinks.footerImage', '')}
                footerText={this.state.footerText}
                AcceptedCards={_.get(this, 'props.user.appConfigDetails.footerLinks.AcceptedCards', '')}
                isShowFooterBrandLogo={_.get(this, 'props.user.appConfigDetails.isShowFooterBrandLogo', true)}
              />
            </React.Fragment>
          )
        )}
      </div>
    )
  }
}

Footer.propTypes = {
  user: PropTypes.object.isRequired,
  pathName: PropTypes.string.isRequired,
  languageCode: PropTypes.string,
  appDownloadLinkViaSmsAsync: PropTypes.func.isRequired,
  setToaster: PropTypes.func.isRequired,
  getAppDownloadLink: PropTypes.func.isRequired,
  getFooterData: PropTypes.func.isRequired,
  setTncLink: PropTypes.func.isRequired,
  setPrivacyLink: PropTypes.func.isRequired,
}

export const FooterComponent = (props) => {
  let countryDetail = props.selectedCountryDetails
  let footerLinks = _.get(props, 'user.appConfigDetails.footerLinks.bussiness')
  let customeFooterText = _.get(props, 'user.appConfigDetails.footerLinks.footerText', '')
  return (
    <div className='footer-container'>
      <div className='footer-ele'>
        <div className='country-text'>
          <div className='flag-image'>
            <img className='cn-flag' src={countryDetail && countryDetail.FlagImageURL ? countryDetail.FlagImageURL : ''} />
            <div className='cn'>
              <span className='bookFontStyle fontLarge'>
                &ensp;
                {countryDetail && countryDetail.CountryName ? countryDetail.CountryName : ''} &nbsp;{' '}
              </span>
              <span className='lightFontStyle fontLarge cr'>
                ({_.get(countryDetail, 'languages[0].Name') ? countryDetail.languages[0].Name + ' / ' : ''}
                {countryDetail.CurrencyCode ? countryDetail.CurrencyCode : ''})
              </span>
            </div>
          </div>
        </div>
        {customeFooterText && <div className='bookFontStyle fontMedium custome-footer-text'>{customeFooterText}</div>}
        <div className='nav-text'>
          <a target='_blank' href={(footerLinks && footerLinks.home) || 'https://www.oneplus.in'} rel='noreferrer'>
            <span className='lightFontStyle fontMedium ml0'>{getTranslationText('common', 'home')}</span>
          </a>
          <a target='_blank' href={(footerLinks && footerLinks.aboutUs) || 'https://www.oneplus.in/about-us'} rel='noreferrer'>
            <span className='lightFontStyle fontMedium'>{getTranslationText('footer', 'aboutOnePlus')} </span>
          </a>
          <a target='_blank' href={(footerLinks && footerLinks.privacyPolicy) || (footerLinks && footerLinks.Privacy)} rel='noreferrer'>
            <span className='lightFontStyle fontMedium border-none'> {getTranslationText('footer', 'privacy')} </span>
          </a>
        </div>
        <div className='seprator' />
        <div className='tc-text'>
          <span className='lightFontStyle fontSmall'>© 2013-2019 OnePlus. {getTranslationText('footer', 'rightsReserved')}</span>
          <span className='lightFontStyle fontMedium span-right '> {getTranslationText('footer', 'powered')} </span>
        </div>
      </div>
    </div>
  )
}

FooterComponent.propTypes = {
  selectedCountryDetails: PropTypes.any,
}

class FooterDownload extends Component {
  render() {
    let baseUrl = this.props.user.appConfigDetails.S3baseUrl
    let sendBtnDisable = this.props.phnoLengthAr.includes(this.props.appLinkMobileNo.length) && !this.props.invalidFormat
    if (this.props.downloadsection) {
      return (
        <div className='row section-first'>
          <div className='col-sm-5'>
            <img src={`/${appName}/images/Footer_icon.png`} alt='' className='footer-jombotron' />
          </div>
          <div className={'col-sm-7 app-store-links ' + (window.isDirectionRTL ? ' text-right ' : ' text-left')}>
            <FooterHeading title={this.props.downloadsection.maintext} />
            <FooterText text={this.props.downloadsection.subtext} />
            <div className='footer-img-container'>
              {this.props.downloadsection.appstorelink && (
                <Link href={this.props.downloadsection.appstorelink} target='_blank'>
                  <img
                    className={'app-store-img mb8 ' + (window.isDirectionRTL ? ' ml8 ' : ' mr8')}
                    src={baseUrl + 'email-images-servify/app-store.png'}
                  />
                </Link>
              )}
              {this.props.downloadsection.playstorelink && (
                <Link href={this.props.downloadsection.playstorelink} target='_blank'>
                  <img className='app-store-img mb8' src={baseUrl + 'email-images-servify/play-store.png'} />
                </Link>
              )}
            </div>
            <div className='app-link-txt boldFontStyle'>{getTranslationText('footer', 'getAppLink')}</div>
            <div className='get-a-link'>
              <Input
                inputType='text'
                value={'+ ' + this.props.user.selectedCountryData.PhoneCode}
                className={{
                  inputContainer: 'footer-input-pre ',
                }}
                isDisabled
              />
              <Input
                inputType='text'
                placeholder={getTranslationText('footer', 'inputMobileNumber')}
                value={this.props.appLinkMobileNo}
                handleOnChange={this.props.handleAppLinkNo}
              />
              <MediumButton
                className='footer-button'
                buttonText={getTranslationText('common', 'send')}
                onClick={this.props.getAppDownloadLink}
                disabled={!sendBtnDisable}
              />
            </div>
          </div>
        </div>
      )
    }
  }
  static propTypes = {
    user: PropTypes.object,
    downloadsection: PropTypes.object,
    appLinkMobileNo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    handleAppLinkNo: PropTypes.func.isRequired,
    getAppDownloadLink: PropTypes.func.isRequired,
    phnoLengthAr: PropTypes.array.isRequired,
    invalidFormat: PropTypes.bool,
  }
}

class FooterInfo extends Component {
  render() {
    return (
      <div className={'row footer-second ' + (window.isDirectionRTL ? ' text-right ' : ' text-left')}>
        <div className='col-sm-6 col-lg-3 footer-info-divs'>
          <div className='footer-img-content'>
            <img className='footer-info-img' src={'/commonAssets/Footer/img-4-png@3x.png'} />
          </div>
          <FooterMiniHeading title={getTranslationText('footer', 'footerTag')} className='info-divs-heading' />
          <div className='fontMedium bookFontStyle'>{getTranslationText('footer', 'splitStringFooter')}</div>
        </div>
        <div className='col-sm-6 col-lg-3 footer-info-divs'>
          <div className='rating-img-content'>
            <img className='rating-img-padding' src={'/commonAssets/Footer/group-4@3x.png'} />
          </div>
          <FooterMiniHeading title={getTranslationText('footer', 'customeRatingLabel')} className='info-divs-heading' />
          <div className='fontMedium bookFontStyle'>{getTranslationText('footer', 'ratingSatisfaction')}</div>
        </div>
        <div className='col-sm-6 col-lg-3 footer-info-divs'>
          <div className='footer-img-content'>
            <img className='footer-info-img' src={'/commonAssets/Footer/layer-39@3x.png'} />
          </div>
          <FooterMiniHeading title={getTranslationText('footer', 'helpCenterLabel')} className='info-divs-heading' />
          <div className='fontMedium bookFontStyle'>{getTranslationText('footer', 'splitStringFooter1')}</div>
        </div>
        <div className='col-sm-6 col-lg-3 footer-info-divs'>
          <div className='footer-img-content'>
            <img className='footer-info-img' src={'/commonAssets/Footer/layer-40@3x.png'} />
          </div>
          <FooterMiniHeading title={getTranslationText('footer', 'cashlessLabel')} className='info-divs-heading' />
          <div className='fontMedium bookFontStyle'>{getTranslationText('footer', 'splitStringFooter2')}</div>
        </div>
      </div>
    )
  }
}

class FooterConnect extends Component {
  getMenuTranlations = (tab) => getTranslationText('common', menuLangTranslationMapping[tab.label], tab.label)
  handleRedirection = (type, customLink, _link) => {
    let skipRedirection = _.get(this, 'props.user.appConfigDetails.SkipRedirection', false)
    let link = customLink
    if (type === 'tnc') {
      link = _link
      skipRedirection && this.props.setTncLink(link)
    } else if (type === 'privacy') {
      link = _link
      skipRedirection && this.props.setPrivacyLink(link)
    }
    if (skipRedirection) {
      link = customLink
    }
    return link
  }
  render() {
    let FAQFooterLink = _.get(this, 'props.user.appConfigDetails.footerLinks.support.FaqLink', '')
    let mailLink = _.get(this, 'props.user.appConfigDetails.BrandConnect.email[0]', 'support@servify.in')
    let consumerFooterLink = _.get(
      this,
      'props.user.appConfigDetails.footerLinks.consumer',
      defaultFooterLinks && defaultFooterLinks.consumerLinks
    )
    let bussinessFooterLink = _.get(
      this,
      'props.user.appConfigDetails.footerLinks.bussiness',
      defaultFooterLinks && defaultFooterLinks.bussinessLinks
    )
    let stayConnectedFooterLink = _.get(
      this,
      'props.user.appConfigDetails.footerLinks.stayConnected',
      defaultFooterLinks && defaultFooterLinks.stayConnectedLinks
    )
    const showStayConnectedlink = _.get(this, 'props.user.appConfigDetails.footerLinks.showStayConnected')
    const tncLink = _.get(this, 'props.user.appConfigDetails.SkipRedirection', false) ? '/terms-conditions' : bussinessFooterLink.Terms
    const privacyLink = _.get(this, 'props.user.appConfigDetails.SkipRedirection', false)
      ? '/privacy-policy'
      : bussinessFooterLink.privacyPolicy
    let supportFooterLink = _.get(
      this,
      'props.user.appConfigDetails.footerLinks.support',
      defaultFooterLinks && defaultFooterLinks.supportLinks
    )
    const showFooterLinks = _.get(this, 'props.user.appConfigDetails.footerLinks.showFooterLinks', true)
    // const appName = _.get(this, 'props.user.appConfigDetails.app', undefined)
    let timings = _.get(this, 'props.user.appConfigDetails.footerLinks.support.timings', '')
    timings = timings && timings.split('\n')

    let brandConfigName = BrandSpecificConfig().ClientName
    if (brandConfigName == 'Realme') {
      brandConfigName = BrandSpecificConfig().ClientName.toLowerCase()
    }
    const companyFooterLink = _.get(this, 'props.user.appConfigDetails.footerLinks.company', {})
    return (
      <>
        {showFooterLinks && (
          <div className={'row section-without-margin ' + (window.isDirectionRTL ? ' text-right ' : ' text-left')}>
            {consumerFooterLink && consumerFooterLink.length ? (
              <div className='col-sm-3 col-6 mb8'>
                <FooterMiniHeading title={getTranslationText('common', 'consumerLabel')} />
                <div className='special-bottom-border' />
                {consumerFooterLink &&
                  consumerFooterLink
                    .filter((data) => data.display && data.label !== 'Blog') // Filter out "Blog" and check display
                    .map((data) => (
                      <div key={data.label} className='padding-top'>
                        <Link to={data.link} target={data.newTab ? '_blank' : ''} className='footer-links inline-ele-Casing'>
                          <span>{this.getMenuTranlations(data)}</span>
                        </Link>
                      </div>
                    ))}
              </div>
            ) : (
              ''
            )}

            {Object.keys(bussinessFooterLink).length ? (
              <div className='col-sm-3 col-6 mb8'>
                <FooterMiniHeading title={getTranslationText('footer', 'businessLabel')} />
                <div className='special-bottom-border' />
                {bussinessFooterLink.aboutBrand && (
                  <div className='padding-top'>
                    <Link to={bussinessFooterLink.aboutBrand} target='_blank' className='footer-links inline-ele-Casing'>
                      <span>
                        {`${getTranslationText('footer', 'about')} 
                      ${BrandSpecificConfig().header == 'RealmeEU-Web' ? 'ASG' : brandConfigName}`}
                      </span>
                    </Link>
                  </div>
                )}
                {bussinessFooterLink.aboutServify && (
                  <div className='padding-top'>
                    <Link to={bussinessFooterLink.aboutServify} target='_blank' className='footer-links inline-ele-Casing'>
                      <span>{getTranslationText('footer', 'about')} Servify</span>
                    </Link>
                  </div>
                )}
                {bussinessFooterLink.privacyPolicy && (
                  <div className='padding-top'>
                    <Link
                      to={privacyLink}
                      target='_blank'
                      className='footer-links '
                      onClick={() => {
                        this.handleRedirection('privacy', '/privacy-policy', bussinessFooterLink.privacyPolicy)
                      }}
                    >
                      <span>{getTranslationText('common', 'privacyPolicy')}</span>
                    </Link>
                  </div>
                )}
                {bussinessFooterLink?.customBusinessPrivacy ? (
                  <div className='padding-top'>
                    <Link to={bussinessFooterLink.customBusinessPrivacy} target='_blank' className='footer-links inline-ele-Casing'>
                      <span>{'HP Privacy Choices'}</span>
                    </Link>
                  </div>
                ) : (
                  ''
                )}
                {bussinessFooterLink.Home && (
                  <div className='padding-top'>
                    <Link to={bussinessFooterLink.Home} target='_blank' className='footer-links '>
                      <span>{getTranslationText('common', 'home')}</span>
                    </Link>
                  </div>
                )}
                {bussinessFooterLink.Terms && (
                  <div className='padding-top'>
                    <Link
                      to={tncLink}
                      target='_blank'
                      className='footer-links '
                      onClick={() => {
                        this.handleRedirection('tnc', '/terms-conditions', bussinessFooterLink.Terms)
                      }}
                    >
                      <span>{getTranslationText('common', 'termsAndCondition')}</span>
                    </Link>
                  </div>
                )}
                {bussinessFooterLink.contactUs && (
                  <div className='padding-top'>
                    <Link to={bussinessFooterLink.contactUs} target='_blank' className='footer-links inline-ele-Casing'>
                      <span>{getTranslationText('common', 'contactUs')}</span>
                    </Link>
                  </div>
                )}
              </div>
            ) : (
              ''
            )}

            {Object.keys(companyFooterLink).length ? (
              <div className='col-sm-3 col-6 mb10'>
                <FooterMiniHeading title={getTranslationText('common', 'company')} />
                <div className='special-bottom-border' />

                {companyFooterLink.aboutServify && (
                  <div className='padding-top'>
                    <Link to={companyFooterLink.aboutServify} target='_blank' className='footer-links inline-ele-Casing'>
                      <span>{getTranslationText('footer', 'about')} Servify</span>
                    </Link>
                  </div>
                )}
                {companyFooterLink.privacyPolicy && (
                  <div className='padding-top'>
                    <Link to={companyFooterLink.privacyPolicy} target='_blank' className='footer-links '>
                      <span>{getTranslationText('common', 'privacyPolicy')}</span>
                    </Link>
                  </div>
                )}
                {companyFooterLink.Terms && (
                  <div className='padding-top'>
                    <Link to={companyFooterLink.Terms} target='_blank' className='footer-links '>
                      <span>{getTranslationText('common', 'termsAndCondition')}</span>
                    </Link>
                  </div>
                )}
                {companyFooterLink.warning && (
                  <div className='padding-top'>
                    <Link to={companyFooterLink.warning} target='_blank' className='footer-links '>
                      <span>{getTranslationText('common', 'warning')}</span>
                    </Link>
                  </div>
                )}
              </div>
            ) : (
              ''
            )}

            {Object.keys(supportFooterLink).length ? (
              <div className='col-sm-3 col-6 mb8'>
                <FooterMiniHeading title={getTranslationText('common', 'supportLabel')} />
                <div className='special-bottom-border' />
                {FAQFooterLink && (
                  <div className='padding-top'>
                    <div className='connect-servify fontSmall bookFontStyle'>
                      <Link to={FAQFooterLink} target='_blank' className='footer-links'>
                        <img className='faqIcon' src={`/${appName}/images/FAQ_Icon.png`} />
                        <span>{getTranslationText('common', 'faq')}</span>
                      </Link>
                    </div>
                  </div>
                )}
                <div className='padding-top'>
                  <div
                    className='connect-servify footer-links bookFontStyle'
                    onClick={() => {
                      window.location.href = 'mailto:' + _.get(this, 'props.user.appConfigDetails.footerLinks.support.email', mailLink)
                    }}
                  >
                    <Email className='mr8' />
                    <span>{_.get(this, 'props.user.appConfigDetails.footerLinks.support.email') || mailLink}</span>
                  </div>
                </div>
                <div className='padding-top'>
                  <div className={'connect-servify footer-links no-cursor bookFontStyle'}>
                    <Phone className='mr8' />
                    {_.get(this, 'props.user.appConfigDetails.footerLinks.support.number') || '1800 121 4984'}
                  </div>
                  {timings ? (
                    <div className={'connect-servify footer-timings no-cursor bookFontStyle mt8 ml20'}>
                      {timings.map((part) => {
                        return <p key={part}>{part}</p>
                      })}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {_.get(this, 'props.user.appConfigDetails.footerLinks.support.webLink') && (
                  <div className='padding-top'>
                    <div className='connect-servify fontSmall bookFontStyle'>
                      <img src={'/commonAssets/Footer/Footer_website.png'} />
                      <Link
                        className='footer-links'
                        target='_blank'
                        to={_.get(this, 'props.user.appConfigDetails.footerLinks.support.webLink')}
                      >
                        {brandConfigName}
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              ''
            )}
            {showStayConnectedlink ? (
              <div className='col-sm-3 col-6 mb8'>
                <FooterMiniHeading title={getTranslationText('footer', 'stayConnectedLabel')} />
                <div className='special-bottom-border' />
                <div className='mt8'>
                  {stayConnectedFooterLink.facebook && (
                    <Link to={stayConnectedFooterLink.facebook} target='_blank' className='social-link'>
                      <img className='mt8' src={'/commonAssets/Footer/facebook@3x.png'} />
                    </Link>
                  )}
                  {stayConnectedFooterLink.twitter && (
                    <Link to={stayConnectedFooterLink.twitter} target='_blank' className=' social-link'>
                      <img className='mt8' src={'/commonAssets/Footer/twitter@3x.png'} />
                    </Link>
                  )}
                  {stayConnectedFooterLink.linkedIn && (
                    <Link to={stayConnectedFooterLink.linkedIn} target='_blank' className=' social-link'>
                      <img className='mt8' src={'/commonAssets/Footer/linkedin@3x.png'} />
                    </Link>
                  )}
                  {stayConnectedFooterLink.google && (
                    <Link to={stayConnectedFooterLink.google} target='_blank' className='social-link'>
                      <img className='mt8' src={'/commonAssets/Footer/google+@3x.png'} />
                    </Link>
                  )}
                  {stayConnectedFooterLink.instagram && (
                    <Link to={stayConnectedFooterLink.instagram} target='_blank' className='social-link'>
                      <img className='mt8' src={'/commonAssets/Footer/instagram@3x.png'} />
                    </Link>
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        )}
      </>
    )
  }
}
// FooterConnect.propTypes = {
//   user: PropTypes.object.isRequired
// }
const CurrentYear = new Date().getFullYear()
const FooterServify = (props) => (
  <SFYFooter className={{ footerContainer: 'row section-lower' }}>
    {_.get(props, 'isShowFooterBrandLogo') ? (
      <div className={'col-sm-2 servify-logo-container ' + (window.isDirectionRTL ? ' text-right ' : ' text-left')}>
        <div className='footer-logo-section'>
          <img className='servify-logo' src={`/commonAssets/PoweredbyServify.png` || props.footerImage} />
        </div>
      </div>
    ) : (
      ''
    )}
    <div
      className={`${
        _.get(props, 'isShowFooterBrandLogo') ? 'text-center' : window.isDirectionRTL ? ' text-right ' : ' text-left'
      } col-sm-8 bookFontStyle pad-top-6 servify-text`}
    >
      <p
        dangerouslySetInnerHTML={{
          __html:
            props.footerText ||
            `Servify is a registered Trademark of Service Lee Technologies Private Limited.
        Copyright © Servify ${CurrentYear - 1}-${CurrentYear}`,
        }}
      />
    </div>
    {props.AcceptedCards ? (
      <div className={'col-sm-2 card-logo-container ' + (window.isDirectionRTL ? ' text-right ' : ' text-left')}>
        <div>
          <img className='card-logo' src={props.AcceptedCards} />
        </div>
      </div>
    ) : (
      ''
    )}
  </SFYFooter>
)

FooterServify.propTypes = {
  footerImage: PropTypes.string.isRequired,
  footerText: PropTypes.string.isRequired,
  AcceptedCards: PropTypes.string.isRequired,
  isShowFooterBrandLogo: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  user: state.user,
  translationData: state.languageDetail.translationText,
  languageCode: state.languageDetail.languageCode,
})

const mapDispatchToProps = {
  appDownloadLinkViaSmsAsync,
  getFooterData,
  getAppDownloadLink,
  setToaster,
  setTncLink,
  setPrivacyLink,
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
