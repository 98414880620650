import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import './login.scss'
import { LoginPhNo, LoginContactDetails, LoginOTP, AccountSelect } from './LoginSubComponent'
import { Captcha, getCaptcha, Modal, Dropdown } from 'sfy-react'
import { BrandSpecificConfig, legalConfigCodes, legalConfigAttributes, generateRandomString } from '../../../utils/constants'
import { regexCheck } from '../../../utils/regex'
import { isValidMobileNumber } from '../../../utils/validators'
import { browserHistory } from 'react-router'
import _ from 'lodash'
import { MediumButton } from '../../../components/UIComponents/Buttons/Buttons'
import lookup from '../../../utils/ipinfo'
import { getTranslationText } from '../../../utils/languageTranslation'
import pushToAnalytics from '../../../utils/Analytics'
import { getLegalConsents, replacePlaceholders, getPlaceholders } from '../../../utils/functions'

class Login extends Component {
  defaultLoginPageDetails = {
    title: '',
    subtitle: '',
    newCustomer: '',
    newCustomerHelp: '',
    existingCustomer: '',
    mobileNumberLabel: '',
    insurance: '',
    termsLabel: '',
    disclaimer: '',
  }
  constructor(props) {
    super(props)
    this.state = {
      loginPageDetails: this.defaultLoginPageDetails,
      loginPageId: 'login',
      otpValue: '',
      resendOTP: false,
      ResendOTPRequest: false,
      allowResend: false,
      Phno: '',
      EmailID: '',
      UsrEmailid: '',
      UsrMobileNo: '',
      UsrName: '',
      showResendOTPbtn: false,
      frequentCalls: 60,
      showOTPTimer: false,
      showCallOptnBtn: false,
      otpViaCallTimer: 60,
      showCallTimer: false,
      sendOTPCounter: 0,
      selectedCountryObj: _.get(props, 'user.selectedCountryData', {}),
      BtnLoaderStatus: false,
      setFocusOTP: false,
      termsAndCondCheck: false,
      invalidFormat: false,
      configFetched: false,
      disableResendOtpCall: false,
      accountInput: '',
      accountType: 'phone',
      showPhoneCodeOptions: false,
      selectedAccount: '',
      selectedContact: {},
      existingContact: [],
    }
    this.dropdownOptionsRef = createRef()
    this.phonecodeDropdown = createRef()
  }

  componentDidMount = async () => {
    this.props.setglobalLoaderStatus(true)
    this.setLayoutMaxWidth('100%')
    this.props.clearConsumerDetails()
    this.setPrefilledUserDetails()
    if (_.isEmpty(this.props.user.userDetails)) {
      const regionCode = await lookup()
      window.CountryCode = regionCode
      window.CountryID = undefined
      // Domain redirection if Not country is Not India in "nokia.servify.tech"
      if (BrandSpecificConfig().ClientName.includes('Nokia') && window.location.hostname.includes('nokia.servify.tech')) {
        if (!window.CountryCode.includes('IN')) {
          window.location.assign('https://nokia.aftersalesgroup.com/store')
        }
      }
    }
    await this.props.loadAppConfig()
    await this.props.getAppLegalConfigs()

    const isSSOEnabled = _.get(this, 'props.user.appConfigDetails.isSSOEnabled', false)
    const callbackURL = _.get(this, 'props.user.appConfigDetails.callbackURL', false)
    if (isSSOEnabled && _.isEmpty(this.props.user.userDetails) && !callbackURL) {
      await this.props.generateRedirectionUrl()
    }
    this.setCountryLocal(window.CountryCode)
    this.props.setglobalLoaderStatus(false)
    window.addEventListener('beforeunload', this.handleBeforeUnload)
    window.addEventListener('click', this.handleOutsideClick)
  }

  handleBeforeUnload = () => {
    const { isSSOFlow, isAnGuestUser } = this.props.user
    !isSSOFlow && isAnGuestUser && this.props.setSelectedCountryData({})
  }

  handleOutsideClick = (e) => {
    if (
      this.state.loginPageId === 'login' &&
      this.phonecodeDropdown.current &&
      this.dropdownOptionsRef.current &&
      !this.phonecodeDropdown.current.contains(e.target) &&
      !this.dropdownOptionsRef.current.contains(e.target) &&
      this.state.showPhoneCodeOptions
    ) {
      this.setState({
        showPhoneCodeOptions: false,
      })
    }
  }

  handleLoginRedirection = (route = 'login') => {
    this.setState({
      loginPageId: route,
    })
  }

  setCountryLocal = (regionCode) => {
    let selectedObj = this.props.user.countries.find((country) => country.CountryCode === regionCode)
    if (_.isEmpty(selectedObj)) {
      this.toggleModal('showCountryModal', true)
      if (_.isEmpty(this.state.selectedCountryObj)) {
        if (this.props.user.countries.length === 1) {
          this.setState({ selectedCountryObj: _.get(this.props, 'user.countries[0]', {}) })
        }
      } else {
        this.props.setSelectedCountryData(this.state.selectedCountryObj)
      }
      this.props.setglobalLoaderStatus(false)
    } else {
      pushToAnalytics('Login - Page Load', {
        'Country ID': selectedObj.CountryID,
        'Country Code': selectedObj.CountryCode,
      })
      this.props.setSelectedCountryData({
        ...selectedObj,
        label: '+ ' + selectedObj.PhoneCode,
        value: selectedObj.PhoneCode,
      })
      this.setState({ selectedCountryObj: selectedObj })
      _.get(this.props, 'user.countries', []).length === 1 && this.toggleModal('showCountryModal', false)
      this.callAPIs()
    }
  }

  toggleModal = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  handleCountrySelection = (selectedCountry) => {
    let configDetails = this.props.user
    if (selectedCountry.CountryID !== configDetails.selectedCountryData.CountryID) {
      // call appConfig only when selected country has changed
      this.setState({
        selectedCountryObj: { ...selectedCountry, value: selectedCountry.CountryName },
      })
      this.props.setSelectedCountryData(selectedCountry)
    }
  }

  handleCountryClick = async () => {
    pushToAnalytics('Login - Page Load', {
      'Country ID': this.state.selectedCountryObj.CountryID,
      'Country Code': this.state.selectedCountryObj.CountryCode,
    })
    this.props.setSelectedCountryData(this.state.selectedCountryObj)
    const fallback = 'en'
    let defaultLanguage = _.get(this.state.selectedCountryObj, 'countryConfig.LanguageCode.DEFAULT', fallback).toLowerCase()
    localStorage.setItem('languageCode', defaultLanguage)
    this.toggleModal('showCountryModal', false)
    this.callAPIs()
  }

  callAPIs = async () => {
    let selectedObj = this.props.user.selectedCountryData
    let defCountry = this.props.user.countries[0]
    let cc = _.get(this, 'props.user.selectedCountryData.CountryCode', 'other').toLowerCase()
    if (!legalConfigCodes[cc]) cc = 'other'

    this.setState(
      {
        loginLinks: {
          termsACondLink: this.handleRedirection('tnc', '/terms-conditions'),
          privacyLink: this.handleRedirection('privacy', '/privacy-policy'),
          dataLink: this.handleRedirection('data', '/online-consent'),
        },
        configFetched: false,
        phnoLengthAr:
          selectedObj !== null && typeof selectedObj === 'object' && Object.keys(selectedObj).length > 0
            ? _.get(selectedObj, 'countryConfig.MobileNumber.LENGTH', [10])
            : _.get(defCountry, 'countryConfig.MobileNumber.LENGTH', [10]),
        legalConsent: getLegalConsents(_.get(this, 'props.user.appLegalConfigs.LegalConfigs', []), [legalConfigCodes[cc].tnc]),
        accountType: _.get(this.props, 'user.appConfigDetails.EnableEmailLogin', false) ? 'email' : 'phone',
      },
      async () => {
        const { phone, email, primary_identifier } = this.state.prefilledData
        this.props.setSelectedCountryData(this.state.selectedCountryObj)
        const defaultLanguage = _.get(this, 'state.selectedCountryObj.countryConfig.LanguageCode.DEFAULT', 'en').toLowerCase()
        localStorage.setItem('languageCode', defaultLanguage)
        window.isDirectionRTL = ['ar', 'AR'].indexOf(localStorage.getItem('languageCode')) !== -1
        window.isDirectionRTL
          ? document.querySelector('html').setAttribute('dir', 'rtl')
          : document.querySelector('html').setAttribute('dir', 'ltr')
        await this.props.getTranslationTextAsync()
        await this.fetchLoginPageDetails()
        // Set Prefilled Data
        const loginInput = primary_identifier ? (primary_identifier === 'EMAIL_ID' ? email : phone) : email || phone
        loginInput && this.handleEmailOrPhoneLogin(null, loginInput)

        setTimeout(() => {
          this.props.setglobalLoaderStatus(false)
        }, 1000)
      }
    )
    window.onpopstate = () => {
      window.location.reload()
    }
  }

  setLayoutMaxWidth = (width) => {
    let ele = document.getElementsByClassName('page-layout__viewport')[0]
    if (ele) {
      ele.style.maxWidth = width
    }
  }

  setPrefilledUserDetails = () => {
    this.setState({
      prefilledData: this.props.location.query,
    })
  }

  componentWillUnmount() {
    this.setLayoutMaxWidth('1500px')
    window.removeEventListener('click', this.handleOutsideClick)
    // clear timer related intervals
    clearInterval(window.frequentCallsFunc)
    clearInterval(window.otpViaCallFunc)
    clearInterval(window.sendOTPCounterFunc)
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  }

  fetchLoginPageDetails = async () => {
    this.props.setglobalLoaderStatus(true)
    const response = await this.props.getLoginPageDetails()
    const { data: { data: loginPageDetailsData = {} } = {} } = response
    const loginPageDetails = {
      ...this.state.loginPageDetails,
      ...loginPageDetailsData,
    }
    const newState = {
      ...this.state,
      loginPageDetails,
    }
    this.setState(newState)
    this.props
      .loadAppConfig()
      .then(() => {
        this.props
          .getAppLegalConfigs()
          .then(() => {
            let cc = _.get(this, 'props.user.selectedCountryData.CountryCode', 'other').toLowerCase()
            if (!legalConfigCodes[cc]) cc = 'other'
            this.setState(
              {
                configFetched: true,
                loginLinks: {
                  termsACondLink: this.handleRedirection('tnc', '/terms-conditions'),
                  privacyLink: this.handleRedirection('privacy', '/privacy-policy'),
                  dataLink: this.handleRedirection('data', '/online-consent'),
                },
                legalConsent: getLegalConsents(_.get(this, 'props.user.appLegalConfigs.LegalConfigs', []), [legalConfigCodes[cc].tnc]),
                termsAndCondCheck: !legalConfigCodes[cc].tnc,
              },
              () => {
                if (legalConfigCodes[cc].tnc && !(this.state.legalConsent.length && this.state.legalConsent[0].legalConfig.Text))
                  this.props.setToaster(getTranslationText('common', 'general_failure'), 'error')
              }
            )
            const languageCode =
              localStorage.getItem('languageCode') || _.get(this, 'props.user.selectedCountryData.countryConfig.LanguageCode.DEFAULT', 'en')
            const loginBackgroundImage = _.get(this, `props.user.appConfigDetails.LoginBackgroundImages.${languageCode.toLowerCase()}`)
            if (loginBackgroundImage) {
              document.documentElement.style.setProperty('--LoginBackImg', `url('${loginBackgroundImage}')`)
            } else {
              const fallBackUrl = window.getComputedStyle(document.documentElement).getPropertyValue('--fallbackLoginBG')
              document.documentElement.style.setProperty('--LoginBackImg', fallBackUrl)
            }
            this.props.setglobalLoaderStatus(false)
          })
          .catch((err) => {
            console.log(err)
            this.props.setglobalLoaderStatus(false)
          })
      })
      .catch((err) => {
        console.log(err)
        this.props.setglobalLoaderStatus(false)
      })
  }

  handleCountryChange = (value) => {
    let configDetails = this.props.user
    this.setState({
      showPhoneCodeOptions: false,
    })
    if (value.CountryID !== configDetails.selectedCountryData.CountryID) {
      // call appConfig only when selected country has changed
      this.setState({
        selectedCountryObj: value,
        loginPageDetails: this.defaultLoginPageDetails,
      })
      this.props.setSelectedCountryData(value)
      let defaultLanguage = _.get(value, 'countryConfig.LanguageCode.DEFAULT', 'en').toLowerCase()
      localStorage.setItem('languageCode', defaultLanguage)
      this.props.setglobalLoaderStatus(true)
      let promise = []
      promise[0] = this.props.getTranslationTextAsync()
      promise[1] = this.props.loadAppConfig()
      promise[2] = this.props.getAppLegalConfigs()

      Promise.all(promise).then(async () => {
        this.setState({
          Phno: '',
          phnoLengthAr: _.get(value, 'countryConfig.MobileNumber.LENGTH', [10]),
        })
        await this.fetchLoginPageDetails()
        this.props.setglobalLoaderStatus(false)
      })
    }
  }

  onFetchingOtp =
    ({ viaResendOptn, requestObj }) =>
    (data) => {
      // @todo remove below default value when the API goes live for the same
      const showReCaptchaDefault = false
      const allowResendDefault = true
      const {
        data: { sendCaptcha: showReCaptcha = showReCaptchaDefault, allowResend = allowResendDefault },
      } = data
      const disableResendOtp = false
      this.setState({ allowResend, showResendOTPbtn: false, showCallOptnBtn: false })
      if (viaResendOptn) {
        // resend option set state
        if (requestObj.voiceOtp) {
          // for resend via call
          this.setState(
            {
              showCallTimer: true,
            },
            () => {
              window.otpViaCallFunc = setInterval(() => {
                if (this.state.otpViaCallTimer === 0) {
                  clearInterval(window.otpViaCallFunc)
                  this.setState({
                    otpViaCallTimer: 60,
                    showCallOptnBtn: true,
                    disableResendOtp,
                    showCallTimer: false,
                  })
                } else {
                  this.setState({
                    showCallOptnBtn: false,
                    otpViaCallTimer: this.state.otpViaCallTimer - 1,
                  })
                }
              }, 1000)
            }
          )
        } else {
          // for resend via msg
          this.setState(
            {
              showOTPTimer: true,
            },
            () => {
              window.frequentCallsFunc = setInterval(() => {
                if (this.state.frequentCalls === 1) {
                  clearInterval(window.frequentCallsFunc)
                  this.setState({
                    frequentCalls: 60,
                    showResendOTPbtn: true,
                    disableResendOtp,
                    showOTPTimer: false,
                  })
                } else {
                  this.setState({
                    showResendOTPbtn: false,
                    frequentCalls: this.state.frequentCalls - 1,
                  })
                }
              }, 1000)
            }
          )
        }
      } else {
        this.setState(
          {
            showReCaptcha,
            BtnLoaderStatus: false,
            otpFetched: true,
            sendOTPCounter: 30,
            loginPageId: 'otp_page',
            setFocusOTP: true,
          },
          () => {
            window.sendOTPCounterFunc =
              allowResend &&
              setInterval(() => {
                // timer functions to display counters for enabling re-send options
                if (this.state.sendOTPCounter === 1) {
                  clearInterval(window.sendOTPCounterFunc)
                  this.setState({
                    sendOTPCounter: 0,
                    showResendOTPbtn: true,
                    showCallOptnBtn: true,
                    disableResendOtp: false,
                  })
                } else {
                  this.setState({
                    sendOTPCounter: this.state.sendOTPCounter - 1,
                  })
                }
              }, 1000)
          }
        )
      }
    }

  onFetchingOtpFailure = () => this.setState({ BtnLoaderStatus: false, disableResendOtp: false })

  fetchOTPfunc = (request, viaResendOptn = true) => {
    const requestObj = { ...request }
    // requestObj.ResendOTPRequest = !!this.state.ResendOTPRequest
    const onFetchingOtp = this.onFetchingOtp({ viaResendOptn, requestObj })
    this.props
      .generateOTPAsync(
        requestObj,
        _.get(this.props, 'user.appConfigDetails.EnableEmailLogin', false) || this.state.accountType === 'email'
      )
      .then(onFetchingOtp)
      .catch(this.onFetchingOtpFailure)
  }

  validatePhno = () => {
    if (this.state.termsAndCondCheck) {
      if (regexCheck('validation', 'numeric', this.state.Phno, this.state.phnoLengthAr)) {
        this.setState(
          {
            BtnLoaderStatus: true,
            accountType: 'phone',
          },
          async () => {
            const request = {
              mobileNo: this.state.Phno,
            }
            if (_.get(this, 'props.user.appConfigDetails.sendCaptcha', false)) {
              const captcha = await getCaptcha()
              this.fetchOTPfunc(
                {
                  ...request,
                  captcha,
                },
                false
              )
            } else {
              this.fetchOTPfunc(request, false)
            }
            pushToAnalytics('Login - Continue', {
              'Mobile Number': request.mobileNo,
            })
          }
        )
      } else {
        this.props.setToaster(getTranslationText('error', 'invalidMobile'), 'error')
        this.setState({
          Phno: '',
        })
      }
    }
  }

  handleEmailLogin = () => {
    if (this.state.termsAndCondCheck) {
      if (regexCheck('validation', 'emailId', this.state.EmailID)) {
        this.setState(
          {
            BtnLoaderStatus: true,
            accountType: 'email',
          },
          async () => {
            const request = {
              emailID: this.state.EmailID,
            }
            if (_.get(this, 'props.user.appConfigDetails.sendCaptcha')) {
              const captcha = await getCaptcha()
              this.fetchOTPfunc(
                {
                  ...request,
                  captcha,
                },
                false
              )
            } else {
              this.fetchOTPfunc(request, false)
            }
            pushToAnalytics('Login - Continue', {
              'Email ID': request.emailID || '',
            })
          }
        )
      } else {
        this.props.setToaster(getTranslationText('error', 'invalidEmailID'), 'error')
        this.setState({
          EmailID: '',
        })
      }
    }
  }

  handleRedirection = (type, customLink) => {
    let skipRedirection = _.get(this, 'props.user.appConfigDetails.SkipRedirection', false)
    let link = customLink
    if (type === 'tnc') {
      link = this.props.user.appConfigDetails.Terms
      skipRedirection && this.props.setTncLink(link)
    } else if (type === 'privacy') {
      link = this.props.user.appConfigDetails.Privacy
      skipRedirection && this.props.setPrivacyLink(link)
    } else if (type === 'data') {
      link = this.props.user.appConfigDetails.ConsentURL
      skipRedirection && this.props.setDataConsentLink(link)
    }
    if (skipRedirection) {
      link = customLink
    }
    return link
  }

  checkFormat = (value) => {
    if (this.state.phnoLengthAr.includes(value.length) && !isNaN(value)) {
      this.setState(
        {
          invalidFormat: isValidMobileNumber(value, this.state.selectedCountryObj.CountryCode),
        },
        () => {
          this.state.invalidFormat && this.props.setToaster(getTranslationText('error', 'invalidMobile'), 'error')
        }
      )
    }
    return true
  }

  handleEmailOrPhoneLogin = (e, prefilledInput) => {
    const inputText = (prefilledInput || e?.target?.value || '').toString().toLowerCase()
    const accountType = regexCheck('onChange', 'numeric', inputText) && inputText ? 'phone' : 'email'
    const inputState = accountType === 'email' ? 'EmailID' : 'Phno'

    // Set type of input to show phone code drodpdown
    this.setState({
      accountType,
    })

    // Validate and set input value
    if (
      (this.checkFormat(inputText) && regexCheck('onChange', 'numeric', inputText, Math.max(...this.state.phnoLengthAr))) ||
      regexCheck('onChange', 'emailId', inputText)
    ) {
      this.setState({
        accountInput: inputText,
        [inputState]: inputText,
      })
    }
  }

  handleContactSelection = (selectedContact = {}) => {
    this.setState({
      selectedContact,
    })
  }

  handleConfirmContact = () => {
    if (!_.isEmpty(_.get(this.state, 'selectedContact'))) {
      this.setState(
        {
          BtnLoaderStatus: true,
        },
        async () => {
          const request = {
            customerReferenceID: _.get(this.state, 'selectedContact.customerReferenceID'),
          }
          if (_.get(this, 'props.user.appConfigDetails.sendCaptcha')) {
            const captcha = await getCaptcha()
            this.fetchOTPfunc(
              {
                ...request,
                captcha,
              },
              false
            )
          } else {
            this.fetchOTPfunc(request, false)
          }
        }
      )
    } else {
      this.props.setToaster(getTranslationText('error', 'somethingWentWrong'), 'error')
    }
  }

  handlePhoneCodeOptions = () => {
    this.setState((prevState) => ({
      showPhoneCodeOptions: !prevState.showPhoneCodeOptions,
    }))
  }

  handlePhNoInputChange = (e) => {
    if (e.which === 13) {
      !this.state.invalidFormat && e.target.value && this.state.phnoLengthAr.includes(e.target.value.length) && this.validatePhno()
    } else {
      if (this.checkFormat(e.target.value) && regexCheck('onChange', 'numeric', e.target.value, Math.max(...this.state.phnoLengthAr))) {
        this.setState({
          Phno: e.target.value,
        })
      }
    }
  }

  handleEmailInputChange = (e) => {
    const inputText = e.target.value.toString().toLowerCase()
    if (e.which === 13) {
      inputText && regexCheck('validation', 'emailId', inputText) && this.handleEmailLogin()
    } else {
      if (regexCheck('onChange', 'emailId', inputText)) {
        this.setState({
          EmailID: inputText,
        })
        if (regexCheck('validation', 'emailId', this.state.EmailID)) {
          this.setState({
            EmailID: inputText,
          })
        }
      }
    }
  }

  // OTP funcs
  handleOnMultiOTPChange = (value) => {
    this.setState({
      otpValue: value,
    })
  }

  checkOTP = () => {
    const { BtnLoaderStatus, otpValue } = this.state
    if (BtnLoaderStatus || !otpValue) {
      return
    }
    let getLink = (link) => {
      if (link === 'tnc') return _.get(this.props, 'user.tncLink', this.state.loginLinks.termsACondLink)
      if (link === 'privacy') return _.get(this.props, 'user.privacyLink', this.state.loginLinks.privacyLink)
      if (link === 'dataconsent') return _.get(this.props, 'user.dataLink', this.state.loginLinks.dataLink)
    }
    this.setState(
      {
        BtnLoaderStatus: true,
      },
      async () => {
        const { showReCaptcha, Phno: mobileNo, otpValue: OTP, EmailID: emailID, accountType } = this.state
        const customerReferenceID = _.get(this.state, 'selectedContact.customerReferenceID')
        let request = { OTP }
        const isEmailLogin = _.get(this.props, 'user.appConfigDetails.EnableEmailLogin', false) || accountType === 'email'
        // For reverification of linked contact flow pass the customer reference ID os selected contact to recieve OTP
        if (customerReferenceID) {
          request = {
            ...request,
            customerReferenceID,
          }
        } else {
          request = isEmailLogin
            ? { ...request, emailID }
            : { ...request, mobileNo, phoneCode: `+${_.get(this.props, 'user.selectedCountryData.PhoneCode')}` }
        }

        if (this.state.legalConsent.length && this.state.legalConsent[0].legalConfig.Text) {
          let attributes = [{ attributeType: legalConfigAttributes.otp, attributeValue: request.OTP }]
          if (isEmailLogin) {
            attributes.push({ attributeType: legalConfigAttributes.email, attributeValue: request.emailID })
          }
          let phs = getPlaceholders(this.state.legalConsent[0].legalConfig.Text)
          phs.length &&
            phs.map((ph) => {
              attributes.push({ attributeType: legalConfigAttributes[ph], attributeValue: getLink(ph) })
            })
          request.consentArray = [
            {
              applicationLegalConfigID: this.state.legalConsent[0].ApplicationLegalConfigID,
              ...(request.mobileNo ? { mobileNo: request.mobileNo } : {}),
              consentText: replacePlaceholders(this.state.legalConsent[0].legalConfig.Text),
              attributes: attributes,
            },
          ]
        }
        if (showReCaptcha) {
          request.captcha = await getCaptcha()
          this.verifyOTP(request)
        } else {
          this.verifyOTP(request)
        }
      }
    )
  }

  redirectToLogin = () => {
    this.setState({
      BtnLoaderStatus: false,
    })
    browserHistory.replace('/')
  }

  handleOtpVerification = ({ sendCaptcha: showReCaptcha, message, isLoginSuccess, existingContact, ...data }) => {
    pushToAnalytics('Login - OTP Verified', {
      'Mobile Number': this.state.mobileNo || '',
      'Email ID': this.state.EmailID || '',
      ConsumerID: (data && data.consumerID) || undefined,
    })
    const BtnLoaderStatus = false
    const selectedContact = ''
    // If has multiple accounts linked go to account selection page
    if (data.hasMultipleContacts) {
      this.setState({
        loginPageId: 'select_account_page',
        existingContact,
        BtnLoaderStatus,
        selectedContact,
      })
      return
    }
    if (isLoginSuccess && data.consumer) {
      const isNewUser = data.isNew || !data.profileComplete
      if (isNewUser) {
        this.props.loadAppConfig().then(() => {
          this.props.getAppLegalConfigs().then(() => {
            this.setState({ loginPageId: 'new_usr', BtnLoaderStatus, selectedContact })
          })
        })
        return
      }
      this.setState({ BtnLoaderStatus })
      this.redirectionLink()

      return
    }

    const loginPageId = 'otp_page'
    const otpValue = ''
    const resetState = {
      loginPageId,
      showReCaptcha,
      otpValue,
      BtnLoaderStatus,
      allowResend: data.allowResend,
    }
    this.setState(resetState)
    this.props.setToaster(message, 'error')
  }

  verifyOTP = (reqBody) => {
    this.props.verifyOTPAsync(reqBody, { allowPartialSuccess: true }).then(this.handleOtpVerification).catch(this.redirectToLogin)
  }

  ResendOtpHandler = (type) => {
    let resendType = type
    this.setState(
      {
        otpValue: '',
        ResendOTPRequest: true,
        disableResendOtp: true,
      },
      async () => {
        let isEmailLogin = _.get(this, 'props.user.appConfigDetails.EnableEmailLogin') || this.state.accountType === 'email'
        const customerReferenceID = _.get(this.state, 'selectedContact.customerReferenceID')
        let reqPayload = customerReferenceID
          ? { customerReferenceID }
          : isEmailLogin
          ? { emailID: this.state.EmailID }
          : { mobileNo: this.state.Phno }

        if (resendType === 'call') {
          reqPayload.voiceOtp = true
        }
        const hander = (req) => this.fetchOTPfunc(req)
        if (_.get(this, 'props.user.appConfigDetails.sendCaptcha')) {
          reqPayload.captcha = await getCaptcha()
          hander(reqPayload)
        } else {
          if (this.state.otpViaCallTimer === 60 || this.state.frequentCalls === 60) {
            hander(reqPayload)
          }
        }
      }
    )
  }

  validateContactDetails = () => {
    let isEmailLogin = _.get(this, 'props.user.appConfigDetails.EnableEmailLogin') || this.state.accountType === 'email'
    let userContactDetails
    let callApiStatus
    if (isEmailLogin) {
      callApiStatus =
        regexCheck('validation', 'userName', this.state.UsrName) &&
        (this.state.UsrMobileNo.length === 0 || regexCheck('validation', 'numeric', this.state.UsrMobileNo, this.state.phnoLengthAr))
      userContactDetails = {
        'Mobile Number': this.state.UsrMobileNo,
        Name: this.state.UsrName,
        Email: this.state.EmailID,
      }
    } else {
      callApiStatus = regexCheck('validation', 'userName', this.state.UsrName) && regexCheck('validation', 'emailId', this.state.UsrEmailid)
      userContactDetails = {
        'Mobile Number': this.state.Phno,
        Name: this.state.UsrName,
        Email: this.state.UsrEmailid,
        ConsumerID: _.get(this.props, 'user.consumerDetails.data.consumerID', undefined),
      }
    }
    // ------------- CleverTap | User Registration --------/
    userContactDetails.ConsumerID = _.get(this.props, 'user.consumerDetails.data.consumerID', '')
    pushToAnalytics('User Registration', userContactDetails)
    // ------------- CleverTap | User Registration --------/
    if (callApiStatus) {
      // let date = new Date()
      this.props.setglobalLoaderStatus(true)
      const updateObj = isEmailLogin
        ? {
            ...(this.state.UsrMobileNo ? { mobileNo: this.state.UsrMobileNo, countryCode: '+' + window.PhoneCode } : {}),
            name: this.state.UsrName,
          }
        : { ...(this.state.UsrEmailid ? { emailID: this.state.UsrEmailid } : {}), name: this.state.UsrName }
      this.props
        .updateUserDetailsAsync({
          AuthID: this.props.user.consumerDetails.data.accessToken.ID,
          data: {
            consumerID: _.get(this.props, 'user.consumerDetails.data.consumerID'),
            updateObj,
          },
        })
        .then(() => {
          const clevertapUserProfile = _.get(this, 'props.user', {})
          // ------------- CleverTap | User Registration --------/
          pushToAnalytics('User Registration Confirmation', userContactDetails, clevertapUserProfile)
          // ------------- CleverTap | User Registration --------/
          this.props.setglobalLoaderStatus(false)
          this.props.loadAppConfig().then(() => {
            this.props.getAppLegalConfigs().then(() => {
              this.redirectionLink()
            })
          })
        })
        .catch(() => {
          this.props.setglobalLoaderStatus(false)
        })
    }
  }

  redirectionLink = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const redirectionPath = urlParams.get('redirectionPath')
    const cprefid = urlParams.get('cprefid') || ''
    const refid = urlParams.get('refid')
    if (Object.keys(_.get(this, 'props.location.lastpathName', {})).length) {
      browserHistory.push(
        {
          pathname: this.props.location.lastpathName.path,
          query: this.props.location.lastpathName.params,
        },
        () => {
          this.props.storeLastKnowPathName({})
        }
      )
    } else {
      if (redirectionPath) {
        if (redirectionPath == 'trackRequest') {
          browserHistory.push(`/track?refid=${refid}`)
        } else if (redirectionPath == 'myDevices' && cprefid) {
          browserHistory.push(`/devices?cpid=${cprefid}`)
        } else if (redirectionPath == 'myDevices') {
          browserHistory.push(`/devices`)
        } else if (redirectionPath == 'planCoverage') {
          browserHistory.push(`/devices?action=repair&cpid=${cprefid}`)
        } else {
          browserHistory.push('/')
        }
      } else {
        browserHistory.push('/')
      }
    }
  }

  handleUserInputs = (e) => {
    if (e.which === 13) {
      this.validateContactDetails()
    } else {
      if (e.target.id === 'MobileNo') {
        if (this.checkFormat(e.target.value) && regexCheck('onChange', 'numeric', e.target.value, Math.max(...this.state.phnoLengthAr))) {
          this.setState({
            UsrMobileNo: e.target.value,
          })
        }
      } else {
        if (e.target.type === 'text') {
          if (regexCheck('onChange', 'userName', e.target.value, 40)) {
            this.setState({
              UsrName: e.target.value,
            })
          }
        } else if (regexCheck('onChange', 'emailId', e.target.value, 40)) {
          this.setState({
            UsrEmailid: e.target.value,
          })
        }
      }
    }
  }

  handleTermsAndCondClick = () => {
    this.setState({
      termsAndCondCheck: !this.state.termsAndCondCheck,
    })
  }

  customLoginRedirection = async () => {
    let callbackURL = this.props.user.loginRedirectionURL
    const randomString = generateRandomString(10)
    if (_.get(this.props, 'user.appConfigDetails.callbackURL', false)) {
      callbackURL = _.get(this.props, 'user.appConfigDetails.callbackURL') + `state=${randomString}`
    }
    localStorage.setItem('log_consents', 1)
    await this.props.setIsSSOFlow(true)
    window.location.replace(callbackURL)
  }

  captchaErrCallBack = () => {
    let state = {
      BtnLoaderStatus: false,
    }
    if (this.state.disableResendOtp) {
      state = {
        ...state,
        ResendOTPRequest: false,
        disableResendOtp: false,
      }
    }
    this.setState(state)
  }

  handleLoginWithToken = () => {
    const { BtnLoaderStatus } = this.state
    if (BtnLoaderStatus) {
      return
    }

    this.setState(
      {
        BtnLoaderStatus: true,
      },
      async () => {
        const { showReCaptcha, Phno: mobileNo, EmailID: emailID, accountType } = this.state

        const isEmailLogin = _.get(this.props, 'user.appConfigDetails.EnableEmailLogin', false) || accountType === 'email'
        let request = isEmailLogin ? { emailID } : { mobileNo, phoneCode: `+${_.get(this.props, 'user.selectedCountryData.PhoneCode')}` }

        if (showReCaptcha) {
          request.captcha = await getCaptcha()
          this.props.verifyTokenAsync(request, { allowPartialSuccess: true }).then(this.handleOtpVerification).catch(this.redirectToLogin)
        } else {
          this.props.verifyTokenAsync(request, { allowPartialSuccess: true }).then(this.handleOtpVerification).catch(this.redirectToLogin)
        }
      }
    )
  }

  render() {
    let legalTxt = _.get(this, 'props.user.appConfigDetails.footerLinks.footerText')
    const {
      state: {
        loginPageDetails: { disclaimer, ...otherLoginPageDetails },
      },
      props: { languageCode },
    } = this
    if (languageCode !== 'en' && disclaimer) {
      legalTxt = disclaimer
    }
    let poweredByText = _.get(this, 'props.user.appConfigDetails.footerLinks.poweredByText', getTranslationText('login', 'powered'))
    let renderDiv
    let displayCaptcha = _.get(this, 'props.user.appConfigDetails.sendCaptcha') || this.state.showReCaptcha
    let isEmailLogin = _.get(this, 'props.user.appConfigDetails.EnableEmailLogin') || this.state.accountType === 'email'
    let isMobileNoMandatory = _.get(this, 'props.user.appConfigDetails.mobileNoMandatory')
    if (this.state.loginPageId === 'new_usr') {
      renderDiv = (
        <LoginContactDetails
          handleUserInputs={this.handleUserInputs}
          UsrEmailid={this.state.UsrEmailid}
          UsrMobileNo={this.state.UsrMobileNo}
          validateContactDetails={this.validateContactDetails}
          phnoLengthAr={this.state.phnoLengthAr}
          UsrName={this.state.UsrName}
          BtnLoaderStatus={this.state.BtnLoaderStatus}
          isEmailLogin={isEmailLogin}
          selectedCountryObj={this.state.selectedCountryObj}
          handleCountryChange={this.handleCountryChange}
          invalidFormat={this.state.invalidFormat}
          isMobileNoMandatory={isMobileNoMandatory}
          {...this.props}
        />
      )
      displayCaptcha = false
    } else if (this.state.loginPageId === 'otp_page') {
      renderDiv = (
        <LoginOTP
          handleOnMultiOTPChange={this.handleOnMultiOTPChange}
          checkOTP={this.checkOTP}
          sendOTPCounter={this.state.sendOTPCounter}
          otpValue={this.state.otpValue}
          handleLoginRedirection={this.handleLoginRedirection}
          {...this.props}
          frequentCalls={this.state.frequentCalls}
          showOTPTimer={this.state.showOTPTimer}
          showResendOTPbtn={this.state.showResendOTPbtn}
          showCallOptnBtn={this.state.showCallOptnBtn}
          otpViaCallTimer={this.state.otpViaCallTimer}
          showCallTimer={this.state.showCallTimer}
          disableResendOtp={this.state.disableResendOtp}
          BtnLoaderStatus={this.state.BtnLoaderStatus}
          Phno={this.state.Phno}
          EmailID={this.state.EmailID}
          accountInput={this.state.accountInput}
          accountType={this.state.accountType}
          user={this.props.user}
          allowResend={this.state.allowResend}
          ResendOtpHandler={this.ResendOtpHandler}
          loginPageDetails={otherLoginPageDetails}
          selectedContact={this.state.selectedContact}
        />
      )
    } else if (this.state.loginPageId === 'select_account_page') {
      renderDiv = (
        <AccountSelect
          isEmailLogin={_.get(this.props, 'user.appConfigDetails.EnableEmailLogin', false)}
          accountInput={this.state.accountInput}
          accountType={this.state.accountType}
          user={this.props.user}
          selectedAccount={this.state.selectedAccount}
          selectedCountryData={_.get(this.props, 'user.selectedCountryData')}
          handleContactSelection={this.handleContactSelection}
          selectedContact={this.state.selectedContact}
          handleConfirmContact={this.handleConfirmContact}
          existingContact={this.state.existingContact}
          BtnLoaderStatus={this.state.BtnLoaderStatus}
          Phno={this.state.Phno}
          EmailID={this.state.EmailID}
          handleLoginWithToken={this.handleLoginWithToken}
        />
      )
    } else {
      renderDiv = (
        <LoginPhNo
          handlePhNoInputChange={this.handlePhNoInputChange}
          validatePhno={this.validatePhno}
          Phno={this.state.Phno}
          accountInput={this.state.accountInput}
          accountType={this.state.accountType}
          handlePhoneCodeOptions={this.handlePhoneCodeOptions}
          showPhoneCodeOptions={this.state.showPhoneCodeOptions}
          phnoLengthAr={this.state.phnoLengthAr}
          EmailID={this.state.EmailID}
          handleEmailInputChange={this.handleEmailInputChange}
          handleEmailLogin={this.handleEmailLogin}
          selectedCountryObj={this.state.selectedCountryObj}
          handleCountryChange={this.handleCountryChange}
          termsAndCondCheck={this.state.termsAndCondCheck}
          handleTermsAndCondClick={this.handleTermsAndCondClick}
          handleEmailOrPhoneLogin={this.handleEmailOrPhoneLogin}
          dropdownOptionsRef={this.dropdownOptionsRef}
          phonecodeDropdown={this.phonecodeDropdown}
          {...this.props}
          BtnLoaderStatus={this.state.BtnLoaderStatus}
          loginLinks={this.state.loginLinks}
          customLoginRedirection={this.customLoginRedirection}
          invalidFormat={this.state.invalidFormat}
          afterLanguageChange={this.fetchLoginPageDetails}
          loginPageDetails={otherLoginPageDetails}
          handleRedirection={this.handleRedirection}
          legalConsent={this.state.legalConsent}
        />
      )
    }
    return (
      <div className='row login-index' dir={window.isDirectionRTL ? 'rtl' : 'ltr'}>
        {this.state.showCountryModal && (
          <Modal showModal closeModal={() => {}} className='sfy-modal login-country-modal'>
            <Modal.Header>
              <h2 className='mb20 text-center'>{getTranslationText('store', 'countryModalHeader')}</h2>
            </Modal.Header>
            <div className={'close-icon ' + (window.isDirectionRTL ? ' text-left ' : ' text-right')}></div>
            <div className='col-sm-12 mb8'>
              {this.props.user?.countries?.length === 1
                ? getTranslationText('store', 'countryModalText')
                : getTranslationText('store', 'countriesModalText')}
            </div>
            <div className='col-sm-12'>
              <Dropdown
                id='store-dropdown'
                handleOnChange={(item) => this.handleCountrySelection(item)}
                value={!_.isEmpty(this.state.selectedCountryObj) ? this.state.selectedCountryObj : ''}
                placeholder={getTranslationText('common', 'searchByText')}
                options={
                  this.props.user.appConfigDetails && _.get(this.props, 'user.countries', []).length
                    ? this.props.user.countries.map((countryObj) => ({
                        ...countryObj,
                        label: countryObj.CountryName,
                        value: countryObj.CountryName,
                      }))
                    : []
                }
                ValueComponent={!_.isEmpty(this.state.selectedCountryObj) && CountryValueComponent}
                OptionsComponent={CountryOptionComponent}
                filterBy='CountryName'
                searchText='Search'
                // isDisabled={this.props.user.countries.length === 1}
              />
            </div>
            <div className='col-sm-12'>
              <MediumButton
                className='button-small mt20'
                buttonText={getTranslationText('common', 'okay')}
                onClick={this.handleCountryClick}
              />
            </div>
          </Modal>
        )}
        {this.state.configFetched ? (
          <div className={'col-sm-12 login-container ' + (BrandSpecificConfig().ClientName === 'OnePlus' ? 'oneplusMaxWidth' : '')}>
            {renderDiv}
            {displayCaptcha ? <Captcha recaptcha={recaptcha} /> : ''}
            <div className='mt20 ml-10'>
              {legalTxt ? <span className='Note_Text bookFontStyle fontSmall' dangerouslySetInnerHTML={{ __html: legalTxt }} /> : ''}
              <div className='mt8'>
                {BrandSpecificConfig().showPServifyTxt && (
                  <span className='fontMedium Note_Text' dangerouslySetInnerHTML={{ __html: poweredByText }} />
                )}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
}

const CountryValueComponent = (props) => (
  <div className='country-value-container'>
    <img src={props.value.FlagImageURL} alt='' />
    <div className='country-label'>
      <span>{props.value.CountryName}</span>
    </div>
  </div>
)
CountryValueComponent.propTypes = {
  value: PropTypes.object.isRequired,
}

const CountryOptionComponent = (props) => (
  <div className='country-value-container'>
    <img src={props.option.FlagImageURL} alt='' />
    <div className='country-label'>{props.option.CountryName}</div>
  </div>
)
CountryOptionComponent.propTypes = {
  option: PropTypes.object.isRequired,
}

export default Login

Login.propTypes = {
  generateOTPAsync: PropTypes.func.isRequired,
  verifyOTPAsync: PropTypes.func.isRequired,
  updateUserDetailsAsync: PropTypes.func.isRequired,
  setToaster: PropTypes.func.isRequired,
  getUserDetailsAsync: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  loadAppConfig: PropTypes.func.isRequired,
  getAppLegalConfigs: PropTypes.func.isRequired,
  logConsentsAsync: PropTypes.func.isRequired,
  storeLastKnowPathName: PropTypes.func.isRequired,
  setSelectedCountryData: PropTypes.func.isRequired,
  getAppConfig: PropTypes.func,
  setglobalLoaderStatus: PropTypes.func.isRequired,
  generateRedirectionUrl: PropTypes.func.isRequired,
  setTncLink: PropTypes.func.isRequired,
  setPrivacyLink: PropTypes.func.isRequired,
  setDataConsentLink: PropTypes.func.isRequired,
  getTranslationTextAsync: PropTypes.func.isRequired,
  clearConsumerDetails: PropTypes.func.isRequired,
  languageCode: PropTypes.string,
  getLoginPageDetails: PropTypes.func.isRequired,
}
