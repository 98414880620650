import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import uuid from 'node-uuid'
import './Profile.scss'
import { Loader, ImageViewerModal, HomeDeviceOverView, ConfirmationPopUp } from '../../../components/UIComponents'
import { MainDetailsComponent, AddressesComponent, UploadDocumentComponent, ProfileSettingsComponent } from './ProfileSubComponents'
import { regexCheck } from '../../../utils/regex'
import { canUpload, isValidMobileNumber } from '../../../utils/validators'
import _ from 'lodash'
import { getTranslationText } from '../../../utils/languageTranslation'
import pushToAnalytics from '../../../utils/Analytics'
import { docServiceModuleName, fallbackAddressConfig } from '../../../utils/constants'
import { getDocServicePayload } from '../../../utils/functions'
import { postRequestAsync } from '../../../api/genericAPIs'

const defHomeIcon = '/commonAssets/DefAddressTypeBtnIcons/Home.png'
const defOfficeIcon = '/commonAssets/DefAddressTypeBtnIcons/Office.png'
const defOtherIcon = '/commonAssets/DefAddressTypeBtnIcons/Other.png'
const activeHomeIcon = `/${appName}/images/AddressTypeBtnIcons/Home.png`
const activeOfficeIcon = `/${appName}/images/AddressTypeBtnIcons/Office.png`
const activeOtherIcon = `/${appName}/images/AddressTypeBtnIcons/Other.png`

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditingMainDetails: false,
      landmark: '',
      placesAutocompleteOptions: {},
      userDetails: {},
      locations: [],
      locationObtained: false,
      ConsumerDetails: {},
      userDetailsChanged: false,
      emailAlerts: false,
      notificationAlerts: false,
      mainDeviceDetailsLoader: true,
      addressComponentLoader: true,
      deviceDocsLoader: false,
      beginnerLvlloaderState: true,
      showDocsModal: false,
      addressTypesList: [
        {
          text: getTranslationText('AddressType', 'addressTypeHome'),
          isSelected: false,
          deactiveImg: defHomeIcon,
          activeImg: activeHomeIcon,
        },
        {
          text: getTranslationText('AddressType', 'addressTypeOffice'),
          isSelected: false,
          deactiveImg: defOfficeIcon,
          activeImg: activeOfficeIcon,
        },
        {
          text: getTranslationText('AddressType', 'addressTypeOther'),
          isSelected: false,
          deactiveImg: defOtherIcon,
          activeImg: activeOtherIcon,
        },
      ],
      deviceOverviewloaderState: true,
      deviceOverviewState: {
        noOfDevices: '00',
        noOfDevicesProtected: '00',
      },
      phnoLengthAr: [10],
      pincodeLengthAr: [6],
      selectedState: {},
      selectedDistrict: {},
      showDistrictSelection: true,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    let Customerid = this.props.user.userDetails.consumerID
    let Authid = this.props.user.consumerDetails.data.accessToken.ID
    this.props.setglobalLoaderStatus(true)

    this.props
      .getCountriesAsync()
      .then(() => {
        this.setState({
          addressComponentLoader: false,
          locations: this.props.user.locations,
        })
      })
      .catch(() => {
        this.setState({
          addressComponentLoader: false,
        })
      })

    this.props
      .getStateListAsync()
      .then(() => {
        this.setState({
          addressComponentLoader: false,
          locations: this.props.user.locations,
        })
      })
      .catch(() => {
        this.setState({
          addressComponentLoader: false,
        })
      })

    this.props
      .getUserDetailsAsync({
        consumerID: Customerid,
        accessToken: Authid,
      })
      .then(() => {
        this.setState({
          mainDeviceDetailsLoader: false,
          userDetails: this.props.user.userDetails,
        })
        this.props.setglobalLoaderStatus(false)
      })
      .catch(() => {
        this.setState({
          mainDeviceDetailsLoader: false,
        })
        this.props.setglobalLoaderStatus(false)
      })

    this.props
      .getUserAddressesAsync({
        ConsumerID: Customerid,
        AuthID: Authid,
      })
      .then(() => {
        this.setState({
          addressComponentLoader: false,
          locations: this.props.user.locations,
        })
      })
      .catch(() => {
        this.setState({
          addressComponentLoader: false,
        })
        this.props.setglobalLoaderStatus(false)
      })

    if (_.get(this, 'props.user.appConfigDetails.isShowProfileDocsUpload', true)) {
      this.props
        .getUserDocsAsync({
          ConsumerID: Customerid,
          AuthID: Authid,
        })
        .then(() => {
          // this.props.setglobalLoaderStatus(false)
        })
        .catch(() => {
          this.props.setglobalLoaderStatus(false)
        })
    }
    // this.props.getBrowserLocationAsync().then(() => {
    //   if (this.props.user.browserLocation) {
    //     let { lat, lng } = this.props.user.browserLocation.location
    //     this.setState({
    //       placesAutocompleteOptions: {
    //         location: new google.maps.LatLng(lat, lng),
    //         radius: 2000,
    //       },
    //     })
    //   }
    // })
    this.props
      .getDeviceOverviewAsync({
        ConsumerID: Customerid,
        AuthID: Authid,
      })
      .then((response) => {
        this.setState({
          deviceOverviewloaderState: false,
          deviceOverviewState: {
            noOfDevices: this.deviceOverviewCounts(response.NoOfDevices),
            noOfDevicesProtected: this.deviceOverviewCounts(response.NoOfDevicesProtected),
          },
        })
      })
      .catch(() => {
        this.setState({
          deviceOverviewloaderState: false,
        })
        this.props.setglobalLoaderStatus(false)
      })

    let selectedCtry = this.props.user.selectedCountryData
    if (!_.isEmpty(selectedCtry)) {
      this.setState({
        phnoLengthAr: _.get(selectedCtry, 'countryConfig.MobileNumber.LENGTH', [10]),
        pincodeLengthAr: _.get(selectedCtry, 'countryConfig.PinCode.LENGTH', [6]),
      })
    }
  }

  // to convert value from (0....9) to (00, 01, ..., 09)
  deviceOverviewCounts = (val) => {
    if (val && val.toString().length < 2) {
      return '0' + val.toString()
    }
    return (val && val.toString()) || '00'
  }

  toggleMainDetailsEdit = (e) => {
    if (!this.state.userDetailsChanged || e.currentTarget.id === 'cancel') {
      this.setState({
        isEditingMainDetails: !this.state.isEditingMainDetails,
        userDetails: this.props.user.userDetails,
      })
    } else {
      this.setState(
        {
          isEditingMainDetails: !this.state.isEditingMainDetails,
          userDetailsChanged: false,
          mainDeviceDetailsLoader: this.state.isEditingMainDetails,
        },
        () => {
          // Remove ISOCounrtyCodeand pass it to update api as null or empty string is not allowed
          const { title, emailID, name, mobileNo, alternateMobileNo } = this.state.userDetails
          // make api call
          if (!this.state.isEditingMainDetails) {
            this.props
              .updateUserDetailsAsync({
                AuthID: this.props.user.consumerDetails.data.accessToken.ID,
                data: {
                  consumerID: this.props.user.userDetails.consumerID,
                  updateObj: {
                    ...(emailID ? { emailID } : {}),
                    ...(name ? { name } : {}),
                    ...(mobileNo ? { mobileNo } : {}),
                    ...(alternateMobileNo ? { alternateMobileNo } : {}),
                    ...(title ? { title } : {}),
                  },
                },
              })
              .then((profileReponse) => {
                // ------------ Clever Tap | pushProfile | updating profile ------------
                const clevertapUserProfile = _.get(this, 'props.user', {})
                pushToAnalytics(
                  'pushProfile',
                  {
                    'Mobile Number': profileReponse.data.mobileNo,
                    Identity: '',
                    Name: profileReponse.data.name,
                    Email: profileReponse.data.emailID,
                    Photo: '',
                    CONSUMER_ID: profileReponse.data.consumerID,
                    ConsumerID: profileReponse.data.consumerID,
                  },
                  clevertapUserProfile
                )
                // ------------ Clever Tap | pushProfile ------------
                this.props.setToaster(getTranslationText('success', 'profileUpdated'), 'success')
                this.props
                  .getUserDetailsAsync({
                    consumerID: this.props.user.userDetails.consumerID,
                    accessToken: this.props.user.consumerDetails.data.accessToken.ID,
                  })
                  .then(() => {
                    this.setState({
                      userDetails: this.props.user.userDetails,
                      mainDeviceDetailsLoader: false,
                    })
                  })
              })
              .catch(() => {
                this.setState({
                  mainDeviceDetailsLoader: false,
                })
              })
          }
        }
      )
    }
  }

  handleMainDetailsChange = (e) => {
    let dataChangedFlg = false
    let value = e.target.value
    if (e.target.name === 'name') {
      if (regexCheck('onChange', 'userName', e.target.value)) {
        dataChangedFlg = true
      }
    } else if (e.target.name === 'mobileNo') {
      if (this.checkFormat(e.target.value) && regexCheck('onChange', 'numeric', e.target.value, Math.max(...this.state.phnoLengthAr))) {
        if (
          e.target.value.length > 0 &&
          (e.target.value === _.get(this.props, 'user.userDetails.alternateMobileNo') ||
            e.target.value === this.state.userDetails.alternateMobileNo)
        ) {
          this.props.setToaster(getTranslationText('error', 'MobileNumberFailed'), 'error')
        }
        dataChangedFlg = true
      }
    } else if (e.target.name === 'alternateMobileNo') {
      if (this.checkFormat(e.target.value) && regexCheck('onChange', 'numeric', e.target.value, Math.max(...this.state.phnoLengthAr))) {
        if (
          e.target.value.length > 0 &&
          (e.target.value === _.get(this.props, 'user.userDetails.mobileNo') || e.target.value === this.state.userDetails.mobileNo)
        ) {
          this.props.setToaster(getTranslationText('error', 'alternateMobileNumberFailed'), 'error')
        }
        dataChangedFlg = true
      }
    } else {
      if (/[A-Za-z0-9@._-]*/.test(value) && value.length <= 50) {
        value = e.target.value.replace(/\s/, '')
        dataChangedFlg = true
      }
    }

    if (dataChangedFlg) {
      this.setState({
        userDetails: {
          ...this.state.userDetails,
          [e.target.name]: value,
        },
        userDetailsChanged: true,
      })
    }
  }

  checkFormat = (value) => {
    if (this.state.phnoLengthAr.includes(value.length) && !isNaN(value)) {
      this.setState(
        {
          invalidFormat: isValidMobileNumber(value, _.get(this, 'props.user.selectedCountryData.CountryCode')),
        },
        () => {
          this.state.invalidFormat && this.props.setToaster(getTranslationText('error', 'invalidMobileNumber'), 'error')
        }
      )
    }
    return true
  }

  uploadConsumerDocToDS = (e) => {
    const documentId = e.target.name
    const file = e.target.files[0]
    const uniqueId = uuid.v4()
    const fileExtension = file?.name?.split('.')?.pop()
    const token = _.get(this, 'props.user.consumerDetails.data.accessToken.ID')
    this.setState({
      deviceDocsLoader: true,
    })
    canUpload(e, { allowedTypes: ['image', 'pdf'] })
      .then(async () => {
        let payload = getDocServicePayload(file, 'ConsumerDocument')
        const response = await postRequestAsync(
          'internal/document/upload',
          payload,
          { Authorization: token, module: docServiceModuleName },
          window.docServiceUri
        )
        await this.props.addConsumerDocToDSAsync({
          ConsumerID: this.props.user.userDetails.consumerID,
          documentId,
          fileType: file?.type,
          fileName: uniqueId + (fileExtension ? '.' + fileExtension : ''),
          ...response,
        })
        await this.props.getUserDocsAsync({
          ConsumerID: this.props.user.userDetails.consumerID,
          AuthID: this.props.user.consumerDetails.data.accessToken.ID,
        })
        this.setState({
          deviceDocsLoader: false,
        })
      })
      .catch((err) => {
        console.log(err)
        this.setState({
          deviceDocsLoader: false,
        })
      })
  }

  changeDoc = (e) => {
    let fileName = e.target.name
    let file = e.target.files[0]
    canUpload(e, { allowedTypes: ['image', 'pdf'] })
      .then(() => {
        let uniqueId = uuid.v1()
        this.setState(
          {
            deviceDocsLoader: true,
          },
          () => {
            this.props
              .getSignedUrlAsync(
                {
                  AuthID: this.props.user.consumerDetails.data.accessToken.ID,
                  data: {
                    Type: file.type,
                    Key: uniqueId,
                  },
                },
                file,
                fileName
              )
              .then(() =>
                this.props.addDocAsync({
                  ConsumerID: this.props.user.userDetails.consumerID,
                  AuthID: this.props.user.consumerDetails.data.accessToken.ID,
                })
              )
              .then(() =>
                this.props
                  .getUserDocsAsync({
                    ConsumerID: this.props.user.userDetails.consumerID,
                    AuthID: this.props.user.consumerDetails.data.accessToken.ID,
                  })
                  .then(() => {
                    this.setState({
                      deviceDocsLoader: false,
                    })
                  })
              )
              .catch(() => {
                this.setState({
                  deviceDocsLoader: false,
                })
              })
          }
        )
      })
      .catch((error) => this.props.setToaster(error.message, 'error'))
  }

  handleAddressClick = (e, cfl_id) => {
    this.props.setDistrictListConfig([])
    if (!e) {
      this.setState({
        createAddress: true,
        newAddressDetails: {},
        selectedState: {},
        selectedDistrict: {},
        addressTypesList: this.state.addressTypesList.map((data) => ({
          ...data,
          isSelected: false,
        })),
      })
    } else {
      let selectedAddress = this.state.locations.filter(
        (address) => address.ConsumerFavouriteLocationID === parseInt(e.target && cfl_id)
      )[0]
      this.setState(
        {
          selectedAddress: e.target && cfl_id,
          landmark: selectedAddress.Landmark,
          lat: selectedAddress.Lat,
          lng: selectedAddress.Lng,
          locationObtained: true,
          selectedState: { State: selectedAddress.State, value: selectedAddress.State },
          selectedDistrict: { District: selectedAddress.District, value: selectedAddress.District },
          addressTypesList: this.state.addressTypesList.map((data) => {
            return {
              ...data,
              isSelected: selectedAddress.AddressType === data.text,
            }
          }),
        },
        () => {
          let addressConfig = _.get(this, 'props.user.countryAddressConfig.addressConfig', fallbackAddressConfig)
          const isDistrictMendatory = addressConfig.some((v) => v['keyName'] === 'District' && v['mandatory'])
          if (isDistrictMendatory) {
            if (selectedAddress.StateCode) {
              this.getDistrictListDetails(selectedAddress.StateCode)
            }
          }
        }
      )
    }
  }

  getDistrictListDetails = (StateCode) => {
    this.props.setglobalLoaderStatus(true)
    let stateCode = StateCode || this.props.user.userLocation.stateCode
    let countryCode = this.props.user.selectedCountryData.CountryCode
    let reqObj = {
      StateCode: `${stateCode}`,
      CountryCode: countryCode,
    }
    this.props
      .getDistrictListAsync(reqObj)
      .then((res) => {
        if (res && res.length) {
          this.setState({
            showDistrictSelection: false,
          })
        } else {
          this.props.setToaster('No District Found.') // translation string
          this.setState({
            isLoaderSet: false,
            showDistrictSelection: true,
          })
        }
      })
      .finally(() => {
        this.props.setglobalLoaderStatus(false)
      })
  }

  handleTypeaheadSelect = (value) => {
    this.props.setDistrictListConfig([])
    this.setState(
      {
        locationObtained: true,
        addressComponentLoader: true,
      },
      () => {
        this.props
          .getGeoLocationAsync(value)
          .then(() => {
            this.setState({ landmark: this.props.user.userLocation.landmark })
            let addressConfig = _.get(this, 'props.user.countryAddressConfig.addressConfig', fallbackAddressConfig)
            const isPinCodeEnabled = addressConfig.some((v) => v['keyName'] === 'PinCode' && v['display'])
            if (isPinCodeEnabled && !this.props.user.userLocation.pincode) {
              this.setUserAddressStateID(this.props.user.userLocation)
              this.setState({
                locationObtained: false,
              })
            } else {
              this.setUserAddressStateID(this.props.user.userLocation).then(() => {
                const isDistrictMendatory = addressConfig.some((v) => v['keyName'] === 'District' && v['mandatory'])
                const isPinCodeEnabled = addressConfig.some((v) => v['keyName'] === 'PinCode' && v['display'])
                const isDistrictPresent = _.get(this, 'props.user.userLocation.District', undefined)
                // district api fetching.
                if (isDistrictMendatory && !isDistrictPresent) {
                  if (_.get(this, 'props.request.userLocation.StateCode') || this.state.selectedState.StateCode) {
                    this.getDistrictListDetails(_.get(this, 'props.user.userLocation.StateCode') || this.state.selectedState.StateCode)
                  }
                }
                isPinCodeEnabled &&
                  this.onChangeAddressInput({
                    target: {
                      name: 'PinCode',
                      value: this.props.user.userLocation.pincode,
                    },
                  })
              })
            }
          })
          .finally(() => {
            this.setState({
              addressComponentLoader: false,
            })
          })
      }
    )
  }

  setUserAddressStateID = async (userAddressLocation) => {
    const userAddressState = userAddressLocation['state']
    const countryStateList = _.get(this, 'props.user.stateAddressListConfig', [])
    let selectedAddressState =
      (await countryStateList) && countryStateList.length && countryStateList.find((v) => userAddressState === v['State'])
    let selectedAddressStateID = selectedAddressState && selectedAddressState['StateID']
    let selectedAddressStateCode = selectedAddressState && selectedAddressState['StateCode']
    if (this.state.selectedAddress) {
      let addressesClone = this.state.locations.slice(0)
      let addressIndex = addressesClone.findIndex((address) => address.ConsumerFavouriteLocationID === parseInt(this.state.selectedAddress))
      addressesClone[addressIndex] = {
        ...addressesClone[addressIndex],
        State: _.get(this, 'props.user.userLocation.state', ''),
        City: _.get(this, 'props.user.userLocation.city', ''),
        Landmark: _.get(this, 'props.user.userLocation.landmark', ''),
        UserLandmark: _.get(this, 'props.user.userLocation.UserLandmark', ''),
        PinCode: userAddressLocation.pincode || userAddressLocation.PinCode || userAddressLocation.ZipCode,
        StateID: selectedAddressStateID || addressesClone[addressIndex]['StateID'],
        StateCode: selectedAddressStateCode || addressesClone[addressIndex]['StateCode'],
      }
      await this.setState({
        locations: addressesClone,
      })
    } else {
      await this.setState({
        newAddressDetails: {
          ...this.state.newAddressDetails,
          State: _.get(this, 'props.user.userLocation.state', ''),
          City: _.get(this, 'props.user.userLocation.city', ''),
          Landmark: _.get(this, 'props.user.userLocation.landmark', ''),
          UserLandmark: _.get(this, 'props.user.userLocation.UserLandmark', ''),
          StateID: selectedAddressStateID,
          PinCode: userAddressLocation.pincode || userAddressLocation.PinCode || userAddressLocation.ZipCode,
        },
      })
    }

    await this.setState(
      {
        lat: this.props.user.userLocation.latitude,
        lng: this.props.user.userLocation.longitude,
        selectedState: {
          State: userAddressState,
          value: userAddressState,
          StateCode: selectedAddressStateCode,
        },
      },
      () => {
        new Promise((resolve) => {
          resolve()
        })
      }
    )
  }

  onChangeTypeahead = (address) => {
    this.setState({
      landmark: address,
      locationObtained: false,
    })
  }

  onChangeStateSelection = (stateObject) => {
    this.setState({ selectedState: stateObject }, () => {
      this.onChangeAddressInput({
        target: {
          name: 'State',
          value: stateObject['value'],
        },
      })
    })
  }

  handleDistrictSelection = (stateObject) => {
    this.setState({ selectedDistrict: stateObject }, () => {
      this.onChangeAddressInput({
        target: {
          name: 'District',
          value: stateObject['value'],
        },
      })
    })
  }

  onChangeAddressInput = (e) => {
    let selectedStateDetails
    let selectedAddressStateID
    let selectedAddressDistrict
    if (e.target.name === 'PinCode') {
      let pinCodeType = _.get(this, 'props.user.selectedCountryData.countryConfig.PinCode.REGEX_FN', 'NUMERIC')
      if (e.target.value) {
        const pincodeLengthCheck = this.state.pincodeLengthAr.some((i) => [e.target.value.length].includes(i))
        if (!regexCheck('onChange', pinCodeType.toLowerCase(), e.target.value, Math.max(...this.state.pincodeLengthAr))) {
          this.setState({ locationObtained: !pincodeLengthCheck })
          return
        }
      }
    }
    if (e.target.name === 'State' && e.target.value) {
      const userAddressState = e.target.value
      const countryStateList = _.get(this, 'props.user.stateAddressListConfig', [])
      selectedStateDetails = countryStateList && countryStateList.length && countryStateList.find((v) => userAddressState === v['State'])

      selectedAddressStateID = selectedStateDetails['StateID']

      let addressConfig = _.get(this, 'props.user.countryAddressConfig.addressConfig', fallbackAddressConfig)
      const isDistrictMendatory = addressConfig.some((v) => v['keyName'] === 'District' && v['mandatory'])
      if (isDistrictMendatory) {
        if (selectedStateDetails.StateCode) {
          this.getDistrictListDetails(selectedStateDetails.StateCode)
        }
      }
    }
    if (e.target.name === 'District' && e.target.value) {
      selectedAddressDistrict = e.target.value
    }
    if (e.target.name === 'city' || e.target.name === 'City' || e.target.name === 'Address' || e.target.name === 'UserLandmark') {
      if (!regexCheck('onChange', 'plainTxt', e.target.value)) {
        return
      }
    }
    if (this.state.selectedAddress) {
      let addressesClone = this.state.locations.slice(0)
      let addressIndex = addressesClone.findIndex((address) => address.ConsumerFavouriteLocationID === parseInt(this.state.selectedAddress))
      addressesClone[addressIndex] = {
        ...addressesClone[addressIndex],
        [e.target.name]: e.target.value,
        StateID: selectedAddressStateID || addressesClone[addressIndex]['StateID'],
        District: selectedAddressDistrict,
      }
      this.setState({
        locations: addressesClone,
      })
    } else {
      this.setState({
        newAddressDetails: {
          ...this.state.newAddressDetails,
          [e.target.name]: e.target.value,
        },
      })
    }
  }

  toggleAddressPillSelection = (e) => {
    let id = e.currentTarget.id.replace('addressType-', '')
    if (id) {
      this.setState(
        {
          addressTypesList: this.state.addressTypesList.map((data, index) => {
            return {
              ...data,
              isSelected: parseInt(id) === index,
            }
          }),
        },
        () => {
          let selectedAddr = this.state.addressTypesList.find((data) => data.isSelected)
          this.onChangeAddressInput({ target: { name: 'AddressType', value: selectedAddr.text } })
        }
      )
    }
  }

  cancelEditOrCreate = () => {
    this.setState({
      selectedAddress: undefined,
      locationObtained: false,
      createAddress: false,
      landmark: '',
      locations: this.props.user.locations,
      selectedState: {},
      selectedDistrict: {},
    })
  }

  handleDeleteAddrConfir = () => {
    this.setState(
      {
        addressComponentLoader: true,
        showDeleteAddrConfirPopUp: false,
      },
      () => {
        this.props
          .deleteAddressAsync({
            ConsumerFavouriteLocationID: parseInt(this.state.selectedAddress),
            AuthID: this.props.user.consumerDetails.data.accessToken.ID,
          })
          .then(() => {
            this.cancelEditOrCreate()
            return this.props.getUserAddressesAsync({
              ConsumerID: this.props.user.userDetails.consumerID,
              AuthID: this.props.user.consumerDetails.data.accessToken.ID,
            })
          })
          .then(() => {
            this.setState({
              locations: this.props.user.locations,
              addressComponentLoader: false,
            })
          })
          .catch(() => {
            this.setState({
              addressComponentLoader: false,
            })
          })
      }
    )
  }

  saveAddress = () => {
    let selectedAddress = ''
    let apiCall = ''
    if (this.state.selectedAddress) {
      selectedAddress = this.state.locations.filter(
        (address) => address.ConsumerFavouriteLocationID === parseInt(this.state.selectedAddress)
      )[0]
      apiCall = this.props.updateAddressAsync
    } else {
      selectedAddress = this.state.newAddressDetails
      apiCall = this.props.addAddressAsync
    }
    this.setState({
      addressComponentLoader: true,
    })
    apiCall({
      AuthID: this.props.user.consumerDetails.data.accessToken.ID,
      data: {
        ConsumerID: this.props.user.userDetails.consumerID,
        ...selectedAddress,
        Address: selectedAddress.Address || '',
        Landmark: selectedAddress.Landmark || this.state.landmark,
        UserLandmark: selectedAddress.UserLandmark || '',
        Lat: this.state.lat,
        Lng: this.state.lng,
      },
    })
      .then(() => {
        this.cancelEditOrCreate()
        return this.props.getUserAddressesAsync({
          ConsumerID: this.props.user.userDetails.consumerID,
          AuthID: this.props.user.consumerDetails.data.accessToken.ID,
        })
      })
      .then(() => {
        this.setState({
          addressComponentLoader: false,
          selectedState: {},
          selectedDistrict: {},
        })
        this.setState({
          locations: this.props.user.locations,
        })
      })
      .catch(() => {
        this.setState({
          addressComponentLoader: false,
        })
      })
  }

  handleLogout = () => {
    if (window.fcWidget) {
      window.fcWidget?.user?.clear()
      window.isFCUserTokenSet = false
    }
    this.props.setglobalLoaderStatus(true)
    this.props.clearSessionAsync({
      CountryID: window.CountryID,
      CountryCode: window.CountryCode,
    })
    this.props.clearConsumerDetails()
    // this.props.clearLoginData()
    // localStorage.removeItem('state')
    this.props.setToaster(getTranslationText('success', 'loggedOut'), 'success')
    browserHistory.replace('/login')
  }

  openDocsGallery = (e) => {
    let docInfo
    this.props.user.documents.filter((docList) => {
      docList.Documents.find((doc) => {
        if (doc.UserDocumentID === parseInt(e.currentTarget.id)) {
          docInfo = doc
        }
      })
    })
    this.setState({
      showDocsModal: true,
      selectedDoc: docInfo,
    })
  }

  deleteUserDocs = () => {
    this.props
      .deleteUserDocsAsync({
        AuthID: this.props.user.consumerDetails.data.accessToken.ID,
        UserDocumentID: this.state.selectedDoc.UserDocumentID,
      })
      .then((response) => {
        this.setState({
          showDocsModal: false,
          selectedDoc: {},
          deviceDocsLoader: true,
        })
        !!response && this.props.setToaster(response.msg, 'success')
        this.props
          .getUserDocsAsync({
            ConsumerID: this.props.user.userDetails.consumerID,
            AuthID: this.props.user.consumerDetails.data.accessToken.ID,
          })
          .then(() => {
            this.setState({
              deviceDocsLoader: false,
            })
          })
      })
  }

  toggleConfirmationPopUp = () => {
    this.setState({
      showDeleteAddrConfirPopUp: !this.state.showDeleteAddrConfirPopUp,
    })
  }

  sortedAddressConfig = (addressConfig) => {
    addressConfig = addressConfig.sort((a, b) => {
      return a.displayOrder - b.displayOrder
    })

    let sortedAddressConfig = addressConfig
    let isUserLandmarkPresent = _.findIndex(sortedAddressConfig, (v) => {
      return v['keyName'] === 'UserLandmark'
    })
    let isLandMarkPresent = _.findIndex(sortedAddressConfig, (v) => {
      return v['keyName'] === 'Landmark'
    })
    if (isUserLandmarkPresent > -1) {
      const swap = (arr, index1, index2) =>
        arr.map((val, idx) => {
          if (idx === index1) return arr[index2]
          if (idx === index2) return arr[index1]
          return val
        })
      const arr = addressConfig
      if (isUserLandmarkPresent == 0) {
        sortedAddressConfig = swap(arr, isUserLandmarkPresent, isLandMarkPresent)
      }
    }
    return sortedAddressConfig
  }

  render() {
    const samsungSSOUser =
      _.get(this, 'props.user.appConfigDetails.app', '') == 'Samsung-Web' && _.get(this, 'props.user.consumerDetails.data.imei', '')
    return (
      <div className='profile-components-container mb48 mt8'>
        <div className='row'>
          <div className='col-lg-9 col-sm-12 add-boxholder-spacing'>
            <div className='row'>
              <div className='col-sm-12'>
                <MainDetailsComponent
                  phnoLengthAr={this.state.phnoLengthAr}
                  invalidFormat={this.state.invalidFormat}
                  isEmailLogin={_.get(this, 'props.user.appConfigDetails.EnableEmailLogin', false)}
                  customerNameDisabled={_.get(this, 'props.user.appConfigDetails.customerNameDisabled', false)}
                  isEditing={this.state.isEditingMainDetails}
                  {...this.state.userDetails}
                  toggleEdit={this.toggleMainDetailsEdit}
                  handleChange={this.handleMainDetailsChange}
                  loaderState={this.state.mainDeviceDetailsLoader}
                />
              </div>
              <div className='col-sm-6'>
                <AddressesComponent
                  isEdit={this.state.selectedAddress}
                  isCreate={this.state.createAddress}
                  locations={this.state.locations}
                  handleAddressClick={this.handleAddressClick}
                  handleTypeaheadSelect={this.handleTypeaheadSelect}
                  cancel={this.cancelEditOrCreate}
                  onChangeTypeahead={this.onChangeTypeahead}
                  onChangeAddressInput={this.onChangeAddressInput}
                  onChangeStateSelection={this.onChangeStateSelection}
                  handleDistrictSelection={this.handleDistrictSelection}
                  selectedDistrict={this.state.selectedDistrict}
                  selectedState={this.state.selectedState}
                  placesAutocomplete={{
                    options: {
                      ...this.state.placesAutocompleteOptions,
                      componentRestrictions: {
                        country: _.get(this, 'props.user.selectedCountryData.CountryCode', '').toLowerCase(),
                      },
                    },
                    landmark: this.state.landmark,
                  }}
                  addressTypesList={this.state.addressTypesList}
                  pincodeLengthAr={this.state.pincodeLengthAr}
                  loaderState={this.state.addressComponentLoader}
                  toggleAddressPillSelection={this.toggleAddressPillSelection}
                  newAddressDetails={this.state.newAddressDetails}
                  allowSave={this.state.locationObtained}
                  saveAddress={this.saveAddress}
                  toggleConfirmationPopUp={this.toggleConfirmationPopUp}
                  user={this.props.user}
                  sortedAddressConfig={this.sortedAddressConfig}
                />
              </div>
              {_.get(this, 'props.user.appConfigDetails.isShowProfileDocsUpload', true) &&
                _.get(this, 'props.user.documents.length', 0) > 0 && (
                  <div className='col-sm-6'>
                    <Loader loaderState={this.state.deviceDocsLoader}>
                      <UploadDocumentComponent
                        documents={this.props.user.documents}
                        changeDoc={this.changeDoc}
                        loaderState={this.state.deviceDocsLoader}
                        openDocsGallery={this.openDocsGallery}
                        uploadConsumerDocToDS={this.uploadConsumerDocToDS}
                      />
                    </Loader>
                  </div>
                )}
            </div>
          </div>
          <div className='col-lg-3 col-sm-12 add-boxholder-spacing'>
            <div className='row'>
              {/*
                <div className='col-lg-12 col-sm-6 col-12'>
                  <Loader loaderState={this.state.beginnerLvlloaderState}>
                    <HomeUserLevels userDevicePoints={this.state.userDevicePoints}
                      loaderState={this.state.beginnerLvlloaderState} />
                  </Loader>
                </div>
                */}
              {samsungSSOUser ? (
                ''
              ) : (
                <div className='col-lg-12 col-sm-6 col-12'>
                  <ProfileSettingsComponent
                    {...this.props.user.userDetails}
                    appConfigDetails={this.props.user.appConfigDetails}
                    handleLogout={this.handleLogout}
                    emailAlerts={this.state.emailAlerts}
                    notificationAlerts={this.state.notificationAlerts}
                    isEmailLogin={_.get(this.props, 'user.appConfigDetails.EnableEmailLogin', false)}
                  />
                </div>
              )}
              <div className='col-lg-12 col-sm-6 col-12'>
                <HomeDeviceOverView deviceOverviewState={this.state.deviceOverviewState} />
              </div>
            </div>
          </div>
        </div>
        {this.state.showDocsModal ? (
          <ImageViewerModal
            show={this.state.showDocsModal}
            closeIconHandler={() => {
              this.setState({ showDocsModal: false })
            }}
            removeBtnStatus
            imagePath={this.state.selectedDoc.FilePath}
            deleteDocsHandler={this.deleteUserDocs}
          />
        ) : (
          ''
        )}
        {this.state.showDeleteAddrConfirPopUp ? (
          <ConfirmationPopUp
            show={this.state.showDeleteAddrConfirPopUp}
            title={getTranslationText('profile', 'deleteAddress')}
            toggle={this.toggleConfirmationPopUp}
            confirmationTxt={getTranslationText('profile', 'deleteAddressText')}
            handleConfirmation={this.handleDeleteAddrConfir}
          />
        ) : (
          ''
        )}
      </div>
    )
  }

  static propTypes = {
    user: PropTypes.object.isRequired,
    getUserDetailsAsync: PropTypes.func.isRequired,
    getUserAddressesAsync: PropTypes.func.isRequired,
    getUserDocsAsync: PropTypes.func.isRequired,
    updateUserDetailsAsync: PropTypes.func.isRequired,
    getSignedUrlAsync: PropTypes.func.isRequired,
    addDocAsync: PropTypes.func.isRequired,
    getBrowserLocationAsync: PropTypes.func.isRequired,
    getGeoLocationAsync: PropTypes.func.isRequired,
    updateAddressAsync: PropTypes.func.isRequired,
    addAddressAsync: PropTypes.func.isRequired,
    deleteAddressAsync: PropTypes.func.isRequired,
    clearConsumerDetails: PropTypes.func.isRequired,
    setToaster: PropTypes.func.isRequired,
    deleteUserDocsAsync: PropTypes.func.isRequired,
    setglobalLoaderStatus: PropTypes.func.isRequired,
    setUserDetails: PropTypes.func.isRequired,
    getDeviceOverviewAsync: PropTypes.func.isRequired,
    clearSessionAsync: PropTypes.func.isRequired,
    getCountriesAsync: PropTypes.func.isRequired,
    getStateListAsync: PropTypes.func.isRequired,
  }
}

export default Profile
